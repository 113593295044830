
<div class="w-full flex justify-end z-20 mt-1">
<button  (click)="onClose()">
    <mat-icon
    class="text-black"
    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
</button>
</div>
<div class="w-full flex items-center   justify-start ml-5  z-10">
<img src="assets/icons/hazard_icon.svg" class="w-12 h-12 " >
<div class="text-black ml-2 text-3xl font-medium text-center">{{data.title?data.title:'Confirm Removal'}}</div>
</div>
<div class="min-w-full min-h-full">
        <mat-dialog-content class="-mt-10 ">
             
           
            <div class="text-[#64748B] flex flex-wrap justify-center max-w-[30rem]  mt-5 text-left text-md font-normal pl-14 pr-5">{{data.message}}</div>
    
            <div class="flex gap-5 w-full justify-end items-center mt-8">
                <button (click)="onClose()" class="text-black  py-2 text-lg font-medium">Cancel</button>
                <button (click)="onClickYes()" class=" rounded-full text-white bg-[#CA3A31] py-2 px-4 text-lg font-medium">{{data.buttonTitle?data.buttonTitle:'Remove'}}</button>
                
            </div>
        
        </mat-dialog-content>
          

    

</div>