import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Component, ElementRef, EventEmitter, inject, Input, Output, SimpleChanges, TemplateRef, ViewChild,viewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerComponent, FuseDrawerService } from '@fuse/components/drawer';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { MatDialog } from '@angular/material/dialog';
import { AddConfigurationComponent } from '../add-configuration/add-configuration.component';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { CommonService } from 'app/core/common/common.service';
import { AuthService } from 'app/core/auth/auth.service';
import { TechnologyDomainService } from 'app/core/technology-domain/technology-domain.service';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor, HTMLElement } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';
import moment from 'moment';
import { environment } from 'environments/environment';
import { debounceTime, distinctUntilChanged, filter, finalize, Subscription, switchMap } from 'rxjs';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { SelectStoriesComponent } from '../select-stories/select-stories.component';

@Component({
  selector: 'app-configuration',
  standalone: true,
  imports: [NgIf, MatFormFieldModule,NgFor,FuseAlertComponent,MatMenuModule, NgClass, MatProgressSpinnerModule, MatSelectModule, MatCheckboxModule, ReactiveFormsModule, MatIcon, MatInputModule, MatButtonModule, MatExpansionModule, DragDropModule, MatSelectModule,FuseDrawerComponent, NgxTiptapModule, MatPaginatorModule],
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationComponent {
  @ViewChild(FuseDrawerComponent) viewDrawer: FuseDrawerComponent;

  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  scrollWidth = 250; // Adjust scroll step width
  flashMessageType: 'success' | 'error' | null = null;
  headerForm:UntypedFormGroup;
  configForm:UntypedFormGroup;

  _proposalDetail: any = {};
  _buildDetail: any = {};
  themeNameValue: string;
  private proposalSet: boolean = false;
  private buildSet: boolean = false;
  private metadataSet: boolean = false;
  private categorySet: boolean = false;
  isSavingConfig: boolean = false;
  isDrawerConfigOpen = false;
  themeTypes: any[]=[];
  epicTypes: any[]=[];
  allMetadataTypeList: any[]= [];
  allMetadataCategoryList: any[]= [];
  allMetadataTypeListForm: any[]= [];

  showLeftArrow = false;
  showRightArrow = false;
  items = 10;

  @Input() set proposal(value: any) {
    if (value) {
      this._proposalDetail = value;
      this.proposalSet = true;
      if(this._proposalDetail.epics && this._proposalDetail.epics.length){
        for(let epic of this._proposalDetail.epics){
          this.themeTypes.push({value:epic._id,label:epic.theme})
        }
      }
      this.checkInitialization();
    }
  }

  @Input() set build(value: any) {
    if (value) {
      this._buildDetail = value;
      this.buildSet = true;
      this.checkInitialization();
    }
  }

  @Input() set configurations(value: any) {
    if (value) {
      this.buildList = (value && value.length)?value:[];
    }
  }

  @Output() saveBuildEvent = new EventEmitter<any>();
  @Output() clearBuildEvent = new EventEmitter<any>();

  @Input()
  view_component:string;

  @Input()
  technologyDomains:any[]=[];

  @Input() set allMetadataCategory(value: any) {
    if(value){
      this.allMetadataCategoryList=value;
      this.categorySet = true;
      this.checkInitialization();
    }
  }

  @Input() set allMetadataType(value: any) {
    if(value){
      this.allMetadataTypeList=value;
      this.metadataSet = true;
      this.checkInitialization();
    }
  }

  @Input()
  metadataMap = new Map();

  @Input() refreshTrigger!: number;

  @Input()
  epicName:string;

  @Input() set themeName(value: any) {
    if(value)this.themeNameValue=value;
  }

  @Input()
  lowLevelSolutionSummary:string;

  @Input()
  themeID:string;

  @Input()
  myIndex:number;

  @Input()
  epicID:string;

  @Input()
  is_write_allowed:any;

  @Output()
  onSaveConfiguration=new EventEmitter<any>();

  private loadingEventSubscription: Subscription;
  totalRecords = 100;
  pageSize = 20;
  currentPage = 0;
  turnTaken:boolean=false;
  selectedBuild:any = {};
  cloudTypes:any [] = [];
  themeListTypes:any [] = [];
  buildList:any[] = [];
  all_original_docs:any[] = [];
  allMetadataSubCategory:any[] = [];
  allMetadataCategoryForm:any[] = [];
  allMetadataSubCategoryForm:any[] = [];
  allMetadataCategoryOriginal:any[] = [];
  loader_buildList:boolean=true;
  userStoryArray: FormArray;
  height: string = 'auto';
  statusTypes:any[]=[
    {value:"Not Started",label:"Not Started"},
    {value:"In Progress",label:"In Progress"},
    {value:"Done",label:"Done"},
    {value:"On-Hold",label:"On-Hold"},
    {value:"Cancelled",label:"Cancelled"}
  ];
  workTypes:any[]=[
    {value:"Configuration",label:"Configuration"},
    {value:"Customization",label:"Customization"}
  ];
  actionTypes:any[]=[
    {value:"New",label:"New"},
    {value:"Update",label:"Update"},
    {value:"Delete",label:"Delete"}
  ];

  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Enter Description ",
      })
    ],
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if (content === '' || content === '<p></p>' || content === '<p><br></p>') {
        editor.commands.clearContent();
        setTimeout(() => {
          this.configForm.get("description").setValue("");
        }, 0);
        this._changeDetectorRef.detectChanges();
      }
    }
  });

  editor1 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Enter Assumption ",
      })
    ],
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if (content === '' || content === '<p></p>' || content === '<p><br></p>') {
        editor.commands.clearContent();
        this.configForm.get("assumption").setValue("");
        this._changeDetectorRef.detectChanges();
      }
    }
  });

  editor2 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Enter Developer Notes ",
      })
    ],
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if (content === '' || content === '<p></p>' || content === '<p><br></p>') {
        editor.commands.clearContent();
        this.configForm.get("developerNote").setValue("");
        this._changeDetectorRef.detectChanges();
      }
    }
  });

  editor_description_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your configuration.',
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });
  private initialized = false;

  constructor(public dialog:MatDialog, 
    private _proposalService:ProposalService,
    private _authService:AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _commonService:CommonService,
    private _websocketService: WebsocketService,
    private _formBuilder: UntypedFormBuilder)
  {
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "configuration_workbook_generated")
    ).subscribe((message) => {
        if(message && this._buildDetail && message['taskID'] == this._buildDetail._id){
          this.initConfigurations();
        }
    });
  }

  private checkInitialization() {
    // console.log('checkInitialization',this.proposalSet , this.buildSet , this.initialized);
    if (this.proposalSet && this.buildSet && !this.initialized) {
      this.initialized = true;
      this.initializeComponent();
      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnInit(){
    // console.log('ngOnInit triggered');
    // console.log('Before:', this._buildDetail._id, this.themeNameValue, this.epicName, this.lowLevelSolutionSummary);
    this.autoGrow();
    if (this.proposalSet && this.buildSet && this.categorySet && this.metadataSet) {
      this.initializeComponent();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refreshTrigger'] && !changes['refreshTrigger'].firstChange && changes['refreshTrigger'].currentValue) {
      // console.log(`Refreshing UI for themeID: ${this.themeID}, epicID: ${this.epicID}, trigger: ${JSON.stringify(changes['refreshTrigger'])}`);
      // this.initConfigurationsWorkView(false); // ✅ Force change detection
    }
  }

  initializeComponent() {
    // console.log('initializeComponent triggered');
    // console.log('After:', this._buildDetail._id, this.themeNameValue, this.epicName, this.lowLevelSolutionSummary);
    this.allMetadataCategoryOriginal = JSON.parse(JSON.stringify(this.allMetadataCategoryList));
    this.headerForm = this._formBuilder.group({
      search : [''],
      selected_type : [''],
      selected_category : [''],
      selected_subcategory : [''],
      selected_status : [''],
      selected_actiontype : [''],
      selected_worktype : [''],
      theme : [''],
      epic : [''],
      cloud : ['']
    });
    
    this.configForm = this._formBuilder.group({
      _id: new FormControl(''),
      proposalID: ['', Validators.required],
      buildID: ['', Validators.required],
      label:[''],
      cloud:['',Validators.required],
      status:['',Validators.required],
      metadataType:['',Validators.required],
      metadataName:['',Validators.required],
      object:[''],
      themeID:['',Validators.required],
      epicID:['',Validators.required],
      solutionCategory:['',Validators.required],
      solutionSubCategory:['',Validators.required],
      workType:['',Validators.required],
      actionType:['',Validators.required],
      description:['',Validators.required],
      assumption:['',Validators.required],
      developerNote:[''],
      name: new FormControl(''),
      created: new FormControl(''),
      author: this._formBuilder.group({
        personal: this._formBuilder.group({
          name: this._formBuilder.group({
            first: new FormControl(''),
            last: new FormControl('')
          })
        }),
        contact: this._formBuilder.group({
          email: new FormControl('')
        }),
        meta: this._formBuilder.group({
          profileImage: new FormControl('')
        })
      }),
      stories: this._formBuilder.array([])
    });
    this.userStoryArray = this._formBuilder.array([]);
    if(this._proposalDetail['epics'] && this._proposalDetail['epics'].length){
      this.themeListTypes = [];this.epicTypes = [];
      this._proposalDetail['epics'].forEach(element => {
        let current = {label:element.theme,value:element._id,epics:[]};
        if(element.epics && element.epics.length){
          element.epics.forEach(epicelement => {
            current['epics'].push({label:epicelement.name,value:epicelement._id});
            this.epicTypes.push({label:epicelement.name,value:epicelement._id});
          });
        }
        this.themeListTypes.push(current);
      });
    }
    if(this.themeID)this.headerForm.get('theme').setValue(this.themeID);
    if(this.epicID)this.headerForm.get('epic').setValue(this.epicID);
    if(this.allMetadataCategoryList && this.allMetadataCategoryList.length){
        this.allMetadataCategoryForm = JSON.parse(JSON.stringify(this.allMetadataCategoryList));
    }
    if(this.view_component=='workspace'){
      // console.log('data:',this._buildDetail._id , this.themeNameValue , this.epicName);
      // if(this.myIndex==0){
      //   if(this.themeNameValue){
      //     this.turnTaken=true;
      //     this.initConfigurationsWorkView(true);
      //   }
      // }else{
      //   this.loadingEventSubscription=this._proposalService.currentLoadingEvent$.subscribe((response)=>{
      //     // console.log('data:',this.myIndex , response , this.turnTaken, this.themeNameValue);
      //     if(this.myIndex==response && !this.turnTaken){
      //       if(this.themeNameValue){
      //         this.turnTaken=true;
      //         this.initConfigurationsWorkView(true);
      //       }
      //     }
      //   });
      // }
    }
    else this.initConfigurations();
    
    this.setupSearchListener();
  }

  ngAfterViewInit() {
    this.checkScroll(); // Check scroll visibility on load
  }


  checkScroll() {
    if (!this.scrollContainer) return;
    const container = this.scrollContainer.nativeElement;
    this.showLeftArrow = container.scrollLeft > 0;
    this.showRightArrow = container.scrollLeft + container.clientWidth < container.scrollWidth;
  }

  ngOnDestroy(){
    if(this.loadingEventSubscription){
      this.loadingEventSubscription.unsubscribe();
    }
  }

  setupSearchListener(): void {
    this.headerForm.get('search')?.valueChanges
      .pipe(
        debounceTime(500), // Wait for 500ms pause in input
        distinctUntilChanged(), // Only emit if value has changed
        switchMap(value => this.filterCount()) // Cancel previous calls if a new value is emitted
      )
      .subscribe();
  }

  initConfigurations(){
    if(this._buildDetail && this._buildDetail._id){
      const offset = this.currentPage * this.pageSize;
      this._proposalService.getConfigurations(
        offset, 
        this.pageSize, 
        this._buildDetail._id, 
        this.headerForm.get('theme').value,
        this.headerForm.get('epic').value,
        this.headerForm.get('selected_subcategory').value, 
        this.headerForm.get('selected_category').value, 
        this.headerForm.get('selected_type').value, 
        this.headerForm.get('selected_status').value,
        this.headerForm.get('selected_actiontype').value,
        this.headerForm.get('selected_worktype').value,
        this.headerForm.get('cloud').value,
        this.headerForm.get('search').value
      ).subscribe((response)=>{
        this.totalRecords = response.total;
        if (response.data && response.data.length) {
          this.buildList = response.data;
          this.all_original_docs = response.data;
        } else {
          this.buildList = [];
        }
        this.loader_buildList = false;
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  onPageChange(event: PageEvent) {
    // console.log("On CHange Page:",event);
    this.loader_buildList = true;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.initConfigurations();
  }

  initConfigurationsWorkView(myTurn=false){
    // console.log(`Instance ${this.myIndex}: Initializing API call.`);
    // if(this._buildDetail && this._buildDetail._id && this.themeNameValue && this.epicName){
    //   const offset = this.currentPage * this.pageSize;
    //   this._proposalService.getConfigurations(
    //     offset, 
    //     this.pageSize, 
    //     this._buildDetail._id, 
    //     this.headerForm.get('theme').value,
    //     this.headerForm.get('epic').value,
    //     this.headerForm.get('selected_subcategory').value, 
    //     this.headerForm.get('selected_category').value, 
    //     this.headerForm.get('selected_type').value, 
    //     this.headerForm.get('selected_status').value, 
    //     this.headerForm.get('selected_actiontype').value,
    //     this.headerForm.get('selected_worktype').value,
    //     this.headerForm.get('cloud').value,
    //     this.headerForm.get('search').value
    //   )
    //   .pipe(
    //     finalize(() => {
    //         if (myTurn) {
    //           // console.log(`Instance ${this.myIndex}: Incrementing turn.`);
    //           this._proposalService.incrementCurrentLoadingEvent(this.myIndex);
    //         }
    //     })
    //   )
    //   .subscribe({
    //     next: (response) => {
    //       // console.log(`Instance ${this.myIndex}: API Success Response:`, response);
    //       this.totalRecords = response.total;
    //       this.buildList = response.data?.length ? response.data : [];
    //       this.loader_buildList = false;
    //       this._changeDetectorRef.markForCheck();
    //     },
    //     error: (err) => {
    //       // console.error(`Instance ${this.myIndex}: API Error:`, err);
    //       this.loader_buildList = false;
    //       this.turnTaken = false;
    //     },
    //     complete: () => {
    //       // console.log(`Instance ${this.myIndex}: API Call Completed.`);
    //     }
    // });
    // }
    // else{
    //   if(myTurn){
    //     this._proposalService.incrementCurrentLoadingEvent(this.myIndex);
    //   }
    // }
  }

  onChangeTheme(event){
    let selectedThemes=event.value;
    this.epicTypes=[];
    if(selectedThemes.length){
      for(let epic of this._proposalDetail.epics){
        if(selectedThemes.includes(epic._id)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic._id };
          });
          this.epicTypes.push(...epics);
        }
      }
    }
    else {
      for(let epic of this._proposalDetail.epics){
        let epics= epic.epics.map((themeEpic) => {
          return { label: themeEpic.name, value: themeEpic._id };
        });
        this.epicTypes.push(...epics);
      }
    }
    this.filterCount();
  }

  onChangeFormTheme(event){
    let selectedThemes=event.value;
    this.epicTypes=[];
    this.configForm.get('epicID').setValue('');
    if(selectedThemes.length){
      for(let epic of this._proposalDetail.epics){
        if(selectedThemes.includes(epic._id)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic._id };
          });
          this.epicTypes.push(...epics);
        }
      }
    }
  }

  onChangeCategory(event) {
    this.allMetadataSubCategory = [];
    let selectedCategories = event.value;
    if (Array.isArray(selectedCategories) && selectedCategories.length > 0) {
      let selectedSubcategories = [];
      selectedCategories.forEach(category => {
        let selectVal = this.allMetadataCategoryOriginal.find(el => el.label === category);
        if (selectVal && selectVal.subcategories) {
          selectedSubcategories.push(...selectVal.subcategories);
        }
      });
      this.allMetadataSubCategory = Array.from(new Set(selectedSubcategories));
    }
    else {
      let allSubcategories = this.allMetadataCategoryOriginal.flatMap(el => el.subcategories || []);
      this.allMetadataSubCategory = Array.from(new Set(allSubcategories));
    }
    this.filterCount();
  }
  
  onChangeMetadata(event) {
    this.allMetadataSubCategory = [];
    this.allMetadataCategoryList = [];
    if (Array.isArray(event.value) && event.value.length) {
      const selectedMetadataList = event.value
        .map(value => this.metadataMap.get(value))
        .filter(metadata => metadata); // Remove undefined values
  
        // console.log(selectedMetadataList);
      // Extract unique categories and subcategories
      const categorySet = new Set();
      const subCategorySet = new Set();
  
      selectedMetadataList.forEach(metadata => {
        categorySet.add(metadata.category);
        subCategorySet.add(metadata.subcategory);
      });
  
      // Convert sets to arrays with label-value structure
      this.allMetadataCategory = Array.from(categorySet).map(category => ({ label: category, value: category }));
      this.allMetadataSubCategory = Array.from(subCategorySet).map(subcategory => ({ label: subcategory, value: subcategory }));
    }
    else {
     this.allMetadataCategoryList = [...this.allMetadataCategoryOriginal];
      let allSubcategories = this.allMetadataCategoryOriginal.flatMap(el => el.subcategories || []);
      this.allMetadataSubCategory = Array.from(new Set(allSubcategories));
    }
    this.filterCount();
  }
  
  async filterCount(){
    this.initConfigurations();
  }

  openDialog() {
    let dialogRef = this.dialog.open(AddConfigurationComponent,
      { 
        panelClass: 'add-config-dialog',
        disableClose: true, 
        data:{
          proposal:this._proposalDetail,
          build:this._buildDetail,
          themeTypes:this.themeTypes,
          epicTypes:this.epicTypes,
          technologyDomains: this.technologyDomains,
          allMetadataCategory : this.allMetadataCategoryOriginal,
          allMetadataType: this.allMetadataTypeList,
          metadataMap: this.metadataMap,
          theme: this.themeID,
          epic: this.epicID
        } 
      }
    );
    dialogRef.beforeClosed().subscribe(result => {console.log(result.story);
      if(result && (result.ok || result.updated || result.success)){
        // this.initConfigurations();
        if(this.view_component=='workspace'){
          this.buildList.push(result.story);
          this._changeDetectorRef.detectChanges();
          this.all_original_docs = this.buildList;
          this.onSaveConfiguration.emit({ValueChanged:true,add:true,config:result.story});
        }
        else {
          this.onSaveConfiguration.emit({ValueChanged:true,add:true,config:result.story});
          this.initConfigurations();
        }
      }
      // this._changeDetectorRef.detectChanges();
    });
  }

  onDelete(config){
    this.viewDrawer.close();
    if(config.delete){
      return;
    }
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {
        message:"Are you sure you want to delete this configuration?",
        title:"Confirm Delete",
        buttonTitle:"Delete"
      }
    });
    dialogRef.beforeClosed().subscribe(result => {
      if(result && result.yes===true){
        config.delete=true;
        this._proposalService.deleteConfiguration({config:config._id}).subscribe((response)=>{
          if(response && (response.ok || response.updated || response.success)){
            // this.initConfigurations();
            if(this.view_component=='workspace'){
              const index = this.buildList.findIndex(el => el._id === config._id);
              if (index > -1) {
                this.buildList.splice(index, 1);
                this._changeDetectorRef.detectChanges();
              }
            }
            else {
              this.onSaveConfiguration.emit({ValueChanged:true,delete:true,config:config});
              this.initConfigurations();
            }
          }
        });
      }
    });
  }

  onEdit(config){
    this.viewDrawer.close();
    const dialogRef = this.dialog.open(AddConfigurationComponent,{ 
      panelClass: 'add-config-dialog',
      disableClose: true,
      data:{
        proposal:this._proposalDetail,
        build:this._buildDetail,
        themeTypes:this.themeTypes,
        epicTypes:this.epicTypes,
        technologyDomains: this.technologyDomains,
        allMetadataCategory : this.allMetadataCategoryOriginal,
        allMetadataType: this.allMetadataTypeList,
        metadataMap: this.metadataMap,
        config:config
      }  
    });
    dialogRef.beforeClosed().subscribe(result => {
      if(result && (result.ok || result.updated || result.success)){
        if(this.view_component=='workspace'){
          // const index = this.buildList.findIndex(el => el._id === config);console.log(index);
          // if (index > -1) {
          //   this.buildList.splice(index, 1);
          //   this._changeDetectorRef.detectChanges();
          // }
        }
        else {
          this.onSaveConfiguration.emit({ValueChanged:true,edit:true,config:result.story});
          this.initConfigurations();
        }
      }
    });
  }

  onChangeEpic(event){

  }

  onChangeFormMetadata(event) {
    // this.allMetadataSubCategoryForm=[];
    // this.allMetadataCategoryList=[];
    const selectedMetadata = this.metadataMap.get(event.value);
    // console.log(selectedMetadata);console.log(this.allMetadataCategoryForm);
    if (selectedMetadata) {
      // this.allMetadataCategoryList = [{label:selectedMetadata.category,value:selectedMetadata.category}];
      // this.allMetadataSubCategoryForm = [{label:selectedMetadata.subcategory,value:selectedMetadata.subcategory}];
      // this.onChangeFormCategory({value:selectedMetadata.category});
      // this.configForm.get("solutionCategory").setValue(selectedMetadata.category);
      // this.configForm.get("solutionSubCategory").setValue(selectedMetadata.subcategory);
      // this.configForm.get("workType").setValue(selectedMetadata.workType);
      // this.configForm.get("metadataType").setValue(event.value);
    }
  }

  onChangeFormCategory(event){
    this.allMetadataSubCategoryForm=[];
    let selectedCategories=event.value;
    if(selectedCategories){
      let selectVal = this.allMetadataCategoryForm.find(el=>el.label==selectedCategories);
      this.configForm.get("solutionSubCategory").setValue('');
      if(selectVal)this.allMetadataSubCategoryForm = selectVal.subcategories;
    }
  }

  onSave(){
    let saveBuild;
    if(this.selectedBuild && this.selectedBuild._id && this.selectedBuild._id!=""){
      //Edit Mode
      saveBuild={
        _id:this.selectedBuild._id,
        proposalID:this.configForm.get("proposalID").value,
        buildID:this.configForm.get("buildID").value,
        label:this.configForm.get("label").value,
        cloud:this.configForm.get("cloud").value,
        metadataType:this.configForm.get("metadataType").value,
        metadataName:this.configForm.get("metadataName").value,
        object:this.configForm.get("object").value,
        themeID:this.configForm.get("themeID").value,
        epicID:this.configForm.get("epicID").value,
        solutionCategory:this.configForm.get("solutionCategory").value,
        solutionSubCategory:this.configForm.get("solutionSubCategory").value,
        workType:this.configForm.get("workType").value,
        status:this.configForm.get("status").value,
        actionType:this.configForm.get("actionType").value,
        description:this.configForm.get("description").value,
        assumption:this.configForm.get("assumption").value,
        developerNote:this.configForm.get("developerNote").value,
        stories:this.userStoryArray.value || []
      };
    }
    if(saveBuild){
      this.isSavingConfig=true;
      this._proposalService.createConfiguration(saveBuild).subscribe((response)=>{
        if(response.ok || response.success || response.update){
          this.isSavingConfig=false;
          const index = this.buildList.findIndex(el => el._id === this.selectedBuild._id);
          if (index > -1) {
            if (this.themeID && this.epicID && (this.themeID !== saveBuild.themeID || this.epicID !== saveBuild.epicID)) {
              // console.log(`Removing build ${this.selectedBuild._id} due to theme/epic change.`);
              this.buildList.splice(index, 1);
              this.saveBuildEvent.emit(saveBuild);
            } else {
              this.buildList[index] = { ...this.buildList[index], ...saveBuild };
              this.all_original_docs = this.buildList;
              this.onSaveConfiguration.emit({ValueChanged:true,edit:true,config:saveBuild});
            }
          }
          this.showFlashMessage('success');
        }else{
          this.showFlashMessage('error');
        }
      });
    }
  }

  selectBuild(eachtbuild) {
    //console.log(eachtbuild);
    this.viewDrawer.open();
    this._changeDetectorRef.detach(); // 🛑 Stop change detection
    this.onChangeFormMetadata({ value: eachtbuild.metadataType });
    this.onChangeFormTheme({ value: [eachtbuild.themeID] });
    this.onChangeFormCategory({ value: eachtbuild.solutionCategory });
    // console.log(eachtbuild);
    setTimeout(() => {
      this.configForm.patchValue({
        _id: eachtbuild._id,
        name: eachtbuild.name,
        description: eachtbuild.description,
        cloud: eachtbuild.cloud,
        status: eachtbuild.status,
        label: eachtbuild.label,
        metadataType: eachtbuild.metadataType,
        proposalID: this._proposalDetail._id,
        buildID: this._buildDetail._id,
        themeID: eachtbuild.themeID,
        epicID: eachtbuild.epicID,
        solutionCategory: eachtbuild.solutionCategory,
        solutionSubCategory: eachtbuild.solutionSubCategory,
        workType: eachtbuild.workType,
        actionType: eachtbuild.actionType,
        developerNote: eachtbuild.developerNote,
        assumption: eachtbuild.assumption,
        author: eachtbuild.author,
        created: eachtbuild.created,
      });
      setTimeout(() => {
        this.configForm.get('metadataName').setValue(eachtbuild.metadataName, { emitEvent: false });
      }, 5);
  
      this.userStoryArray.clear();
      if (eachtbuild.stories?.length) {
        this.batchProcessStories(eachtbuild.stories);
      }
  
      this.selectedBuild = eachtbuild;
      this._changeDetectorRef.reattach(); // ✅ Re-enable change detection
      this._changeDetectorRef.detectChanges(); // ✅ Force UI update once
    }, 10);
  }
  
  // 🛠 Process stories in small batches to prevent UI freeze
  batchProcessStories(stories) {
    const batchSize = 10; // Update 10 at a time
    let index = 0;
    const total = stories.length;
    const processBatch = () => {
      for (let i = 0; i < batchSize && index < total; i++, index++) {
        this.userStoryArray.push(this._formBuilder.group(this._createFormControls(stories[index])));
        // this.userStoryArray.push(this._formBuilder.group(stories[index]));
      }
      if (index < total) setTimeout(processBatch, 10); // Prevent blocking UI
    };
  
    processBatch();
  }
  

  selectStory(){
    const dialogRefstory = this.dialog.open(SelectStoriesComponent,{ 
      panelClass: 'add-story-dialog',
      disableClose: true,
      data: {
        proposal:this._proposalDetail,
        _buildDetail:this._buildDetail,
        themeTypes:this.themeTypes,
        userStoryArray: this.userStoryArray.value
      }
    });
    dialogRefstory.afterClosed().subscribe(result => {
      // console.log(result);
      if (this.userStoryArray && Array.isArray(result) && result.length > 0) {
        this.userStoryArray.clear();
        result.forEach((eachStory) => {
          this.userStoryArray.push(this._formBuilder.group(this._createFormControls(eachStory)));
        });
      
        if (this.configForm) {
          this.configForm.markAsDirty();
        }
        this._changeDetectorRef.detectChanges();
      }
    });
  }

  private _createFormControls(data: any): { [key: string]: any } {
    const group: { [key: string]: any } = {};
    Object.keys(data).forEach((key) => {
      group[key] = [data[key]]; // Wrap each property in an array for FormControl
    });
    return group;
  }

  toggleBold(editorname) {

    if(editorname === 'desc'){      
      this.editor.chain().focus().toggleBold().run();
    }
    if(editorname === 'assumption'){      
      this.editor1.chain().focus().toggleBold().run();
    }
    if(editorname === 'notes'){      
      this.editor2.chain().focus().toggleBold().run();
    }

  }


  toggleItalic(editorname) {
    if(editorname === 'desc'){      
       this.editor.chain().focus().toggleItalic().run();
    }
    if(editorname === 'assumption'){      
      this.editor1.chain().focus().toggleItalic().run();
    }
    if(editorname === 'notes'){      
      this.editor2.chain().focus().toggleItalic().run();
    }
  }

  toggleBulletList(editorname) {
    if(editorname === 'desc'){      
      this.editor.chain().focus().toggleBulletList().run();
    }
    if(editorname === 'assumption'){      
      this.editor1.chain().focus().toggleBulletList().run();
    }
    if(editorname === 'notes'){      
      this.editor2.chain().focus().toggleBulletList().run();
    }
    
  }
  // toggleHeading(level: number) {
  //     this.editor.chain().focus().toggleHeading([ level ]).run();
  // }

  

  getThemeName(theme){
    if(theme && this.themeListTypes.find(t => t.value === theme))
      return this.themeListTypes.find(t => t.value === theme).label;
    else 
      return '-';
  }

  getEpicName(theme,epic){
    if(theme && epic){
      const themeObj = this.themeListTypes.find(t => t.value === theme);
      // console.log(themeObj)
      if (!themeObj) return '-'; // If theme is not found, return null
  
      const epicVal = themeObj.epics.find(e => e.value === epic);
  
      return epicVal ? epicVal.label : '-';
    }
    else return '-';
  }

  getMetadataName(object){
    if(object && this.allMetadataTypeList.find(t => t.value === object)){
      return this.allMetadataTypeList.find(t => t.value === object).label;
    }
  }

  getImageURL(url){
    return environment.file_url_cf+ url + '?token='+this._authService.accessToken
  }

  format(time) {
    return moment(time).format('MMM D, YYYY');
  }
  
  openedChanged(event){
    this.isDrawerConfigOpen = event;
    if(event===false){
      this.onCloseDrawer();
    }
  }

  clear(){
    this.onCloseDrawer();
  }

  onCloseDrawer(){
    this.userStoryArray.clear();
    this.configForm.reset();
    this.selectedBuild = {};
    this.viewDrawer.close();
  }


  autoGrow(event?: Event): void {
    const textarea = event ? (event.target as HTMLTextAreaElement) : document.querySelector('textarea') as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scroll height
      this.height = textarea.style.height;
    }
  }



  scrollLeft() {
    this.scrollContainer.nativeElement.scrollBy({ left: -this.scrollWidth, behavior: 'smooth' });
    setTimeout(() => this.checkScroll(), 300); // Delay to ensure scroll is detected
  }

  scrollRight() {
    this.scrollContainer.nativeElement.scrollBy({ left: this.scrollWidth, behavior: 'smooth' });
    setTimeout(() => this.checkScroll(), 300);
  }


  showFlashMessage(type: 'success' | 'error'): void
  {
      // Show the message
      this.flashMessageType = type;
      this._changeDetectorRef.detectChanges();
      // Hide it after 3 seconds
      setTimeout(() =>
      {          
        this.isSavingConfig=false;
        this.flashMessageType = null;
      }, 3000);
  }



}
