import { inject } from '@angular/core';
import { CanActivateFn, CanActivateChildFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((userdata) =>
        {   
            // console.log("state",state.url);console.log("userdata",userdata);
            let routeRole = route.data && route.data.roles?route.data.roles[0]:'';
            // console.log("routeRole",routeRole);
            // If the user is not authenticated...
            if ( !userdata.isAuthenticated )
            {
                // Redirect to the sign-in page with a redirectUrl param
                const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`sign-in?${redirectURL}`);
                // console.log("urlTree",urlTree);
                return of(urlTree);
            }
            else if(userdata.isAuthenticated && (state.url.includes('/join') || state.url.includes('/sign-out') || state.url.includes('/settings')))return of(true);
            else if(userdata.isAuthenticated && routeRole == 'Admin' && userdata.additionalData.is_system_admin)return of(true);
            else if(userdata.isAuthenticated && routeRole == 'User' && userdata.additionalData  && !userdata.additionalData.is_system_admin)return of(true);
            else if(userdata.isAuthenticated && routeRole == 'SME' && userdata.additionalData  && !userdata.additionalData.is_system_admin)return of(true);
            else{
                // Redirect to the sign-in page
                const urlTree = router.parseUrl(`sign-in`);console.log("urlTree",urlTree);
                return of(urlTree);
            }
        }),
    );
};
