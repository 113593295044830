<div class="flex actBt flex-col gap-3 mb-40  ">
    <div class="subHeadingCont">                                                
        <div class="text-black font-medium text-lg">Review Existing Code Metadata</div>
        <div class="text-[#94A3B8] text-md font-normal mt-1">Integrates with all AI development tools to automate code creation.</div> 
    </div>



    <div class="bg-card p-4 rounded">
        <div class="flex justify-between">
            <h6 class="font-medium">Metadata Summary</h6>
            <a href="" class="text-[#4F45E4] text-sm flex gap-2">View Detailed Report <mat-icon svgIcon="heroicons_outline:arrow-right-circle" class="icon-size-5 text-[#4F45E4]"></mat-icon></a>
        </div>
        <div class="flex gap-3 flex-wrap mt-6">
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">APEX CLASSES</span>
                <h4 class="text-3xl font-semibold">2344</h4>
            </div>
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">APEX TRIGGERS</span>
                <h4 class="text-3xl font-semibold">2344</h4>
            </div>
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">APPLICATION</span>
                <h4 class="text-3xl font-semibold">34</h4>
            </div>
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">LWC COMPONENTS</span>
                <h4 class="text-3xl font-semibold">123</h4>
            </div>
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">CUSTOM LABELS</span>
                <h4 class="text-3xl font-semibold">23</h4>
            </div>
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">CUSTOM METADATA</span>
                <h4 class="text-3xl font-semibold">101</h4>
            </div>
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">CUSTOM OBJECTS</span>
                <h4 class="text-3xl font-semibold">291</h4>
            </div>
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">CUSTOM TABS</span>
                <h4 class="text-3xl font-semibold">4332</h4>
            </div>
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">DASHBOARD</span>
                <h4 class="text-3xl font-semibold">13</h4>
            </div>
            <div class="kpi flex border rounded mb-3 p-4  flex-col ">
                <span class="text-sm text-secondary">FLOWS</span>
                <h4 class="text-3xl font-semibold">3</h4>
            </div>
        </div>
        <a href="" class="text-[#4F45E4] text-sm flex gap-2">View More <mat-icon svgIcon="heroicons_outline:arrow-right-circle" class="icon-size-5 text-[#4F45E4]"></mat-icon></a>
    </div>

    <div class="chatBox relative">
        <div class="boxHead justify-end">
        
            <div class="docTab">
                <span [ngClass]="{'active': tabActive == 0 }" (click)="tabActivefunc(0)"> Chat </span>
                <span [ngClass]="{'active': tabActive == 1 }" (click)="tabActivefunc(1)"> Insights </span>
                <span [ngClass]="{'active': tabActive == 2 }" (click)="tabActivefunc(2)"> History </span>
            </div>
        </div>
        <div class="chatScreen" *ngIf="tabActive==0 "   >
            <div class="defaultScreen"  *ngIf="!chatClick" >
                <h3>Get Insights from your metadata</h3>
                <p>Use one of the most common prompts below  or use your own to begin</p>
                <div class="cardList mt-6 flex gap-6">
                    <div class="card border rounded-2xl">
                        <h4>Explain the logic of AccountController.apex class.</h4>
                    </div>
                    <div class="card border rounded-2xl">
                        <h4>List all active flows on opportunity object.</h4>
                    </div>
                    <div class="card border rounded-2xl">
                        <h4>Fetch all Apex classes where code coverage is less than 75%.</h4>
                    </div>

                </div>
            </div>

            <div class="chatData" *ngIf="chatClick"  #scrollableDiv>
                <ng-container *ngFor="let message of chat.messages; let i = index; let first = first; let last = last; trackBy: trackByFn">
                
                        <div class="flex flex-col"
                            [ngClass]="{'items-end': message.isMine,
                                        'items-start  ': !message.isMine,
                                        'mt-0.5': i > 0 && chat.messages[i - 1].isMine === message.isMine,
                                        'mt-3': i > 0 && chat.messages[i - 1].isMine !== message.isMine}">
                            
                            <div
                                class="relative max-w-3/4 rounded-lg"
                                [ngClass]="{'shadow responeMsg': !message.isMine}">
                                <ng-container *ngIf="last || chat.messages[i + 1].isMine !== message.isMine">
                                    <div
                                        class="absolute bottom-0 w-3"
                                        [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': message.isMine,
                                                    'text-gray-500 -left-1 -ml-px mb-px -scale-x-1': !message.isMine}">
                                        <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                                    </div>
                                </ng-container>
                                <div *ngIf="(message.type=='question' && message.value)"
                                    class="min-w-4 leading-5 p-2 messagebox"
                                    [innerHTML]="message.value">
                                </div>

                                <div *ngIf="(message.type=='answer' && message.value)">
                                    <div [innerHTML]="message.value" class="p-2"></div>
                                </div>

                                <ng-container *ngIf="(message.type=='answer')">
                                    <span class="block border-b"></span>
                                    <div  class="inline-flex gap-2 p-2">
                                        <button  class="viewBt2">
                                            <mat-icon svgIcon="heroicons_outline:document-duplicate"></mat-icon>
                                            <span>Copy</span>
                                        </button>
                                        <button  class="viewBt2" >
                                            <mat-icon svgIcon="heroicons_outline:document-arrow-down"></mat-icon>
                                            <span>Add to Insights</span>
                                        </button>
                                        <button  class="viewBt2" >
                                            <mat-icon svgIcon="heroicons_outline:arrow-path-rounded-square"></mat-icon>
                                            <span>Regenerate Response</span>
                                        </button>
                                    </div>
                                </ng-container>

                            </div>
                            
                        
                        </div>
                </ng-container>
            </div>
        </div>

        <div class="inputChat" *ngIf="tabActive==0">
            <textarea name="" id="" class="border shadow" placeholder="Type your question" ></textarea>
            <span class="subChat" (click)="chatSubmit()"  >
                <img src="assets/icons/chatsend.svg" alt="">
            </span>

        </div>


        <div class="insightTab " *ngIf="tabActive==1">
            <div class="queansTab ">
              <div class="quehead">
                <h6 class="font-bold">Question</h6>
                <span class="text-gray-400">14 Nov, 2024, Ryan Gosling</span>
              </div>
              <h3>Can you analyze the dependencies of the OpportunityRevenueCalculation.apex class?</h3>
              <div class="anshead">
                <h6 class="font-bold">Answer</h6>
                <div class="border mt-2 mb-2 rounded-lg p-2">
                    <p class="border-0 p-0">Certainly! The OpportunityRevenueCalculation.apex class has dependencies across several Salesforce components. Here’s a breakdown:</p>
                    <ul>
                        <li><b>Related Objects:</b> It directly references the Opportunity and OpportunityLineItem objects to calculate revenue based on product line items.</li>
                        <li><b>Triggers:</b> The class is called by a trigger on OpportunityLineItem updates to recalculate revenue whenever line items are modified.</li>
                        <li><b>Referenced Apex Classes:</b> It invokes methods from CurrencyConversionUtility and DiscountRateManager classes for accurate revenue calculations in multi-currency setups.</li>
                        <li><b>Workflow Rules and Processes:</b> Several workflow rules are triggered upon the update of revenue fields, which in turn fire notifications to the sales team when thresholds are met.</li>
                    </ul>
                    <p class="border-0 p-0">This class plays a crucial role in revenue reporting and is tightly integrated with various automation processes and utility classes.</p>
                </div>
              </div>
              <div class="actionins flex justify-between pr-7 pt-4">
                <div class="flex gap-2">
                    <button class="border flex items-center p-2 rounded text-xs viewBt2"> 
                        <mat-icon class="icon-size-3" svgIcon="heroicons_outline:document-duplicate"></mat-icon> Copy 
                    </button>
                    <button class="border flex items-center p-2 rounded text-xs viewBt2 ">
                        <mat-icon svgIcon="heroicons_outline:trash"></mat-icon> Delete
                    </button>
                </div>
              </div>
            </div>
            <div class="queansTab ">
                <div class="quehead">
                  <h6 class="font-bold">Question</h6>
                  <span class="text-gray-400">14 Nov, 2024, Ryan Gosling</span>
                </div>
                <h3>Can you list the flows and process builders that interact with the Lead object and describe their functions?</h3>
                <div class="anshead">
                  <h6 class="font-bold">Answer</h6>
                  <div class="border mt-2 mb-2 rounded-lg p-2">
                      <p class="border-0 p-0">Sure! Here is a summary of the active flows and process builders interacting with the Lead object:</p>
                      <ul>
                          <li><b>Lead Qualification Flow:</b> This flow is triggered when a lead’s status changes to "Qualified." It automatically creates a new Opportunity and assigns it to a sales rep based on predefined rules. Additionally, it updates related fields on the lead record to mark it as converted.</li>
                          <li><b>Lead Nurturing Process (Process Builder):</b> This process builder is designed to handle leads that are not yet sales-ready. It sets reminders and follow-up tasks at scheduled intervals and sends automated emails to nurture leads based on their last interaction.</li>
                          <li><b>Lead Scoring Flow:</b> This flow calculates a lead score based on engagement data, such as email opens, website visits, and interactions with marketing campaigns. It updates a custom Lead Score field, allowing sales reps to prioritize high-scoring leads.</li>
                          <li><b>Duplicate Check Process (Process Builder): </b> This process builder checks for duplicate records when a new lead is created. If a match is found based on the email or phone number, it notifies the sales admin team to review and resolve duplicates.</li>
                      </ul>
                      <p class="border-0 p-0">These automations collectively streamline lead handling, improve lead qualification, and maintain data quality across the sales process.</p>
                  </div>
                </div>
                <div class="actionins flex justify-between pr-7 pt-4">
                  <div class="flex gap-2">
                      <button class="border flex items-center p-2 rounded text-xs viewBt2"> 
                          <mat-icon class="icon-size-3" svgIcon="heroicons_outline:document-duplicate"></mat-icon> Copy 
                      </button>
                      <button class="border flex items-center p-2 rounded text-xs viewBt2 ">
                          <mat-icon svgIcon="heroicons_outline:trash"></mat-icon> Delete
                      </button>
                  </div>
                </div>
              </div>
          </div>


        <div class="historyTab" *ngIf="tabActive ==2">
            <ng-container>
                <div class="notfound">
                    <mat-icon svgIcon="heroicons_outline:document-minus"></mat-icon>
                    <p>There is no conversation history to display at the moment. Begin a new conversation, and your history will appear here for future reference</p>
                </div>
            </ng-container>
        </div>


    </div>

</div>