import { ChangeDetectorRef, Component, ElementRef, Inject, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DatePipe,NgFor,NgIf,NgClass,NgTemplateOutlet, JsonPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

import { UntypedFormBuilder } from '@angular/forms';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { CommonService } from 'app/core/common/common.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { UserService } from 'app/core/user/user.service';
import { filter, interval, Subject, take, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { marked } from 'marked';
import moment from 'moment';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor, HTMLElement } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Heading } from '@tiptap/extension-heading';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { DOMSerializer, DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';
import { TextSelection } from '@tiptap/pm/state';

@Component({
  selector: 'app-chat-box',
  standalone: true,
  imports: [MatDialogModule,MatSelectModule,NgxTiptapModule,MatFormFieldModule,MatProgressSpinnerModule,MatIconModule,NgFor,NgIf,NgClass,NgTemplateOutlet,DatePipe,MatCheckboxModule,JsonPipe],
  templateUrl: './chat-box.component.html',
  styleUrl: './chat-box.component.scss'
})
export class ChatBoxComponent {
  @ViewChild('messageInput') messageInput: ElementRef;
  @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
  @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
  @ViewChild('scrollableDiv') private scrollableDiv: ElementRef;

  user: User;
  private _tagsPanelOverlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isSendButtonDisabled: boolean = true;
  isAIProcessing:boolean=false;
  tagsEditMode: boolean = false;
  selectedKnowledgeBase:any=null;
  selectedModel:string="gpt-4o";
  load_insight: boolean = true;
  load_history: boolean = true;
  load_chat_history: boolean = false;
  tabActive=0; 
  typingSpeed = 10;
  chat: any= {
    messages: []
  };
  models=[
    {
      title:"GPT-4o",
      value:"gpt-4o"
    },
    {
      title:"GPT-4o Mini",
      value:"gpt-4o-mini"
    },
    {
      title:"Claude Sonnet",
      value:"anthropic.claude-3-sonnet-20240229-v1:0"
    },
    {
      title:"Llama 3",
      value:"meta.llama3-70b-instruct-v1:0"
    },
    {
      title:"Mixtral",
      value:"mistral.mixtral-8x7b-instruct-v0:1"
    },
    {
      title:"Claude Haiku",
      value:"anthropic.claude-3-haiku-20240307-v1:0"
    },
  ];
  defaultPrompts:any [] =[
    {
      "message": "Summarize the document in 50 words",
      "icon": "heroicons_outline:clipboard-document-list"
    },
    {
      "message": "List the high-level project requirements",
       "icon": "heroicons_outline:fire"
    },
    {
      "message": "Provide a list of functional requirements",
       "icon": "heroicons_outline:list-bullet"
    }
  ];
  insights: any[]=[];
  capabilities_themes: any[]=[];
  filteredTags:any[]=[];
  alltagTypes:any[]=[];
  chat_history:any[]=[];
  selectedAddInsight:any = {};

  constructor( 
    private _changeDetectorRef: ChangeDetectorRef,
    private _formBuilder: UntypedFormBuilder,
    private _proposalService: ProposalService,
    private _commonService: CommonService,
    private _websocketService: WebsocketService,
    private _userService: UserService,
    private _overlay: Overlay, private _viewContainerRef: ViewContainerRef,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChatBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {proposal: object,document: object,proposalID: string}
  ){
    this._userService.user$
    .pipe((takeUntil(this._unsubscribeAll)))
    .subscribe((user: User) =>
    {
        this.user = user;
    });

    this.selectedKnowledgeBase = this.data.document;
    // console.log(this.data);
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "playground")
    ).subscribe((message) => {
        console.log("Chat Received:", message);
        // this.streamText(message);
        this.streamMessage(message);
        // if(!message.isCompleted){
        //   //let stripContent = this.drawerGroupForm.get('modified').value + message.data;
        //   let lastMessage=this.chat.messages[this.chat.messages.length-1];
        //   lastMessage.value+=message.data;
        //   this._changeDetectorRef.detectChanges();
        // }
        // else if(message.isCompleted){
        //   this.streamText(message);
        // }
    });
    this.initProposalInsights();
    this.getInsights();
    this.getChatHistory();

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "detect_insight_theme")
    ).subscribe((message) => {
        console.log("Chat Received:", message);
        if(message.isCompleted && message.taskID==this.selectedAddInsight.taskID){
          delete this.selectedAddInsight.taskID;
          this.selectedAddInsight['capability_title']=message.data;
          this.onSaveInsightQuestion();
        }
    });
    // console.log(this.data);console.log(this.data.document);console.log(this.data.document['title']);
  }

  ngAfterViewInit() {
    this.attachInputListener();
  }

  attachInputListener() {
    setTimeout(()=>{
      if(this.messageInput && this.messageInput.nativeElement){
        this.messageInput.nativeElement.addEventListener('input', () => {
          const value = this.messageInput.nativeElement.value;//console.log(value);
          if(value.trim().length){
            this.isAIProcessing=false;
            this.isSendButtonDisabled=false;
          }
          else this.isSendButtonDisabled=true;
        });
      }
      this.scrollToBottom();
    },1500);
  }

  // async streamText(message): Promise<void> {
  //   if(this.chat.messages.length){
  //     let lastMessage=this.chat.messages[this.chat.messages.length-1];
  //     if(message.data){
  //       let message_data = message.data;
  //       let text = this.stripHtml(await marked.parse(message_data));
  //       const typingStream = interval(this.typingSpeed).pipe(take(text.length));
  //       typingStream.subscribe((index) => {
  //         lastMessage['value'] += text[index];
  //         if(index === text.length - 1) {
  //           lastMessage['isStreaming'] = false;
  //           this.isSendButtonDisabled=false;
  //           this.isAIProcessing=false;
  //         }
  //       });
  //       this.saveChat(text);
  //     }
  //     this._changeDetectorRef.detectChanges();
  //   }
  // }

  async streamMessage(message): Promise<void> {
    let lastMessage=this.chat.messages[this.chat.messages.length-1];
    let typingSpeed = this.data['accessStatus']!='view'?10:1;
    if(message.data && lastMessage && lastMessage.taskID==message.taskID){
      if (!lastMessage || !lastMessage.editor) {
        console.error('Editor is not initialized for lastMessage');
        return;
      }
      delete lastMessage.taskID;
      const messageHTML = message.data; // Use the full HTML content to stream
      let text = await marked.parse(messageHTML);
      if(this.data['accessStatus']!='view'){
        // Create an observable for streaming
        const typingStream = interval(this.typingSpeed).pipe(take(text.length));
        let currentContent = '';
        // lastMessage['value'] = text;
        // lastMessage.editor.commands.setContent(text, false); 
        typingStream.subscribe((index) => {
          currentContent += text[index]; // Append one character at a time
          lastMessage['value'] += text[index];
          // Update TipTap editor with the current streamed content
          // message.editor.commands.setContent(currentContent, false); // false avoids reinitialization
          lastMessage.editor.commands.setContent(currentContent, false); 
          // When finished streaming
          
          if (index === text.length - 1) {   
            // this.scrollToBottom();       
            lastMessage['isStreaming'] = false;
            this.isSendButtonDisabled=false;
            this.isAIProcessing=false;
            this.saveChat(text);
          }
          setTimeout(()=>{
              this.scrollToBottom();
          },1000);
          this._changeDetectorRef.detectChanges();
        });
      }
      else {
        lastMessage['isStreaming'] = false;
        lastMessage['value'] = text;
        lastMessage.editor.commands.setContent(text, false); 
        this.isSendButtonDisabled=false;
        this.isAIProcessing=false;
        this.saveChat(text);
        setTimeout(()=>{
          this.scrollToBottom();
        },1000);
        this._changeDetectorRef.detectChanges();
      }
    }
  }

  getInsights(){
    this.insights = [];
    this.load_insight = true;
    this._proposalService.getDocInsights(this.data.proposalID,this.selectedKnowledgeBase._id).subscribe(
      (value) =>
        {
          // console.log(value);
          if(value.success && value.data.length){
            // this.capabilities_themes=[];
            value.data.forEach(element => {
              // this.capabilities_themes.push({label:element.capability,value:element._id});
              element.questions.forEach(eachquestion => {
                if(eachquestion.notes){
                  eachquestion['insightID']=element._id;
                  eachquestion['capability']=element.capability;
                  eachquestion['created'] = (eachquestion.created)?eachquestion.created:element.created;
                  eachquestion['author'] = (eachquestion.author)?eachquestion.author:element.authorDetails;
                  eachquestion['copied'] = false;
                  eachquestion['loader_delete'] = false;
                  eachquestion['loader_theme'] = false;
                  eachquestion['editor']= new Editor({
                    content: eachquestion.notes,
                    extensions: [StarterKit],
                    editable: false, // Set to false for read-only state
                  })
                  this.insights.push(eachquestion);
                }
              });
            });
          }
          this.load_insight = false;
          this._changeDetectorRef.detectChanges();
        },
        (response) =>
        {
        },
    );
  }

  getChatHistory(){
    this.chat_history = [];
    this._proposalService.getDocHistory(this.data.proposalID,this.selectedKnowledgeBase._id).subscribe(
      (value) =>
        {
          if(value.success && value.data.length){
            this.chat_history=value.data;
           
          }
          this.load_history = false;
          
          
        },
        (response) =>
        {
        },
    );
  }

  initProposalInsights(){
    this._proposalService.getProposalInsights(this.data.proposalID).subscribe((response)=>{
      if(response.success==true){
        this.capabilities_themes=[];
        for(let insight of response.data){
          this.capabilities_themes.push({label:insight.capability,value:insight._id});
        }
        if(this.capabilities_themes.length)this.filteredTags = this.capabilities_themes;
      }
    });
  }

  stripHtml(html: string): string {
    // Create a new DOM element and set the innerHTML to the parsed HTML
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
  
    // Use textContent to get the plain text without HTML tags
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  onEnter(event: KeyboardEvent) {
    //console.log(event,this.isSendButtonDisabled,this.isAIProcessing);
    if (event.key === 'Enter' && !this.isSendButtonDisabled && !this.isAIProcessing) {
        event.preventDefault(); // Prevents a new line being added in the textarea
        this.onSendMessage();
    }
  }

  scrollToBottom(): void {
    try {
      if(this.scrollableDiv && this.scrollableDiv.nativeElement)
      this.scrollableDiv.nativeElement.scrollTo({
        top: this.scrollableDiv.nativeElement.scrollHeight,
        behavior: 'smooth'
      });
    } catch (err) {
    }
  }

  onSendMessage(){
    if(this.messageInput.nativeElement.value===""){
      return;
    }
    this.isSendButtonDisabled=true;
    this.isAIProcessing=true;
    let userPrompt=this.messageInput.nativeElement.value;

    this.chat.messages.push({
      value: this.messageInput.nativeElement.value,
      createdAt: new Date(),
      isMine: true,
      type: 'question',
      isStreaming: false
    });
    let taskID = 'answer'+this.chat.messages.length + '123';
    setTimeout(()=>{
        this.chat.messages.push({
          value: "",
          createdAt: new Date(),
          isMine: false,
          copied: false,
          added: false,
          loader_add: false,
          type: 'answer',
          isStreaming: true,
          taskID: taskID,
          editor: new Editor({
            content: '',
            extensions: [StarterKit],
            editable: false, // Set to false for read-only state
          })
        });
        this._websocketService.sendPlaygroundCommand(userPrompt,this.selectedModel,(this.selectedKnowledgeBase && this.selectedKnowledgeBase._id!=null)?this.selectedKnowledgeBase._id:"",taskID,this.selectedKnowledgeBase.embeddingsType,this.selectedKnowledgeBase.document_type);
        this.messageInput.nativeElement.value="";
        this._changeDetectorRef.detectChanges();
        this.scrollToBottom();
    },500);
  }

  onPromptSend(index){
    let userPrompt=this.defaultPrompts[index].message;
    this.messageInput.nativeElement.value = userPrompt;
    this.messageInput.nativeElement.focus();
    this.isSendButtonDisabled=false;
    this.isAIProcessing=false;
    // this.chat.messages.push({
    //   value: userPrompt,
    //   createdAt: new Date(),
    //   isMine: true,
    //   type: 'question',
    //   isStreaming: false
    // });
    // setTimeout(()=>{
    //   this.chat.messages.push({
    //     value: "",
    //     createdAt: new Date(),
    //     isMine: false,
    //     copied: false,
    //     added: false,
    //     type: 'answer',
    //     isStreaming: true,
    //     editor: new Editor({
    //       content: '',
    //       extensions: [StarterKit],
    //       editable: false, // Set to false for read-only state
    //     })
    //   });
    // },500);
    // this._websocketService.sendPlaygroundCommand(userPrompt,this.selectedModel,(this.selectedKnowledgeBase && this.selectedKnowledgeBase._id!=null)?this.selectedKnowledgeBase._id:"","123",this.selectedKnowledgeBase.embeddingsType,this.selectedKnowledgeBase.document_type);
    // this._changeDetectorRef.detectChanges();
  }

  tabActivefunc(tab){
    this.tabActive = tab;
    if(tab===0)this.attachInputListener();
  }

  displaySelectedHistory(selectedDate){
    this.chat.messages = [];
    this.load_chat_history = true;
    this.isAIProcessing=true;
    this.isSendButtonDisabled=true;
    this.tabActivefunc(0);
    this._proposalService.getDocHistoryByDate(this.data.proposalID,this.selectedKnowledgeBase._id,selectedDate).subscribe(
      (value) =>
        {
          if(value.success && value.data.length){
            value.data.forEach(element => {
              if(element.type=='question')this.chat.messages.push(element);
              else if(element.type=='answer'){
                element.editor= new Editor({
                  content: element.value,
                  extensions: [StarterKit],
                  editable: false, // Set to false for read-only state
                })
                this.chat.messages.push(element);
              }
            });
            // this.chat.messages = value.data;
            this.isAIProcessing=false;
          }
          this.load_chat_history = false;
        },
        (response) =>
        {
        },
    );
    this.attachInputListener();
    
  }

  async copyMessage(index) {
    let userPrompt=this.chat.messages[index].value;
    let text_value = this.stripHtml(await marked.parse(userPrompt));
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text_value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.chat.messages[index]['copied'] = true;
    setTimeout(()=>{
      this.chat.messages[index]['copied'] = false;
    },1000);
  }

  copyInsight(index) {
    let userPrompt=this.insights[index].notes;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = userPrompt;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.insights[index]['copied'] = true;
    setTimeout(()=>{
      this.insights[index]['copied'] = false;
    },1000);
  }

  onDeleteInsightQuestion(index){
    let selectedInsight = this.insights[index];
    this.insights[index]['loader_delete'] = true;
    this._proposalService.deleteInsightQuestion(selectedInsight.insightID,selectedInsight._id).subscribe((response)=>{
      if(response.success==true){
        // this.insights.splice(index,1);
        this.getInsights();
      }else{

      }
    });
  }

  regenerate(index){
    this.isAIProcessing=true;
    let userPrompt=this.chat.messages[index].value;
    let taskID = 'answer'+this.chat.messages.length + '123';
    setTimeout(()=>{
      this.chat.messages.push({
        value: "",
        createdAt: new Date(),
        isMine: false,
        copied: false,
        added: false,
        loader_add: false,
        type: 'answer',
        isStreaming: true,
        taskID: taskID,
        editor: new Editor({
          content: '',
          extensions: [StarterKit],
          editable: false, // Set to false for read-only state
        })
      });
      this._websocketService.sendPlaygroundCommand(userPrompt,this.selectedModel,(this.selectedKnowledgeBase && this.selectedKnowledgeBase._id!=null)?this.selectedKnowledgeBase._id:"",taskID,this.selectedKnowledgeBase.embeddingsType,this.selectedKnowledgeBase.document_type);
      this.messageInput.nativeElement.value="";
      this._changeDetectorRef.detectChanges();
      this.scrollToBottom();
    },500);
  }

  addInsight(currentIndex){
    let addAnswer=this.chat.messages[currentIndex].value;
    this.chat.messages[currentIndex].loader_add = true;
    let previousQuestion = this.findPreviousQuestion(currentIndex);
    let capabilities = this.capabilities_themes.map(el=>el.label);
    let taskID = 'addInsight' + currentIndex;
    this.selectedAddInsight = {question:previousQuestion.value,answer:addAnswer,taskID:taskID,currentIndex:currentIndex};
    // console.log(this.selectedAddInsight);console.log(capabilities);
    this._websocketService.getInsightTheme(previousQuestion.value,taskID,capabilities);
  }

  onSaveInsightQuestion(){
    this.capabilities_themes =JSON.parse(JSON.stringify(this.capabilities_themes));
    let associatedDocs=this.data.proposal['supporting_docs'].map((doc)=>doc._id);
    console.log(this.capabilities_themes,this.selectedAddInsight['capability_title']);
    let find_capability = this.capabilities_themes.find(el=>el.label.toLowerCase().trim()==this.selectedAddInsight['capability_title'].toLowerCase().trim());
    console.log(find_capability);
    if(find_capability){
      this._proposalService.createUpdateInsightQuestion(find_capability.value,this.selectedAddInsight.question,this.selectedAddInsight.answer,associatedDocs,'',[this.selectedKnowledgeBase._id],find_capability.value,true,this.selectedKnowledgeBase._id).subscribe((response)=>{
        this.chat.messages[this.selectedAddInsight.currentIndex].loader_add = false;
        if(response.success){
          this.getInsights();
          this.chat.messages[this.selectedAddInsight.currentIndex].added = true;
          this._proposalService.saveInteraction({proposal:this.data.proposalID,supporting_doc:this.selectedKnowledgeBase._id,insights:1}).subscribe((response)=>{
            if(response.success==true){
              this.getChatHistory();
            }
          });
          setTimeout(()=>{
            this.chat.messages[this.selectedAddInsight.currentIndex].added = false;
          },1000);
        }
      });
    }
    else this.chat.messages[this.selectedAddInsight.currentIndex].loader_add = false;
  }

  getFileTypeString(type:number){
    let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.value===type);
    return fileValue.label;
  }

  getFileClass(fileType: number): string {
    switch (fileType) {
      case 0: return 'bg-red-600';
      case 1: return 'bg-green-600';
      case 2: return 'bg-gray-600';
      case 3: return 'bg-blue-600';
      case 4: return 'bg-blue-500';
      case 5: return 'bg-yellow-600';
      case 6: return 'bg-yellow-500';
      case 7: return 'bg-orange-600';
      case 8: return 'bg-orange-500';
      case 9: return 'bg-amber-600';
      case 10: return 'bg-green-600';
      default: return 'bg-gray-600';
    }
  }

  findPreviousQuestion(currentIndex: number): any | null {
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (this.chat.messages[i].type === 'question') {
        return this.chat.messages[i];
      }
    }
    return null;
  }

  saveChat(text){
    let lastMessageAnswer=text;
    let questionList=this.chat.messages.filter(el=>el.type=='question');
    let lastMessageQuestion = questionList[questionList.length-1];
    this._proposalService.saveInteraction({proposal:this.data.proposalID,question:lastMessageQuestion.value,answer:lastMessageAnswer,supporting_doc:this.selectedKnowledgeBase._id}).subscribe((response)=>{
      if(response.success==true){
        this.getChatHistory();
      }
    });
  }

  format(date,type){
    if(type=='initial')
      return moment(date).format('DD MMM YYYY');
    else if(type=='comma')
      return moment(date).format('DD MMM, YYYY');
  }

  formatDate(date){
    date = moment(date);
    return date.format('DD MMM, YYYY');
  }

  /**
     * Open tags panel
     */
  openTagsPanel(): void
  {
      // Create the overlay
      this._tagsPanelOverlayRef = this._overlay.create({
          backdropClass   : '',
          hasBackdrop     : true,
          scrollStrategy  : this._overlay.scrollStrategies.block(),
          positionStrategy: this._overlay.position()
              .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
              .withFlexibleDimensions(true)
              .withViewportMargin(64)
              .withLockedPosition(true)
              .withPositions([
                  {
                      originX : 'start',
                      originY : 'bottom',
                      overlayX: 'start',
                      overlayY: 'top',
                  },
              ]),
      });

      // Subscribe to the attachments observable
      this._tagsPanelOverlayRef.attachments().subscribe(() =>
      {
          // Focus to the search input once the overlay has been attached
          this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
      });

      // Create a portal from the template
      const templatePortal = new TemplatePortal(this._tagsPanel, this._viewContainerRef);

      // Attach the portal to the overlay
      this._tagsPanelOverlayRef.attach(templatePortal);

      // Subscribe to the backdrop click
      this._tagsPanelOverlayRef.backdropClick().subscribe(() =>
      {
          // If overlay exists and attached...
          if ( this._tagsPanelOverlayRef && this._tagsPanelOverlayRef.hasAttached() )
          {
              // Detach it
              this._tagsPanelOverlayRef.detach();

              // Reset the tag filter
              this.filteredTags = this.capabilities_themes;
              //console.log(this.filteredTags);
              // Toggle the edit mode off
              this.tagsEditMode = false;
          }

          // If template portal exists and attached...
          if ( templatePortal && templatePortal.isAttached )
          {
              // Detach it
              templatePortal.detach();
          }
      });
  }

  /**
     * Filter tags
     *
     * @param event
     */
  filterTags(event): void
  {
      // Get the value
      const value = event.target.value.toLowerCase();

      // Filter the tags
      this.filteredTags = this.capabilities_themes.filter(tag => tag.label.toLowerCase().includes(value));
  }

  /**
     * Should the create tag button be visible
     *
     * @param inputValue
     */
  shouldShowCreateTagButton(inputValue: string): boolean
  {
      return !!!(inputValue === '' || this.alltagTypes.findIndex(tag => tag.label.toLowerCase() === inputValue.toLowerCase()) > -1);
  }

  /**
     * Filter tags input key down event
     *
     * @param event
     */
  filterTagsInputKeyDown(event): void
  {
      // Return if the pressed key is not 'Enter'
      if ( event.key !== 'Enter' )
      {
          return;
      }

      // If there is no tag available...
      if ( this.filteredTags.length === 0 )
      {
          // Create the tag
          // this.createTag(event.target.value);

          // Clear the input
          event.target.value = '';

          // Return
          return;
      }

      // If there is a tag...
      const tag = this.filteredTags[0];
      const isTagApplied = this.capabilities_themes.find(id => id === tag._id);
  }

  toggleTaskTag(event,eachinsight,index): void
  {   console.log(this.insights[index]);
    let selectedInsight = {};
    this.insights[index].loader_theme = true;
    this.filteredTags = this.capabilities_themes.filter(tag => !tag.label.toLowerCase().includes(event.value));
    let selectedCapability = this.capabilities_themes.find(tag => tag.label==event.value);
    this.insights[index].capability= selectedCapability.label;
    let questionData=this.insights[index];
    let oldInsight=questionData.insightID;
    let linkedDocs=questionData.linkedDocs;
    let associatedDocs=this.data.proposal['supporting_docs'].map((doc)=>doc._id);

    if(questionData.insightID!=selectedCapability.value){
      selectedInsight=this.capabilities_themes.find((insight)=>insight.insightID==selectedCapability.value);
    }

    // Toggle the edit mode off
    this.tagsEditMode = false;

    this._proposalService.createUpdateInsightQuestion(selectedCapability.value,questionData.title,questionData.notes,associatedDocs,questionData._id,linkedDocs,oldInsight,true,questionData.reference).subscribe((response)=>{
      if(response.success==true){
        this.insights[index].insightID = selectedCapability.value;
        this.insights[index]._id = response.id;
        // this.getInsights();
      }
      this.insights[index].loader_theme = false;
    });    
  }

  closeRef(){
    this.dialogRef.close();
  }

}
