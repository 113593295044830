import { ProgressEventTracker } from './../../../core/common/progress_event_tracker';
import { NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EventService } from 'app/core/common/event.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { filter, Subscription } from 'rxjs';


@Component({
  selector: 'app-regenerating-progress-bar',
  standalone: true,
  imports: [MatIconModule,MatProgressBarModule,NgStyle],
  templateUrl: './regenerating-progress-bar.component.html',
  styleUrl: './regenerating-progress-bar.component.scss'
})
export class RegeneratingProgressBarComponent {

  progressValue=100;
  private subscription: Subscription;

  generating:string="Initializing Agents";
  eventsCaptured=[];
  isProcessing:boolean=false;
  currentagentid:string;

  @Input()
  type=0;

  @Input()
  taskID:string="";

  @Input()
  private _generationType: string = "";
  
  @Input()
  set generationType(value: string) {
    this._generationType = value;
    this.initLastProgressEvent();
  }
  
  get generationType(): string {
    return this._generationType;
  }

  @Input()
  set restart(value: boolean) {
     // Add logic here if needed, for example:
     if (value) {
        this.init();
     }
  }
  private _restart: boolean;
  
  get restart(): boolean {
     return this._restart;
  }
  @Input() set generatingtitle(value: any) {
    if (value) {
      this.generating='Extracting ' + value;
    }
  }
  @Input() set agentid(value: any) {
    if (value) {
      this.currentagentid=value;
    }
  }

  constructor(private progressTracker:ProgressEventTracker,private websocketService:WebsocketService,private eventService: EventService,private _changeDetectorRef: ChangeDetectorRef,){

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "epic_solutions_regenerated")
    ).subscribe((message:any) => {

      if(this.generationType!="epic"){
        return;
      }

      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }

      this.saveProgressEvent("Regenerating Solutions");

      console.log("Epic Solutions Regenerated:",message);
      if(!this.eventsCaptured.includes("solutions")){
      this.eventsCaptured.push("solutions");
      this.generating="Regenerating Solutions";
      }
      this._changeDetectorRef.detectChanges();
    });


    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "features_regenerated")
    ).subscribe((message:any) => {
      if(this.generationType!="epic" && this.generationType!="theme"){
        return;
      }
      console.log("Features Regenerated:",message);

      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.saveProgressEvent("Regenerating Features");
      if(!this.eventsCaptured.includes("features")){
      this.eventsCaptured.push("features");
      this.generating="Regenerating Features";
      
      }
      this._changeDetectorRef.detectChanges();
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "stories_regenerated")
    ).subscribe((message:any) => {
      console.log("Stories Regenerated:",message);

      if(this.generationType!="epic" && this.generationType!="theme"){
        return;
      }


      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.saveProgressEvent("Regenerating User Stories");
      if(!this.eventsCaptured.includes("stories")){
      this.eventsCaptured.push("stories");
      this.generating="Regenerating User Stories";
      
      }
      
      this._changeDetectorRef.detectChanges();
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "testcases_regenerated")
    ).subscribe((message:any) => {
      if(this.generationType!="epic" && this.generationType!="theme"){
        return;
      }
      console.log("Test Cases Regenerated:",message);
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }

      this.saveProgressEvent("Regenerating Test Cases");
      if(!this.eventsCaptured.includes("testcases")){
      this.eventsCaptured.push("testcases");
      this.generating="Regenerating Test Cases";

      }
      this._changeDetectorRef.detectChanges();
    });
    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "metadata_list_generated")
    ).subscribe((message) => {
      if(this.generationType!="metadata"){
        return;
      }
       if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      if(message.taskID==this.currentagentid && !this.eventsCaptured.includes("metadata_list_generated")){
        this.eventsCaptured.push("metadata_list_generated");
        this.generating="Extracting Metadata";
      }
      this.saveProgressEvent("Extracting Metadata");
      this._changeDetectorRef.detectChanges();
    });
    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "validate_associated_doc")
    ).subscribe((message) => {
      if(this.generationType!="metadata"){
        return;
      }
      // console.log(message);
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      if(message.taskID==this.currentagentid && !this.eventsCaptured.includes("validate_associated_doc")){
        this.eventsCaptured.push("validate_associated_doc");
        this.generating="Extracting Metadata";
      }
      this.saveProgressEvent("Extracting Metadata");
      this._changeDetectorRef.detectChanges();
    });
    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "create_vector")
    ).subscribe((message) => {

      // console.log(message);
      // if(message.data)this.generating=message.data['fullName'] + ' extraction done';
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "processing_metadata")
    ).subscribe((message) => {
      if(this.generationType!="metadata"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
        // console.log("Received:", message);
        if(message.taskID==this.currentagentid && message.data)this.generating='Extracting ' + message.data['metadataName'];
    
        this.saveProgressEvent("Extracting "+message.data['metadataName']);
        this._changeDetectorRef.detectChanges();
      });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "processing_metadata_complete")
    ).subscribe((message) => {
      if(this.generationType!="metadata"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
        // console.log("Received:", message);
        if(message.taskID==this.currentagentid && message.data)this.generating='Extraction finished';
    
        this.saveProgressEvent("Extraction finished");
        this._changeDetectorRef.detectChanges();
      });



    //Level Level Solution Generation Callbacks

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_low_level_solution")
    ).subscribe((message:any) => {
      console.log("generating_low_level_solution:",message);
      if(this.generationType!="lls" && this.generationType!="story-lls"){
        return;
      }
      console.log("Generating LLS:",message);
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Generating low level solutions";
      this.saveProgressEvent("Generating low level solutions");
      this._changeDetectorRef.detectChanges();

    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "refining_low_level_solution_metadata")
    ).subscribe((message:any) => {
      console.log("refining_low_level_solution_metadata:",message);
      if(this.generationType!="lls" && this.generationType!="story-lls"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Analyzing org metadata";
      this.saveProgressEvent("Analyzing org metadata");
      this._changeDetectorRef.detectChanges();
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "aligning_low_level_solution_data_models")
    ).subscribe((message:any) => {
      console.log("aligning_low_level_solution_data_models:",message);
      if(this.generationType!="lls" && this.generationType!="story-lls"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Aligning with standard data models";
      this.saveProgressEvent("Aligning with standard data models");
      this._changeDetectorRef.detectChanges();

    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "revalidating_low_level_solution")
    ).subscribe((message:any) => {
      console.log("revalidating_low_level_solution:",message);
      if(this.generationType!="lls" && this.generationType!="story-lls"){
        return;
      }
      
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Revalidating solutions";
      this.saveProgressEvent("Revalidating solutions");
      this._changeDetectorRef.detectChanges();

    });


    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_low_level_solution_story_summary")
    ).subscribe((message:any) => {
      console.log("generating_low_level_solution_story_summary:",message);
      if(this.generationType!="lls" && this.generationType!="story-lls"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Generating stories solution summary";
      this.saveProgressEvent("Generating stories solution summary");
      this._changeDetectorRef.detectChanges();

    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_low_level_solution_epic_summary")
    ).subscribe((message:any) => {
      console.log("generating_low_level_solution_epic_summary:",message);
      if(this.generationType!="lls" && this.generationType!="story-lls"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Generating epic solution summary";
      this.saveProgressEvent("Generating epic solution summary");
      this._changeDetectorRef.detectChanges();
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_solutions")
    ).subscribe((message:any) => {
      console.log("generating_solutions:",message);
      if(this.generationType!="epic" && this.generationType!="theme"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Generating solutions";
      this.saveProgressEvent("Generating solutions");

      this._changeDetectorRef.detectChanges();

    });


    

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "saving_low_level_solution")
    ).subscribe((message:any) => {
      console.log("saving_low_level_solution:",message);
      if(this.generationType!="lls" && this.generationType!="story-lls"){
        return;
      }
      
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Finalizing solutions";
      this._changeDetectorRef.detectChanges();
    });

    

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "analyzing_epic_solutions")
    ).subscribe((message) => {
      console.log("analyzing_epic_solutions:",message);
      if(this.generationType!="workbook" && this.generationType!="story-workbook"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Analyzing epic solutions";
      this.saveProgressEvent("Analyzing epic solutions");
      this._changeDetectorRef.detectChanges();
    });


    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_configuration_workbook")
    ).subscribe((message) => {
      console.log("generating_configuration_workbook:",message);
      if(this.generationType!="workbook" && this.generationType!="story-workbook" && this.generationType!="build"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Generating configuration workbook";
      this.saveProgressEvent("Generating configuration workbook");
      this._changeDetectorRef.detectChanges();
    });



    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "configuration_workbook_started")
    ).subscribe((message) => {
      console.log("configuration_workbook_generated:",message);
      if(this.generationType!="workbook" && this.generationType!="story-workbook" && this.generationType!="build"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="Generating Configuration Workbook";
      this.saveProgressEvent("Generating Configuration Workbook");
      this._changeDetectorRef.detectChanges();
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "configuration_workbook_generated")
    ).subscribe((message) => {
      console.log("configuration_workbook_generated:",message);
      if(this.generationType!="workbook" && this.generationType!="story-workbook" && this.generationType!="build"){
        return;
      }
      if(this.taskID!=="" && message.taskID!=this.taskID){
        return;
      }
      this.generating="";
      this.saveProgressEvent("");
      this._changeDetectorRef.detectChanges();
    });



  }

  init(){
    //Type 0=proposal , 1=project , 2=testing
    this.eventsCaptured=[];
    this.generating="Initializing Agents";
    
   
     
  }


  saveProgressEvent(event:string){
    this.progressTracker.saveProgressEvent(this.generationType,this.taskID,event);
  }
  initLastProgressEvent(){
    setTimeout(()=>{
    let lastEvent=this.progressTracker.getProgressEvents(this.generationType,this.taskID);
    if(lastEvent && lastEvent!=""){
      this.generating=lastEvent;
      this._changeDetectorRef.detectChanges();
    }
    },1000);
  }


  initProgress(){


    let completed=0;

      if(this.type==0){
        //Proposal
        this.progressValue=this.eventsCaptured.length*0.5;

      }else if(this.type==1){
        //Project
        this.progressValue=this.eventsCaptured.length*0.33;
      }else{
        //test plan
        this.progressValue=this.eventsCaptured.length*0.25;
      }

      this.progressValue=Math.min(
        Math.ceil(completed),
        99
      );

  }


  delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

}
