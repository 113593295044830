 

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'themeDrawer'"
    [position]="'right'"
    (openedChanged)="openedChanged($event)"

    #themeDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card">
 


            <div class="flex items-center justify-between mt-5 ml-3 mr-3">

            <ng-container>
                <div class="flex items-center justify-center">
                    <ng-container>
                        <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                    </ng-container>
                    

                    <span class="ml-2 font-semibold text-lg text-black">{{ isEdit?'Edit':'Add' }} Theme</span>
                </div>
            </ng-container>

            <div class="flex items-center">
                <!-- More menu -->
                <button
                    *ngIf="false"
                    mat-icon-button
                    [matMenuTriggerFor]="moreMenu">
                    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
                </button>
                <mat-menu #moreMenu="matMenu">
                    <button
                        mat-menu-item
                        (click)="onDeleteTheme()"
                       >
                        <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                        <span>Delete Theme</span>
                    </button>
                </mat-menu>

                <!-- Close button -->
                <button
                    mat-icon-button
                    (click)="themeDrawer.toggle()"
                    >
                    <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>

            

        </div>
       
        <form
        class="flex flex-col flex-auto p-6 pt-10 sm:p-8 sm:pt-10"
        [formGroup]="themeForm">
       
         <div>
            <mat-form-field
                class="w-full"
                >
                <mat-label class="font-semibold subtitle text-black">Theme Name</mat-label>
                <input
                    matInput
                    [formControlName]="'themeName'"
                    >

            </mat-form-field>
        </div>

        <!-- Business Capability -->
        <!-- <div class="mt-5 w-full">
        <mat-form-field class="w-full" *ngIf="selectedInsight!=null">
            <mat-label class="font-semibold">Business Capability</mat-label>
            <mat-select formControlName="capability" (selectionChange)="onChangeCapabilitySelection($event)">
              @for (capability of capabilities; track capability) {
                <mat-option  [value]="capability.value">{{capability.label}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div> -->

 
       

        

       

        <div class="flex w-full justify-end mr-5 mt-5">
            <button
              mat-flat-button
              [disabled]="isSavingTheme || themeForm.invalid"
              (click)="onSaveThemeEvent()"
              class="bg-[#4F45E4] text-white text-lg font-semibold mt-5 py-3 px-10 rounded-lg"
              [class.disabled]="isSavingTheme || themeForm.invalid">
              Save
            </button>
          </div>
          <div class="my-1 mb-2" *ngIf="themeSaved">
            <fuse-alert
            [appearance]="'soft'"
            [showIcon]="false"
            type="success"
            [dismissible]="true" [dismissed]="false">
            Theme saved successfully!
        </fuse-alert>
        </div>
        

</form>
    </div>

</fuse-drawer>
