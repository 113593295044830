import { tags } from './../../../../mock-api/apps/tasks/data';
import { trigger } from '@angular/animations';
import { Component, ElementRef, Inject, ViewChild,TemplateRef,ViewContainerRef } from '@angular/core';

import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Overlay, OverlayRef,OverlayContainer } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JIRA } from 'app/core/common/constants';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { DatePipe, NgIf,NgFor, NgClass } from '@angular/common';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { DialogRef } from '@angular/cdk/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertType } from '@fuse/components/alert';
import { CommonService } from 'app/core/common/common.service';
import { firstValueFrom } from 'rxjs';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { environment } from 'environments/environment';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
import { NgxTiptapModule } from 'ngx-tiptap';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
@Component({
  selector: 'app-select-story',
  standalone: true,
  imports: [MatIconModule,NgxTiptapModule,NgIf,MatProgressSpinnerModule,DatePipe,NgFor,NgClass,ReactiveFormsModule,MatDialogModule,MatIcon,MatMenuModule,MatInputModule,MatSelectModule,MatButtonModule,MatOptionModule,MatCheckboxModule,MatFormFieldModule,MatRadioModule],
  templateUrl: './select-story.component.html',
  styleUrl: './select-story.component.scss'
})
export class SelectStoryComponent {

  headerForm:UntypedFormGroup;
  proposal: any = {};
  _testplanDetail: any = {};
  is_write_allowed: boolean = true;
  loader_data: boolean = true;
  all_original_docs:any=[];
  all_tags:any [] = [];
  all_themes:any [] = [];
  storyList:any[] = [];
  all_epics:any[]=[];
  all_types:any[]=[
    {value:'Acceptance',label:"Acceptance"}, 
    {value:'Accessibility',label:"Accessibility"}, 
    {value:'Compatibility',label:"Compatibility"},
    {value:'Destructive',label:"Destructive"},
    {value:'Functionality',label:"Functionality"},
    {value:'Performance',label:"Performance"},
    {value:'Regression',label:"Regression"},
    {value:'Security',label:"Security"},
    {value:'Smoke & Sanity',label:"Smoke & Sanity"},
    {value:'Usability',label:"Usability"}
  ];

  constructor(
      private _formBuilder: UntypedFormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<SelectStoryComponent>,
      private atlassianService:AtlassianService
  ){
    // console.log("data:",data);
    if(data){
      this.proposal=data.proposal;
      this.all_themes = data.themeTypes;
      this._testplanDetail = data._testplanDetail;
      this.all_epics=[];
      for(let epic of this.proposal.epics){
        let epics= epic.epics.map((themeEpic) => {
          return { label: themeEpic.name, value: themeEpic.name };
        });
        this.all_epics.push(...epics);
      }
      this.initProjectStories();
    }
  }

  ngOnInit(){
    this.headerForm = this._formBuilder.group({
      search : [''],
      selected_tag : [''],
      selected_theme : [''],
      selected_epic : [''],
    });
  }

  initProjectStories(){
    this.atlassianService.getProjectStories(this.proposal._id).subscribe((response)=>{
      if(response.success){
        this.storyList=response.data;
        this.all_original_docs=response.data;
        // console.log(response.data);
      }
      this.loader_data = false;
    });
  }

  filterCount(){
    let all_docs = JSON.parse(JSON.stringify(this.all_original_docs));
    let tag_collection = this.headerForm.get('selected_tag').value || [];
    let theme_collection = this.headerForm.get('selected_theme').value || [];
    let epic_collection = this.headerForm.get('selected_epic').value || [];
    let search_term = this.headerForm.get('search').value?.toLowerCase() || '';

    this.storyList = all_docs.filter(element => {
      // Filter by tags if selected
      const matchesTags = !tag_collection.length || (element.tags && element.tags.some(tag => tag_collection.includes(tag)));
  
      // Filter by theme if selected
      const matchesTheme = !theme_collection.length || (element.theme && theme_collection.includes(element.theme));
  
      // Filter by epic if selected
      const matchesEpic= !epic_collection.length || (element.epic && epic_collection.includes(element.epic));
  
      // Filter by search term if provided
      const matchesSearchTerm = !search_term || (element.title && element.title.toLowerCase().includes(search_term)) || (element.storyID && element.storyID.toLowerCase().includes(search_term)) || (element.theme && element.theme.toLowerCase().includes(search_term)) || (element.epic && element.epic.toLowerCase().includes(search_term));
  
      // Include element only if it matches all criteria
      return matchesTags && matchesTheme && matchesEpic && matchesSearchTerm;
    });
  }

  onChangeTheme(event){
    let selectedThemes=event.value;

    if(selectedThemes.length){
      this.all_epics=[];
      for(let epic of this.proposal.epics){
        if(selectedThemes.includes(epic.theme)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic.name };
          });
          this.all_epics.push(...epics);
        }
      }
    }
  }

  closeRef(){
    this.dialogRef.close();
  }

  selectStory(eachstory){
    this.dialogRef.close(eachstory);
  }

}
