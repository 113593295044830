<div class="featureTableCard flex flex-auto flex-col min-w-240 relative shadow">
    <span class="close-dialog"  [mat-dialog-close]="true">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">
        <div class="formBox">
            <div class="flex font-bold justify-center gap-2 text-4xl settingHead">
                <h2>{{type=='proposal'?'Proposal':(type=='project'?'Project':(type=='testing'?'Testing':'Template'))}} Settings</h2>
            </div>

            <div *ngIf="(type=='proposal' || type=='project' || type=='testing')" class="flex flex-col sm:flex-row p-8 m-p-0">
                <form class="flex flex-col w-full" [formGroup]="tabGroupForm">
                    <div class="flex flex-auto flex-wrap">
                        <div class="grid sm:grid-cols-4 gap-8 w-full mt-8">
                            <div class="sm:col-span-2">
                                <mat-form-field class="w-full">
                                    <mat-label>{{(type=='proposal'?'Proposal':(type=='project'?'Project':'Project'))}} Name</mat-label>
                                    <input
                                        matInput formControlName="name" required>
                                </mat-form-field>
                            </div>
                        
                            <div class="sm:col-span-2" *ngIf="(type != 'testing')">
                                <label class="block font-medium mb-1">{{(type=='proposal'?'Proposal':(type=='project'?'Project':'Testing'))}} Status</label>
                                <p class="statusBox">{{getStatus(tabGroupForm.get('working_status').value)}}</p>
                                <!-- <mat-form-field class="w-full">
                                    <mat-label>Proposal Status</mat-label>
                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:queue-list'" ></mat-icon>
                                    <mat-select formControlName="working_status" [placeholder]="'Select Option'" required>
                                        @for (option of proposalStatusTypes; track option) {
                                            <mat-option [value]="option.value">{{option.label}}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field> -->
                            </div>

                            <div class="sm:col-span-2">
                                <mat-form-field class="w-full">
                                    <mat-label>Client Name</mat-label>
                                    <input
                                        matInput formControlName="client_name">
                                </mat-form-field>
                            </div>
                        
                            <div class="flex flex-col justify-end profileText sm:col-span-2">
                                <h3>Last Updated On</h3>
                                <p>{{tabGroupForm.get('updated').value}}</p>
                            </div>

                        </div> 

                       

                        <div class="buttonSec mt-6 flex flex-auto justify-end"> 

                            <button
                                class="px-8 mr-2 "
                                mat-flat-button
                                [color]="'accent'"
                                type="button" 
                                [mat-dialog-close]="true"
                                >
                                Close
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'" [disabled]="(tabGroupForm.invalid || assumption_loader)"
                                type="button" (click)="save()"
                                >
                                <mat-progress-spinner
                                *ngIf="assumption_loader"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                                Save
                            </button>
                            
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="type=='template'" class="flex flex-col sm:flex-row p-8 m-p-0">
                <form class="flex flex-col w-full" [formGroup]="tabGroupForm">
                    <div class="flex flex-auto flex-wrap">
                        <div class="grid sm:grid-cols-4 gap-8 w-full mt-8">
                            <div class="sm:col-span-2">
                                <div class="w-full">
                                    <span class="font-medium">Template Name</span>
                                    <span> - {{stripHTML(proposal_detail.name)}}</span>
                                </div>
                            </div>
                        
                            <div class="sm:col-span-2">
                                <div class="w-full">
                                    <span class="font-medium">Technology</span>
                                    <span> - {{proposal_detail.proposal_meta.technology}}</span>
                                </div>
                            </div>

                        </div> 

                        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                            <div class="sm:col-span-2">
                                <mat-form-field class="w-full">
                                    <mat-label>Proposal Name</mat-label>
                                    <input
                                        matInput formControlName="name" required>
                                </mat-form-field>
                            </div>
                            <div class="sm:col-span-2">
                                <mat-form-field class="w-full">
                                    <mat-label>Client Name</mat-label>
                                    <input
                                        matInput formControlName="client_name">
                                </mat-form-field>
                            </div>
                        </div> 

                        <div class="buttonSec mt-6 flex flex-auto justify-end"> 

                            <button
                                class="px-8 mr-2 "
                                mat-flat-button
                                [color]="'accent'"
                                type="button" 
                                [mat-dialog-close]="true"
                                >
                                Close
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'" [disabled]="(tabGroupForm.invalid || assumption_loader)"
                                type="button" (click)="createTemplate()"
                                >
                                <mat-progress-spinner
                                *ngIf="assumption_loader"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                                Save
                            </button>
                           
                        </div>
                        <div class="flex mt-4 w-full" *ngIf="flashMessageType">
                            <ng-container *ngIf="flashMessageType === 'success'">
                                <mat-icon
                                    class="text-green-500"
                                    [svgIcon]="'heroicons_outline:check'"></mat-icon>
                                <span class="ml-2">{{flashMessage}}</span>
                            </ng-container>
                            <ng-container *ngIf="flashMessageType === 'error'">
                                <mat-icon
                                    class="text-red-500"
                                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                <span class="ml-2">{{flashMessage}}</span>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>