<div class="flex actBt deployBox flex-col gap-3 mb-6 ">
    <div class="subHeadingCont">                                                
        <div class="text-black font-medium text-lg">Solution Design</div>
        <div class="text-[#94A3B8] text-md font-normal mt-1">View detailed low-level design, including components and configurations.</div> 
    </div>


    <div class="tab-panel flex flex-row">
        <div class="left-side-panel p-2 rounded" >
            <ul>
                <li class="font-medium text-lg" [ngClass]="{'active': (activeTab == 'objects')}" (click)="changeTab('objects')">Objects</li>
                <li class="font-medium text-lg" [ngClass]="{'active': (activeTab == 'apex')}" (click)="changeTab('apex')">Apex Classes</li>
                <li class="font-medium text-lg disabled">Relationships</li>
                <li class="font-medium text-lg disabled">Validation rules</li>
                <li class="font-medium text-lg disabled">Triggers</li>
                <li class="font-medium text-lg disabled">Applications</li>
                <li class="font-medium text-lg disabled">Dashboards</li>
                <li class="font-medium text-lg disabled">Email Templates</li>
                <li class="font-medium text-lg disabled">Installed Packages</li>
                <li class="font-medium text-lg disabled">Lightning Pages</li>
                <li class="font-medium text-lg disabled">Permission Sets</li>
                <li class="font-medium text-lg disabled">Profiles</li>
                <li class="font-medium text-lg disabled">Reports</li>
                <li class="font-medium text-lg disabled">Report Types</li>
                <li class="font-medium text-lg disabled">Roles</li>
                <li class="font-medium text-lg disabled">Sharing Rules</li>
                <li class="font-medium text-lg disabled">Visualforce Components</li>
                <li class="font-medium text-lg disabled">Visualforce Pages</li>
                <li class="font-medium text-lg disabled">Requirements/User Stories</li>
                <li class="font-medium text-lg disabled">User-defined metadata</li>
            </ul>
        </div>
        <div class="right-panel px-4">


            <!-- objects -->

            <div class="objectBox" *ngIf="activeTab == 'objects'">
                <div class="detailBox bg-card rounded">
                    <h3 class="px-4 py-2 border-b text-2xl">Data Model Description</h3>
                    <p class="p-4 text-secondary font-normal" >This automotive Salesforce Sales Cloud project incorporates custom data models to manage and streamline critical information such as vehicle inventory, customer interactions, financing options, and sales processes. These data models ensure organized data flow, improve accessibility, and support analytics, enabling automotive sales teams to operate efficiently and make informed decisions.</p>
                </div>

                <div class="tableBox bg-card rounded mt-6">
                    <table mat-table [dataSource]="dataSource">                     
                      
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef class="descTab"> Description </th>
                          <td mat-cell *matCellDef="let element" class="descTab"> {{element.description}} </td>
                        </ng-container>

                    
                      
                        <ng-container matColumnDef="completion">
                          <th mat-header-cell *matHeaderCellDef> Completion </th>
                          <td mat-cell *matCellDef="let element" [ngClass]="element.completionClass" > 
                            <div class="flex  completionTag">
                                <img [src]="getcompletionIc(element.completion)" alt=""> {{element.completion}}
                            </div> 
                        </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.actionClass">  
                                <div class="flex items-center gap-2" >
                                    {{element.action}} 
                                    <mat-icon svgIcon="heroicons_outline:pencil-square" *ngIf="element.action == 'View' " class="icon-size-4"></mat-icon>
                                </div>
                            </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                </div>

            </div>


            <div class="objectBox" *ngIf="activeTab == 'apex'">
                <div class="detailBox bg-card rounded">
                    <h3 class="px-4 py-2 border-b text-2xl">Apex Class Description</h3>
                    <p class="p-4 text-secondary font-normal" >This project leverages custom Apex classes to optimize automotive sales processes in Salesforce Sales Cloud. These classes support functionalities such as lead assignment, vehicle inventory management, finance application processing, and automated customer communications, providing an integrated solution for automotive sales teams to drive efficiency and improve customer engagement.</p>
                </div>

                <div class="tableBox bg-card rounded mt-6">
                    <table mat-table [dataSource]="dataSource2">                     
                      
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef> Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef class="descTab"> Description </th>
                          <td mat-cell *matCellDef="let element" class="descTab"> {{element.description}} </td>
                        </ng-container>

                      
                        <ng-container matColumnDef="completion">
                          <th mat-header-cell *matHeaderCellDef> Completion </th>
                          <td mat-cell *matCellDef="let element" [ngClass]="element.completionClass" > 
                            <div class="flex  completionTag">
                                <img [src]="getcompletionIc(element.completion)" alt=""> {{element.completion}}
                            </div> 
                        </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.actionClass">  
                                <div class="flex items-center gap-2" >
                                    {{element.action}} 
                                    <mat-icon svgIcon="heroicons_outline:pencil-square" *ngIf="element.action == 'View' " class="icon-size-4"></mat-icon>
                                </div>
                            </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                      </table>
                </div>

            </div>

        </div>

    </div>
</div>