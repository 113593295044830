

<div class="flex flex-col flex-auto min-w-0 relative popup-content overflow-visible" (mousedown)="onClickOutside($event)" >
    <span class="close-dialog" [mat-dialog-close]="true" (click)="shownps()">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>
    <!-- Main -->
     
    <div class="flex-auto p-6 sm:p-10 m-p-0 relative overflow-visible" >
        <!-- <app-quick-prompt (mouseenter)="onMouseEnterQuickPrompt()" (mouseleave)="onMouseLeaveQuickPrompt()" *ngIf="showQuickPromptMenu && selectedFeatureData.type!='solutionDescription' && selectedFeatureData.type!='featureDetails'" (onCompleteQuickAction)="onCompleteQuickAction($event)" [quickActionSelection]="quickSelectedText" [menuPosition]="menuPosition"></app-quick-prompt> -->
        <div class="formBox">
            <div class="flex font-bold justify-center items-center gap-2 text-4xl featureHead">
                <mat-icon svgIcon="heroicons_outline:queue-list" class="icon1"></mat-icon>
                <h2>Add New Solutions</h2>
            </div>
            <div class="flex font-bold justify-between leading-6 gap-2 text-2xl tracking-tight mt-8 items-center flex-wrap">
                Solutions Master List
                <!-- Header -->
                <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <form [formGroup]="multitabGroupForm">
                        <!-- Actions -->
                        <div class="flex shrink-0 items-center gap-3 mt-6 sm:mt-0   mflex-auto">
                            <!-- Technology domain -->
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectoption selectoptionSm  mw-100 ">
                                <mat-select
                                    [formControlName]="'product'"
                                    [placeholder]="'Technology'" multiple (selectionChange)="selectionChange($event)">
                                    <mat-option #allSelected [value]="0">All</mat-option>
                                    @for (option of cloudTypes; track option) {
                                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                                    }
                                </mat-select>
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cloud'"></mat-icon>
                            </mat-form-field>
                            <!-- Epic -->
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop  mw-100 ">
                                <mat-select
                                    [formControlName]="'epic_name'"
                                    [placeholder]="'Solution Module'" multiple (selectionChange)="epicChange($event)">
                                    @for (option of epicTypes; track option) {
                                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                                    }
                                </mat-select>
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:document-text'"></mat-icon>
                            </mat-form-field>
                            <!-- Search -->
                            <mat-form-field
                                class="fuse-mat-dense fuse-mat-rounded min-w-30 mw-100">
                                <mat-icon
                                    class="icon-size-5"
                                    matPrefix
                                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                <input #searchInput
                                    matInput
                                    [autocomplete]="'off'" (input)="search($event)"
                                    [placeholder]="'Search Solution'">
                            </mat-form-field>
                            <!-- Add product button -->
                            <button
                                class="addFeatureBt mw-100"
                                mat-flat-button
                                [color]="'primary'"  (click)="openAddForm()"  >
                                <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                <span class="ml-2 mr-1">Add</span>
                            </button>
                            <button
                                class="getAi mw-100 shadow2"
                                mat-flat-button
                                [color]="'white'" [ngClass]="getAIDrawer?'shadow2':'' " (click)="getAIDrawer = !getAIDrawer;clickgenAI();" >
                                <img src="assets/icons/aiicon1.svg" style="width: 15px;" alt="">
                                <span class="ml-2 mr-1">Get AI Recommendations</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>


            <div class="getAiRec shadow2" *ngIf="getAIDrawer">
                <div class="flex  justify-between leading-6 text-2xl tracking-tight">
                    Get AI Recommendations
                    <button
                        class="absolute closeAdd"
                        mat-icon-button
                        (click)="getAIDrawer = !getAIDrawer; closegenAI();">
                        <mat-icon
                            class="text-current"
                            [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
                <p class="mt-2 lightgrey" >Refine your project's solution list with AI-driven recommendations tailored to your specific requirements.</p>
                <div class="flex flex-col gt-xs:flex-row mt-4">
                    Context
                    <tiptap-bubble-menu [editor]="editor2" id="recommended'">
                        <app-editor-controls 
                          [editor]="editor2" 
                          [selectedText]="selectedText"
                          (completeQuickAction)="onCompleteQuickAction($event)">
                        </app-editor-controls>
                    </tiptap-bubble-menu>
                    
                    <tiptap-editor 
                    [id]="'recommended'" 
                    [editor]="editor2" 
                    [(ngModel)]="reommendation_text"
                    (blur)="onTextareaBlur()"
                    (mouseenter)="onMouseEnter()"
                    (mouseleave)="onMouseLeave()"
                    (mousedown)="onMouseDown($event, 'recommended')"
                    (mouseup)="onTextSelect($event, 'recommended')"
                    (select)="onSelectionChange($event, 'recommended')"
                    (keydown)="onSelectionChange($event, 'recommended')"
                    id="editor-with-bubble-menu"
                    placeholder="Please enter your specific needs in the field below to tailor the solution list accordingly. After you have input your requirements, click Fetch Solutions to retrieve the solutions that best match your needs.">
                    </tiptap-editor>
                    <!-- <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>Context</mat-label>
                        <textarea
                        id="recommended"
                        (blur)="onTextareaBlur()"
                        (mouseenter)="onMouseEnter()" 
                        (mouseleave)="onMouseLeave()"
                        (mousedown)="onMouseDown($event,'recommended')"
                        (mouseup)="onTextSelect($event,'recommended')"
                        (select)="onSelectionChange($event,'recommended')"
                        (keydown)="onSelectionChange($event,'recommended')"
                            matInput [(ngModel)]="reommendation_text"
                            [placeholder]="'Please enter your specific needs in the field below to tailor the feature list accordingly. After you have input your requirements, click Fetch Features to retrieve the features that best match your needs.'"
                            cdkTextareaAutosize [rows]="5"></textarea>
                    </mat-form-field> -->
                </div>
                <button
                    class="px-8 mt-4 "
                    mat-flat-button
                    [color]="'primary'" [disabled]="!reommendation_text"
                    type="button" (click)="getRecommendation()"
                    >
                    <mat-spinner *ngIf="fetch_loader" class="matSpinner"></mat-spinner>
                    Fetch Solutions
                </button>
            </div>

            <fuse-alert class="mb-3 mt-3" *ngIf="bulkMessage" type="success"  [showIcon]="true" [appearance]="'soft'" [dismissed]="false"  [dismissible]="true"> {{ bulkMessageContent }}</fuse-alert>
            <div class="sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card shadow featureTableCard dark:bg-transparent">
               
                                
                <!-- Main -->
                <div class="flex flex-auto tableBox">
                    <div class="flex justify-center p-6 text-center w-full" *ngIf="fetch_list">
                        <mat-spinner  class="matSpinner"></mat-spinner>
                    </div>
                    <!-- feature list -->
                    <div *ngIf="(!fetch_list && !fetch_loader && ((getAIDrawer && is_genai_interacted) || !getAIDrawer))" class="flex flex-col flex-auto tableBox sm:overflow-y-auto">
                        <form [formGroup]="tabGroupForm">
                           
                            <ng-container *ngIf="pro_data">
                                <ng-container *ngIf="pro_data.length>0;else noFeatures">
                                    <div class="grid">
                                        <!-- Header -->
                                        <div class="border-b dark:bg-black font-semibold gap-4 grid inventory-grid md:px-8 ng-tns-c3037872216-3 px-6 py-4 sticky text-md text-secondary top-0 z-10">
                                            <div class="font-bold text-default checkicm"></div>
                                            <div class="font-bold text-default contentm">Module</div>
                                            <div class="font-bold text-default contentm">Sub-Module</div>
                                            <div class="font-bold text-default text-center contentm text-center">Technology</div>
                                            <div class="font-bold text-default text-center contentm">Setup Type</div>
                                            <div class="font-bold text-default text-center contentm">Effort</div>
                                            <div class="text-center font-bold text-default checkicm">Action</div>
                                        </div>
                                        <!-- Rows -->
                                        <ng-container formArrayName="features">
                                            <ng-container *ngFor="let feature of featuresArray.controls; let i = index">
                                                <div class="inventory-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b"  [ngClass]="displaySelected(feature.get('_id').value)">
                                                    <div class="truncate2 checkicm ">
                                                        <mat-icon svgIcon="heroicons_outline:check-circle" class="cursor-pointer" (click)="openfixedBox(feature.get('_id').value)"></mat-icon>
                                                    </div>
                                                    <div class="truncate2 text-default contentm">
                                                        {{feature.get('epicName').value}}
                                                    </div>
                                                    <div class="truncate2 contentm">
                                                        {{feature.get('featureName').value}}
                                                    </div>
                                                    <div class="text-center contentm break-all">
                                                        {{feature.get('cloud').value}}
                                                    </div>
                                                    <div class="text-center contentm break-all">
                                                        {{feature.get('setupType').value}}
                                                    </div>
                                                    <div class="text-center contentm">
                                                        {{feature.get('effort').value}}
                                                    </div>
                                                    <div class="text-center checkicm">
                                                        <button
                                                            class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                            mat-stroked-button
                                                            (click)="toggleDetails(feature.get('_id').value)">
                                                            <mat-icon
                                                                class="icon-size-5"
                                                                [svgIcon]="selectedFeature?._id === feature.get('_id').value ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'"></mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="grid" *ngIf="selectedFeature?._id === feature.get('_id').value">
                                                    <ng-container *ngTemplateOutlet="rowDetailsTemplate; context: { inputFormGroup: featuresArray.at(i), index: i }"></ng-container>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                
                                    <!-- <mat-paginator
                                        class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                                        [ngClass]="{'pointer-events-none': isLoading}"
                                        [length]="pagination.length"
                                        [pageIndex]="pagination.page"
                                        [pageSize]="pagination.size"
                                        [pageSizeOptions]="[5, 10, 25, 100]"
                                        [showFirstLastButtons]="true"></mat-paginator> -->
                
                                </ng-container>
                            </ng-container>
                
                            <ng-template #rowDetailsTemplate let-inputFormGroup="inputFormGroup" let-index="index" >
                                <div class="shadow-lg overflow-visible">
                                    <div class="flex border-b">
                                        <!-- Selected feature form -->
                                        <form
                                            class="flex flex-col w-full"
                                            [formGroup]="inputFormGroup">
                
                                            <div class="flex flex-col sm:flex-row p-8">
                                                <div class="flex flex-auto flex-wrap">
                                                    <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                                        <div class="sm:col-span-2">
                                                            <mat-form-field class="w-full">
                                                                <mat-label>Solution Sub-Module Name</mat-label>
                                                                <input
                                                                    matInput formControlName="featureName">
                                                            </mat-form-field>
                                                        </div>
                                                    
                                                        <div class="sm:col-span-2">
                                                            <mat-form-field class="w-full">
                                                                <mat-label>Complexity</mat-label>
                                                                <mat-select formControlName="complexity"[placeholder]="'Select Option'" required (selectionChange)="complexityChange($event,inputFormGroup)">
                                                                    @for (option of complexities; track option) {
                                                                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                                    }
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="sm:col-span-2" *ngIf="themeListTypes.length">
                                                            <mat-form-field class="w-full">
                                                                <mat-label>Theme</mat-label>
                                                                <mat-select formControlName="theme" [placeholder]="'Select Option'" required (selectionChange)="featureThemeChange($event,inputFormGroup)">
                                                                @for (option of themeListTypes; track option) {
                                                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                                }
                                                                </mat-select>
                                                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
                                                                <!-- <input matInput *ngIf="(data['selected_theme'])" formControlName="theme" [readonly]="(data['selected_theme'])"> -->
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="sm:col-span-2" *ngIf="themeListTypes.length">
                                                            <mat-form-field class="w-full">
                                                                <mat-label>Epic</mat-label>
                                                                <mat-select formControlName="epic"[placeholder]="'Select Option'" required>
                                                                    <ng-container *ngFor="let option of inputFormGroup.get('epicList').value">
                                                                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                                    </ng-container>
                                                                </mat-select>
                                                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                                                                <!-- <input matInput *ngIf="(data['selected_epic'])" formControlName="epic" [readonly]="(data['selected_epic'])"> -->
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="sm:col-span-2">
                                                            <mat-form-field class="w-full">
                                                                <mat-label>Module Name</mat-label>
                                                                <input matInput formControlName="epicName">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="sm:col-span-2">
                                                            <mat-form-field class="w-full">
                                                                <mat-label>Components Count</mat-label>
                                                                <input type="number" matInput formControlName="noOfComponents" (change)="onComponentsCountChange($event,inputFormGroup)">
                                                            </mat-form-field>
                                                        </div>
                                                        <!-- <div class="sm:col-span-2">
                                                            <mat-form-field class="w-full">
                                                                <mat-label>Epic Name</mat-label>
                                                                <input matInput formControlName="epicName">
                                                            </mat-form-field>
                                                        </div> -->
                                                        <!-- <div class="sm:col-span-2">
                                                            <mat-form-field class="w-full">
                                                                <mat-select formControlName="setupType"[placeholder]="'Select Option'" required>
                                                                    @for (option of complexities; track option) {
                                                                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                                    }
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div> -->
                                                    </div> 

                                                    <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                                            
                                                            <div class="sm:col-span-2">
                                                                <mat-form-field class="w-full">
                                                                    <mat-label>Effort</mat-label>
                                                                    <input type="number" matInput formControlName="effort">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="sm:col-span-2">
                                                                <mat-form-field class="w-full">
                                                                    <mat-label>Release</mat-label>
                                                                    <mat-select formControlName="release" [placeholder]="'Select Option'" required>
                                                                        <ng-container *ngFor="let option of inputFormGroup.get('releases_list').value">
                                                                            <mat-option [value]="option._id">{{option.name}}</mat-option>
                                                                        </ng-container>
                                                                    </mat-select>
                                                                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
                                                                    <!-- <input matInput *ngIf="(data['selected_epic'])" formControlName="epic" [readonly]="(data['selected_epic'])"> -->
                                                                </mat-form-field>
                                                            </div>
                                                            
                                                    </div>

                                                    <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                                        <div class="sm:col-span-4">
                                                            <!-- <div class="buttonSec flex justify-start mb-6 relative"> 
                                                               <app-quick-prompt (mouseenter)="onMouseEnterQuickPrompt()" (mouseleave)="onMouseLeaveQuickPrompt()" *ngIf="showQuickPromptMenu && selectedFeatureData.type=='featureDetails'" (onCompleteQuickAction)="onCompleteQuickAction($event)" [quickActionSelection]="quickSelectedText" [menuPosition]="{top: '0px', left: '0px'}" ></app-quick-prompt> 
                                                            </div> -->
                                                            <span class="font-medium block mb-2"> Solution Description</span>
                                                            <tiptap-bubble-menu [editor]="editor" [id]="'featureDetails-' + index">
                                                                <app-editor-controls 
                                                                  [editor]="editor" 
                                                                  [selectedText]="selectedText"
                                                                  (completeQuickAction)="onCompleteQuickAction($event)">
                                                                </app-editor-controls>
                                                            </tiptap-bubble-menu>
                                                            
                                                            <tiptap-editor 
                                                            [id]="'featureDetails-' + index" 
                                                            [editor]="editor" 
                                                            formControlName="featureDetails"
                                                            (blur)="onTextareaBlur()"
                                                            (mouseenter)="onMouseEnter()"
                                                            (mouseleave)="onMouseLeave()"
                                                            (mousedown)="onMouseDown($event, 'featureDetails-' + index)"
                                                            (mouseup)="onTextSelect($event, 'featureDetails-' + index)"
                                                            (select)="onSelectionChange($event, 'featureDetails-' + index)"
                                                            (keydown)="onSelectionChange($event, 'featureDetails-' + index)"
                                                            id="editor-with-bubble-menu">
                                                            </tiptap-editor>
                                                            <!-- <mat-form-field class="w-full">
                                                                <mat-label>Feature Description</mat-label>
                                                                <textarea
                                                                [id]="'featureDetails-'+index"
                                                                (blur)="onTextareaBlur()"
                                                                (mouseenter)="onMouseEnter()" 
                                                                (mouseleave)="onMouseLeave()"
                                                                (mousedown)="onMouseDown($event)"
                                                                (mouseup)="onTextSelect($event)"
                                                                (select)="onSelectionChange($event)"
                                                                (keydown)="onSelectionChange($event)"
                                                                matInput formControlName="featureDetails"
                                                                    [placeholder]="'Write a description'"
                                                                     [rows]="5" cdkTextareaAutosize></textarea>
                                                            </mat-form-field> -->
                                                           
                                                            <!-- <div class="buttonSec flex justify-start mt-6"> 
                                                                <button class="px-8 mr-2 rewriteAiBt shadow2" (click)="toggleDrawer('featureDetails',index);rewriteAIDrawer.toggle()" mat-raised-button color="white" type="button" > <img src="assets/icons/aiicon1.svg" alt=""> Rewrite with AI </button> 
                                                            </div> -->
                                                        </div>
                                                            
                                                            <!-- <div class="sm:col-span-2">
                                                                <span class="font-medium block mb-2">Solution Description</span>
                                                                <tiptap-bubble-menu [editor]="editor1" [id]="'solutionDescription-' + index">
                                                                    <app-editor-controls 
                                                                      [editor]="editor1" 
                                                                      [selectedText]="selectedText"
                                                                      (completeQuickAction)="onCompleteQuickAction($event)">
                                                                    </app-editor-controls>
                                                                </tiptap-bubble-menu>
                                                                
                                                                <tiptap-editor 
                                                                [id]="'solutionDescription-' + index" 
                                                                [editor]="editor1" 
                                                                formControlName="solutionDescription"
                                                                (blur)="onTextareaBlur()"
                                                                (mouseenter)="onMouseEnter()"
                                                                (mouseleave)="onMouseLeave()"
                                                                (mousedown)="onMouseDown($event, 'solutionDescription-' + index)"
                                                                (mouseup)="onTextSelect($event, 'solutionDescription-' + index)"
                                                                (select)="onSelectionChange($event, 'solutionDescription-' + index)"
                                                                (keydown)="onSelectionChange($event, 'solutionDescription-' + index)"
                                                                id="editor-with-bubble-menu">
                                                                </tiptap-editor>
                                                            </div> -->
                                                    </div>            
                                                </div>
                
                                            </div>
                
                                            <div class="flex items-center  w-full border-t px-8 py-4 gap-2" [ngClass]="flashMessage?'justify-between':'justify-end' ">
                                                <!-- <button
                                                    class="-ml-4 dark"
                                                    mat-button
                                                    [color]="'black'"
                                                    (click)="deleteSelectedFeature(inputFormGroup)">
                                                    Delete
                                                </button> -->

                                                <fuse-alert  *ngIf="flashMessage === 'success'" type="success" [showIcon]="true" [appearance]="'soft'"> Solution is Successfully Added!</fuse-alert>

                                                <fuse-alert *ngIf="flashMessage === 'error'" type="warn" [showIcon]="true" [appearance]="'soft'"> An error occurred, try again!</fuse-alert>

                                                <div class="flex items-center">
                                                    <!-- <div
                                                        class="flex items-start mr-4"
                                                        *ngIf="flashMessage">
                                                        <ng-container *ngIf="flashMessage === 'success'">
                                                            <mat-icon
                                                                class="text-green-500"
                                                                [svgIcon]="'heroicons_outline:check'"></mat-icon>
                                                            <span class="ml-2">Feature inserted</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="flashMessage === 'error'">
                                                            <mat-icon
                                                                class="text-red-500"
                                                                [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                                            <span class="ml-2">An error occurred, try again!</span>
                                                        </ng-container>
                                                    </div> -->
                                                    <button
                                                        mat-flat-button
                                                        [color]="'primary'"
                                                        (click)="insertSelectedFeature(inputFormGroup,false)">
                                                        <mat-spinner *ngIf="inputFormGroup.get('insert_loader').value" class="matSpinner"></mat-spinner>
                                                        Insert Solution
                                                    </button>
                                                </div>
                                            </div>
                
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                
                            <ng-template #noFeatures>
                                <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">There are no Solutions!</div>
                            </ng-template>
                        </form>
                        <div class="quickAction" *ngIf="quickActionIds.length">
                            <div class="flex gap-6 pl-3 text-white">
                                <div>{{quickActionIds.length}} {{quickActionIds.length>1?'Solutions':'Solution'}} Selected</div>
                                <div class="divider"></div>
                                <div class="flex gap-1 items-center quickBt cursor-pointer" (click)="bulkInsert()">
                                    <mat-icon svgIcon="heroicons_outline:bars-arrow-down" color="primary"></mat-icon>
                                    <span>Bulk Insert</span>
                                </div>
                            </div>
                        </div>
                    </div>
            
                </div>
            
            </div>


        </div>
    </div>
</div>

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'rewriteAIDrawer'" (openedChanged)="openedChanged($event)"
    [position]="'right'"
    #rewriteAIDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex flex-row h-20 items-center justify-center min-h-20 relative px-6 text-center w-full">
            <img src="assets/icons/aiicon1.svg" style="width: 20px;" alt=""> 
            <div class="ml-3 text-2xl font-semibold tracking-tight">Rewrite with AI</div>
            <button
                class="absolute right-0"
                mat-icon-button
                (click)="showcustomprompt=false;rewriteAIDrawer.close();clear();">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    
            </button>
        </div>

        <div class="flex flex-col p-6">
            <form [formGroup]="drawerGroupForm">
                <div class="flex flex-col gt-xs:flex-row">
                    <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>Original Text </mat-label>
                        <textarea
                            matInput [formControlName]="'original'" cdkTextareaAutosize [rows]="5"
                            [placeholder]="'Write a short description about your project summary'"
                            [rows]="5"></textarea>
                    </mat-form-field>
                </div>
                <div class="buttonSec flex justify-center"> 
                    <button class="px-8 mr-2 mt-6 fixgramBt"  mat-raised-button color="primary" type="button"  (click)="showcustomprompt=false;fixGrammar()" [disabled]="(!drawerGroupForm.get('original').value || generate_grammar || generate_loader)"> 
                        <img *ngIf="!generate_grammar" src="assets/icons/whiteaiicon.png" style="width: 15px; margin-right: 5px;" alt=""> 
                        <mat-spinner *ngIf="generate_grammar" class="matSpinner"></mat-spinner>
                        Fix Grammar </button> 

                    <button class="px-8 mr-2 mt-6 rewriteAiBt gap-2"  mat-raised-button color="white" type="button" (click)="showcustomprompt=true;" [disabled]="(generate_grammar || generate_loader)"> 
                        <mat-icon color="white" svgIcon="heroicons_outline:squares-plus"></mat-icon> 
                        Custom Prompt 
                    </button> 
                </div>


                <div *ngIf="showcustomprompt" class="flex flex-col gt-xs:flex-row mt-6">
                    <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>Custom Prompt </mat-label>
                        <textarea
                            matInput [formControlName]="'custom'" 
                            [placeholder]="'Write a short description about your project summary'"
                            cdkTextareaAutosize [rows]="5"></textarea>
                    </mat-form-field>
                </div>

                <div *ngIf="showcustomprompt" class="buttonSec flex justify-center"> 
                    <button class="px-8 mr-2 mt-6"  mat-raised-button color="white" type="button" [disabled]="(!drawerGroupForm.get('custom').value || generate_loader)" (click)="rewiteSummary()"> 
                        <!-- <img src="assets/icons/whiteaiicon.png" style="width: 15px; margin-right: 5px;" alt="">  -->
                        <img *ngIf="!generate_loader" src="assets/icons/aiicon1.svg" style="width: 15px; margin-right: 5px;" alt=""> 
                        <mat-spinner  *ngIf="generate_loader" class="matSpinner"></mat-spinner>
                        Generate </button>  
                </div>

                <div class="flex flex-col gt-xs:flex-row mt-6">
                    <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>New Text </mat-label>
                        <textarea
                            matInput [formControlName]="'modified'"
                            [placeholder]="'Write a short description about your project summary'"
                            cdkTextareaAutosize [rows]="5"></textarea>
                    </mat-form-field>
                </div>

                <div class="buttonSec flex justify-center"> 
                    <button
                        class="px-8 mr-2 mt-6 replaceBt "
                        mat-flat-button
                        [color]="'accent'"
                        type="button" [disabled]="(!drawerGroupForm.get('modified').value || generate_grammar || generate_loader)" (click)="newContent('replace')"
                        >
                        <mat-spinner  *ngIf="generate_replace" class="matSpinner"></mat-spinner>
                        Replace
                    </button>
                    <button
                        class="px-8 mt-6 appendBt"
                        mat-flat-button
                        [color]="'primary'"
                        type="button" [disabled]="(!drawerGroupForm.get('modified').value || generate_grammar || generate_loader)" (click)="newContent('append')"
                        >
                        <mat-spinner  *ngIf="generate_append" class="matSpinner"></mat-spinner>
                        Append
                    </button>
                    
                </div>
            </form>
        </div>

    </div>

</fuse-drawer>