<div class="flex flex-col flex-auto min-w-0 relative featureTableCard shadow">
    <span class="close-dialog"  [mat-dialog-close]="true">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>
    <!-- Main -->
    <div class="flex-auto p-6 ">
        <div class="formBox ">
            <div class="flex flex-col gap-2 settingHead">
                <h2 class="font-bold text-4xl ">Welcome to GetGenerative.ai</h2>
                <p class="text-2xl ">You’re on your way to become a <b>10x productive consultant.</b><br> Start your product tour now and begin your journey to excellence.</p>
                
            </div>
            <div class="text-right mt-6">
                <button  mat-flat-button [color]="'primary'" type="button" style="flex-direction: row-reverse;" (click)="startTour()" [mat-dialog-close]="true">
                    Start Tour
                    <mat-icon svgIcon="heroicons_outline:chevron-right"></mat-icon>
                </button>
            </div>
          
        </div>
    </div>
</div>