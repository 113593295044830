import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, from, map, Observable, of, ReplaySubject, switchMap, tap, throwError } from 'rxjs';
import { IKnowledgeCenter, IPagination } from './knowledge-center.types';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({providedIn: 'root'})
export class KnowledgeCenterService
{

    typeOptions=[
        { value: 0, label: 'PDF' },
    { value: 1, label: 'CSV' },
    { value: 2, label: 'TXT' },
    { value: 4, label: 'DOCX' },
    { value: 6, label: 'PPTX' },
    { value: 7, label: 'Image' },
    ];

    embeddingsTypeOptions=[
        { value: 0, label: 'Open AI Embeddings' },
    { value: 1, label: 'Fast Embeddings' }
    ];

    statusTypes=[
        {value:0,label:"Pending"},
        {value:1,label:"In Progress"},
        {value:2,label:"Completed"},
        {value:-1,label:"Error"},
    ]

    private _httpClient = inject(HttpClient);
    private _authService=inject(AuthService);
    private _knowledgeCenters: ReplaySubject<IKnowledgeCenter[]> = new ReplaySubject<IKnowledgeCenter[]>(1);
    private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for knowledgebase
     *
     * @param value
     */
    set knowledgeBases(value: IKnowledgeCenter[])
    {
        // Store the value
        this._knowledgeCenters.next(value);
    }

    get knowledgeBases$(): Observable<IKnowledgeCenter[]>
    {
        return this._knowledgeCenters.asObservable();
    }

     /**
     * Getter for pagination
     */
     get pagination$(): Observable<IPagination>
     {
         return this._pagination.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    
    get(pageIndex:number): Observable<IKnowledgeCenter[]>
    {
        let skip=pageIndex*10;
        let limit=10;
        return this._httpClient.get(environment.api_endpoint + 'knowledge-center?skip='+skip+'&limit'+limit, { headers: { method: 'get',Authorization: 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                
                if(response.success){

                        this._pagination.next({
                            length: response.total,
                            size: 10,
                            page: pageIndex,
                            lastPage: 0,
                            startIndex: 0,
                            endIndex: (response.total-1)
                        });

                        this._knowledgeCenters.next(response.data);

                        return this._knowledgeCenters.asObservable();


                }else{
                    return of([]);
                }
            }),
        );

    }

    saveKnowledgeCenter(knowledgebase:IKnowledgeCenter): Observable<boolean>
    {

        return this._httpClient.post(environment.api_endpoint + 'knowledge-center',JSON.stringify(knowledgebase), { headers: { method: 'create_knowledge_center','Authorization': 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {

                if(response.success){

                        return of(true);

                }else{
                    return of(false);
                }
            }),
        );

    }

    uploadSupportingFile(uploadUrl: string,file: File, ): Observable<any> {
        return from(
            fetch(uploadUrl, {
              method: 'PUT',
              body: file,
              headers: {
                'Content-Type': file.type
              }
            })
          ).pipe(
            catchError(error => {
              console.error('Error uploading file:', error);
              return throwError(error);
            })
          );
      }


}
