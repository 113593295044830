<div *ngIf="(view_component!='workspace')" class="flex actBt flex-wrap items-center mt-4 gap-3 justify-between">
    <div class="subHeadingCont">                                                
        <div class="text-black font-medium text-lg">Implementation Workbook</div>
        <div class="text-[#94A3B8] text-md font-normal">Comprehensive project configuration guide for system setup and implementation.</div> 
    </div>
    <div *ngIf="is_write_allowed">
        <button class="addThemeBt" mat-flat-button [color]="'primary'" (click)="openDialog()">
            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="">Add Configuration </span>
        </button>
    </div>
</div>
<div class="bg-card rounded-lg shadow  py-3 mt-6" *ngIf="(view_component!='workspace')" >
    <!-- Header Section -->
    <section class="flex flex-row flex-wrap justify-end px-3 relative">
        <form class="flex gap-2 justify-start overflow-auto " [formGroup]="headerForm" *ngIf="headerForm" #scrollContainer>
            <mat-form-field class="fuse-mat-dense searchTab min-w-64" >
                <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input matInput class="text-sm font-medium" [formControlName]="'search'" [autocomplete]="'off'" [placeholder]="'Search'" >
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <img src="assets/icons/type.svg" class="w-5 mr-2" alt="">
                <mat-select [placeholder]="'Type'" multiple [formControlName]="'selected_type'"(selectionChange)="onChangeMetadata($event)">
                    @for(option of allMetadataTypeList;track option){
                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <mat-select [formControlName]="'theme'" [placeholder]="'Theme'" multiple (selectionChange)="onChangeTheme($event)">
                    @for (option of themeTypes; track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix  svgIcon="heroicons_outline:squares-plus"></mat-icon>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <mat-select [formControlName]="'epic'" [placeholder]="'Epic'" multiple (selectionChange)="filterCount()">
                    @for (option of epicTypes; track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix  svgIcon="heroicons_outline:rectangle-group"></mat-icon>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <img src="assets/icons/folderic.svg" class="w-5 mr-2" alt="">
                <mat-select [formControlName]="'selected_category'" multiple [placeholder]="'Category'" (selectionChange)="onChangeCategory($event)">
                    @for(option of allMetadataCategoryList;track option){
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <img src="assets/icons/folder2ic.svg" class="w-5 mr-2" alt="">
                <mat-select [formControlName]="'selected_subcategory'" multiple [placeholder]="'Sub-Category'" (selectionChange)="filterCount()">
                    @for(option of allMetadataSubCategory;track option){
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            
           
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <img src="assets/icons/work_type.svg" class="w-5 mr-2" alt="">
                <mat-select [formControlName]="'selected_worktype'" multiple [placeholder]="'Work Type'" (selectionChange)="filterCount()">
                    @for (option of workTypes; track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <img src="assets/icons/action_type2.svg" class="w-5 mr-2" alt="">
                <mat-select [formControlName]="'selected_actiontype'" multiple [placeholder]="'Action Type'" (selectionChange)="filterCount()">
                    @for (option of actionTypes; track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <mat-select [formControlName]="'cloud'" multiple [placeholder]="'Cloud'" (selectionChange)="filterCount()">
                    @for (option of technologyDomains; track option) {
                        <mat-option [value]="option.name">{{option.name}}</mat-option>
                    }
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix  svgIcon="heroicons_outline:cloud"></mat-icon>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 mr-8">
                <mat-select [formControlName]="'selected_status'" multiple [placeholder]="'Status'" (selectionChange)="filterCount()">
                    @for (option of statusTypes; track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix  svgIcon="heroicons_outline:flag"></mat-icon>
            </mat-form-field>
        </form>
        <span class="arrowleftIc" *ngIf="showLeftArrow" (click)="scrollLeft()">
            <mat-icon svgIcon="heroicons_outline:chevron-left"></mat-icon>
        </span>
        <span class="arrowrightIc" (click)="scrollRight()"  *ngIf="showRightArrow">
            <mat-icon svgIcon="heroicons_outline:chevron-right"></mat-icon>
        </span>
    </section>

    <div class="tableSec docTable mt-6">
        <div class="tabledata " >
            <div class="flex min-w-full  gap-3 py-5 px-5 tableth ">
                <div class="text-md  smallTab text-left font-medium uppercase ">Theme</div>
                <div class="text-md  smallTab text-left font-medium uppercase ">Epic</div>
                <div class="text-md smallTab text-left font-medium uppercase ">Category</div>
                <div class="text-md smallTab text-left font-medium uppercase ">Sub category</div>
                <div class="text-md smallTab text-left font-medium uppercase firstcol">Type</div>
                <div class="text-md largeTab text-left font-medium uppercase ">Description</div>
                <div class="text-md  smallTab text-left font-medium uppercase ">Work Type</div>
                <div class="text-md  smallTab text-left font-medium uppercase ">Action Type</div>
                <div class="text-md smallTab text-left font-medium uppercase ">Cloud</div>
                <div class="text-md smallTab text-left font-medium uppercase ">Status</div>
            </div>
              
            <section>
                <div class=" tablebody" >
                    @for(eachbuild of buildList;track eachbuild){
                        <div class="relative group   select-none  sm:mt-0 mt-3 customB">
                            <div class="tableRow relative">
                                <div class="flex gap-3 group w-full items-center py-3 px-5  cursor-pointer tabletd border-b hover:bg-gray-100 dark:hover:bg-hover " (click)="selectBuild(eachbuild)" >
                                    <div class="text-md text-black text-left font-regular smallTab" >
                                        {{getThemeName(eachbuild.themeID)}}
                                    </div>
                                    <div class="text-md text-black text-left font-regular smallTab" >
                                        {{getEpicName(eachbuild.themeID,eachbuild.epicID)}}
                                    </div>
                                    <div class="text-md text-black text-left font-regular smallTab" >
                                        {{eachbuild.solutionCategory}}
                                    </div>
                                    <div class="text-md text-black text-left font-regular  smallTab" >
                                        {{eachbuild.solutionSubCategory}}
                                    </div>
                                    <div class="text-md text-black text-left font-regular firstcol smallTab break-all "  >
                                        {{getMetadataName(eachbuild.metadataType)}}
                                    </div>
                                    <div class="text-md text-black text-left font-regular largeTab" [innerHTML]="eachbuild.description">
                                    </div>
                                    <div class="text-md text-black text-left font-regular smallTab" >
                                        {{eachbuild.workType}}
                                    </div>
                                    <div class="text-md text-black text-left font-regular smallTab" >
                                        {{eachbuild.actionType}}
                                    </div>
                                    <div class="text-md text-black text-left font-regular smallTab" >
                                        {{eachbuild.cloud}}
                                    </div>
                                    <div class="text-md text-black text-left font-regular smallTab flex justify-between" >
                                        <span  [ngClass]="{ 'todoTag': (eachbuild.status == 'Not Started') , 'inprogTag': (eachbuild.status == 'In Progress')  ,'doneTag': (eachbuild.status == 'Done') ,'holdTag': (eachbuild.status == 'On-Hold') ,'cancelledTag': (eachbuild.status == 'Cancelled') }">{{eachbuild.status}}</span>
                                        <div class="flex  right-0 z-999 opacity-0 editBox transition-opacity duration-300 group-hover:opacity-100">
                                            <div class="gap-2 mr-2" *ngIf="is_write_allowed">
                                                <mat-icon  class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="selectBuild(eachbuild)"></mat-icon>
                                                <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:trash" (click)="onDelete(eachbuild)"></mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                               
        
                            </div>                     
                        </div>   
                    }                           
                </div>
                <mat-paginator *ngIf="(buildList.length)"  [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" (page)="onPageChange($event)"  [pageIndex]="currentPage" class="customPaginator w-full"></mat-paginator>

                <div class="flex" *ngIf="(!buildList.length && !loader_buildList)">
                    <div class="flex flex-col gap-4 items-center justify-center notfound p-16 w-full">
                        <mat-icon svgIcon="heroicons_outline:exclamation-circle"></mat-icon>
                        <h4 class="text-lg font-medium">No Configurations Found</h4>
                    </div>
                </div>
              
            </section> 
        
        </div>
    </div>
</div>


<section *ngIf="(view_component =='workspace')" >
        
    <div class="bg-card m-bg-card storyTableBox  ">
        <!-- <div class="flex justify-between items-center w-full px-5 py-5">
            <div class="text-lg font-semibold text-black">Low Level solution</div>       
          </div> -->

          <div class="innerB">
            <section>  
                <div class="grid table-grid  min-w-full px-5 gap-3 py-5">
                    <div class="flex justify-start  text-md font-medium uppercase ">Category</div>
                    <div class="flex justify-start  text-md font-medium uppercase ">Sub Category</div>
                  <div class="flex justify-start  text-md font-medium uppercase ">Type</div>
                  <div class="flex justify-start  text-md font-medium uppercase ">Description</div>
                  <div class="flex justify-start text-md font-medium uppercase pl-4">Action Type</div>
                </div>
               </section>
                  @if(buildList){
                  <section>
                    <div class="w-full" >
                      
                    @for(eachbuild of buildList;track eachbuild){
                      <div  class="relative group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0 mt-3 customB border-b"  >
                        <!-- Content for each section -->
                        <div class="grid table-grid gap-3 items-center py-3 px-5 tabBorder cursor-pointer">
                            <div class=" text-md text-black font-regular line-clamp-2"  (click)="is_write_allowed && selectBuild(eachbuild)">
                              {{eachbuild.solutionCategory}}
                            </div>
                            <div class=" text-md text-black font-regular line-clamp-2"  (click)="is_write_allowed && selectBuild(eachbuild)">
                              {{eachbuild.solutionSubCategory}}
                            </div>
                            <div class="flex flex-wrap justify-start  text-md text-black font-regular "  (click)="is_write_allowed && selectBuild(eachbuild)">
                                {{getMetadataName(eachbuild.metadataType)}}
                            </div>
                            <div class=" text-md text-black font-regular line-clamp-2"  (click)="is_write_allowed && selectBuild(eachbuild)"  [innerHTML]="eachbuild.description">
                            </div>
                          
                          <div class="flex   text-md text-black font-regular pl-4"  [ngClass]="is_write_allowed ? 'justify-between' : 'justify-start'">
                            <div class=" text-md text-black font-regular line-clamp-2"  (click)="is_write_allowed && selectBuild(eachbuild)">
                                {{eachbuild.actionType}}
                              </div>
                            <div class="flex flex-wrap justify-start gap-2 items-center text-md text-black font-regular cursor-pointer" *ngIf="is_write_allowed" >
        
                              <div class="flex justify-end group gap-2">
                                <div  class="flex gap-1 group-hover:opacity-100 items-center mr-2 opacity-0">
                                  <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="selectBuild(eachbuild)" ></mat-icon>
                                  <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:trash" (click)="onDelete(eachbuild)"></mat-icon>
                                </div>
                                
                              </div>
                            </div>
        
                           
                          </div>
                         
                        </div>
                      </div> 
                    }
                                                          
                    </div>
                  
                  </section>
                }@else {
                    <section>
                        <p class="noSolution">No Configuration Found</p>
                    </section>
                  
                } 
                  
                    <!-- Add Feature Button -->
                    <div *ngIf="(is_write_allowed)" class="min-w-full flex pl-10 py-5 items-center justify-start relative border-t cursor-pointer" (click)="openDialog()">
                      <mat-icon class="text-[#9AA6B8]" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                      <div class="pl-2 text-md text-[#9AA6B8]">Add Configuration</div>
                     
                    </div>
          </div>

       

    </div>


</section>

<fuse-drawer
    class="w-screen  sm:w-100 z-999 sideDraw"
    fixed
    [mode]="'over'"
    [name]="'viewDrawer'"
    [position]="'right'" 
    (openedChanged)="openedChanged($event)"
    #viewDrawer>
    
    <div *ngIf="isDrawerConfigOpen && selectedBuild" class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex justify-between items-center px-4 py-2" >
            <div class="">
                <h4 class="text-lg font-medium text-black flex items-center">
                    <mat-icon svgIcon="heroicons_outline:pencil-square" class="icon-size-5 text-black"></mat-icon>
                    Edit Configuration
                </h4>
            </div>
            <div class="actSide flex  items-center" >
                <button *ngIf="is_write_allowed" mat-icon-button [matMenuTriggerFor]="moreMenu2" >
                    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'" class="icon-size-4"></mat-icon>
                </button>
                <mat-menu #moreMenu2="matMenu">
                    <button mat-menu-item (click)="onDelete(selectedBuild)">
                        <mat-icon [svgIcon]="'heroicons_outline:trash'" class="icon-size-4"></mat-icon>
                        <span>Delete </span>
                    </button>
                    
                </mat-menu>
                <button class="ml-auto" mat-icon-button (click)="viewDrawer.toggle()">
                    <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </div>

        <div class="formSec px-4 py-2 flex flex-col gap-4">
            <form class="flex flex-col flex-auto gap-2" [formGroup]="configForm">
                <div class="detailBox">
                    <div class="innerDetail mb-2">
                        <h4 class="font-medium text-black">Name</h4>
                        <!-- <textarea [formControlName]="'metadataName'" name="" id="" class="border mt-2 rounded w-full p-3 auto-grow text-black" row="2"  #textarea (input)="autoGrow(textarea)" rows="1" style="min-height: 60px; resize:none;" [style.height]="height"></textarea> -->
                        <input class="border mt-2 rounded w-full p-3 auto-grow text-black" [formControlName]="'metadataName'">
                    </div>
                    <div class="flex gap-10 innerDetail my-4">
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label> Type</mat-label>
                            <mat-select [placeholder]="'Select Type'" [formControlName]="'metadataType'" class="selectOption2" (selectionChange)="onChangeFormMetadata($event)">
                                @for(option of allMetadataTypeList;track option){
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>Theme</mat-label>
                            <mat-select [placeholder]="'Theme'" [formControlName]="'themeID'" class="selectOption2" (selectionChange)="onChangeFormTheme($event)">
                                @for(option of themeTypes;track option){
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex gap-10 innerDetail mb-2 my-4">
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>Epic</mat-label>
                            <mat-select [placeholder]="'Epic'" [formControlName]="'epicID'" class="selectOption2" (selectionChange)="onChangeEpic($event)">
                                @for(option of epicTypes;track option){
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label> Category</mat-label>
                            <mat-select [placeholder]="'Select Category'" [formControlName]="'solutionCategory'" class="selectOption2" readonly (selectionChange)="onChangeFormCategory($event)">
                                @for(option of allMetadataCategoryForm;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>                        
                    </div>
                    <div class="flex gap-10 innerDetail mb-2 my-4">
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>Sub-Category</mat-label>
                            <mat-select [placeholder]="'Select Sub Category'" [formControlName]="'solutionSubCategory'" class="selectOption2" readonly>
                                @for(option of allMetadataSubCategoryForm;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>Cloud</mat-label>
                            <mat-select [formControlName]="'cloud'" [placeholder]="'Cloud'" class="selectOption2">
                                @for (option of technologyDomains; track option) {
                                    <mat-option [value]="option.name">{{option.name}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex gap-10 innerDetail mb-2 my-4">
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>Work Type</mat-label>
                            <mat-select [placeholder]="'Type'" [formControlName]="'workType'" class="selectOption2" readonly>
                                @for(option of workTypes;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>Action</mat-label>
                            <mat-select [placeholder]="'Action'" [formControlName]="'actionType'" class="selectOption2">
                                @for(option of actionTypes;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        
                    </div>
                    <div class="flex  gap-10 innerDetail mb-2 my-4 w-1/2 pr-4">
                        <!-- <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>Status</mat-label>
                            <mat-select [placeholder]="'Status'" [formControlName]="'status'" class="selectOption2">
                                @for(option of statusTypes;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>  Status</mat-label>
                            <mat-select [placeholder]="'Select Status'" [formControlName]="'status'"   #statusSelect="matSelect">
                            <mat-select-trigger>
                                <div class="flex gap-1 items-center">
                                        <span class="w-2 h-2 " [ngClass]="{ 'todoTag2': ((configForm.get('status').value) == 'Not Started') , 'inprogTag2': ((configForm.get('status').value) == 'In Progress')  ,'doneTag2': ((configForm.get('status').value) == 'Done') ,'holdTag2': ((configForm.get('status').value) == 'On-Hold') ,'cancelledTag2': ((configForm.get('status').value) == 'Cancelled') }"></span>
                                    {{configForm.get('status').value}}
                                </div> 
                            </mat-select-trigger>
            
                            <ng-container *ngFor="let status of statusTypes">
                                <mat-option [value]="status.value">
                                    <div class="flex gap-1 items-center">
                                        <!-- <img class="w-3 h-3" [src]="status.icon"> -->
                                        <span class="w-2 h-2 " [ngClass]="{ 'todoTag2': ((status.value) == 'Not Started') , 'inprogTag2': ((status.value) == 'In Progress')  ,'doneTag2': ((status.value) == 'Done') ,'holdTag2': ((status.value) == 'On-Hold') ,'cancelledTag2': ((status.value) == 'Cancelled') }"></span>
                                        <span>{{status.label}}</span></div>  
                                </mat-option>
                            </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="desc flex flex-col mt-6">
                        <label class="font-medium text-black">Description*</label>
                        <!-- <textarea name="" id="" class="descText" [formControlName]="'description'"></textarea> -->
                        <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                            <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBold('desc')">
                                <mat-icon class="icon-size-4">format_bold</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleItalic('desc')">
                                <mat-icon class="icon-size-4">format_italic</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBulletList('desc')">
                                <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                            </button>
                            </div>
                            <tiptap-editor 
                            [editor]="editor"
                            [formControlName]="'description'"
                            id="tiptap-editor"
                            placeholder="Start typing here...">
                            </tiptap-editor>
                        </div>
                    </div> 

                    <div class="desc flex flex-col mt-6">
                        <label class="font-medium text-black">Assumption*</label>
                        <!-- <textarea name="" id="" class="descText" [formControlName]="'assumption'"></textarea> -->
                        <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                            <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBold('assumption')">
                                <mat-icon class="icon-size-4">format_bold</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleItalic('assumption')">
                                <mat-icon class="icon-size-4">format_italic</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBulletList('assumption')">
                                <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                            </button>
                            </div>
                        
                            <tiptap-editor 
                            [editor]="editor1"
                            [formControlName]="'assumption'"
                            id="tiptap-editor2"
                            placeholder="Start typing here...">
                            </tiptap-editor>
                        </div>
                    </div> 

                    <div class="desc flex flex-col mt-6">
                        <label class="font-medium text-black">Developer Notes</label>
                        <!-- <textarea name="" id="" class="descText" [formControlName]="'assumption'"></textarea> -->
                        <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                            <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBold('notes')">
                                <mat-icon class="icon-size-4">format_bold</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleItalic('notes')">
                                <mat-icon class="icon-size-4">format_italic</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBulletList('notes')">
                                <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                            </button>
                            </div>
                        
                            <tiptap-editor 
                            [editor]="editor2"
                            [formControlName]="'developerNote'"
                            id="tiptap-editor2"
                            placeholder="Start typing here...">
                            </tiptap-editor>
                        </div>
                    </div> 


                    <div class="innerDetail mb-2 my-4">
                        <h4 class="font-medium text-black">Linked User Stories</h4>
                        <div class="linkedTable">
                            <div class="table-container" >
                                <table>
                                    <thead>
                                        <tr>
                                            <th class="font-medium">Story ID</th>
                                            <th class="font-medium">Title</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  *ngIf="(!userStoryArray || !userStoryArray.value || !userStoryArray.value.length)">
                                            <td colspan="2" class="border-b text-center">No Story Found</td>
                                        </tr>
                                        <tr *ngFor="let eachstory of userStoryArray.value; let i = index" >
                                            <td>{{eachstory.storyID}}</td>
                                            <td>{{eachstory.title}}</td>
                                        </tr>
                                        <tr class="cursor-pointer">
                                            <td colspan="2" (click)="selectStory()"> 
                                                <div class="flex items-center text-md gap-1 ">
                                                    <mat-icon svgIcon="heroicons_outline:plus-circle" class="icon-size-5"></mat-icon> <span>Add User Story </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="border userinfo mb-15" >
                    <img class="w-7 h-7 mr-1 rounded-full" *ngIf="(configForm.get('author.meta.profileImage').value)" [src]="getImageURL(configForm.get('author.meta.profileImage').value)" appUrl>
                    <mat-icon *ngIf="!(configForm.get('author.meta.profileImage').value)" [svgIcon]="'heroicons_outline:user-circle'" class="icon-size-12"></mat-icon>

                    <div class="mr-3"> 
                        <p>Updated by {{configForm.get('author.personal.name.first').value}},{{format(configForm.get('created').value)}}</p>
                    </div>
                </div>

                <div class="actionBt text-right flex mt-4" *ngIf="(configForm.dirty && is_write_allowed)" [ngClass]="{'justify-between' :(flashMessageType) , 'justify-end' :(!flashMessageType)  }">

                    <fuse-alert class="text-md" *ngIf="flashMessageType === 'success' && flashMessageType" type="success" [showIcon]="true" [appearance]="'soft'" [dismissible]="true" [dismissed]="false"> Updated successfully</fuse-alert>
        
                    <fuse-alert class="text-md" *ngIf="flashMessageType === 'error' && flashMessageType" type="warn" [showIcon]="true" [appearance]="'soft'" [dismissible]="true" [dismissed]="false"> An error occurred, try again!</fuse-alert>

                    <button  mat-flat-button [color]="'primary'" [disabled]="(!configForm.valid || isSavingConfig || configForm.get('assumption').value=='<p></p>' || configForm.get('description').value=='<p></p>')"  (click)="onSave()" >
                        <div class="flex gap-1 items-center">
                        <mat-progress-spinner *ngIf="isSavingConfig" mode="indeterminate"></mat-progress-spinner> <span>Save</span>
                        </div>
                    </button>
                </div>
            </form>
        </div>        
    </div>
</fuse-drawer>