import { NgFor, NgIf, NgClass } from '@angular/common';
import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild, ElementRef, Injector } from '@angular/core';
import { FormArray, FormControl, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FeedbackService } from 'app/core/feedback/feedback.service';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { AnalyticsService } from 'app/core/common/analytics_service';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import analytics_event from 'app/core/common/analytics_event';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'environments/environment';
import { EditorControlsComponent } from '../../common/editor-controls/editor-controls.component';

import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { NgxTiptapModule } from 'ngx-tiptap';
import Dropcursor from '@tiptap/extension-dropcursor'
import Image from '@tiptap/extension-image'

@Component({
  selector: 'app-feedback',
  standalone: true,
  imports      : [EditorControlsComponent, NgClass, NgxTiptapModule,MatIconModule,FuseAlertComponent, MatProgressSpinnerModule, FormsModule, NgIf, MatDialogModule,MatProgressBarModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule,NgFor],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss'
})
export class FeedbackComponent {
  @ViewChild('feedbackNgForm') feedbackNgForm: NgForm;
  showAlert: boolean = false;
  alert: { type: FuseAlertType; message: string } = {
      type   : 'success',
      message: '',
  };
  
  user: User;
  feedbackForm: UntypedFormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  feedbak_loader: boolean;
  pageUrl:string="";
  uploadedFileName:string="";
  // selectedFiles?: FileList [] = [];
  selectedFiles: File[] = [];
  selectedFileNames: string[] = [];
  previews: string[] = [];
  original_feedback: any;
  selectedText: string = '';

  editor = new Editor({
    extensions: [
      StarterKit,
      Placeholder,
      Image,
      Dropcursor
    ],
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });

  constructor(
    private injector: Injector,
    private dialog: MatDialog, 
    private _formBuilder: UntypedFormBuilder,
    private _userService: UserService,
    private _authService:AuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _analyticsService:AnalyticsService,
    private _feedbackService: FeedbackService
  )
  {
    // Subscribe to the user service
    this._userService.user$
    .pipe((takeUntil(this._unsubscribeAll)))
    .subscribe((user: User) =>
    {
        this.user = user;
    });
  }

  ngOnInit(): void
  {
    this._activatedRoute.paramMap.subscribe(params => {
      this.pageUrl = environment.domain_full_url + this._activatedRoute.snapshot['_routerState'].url;
    });

    // Horizontal stepper form
    this.feedbackForm = this._formBuilder.group({
      content: [''],
      pageurl: [''],
      subject: ['',Validators.required],
      message: ['',Validators.required],
      upload_files: new FormArray([new FormControl('')]),
    });
  }

  updateSelectedText(editor: Editor): void {
    const { from, to } = editor.state.selection;
    this.selectedText = editor.state.doc.textBetween(from, to, ' ');
  }

  get upload_files(): FormArray {
        return this.feedbackForm.get('upload_files') as FormArray;
  }

  onAddFiles(){
    this.upload_files.push(new FormControl());
  }

  save()
    {
      if ( this.feedbackForm.invalid )
        {
            return;
        }
      // Disable the form
      this.feedbackForm.disable();
    
      // Hide the alert
      this.showAlert = false;

      this.feedbak_loader = true;

      this.feedbackForm.get('pageurl').setValue(this.pageUrl);
      this.upload_files.removeAt(0);
      // console.log(this.selectedFileNames.length,this.selectedFiles.length);console.log(this.feedbackForm.value);
      if(this.selectedFileNames.length)this.uploadFiles();
      else this.feedback(this.feedbackForm.value);
  }

  feedback(values){
    // console.log(values);return;
    this._feedbackService.save(values)
        .subscribe(
            (response) =>
            {
                // Navigate to the confirmation required page
                if(response.success){
                    // Set the alert
                    this.alert = {
                        type   : 'success',
                        message: 'Thank you for submitting your feedback and helping us to improve your favourite platform. Our team will look into it immediately.',
                    };

                    // Re-enable the form
                    this.feedbackForm.enable();

                    // Reset the form
                    this.feedbackNgForm.resetForm();

                    this.selectedFileNames = [];
                    this.selectedFiles = [];
                    this.previews = [];
                    this.upload_files.clear();

                    this.showAlert = true;
                    
                    setTimeout(() => {
                        // Hide the alert
                        this.showAlert = false;
                    }, 2000);
                }
                else{
                    // Re-enable the form
                    this.feedbackForm.enable();

                    // Reset the form
                    this.feedbackNgForm.resetForm();

                    // Set the alert
                    this.alert = {
                        type   : 'error',
                        message: 'Something went wrong, please try again.',
                    };

                    // Show the alert
                    this.showAlert = true;

                    setTimeout(() => {
                        // Hide the alert
                        this.showAlert = false;
                    }, 2000);
                }
                this.feedbak_loader = false;
            },
            (response) =>
            {

                this.feedbak_loader = false;
                // Set the alert
                this.alert = {
                    type   : 'error',
                    message: (response.error && response.error.message)?response.error.message.message:'Something went wrong, please try again.',
                };
                // Show the alert
                this.showAlert = true;

                setTimeout(() => {
                    // Hide the alert
                    this.showAlert = false;
                }, 2000);

                // Re-enable the form
                this.feedbackForm.enable();

                // Reset the form
                this.feedbackNgForm.resetForm();

            }
        );
  }

  closeDialog(){
    this.dialog.closeAll();
  }

  saveFile(selectedFile): Promise<any> {
    return new Promise((resolve) => {
      // console.log("on Save File:",selectedFile);
      if (selectedFile) {
        let self = this;
        this._userService
          .postUserImage({
            filename: selectedFile.name,
            filetype: selectedFile.type,
          })
          .subscribe(
            (data) => {
              const uploadsignedURL = data.uploadURL;
              let imgfile;
              let fileReader = new FileReader();
              fileReader.readAsDataURL(selectedFile);
              fileReader.onload = function () {
                imgfile = fileReader.result;
                if (imgfile) {
                  let binary = atob(imgfile.split(',')[1]);
                  let array = [];
                  for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                  }
                  let uploadblobdata = new Blob([new Uint8Array(array)], {
                    type: selectedFile.type,
                  });

                  // let searchTerm = '&x-amz-tagging=';
                  // let index = uploadsignedURL.indexOf(searchTerm),
                  //   strOut =
                  //     index > -1
                  //       ? uploadsignedURL.substr(0, index)
                  //       : uploadsignedURL,
                  //   tagVal =
                  //     index > -1
                  //       ? decodeURIComponent(
                  //           uploadsignedURL.substr(index + searchTerm.length)
                  //         )
                  //       : '';
                  const result =fetch(uploadsignedURL, {
                    method: 'PUT',
                    body: uploadblobdata
                  });
                  result.then(response => {
                    if (response.ok) {
                      // File uploaded successfully
                      self.uploadedFileName = data.filename.split('?')[0].split('/').pop();
                      resolve(true);
                    } else {
                      // Error during file upload
                      console.error('Failed to upload file:', response.statusText);
                      resolve(false);
                    }
                  })
                  .catch(error => {
                    console.error('Error during file upload:', error);
                    resolve(false);
                  });
                }
              };
            },
            (err) => {
              console.log("error:",err);
              resolve(false);
            }
          );
      } else {
        resolve(true);
      }
    });
  }

  selectFiles(event: any): void {
    // Create a shallow copy of the file list
    const fileList: File[] = Array.from(event.target.files);
    
    fileList.forEach(file => {
        // Check if the file name is already in the selectedFileNames array
        if (!this.selectedFileNames.includes(file.name)) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                // Push the file preview into the previews array
                this.previews.push(e.target.result);
            };
            reader.readAsDataURL(file);

            // Add the file name to the selectedFileNames array
            this.selectedFileNames.push(file.name);

            // Add the file to the selectedFiles array
            this.selectedFiles.push(file);
        } else {
            console.warn(`File ${file.name} is already selected.`);
        }
    });

    // Reset the file input so the same file can be selected again if removed
    event.target.value = '';
  }


  // selectFiles(event: any): void {
  //   this.selectedFiles.push(event.target.files);
  //   this.uploadedFileName="";
  //   // console.log(this.previews);console.log(this.selectedFileNames,this.selectedFiles);
  //   this.selectedFiles.forEach(fileList => {
  //     for (let i = 0; i < fileList.length; i++) {
  //       const file = fileList[i];
  //       if (!this.selectedFileNames.includes(file.name)) {
  //         const reader = new FileReader();
  //         reader.onload = (e: any) => {
  //           this.previews.push(e.target.result);
  //         };
  //         reader.readAsDataURL(file);
  //         this.selectedFileNames.push(file.name);
  //       } else {
  //         console.warn(`File ${file.name} is already selected.`);
  //       }
  //       // if(event.target.files.length-1==i)event.target.value = '';
  //     }
  //   });
  //   // Reset the file input so the same file can be selected again if removed
  //   //   event.target.value = '';
  // }

  selectDoc(){
    document.getElementById('filefeedback').click();
  }

  async upload(idx: number, file: File) {
    await this.saveFile(file);
  }

  async uploadFiles(): Promise<void> {
    this.original_feedback = this.feedbackForm.value; 
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      await this.uploadFile(file);
    }
  }

  async uploadFile(file: File): Promise<void> {
    const result = await this.saveFile(file);
    if (result) {
      let temp_arr = JSON.parse(JSON.stringify(this.upload_files.value));
      this.upload_files.clear();
      temp_arr.push(this.uploadedFileName);
      temp_arr.forEach(element => {
          this.upload_files.push(new FormControl(element));
      });
      // this.feedbackForm.get('subject').setValue()
      // this.upload_files.push(new FormControl(this.uploadedFileName));
      if (this.selectedFileNames.length === this.upload_files.length) {
        this.original_feedback['upload_files'] = this.upload_files.value;
        this.feedback( this.original_feedback);
      }
    }
  }

  deleteImage(index){
    //console.log(this.previews);console.log(this.selectedFileNames);console.log(this.upload_files);
    this.previews.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.upload_files.removeAt(index);
  }

  getImageURL(url){
    return environment.file_url_cf+ url + '?token='+this._authService.accessToken
  }

}
