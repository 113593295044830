import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { CommonService } from 'app/core/common/common.service';
import { DateTime } from 'luxon';
import { UserService } from 'app/core/user/user.service';
import { NpsService } from 'app/core/nps/nps.service';
import { AnalyticsService } from 'app/core/common/analytics_service';
import analytics_event from 'app/core/common/analytics_event';
import { NpsComponent } from '../../nps/nps.component';

@Component({
  selector: 'app-setting',
  standalone: true,
  imports      : [MatIconModule, FormsModule,CurrencyPipe, MatProgressSpinnerModule, NgClass, NgFor, NgIf,NgTemplateOutlet, MatDialogModule,MatProgressBarModule,ReactiveFormsModule, MatStepperModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule],
  templateUrl: './setting.component.html',
  styleUrl: './setting.component.scss'
})
export class SettingComponent {

  tabGroupForm: UntypedFormGroup;
  flashMessage: string;
  flashMessageType: 'success' | 'error' | null = null;
  statuses: any[] = [];
  proposalStatusTypes: any[]=[];
  proposal_detail: any = {};
  type: string;
  assumption_loader = false;
  showModal;
  user:any;
  regexForStripHTML = /<[^>]*>/g;


  constructor(  
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private _router: Router,
    private _commonService: CommonService,
    private _proposalService: ProposalService,
    private _userService:UserService,
    private _npsService: NpsService,
    
    private _analyticsService:AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: {proposal_data: object}
  ){
    this.type = this.data['type'];
    this.proposal_detail = this.data['proposal_detail'];
    this.statuses = this._commonService.statusTypes;
    this.proposalStatusTypes = this._commonService.proposalStatusTypes;console.log(this.proposalStatusTypes);
    this._npsService.getStatus().subscribe();
    this._npsService.nps$.subscribe((response)=>{
      if(response)this.showModal=response['showModal'];
    });
  }

  ngOnInit(): void
  {
    // Create the selected product form
    this.tabGroupForm = this._formBuilder.group({
      name: ['', Validators.required],
      working_status: [this.proposal_detail['working_status'], Validators.required],
      client_name: [''],
      updated: [this.format(this.proposal_detail['updated']), Validators.required],
    });
    if(this.data['type']=='proposal' || this.data['type']=='project' || this.data['type']=='testing' || this.data['type']=='buildagent'){
      this.tabGroupForm.get('name').setValue(this.stripHTML(this.proposal_detail['name']));
      this.tabGroupForm.get('client_name').setValue(this.proposal_detail['client_name']);
    }

    this._userService.user$.subscribe((user)=>{
        this.user=user;
    });

    

     
    if(this.type=="proposal"){
      this._analyticsService.trackEvent(analytics_event.PROPOSAL_SETTINGS,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name,proposalName:this.data["name"]});
    }else if(this.type=="project"){
      this._analyticsService.trackEvent(analytics_event.PROJECT_SETTINGS,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name,proposalName:this.data["name"]});
    }else if(this.type=="testing"){
      this._analyticsService.trackEvent(analytics_event.TEST_PLAN_SETTINGS,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name,proposalName:this.data["name"]});
    }
 
  }

  stripHTML(text){
    return text.replaceAll(this.regexForStripHTML, '');
  }

  save(){
    try{
      if(this.tabGroupForm.get('working_status').value==2){
        this._analyticsService.trackEvent(analytics_event.PROPOSAL_COMPLETED,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name,proposalName:this.data["name"]});
      }
    }catch(error){
    }

    this.assumption_loader = true;
    if((this.type=='proposal' || this.type=='project')){
      this._proposalService.update({id:this.proposal_detail['_id'],settings:{name:this.tabGroupForm.get('name').value,client_name:this.tabGroupForm.get('client_name').value,working_status:this.tabGroupForm.get('working_status').value}})
      .subscribe(
        (value) =>
        {
          this._userService.clearCacheAndFetch().subscribe();
          if(this.assumption_loader)this.assumption_loader = false;
          this.dialog.closeAll();
        },
        (response) =>
        {
        },
      );
    }
    else if(this.type=='testing' || this.type=='buildagent'){
      this._proposalService.updateName({id:this.proposal_detail['_id'],name:this.tabGroupForm.get('name').value,build:this.type=='buildagent',testplan:this.type=='testing'})
      .subscribe(
        (value) =>
        {
          this._userService.clearCacheAndFetch().subscribe();
          if(this.assumption_loader)this.assumption_loader = false;
          this.dialog.closeAll();
        },
        (response) =>
        {
        },
      );
    }
  }

  createTemplate(){
    this.assumption_loader = true;
    this._proposalService.save({type:'template',id:this.proposal_detail['_id'],name:this.tabGroupForm.get('name').value,client_name:this.tabGroupForm.get('client_name').value})
    .subscribe(
      (value) =>
      {
        if(value.status){
          this._proposalService.update({id:value.data._id,selected:true})
            .subscribe(
              (value) =>
              {
                this.updateUser();
              },
              (response) =>
              {
              },
            );
            this.assumption_loader = false;
            this.dialog.closeAll();
            //Check and trigger nps if showModal
            if(this.showModal){
              const dialogRef = this.dialog.open(NpsComponent,{ 
                panelClass: 'custom-dialog-container-nps',
                disableClose: true,
                data: {
                  category: this.type=='proposal'?'Proposal':'Project'
                } 
              });
            }
            this._router.navigate(['/proposal/'+value.data._id]);
          // this.dialog.closeAll();
          // this._router.navigate(['/proposal/'+value.data._id]);
        }
        else {
          this.assumption_loader = false;
          this.showFlashMessage('error',value.message);
          setTimeout(() => {
            // localStorage.setItem('panel','plan-billing');
            if(this.dialog)this.dialog.closeAll();
            this._router.navigate(['/settings/pricing']);
          }, 3000);
        }
      },
      (response) =>
      {
        this.showFlashMessage('error','Error in creating the proposal');
        if(this.assumption_loader)this.assumption_loader = false;
      },
    );
  }

  showFlashMessage(type: 'success' | 'error',message): void
  {
      // Show the message
      this.flashMessageType = type;
      this.flashMessage = message;
  }

  updateUser(){
    this._userService.get().subscribe(()=>{
    });
  }

  format(date){
    const newdate = DateTime.fromISO(date);
    const humanReadable = newdate.toLocaleString(DateTime.DATE_FULL);
    return humanReadable;
  }

  getStatus(value){
    let statusType = this.proposalStatusTypes.find(el=>el.value==value);
    return statusType.label;
  }

}
