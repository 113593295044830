import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((userdata) =>
        {   //console.log("userdata",userdata);
            // If the user is authenticated...
            if ( userdata.isAuthenticated && !state.url.includes('/register') && !state.url.includes('/get-recommendation'))
            {
                if(userdata.additionalData && userdata.additionalData.roles && userdata.additionalData.roles.length){
                    if(userdata.additionalData.is_system_admin)return of(router.parseUrl('/admin/dashboard'));
                    else if(userdata.additionalData.roles.indexOf('pr1')>-1)return of(router.parseUrl('/proposal'));
                    else if(userdata.additionalData.roles.indexOf('ad1')>-1)return of(router.parseUrl('/sme/dashboard'));
                }
                else return of(router.parseUrl('/proposal'));
            }

            // Allow the access
            return of(true);
        }),
    );
};
