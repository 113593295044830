export default {
    SIGN_UP:"sign_up",
    SIGN_IN:"sign_in",
    LOGOUT:"logout",
    DOWNLOAD_PROPOSAL:"download_proposal",
    START_NEW_PROPOSAL:"start_new_proposal",
    PROPOSAL_COMPLETED:"proposal_completed",
    CONTACT_ENTERPRISE:"contact_enterprise",
    CANCEL_SUBSCRIPTION:"cancel_subscription",
    SUBSCRIBE_MONTHLY_PLAN:"subscribe_monthly_plan",
    SUBSCRIBE_YEARLY_PLAN:"subscribe_yearly_plan",
    UPGRADE_TO_YEARLY_PLAN:"upgrade_to_yearly_plan",
    UPGRADE_TO_MONTHLY_PLAN:"upgrade_to_monthly_plan",
    PAYMENT_FAILED:"payment_failed",
}