import { NgFor, NgIf, NgClass, NgStyle } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter,ElementRef,ViewChild,Renderer2 } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {  MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { IPrompt } from 'app/core/prompts/prompts.types';
import { PromptsService } from 'app/core/prompts/prompts.service';
import {v4 as uuidv4} from 'uuid';

import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor, HTMLElement } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Heading } from '@tiptap/extension-heading';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { DOMSerializer, DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';

import HTML from '@tiptap/html'
import { marked } from 'marked';
import Blockquote from '@tiptap/extension-blockquote';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Image from '@tiptap/extension-image';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';

import { QuickPromptComponent } from 'app/modules/common/quick-prompt/quick-prompt.component';
import { filter } from 'rxjs';

@Component({
  selector: 'app-editor-controls',
  standalone: true,
  imports      : [ QuickPromptComponent, NgClass, NgStyle, MatMenuModule, NgxTiptapModule,MatIconModule,FuseAlertComponent, MatProgressSpinnerModule, FormsModule, NgIf, MatDialogModule,MatProgressBarModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule,NgFor],
  templateUrl: './editor-controls.component.html',
  styleUrl: './editor-controls.component.scss'
})
export class EditorControlsComponent implements OnChanges {
    @ViewChild('toggleText') toggleButton: ElementRef;
    @ViewChild('textmenu') menu: ElementRef; @ViewChild('textArea') textArea!: ElementRef;
  
    @Input()
    menuPosition={ top: '500px', left: '80px' };
  
    @Input()
    boxWidth='460px';
  
    @Input()
    quickActionSelection:string="";
  
    // @Output()
    // onCompleteQuickAction1 = new EventEmitter<string>();
     
    selectedQuickPrompt:IPrompt=null;
  
    showCustomPrompt:boolean=false;
    
  
    customPrompt:string="";
    quickPromptOutput:string="";
  
    isLoading:boolean=false;
    isAIVisible:boolean=false;
  
    public promptTemplates=[];
    public quickPromptActions=[];
  

    @Input() editor!: Editor;
    @Input() selectedText: string = '';
    @Output() completeQuickAction = new EventEmitter<string>();
    quickSelectedText="";
    isQuickMenuHighlighted:boolean=false;

    textoverlay: boolean = false;

    editor_prompt = new Editor({
      extensions: [
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
        }),
        BulletList,
        ListItem,
        Underline,
        TextStyle,
        Color,
        Heading.configure({
          levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
        })
      ],
      parseOptions: {
        preserveWhitespace: "full",
      },
      editorProps: {
        attributes: {
          class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
          spellCheck: 'false',
        },
      }
    });

    constructor(private renderer: Renderer2,private _promptService:PromptsService,private _websocketService:WebsocketService){   
      // this.renderer.listen('window', 'click',(e:Event)=>{
      //  if(e.target !== this.toggleButton.nativeElement && e.target!==this.menu.nativeElement){
      //      this.textoverlay=false;
      //  }
      // });
      this.quickPromptActions=this._promptService.promptActions;
      // console.log(this.quickPromptActions);
      this._promptService.templatePrompts$.subscribe((response)=>{
        this.promptTemplates=response;
      });

      this._websocketService.messageSubject.pipe(
        filter((message) => message.type === "fix_grammar")
      ).subscribe((message) => {
          if(!message.isCompleted){
            this.quickPromptOutput+=message.data;
            // this.quickPromptOutput=this.stripMarkdownStyles(this.quickPromptOutput);
            this.scrollToBottom();
          }
          else if(message.isCompleted){
            this.isLoading=false;
            this.quickPromptOutput=this.stripMarkdownStyles(this.quickPromptOutput);
            // console.log("Final:",this.quickPromptOutput);
          }
      });
    }


    toggleTextBox() {
      this.textoverlay = !this.textoverlay;
    }


    ngOnInit(): void
    {
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.editor) {
          // console.log('Editor:', this.editor);
        }
        if (changes.selectedText) {
          // console.log('Selected Text:', this.selectedText);
          this.isAIVisible = false;
          this.quickSelectedText = this.selectedText;
          if(this.selectedText=='')this.onClickMenuButton();
        }
      }

    onMouseEnterQuickPrompt(){
        this.isQuickMenuHighlighted=true;
    }
    onMouseLeaveQuickPrompt(){
        this.isQuickMenuHighlighted=false;
    }

    onCompleteQuickAction(newValue){
        // console.log('newValue:', newValue);
        this.completeQuickAction.emit(newValue);
    }

    //Quick prompt
    onSelectQuickPrompt(quickPrompt:IPrompt){
      //console.log(quickPrompt,this.quickSelectedText);
      this.isAIVisible=true;
      this.showCustomPrompt=false;
      this.isLoading=true;
  
      if(quickPrompt.prompt=="" || quickPrompt.prompt.trim()==""){
        this.showCustomPrompt=true;
        this.isLoading=false;
      }
      this.selectedQuickPrompt=quickPrompt;
      
      if(quickPrompt.prompt!="" && quickPrompt.prompt.trim()!=""){
      let taskID=uuidv4();
  
      this._websocketService.sendFixGrammarCommand(this.quickSelectedText,quickPrompt.prompt,taskID);
      }
    }
  
    onApplyCustomPrompt(){
      if(this.customPrompt!=""){
        this.isLoading=true;
        let taskID=uuidv4();
        this._websocketService.sendFixGrammarCommand(this.quickSelectedText,this.customPrompt,taskID);     
      }
    }
  
    onSelectionQuickAction(action:any){
      // console.log("action:",action);
      // console.log("quickPromptOutput:",this.quickPromptOutput);
      // console.log("quickSelectedText:",this.quickSelectedText);
      if(action.label=="Replace Selection"){
        this.completeQuickAction.emit(this.quickPromptOutput);
        this.isAIVisible = false;
        this.selectedQuickPrompt=null;
        this.showCustomPrompt=false;
        this.quickPromptOutput="";
      }else if(action.label=="Insert Below"){
        let updateText=`${this.quickSelectedText}<br/>${this.quickPromptOutput} `;
        this.completeQuickAction.emit(updateText);
        this.isAIVisible = false;
        this.selectedQuickPrompt=null;
        this.showCustomPrompt=false;
        this.quickPromptOutput="";
      }else if(action.label=="Try Again"){
        this.isLoading=true;
        let taskID=uuidv4();
        this.quickPromptOutput="";
        this._websocketService.sendFixGrammarCommand(this.quickSelectedText,this.selectedQuickPrompt.prompt,taskID);
      }else if(action.label=="Delete"){
        this.isAIVisible = false;
        this.selectedQuickPrompt=null;
        this.showCustomPrompt=false;
        this.quickPromptOutput="";
      }
    }
  
    private scrollToBottom() {
      if(this.textArea && this.textArea.nativeElement){
      const element = this.textArea.nativeElement;
      element.scrollTop = element.scrollHeight;
      }
    }
  
    showQuickActions(){
      if(this.showCustomPrompt){
        //its custom prompt
        if(this.quickPromptOutput==""){
          return false;
        }else if(this.isLoading){
          return false;
        }
  
        return true;
      }else{
        return !this.isLoading;
      }
    }
  
    stripMarkdownStyles(markdownString) {
      // Remove bold (**) and italic (*) styles
      markdownString = markdownString.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove bold
      markdownString = markdownString.replace(/\*(.*?)\*/g, '$1'); // Remove italic
  
      // Remove headers (#, ##, ###, ####, etc.)
      markdownString = markdownString.replace(/^#{1,4}\s+(.*)$/gm, '$1'); // Remove headers
  
      // Remove inline code (`) and code blocks (```)
      markdownString = markdownString.replace(/`([^`]+)`/g, '$1'); // Remove inline code
      markdownString = markdownString.replace(/```[^`]*```/gs, ''); // Remove code blocks
  
      // Remove links ([text](url))
      markdownString = markdownString.replace(/\[(.*?)\]\(.*?\)/g, '$1'); // Remove links
  
      // Remove images (![alt](url))
      markdownString = markdownString.replace(/!\[.*?\]\(.*?\)/g, ''); // Remove images
  
      // Remove horizontal rules (---)
      markdownString = markdownString.replace(/^---+\s*$/gm, ''); // Remove horizontal rules
  
      // Trim whitespace
      return markdownString.trim();
    }
  
    onClickMenuButton(){
      this.selectedQuickPrompt=null;
      this.showCustomPrompt=false;
      this.quickPromptOutput="";
    }
    
    setColor(event: Event): void {
      const input = event.target as HTMLInputElement;
      const color = input.value;
      this.editor.chain().focus().setColor(color).run();
    }

    closeaimenu(event:any){
      if (!event){
        this.isAIVisible = false;
      }
    }

}
