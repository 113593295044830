<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="bg-card">
        <div class="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8">
            <div class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-8 sm:my-12">
                <!-- Avatar and name -->
                <div class="flex flex-auto items-center min-w-0">
                    <div class="flex flex-col min-w-0 ml-4 m-ml-0">
                        <ng-container >
                            <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">{{(agentStats && agentStats.hasOwnProperty('returning_user') && agentStats.returning_user)?'Welcome back':'Welcome'}}, {{user.personal.name.first}}!</div>
                        </ng-container>
                        <div class="flex items-center">
                            <div class="ml-1.5 leading-6 truncate text-secondary">You have {{agentStats && agentStats.hasOwnProperty('active_agent')?agentStats.active_agent:0}} active agents deployed.</div>
                        </div>
                    </div>
                </div>
                <!-- Actions -->
                <div class="flex items-center mt-6 sm:mt-0 sm:ml-2 space-x-3 " id="startBt">
                    <button
                        mat-flat-button
                        [color]="'primary'"   
                        class="animated-box shadow2"
                        [@shake]="true" (click)="demo()"
                        >
                        <mat-icon svgIcon="heroicons_outline:chat-bubble-bottom-center-text"></mat-icon>
                        <!-- <img src="assets/icons/agents/demo_book.svg" class="aiicon1" width="15" alt=""> -->
                        <span class="ml-2"> Book a Demo</span>
                    </button>
                </div>
            </div>
        </div>
    </div>




    <div class="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8 mt-8 ">

        <div class="flex flex-col flex-auto w-full mx-auto">
            <h2 class="text-2xl font-bold">Explore Agents</h2>
            <div class="agentscards flex-wrap flex gap-4 py-4">
                <div class="agentscard cursor-pointer">
                    <div class="normalstate presalesCard  flex-col gap-2 p-4 ">
                        <img src="assets/icons/agents/presales-white.svg" alt="" class="w-5">
                        <h4 class="text-3xl  text-white">Pre-Sales Agent</h4>
                        <span class="text-sm text-white font-normal">{{(agentStats && agentStats.hasOwnProperty('pre_sales_agent'))?agentStats.pre_sales_agent:0}} Active Projects</span>
                    </div>
                    <div class="hoverstate p-4 presalesCardhover flex-col gap-2" (click)="_router.navigate(['/proposal'])">
                        <img src="assets/icons/agents/presales-white.svg" alt="" class="icon-img" >
                        <h4 class="text-2xl  text-black" >Pre-Sales Agent</h4>
                        <p class="text-sm text-black" >Enrich needs analysis, automate proposal creation, and optimize solution design.</p>
                        <span class="text-sm font-medium" >Key Features:</span>
                        <div class="keyList flex gap-2 items-center " >
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Multi modal input analysis</span>
                        </div>
                        <div class="keyList flex gap-2 items-center " >
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Generate Salesforce Clouds and  Industries specific solution</span>
                        </div>
                        <div class="keyList flex gap-2 items-center " >
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Generate project schedule and resourcing plan</span>
                        </div>
                        <div class="keyList flex gap-2 items-center " >
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Enable collaboration cross teams and  roles</span>
                        </div>
                        <button mat-flat-button [color]="'primary'"   class="mt-2">Explore Agent Dashboard</button>
                        <a style="font-size: 13px;" class="w-full text-center flex justify-center gap-1 items-center text-[#4F45E4]" (click)="openDialog('Proposal')"><mat-icon class="text-[#4F45E4] icon-size-4" svgIcon="heroicons_outline:plus-circle"></mat-icon>{{isPaid?'Start New Proposal with AI':'Subscribe to Create Proposal with AI'}}</a>
                    </div>
                </div>

                <div class="agentscard  cursor-pointer">
                    <div class="normalstate designCard flex-col gap-2 p-4">
                        <img src="assets/icons/agents/design-white.svg" alt="" class="w-5">
                        <h4 class="text-3xl  text-white">Design Agent</h4>
                        <span class="text-sm text-white font-normal">{{(agentStats && agentStats.hasOwnProperty('design_agent'))?agentStats.design_agent:0}} Active Projects</span>
                    </div>
                    <div class="hoverstate p-4  designCardhover flex-col gap-2" (click)="_router.navigate(['/project'])" >
                        <img src="assets/icons/agents/design-white.svg" alt="" class="icon-img">
                        <h4 class="text-2xl text-black">Design Agent</h4>
                        <p class="text-sm text-black">Enrich needs analysis, automate proposal creation, and optimize solution design.</p>
                        <span class="text-sm font-medium">Key Features:</span>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Run discovery and process complex inputs.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Generate low-level solution designs.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Generate User Stories and Acceptance Criteria.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Comprehensive project setup in Jira. </span>
                        </div>
                        <button mat-flat-button [color]="'primary'"   class="mt-2">Explore Agent Dashboard</button>
                        <a style="font-size: 13px;" class="w-full text-center flex justify-center gap-1 items-center text-[#4F45E4]" (click)="openDialog('Project')"><mat-icon class="text-[#4F45E4] icon-size-4" svgIcon="heroicons_outline:plus-circle"></mat-icon>{{isPaid?'Start New Project with AI':'Subscribe to Create Project with AI'}}</a>
                    </div>
                </div>

                <div class="agentscard  cursor-pointer">
                    <div class="normalstate testCard flex-col gap-2 p-4">
                        <img src="assets/icons/agents/test-white-gray.svg" alt="" style="width: 30px;">
                        <h4 class="text-3xl  text-white">Test Agent</h4>
                        <span class="text-sm text-white font-normal">{{(agentStats && agentStats.hasOwnProperty('test_agent'))?agentStats.test_agent:0}} Active Projects</span>
                    </div>
                    <div class="hoverstate p-4 testCardhover flex-col gap-2"  (click)="_router.navigate(['/testing'])" >
                        <img src="assets/icons/agents/test-white.svg" alt="" class="icon-img" style="width: 40px !important; height: 40px;">
                        <h4 class="text-2xl text-black">Test Agent</h4>
                        <p class="text-sm text-black">Enrich needs analysis, automate proposal creation, and optimize solution design.</p>
                        <span class="text-sm font-medium">Key Features:</span>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Create comprehensive test strategies.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Generate test cases.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Manage test runs and results.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Manage defects.</span>
                        </div>
                        <button mat-flat-button [color]="'primary'" class="mt-2" >Explore Agent Dashboard</button>
                        <a style="font-size: 13px;" class="w-full text-center flex justify-center gap-1 items-center text-[#4F45E4]" (click)="openDialog('Testing')"><mat-icon class="text-[#4F45E4] icon-size-4" svgIcon="heroicons_outline:plus-circle"></mat-icon> {{isPaid?'Start New Test Plan with AI':'Subscribe to Create Test Plan with AI'}}</a>
                    </div>
                </div>

                <div class="agentscard  cursor-pointer">
                    <div class="normalstate buildCard flex-col gap-2 p-4">
                        <img src="assets/icons/agents/build-white.svg" alt="" class="w-5">
                        <h4 class="text-3xl  text-white">Build Agent</h4>
                        <span class="text-sm text-white font-normal">{{(agentStats && agentStats.hasOwnProperty('build_agent'))?agentStats.build_agent:0}} Active Projects</span>
                    </div>
                    <div class="hoverstate p-4 buildCardhover flex-col gap-2" (click)="_router.navigate(['/builds'])" >
                        <img src="assets/icons/agents/build-white.svg" alt="" class="icon-img">
                        <h4 class="text-2xl text-black">Build Agent</h4>
                        <p class="text-sm text-black">Enrich needs analysis, automate proposal creation, and optimize solution design.</p>
                        <span class="text-sm font-medium">Key Features:</span>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Identify new metadata from low-level designs.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Generate custom code.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Analyze existing org metadata.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Integrate with AI development tools.</span>
                        </div>
                        <button mat-flat-button [color]="'primary'"  class="mt-2" >Explore Agent Dashboard</button>
                        <a style="font-size: 13px;" class="w-full text-center flex justify-center gap-1 items-center text-[#4F45E4]" (click)="openDialog('Build')"><mat-icon class="text-[#4F45E4] icon-size-4" svgIcon="heroicons_outline:plus-circle"></mat-icon> {{isPaid?'Start New Build with AI':'Subscribe to Create Build with AI'}}</a>
                    </div>
                </div>

                <div class="agentscard  ">
                    <div class="normalstate goliveCard flex-col gap-2 p-4">
                        <img src="assets/icons/agents/golive-white.svg" alt="" class="w-5">
                        <h4 class="text-3xl  text-white">Go-Live Agent</h4>
                        <span class="text-sm text-white font-normal">{{(agentStats && agentStats.hasOwnProperty('go_live_agent'))?agentStats.go_live_agent:0}} Active Projects</span>
                    </div>
                    <div class="hoverstate p-4 goliveCardhover flex-col gap-2">
                        <img src="assets/icons/agents/golive-white.svg" alt="" class="icon-img">
                        <h4 class="text-2xl text-black">Go-Live Agent</h4>
                        <p class="text-sm text-black">Enrich needs analysis, automate proposal creation, and optimize solution design.</p>
                        <span class="text-sm font-medium">Key Features:</span>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Generate deployment plans.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Generate Go-Live and post-Go-Live support plans.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Streamline the deployment process.</span>
                        </div>
                        <div class="keyList flex gap-2 items-center ">
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="icon-size-5 text-[#000000]"></mat-icon>
                            <span class="text-md">Integrate with AI DevOps tools.</span>
                        </div>
                        <button mat-flat-button [color]="'primary'" class="mt-2 " disabled style="background: #94A3B8;    color: #fff !important;">Coming Soon</button>
                        <!-- <a style="font-size: 13px;" class="w-full text-center flex justify-center gap-1 items-center text-[#4F45E4]" (click)="request()"><mat-icon class="text-[#4F45E4] icon-size-4" svgIcon="heroicons_outline:plus-circle"></mat-icon> Request Early Access</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>        



    <div class="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8 mt-8 ">

        <div class="flex flex-col flex-auto w-full mx-auto">
            <h2 class="text-2xl font-bold">Active Agents List</h2>



            <div class="tablebox bg-card border mb-40  rounded mt-6">
                <div class=" tablebox p-4">
                    <!-- Header Section -->
                    <section class="flex flex-row flex-wrap justify-end px-3 w-full">
                        <form [formGroup]="headerForm" class="flex flex-wrap gap-2 justify-center ">
                            <mat-form-field  class="fuse-mat-dense searchTab min-w-72">
                                <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                <input matInput class="text-sm font-medium" [formControlName]="'search'" [autocomplete]="'off'" [placeholder]="'Search'" (input)="filterCount()">
                            </mat-form-field>
                            <!-- Add filters -->
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm cusTDrp" style="min-width: 170px;">
                                <svg width="20" class="mr-2 min-w-5" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 3V11.5C1 12.163 1.26339 12.7989 1.73223 13.2678C2.20107 13.7366 2.83696 14 3.5 14H4.56C5.0098 13.9991 5.45365 13.8971 5.8587 13.7015C6.26374 13.5059 6.61962 13.2217 6.9 12.87L8.44 10.95C8.6274 10.7164 8.86487 10.5279 9.13488 10.3984C9.40488 10.2688 9.70053 10.2016 10 10.2016C10.2995 10.2016 10.5951 10.2688 10.8651 10.3984C11.1351 10.5279 11.3726 10.7164 11.56 10.95L13.1 12.87C13.3804 13.2217 13.7363 13.5059 14.1413 13.7015C14.5464 13.8971 14.9902 13.9991 15.44 14H16.5C17.163 14 17.7989 13.7366 18.2678 13.2678C18.7366 12.7989 19 12.163 19 11.5V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5 7C5 7.26522 5.10536 7.51957 5.29289 7.70711C5.48043 7.89464 5.73478 8 6 8C6.26522 8 6.51957 7.89464 6.70711 7.70711C6.89464 7.51957 7 7.26522 7 7C7 6.73478 6.89464 6.48043 6.70711 6.29289C6.51957 6.10536 6.26522 6 6 6C5.73478 6 5.48043 6.10536 5.29289 6.29289C5.10536 6.48043 5 6.73478 5 7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13 7C13 7.26522 13.1054 7.51957 13.2929 7.70711C13.4804 7.89464 13.7348 8 14 8C14.2652 8 14.5196 7.89464 14.7071 7.70711C14.8946 7.51957 15 7.26522 15 7C15 6.73478 14.8946 6.48043 14.7071 6.29289C14.5196 6.10536 14.2652 6 14 6C13.7348 6 13.4804 6.10536 13.2929 6.29289C13.1054 6.48043 13 6.73478 13 7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                                <mat-select [formControlName]="'agent'" [placeholder]="'Agents'"  (selectionChange)="filterCount()" multiple>
                                    @for (option of agents; track option) {
                                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <!-- <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab   ">
                                <svg width="24" class="mr-2" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0013 22.8346C17.9844 22.8346 22.8346 17.9844 22.8346 12.0013C22.8346 6.01822 17.9844 1.16797 12.0013 1.16797C6.01822 1.16797 1.16797 6.01822 1.16797 12.0013C1.16797 17.9844 6.01822 22.8346 12.0013 22.8346Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.9987 14.1654C13.1953 14.1654 14.1654 13.1953 14.1654 11.9987C14.1654 10.8021 13.1953 9.83203 11.9987 9.83203C10.8021 9.83203 9.83203 10.8021 9.83203 11.9987C9.83203 13.1953 10.8021 14.1654 11.9987 14.1654Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <mat-select [formControlName]="'agent'" [placeholder]="'Agents'"  (selectionChange)="filterCount()">
                                    @for (option of agents; track option) {
                                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                                    }
                                </mat-select>
                               
                                    
                            </mat-form-field> -->
                            <!-- <mat-form-field  class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 ">
                                <mat-select  [placeholder]="'Status'" >
                                    <mat-option>Active</mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:flag'"></mat-icon>
                            </mat-form-field> -->
                        </form>
                    </section>
                </div>
    
            
                <div class="max-w-full flex flex-col mt-6 tableD overflow-auto" >  
                    <table mat-table [dataSource]="dataSource">

                      
                        <ng-container matColumnDef="agent">
                          <th mat-header-cell class="table-th" *matHeaderCellDef> Agent Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.agent}} </td>
                        </ng-container>
                      
                        <ng-container matColumnDef="name">
                          <th mat-header-cell class="table-th" *matHeaderCellDef> Project Name </th>
                          <td mat-cell *matCellDef="let element" [innerHTML]="element.name"></td>
                        </ng-container>
                      
                        <!-- <ng-container matColumnDef="status">
                          <th mat-header-cell class="table-th" *matHeaderCellDef> Status </th>
                          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                        </ng-container> -->
                      
                        <ng-container matColumnDef="author">
                          <th mat-header-cell class="table-th" *matHeaderCellDef> Created By </th>
                          <td mat-cell *matCellDef="let element"> {{element.author}} </td>
                        </ng-container>

                        <ng-container matColumnDef="created">
                            <th mat-header-cell class="table-th" *matHeaderCellDef> Created On </th>
                            <td mat-cell *matCellDef="let element"> 
                                <div class="flex justify-between gap-2">{{format(element.created)}}  
                                    <button class="viewdetailBt" (click)="navigate(element)">View Details</button>
                                </div>
                            </td>
                          </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <div class="nodata flex-col gap-4" *ngIf="dataSource.length === 0">
                        <mat-icon svgIcon="heroicons_outline:exclamation-circle"></mat-icon>
                        <span>No Data found</span>
                    </div>


                </div>




            </div>



            <!-- <div class="nodata flex-col gap-4">
                <mat-icon svgIcon="heroicons_outline:exclamation-circle"></mat-icon>
                <span>No Data found</span>
            </div> -->


        </div>
    </div>   



</div>