import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, JsonPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Component, OnDestroy, OnInit, ViewEncapsulation,ViewChild, Input, AfterViewInit, TemplateRef, AfterViewChecked, ElementRef, ViewChildren, QueryList, HostListener, Output, EventEmitter, Renderer2  } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators,UntypedFormGroup,UntypedFormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule,MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { PricingPlanService } from 'app/core/pricing-plan/pricing-plan.service';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { CommonService } from 'app/core/common/common.service';
import { finalize, Subject, takeUntil, Observable, Subscription, interval, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, takeWhile, filter} from 'rxjs/operators';
import { MatSliderChange, MatSliderModule } from '@angular/material/slider';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { IProposal } from 'app/core/proposal/proposal.types';
import { FeatureComponent } from 'app/modules/user/common/feature/feature.component';
import Speech from 'speak-tts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SettingComponent } from 'app/modules/user/common/setting/setting.component';
import { ShareComponent } from 'app/modules/user/common/share/share.component';
import {v4 as uuidv4} from 'uuid';
import { QuillEditorComponent } from 'ngx-quill';
import { ImageCroppedEvent, ImageCropperModule, LoadedImage } from 'ngx-image-cropper';
import { NpsService } from 'app/core/nps/nps.service';
import { AnalyticsService } from 'app/core/common/analytics_service';
import analytics_event from 'app/core/common/analytics_event';
import { PromptsService } from 'app/core/prompts/prompts.service';
import { QuickPromptComponent } from 'app/modules/common/quick-prompt/quick-prompt.component';
import { NpsComponent } from '../../nps/nps.component';
import { QuickPromptTipComponent } from 'app/modules/common/quick-prompt-tip/quick-prompt-tip.component';
import * as introJs from 'intro.js';
import { MatTabGroup } from '@angular/material/tabs';
import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor, HTMLElement } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Heading } from '@tiptap/extension-heading';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { DOMSerializer, DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';
import moment from 'moment';
import { marked } from 'marked';
import { TextSelection } from '@tiptap/pm/state';
import { AiInsightsComponent } from "../../common/ai-insights/ai-insights.component";
import { DocumentedAiComponent } from "../../common/documented-ai/documented-ai.component";
import { ResourceAllocationComponent } from "../../common/resource-allocation/resource-allocation.component";
import { SolutionHeaderComponent } from "../../common/solution-header/solution-header.component";
import { RequirementsViewComponent } from "../../common/requirements-view/requirements-view.component";
import { WorkspaceViewComponent } from '../../common/workspace-view/workspace-view.component';
import { JiraIntegrationComponent } from 'app/modules/common/jira-integration/jira-integration.component';
import { ManageReleaseComponent } from '../../common/manage-release/manage-release.component';
import { MetadataComponent } from '../../common/metadata/metadata.component';
import { UserstoryViewComponent } from "../../common/userstory-view/userstory-view.component";
import { GeneratingProgressBarComponent } from 'app/modules/common/generating-progress-bar/generating-progress-bar.component';
import { RegeneratingProgressBarComponent } from 'app/modules/common/regenerating-progress-bar/regenerating-progress-bar.component';
import { EventService } from 'app/core/common/event.service';

declare var webkitSpeechRecognition: any;


export interface Feature
{
    _id: string;
    name: string;
    technology: string;
    description: string;
    assumption: string;
    complexity: string;
    cloud: string;
    effort: string;
    epicName: string;
    release: string;
    releaseName: string;
    epic: string;
    theme: string;
    componentsCount: number;
    masterFeatureID: string;
    solutionAssumption: string;
    featureDescription: string;
}


@Component({
  selector: 'app-detail',
  standalone: true,
  imports: [GeneratingProgressBarComponent,RegeneratingProgressBarComponent,SolutionHeaderComponent, WorkspaceViewComponent, ManageReleaseComponent, RequirementsViewComponent, DocumentedAiComponent, ResourceAllocationComponent, EditorControlsComponent, NgxTiptapModule, ImageCropperModule, QuickPromptTipComponent, MatIconModule, FuseDrawerComponent, FuseAlertComponent, MatButtonModule, MatSliderModule, MatSort, MatSelectModule, MatPaginator, MatPaginatorModule, FormsModule, MatRippleModule, ReactiveFormsModule, MatMenuModule, MatTabsModule, MatFormFieldModule, NgTemplateOutlet, MatInputModule, MatButtonToggleModule, NgFor, NgIf, MatSortModule, MatTableModule, NgClass, MatProgressSpinnerModule, MatSlideToggleModule, MatProgressBarModule, CurrencyPipe, JsonPipe, QuillEditorComponent, MetadataComponent,CommonModule, QuickPromptComponent, AiInsightsComponent, SolutionHeaderComponent, RequirementsViewComponent, UserstoryViewComponent],
  templateUrl: './detail.component.html',
  styleUrl: './detail.component.scss',
  animations     : fuseAnimations,
  // changeDetection: ChangeDetectionStrategy.OnPush

})

export class DetailComponent implements OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild(MatSort) private _sort: MatSort;
  @ViewChild('downloadDrawer', { static: false }) downloadDrawer!: FuseDrawerComponent;
  // @ViewChild(QuillEditorComponent, { static: true })editor: QuillEditorComponent;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChildren('solutionEditor') solutionEditors: QueryList<ElementRef>;
  @ViewChildren('featureEditor') featureEditors: QueryList<ElementRef>;

  @ViewChild('workspace', { static: false }) workspace: WorkspaceViewComponent;

  @Output() dataChange: EventEmitter<string> = new EventEmitter();

  introJS = introJs.default();
  hasWorkspaceView:boolean=true;

  private $changeValues = new Subject<object>();
  private unsubscribe$ = new Subject<void>();
  private promptTemplates=[];
  private quickPromptActions=[];
  private selectedWorkSpace=0;
  private workspaceMeta=[
    {title:"Solution Workspace",description:"Lorem ipsum dolor sit amet consectetur. Duis mauris diam praesent malesuada ut sit posuere ullamcorper nam.  "},
    {title:"Detailed Solution",description:"Breakdown of solutions by modules with corresponding effort. "},
    {title:"Requirement Features",description:"Lorem ipsum dolor sit amet consectetur. Duis mauris diam praesent malesuada ut sit posuere ullamcorper nam.  "},
  ];

  private subscription: Subscription;
  private selectedFeatureData={
    type:"",
    index:0
  }

  nextAct:any;
  isMobile: boolean = false;
  quickAction: boolean = false;
  mouseInitialPosition=null;
  isQuickMenuHighlighted:boolean=false;
  showQuickPromptMenu = false;
  menuPosition = { top: '500px', left: '80px' };
  showModal;
  user:any;
  _proposal_id: string;
  _page_id: string;
  flashMessage: string;
  selectedIndex: number;
  flashMessageType: 'success' | 'error' | null = null;
  isLoading = false;
  btLoader = false;
  recording = false;
  generate_loader = false;
  generate_replace = false;
  generate_append = false;
  generate_grammar = false;
  download_doc_loader = false;
  flashMessageDelete = false;
  download_summary_loader = false;
  download_pdf_loader = false;
  assumption_loader = false;
  showcustomprompt = false;
  loadingFeature = false;
  loadingMetadata = false;
  is_write_allowed = false;
  is_share_visible = false;
  isPaid = true;
  generatingtitle:string;
  totalFeatures = 0;
  featuresFetched = 0;
  remainingProgress = 0;
  speech: any;
  speechData: any;
  drawerGroupForm: UntypedFormGroup;
  tabGroupForm: UntypedFormGroup;
  downloadGroupForm: UntypedFormGroup;
  selectedFeature: Feature | null = null;
  proposal_detail: any = {};
  original_features: any [] =[];
  releases_list:any=[];
  speechRecognition;
  proposal_share_status= false;
  proposal_acess_status= '';
  phaseResourcesArray: FormArray<any>;
  phasesArray: FormArray<any>;
  resourcesArray: FormArray<any>;
  featuresArray: FormArray<any>;
  costGroup: FormGroup<any>;
  statsGroup: FormGroup<any>;
  effortGroup: FormGroup<any>;
  metaGroup: FormGroup<any>;
  paymentDetails$: Observable<any>;
  costDetails$: Observable<any>;
  regexForStripHTML = /<[^>]*>/g;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageBlob: any = '';
  imageDetail: any;
  pro_phase_data: any[] = [];
  original_phases: any [] =[];
  pro_resource_data: any[] = [];
  original_resources: any [] =[];
  epicTypes: any[] = [];
  allEpics=[];
  themeTypes: any[] = [];
  themeListTypes: any[] = [];
  cloudTypes: any[] = [];
  indicators: any[] = [];
  pro_data: Feature[] = [];
  complexities: any[] = [];
  intervalId: any;
  progressValue = 0;
  intervalSubscription: Subscription | undefined;
  quillModules: any = {
    // toolbar: [
    //   ['bold', 'italic', 'underline', 'strike'],
    //   ['blockquote', 'code-block'],
    //   [{ 'size': ['small', false, 'large', 'huge'] }],
    //   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    //   [{ list: 'ordered' }, { list: 'bullet' }],
    //   [{ script: 'sub' }, { script: 'super' }],
    //   [{ indent: '-1' }, { indent: '+1' }],
    //   [{ direction: 'rtl' }],
    //   [{ color: [] }, { background: [] }],
    //   [{ align: [] }]
    // ],
    toolbar: false,
  };
  quickSelectedText="";
  insideTextArea=false;
  mouseDownPos=0;
  show_view_count=false;
  quickSelectionStartIndex=-1;
  quickSelectionEndIndex=-1;
  selectedText: string = '';
  selectedTargetIdEditor: string = '';
  beforeHTML;afterHTML;
  initial_load = false;
  quickActionIds: any[]=[];
  totalThemes=10;
  totalEpics=2;
  totalHours=30;
  totalDevHours=0;

  parentData: string = 'Initial data from Parent';
  @ViewChild('scrollableContainer') scrollableContainer!: ElementRef;

  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'];
  middleIndex = 1; 


  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'summary',content);
    }
  });

  editor1 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a scope about your project summary',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'scope',content);
    }
  });

  editor2 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a description',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });

  editor3 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a description',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });

  summaryEditor1 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short overview of your project.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'summaryOverview',content);
    }
  });

  summaryEditor2 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about the customer.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'aboutCustomer',content);
    }
  });

  summaryEditor3 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Please provide a detailed description of the challenges faced in your project.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'projectChallenges',content);
    }
  });

  summaryEditor4 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Please provide a detailed description of your project requirements.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      // const content = editor.getHTML();
      // this.tabGroupForm.get('projectRequirements').setValue(content, { emitEvent: true });
      // if(content && content!='<p></p>')this.captureChangeSummary({}, 'projectRequirements',content);
      // this.cdr.detectChanges();
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'projectRequirements',content);
    }
  });

  summaryEditor5 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Please provide a detailed description of the expected project outcomes.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      // this.tabGroupForm.get('expectedOutcomes').setValue(content, { emitEvent: true });
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'expectedOutcomes',content);
      // this.cdr.detectChanges();
    }
  });

  summaryEditor6 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Briefly describe your company, unique strengths, and why you’re the right choice for this project. You can also add this information under the Company Information tab so the system can automatically use it for future proposals.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      // this.tabGroupForm.get('aboutus').setValue(content, { emitEvent: true });
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'aboutUs',content);
      // this.cdr.detectChanges();
    }
  });

  summaryEditor7 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Summarize key service terms, policies, and guidelines for using your services. You can also add this information under the Company Information tab so the system can automatically include it in future proposals.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      // this.tabGroupForm.get('terms').setValue(content, { emitEvent: true });
      // Ensure this captures the new value after the update
      if(content && content !== '<p></p>') {
        this.captureChangeSummary({}, 'terms', content);
      }
      // this.cdr.detectChanges();
    }
  });

  scopeeditor1 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write an overview of your project scope.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'scopeOverview',content);
    }
  });

  scopeeditor2 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a detailed scope of project.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'projectScope',content);
    }
  });

  scopeeditor3 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write any requirements that are not included in the scope of this project.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'outOfScope',content);
    }
  });

  editor_summaryOverview_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_aboutCustomer_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_projectRequirements_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_projectChallenges_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_expectedOutcomes_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_aboutus_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_terms_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_scopeOverview_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_projectScope_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_outOfScope_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_feature_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  editor_solution_readonly = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short description about your project summary.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    editable: false,
    enablePasteRules: false,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    }
  });

  showInsights:boolean=true;

  constructor( 
    private zone: NgZone,
    private breakpointObserver: BreakpointObserver,
    private _formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private _npsService: NpsService,
    private _authService: AuthService,
    private _userService: UserService,
    private _commonService: CommonService,
    private _proposalService: ProposalService,
    private _websocketService: WebsocketService,
    private _confirmationService:FuseConfirmationService,
    private _pricingPlanService: PricingPlanService,
    public _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _analyticsService:AnalyticsService,
    public dialog: MatDialog,
    private eventService: EventService,
    private _promptService:PromptsService
  ){
    this._promptService.getTemplatePrompts().subscribe();

    try{
      this.speechRecognition = new webkitSpeechRecognition();
      this.speech = new Speech(); // will throw an exception if not browser supported
      if (this.speech.hasBrowserSupport()) {
        console.log(
          'speech synthesis supported',
          this.speech.hasBrowserSupport()
        );
        this.speech
          .init({
            volume: 1,
            lang: 'en-GB',
            rate: 1.2,
            pitch: 1,
            voice: 'Google UK English Female',
            splitSentences: true,
            // listeners: {
            //   onvoiceschanged: (voices) => {
            //     console.log('Event voiceschanged', voices);
            //   },
            // },
          })
          .then((data) => {
            // The "data" object contains the list of available voices and the voice synthesis params
            this.speechData = data;
          })
          .catch((e) => {
            console.error('An error occured while initializing : ', e);
          });
      }
    }catch(error){
    }

    this._pricingPlanService.getStatus().subscribe((response) => {
      if(response)this.isPaid=((response['subscription_status']=='CONTINUE' && response.remaining_data.proposal.status) || (response['subscription_status']=='CANCELLED' && response.remaining_data.proposal.status))?true:false;
    });

    this._npsService.getStatus().subscribe();
    this._npsService.nps$.subscribe((response)=>{
      if(response)this.showModal=response['showModal'];
    });

    this.complexities = this._commonService.complexities;
    if(localStorage.getItem("selectedIndex")!=null){
      this.selectedIndexChange(parseInt(localStorage.getItem("selectedIndex")));
      this.selectedIndex=parseInt(localStorage.getItem("selectedIndex"));
    }


    this.$changeValues
    .pipe(
      debounceTime(500),
      switchMap((obj) =>
        this._proposalService
          .update(obj)
          .pipe(takeUntil(this.unsubscribe$))
      )
    )
    .subscribe((results) => {

    },
    (error) => {
      
    });
     

   

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "connection")
    ).subscribe((message) => {
        console.log("Received:", message);
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "fix_grammar")
    ).subscribe((message) => {
        // console.log("Received:", message);
        if(!message.isCompleted && message.taskID == this.drawerGroupForm.get('taskID').value){
          let stripContent = this.drawerGroupForm.get('modified').value + message.data;
          this.drawerGroupForm.get('modified').setValue(stripContent);
        }
        else if(message.isCompleted){
          this.generate_grammar = false;
          this.generate_loader = false;
        }
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "executive_summary")
    ).subscribe(async(message) => {
        // console.log("Received:", message);
        if(message.taskID==this._proposal_id && !this.tabGroupForm.get('summaryOverview').value){
          if(message.data){
            this.proposal_detail.executiveSummary=message.data;
            let overview,aboutCustomer,projectChallenges,projectRequirements,expectedOutcomes,aboutUs,terms="";
            
            if(this.proposal_detail.executiveSummary){

              if(typeof(this.proposal_detail.executiveSummary)=="string"){
                
                overview=await marked.parse(this.proposal_detail.executiveSummary);
              }else{
                //parse parts
                overview=await marked.parse(this.proposal_detail.executiveSummary.overview) || "";
                aboutCustomer=await marked.parse(this.proposal_detail.executiveSummary.aboutCustomer) || "";
                projectChallenges=await marked.parse(this.proposal_detail.executiveSummary.projectChallenges) || "";
                projectRequirements=await marked.parse(this.proposal_detail.executiveSummary.projectRequirements) || "";
                expectedOutcomes=await marked.parse(this.proposal_detail.executiveSummary.expectedOutcomes) || "";
                aboutUs=await marked.parse(this.proposal_detail.executiveSummary.aboutUs) || "";
                terms=await marked.parse(this.proposal_detail.executiveSummary.terms) || "";
              }

              this.tabGroupForm.get('summaryOverview').setValue(overview);
              this.tabGroupForm.get('aboutCustomer').setValue(aboutCustomer);
              this.tabGroupForm.get('projectChallenges').setValue(projectChallenges);
              this.tabGroupForm.get('projectRequirements').setValue(projectRequirements);
              this.tabGroupForm.get('expectedOutcomes').setValue(expectedOutcomes);

              this.tabGroupForm.get('aboutus').setValue(aboutUs);
              this.tabGroupForm.get('terms').setValue(terms);

            }

            /*
            let markedsummaryStrip = marked.parse(message.data);
            let summaryStrip = this.replaceNewlines(markedsummaryStrip.toString());
            this.tabGroupForm.get('executiveSummary').setValue(summaryStrip);
            this.proposal_detail.executiveSummary=summaryStrip;
            */


            this.calculateCurrentProgress();
          }
        }
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "scope")
    ).subscribe(async(message) => {
        // console.log("Received:", message);
        if(message.taskID==this._proposal_id && !this.tabGroupForm.get('scopeOverview').value){
          if(message.data){

            this.proposal_detail.scope=message.data;
            let scopeOverview,projectScope,outOfScope="";

            if(this.proposal_detail.scope){

              if(typeof(this.proposal_detail.scope)=="string"){
                scopeOverview=await marked.parse(this.proposal_detail.scope);
              }else{
                //parse parts
                scopeOverview=await marked.parse(this.proposal_detail.scope.overview) || "";
                projectScope=await marked.parse(this.proposal_detail.scope.scope) || "";
                outOfScope=await marked.parse(this.proposal_detail.scope.outOfScope) || "";

              }
              this.tabGroupForm.get('scopeOverview').setValue(scopeOverview);
              this.tabGroupForm.get('projectScope').setValue(projectScope);
              this.tabGroupForm.get('outOfScope').setValue(outOfScope);


              // let markedscopeStrip = marked.parse(this.proposal_detail.scope);
              // let scopeStrip = this.replaceNewlines(markedscopeStrip.toString());
              // this.tabGroupForm.get('scope').setValue(scopeStrip);
            }

            /*
            let markedscopeStrip = marked.parse(message.data);
            let scopeStrip = this.replaceNewlines(markedscopeStrip.toString());
            this.tabGroupForm.get('scope').setValue(scopeStrip);
            this.proposal_detail.scope=scopeStrip;
            */
            this.calculateCurrentProgress();
          }
        }
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "features_count")
    ).subscribe((message) => {
      if(message.taskID==this._proposal_id){
        this.totalFeatures = parseInt(message.data);
       // this.remainingProgress = (100 - this.progressValue)/this.totalFeatures;
      }
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "proposal_feature")
    ).subscribe((message) => {
        // console.log("Received:", message,this.progressValue);
        if(!message.isCompleted && message.taskID==this._proposal_id){
          this.featuresFetched++;
          let features = this.tabGroupForm.get('features').value;
          this.pro_data = JSON.parse(JSON.stringify(features));
          let featureData = JSON.parse(JSON.stringify(message['data']));
          this.pro_data.push({
            _id: '',
            name: featureData.name,
            technology: featureData.technology,
            description: featureData.featureDescription,
            assumption: featureData.solutionAssumption,
            cloud: featureData.cloud,
            complexity: featureData.complexity,
            effort: featureData.effort,
            epicName: featureData.epicName,
            epic            : featureData.epic,
            release: featureData.release,
            releaseName: featureData.releaseName,
            theme: featureData.theme,
            componentsCount: featureData.complexity,
            masterFeatureID: featureData.masterFeatureID,
            solutionAssumption: featureData.solutionAssumption,
            featureDescription: featureData.featureDescription,
          })
          this.pushFeature(this.pro_data);
          this.getWSSCalaulation();
          this.calculateCurrentProgress();
          // this.drawerGroupForm.get('scope').setValue(stripContent);
        }
        else if(message.isCompleted){
          this.progressValue = 100;
          this.featuresFetched=this.totalFeatures;
          if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
        }
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "proposal_completed")
    ).subscribe((message) => {
        if(message.taskID==this._proposal_id){
          this.initial_load = true;
          this.getProposal(true);
        }
    });

    this._promptService.templatePrompts$.subscribe((response)=>{
      this.promptTemplates=response;
    });


    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "epics")
    ).subscribe(message=>{
      this.getProposal(true);
    });

    this.subscription = this.eventService.event$.subscribe((data) => {
      // console.log("data:",data);
      if(data.hasOwnProperty('extract')){
        this.loadingMetadata=data.extract;
        this.generatingtitle=data.generatingtitle?data.generatingtitle:'';
      }
    });



  }

  ngOnInit(): void
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
        this.isMobile = result.matches;
    });

    this._activatedRoute.paramMap.subscribe(params => {
      this._proposal_id  = params.get('id');
      this._page_id = this._activatedRoute.snapshot['_routerState'].url.includes('proposal')?'proposal':'template';
      this.getProposal(false);
    });

    this.tabGroupForm = this._formBuilder.group({
      summaryOverview: [''],
      aboutCustomer: [''],
      projectChallenges: [''],
      projectRequirements: [''],
      expectedOutcomes: [''],
      scopeOverview: [''],
      projectScope: [''],
      outOfScope: [''],
      aboutus:[''],
      terms:[''],
      last_edit_history_by:[''],
      last_edit_history_on:[''],
      last_edit_history_field:[''],
      features: new FormArray([
        new FormGroup({
          _id: new FormControl(''),
          name: new FormControl(''),
          technology: new FormControl(''),
          masterFeatureID: new FormControl(''),
          solutionAssumption: new FormControl(''),
          complexity: new FormControl(''),
          effort: new FormControl(''),
          componentsCount: new FormControl(''),
          epicName: new FormControl(''),
          epic: new FormControl(''),
          theme: new FormControl(''),
          release: new FormControl(''),
          releaseName: new FormControl(''),
          featureDescription: new FormControl(''),
          epicList: new FormArray([
            new FormGroup({
              label: new FormControl(''),
              value: new FormControl(''),
            }),
          ]),
          releases_list: new FormArray([
            new FormGroup({
              name: new FormControl(''),
              _id: new FormControl(''),
            }),
          ])
        }),
      ]),
      phases: new FormArray([
        new FormGroup({
          _id: new FormControl(''),
          name: new FormControl(''),
          currency: new FormControl(''),
          weeks: new FormControl(''),
          hours: new FormControl(''),
          cost: new FormControl(''),
          order: new FormControl(''),
          phase: new FormControl(''),
          phase_resources: new FormArray([
            new FormGroup({
              _id: new FormControl(''),
              name: new FormControl(''),
              short_name: new FormControl(''),
              count: new FormControl('')
            })
          ])
        })
      ]),
      resources: new FormArray([
        new FormGroup({
          _id: new FormControl(''),
          name: new FormControl(''),
          title: new FormControl(''),
          short_name: new FormControl(''),
          source_type: new FormControl(''),
          reference_name: new FormControl(''),
          currency: new FormControl(''),
          weeks: new FormControl(''),
          hours: new FormControl(''),
          rate: new  FormControl(''),
          cost: new FormControl(''),
          order: new FormControl(''),
          active: new FormControl(''),
          resource: new FormControl(''),
          type: new FormControl(''),
          resource_phases: new FormArray([
            new FormGroup({
              _id: new FormControl(''),
              phaseId: new FormControl(''),
              proposalphaseId: new FormControl(''),
              name: new FormControl(''),
              weeks: new FormControl('')
            })
          ])
        })
      ]),
      cost_distribution: new FormGroup({
        contingency               : new FormControl(''),
        deployment              : new FormControl(''),
        documentation              : new FormControl(''),
        enduser_testing          : new FormControl(''),
        integration_testing            : new FormControl(''),
        required_analysis           : new FormControl(''),
        uat            : new FormControl(''),
        unit_testing            : new FormControl('')
      }),
      proposal_stats: new FormGroup({
        complexity               : new FormControl(''),
        total_feature              : new FormControl(''),
        total_hours              : new FormControl(''),
        total_value          : new FormControl(''),
        total_phase_weeks          : new FormControl('')
      }),
      proposal_meta: new FormGroup({
        currency               : new FormControl('')
      }),
      effort: new FormGroup({
        development               : new FormControl(''),
        overhead               : new FormControl(''),
        project               : new FormControl(''),
        design                : new FormControl('')
      }),
      featuresCount: [0],
      totalDevHours: [0],
      progress_indicator_incompleted: [0],
      progress_indicator_completed: [0],
      progress_indicator_summary: [false],
      progress_indicator_scope: [false],
      progress_indicator_solutions: [false],
      progress_indicator_project: [false],
      rate : [0],
      search : [''],
      technology_domain : [''],
      epic_name : [''],
      complexity_value : [''],
      release : ['']
    });

    this.downloadGroupForm  = this._formBuilder.group({
      logo: [''],
      fileType: [''],
      executiveSummary: [true],
      company_bio: [true],
      scope: [true],
      commercials: [true],
      deliverables: [true],
      company_tnc: [true],
      conclusion: [true],
    });

    this.drawerGroupForm = this._formBuilder.group({
      index: [0],
      taskID: [''],
      type: [''],
      original: ['', Validators.required],
      custom: [''],
      modified: ['']
    });

    this.costGroup = this.tabGroupForm.get('cost_distribution') as FormGroup;
    this.effortGroup = this.tabGroupForm.get('effort') as FormGroup;
    this.metaGroup = this.tabGroupForm.get('proposal_meta') as FormGroup;
    this.statsGroup = this.tabGroupForm.get('proposal_stats') as FormGroup;
    this.featuresArray = this.tabGroupForm.get('features') as FormArray;
    this.phasesArray = this.tabGroupForm.get('phases') as FormArray;
    this.resourcesArray = this.tabGroupForm.get('resources') as FormArray;


    this._userService.user$.subscribe((user)=>{
      this.user=user;
      this._websocketService.sendConnectionCommand(user._id);
    });
  }

  onScrollDiv(): void {
    const scrollTop = this.scrollableContainer.nativeElement.scrollTop;
    const itemHeight = 45; 
    const totalItems = this.items.length;
    this.middleIndex = Math.floor(scrollTop / itemHeight) + 1;
    if (this.middleIndex >= totalItems - 1) {
      this.middleIndex = totalItems - 2;
    } else if (this.middleIndex < 1) {
      this.middleIndex = 1;
    }
  }

  isMiddle(index: number): boolean {
    return index === this.middleIndex;
  }


  ngAfterViewChecked() {
    // Ensure the contenteditable attribute is set correctly after every view check
    if(this._page_id=="template" || this.proposal_acess_status=="view"){
      this.showInsights=false;
    }

    if((this._page_id=='template') || (this._page_id=='proposal' && !this.is_write_allowed)){
        
      setTimeout(() => {
        
        const summaryElement1 = document.querySelector('#summaryTextReadonly1 div');
        if (summaryElement1) {
          summaryElement1.setAttribute('contenteditable', 'false');
          this.editor_summaryOverview_readonly.setEditable(false);
        }
        const summaryElement2 = document.querySelector('#summaryTextReadonly2 div');
        if (summaryElement2) {
          summaryElement2.setAttribute('contenteditable', 'false');
          this.editor_aboutCustomer_readonly.setEditable(false);
        }
        const summaryElement3 = document.querySelector('#summaryTextReadonly3 div');
        if (summaryElement3) {
          summaryElement3.setAttribute('contenteditable', 'false');
          this.editor_projectChallenges_readonly.setEditable(false);
        }
        const summaryElement4 = document.querySelector('#summaryTextReadonly4 div');
        if (summaryElement4) {
          summaryElement4.setAttribute('contenteditable', 'false');
          this.editor_projectRequirements_readonly.setEditable(false);
        }
        const summaryElement5 = document.querySelector('#summaryTextReadonly5 div');
        if (summaryElement5) {
          summaryElement5.setAttribute('contenteditable', 'false');
          this.editor_expectedOutcomes_readonly.setEditable(false);
        }
        const summaryElement6 = document.querySelector('#summaryTextReadonly6 div');
        if (summaryElement6) {
          summaryElement6.setAttribute('contenteditable', 'false');
          this.editor_aboutus_readonly.setEditable(false);
        }
        const summaryElement7 = document.querySelector('#summaryTextReadonly7 div');
        if (summaryElement7) {
          summaryElement7.setAttribute('contenteditable', 'false');
          this.editor_terms_readonly.setEditable(false);
        }
        
        const scopeElement1 = document.querySelector('#scopeTextReadonly1 div');
        if (scopeElement1) {
          scopeElement1.setAttribute('contenteditable', 'false');
          this.editor_scopeOverview_readonly.setEditable(false);
        }

        const scopeElement2 = document.querySelector('#scopeTextReadonly2 div');
        if (scopeElement2) {
          scopeElement2.setAttribute('contenteditable', 'false');
          this.editor_projectScope_readonly.setEditable(false);
        }

        const scopeElement3 = document.querySelector('#scopeTextReadonly3 div');
        if (scopeElement3) {
          scopeElement3.setAttribute('contenteditable', 'false');
          this.editor_outOfScope_readonly.setEditable(false);
        }
        this.featureEditors.forEach(editorRef => {
          if(editorRef){
            const editorElement = editorRef.nativeElement.querySelector('div');
            if (editorElement && editorElement.getAttribute('contenteditable') !== 'false') {
              editorElement.setAttribute('contenteditable', 'false');
              this.editor_feature_readonly.setEditable(false);
            }
          }
        });
        this.solutionEditors.forEach(editorRef => {
          if(editorRef){
            const editorElement = editorRef.nativeElement.querySelector('div');
            if (editorElement && editorElement.getAttribute('contenteditable') !== 'false') {
              editorElement.setAttribute('contenteditable', 'false');
              this.editor_solution_readonly.setEditable(false);
            }
          }
        });
      },1000);
    }
  }

  calculateCurrentProgress(){
    if(this.proposal_detail!=null){
        let totalCompleted=0;
      if(this.proposal_detail.executiveSummary!=""){
        totalCompleted+=5;
      }

      if(this.proposal_detail.scope!=""){
        totalCompleted+=5;
      }

      if(this.proposal_detail.estimatedFeatures>0){
        this.totalFeatures=this.proposal_detail.estimatedFeatures;
      }
      if(this.proposal_detail.features && this.proposal_detail.features.length>0){
        this.featuresFetched=this.proposal_detail.features.length;
      }
      
      if(this.totalFeatures!=0){
        let perFeatureProgress=89/this.totalFeatures;
        totalCompleted+=(perFeatureProgress*this.featuresFetched);
      }

      this.progressValue=Math.min(
        Math.ceil(totalCompleted),
        99
      );
    }
  }

  updateSelectedText(editor: Editor): void {
    // const { from, to } = editor.state.selection;
    // this.selectedText = editor.state.doc.textBetween(from, to, '\n');
    // this.quickSelectedText = editor.state.doc.textBetween(from, to, ' ');
    // this.quickSelectionStartIndex = from;
    // this.quickSelectionEndIndex = to; console.log(from, to);
    // const selectedHTML = editor.state.doc.textBetween(from, to, '\n');
    // this.beforeHTML = editor.state.doc.textBetween(0, from, '\n');
    // this.afterHTML = editor.state.doc.textBetween(to, editor.state.doc.content.size, '\n');
    // console.log(this.tabGroupForm.get('executiveSummary').value);
    // console.log("beforeHTML:", this.beforeHTML);
    // console.log("afterHTML:", this.afterHTML);
    const { from, to } = editor.state.selection;
    // console.log("from:", from);
    // console.log("to:", to);
    // Get the selected content as a ProseMirror Fragment
    const selectedFragment = editor.state.doc.slice(from, to);

    // Convert the Fragment to a DOM Node
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const div = document.createElement('div');
    selectedFragment.content.forEach(node => {
        div.appendChild(serializer.serializeNode(node));
    });

    // Get the selected HTML
    const selectedHTML = div.innerHTML;

    // Store the selection details
    this.quickSelectionStartIndex = from;
    this.quickSelectionEndIndex = to;
    this.selectedText = selectedHTML;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------



  /**
  * Get Plan
  */
  getProposal(totalSave): void
  {
      // Return if the form is invalid
      if ( !this._proposal_id )
      {
        return;
      }

      // Send the request to the server
      this._proposalService.getDetail(this._proposal_id,this._page_id)
          .pipe(
              finalize(() =>
              {
                
              }),
          )
          .subscribe(
             async (response) =>
              {
                  if(response){

                    // this.proposal_detail = response;
                    this.proposal_detail = { ...response, updatedValue: totalSave };
                    this.getShareStatus();
                    if(this.workspace){
                      this.workspace.proposal=this.proposal_detail;
                      this.workspace.ngOnInit();
                    }
                    this.releases_list = [];
                    if(this.proposal_detail.hasOwnProperty('releases_list') && this.proposal_detail.releases_list.length){
                      this.releases_list = JSON.parse(JSON.stringify(this.proposal_detail.releases_list));
                      this.releases_list.forEach(release => {
                        release.requirements = release.requirements || [];
                      });
                    }
                    if(!this.proposal_detail.epics || !this.proposal_detail.epics.length){
                      this.hasWorkspaceView=false;
                      this.selectedWorkSpace=1;
                    }
                    else{
                      this.hasWorkspaceView=true;this.selectedWorkSpace=0;
                      this.allEpics = this.proposal_detail.epics?.flatMap((epic) => {
                        return epic.epics.map((themeEpic) => {
                          return { label: themeEpic.name, value: themeEpic.name };
                        });
                      }) ?? [];    
                      this.themeListTypes = [];
                      this.proposal_detail.epics.forEach(element => {
                        let current;
                        if(element.theme)current = {label:element.theme,value:element.theme,epics:[]};
                        if(element.epics && element.epics.length){
                          element.epics.forEach(epicelement => {
                            current['epics'].push({label:epicelement.name,value:epicelement.name});
                            epicelement.features.forEach(requirement => {
                              if (requirement.release) {
                                let release = this.releases_list.findIndex(release => release._id === requirement.release);
                                if (release>-1) {
                                  requirement['theme'] = {_id: element._id, title:element.theme};
                                  requirement['epic'] = {_id: epicelement._id, title:epicelement.name};
                                  this.releases_list[release].requirements.push(requirement);
                                }
                              }
                            });
                          });
                        }
                        this.themeListTypes.push(current)
                      });
                    }
                    
                    if(this.proposal_detail.businessCapabilities && this.proposal_detail.businessCapabilities.length){
                      this.themeTypes=this.proposal_detail.businessCapabilities.map((cap)=>{
                        return {label:cap,value:cap};
                      });
                    }
                    
                    this.totalFeatures=this.proposal_detail.estimatedFeatures;
                    let overview="";
                    let aboutCustomer="";
                    let projectChallenges="";
                    let projectRequirements="";
                    let expectedOutcomes="";
                    let scopeOverview="";
                    let projectScope="";
                    let outOfScope="";
                    let aboutUs="";
                    let terms="";

                    if(this.proposal_detail.executiveSummary){

                      if(typeof(this.proposal_detail.executiveSummary)=="string"){
                        overview=await marked.parse(this.proposal_detail.executiveSummary);console.log(overview);
                      }else{

                        //parse parts
                        if(this.proposal_detail.executiveSummary.overview)overview=await marked.parse(this.proposal_detail.executiveSummary.overview) || "";
                        if(this.proposal_detail.executiveSummary.aboutCustomer)aboutCustomer=await marked.parse(this.proposal_detail.executiveSummary.aboutCustomer) || "";
                        if(this.proposal_detail.executiveSummary.projectChallenges)projectChallenges=await marked.parse(this.proposal_detail.executiveSummary.projectChallenges) || "";
                        if(this.proposal_detail.executiveSummary.projectRequirements)projectRequirements=await marked.parse(this.proposal_detail.executiveSummary.projectRequirements) || "";
                        if(this.proposal_detail.executiveSummary.expectedOutcomes)expectedOutcomes=await marked.parse(this.proposal_detail.executiveSummary.expectedOutcomes) || "";

                       if(this.proposal_detail.executiveSummary.aboutUs){
                        aboutUs= await marked.parse(this.proposal_detail.executiveSummary.aboutUs) || "";
                       }
                       if(this.proposal_detail.executiveSummary.terms){
                        terms=await marked.parse(this.proposal_detail.executiveSummary.terms) || "";
                       }

                       if(!this.proposal_detail.executiveSummary.terms && 
                        !this.proposal_detail.executiveSummary.aboutUs &&
                        this.user.company_bio &&
                        this.user.company_tnc
                       ){
                        aboutUs=this.user.company_bio || "";
                        terms=this.user.company_tnc || "";
                       }

                      }



                      this.tabGroupForm.get('summaryOverview').setValue(overview);
                      this.tabGroupForm.get('aboutCustomer').setValue(aboutCustomer);
                      this.tabGroupForm.get('projectChallenges').setValue(projectChallenges);
                      this.tabGroupForm.get('projectRequirements').setValue(projectRequirements);
                      this.tabGroupForm.get('expectedOutcomes').setValue(expectedOutcomes);

                      this.tabGroupForm.get("aboutus").setValue(aboutUs);
                      this.tabGroupForm.get("terms").setValue(terms);

                      // let markedsummaryStrip = marked.parse(this.proposal_detail.executiveSummary);
                      // let summaryStrip = this.replaceNewlines(markedsummaryStrip.toString());

                     
                    }
                    if(this.proposal_detail.scope){

                      if(typeof(this.proposal_detail.scope)=="string"){
                        scopeOverview=await marked.parse(this.proposal_detail.scope);
                      }else{
                        //parse parts
                        scopeOverview=await marked.parse(this.proposal_detail.scope.overview) || "";
                        projectScope=await marked.parse(this.proposal_detail.scope.scope) || "";
                        outOfScope=await marked.parse(this.proposal_detail.scope.outOfScope) || "";

                      }
                      this.tabGroupForm.get('scopeOverview').setValue(scopeOverview);
                      this.tabGroupForm.get('projectScope').setValue(projectScope);
                      this.tabGroupForm.get('outOfScope').setValue(outOfScope);


                      // let markedscopeStrip = marked.parse(this.proposal_detail.scope);
                      // let scopeStrip = this.replaceNewlines(markedscopeStrip.toString());
                      // this.tabGroupForm.get('scope').setValue(scopeStrip);
                    }
                    if(this.proposal_detail.edit_history && this.proposal_detail.edit_history.length){
                      var lastItem = this.proposal_detail.edit_history.pop();
                      if(lastItem){
                        let history_name = lastItem.by.personal.name.first + (lastItem.by.personal.name.last?(' ' +lastItem.by.personal.name.last):'');
                        let history_time = moment(lastItem.at).format('MMM D YYYY');
                        let history_field = [];
                        if(lastItem.changes && lastItem.changes.length){
                          lastItem.changes.forEach(element => {
                            if(element.field)history_field.push(element.field);
                          });
                          this.tabGroupForm.get('last_edit_history_field').setValue(history_field.join(', '));
                        }
                        this.tabGroupForm.get('last_edit_history_by').setValue(history_name);
                        this.tabGroupForm.get('last_edit_history_on').setValue(history_time);
                      }
                    }
                    if(this._page_id=='template'){
                      setTimeout(() => {
                        const summaryElement1 = document.querySelector('#summaryTextReadonly1 div');
                        if (summaryElement1) {
                          summaryElement1.setAttribute('contenteditable', 'false');
                          this.editor_summaryOverview_readonly.setEditable(false);
                        }
                        const summaryeditorElement = document.querySelector('#summaryTextReadonly2 div');
                        if (summaryeditorElement) {
                          summaryeditorElement.setAttribute('contenteditable', 'false');
                          this.editor_aboutCustomer_readonly.setEditable(false);
                        }
                        const summaryElement3 = document.querySelector('#summaryTextReadonly3 div');
                        if (summaryElement3) {
                          summaryElement3.setAttribute('contenteditable', 'false');
                          this.editor_projectChallenges_readonly.setEditable(false);
                        }
                        const summaryElement4 = document.querySelector('#summaryTextReadonly4 div');
                        if (summaryElement4) {
                          summaryElement4.setAttribute('contenteditable', 'false');
                          this.editor_projectRequirements_readonly.setEditable(false);
                        }
                        const summaryElement5 = document.querySelector('#summaryTextReadonly5 div');
                        if (summaryElement5) {
                          summaryElement5.setAttribute('contenteditable', 'false');
                          this.editor_expectedOutcomes_readonly.setEditable(false);
                        }
                        const summaryElement6 = document.querySelector('#summaryTextReadonly6 div');
                        if (summaryElement6) {
                          summaryElement6.setAttribute('contenteditable', 'false');
                          this.editor_aboutus_readonly.setEditable(false);
                        }
                        const scopeeditorElement1 = document.querySelector('#scopeTextReadonly1 div');
                        if (scopeeditorElement1) {
                          scopeeditorElement1.setAttribute('contenteditable', 'false');
                          this.editor_scopeOverview_readonly.setEditable(false);
                        }

                        const scopeElement2 = document.querySelector('#scopeTextReadonly2 div');
                        if (scopeElement2) {
                          scopeElement2.setAttribute('contenteditable', 'false');
                          this.editor_projectScope_readonly.setEditable(false);
                        }

                        const scopeElement3 = document.querySelector('#scopeTextReadonly3 div');
                        if (scopeElement3) {
                          scopeElement3.setAttribute('contenteditable', 'false');
                          this.editor_outOfScope_readonly.setEditable(false);
                        }
                      },1000);
                      this.editor_scopeOverview_readonly.setEditable(false);
                      this.editor_projectScope_readonly.setEditable(false);
                      this.editor_outOfScope_readonly.setEditable(false);
                    }
                    if (this.proposal_detail.status==2)this.loadingFeature = false;
                    else{
                      this.progressValue = 0;
                      this.loadingFeature = true;
                      this.calculateCurrentProgress();
                    }
                    let perm_user = (this.proposal_detail.hasOwnProperty('permissions') && this.proposal_detail.permissions.length?this.proposal_detail.permissions.find(el => (el.user_id._id==this.user._id)):{});
                    if(perm_user)this.show_view_count = !!(((perm_user.admin || perm_user.write || perm_user.read) && (!perm_user.view_count || perm_user.view_count<1)));
                    if(this.proposal_detail.features && this.proposal_detail.features.length){
                      this.pro_data = this.proposal_detail.features;
                      this.original_features = JSON.parse(JSON.stringify(this.proposal_detail.features));
                      if (this.featuresArray instanceof FormArray) {
                        this.pushFeature(this.proposal_detail.features);
                      }
                      this.tabGroupForm.get('featuresCount').setValue(this.proposal_detail.features.length);
                      this.getCloud();
                    }
                    else{
                      this.pro_data = [];
                      this.original_features = [];
                      this.tabGroupForm.get('featuresCount').setValue(0);
                      this.featuresArray.clear();
                    }
                    if(this.proposal_detail.progress_indicator && this.proposal_detail.progress_indicator){
                      let completed_count = this.proposal_detail.progress_indicator.filter(el=>el.status=='Completed');
                      this.proposal_detail['progress_indicator_completed'] = completed_count.length;
                      this.tabGroupForm.get('progress_indicator_completed').setValue(completed_count.length);
                      let incompleted_count = this.proposal_detail.progress_indicator.filter(el=>el.status!='Completed');
                      this.proposal_detail['progress_indicator_incompleted'] = incompleted_count.length;
                      this.tabGroupForm.get('progress_indicator_incompleted').setValue(incompleted_count.length);
                    }
                    else {
                      this.proposal_detail['progress_indicator_completed'] = 0;
                      this.proposal_detail['progress_indicator_incompleted'] = 0;
                      this.tabGroupForm.get('progress_indicator_completed').setValue(0);
                      this.tabGroupForm.get('progress_indicator_incompleted').setValue(0);
                      this.tabGroupForm.get('progress_indicator_summary').setValue(false);
                      this.tabGroupForm.get('progress_indicator_scope').setValue(false);
                      this.tabGroupForm.get('progress_indicator_solutions').setValue(false);
                      this.tabGroupForm.get('progress_indicator_project').setValue(false);
                    }
                    this.getIndicators();
                    if(this.proposal_detail.phases && this.proposal_detail.phases.length){
                      this.pro_phase_data = this.proposal_detail.phases;
                      this.original_phases = JSON.parse(JSON.stringify(this.proposal_detail.phases));
                      if (this.phasesArray instanceof FormArray) {
                        this.pushPhases(this.proposal_detail.phases);
                      }
                    }
                    else{
                      this.pro_phase_data = [];
                      this.original_phases = [];
                      this.phasesArray.clear();
                    }
                    if(this.proposal_detail.resources && this.proposal_detail.resources.length){
                      this.pro_resource_data = this.proposal_detail.resources;
                      this.original_resources = JSON.parse(JSON.stringify(this.proposal_detail.resources));
                      if (this.resourcesArray instanceof FormArray) {
                        this.pushResources(this.proposal_detail.resources);
                      }
                    }
                    else{
                      this.pro_resource_data = [];
                      this.original_resources = [];
                      this.resourcesArray.clear();
                    }
                    this.statsGroup.patchValue({
                      complexity: (this.proposal_detail.proposal_stats && this.proposal_detail.proposal_stats.complexity)?this.proposal_detail.proposal_stats.complexity:'Low',
                      total_feature: (this.proposal_detail.features?this.proposal_detail.features.length:0),
                      total_hours: (this.proposal_detail.proposal_stats && this.proposal_detail.proposal_stats.total_hours)?this.proposal_detail.proposal_stats.total_hours:0,
                      total_value: (this.proposal_detail.proposal_stats && this.proposal_detail.proposal_stats.total_value)?this.proposal_detail.proposal_stats.total_value:0
                    });
                    this.metaGroup.patchValue({
                      currency: (this.proposal_detail.proposal_meta && this.proposal_detail.proposal_meta.currency?this.proposal_detail.proposal_meta.currency:'USD')
                    });
                    this.effortGroup.patchValue({
                      development: this.proposal_detail.effort?this.proposal_detail.effort.development:0,
                      overhead: this.proposal_detail.effort?this.proposal_detail.effort.overhead:0,
                      project: this.proposal_detail.effort?this.proposal_detail.effort.project:0,
                      design: this.proposal_detail.effort?this.proposal_detail.effort.design:0
                    });
                    this.tabGroupForm.get('totalDevHours').setValue(this.effortGroup.get('development').value);
                    this.totalDevHours = this.proposal_detail.effort?Math.round(this.proposal_detail.effort.development):0;
                    this.costGroup.patchValue({
                      contingency: this.proposal_detail.cost_distribution?this.proposal_detail.cost_distribution.contingency:0,
                      deployment: this.proposal_detail.cost_distribution?this.proposal_detail.cost_distribution.deployment:0,
                      documentation: this.proposal_detail.cost_distribution?this.proposal_detail.cost_distribution.documentation:0,
                      enduser_testing: this.proposal_detail.cost_distribution?this.proposal_detail.cost_distribution.enduser_testing:0,
                      integration_testing: this.proposal_detail.cost_distribution?this.proposal_detail.cost_distribution.integration_testing:0,
                      required_analysis: this.proposal_detail.cost_distribution?this.proposal_detail.cost_distribution.required_analysis:0,
                      uat: this.proposal_detail.cost_distribution?this.proposal_detail.cost_distribution.uat:0,
                      unit_testing: this.proposal_detail.cost_distribution?this.proposal_detail.cost_distribution.unit_testing:0
                    });
                    this.tabGroupForm.get('rate').setValue(this.proposal_detail.rate);
                    this.costDetails$ = this.costGroup.valueChanges;
                    try{
                      if(this.proposal_detail.working_status==2){
                        this._analyticsService.trackEvent(analytics_event.PROPOSAL_COMPLETED,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name,proposalName:this.proposal_detail["name"]});
                      }
                    }catch(error){
                    }
                    this.cdr.detectChanges(); 
                  }
                  else {
                     
                  }
              },
              (error) =>
              {
                  
              },
          );
  }

  replaceNewlines(content: string): string {
    content = content.replace(/(?<!<p><\/p>)<h6>/g, '<p></p><h6>'); 
    content = content.replace(/(?<!<p><\/p>)<h5>/g, '<p></p><h5>'); 
    content = content.replace(/(?<!<p><\/p>)<h4>/g, '<p></p><h4>'); 
    content = content.replace(/(?<!<p><\/p>)<h3>/g, '<p></p><h3>'); 
    content = content.replace(/(?<!<p><\/p>)<h2>/g, '<p></p><h2>'); 
    content = content.replace(/(?<!<p><\/p>)<h1>/g, '<p></p><h1>');
    content = content.replace(/(?<!<p><\/p>)<p><br\/><\/p>/g, '');

    return content;
  }

  getCloud(): void
  {
    let features = JSON.parse(JSON.stringify(this.original_features));
    this.cloudTypes = [];
    features.forEach(element => {
      if(element.cloud && !this.cloudTypes.find(el=>el.value==element.cloud))this.cloudTypes.push({label:element.cloud, value: element.cloud});
      if(this.proposal_detail['proposal_meta'].product.includes(element.cloud) && element.epicName!='' && !this.epicTypes.find(el=>el.value==element.epicName))this.epicTypes.push({label:element.epicName, value: element.epicName});
    });
    // this.tabGroupForm.get('technology_domain').setValue(this.proposal_detail['proposal_meta'].product);
    this.epicTypes.sort((a, b) => a.label.localeCompare(b.label));
    this.cloudTypes = this.reorderArray(this.cloudTypes, this.proposal_detail['proposal_meta'].product);
    this.filterCount();
  }

  getIndicators(): void
  {
   this.indicators = [];
   this._proposalService.getProgressIndicators(this._proposal_id)
    .subscribe(
      (value) =>
      {
        if(value.data && value.data.length){
          this.indicators = value.data;
          let sumStatus = this.indicators.find(el=>el.reference=='indicator_review_summary');
          if(sumStatus && sumStatus.status=='Completed')this.tabGroupForm.get('progress_indicator_summary').setValue(true);
          let scopeStatus = this.indicators.find(el=>el.reference=='indicator_review_scope');
          if(scopeStatus && scopeStatus.status=='Completed')this.tabGroupForm.get('progress_indicator_scope').setValue(true);
          let solStatus = this.indicators.find(el=>el.reference=='indicator_review_solution');
          if(solStatus && solStatus.status=='Completed')this.tabGroupForm.get('progress_indicator_solutions').setValue(true);
          let proStatus = this.indicators.find(el=>el.reference=='indicator_review_project');
          if(proStatus && proStatus.status=='Completed')this.tabGroupForm.get('progress_indicator_project').setValue(true);
          let findIndex = this.indicators.findIndex(item => item.status !== 'Completed');
          this.nextAct = findIndex;
          // const scrollTop = this.scrollableContainer.nativeElement.scrollTop;
          // const itemHeight = 45; 
          // const totalItems = index;
          // this.middleIndex = Math.floor(scrollTop / itemHeight) + 1;
          // if (this.middleIndex >= 1) {
          //   this.middleIndex = index;
          // } else if (this.middleIndex < 1) {
          //   this.middleIndex = 1;
          // }

          setTimeout(function(){
            // document.getElementById('nextAct').scrollIntoView({ behavior: 'smooth' });
           /*
            const nextActElement = document.getElementById('nextAct');
            const scrollContainer = document.querySelector('.scroll-container');
            
            if (nextActElement && scrollContainer) {
                const elementPosition = nextActElement.getBoundingClientRect().top;
                const containerPosition = scrollContainer.getBoundingClientRect().top;
            
                scrollContainer.scrollTo({
                    top: scrollContainer.scrollTop + (elementPosition - containerPosition),
                    behavior: 'smooth'
                });
            }
            */
            const nextActElement = document.getElementById('nextAct');
            const scrollContainer = document.querySelector('.scroll-container');
            
            if (nextActElement && scrollContainer) {
                const elementPosition = nextActElement.getBoundingClientRect().top;
                const containerPosition = scrollContainer.getBoundingClientRect().top;
                
                // Calculate the element's position relative to the container
                const offset = elementPosition - containerPosition;
                
                // Center the element in the scroll container's visible area
                const containerHeight = scrollContainer.clientHeight;
                const elementHeight = nextActElement.clientHeight;
                const scrollPosition = scrollContainer.scrollTop + offset - (containerHeight / 2) + (elementHeight / 2);
            
                scrollContainer.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth'
                });
            }
          },200)

        }
      },
      (response) =>
      {
      },
    );
  }

  toggleChange(event,type){
    if(type=='solution'){
      let findIndicator = this.indicators.find(el=>el.reference=='indicator_review_solution');
      this.updateIndicators(findIndicator._id,((event.checked)?'Completed':'In Progress'));
    }
    else if(type=='project'){
      let findIndicator = this.indicators.find(el=>el.reference=='indicator_review_project');
      this.updateIndicators(findIndicator._id,((event.checked)?'Completed':'In Progress'));
    }
    else if(type=='scope'){
      let findIndicator = this.indicators.find(el=>el.reference=='indicator_review_scope');
      this.updateIndicators(findIndicator._id,((event.checked)?'Completed':'In Progress'));
    }
    else if(type=='summary'){
      let findIndicator = this.indicators.find(el=>el.reference=='indicator_review_summary');
      this.updateIndicators(findIndicator._id,((event.checked)?'Completed':'In Progress'));
    }
  }

  updateIndicators(step,status){
    this.update({id:this._proposal_id,progress_indicator:true,step:step,status:status});
  }

  reorderArray(objArray, orderArray) {
    const orderMap = new Map();
    
    // Create a map for quick lookup
    orderArray.forEach((key, index) => {
      orderMap.set(key, index);
    });
  
    // Filter and sort the objects based on orderArray
    const orderedObjects = objArray
      .filter(obj => orderMap.has(obj.value))
      .sort((a, b) => orderMap.get(a.value) - orderMap.get(b.value));
    
    // Get the remaining objects
    const remainingObjects = objArray
      .filter(obj => !orderMap.has(obj.value));
    // Concatenate the ordered objects with the remaining ones
    return [...orderedObjects, ...remainingObjects];
  }

  startProgressTimer(): void {
    this.intervalSubscription = interval(2000)
    .pipe(
      takeWhile(() => this.progressValue < 100)
    )
    .subscribe(() => {
      // Calculate progress percentage
      //remaining = 60; total = 21; (60/21)*100
      // console.log(this.featuresFetched,this.remainingProgress)
      if(this.totalFeatures>0 && this.featuresFetched>0){
        this.progressValue =  Math.min(
            Math.ceil(this.progressValue + (this.featuresFetched*this.remainingProgress)),
            99
        );
        // this.progressValue = Math.ceil((this.featuresFetched / this.totalFeatures) * 100);
        // this.progressValue += Math.ceil((this.remainingProgress / this.totalFeatures) * this.featuresFetched);
        // this.progressValue = Math.min(
        //   this.progressValue + Math.ceil((this.remainingProgress / this.totalFeatures) * this.featuresFetched),
        //   99
        // );
      }
      else{
        // this.progressValue=this.progressValue+1;
        this.progressValue = Math.min(this.progressValue + 1, 99);
      }
    });
  }

  stripHTML(text){
    return text.replaceAll(this.regexForStripHTML, '');
  }

  /**
   * Voice Record
  */
  startSpeechRecognition() {
    this.recording = true;
    this.speechRecognition.start();
    this.speechRecognition.onresult = (event) => {
      const result = event.results[0][0].transcript;
      this.zone.run(() => {
        // this.horizontalStepperForm.get('step4.summary').setValue(result.toString() + '. ');
        // this.message += result.toString();
        // this.message += '. ';
        this.recording = false;
      });
    };
  }

  // getCalculations(totalSave){
  //   // console.log("totalSave",totalSave);
  //   let feature_effort: number= 0; let total_effort: number= 0;let total_rate: number= 0;let overhead_effort: number= 0;let design_effort: number= 0;
  //   if(this.proposal_detail.features && this.proposal_detail.features.length){
  //     this.proposal_detail.features.forEach(element => {
  //       feature_effort = feature_effort+element.effort;
  //     });
  //   }

  //   if(feature_effort>0){
  //     overhead_effort = (feature_effort * ((this.costGroup.get('contingency')?.value)/100)) + (feature_effort * ((this.costGroup.get('deployment')?.value)/100)) + (feature_effort * ((this.costGroup.get('documentation')?.value)/100)) + (feature_effort * ((this.costGroup.get('enduser_testing')?.value)/100)) + (feature_effort * ((this.costGroup.get('integration_testing')?.value)/100)) + (feature_effort * ((this.costGroup.get('required_analysis')?.value)/100)) + (feature_effort * ((this.costGroup.get('uat')?.value)/100)) + (feature_effort * ((this.costGroup.get('unit_testing')?.value)/100));
  //   }

  //   total_effort = feature_effort + overhead_effort;
  //   total_rate = this.tabGroupForm.get('rate').value * total_effort;

  //   this.effortGroup.get('development').setValue(feature_effort.toFixed(1));
  //   this.effortGroup.get('overhead').setValue(overhead_effort.toFixed(1));
  //   this.effortGroup.get('project').setValue(total_effort.toFixed(1));
  //   if(totalSave)this.save_assumption();
  //   if(!this.proposal_detail.stats_updated && this.proposal_detail.status==2){
  //     this.update({id:this._proposal_id,proposal_stats:this.statsGroup.value,cost_distribution:this.costGroup.value,effort:this.effortGroup.value,rate:this.tabGroupForm.get('rate').value,currency:this.tabGroupForm.get('proposal_meta.currency').value,settings:{working_status:this.proposal_detail.working_status}});
  //   }
  // }

  getWSSCalaulation(){
    if(this.proposal_detail && this.proposal_detail.proposal_meta && this.proposal_detail.proposal_meta.currency)this.tabGroupForm.get('proposal_meta.currency').setValue(this.proposal_detail.proposal_meta.currency);
    this.tabGroupForm.get('proposal_stats.total_feature').setValue(this.pro_data.length);
    let feature_effort: number= 0;
    if(this.pro_data && this.pro_data.length){
      this.pro_data.forEach(element => {
        feature_effort = feature_effort + parseInt(element.effort);
      });
    }
    let total_rate = this.tabGroupForm.get('rate').value * feature_effort;
    this.tabGroupForm.get('proposal_stats.total_value').setValue(total_rate);
    this.tabGroupForm.get('proposal_stats.total_hours').setValue(feature_effort);
  }

  getShareStatus(){
    this.is_share_visible = false;
    let perm_user = (this.proposal_detail.hasOwnProperty('permissions') && this.proposal_detail.permissions.length?this.proposal_detail.permissions.find(el => (el.user_id._id==this.user._id)):{});
    if(this.user._id==this.proposal_detail.author._id){
      this.is_share_visible = true;
      this.is_write_allowed=true;
      this.proposal_acess_status = 'admin';
      this.initEditor(true);
    }
    else if(this.proposal_detail.hasOwnProperty('permissions') && this.proposal_detail.permissions.length && perm_user && perm_user.admin){
      this.is_share_visible = true;
      this.is_write_allowed = true;
      this.proposal_share_status = true;
      this.proposal_acess_status = 'admin';
      this.initEditor(true);
    }
    else if(this.proposal_detail.hasOwnProperty('permissions') && this.proposal_detail.permissions.length && perm_user && perm_user.write){
      this.is_write_allowed = true;
      this.proposal_share_status = true;
      this.proposal_acess_status = 'edit';
      this.initEditor(true);
    }
    else if(this.proposal_detail.hasOwnProperty('permissions') && this.proposal_detail.permissions.length && perm_user && perm_user.read){
      this.is_write_allowed = false;
      this.proposal_share_status = true;
      this.proposal_acess_status = 'view';
      // this.initEditor(false);
    }
    // return is_share_visible;
  }

  // initEditor(write_type){
  //   if(!write_type){
  //     setTimeout(() => {
  //       const summaryElement1 = document.querySelector('#summaryTextReadonly1 div');
  //       if (summaryElement1) {
  //         summaryElement1.setAttribute('contenteditable', 'false');
  //         this.editor_summaryOverview_readonly.setEditable(false);
  //       }
  //       const summaryElement2 = document.querySelector('#summaryTextReadonly2 div');
  //       if (summaryElement2) {
  //         summaryElement2.setAttribute('contenteditable', 'false');
  //         this.editor_aboutCustomer_readonly.setEditable(false);
  //       }
  //       const summaryElement3 = document.querySelector('#summaryTextReadonly3 div');
  //       if (summaryElement3) {
  //         summaryElement3.setAttribute('contenteditable', 'false');
  //         this.editor_projectChallenges_readonly.setEditable(false);
  //       }
  //       const summaryElement4 = document.querySelector('#summaryTextReadonly4 div');
  //       if (summaryElement4) {
  //         summaryElement4.setAttribute('contenteditable', 'false');
  //         this.editor_projectRequirements_readonly.setEditable(false);
  //       }
  //       const summaryElement5 = document.querySelector('#summaryTextReadonly5 div');
  //       if (summaryElement5) {
  //         summaryElement5.setAttribute('contenteditable', 'false');
  //         this.editor_expectedOutcomes_readonly.setEditable(false);
  //       }
  //       const summaryElement6 = document.querySelector('#summaryTextReadonly6 div');
  //       if (summaryElement6) {
  //         summaryElement6.setAttribute('contenteditable', 'false');
  //         this.editor_aboutus_readonly.setEditable(false);
  //       }
  //       const scopeElement1 = document.querySelector('#scopeTextReadonly1 div');
  //       if (scopeElement1) {
  //         scopeElement1.setAttribute('contenteditable', 'false');
  //         this.editor_scopeOverview_readonly.setEditable(false);
  //       }
  //       const scopeElement2 = document.querySelector('#scopeTextReadonly2 div');
  //       if (scopeElement2) {
  //         scopeElement2.setAttribute('contenteditable', 'false');
  //         this.editor_projectScope_readonly.setEditable(false);
  //       }
  //       const scopeElement3 = document.querySelector('#scopeTextReadonly3 div');
  //       if (scopeElement3) {
  //         scopeElement3.setAttribute('contenteditable', 'false');
  //         this.editor_outOfScope_readonly.setEditable(false);
  //       }
  //       this.featureEditors.forEach(editorRef => {
  //         if(editorRef){
  //           const editorElement = editorRef.nativeElement.querySelector('div');
  //           if (editorElement && editorElement.getAttribute('contenteditable') !== 'false') {
  //             editorElement.setAttribute('contenteditable', 'false');
  //             this.editor_feature_readonly.setEditable(false);
  //           }
  //         }
  //       });
  //       this.solutionEditors.forEach(editorRef => {
  //         if(editorRef){
  //           const editorElement = editorRef.nativeElement.querySelector('div');
  //           if (editorElement && editorElement.getAttribute('contenteditable') !== 'false') {
  //             editorElement.setAttribute('contenteditable', 'false');
  //             this.editor_solution_readonly.setEditable(false);
  //           }
  //         }
  //       });
  //     },1000);
  //   }
  // }

  initEditor(write_type) {
    if (!write_type) {
      setTimeout(() => {
        this.disableEditor('#summaryTextReadonly1 div', this.editor_summaryOverview_readonly);
        this.disableEditor('#summaryTextReadonly2 div', this.editor_aboutCustomer_readonly);
        this.disableEditor('#summaryTextReadonly3 div', this.editor_projectChallenges_readonly);
        this.disableEditor('#summaryTextReadonly4 div', this.editor_projectRequirements_readonly);
        this.disableEditor('#summaryTextReadonly5 div', this.editor_expectedOutcomes_readonly);
        this.disableEditor('#summaryTextReadonly6 div', this.editor_aboutus_readonly);
        this.disableEditor('#summaryTextReadonly7 div', this.editor_terms_readonly);
        this.disableEditor('#scopeTextReadonly1 div', this.editor_scopeOverview_readonly);
        this.disableEditor('#scopeTextReadonly2 div', this.editor_projectScope_readonly);
        this.disableEditor('#scopeTextReadonly3 div', this.editor_outOfScope_readonly);
        // Repeat for other fields
 
        // For featureEditors and solutionEditors
        this.featureEditors.forEach(editorRef => {
          if (editorRef) {
            this.disableEditor(editorRef.nativeElement.querySelector('div'), this.editor_feature_readonly);
          }
        });
        this.solutionEditors.forEach(editorRef => {
          if (editorRef) {
            this.disableEditor(editorRef.nativeElement.querySelector('div'), this.editor_solution_readonly);
          }
        });
      }, 1000);
    }
 }
 
 disableEditor(selector: string, editorInstance: any) {
   const element = typeof selector === 'string' ? document.querySelector(selector) : selector;
   if (element) {
     this.renderer.setAttribute(element, 'contenteditable', 'false');
     if (editorInstance && typeof editorInstance.setEditable === 'function') {
       editorInstance.setEditable(false);
     }
   }
 }

  closeView(){
    this.show_view_count = false;
    this._proposalService.updateViewCount({id:this._proposal_id})
    .subscribe(
      (value) =>
      {
        if(value.status)this.getProposal(false);
      },
      (response) =>
      {
      },
    );
  }

  filterCount() {
    let self = this;
    let alldata = JSON.parse(JSON.stringify(self.original_features));
    let technology = [],
      epic = [],
      releases = [],
      complexity = [];
      self.pro_data = [];
    this.epicTypes = [];
    // console.log(this.tabGroupForm.get('search').value);
    if (this.tabGroupForm.get('complexity_value').value && this.tabGroupForm.get('complexity_value').value.length) {
      complexity = this.tabGroupForm.get('complexity_value').value;
    }
    if (this.tabGroupForm.get('release').value && this.tabGroupForm.get('release').value.length) {
      releases = this.tabGroupForm.get('release').value;
    }
    if (this.tabGroupForm.get('technology_domain').value && this.tabGroupForm.get('technology_domain').value.length) {
      technology = this.tabGroupForm.get('technology_domain').value;
      alldata.forEach(element => {
        if(technology.includes(element.cloud) && element.epicName!='' && !this.epicTypes.find(el=>el.value==element.epicName))this.epicTypes.push({label:element.epicName, value: element.epicName});
      });
      this.epicTypes.sort((a, b) => a.label.localeCompare(b.label));
    }
    else {
      alldata.forEach(element => {
        if(!this.epicTypes.find(el=>el.value==element.epicName))this.epicTypes.push({label:element.epicName, value: element.epicName});
      });
      this.epicTypes.sort((a, b) => a.label.localeCompare(b.label));
    }
    if (this.tabGroupForm.get('epic_name').value && this.tabGroupForm.get('epic_name').value.length) {
      epic = this.tabGroupForm.get('epic_name').value;
    }
    if (this.tabGroupForm.get('search').value != '') {
      let data = alldata.filter(obj =>
        Object.values(obj).some(val =>
          typeof val === 'string' && val.toLowerCase().includes(this.tabGroupForm.get('search').value.toLowerCase())
        )
      );
      // console.log(data);
      if (!technology.length && !complexity.length && !epic.length && !releases.length && data.length) {
        this.pro_data = data;
      } else if ((technology.length || complexity.length || epic.length || releases.length) && data.length){
        let filteredDataLibrary = data.filter((item) => {
          let match = true;
          if (technology.length && technology.indexOf(item.cloud) < 0)match = false;
          if (complexity.length && complexity.indexOf(item.complexity) < 0)match = false;
          if (epic.length && epic.indexOf(item.epicName) < 0)match = false;
          if (releases.length && releases.indexOf(item.release) < 0)match = false;
          return match;
        });
        this.pro_data = filteredDataLibrary;
      }
      else{
        this.pro_data = [];
      }
    } else {
      if (!technology.length && !complexity.length && !epic.length && !releases.length) {
        this.pro_data = alldata;
        this.tabGroupForm.get('featuresCount').setValue(this.pro_data.length);
        this.calculateDevEffort(this.pro_data);
        this.pushFeature(this.pro_data);
      } else {
        let filteredDataLibrary = alldata.filter((item) => {
          let match = true;
          if (technology.length && technology.indexOf(item.cloud) < 0)match = false;
          if (complexity.length && complexity.indexOf(item.complexity) < 0)match = false;
          if (releases.length && releases.indexOf(item.release) < 0)match = false;
          if (epic.length && epic.indexOf(item.epicName) < 0)match = false;
          return match;
        });
        this.pro_data = filteredDataLibrary;
      }
    }
    this.tabGroupForm.get('featuresCount').setValue(this.pro_data.length);
    this.calculateDevEffort(this.pro_data);
    this.pushFeature(this.pro_data);
  }

  calculateDevEffort(array){
    let dev_effort = 0;
    if(array.length){
      array.forEach(element => {
        dev_effort = dev_effort+element.effort;
      });
    }
    this.tabGroupForm.get('totalDevHours').setValue(dev_effort);
  }

  // save_assumption(){
  //   this.assumption_loader = true;
  //   this.update({id:this._proposal_id,proposal_stats:this.statsGroup.value,cost_distribution:this.costGroup.value,effort:this.effortGroup.value,rate:this.tabGroupForm.get('rate').value,currency:this.tabGroupForm.get('proposal_meta.currency').value});
  // }

  update(params){
    params['testplan'] = false;
    this._proposalService.update(params)
    .subscribe(
      (value) =>
      {
        this.showFlashMessage('success');
        if(this.assumption_loader || params.progress_indicator){
          this.assumption_loader = false;
          this.getProposal(false);
        }
        if(params.ValueChanged){
          this.parentData = 'refresh';
          this.getProposal(true);
        }
        //this.getProposal(false);
      },
      (response) =>
      {
      },
    );
  }

  newContent(type){

    let obj = {id: this._proposal_id};
    let text = "";
    if(type=='replace'){
      this.generate_replace = true;
      text = this.drawerGroupForm.get('modified').value;
      this.drawerGroupForm.get('original').setValue(text);
    }
    else {
      this.generate_append = true;
      text = this.drawerGroupForm.get('original').value + " " + this.drawerGroupForm.get('modified').value;
      this.drawerGroupForm.get('original').setValue(text);
    }
    if(text && (this.drawerGroupForm.get('type').value=='summary' || this.drawerGroupForm.get('type').value=='scope')){
      if(this.drawerGroupForm.get('type').value=='summary')obj['executiveSummary'] = text;
      else if(this.drawerGroupForm.get('type').value=='scope')obj['scope'] = text;
      this._proposalService.update(obj)
        .subscribe(
          (value) =>
          {
            if(value.status){
              this.showcustomprompt=false;
              this.generate_replace = false;
              this.generate_append = false;
              this.drawerGroupForm.get('custom').setValue('');
              this.getProposal(false);
            }
            else this.showFlashMessage('error');
          },
          (response) =>
          {
          },
      );
    } 
    else if(text && (this.drawerGroupForm.get('type').value=='solutionAssumption' || this.drawerGroupForm.get('type').value=='featureDescription')){
      if(this.drawerGroupForm.get('type').value=='solutionAssumption')this.featuresArray.at(this.drawerGroupForm.get('index').value).get('solutionAssumption').setValue(text);
      else if(this.drawerGroupForm.get('type').value=='featureDescription')this.featuresArray.at(this.drawerGroupForm.get('index').value).get('featureDescription').setValue(text);
      this.showcustomprompt=false;
      this.generate_replace = false;
      this.generate_append = false;
      this.drawerGroupForm.get('custom').setValue('');
    }
  }

  openedChanged(event){
    if(event===false){
      this.clear();
    }
  }

  download(type){
    
    if(type=='pdf')this.download_pdf_loader = true;
    else if(type=='doc')this.download_doc_loader = true;
    this.downloadGroupForm.get('fileType').setValue(type);
    // console.log(this.downloadGroupForm.value);
    this._proposalService.update({id:this._proposal_id,downloadSettings:this.downloadGroupForm.value})
          .subscribe(
            (value) =>
            {
              if(value.status)this.downloadFile();
              else {
                this.download_pdf_loader = false;
                this.download_doc_loader = false;
              }
            },
            (response) =>
            {
            },
        );
  }

  downloadFile(){

    try{
      if(this.user){
        this._analyticsService.trackEvent(analytics_event.DOWNLOAD_PROPOSAL,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name,file:this.download_pdf_loader?"pdf":"docx"});
      }
    }catch(error){
      
    }
    
     this._proposalService.download({id:this._proposal_id})
    .subscribe(
      (value) =>
      {
        // console.log(value);
        if(value.status){
          window.open(value.data, '_blank');
          if(this.showModal){
            const dialogRef = this.dialog.open(NpsComponent,{ 
              panelClass: 'custom-dialog-container-nps',
              disableClose: true ,
              data: {
                category: 'Proposal'
              } 
            });
          }
        }
        this.downloadDrawer.close();
        this.download_pdf_loader = false;
        this.download_doc_loader = false;
      },
      (response) =>
      {
        // console.log(response);
      },
    );
  }

  clear(){
    this.showcustomprompt=false;
    this.generate_replace = false;
    this.generate_append = false;
    this.drawerGroupForm.get('modified').setValue('');
    this.drawerGroupForm.get('original').setValue('');
    this.drawerGroupForm.get('type').setValue('');
    this.drawerGroupForm.get('custom').setValue('');
  }

  toggleDetails(productId: string,index): void
  {
      // If the product is already selected...
      if ( this.selectedFeature && this.selectedFeature['_id'] === productId )
      {
        // Close the details
        this.closeDetails();
        return;
      }else{
        this.selectedFeature = this.pro_data.find(o => o._id === productId);
        this.selectedFeature['index'] = index;
        // if(this.selectedFeature.theme){
        //   let findTheme = this.themeListTypes.find(el=>el.value==this.selectedFeature.theme);
        //   if(findTheme && findTheme.epics){
        //     this.themeEpicTypes = findTheme.epics;
        //   }
        // }
        this.cdr.detectChanges();
      }
  }

  captureChangeSummary(event: any,type,content): void {
    console.log("On Update :",type);
    let obj = {id: this._proposal_id};

    if((type=='summaryOverview' || type=='aboutCustomer' || type=='projectRequirements' || type=='projectChallenges' || type=='expectedOutcomes'|| type=='aboutUs'|| type=='terms') && content && content!='<p></p>')
      {
        obj['executiveSummary'] = {
          overview:this.tabGroupForm.get("summaryOverview").value,
          aboutCustomer:this.tabGroupForm.get("aboutCustomer").value,
          projectRequirements:this.tabGroupForm.get("projectRequirements").value,
          projectChallenges:this.tabGroupForm.get("projectChallenges").value,
          expectedOutcomes:this.tabGroupForm.get("expectedOutcomes").value,
          aboutUs:this.tabGroupForm.get("aboutus").value,
          terms:this.tabGroupForm.get("terms").value,
        };
      }
    else if((type=='summaryOverview' || type=='aboutCustomer' || type=='projectRequirements' || type=='projectChallenges' || type=='expectedOutcomes'|| type=='aboutUs'|| type=='terms') && !content){
      obj['executiveSummary'] = {
        overview:this.tabGroupForm.get("summaryOverview").value,
        aboutCustomer:this.tabGroupForm.get("aboutCustomer").value,
        projectRequirements:this.tabGroupForm.get("projectRequirements").value,
        projectChallenges:this.tabGroupForm.get("projectChallenges").value,
        expectedOutcomes:this.tabGroupForm.get("expectedOutcomes").value,
        aboutUs:this.tabGroupForm.get("aboutus").value,
        terms:this.tabGroupForm.get("terms").value,
      };
    }



    if((type=='scopeOverview' || type=='projectScope' || type=='outOfScope') && content && content!='<p></p>'){
      obj['scope'] = {
        overview:this.tabGroupForm.get("scopeOverview").value,
        scope:this.tabGroupForm.get("projectScope").value,
        outOfScope:this.tabGroupForm.get("outOfScope").value,
      };
    }else if((type=='scopeOverview' || type=='projectScope' || type=='outOfScope') && !content)
    {
      obj['scope'] = {
        overview:this.tabGroupForm.get("scopeOverview").value,
        scope:this.tabGroupForm.get("projectScope").value,
        outOfScope:this.tabGroupForm.get("outOfScope").value,
      };
   
    }

    this.$changeValues.next(obj);
  }

  selectedIndexChange(event){
    this.selectedIndex = event;
    this.selectedWorkSpace=0;
    localStorage.setItem("selectedIndex",JSON.stringify(this.selectedIndex));
  }

  onSolutionReleaseSelection(event,feature,index){
    this.featuresArray.at(index).get('release').setValue(event.value)
    this.updateSelectedFeature(feature);
  }

  search(event){
    this.filterCount();
    // if(event.target.value){
    //   let data = this.pro_data.filter(obj =>
    //     Object.values(obj).some(val =>
    //       typeof val === 'string' && val.toLowerCase().includes(event.target.value.toLowerCase())
    //     )
    //   );
    //   if(data.length){
    //     this.pushFeature(data);
    //   }
    //   else{
    //     this.pro_data = [];
    //     this.featuresArray.clear();
    //   }
    // }
    // else{ 
    //   this.pro_data = JSON.parse(JSON.stringify(this.original_features));
    //   this.pushFeature(this.pro_data);
    // }
  }

  downloadSummary(){
    this.download_summary_loader = true;
     this._proposalService.downloadSummary({id:this._proposal_id})
    .subscribe(
      (value) =>
      {
        if(value.status)window.open(value.data, '_blank');
        this.download_summary_loader = false;
      },
      (response) =>
      {
        // console.log(response);
      },
    );
  }

  onResourceModifyAction(event){
    if(event=='false')this.getProposal(false);
  }

  pushFeature(array){
    this.featuresArray.clear();
    if(array.length){
      array.forEach(detail => {
        // Determine the selected theme
        let themeEpicTypes = [];
        let selectedTheme = (detail.theme ? detail.theme : (this.themeListTypes.length?this.themeListTypes[0].value:''));
        // Find the corresponding theme object from the theme list
        let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);
        let releaseName = this.releases_list.find(el=>el._id==detail.release);
        // Set the themeEpics based on the selected theme
        if (findTheme && findTheme.epics) {
          themeEpicTypes = findTheme.epics;
        }

        // Prepare the additionalInfo array based on the selected theme
        let additionalInfoArray = themeEpicTypes.map(epic => {
          return this._formBuilder.group({
            label: epic.label || '', // Default to empty if label not found
            value: epic.value || '', // Default to empty if value not found
          });
        });
        let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
        // let _featureDescription = detail.featureDescription.replaceAll(this.regexForStripHTML, '');
        // let _solutionAssumption = detail.solutionAssumption.replaceAll(this.regexForStripHTML, '');
        this.featuresArray.push(this._formBuilder.group({
            _id               : [detail._id],
            name              : [detail.name],
            technology              : [detail.technology],
            cloud              : [detail.cloud],
            masterFeatureID          : [(detail.masterFeatureID?detail.masterFeatureID._id:'')],
            solutionAssumption            : [detail.solutionAssumption?detail.solutionAssumption.replace(/(?<!<p>)\n(?!<\/p>)/g, '<br/>'):''],
            complexity           : [detail.complexity],
            effort            : [detail.effort],
            componentsCount            : [detail.componentsCount],
            epicName            : [detail.epicName],
            release: [detail.release],
            releaseName: [releaseName?releaseName.short_name:'-'],
            theme: [selectedTheme],
            epicList: this._formBuilder.array(additionalInfoArray),
            epic            : [detail.epic],
            releases_list: this._formBuilder.array(additionalReleaseArray),
            featureDescription: [detail.featureDescription.replace(/(?<!<p>)\n(?!<\/p>)/g, '<br/>')]
        }));
      });
    }
    this.paymentDetails$ = this.featuresArray.valueChanges;
  }

  pushPhases(array) {
    this.phasesArray.clear();
    this.resourcesArray.clear();
    if (array.length) {
      let phase_week = 0;
      array.forEach((detail, index) => {
        const phaseResourcesArray = this._formBuilder.array(
          detail.phase_resources.map(resource => this._formBuilder.group({
            _id: [resource._id],
            name: [resource.name],
            short_name: [resource.short_name],
            count: [resource.count]
          }))
        );
        this.phasesArray.push(this._formBuilder.group({
          _id: [detail._id],
          name: [detail.name],
          currency: [detail.currency],
          weeks: [detail.weeks],
          hours: [detail.hours],
          cost: [detail.cost],
          order: [detail.order],
          phase: [detail.phase],
          phase_resources: phaseResourcesArray
        }));
        phase_week += detail.weeks;
      });
      this.statsGroup.patchValue({'total_phase_weeks':phase_week});
      // Sort the phases array by the 'order' field in ascending order
      // this.phasesArray.controls = this.phasesArray.controls.sort((a, b) => {
      //   const orderA = a.get('order')?.value;
      //   const orderB = b.get('order')?.value;
      //   return orderA - orderB;
      // });
    }
    this.paymentDetails$ = this.phasesArray.valueChanges;
  }

  pushResources(array){
    this.resourcesArray.clear();
    if (array.length) {
      array.forEach((detail, index) => {
        const resourcesPhaseArray = this._formBuilder.array(
          detail.phases.map(eachPhase => this._formBuilder.group({
            _id: [eachPhase._id],
            phaseId: [eachPhase.phaseId],
            proposalphaseId: [eachPhase.proposalphaseId],
            name: (this.phasesArray.value.find(el=>el._id==eachPhase.proposalphaseId))?this.phasesArray.value.find(el=>el._id==eachPhase.proposalphaseId).name:'',
            weeks: this.phasesArray.value.find(el=>el._id==eachPhase.proposalphaseId)?this.phasesArray.value.find(el=>el._id==eachPhase.proposalphaseId).weeks:0
          }))
        );
        this.resourcesArray.push(this._formBuilder.group({
          _id: detail._id,
          name: detail.name,
          title: detail.title,
          short_name: detail.short_name,
          source_type: detail.source_type,
          reference_name: detail.reference_name,
          currency: detail.currency,
          weeks: detail.weeks,
          hours: detail.hours,
          rate: detail.rate,
          cost: detail.cost,
          order: detail.order,
          active: detail.active,
          resource: detail.resource,
          type: detail.type,
          resource_phases: resourcesPhaseArray
        }));
      });

      // Sort the phases array by the 'order' field in ascending order
      // this.phasesArray.controls = this.phasesArray.controls.sort((a, b) => {
      //   const orderA = a.get('order')?.value;
      //   const orderB = b.get('order')?.value;
      //   return orderA - orderB;
      // });
    }
    this.paymentDetails$ = this.phasesArray.valueChanges;
  }

  getPhaseResources(index: number) {
    return (this.phasesArray.at(index).get('phase_resources') as FormArray);
  }

  // getResources(formGroup: FormGroup): FormArray {
  //   return formGroup.get('phase_resources') as FormArray;
  // }

  /**
   * Close the details
   */
  closeDetails(): void
  {
    this.selectedFeature = null;
  }

  updateSelectedFeature(inputFormGroup): void
  {
    
    // console.log(inputFormGroup.value);return;
    this._proposalService.update({id:this._proposal_id,feature_action:'update',feature_data:inputFormGroup.value})
          .subscribe(
            (value) =>
            {
              if(value.status){
                this.showFlashMessage('success');
                this.flashMessage = 'Feature updated successfully!';
                if(this.showModal){
                  const dialogRef = this.dialog.open(NpsComponent,{ 
                    panelClass: 'custom-dialog-container-nps',
                    disableClose: true,
                    data: {
                      category: 'Proposal'
                    }   
                  });
                }
                setTimeout(() =>
                {
                  this.tabGroupForm.get('search').setValue('');
                  this.closeDetails();
                  this.getProposal(true);
                }, 2000);
              }
              else this.showFlashMessage('error');
            },
            (response) =>
            {
            },
        );
  }

  deleteSelectedFeature(inputFormGroup){
    
    let feature_id = inputFormGroup.value['_id'];
    this._proposalService.update({id:this._proposal_id,feature_action:'delete',feature_data:{_id:feature_id}})
          .subscribe(
            (value) =>
            {
              if(value.status){
                this.showFlashMessage('success');
                this.flashMessage = 'Feature deleted successfully!';
                setTimeout(() =>
                {
                  this.tabGroupForm.get('search').setValue('');
                  this.closeDetails();
                  this.getProposal(true);
                }, 2000);
              }
              else this.showFlashMessage('error');
            },
            (response) =>
            {
            },
        );
  }

  showFlashMessage(type: 'success' | 'error'): void
  {
      // Show the message
      this.flashMessageType = type;


      // Hide it after 3 seconds
      setTimeout(() =>
      {
          this.flashMessageType = null;

      }, 1000);
  }

  chooseTemplate(){
    if(this.isPaid){
      let proposal_data = {proposal_detail:this.proposal_detail,type:'template'};
      const dialogRef = this.dialog.open(SettingComponent,
        { 
          panelClass: 'proposal-setting-dialog',
          disableClose: true,
          data: proposal_data,
        }
      );
      dialogRef.afterClosed().subscribe(result => {
        this.getProposal(false);
      });
    }
    else {
      // localStorage.setItem('panel','plan-billing');
      this._router.navigate(['/settings/pricing']);
    }
  }

  openDialog(type) {
    this.quickActionIds = [];
    this.proposal_detail['dialog_type'] = type;
    this.proposal_detail['selected_theme'] = '';
    this.proposal_detail['selected_epic'] = '';
    // this.proposal_detail['releases_list'] = this.releases_list;
    const dialogRef = this.dialog.open(FeatureComponent,
      { 
        panelClass: 'add-feature-dialog',
        disableClose: true,
        data: this.proposal_detail,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      this.tabGroupForm.get('search').setValue('');
      this.getProposal(true);
    });
  }

  openSetting() {
    let proposal_data = {proposal_detail:this.proposal_detail,type:'proposal'};
    const dialogRef = this.dialog.open(SettingComponent,
      { 
        panelClass: 'proposal-setting-dialog',
        disableClose: true,
        data: proposal_data,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      this.getProposal(false);
    });
  }

  formatMoney(value) {
    return value?value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):0;
  }

  selectLogo(){
    document.getElementById('file').click();
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
      this.imageDetail = event.srcElement.files[0];
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      this.saveImage();
  }

  saveImage() {
      if (this.imageDetail && this.imageChangedEvent) {
        let self = this;
        this._userService
          .postUserImage({
            filename: this.imageDetail.name,
            filetype: this.imageDetail.type,
          })
          .subscribe(
            (data) => {
              const uploadsignedURL = data.uploadURL;
              if (this.croppedImage) {
                let binary = atob(this.croppedImage.split(',')[1]);
                let array = [];
                for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                const uploadblobdata = new Blob([new Uint8Array(array)], {
                    type: self.imageDetail.type,
                });

                // Use the pre-signed URL directly without modifying it
                const result = fetch(uploadsignedURL, {
                    method: 'PUT',
                    body: uploadblobdata,
                    // Note: No need to manually add `x-amz-tagging` header here
                });

                result.then((value) => {
                    let _pimage = uploadsignedURL
                        .split('?')[0]
                        .split('/')
                        .pop();
                    self.downloadGroupForm.get('logo').setValue(_pimage);
                    self.imageChangedEvent = '';
                }).catch((error) => {
                    console.error('Upload failed:', error);
                });
              }
              return true;
            },
            (err) => {
              return false;
            }
          );
      }
  }

  removeImage(){
    this.downloadGroupForm.get('logo').setValue('');
    this.imageChangedEvent = '';
  }

  getImageURL(url){
    return environment.file_url_cf+ url + '?token='+this._authService.accessToken
  }

  featureComplexityChange(event,inputFormGroupelement){
    let infoValues = inputFormGroupelement.value;
    let findFeature = this.original_features.find(el=>el._id==infoValues._id);
    // console.log("findFeature",findFeature);console.log("infoValues",infoValues);
    let component = parseInt(infoValues['componentsCount']);
    if(component>0 && findFeature.masterFeatureID){
      if(event.value=='Low')inputFormGroupelement.get('effort').setValue(component*findFeature.masterFeatureID['lowEffort']);
      else if(event.value=='Medium')inputFormGroupelement.get('effort').setValue(component*findFeature.masterFeatureID['mediumEffort']);
      else if(event.value=='High')inputFormGroupelement.get('effort').setValue(component*findFeature.masterFeatureID['highEffort']);
    }
  }

  featureThemeChange(event,inputFormGroupelement){
    // Find the selected theme
    let themeEpicTypes = [];
    let findTheme = this.themeListTypes.find(el => el.value == event.value);
    if (findTheme && findTheme.epics) {
      themeEpicTypes = findTheme.epics;
    }

    // Create a FormArray based on the themeEpicTypes
    const additionalInfoArray = this._formBuilder.array(
      themeEpicTypes.map(epic => {
        return this._formBuilder.group({
          label: epic.label || '', // Default to empty if label not found
          value: epic.value || '', // Default to empty if value not found
        });
      })
    );

    // Replace the 'additionalInfo' FormArray with the newly created one
    inputFormGroupelement.setControl('epicList', additionalInfoArray);

    // Set the 'epic' value
    inputFormGroupelement.get('epic').setValue(themeEpicTypes[0].value);
  }

  onComponentsCountChange(value: any,inputFormGroupelement): void {
    let infoValues = inputFormGroupelement.value;
    let findFeature = this.original_features.find(el=>el._id==infoValues._id);
    // console.log("findFeature",findFeature);console.log("infoValues",infoValues);
    let effort_val;
    if(findFeature.masterFeatureID){
      if(infoValues.complexity=='Low')effort_val = findFeature.masterFeatureID['lowEffort'];
      else if(infoValues.complexity=='Medium')effort_val = findFeature.masterFeatureID['mediumEffort'];
      else if(infoValues.complexity=='High')effort_val = findFeature.masterFeatureID['highEffort'];
      let calcVal = infoValues.componentsCount*effort_val;
      inputFormGroupelement.get('effort').setValue(calcVal);
    }
  }

  featureReleaseChange(event,inputFormGroupelement){

  }

  getCurrencyIc(currency){   
      if(currency == 'USD' || currency == 'AUD'){
        return '$';
      }

      if(currency == 'INR'){
        return '₹';
      }

      if(currency == 'EUR'){
        return '€';
      }

      if(currency == 'GBP'){
        return '£';
      }else{
        return '$';
      }

  }

  // @HostListener('window:scroll', [])
  // onScroll(): void {

  //   // console.log('window',window,window.innerHeight,window.scrollY,document.body.offsetHeight,((window.innerHeight + window.scrollY) >= document.body.offsetHeight));pageYoffset
  //   // console.log((window.scrollY > 0 && Math.abs((window.innerHeight + window.scrollY) - document.body.offsetHeight) <= 1));

  //   if ((window.scrollY > 0 &&
  //     Math.abs((window.innerHeight + window.scrollY) - document.body.offsetHeight) <= 1) && this._page_id=='proposal' && this.is_write_allowed) {
  //     // console.log('You have reached the bottom of the page!',this.selectedIndex);
  //     if(!this.selectedIndex || this.selectedIndex==0){
  //       let findIndicator = this.indicators.find(el=>el.reference=='indicator_review_summary');
  //       if(findIndicator && findIndicator.status && findIndicator.status!='Completed' && (this.tabGroupForm.get('summaryOverview').value || this.tabGroupForm.get('aboutCustomer').value || this.tabGroupForm.get('projectChallenges').value || this.tabGroupForm.get('projectRequirements').value || this.tabGroupForm.get('expectedOutcomes').value || this.tabGroupForm.get("aboutus").value || 
  //       this.tabGroupForm.get("terms").value)){
  //         this.updateIndicators(findIndicator._id,'Completed');
  //       }
  //     }
  //     else if(this.selectedIndex==1){
  //       let findIndicator = this.indicators.find(el=>el.reference=='indicator_review_scope');
  //       if(findIndicator && findIndicator.status && findIndicator.status!='Completed' && (this.tabGroupForm.get('scopeOverview').value || this.tabGroupForm.get('projectScope').value || this.tabGroupForm.get('outOfScope').value))this.updateIndicators(findIndicator._id,'Completed');
  //     }
  //     // You can perform other actions here, like loading more content
  //   }
  // }

  ngAfterViewInit(){
    if(localStorage.getItem('productTour')){
      setTimeout(() => {
        this.startTour();
      }, 5000);
    }

    // this.onScroll(); 
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    this.proposal_detail = {};
    localStorage.removeItem("selectedIndex");
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    clearInterval(this.intervalId);
    localStorage.removeItem('productTour');
  }

  onTextSelect(event,targetId){ 
   console.log("Event:",event);
   if(!this.is_write_allowed){
    return;
   }
    // const targetId = (event.target as HTMLElement).id;
   console.log('Target element ID:', targetId);

    if(targetId.includes("description")||targetId.includes("solution")){
      let data=targetId.split("-");
      this.selectedFeatureData={
        type:data[0],
        index:parseInt(data[1])
      };
    }else{
      this.selectedTargetIdEditor = targetId;
      this.selectedFeatureData={
        type:"",
        index:0
      };
    }
    // console.log('selectedFeatureData', this.selectedFeatureData);
    this.mouseInitialPosition=event.clientY;
    //This code place menu at bottom of selection
    const textarea = event.target as HTMLTextAreaElement;

    const selection = window.getSelection();
    if (selection && selection.toString().trim().length > 0) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        let last=event.pageY-120;
        if(last<this.mouseDownPos){
          const screenWidth = window.innerWidth;
          const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;

        this.menuPosition = {
            top: `${last}px`,
            left: `${xPosition}px`
        };

        }
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    }
  }

  replaceSubstring(originalString: string, startIndex: number, endIndex: number, replacement: string): string {
    // Extract parts of the original string before and after the substring to be replaced
    const before = originalString.slice(0, startIndex);
    const after = originalString.slice(endIndex);
    const newString = before + replacement + after;
    return newString;
  }

  onCompleteQuickAction(newValue){

    console.log("Value to update:",newValue);
    console.log("Selection:",this.quickSelectedText);
    console.log("selectedFeatureData:",this.selectedFeatureData);
    console.log("selectedIndex:",this.selectedIndex,this.selectedTargetIdEditor);
    console.log(this.quickSelectionStartIndex,this.quickSelectionEndIndex);
    
    let obj = {id: this._proposal_id};
    if(this.selectedIndex==0 || !this.selectedIndex){
      if(this.selectedTargetIdEditor=='summaryEditor7'){
        const { from, to } = this.summaryEditor7.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.summaryEditor7.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.summaryEditor7.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.summaryEditor7.view.dispatch(tr);
        this.summaryEditor7.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['executiveSummary'] = this.tabGroupForm.get('terms').value;
        this.captureChangeSummary({},'terms','');
      }
      else if(this.selectedTargetIdEditor=='summaryEditor6'){
        const { from, to } = this.summaryEditor6.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.summaryEditor6.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.summaryEditor6.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.summaryEditor6.view.dispatch(tr);
        this.summaryEditor6.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['executiveSummary'] = this.tabGroupForm.get('aboutus').value;
        this.captureChangeSummary({},'aboutUs','');
      }
      else if(this.selectedTargetIdEditor=='summaryEditor5'){
        const { from, to } = this.summaryEditor5.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.summaryEditor5.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.summaryEditor5.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.summaryEditor5.view.dispatch(tr);
        this.summaryEditor5.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['executiveSummary'] = this.tabGroupForm.get('expectedOutcomes').value;
        this.captureChangeSummary({}, 'expectedOutcomes','');
      }
      else if(this.selectedTargetIdEditor=='summaryEditor4'){
        const { from, to } = this.summaryEditor4.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.summaryEditor4.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.summaryEditor4.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.summaryEditor4.view.dispatch(tr);
        this.summaryEditor4.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['executiveSummary'] = this.tabGroupForm.get('projectRequirements').value;
        this.captureChangeSummary({}, 'projectRequirements','');
      }
      else if(this.selectedTargetIdEditor=='summaryEditor3'){
        const { from, to } = this.summaryEditor3.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.summaryEditor3.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.summaryEditor3.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.summaryEditor3.view.dispatch(tr);
        this.summaryEditor3.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['executiveSummary'] = this.tabGroupForm.get('projectChallenges').value;
        this.captureChangeSummary({}, 'projectChallenges','');
      }
      else if(this.selectedTargetIdEditor=='summaryEditor2'){
        const { from, to } = this.summaryEditor2.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.summaryEditor2.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.summaryEditor2.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.summaryEditor2.view.dispatch(tr);
        this.summaryEditor2.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['executiveSummary'] = this.tabGroupForm.get('aboutCustomer').value;
        this.captureChangeSummary({}, 'aboutCustomer','');
      }
      else if(this.selectedTargetIdEditor=='summaryEditor1'){
        const { from, to } = this.summaryEditor1.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.summaryEditor1.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.summaryEditor1.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.summaryEditor1.view.dispatch(tr);
        this.summaryEditor1.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['executiveSummary'] = this.tabGroupForm.get('summaryOverview').value;
        this.captureChangeSummary({}, 'summaryOverview','');
      }
     
      
    }else if(this.selectedIndex==1){
      if(this.selectedTargetIdEditor=='scopeeditor3'){
        const { from, to } = this.scopeeditor3.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.scopeeditor3.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        const tr: Transaction = this.scopeeditor3.state.tr.replaceWith(from, to, replacementNode);
        const newTo = from + replacementNode.size;
        this.scopeeditor3.view.dispatch(tr);
        this.scopeeditor3.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['scope'] = this.tabGroupForm.get('outOfScope').value;
        this.captureChangeSummary({},'outOfScope','');
      }
      else if(this.selectedTargetIdEditor=='scopeeditor2'){
        const { from, to } = this.scopeeditor2.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.scopeeditor2.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        const tr: Transaction = this.scopeeditor2.state.tr.replaceWith(from, to, replacementNode);
        const newTo = from + replacementNode.size;
        this.scopeeditor2.view.dispatch(tr);
        this.scopeeditor2.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['scope'] = this.tabGroupForm.get('projectScope').value;
        this.captureChangeSummary({},'projectScope','');
      }
      else if(this.selectedTargetIdEditor=='scopeeditor1'){
        const { from, to } = this.scopeeditor1.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.scopeeditor1.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        const tr: Transaction = this.scopeeditor1.state.tr.replaceWith(from, to, replacementNode);
        const newTo = from + replacementNode.size;
        this.scopeeditor1.view.dispatch(tr);
        this.scopeeditor1.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['scope'] = this.tabGroupForm.get('scopeOverview').value;
        this.captureChangeSummary({},'scopeOverview','');
      }
    }else if(this.selectedIndex==2){
      if(this.selectedFeatureData.type=="description"){
      //   let updatedValue=this.replaceSubstring(this.featuresArray.at(this.selectedFeatureData.index).get("featureDescription").value,this.quickSelectionStartIndex,this.quickSelectionEndIndex,newValue);
      //   console.log("updatedValue:",updatedValue);
      // // let updatedValue=this.featuresArray.at(this.selectedFeatureData.index).get("featureDescription").value.replace(this.quickSelectedText,newValue);
      //  this.featuresArray.at(this.selectedFeatureData.index).get("featureDescription").setValue(updatedValue);
        const { from, to } = this.editor2.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.editor2.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        const tr: Transaction = this.editor2.state.tr.replaceWith(from, to, replacementNode);
        const newTo = from + replacementNode.size;
        this.editor2.view.dispatch(tr);
        this.editor2.chain().focus().setTextSelection({ from, to: newTo }).run();
       this.showQuickPromptMenu=false;
      }else if(this.selectedFeatureData.type=="solution"){
        // let updatedValue=this.replaceSubstring(this.featuresArray.at(this.selectedFeatureData.index).get("solutionAssumption").value,this.quickSelectionStartIndex,this.quickSelectionEndIndex,newValue);
        // console.log("updatedValue:",updatedValue);
        // //let updatedValue=this.featuresArray.at(this.selectedFeatureData.index).get("solutionAssumption").value.replace(this.quickSelectedText,newValue);
        // this.featuresArray.at(this.selectedFeatureData.index).get("solutionAssumption").setValue(updatedValue);
        const { from, to } = this.editor3.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.editor3.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        const tr: Transaction = this.editor3.state.tr.replaceWith(from, to, replacementNode);
        const newTo = from + replacementNode.size;
        this.editor3.view.dispatch(tr);
        this.editor3.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
       }
    }
    // setTimeout(()=>{
    //   this.$changeValues.next(obj);
    // },1000);
    this.showQuickPromptMenu=false;
    this.isQuickMenuHighlighted=false;
    document.getSelection().removeAllRanges();   
  }

  onTextareaBlur(){
    if(!this.insideTextArea && !this.isQuickMenuHighlighted){
        this.showQuickPromptMenu=false;
    }
  }

  onMouseEnter(){
    
    this.insideTextArea=true;
  }

  onMouseLeave(){
    
    this.insideTextArea=false;
  }

  onMouseEnterQuickPrompt(){
    this.isQuickMenuHighlighted=true;
  }

  onMouseLeaveQuickPrompt(){
    this.isQuickMenuHighlighted=false;
  }

  onClickOutside(event){
     if(!this.isQuickMenuHighlighted){
    this.isQuickMenuHighlighted=false;
    this.showQuickPromptMenu=false;
     }
     
  }

  onMouseDown(event){

    if(this.isQuickMenuHighlighted){
      return;
    }
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    if(!this.showQuickPromptMenu){
      const screenWidth = window.innerWidth;
      const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;

      this.menuPosition = {
        top: `${event.pageY - 120}px`,
        left: `${xPosition}px`
      };
      this.mouseDownPos=event.pageY-120;
    }
  }

  onSelectionChange(event,targetId){
    if(!this.is_write_allowed){
      return;
     }
    // console.log("On Selection Change:",event.clientX,event.clientY);
     const textarea = event.target as HTMLTextAreaElement;
    // const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    // console.log("Event:",selectedText);
    this.quickSelectionStartIndex=textarea.selectionStart;
    this.quickSelectionEndIndex=textarea.selectionEnd;
    //const targetId = (event.target as HTMLElement).id;
    //console.log('Target element ID:', targetId);

    if(targetId.includes("description")||targetId.includes("solution")){
      let data=targetId.split("-");
      this.selectedFeatureData={
        type:data[0],
        index:parseInt(data[1])
      };
    }else{
      this.selectedFeatureData={
        type:"",
        index:0
      };
    }
    
    let clientY=0
    if(this.mouseInitialPosition!=null){
      clientY=this.mouseInitialPosition;
    }
    
    // this.quickSelectedText=selectedText;
    if (this.quickSelectedText.trim().length > 0) {
      
     /*
      if(event.pageY<this.mouseDownPos){

      
      this.menuPosition = {
          top: `${event.pageY-90}px`,
          left: this.menuPosition.left
      };

      }
      */

      /*
        this.menuPosition = {
            top: `${clientY + window.scrollY -30}px`,
            left: `${90+window.scrollX}px`
        };
*/
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    }
  }

  sharepop(){
    const dialogRef = this.dialog.open(ShareComponent,{ 
      panelClass: 'custom-dialog-container-share',
      disableClose: true,
      data: {
        proposal_id: this._proposal_id,
        _page_id: this._page_id
      }
    });

  }
  
  startTour(){
    try{
      this._analyticsService.trackEvent(analytics_event.PRODUCT_TOUR,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
    }catch(error){
    }
    const intro  = this.introJS.setOptions({
      steps: [
        {
          element: document.getElementById('propsalStat'),
          title: 'Proposal Overview',
          position: 'bottom',
          intro: 'You can see all the key metrics at the top of the proposal section.'
        },
        {
          element: '.mat-mdc-tab-header',
          title: 'Proposal Sections',
          position: 'top',
          intro: 'You can see all the key details about the proposal under different sections. '
        },
        {
          element: document.getElementById('matTab'),
          title: 'Proposal Summary',
          position: 'top',
          intro: 'This section provides an executive summary and scope of the proposal. ',
      
        },
        {
          element: document.getElementById('matTab'),
          title: 'Rewrite with AI',
          position: 'top',
          intro: 'You can highlight any content to rewrite it with AI and choose from the editing options.'
        },
        {
          element: document.getElementById('matTab'),
          title: 'Solution',
          position: 'top',
          intro: 'View features suggested from your initial brief, modify them instantly, add new ones from our database, and get contextual help using AI recommendations.'
        },
        {
          element: document.getElementById('matTab'),
          title: 'Project Plan',
          position: 'top',
          intro: 'Calculate the actual proposal cost by adding project overheads and contingencies to the total project effort.'
        },
        {
          element: document.getElementById('matTab'),
          title: 'AI Insight',
          position: 'top',
          intro: 'Enhance input details to achieve optimal output during regeneration.'
        },
        {
          element: document.getElementById('talk_to_expert'),
          title: 'Book a Demo',
          position: 'right',
          intro: 'Book a Free one-on-one meeting with a Salesforce expert to review your proposal and receive valuable feedback on how to improve it.'
        },
      
  ],
  
  nextLabel: "Next",
  showProgress: false,
  showBullets: false,
  disableInteraction: false,
  exitOnOverlayClick: false
  
  
  })

  this.introJS.onchange((targetElement) => {

    // const element = document.querySelector('#propsalStat');
    // element.scrollIntoView({ behavior: 'smooth' });
  
    if(this.introJS._currentStep == 3){
      // console.log(this.user); 
        // console.log(document.getElementById('summaryText'));
      this.editor.commands.selectAll();
      // const textarea = document.getElementById('summaryText') as HTMLElement;

      // textarea.select();

      // this._router.navigate(['/proposal/'+this.user.last_proposal_state]);
    }

    if(this.introJS._currentStep == 3 || this.introJS._currentStep == 2 ){
      this.tabGroup.selectedIndex = 0;

      setTimeout(function(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },500);
     

    }
   
    if(this.introJS._currentStep == 4){
      this.tabGroup.selectedIndex = 2;
      
      setTimeout(function(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },500);
     
    }

    if(this.introJS._currentStep == 5){
      this.tabGroup.selectedIndex = 3;
      
      setTimeout(function(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },500);
     
    }

    if(this.introJS._currentStep == 6){
      this.tabGroup.selectedIndex = 4;
      
      setTimeout(function(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },500);
     
    }

    if(this.introJS._currentStep == 7){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'auto';
    }

    
    window.scrollTo(0,0);
    // console.log(`Current step: ${this.introJS._currentStep}`);
  });
  // intro.onbeforechange((targetElement: HTMLElement) => {

  //   this.onNextStep(targetElement);
  //   return true;
  // });
 

  this.introJS.oncomplete(() => {
    document.body.style.overflow = 'auto';
    localStorage.removeItem('productTour');
  });

  this.introJS.onexit(function () {
    document.body.style.overflow = 'auto';
    localStorage.removeItem('productTour');
  });
  this.introJS.start();
  }

  openfixedBox(id){
    if(id=='all'){
      if(this.quickActionIds.length == this.featuresArray.controls.length)this.quickActionIds = [];
      else {
        this.quickActionIds = this.featuresArray.controls.map(control => control.get('_id')?.value) as string[];
        // this.featuresArray.controls.forEach(control => {
        //   const _id = control.value._id; 
        //     const index = this.quickActionIds.indexOf(_id);
        //     if (index === -1) {
        //         this.quickActionIds.push(_id);
        //     }
        // });
      }
    }
    else {
      let indexId = this.quickActionIds.indexOf(id);
      if(indexId>-1)this.quickActionIds.splice(indexId, 1);
      else this.quickActionIds.push(id);
    }
  }

  displaySelected(id){
    let classes = '';
    if(this.proposal_detail && this.proposal_detail.epics && this.proposal_detail.epics.length){
      classes += ' inventory-grid';
    }
    else {
      classes += ' small-inventory-grid';
    }
    let indexId = this.quickActionIds.indexOf(id);
    if(indexId>-1){
      classes += ' selected';
    }
    return classes;
  }

  bulkDelete(){
    const dialogRef = this._confirmationService.open({
      dismissible:true,
      title:"Delete Features?",
      message:"Are you sure you want to delete " + (this.quickActionIds.length==1?"this feature":"these features")+ "?",
      actions:{
        confirm:{
          show:true,
          label:"Delete",
          color:'warn'
        },
        cancel:{
          show:true,
          label:"Cancel"
        }
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if(result=='confirmed'){ 
        if(this.quickActionIds.length==1){
          this._proposalService.update({id:this._proposal_id,feature_action:'delete',feature_data:{_id:this.quickActionIds[0]}})
          .subscribe(
            (value) =>
            {
              if(value.status){
                this.quickActionIds = [];
                this.flashMessageDelete = true;
                setTimeout(() =>
                {
                  this.tabGroupForm.get('search').setValue('');
                  this.getProposal(true);
                  this.flashMessageDelete = false;
                }, 2000);
              }
            },
            (response) =>
            {
            },
          );
        }
        else {
          this._proposalService.update({id:this._proposal_id,feature_action:'bulk_delete',feature_data:{_id:this.quickActionIds}})
          .subscribe(
            (value) =>
            {
              if(value.status){
                this.quickActionIds = [];
                this.flashMessageDelete = true;
                setTimeout(() =>
                {
                  this.tabGroupForm.get('search').setValue('');
                  this.getProposal(true);
                  this.flashMessageDelete = false;
                }, 2000);
              }
            },
            (response) =>
            {
            },
          );
        }
      }
    });    
  }

  bulkExport(){
    this._proposalService.downloadSummary({id:this._proposal_id,type:'features',features:this.quickActionIds})
    .subscribe(
      (value) =>
      {
        if(value.status){
          this.quickActionIds = [];
          window.open(value.data, '_blank');
        }
      },
      (response) =>
      {
        // console.log(response);
      },
    );
  }

  onChangeWorkspace(event){
    this.selectedWorkSpace=event;
    console.log("Current Workspace: ",event);
  }

  getTotalEpics(){
    let totalEpics=0;

    for(let theme of this.proposal_detail.epics){
      totalEpics+=theme.epics.length;
    }

    return totalEpics;

  }

  onSaveEpics(epics){
    this.parentData = '';
    this.update({id:this.proposal_detail._id,epics:((epics.hasOwnProperty('epics') && epics.epics.length)?epics.epics:epics),ValueChanged:true});
  }

  onSaveThemeAndEpics(event){
    this.parentData = '';
    this.update({id:this.proposal_detail._id,epics:event.epics,businessCapabilities:event.businessCapabilities,ValueChanged:event.ValueChanged});
  }

  onFeatureUpdate(event){
    this.getProposal(true);
  }

  onKCDocUpload(event){
    this.getProposal(true);
  }

  onClickJiraIntegrations(){
    const dialogRef = this.dialog.open(JiraIntegrationComponent,{ panelClass: 'custom-dialog-container',disableClose: true,data:{proposalID:this.proposal_detail._id}  });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  navigateBack(){
    this._router.navigate(['proposal']);
  }

}
