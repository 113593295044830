<div class="flex flex-col flex-auto min-w-0 relative" >
    <div class="flex-auto">
        <div class="formBox">
            <div class="formHead flex justify-between">
                <h4 class="text-2xl">Add Configurations</h4>
                <span class="close-dialog2 cursor-pointer" [mat-dialog-close]="true" > 
                    <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
                </span>
            </div>
            <div class="separator"></div>
            <form action="" class="mainform" >
                <div class="desc flex flex-col">
                    <label class="font-medium">Title</label>
                    <textarea [formControlName]="'title'"  name="" id="" class="border mt-2 rounded w-full p-3" row="2" style="resize: none;" ></textarea>
                </div>

                <div class="fir flex flex-wrap gap-6 mt-6">
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Theme</mat-label>
                        <mat-select [placeholder]="'Select Theme'" [formControlName]="'theme'"  class="selectOption2" >
                            <mat-option >Test</mat-option> 
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Epic</mat-label>
                        <mat-select [placeholder]="'Select Epic'" [formControlName]="'epic'"  class="selectOption2">
                            <mat-option >Test</mat-option> 
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label> Category</mat-label>
                        <mat-select [placeholder]="'Select  Category'" [formControlName]="'release'"  class="selectOption2">
                            <mat-option >Test</mat-option> 
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Sub Category</mat-label>
                        <mat-select [placeholder]="'Select Sub Category'" [formControlName]="'release'"  class="selectOption2">
                            <mat-option >Test</mat-option> 
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Work Type</mat-label>
                        <mat-select [placeholder]="'Select Work Type'" [formControlName]="'release'"  class="selectOption2">
                            <mat-option >Test</mat-option> 
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Action</mat-label>
                        <mat-select [placeholder]="'Select Action'" [formControlName]="'release'"  class="selectOption2">
                            <mat-option >Test</mat-option> 
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="desc flex flex-col mt-6">
                    <label class="font-medium">Description*</label>
                    <textarea name="" id="" class="descText"></textarea>
                </div> 

                <div class="desc flex flex-col mt-6">
                        <label class="font-medium">Precondition</label>
                        <textarea name="" id="" class="descText"></textarea>
                </div> 


                <div class="formAct flex justify-end mb-8 items-center">
                    <button  mat-flat-button [color]="'primary'" class="mt-6" >
                        <div class="flex gap-1 items-center">
                        <!-- <mat-progress-spinner  mode="indeterminate"></mat-progress-spinner>  -->
                        <span>Save</span>
                        </div>
                    </button>
                </div>


            </form>

        </div>
    </div>
</div>