import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, JsonPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Component, OnDestroy, OnInit, ViewEncapsulation,ViewChild, Input, AfterViewInit, TemplateRef, AfterViewChecked, ElementRef, ViewChildren, QueryList, HostListener, Output, EventEmitter, Renderer2  } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators,UntypedFormGroup,UntypedFormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';


export interface ObjectElement {
  name: string;
  id: number;
  description: string;
  apiname: string;
  completion: string;
  action: string;
  completionClass: string;
  actionClass:string;
}

export interface ApexElement {
  name: string;
  id: number;
  description: string;
  completion: string;
  action: string;
  completionClass: string;
  actionClass:string;
}



const ELEMENT_DATA: ObjectElement[] = [
  {id: 1, name: 'VehicleInventory', description: 'Stores vehicle details such as make, model, year, VIN, and availability status.', apiname: 'Manual',completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 2, name: 'CustomerDetails', description: 'Contains customer profiles, purchase history, and contact preferences.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 3, name: 'FinanceOptions', description: 'Manages various financing plans and associated terms available to customers.', apiname: 'Manual',completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy' },
  {id: 4, name: 'DealershipLocations', description: 'Stores dealership location details and contact information for easy customer reference.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 5, name: 'SalesLeads', description: 'Captures and categorizes new sales leads, including source and lead stage.', apiname: 'Manual',completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 6, name: 'AppointmentHistory', description: 'Logs past and upcoming customer appointments, including test drives and service visits.', apiname: 'Manual',completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy'},
  {id: 7, name: 'WarrantyRecords', description: 'Maintains warranty information for each vehicle sold and service coverage details.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 8, name: 'TradeInOffers', description: 'Tracks trade-in offers made to customers, including evaluation criteria and final offer details.', apiname: 'Manual',completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 9, name: 'SalesForecasts', description: 'Contains data on projected sales targets based on historical trends and market analysis.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 10, name: 'ServiceRequests', description: 'Manages customer service requests, including issue tracking and resolution status.', apiname: 'Manual',completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy' },
  {id: 11, name: 'MarketingCampaigns', description: 'Stores information on active and past marketing campaigns, including target audience and results.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 12, name: 'CustomerFeedback', description: 'Collects and organizes feedback from customers to guide service improvements.',  apiname: 'Manual',completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy'  },
  {id: 13, name: 'InventoryForecasting', description: 'Forecasts inventory needs based on current stock and expected sales volume.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 14, name: 'SalesPerformance', description: 'Tracks sales performance metrics for reporting and analysis purposes.', apiname: 'Manual',completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 15, name: 'RecallNotifications', description: 'Stores details on vehicle recalls and tracks notifications sent to affected customers.', apiname: 'Manual',completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy' },
];



const ELEMENT_DATA2: ApexElement[] = [
  {id: 1, name: 'LeadAssignmentHandler', description: 'Manages automatic lead assignment based on criteria such as region, lead source, and sales rep load.', completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 2, name: 'VehicleInventoryManager', description: 'Handles vehicle inventory data integration and updates in Salesforce.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 3, name: 'CustomerNotification', description: 'Automates customer notifications for test drives, order updates, and promotional offers.', completion:'Not Started',action:'Generate Code',completionClass:'notstarted',actionClass:'deploy' },
  {id: 4, name: 'FinanceApplication', description: 'Processes finance applications, including credit checks and approval workflows.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 5, name: 'DealerLocator', description: 'Provides functionality to locate and display nearby dealerships based on customer location.', completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 6, name: 'SalesForecasting', description: 'Generates sales forecasts using historical data and predictive models for future planning.', completion:'Not Started',action:'Generate Code',completionClass:'notstarted',actionClass:'deploy'},
  {id: 7, name: 'AppointmentScheduler', description: 'Manages scheduling of test drives and service appointments, with customer reminders.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 8, name: 'WarrantyServiceManager', description: 'Tracks and manages warranty information and service eligibility for sold vehicles.', completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy' },
  {id: 9, name: 'TradeInEvaluator', description: 'Calculates trade-in values based on vehicle condition, mileage, and market data.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 10, name: 'SalesRepPerformance', description: 'Analyzes sales rep performance metrics and generates reports.', completion:'Not Started',action:'Deploy',completionClass:'notstarted',actionClass:'deploy' },
  {id: 11, name: 'CustomerFeedbackHandler', description: 'Collects and analyzes customer feedback post-sale to improve service quality.', completion:'Deployed',action:'Generate Code',completionClass:'deployed',actionClass:'view' },
  {id: 12, name: 'InventoryForecasting', description: 'Predicts inventory needs based on sales trends and order history.',  completion:'In Progress',action:'Deploy',completionClass:'inprogress',actionClass:'deploy'  },
  {id: 13, name: 'DealApprovalWorkflow', description: 'Manages approval workflows for vehicle deals and pricing adjustments.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 14, name: 'SalesOrderProcessor', description: 'Processes vehicle sales orders and updates relevant records.', completion:'Deployed',action:'View',completionClass:'deployed',actionClass:'view' },
  {id: 15, name: 'VehicleRecallNotifier', description: 'Notifies customers of vehicle recalls and manages scheduling for recall-related service.', completion:'Not Started',action:'Generate Code',completionClass:'notstarted',actionClass:'deploy' },
];

@Component({
  selector: 'app-low-level-design',
  standalone: true,
  imports: [ MatIconModule, MatButtonModule, MatSelectModule, MatPaginator, MatPaginatorModule, FormsModule, MatRippleModule, ReactiveFormsModule, MatMenuModule, MatTabsModule, MatFormFieldModule, NgTemplateOutlet, MatInputModule, MatButtonToggleModule, NgFor, NgIf, MatTableModule, NgClass, MatProgressBarModule, CurrencyPipe, JsonPipe, CommonModule],
  templateUrl: './low-level-design.component.html',
  styleUrl: './low-level-design.component.scss'
})
export class LowLevelDesignComponent {

  displayedColumns: string[] = ['name', 'description','completion','action'];
  dataSource = ELEMENT_DATA;

  displayedColumns2: string[] = ['name', 'description','completion','action'];
  dataSource2 = ELEMENT_DATA2;

  activeTab='objects';


  changeTab(data){
    this.activeTab = data;
  }


  getcompletionIc(data){
    if(data=='In Progress'){
      return 'assets/icons/inprog.svg';
    }

    if(data=='Deployed'){
      return 'assets/icons/deployed.svg';
    }

    if(data=='Not Started'){
      return 'assets/icons/notstarted.svg';
    }
    
  }


}
