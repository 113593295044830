import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({providedIn: 'root'})
export class CommonService
{
    technologiesTypes = [
        {
          value: "Salesforce",
          label: "Salesforce",
          active: true
        },
        {
          value: "ServiceNow",
          label: "Service Now",
          active: false
        },
        {
          value: "HubSpot",
          label: "HubSpot",
          active: false
        },
        {
          value: "Workday",
          label: "Workday",
          active: false
        },
        {
          value: "Microsoft Dynamics",
          label: "Microsoft Dynamics",
          active: false
        },
        {
          value: "Oracle NetSuite",
          label: "Oracle NetSuite",
          active: false
        }
    ];
    
    cloudTypes = {
      Salesforce: [
        { value: "Sales Cloud", label: "Sales Cloud", active: true },
        { value: "Service Cloud", label: "Service Cloud", active: true },
        { value: "Platform", label: "Platform", active: true },
        { value: "CPQ & Billing", label: "CPQ & Billing", active: true },
        { value: "Experience Cloud", label: "Experience Cloud", active: true },
        { value: "CRM Analytics", label: "CRM Analytics", active: true },
        { value: "Field Service", label: "Field Service", active: true },
        { value: "Health Cloud", label: "Health Cloud", active: true },
        { value: "B2C Commerce Cloud", label: "B2C Commerce Cloud", active: false },
        { value: "B2B Commerce Cloud", label: "B2B Commerce Cloud", active: false },
        { value: "Data Cloud", label: "Data Cloud", active: false },
        { value: "NonProfit Cloud", label: "NonProfit Cloud", active: false },
        { value: "Net Zero Cloud", label: "Net Zero Cloud", active: false },
        { value: "Mulesoft", label: "Mulesoft", active: false },
        { value: "Slack", label: "Slack", active: false },
        { value: "Quip", label: "Quip", active: false },
        { value: "Marketing Cloud", label: "Marketing Cloud", active: false },
        { value: "Marketing Cloud Account Engagement", label: "Marketing Cloud Account Engagement", active: false },
        { value: "Financial Services Cloud", label: "Financial Services Cloud", active: false },
        { value: "Consumer Goods Cloud", label: "Consumer Goods Cloud", active: false },
        { value: "Manufacturing Cloud", label: "Manufacturing Cloud", active: false },
        { value: "Industries Platform", label: "Industries Platform", active: false },
        { value: "IoT Cloud", label: "IoT Cloud", active: false },
        { value: "Education Cloud", label: "Education Cloud", active: false }
      ],
      ServiceNow: [
          {
            value: "Platform",
            label: "Platform",
            active: false
          },
      ],
      AWS: [
          {
            value: "Platform",
            label: "Platform",
            active: false
          },
      ]
    };
    
    

    fileTypes=[
      {value:0,label:"PDF"},
      {value:1,label:"CSV"},
      {value:2,label:"TXT"},
      {value:3,label:"DOC"},
      {value:4,label:"DOCX"},
      {value:5,label:"PPT"},
      {value:6,label:"PPTX"},
      {value:7,label:"JPEG"},
      {value:8,label:"PNG"},
      {value:9,label:"JPG"},
      {value:10,label:"XLSX"},
    ];



    typeOptions = [
        { value: 0, label: 'PDF' },
        { value: 1, label: 'CSV' },
        { value: 2, label: 'Website' },
        { value: 3, label: 'Youtube Video' },
        { value: 4, label: 'TXT' },
    ];

    statusTypes = [
        {value:0,label:"Pending"},
        {value:1,label:"In Progress"},
        {value:2,label:"Completed"},
        {value:-1,label:"Error"},
    ]

    proposalStatusTypes = [
      {value:0,label:"New"},
      {value:1,label:"In Progress"},
      {value:2,label:"Completed"}
    ]

    complexities = [
      {value:"Low",label:"Low"},
      {value:"Medium",label:"Medium"},
      {value:"High",label:"High"}
    ]

    industries = [
      {
        label: "Automotive",
        value: "Automotive"
      },
      {
        label: "Business Services",
        value: "Business Services"
      },
      {
        label: "Communications",
        value: "Communications"
      },
      {
        label: "Consumer Goods",
        value: "Consumer Goods"
      },
      {
        label: "Education",
        value: "Education"
      },
      {
        label: "Energy and Utilities",
        value: "Energy and Utilities"
      },
      {
        label: "Financial Services",
        value: "Financial Services"
      },
      {
        label: "Government",
        value: "Government"
      },
      {
        label: "Healthcare and Life Sciences",
        value: "Healthcare and Life Sciences"
      },
      {
        label: "Hospitality",
        value: "Hospitality"
      },
      {
        label: "Insurance",
        value: "Insurance"
      },
      {
        label: "Manufacturing",
        value: "Manufacturing"
      },
      {
        label: "Media and Entertainment",
        value: "Media and Entertainment"
      },
      {
        label: "Non-Profit",
        value: "Non-Profit"
      },
      {
        label: "Professional Services",
        value: "Professional Services"
      },
      {
        label: "Retail Industry",
        value: "Retail Industry"
      },
      {
        label: "Real Estate",
        value: "Real Estate"
      },
      {
        label: "Transportation",
        value: "Transportation"
      }
    ];

    suggestionTypes = [
      {
        value: 0,
        label: "New",
        active: true
      },
      {
        value: 1,
        label: "Start Tour",
        active: false
      },
      {
        value: 2,
        label: "View",
        active: false
      }
  ];
  suggestionLinkTypes = [
    {
      value: 0,
      label: "Internal",
      active: true
    },
    {
      value: 1,
      label: "External",
      active: false
    }
];

  priorityTypes=[
    {value:"Low",label:"Low",icon:'assets/icons/p_low.svg'},
    {value:"Medium",label:"Medium",icon:'assets/icons/p_medium.svg'},
    {value:"Critical",label:"Critical",icon:'assets/icons/p_high.svg'},
    {value:"High",label:"High",icon:'assets/icons/p_highest.svg'},
  ];

  suggestionDisplayTypes = [
    {
      value: 0,
      label: "Fixed",
      active: false
    },
    {
      value: 1,
      label: "Daily",
      active: true
    },
    {
      value: 2,
      label: "Monthly",
      active: false
    },
    {
      value: 3,
      label: "Yearly",
      active: false
    },
  ];

    private _httpClient = inject(HttpClient);
    private _authService=inject(AuthService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

}
