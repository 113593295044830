import { Component, ElementRef, Inject, ViewChild,TemplateRef,ViewContainerRef, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, NgZone, ComponentRef } from '@angular/core';

import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Overlay, OverlayRef,OverlayContainer } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JIRA } from 'app/core/common/constants';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { DatePipe, NgIf,NgFor, NgClass, DOCUMENT } from '@angular/common';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { DialogRef } from '@angular/cdk/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-add-configuration',
  standalone: true,
  imports: [MatIconModule,NgIf,MatProgressSpinnerModule,DatePipe,NgFor,NgClass,ReactiveFormsModule,MatDialogModule,MatIcon,MatMenuModule,MatInputModule,MatSelectModule,MatButtonModule,MatOptionModule,MatCheckboxModule,MatFormFieldModule,MatRadioModule],
  templateUrl: './add-configuration.component.html',
  styleUrl: './add-configuration.component.scss'
})
export class AddConfigurationComponent {


  constructor(private dialog:MatDialog,){

  }

}
