<div class="w-full flex flex-col">
    <!-- Insights -->
    <div class="min-h-72 min-w-full bg-white rounded-md shadow-sm py-5 mt-5 relative">
        <div class="w-full flex px-4 headingBox ">
            <div class="flex w-full flex-col">
                <div class="flex w-full flex-col justify-between">
                    <h4 class="font-semibold text-2xl">Resource Allocation</h4>
                    <span class="font-medium smallText"> <span class="blueText">{{resourcesArray.length}} </span> Resources , <span class="blueText">{{(resourcesArray && resourcesArray.length > 0)?getTotalHours():0}} </span> Hours </span>
                </div>

                <!-- <div class="tagBox gap-1">
                    <div class="flex text-md font-semibold gap-1">
                        <div class="text-[#4F45E4]">{{resourcesArray.length}}</div>
                        <div class="text-[#656A77]">Resources,</div>
                    </div>
                    <div class="flex text-md font-semibold gap-1">
                        <div class="text-[#4F45E4]">{{(resourcesArray && resourcesArray.length > 0)?getTotalHours():0}}</div>
                        <div class="text-[#656A77]">Hours</div>
                    </div>
                </div> -->

            </div>
            <div class="actionBt flex gap-4">
                <button *ngIf="(_page_id=='proposal' && is_write_allowed)" mat-flat-button [color]="'primary'" (click)="onSelectInsight({})">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:plus'" ></mat-icon>  
                    Add Resource
                </button>
                <!-- <button *ngIf="(_page_id=='proposal')" mat-flat-button style="border: 1px solid #CFD1D4;" (click)="downloadSummary()" [disabled]="(download_summary_loader)">  
                    <mat-progress-spinner *ngIf="download_summary_loader" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                    Download Summary
                </button> -->
            </div>
        </div>
     

        <div class="mt-0">
            <form [formGroup]="tabGroupForm">
                <ng-container *ngIf="resourcesArray && resourcesArray.length > 0">
                    <div class="flex flex-col overflow-auto">
                        <div class="border-bottom tableHeading cursor-pointer flex flex-auto h-full items-center justify-between min-w-0 mt-6 pb-4 pl-9 pr-7">
                            <div class="text-md uppercase text-secondary font-medium truncate contentm w-full">
                                Resource 
                            </div>
                            <div class="text-md uppercase text-secondary font-medium truncate contentm w-full text-center ">
                                Location
                            </div>
                            <div class="text-md uppercase text-secondary font-medium truncate contentm w-full text-center ">
                                Hourly Rate
                            </div>
                            <div class="text-md uppercase text-secondary font-medium truncate contentm w-full text-center ">
                                Total Weeks
                            </div>
                            <div class="text-md uppercase text-secondary font-medium truncate contentm w-full text-center ">
                                Total Hours
                            </div>
                            <div class="text-md uppercase text-secondary font-medium truncate contentm w-full text-center ">
                                Cost Distribution
                            </div>
                        </div>
            
                        <!-- Task Questions -->
                        <div cdkDropList (cdkDropListDropped)="dropped($event)"  [cdkDropListData]="resourcesArray.controls" class="questions-container w-full">
                            <ng-container *ngFor="let eachResource of resourcesArray.controls; let i = index" formArrayName="resources">
                                <div
                                    [id]="'eachResource-' + eachResource._id"
                                    class="group w-full select-none hover:bg-gray-100 dark:hover:bg-hover h-16 "
                                    cdkDrag
                                    (click)="onSelectInsight(eachResource)"
                                    [cdkDragLockAxis]="'y'">
                                    
                                    <!-- Drag preview -->
                                    <div class="flex flex-0 w-0 h-0" *cdkDragPreview></div>
                                    
                                    <!-- Task content -->
                                    <div class="relative flex items-center h-full pl-7 ">
                                        
                                        <!-- Selected indicator -->
                                        <!-- <ng-container *ngIf="selectedQuestion && selectedQuestion.id === question.id">
                                            <div class="z-10 absolute inset-0 bg-primary opacity-10"></div>
                                        </ng-container> -->
                                        
                                        <!-- Drag handle -->
                                        <div
                                            class="md:hidden absolute mr-2 flex items-center justify-center checkicm inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                            cdkDragHandle>
                                            <mat-icon
                                                class="icon-size-5 text-hint"
                                                [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                        </div>
                                        <div class="flex flex-auto items-center justify-between min-w-0 h-full pr-7 pl-2 cursor-pointer">
                                            <!-- Title & Placeholder -->
                                            <div class="truncate contentm w-full ">
                                                {{eachResource.get('title').value}}
                                            </div>
                                            <div class="truncate contentm w-full text-center ">
                                                {{eachResource.get('type').value}}
                                            </div>
                                            <div class="truncate contentm w-full text-center">
                                                {{getCurrencyIc(eachResource.get('currency').value)}}{{formatMoney(eachResource.get('rate').value)}}
                                            </div>
                                            <div class="truncate contentm w-full text-center ">
                                                {{eachResource.get('weeks').value}}
                                            </div>
                                            <div class="truncate contentm w-full text-center ">
                                                {{eachResource.get('hours').value}}
                                            </div>
                                            <div class="truncate contentm w-full text-center ">
                                                {{getCurrencyIc(eachResource.get('currency').value)}}{{formatMoney(eachResource.get('cost').value)}}
                                            </div>
    
                                            <!-- Due date -->
                                            <!-- <div class="text-sm whitespace-nowrap" [ngStyle]="{'color':question.completed==true?'#16A34A':'#94A3B8'}">
                                                +5 Score
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
    
                </ng-container>
            </form>
        </div>

    </div>

</div>


<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'rewriteAIDrawer'"
    [position]="'right'" 
    (openedChanged)="openedChanged($event)"
    #insightDrawer>
    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex items-center justify-between mt-5 ml-3 mr-3">
            <ng-container *ngIf="true">
                <div class="flex items-center justify-center">
                    <span class="ml-2 font-semibold">{{selectedResource?'Edit Resource':'Add New Resource'}}</span>
                </div>
            </ng-container>
            <div class="flex items-center">
                <!-- More menu -->
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="moreMenu">
                    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
                </button>
                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item (click)="deleteResource()" [disabled]="delete_resource_loader">
                        <mat-icon *ngIf="!delete_resource_loader" [svgIcon]="'heroicons_outline:trash'"></mat-icon> 
                        <mat-progress-spinner *ngIf="delete_resource_loader" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                        <span>Delete Resource</span>
                    </button>
                </mat-menu>

                <!-- Close button -->
                <button
                    mat-icon-button
                    (click)="onCloseDrawer()"
                    >
                    <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
       
        <form
        class="flex flex-col flex-auto p-6 pt-10 sm:p-8 sm:pt-10 overflow-y-auto"
        [formGroup]="insightForm">
            <div>
                <mat-form-field
                    class="w-full mb-6"
                    [subscriptSizing]="'dynamic'">
                    <mat-label class="mb-4">Resource Name</mat-label>
                    <input matInput [formControlName]="'title'" >
                </mat-form-field>
            </div>

            <div *ngIf="selectedResource">
                <mat-form-field
                    class="w-full mb-6"
                    [subscriptSizing]="'dynamic'">
                    <mat-label class="mb-4">Resource Type</mat-label>
                    <!-- <mat-select *ngIf="!selectedResource" matInput [required]="true" (selectionChange)="selectionResource($event)">
                        @for (option of  resourceTypes; track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                        }
                    </mat-select> -->
                    <input *ngIf="selectedResource" matInput readonly [formControlName]="'name'" >
                </mat-form-field>
            </div>
            <div *ngIf="!selectedResource">
                <div class="mb-6">
                    <div class="font-medium mb-1.5">Resource Type *</div>
                    <div class="flex flex-wrap items-center -m-1.5">
                        <!-- Tags -->
                        <ng-container *ngIf="insightForm.get('name').value">
                            <ng-container>
                                <div class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                                    <span class="text-md font-medium whitespace-nowrap">{{insightForm.get('name').value}}</span>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div
                            class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                            (click)="openTagsPanel()"
                            #tagsPanelOrigin>
                            <ng-container *ngIf="insightForm.get('name').value">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                                <span class="ml-1.5 text-md font-medium whitespace-nowrap">Edit</span>
                            </ng-container>

                            <ng-container *ngIf="!insightForm.get('name').value">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                <span class="ml-1.5 text-md font-medium whitespace-nowrap">Add</span>
                            </ng-container>
        
                            <!-- Tags panel -->
                            <ng-template #tagsPanel>
                                <div class="w-60 rounded border shadow-md bg-card">
                                    <!-- Tags panel header -->
                                    <div class="flex items-center m-3 mr-2">
                                        <div class="flex items-center">
                                            <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                            <div class="ml-2">
                                                <input
                                                    class="w-full min-w-0 py-1 border-0"
                                                    type="text"
                                                    placeholder="Enter Resource Type"
                                                    (input)="filterTags($event)"
                                                    (keydown)="filterTagsInputKeyDown($event)"
                                                    [maxLength]="30"
                                                    #newTagInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
                                        <!-- Tags -->
                                        <ng-container *ngIf="!tagsEditMode">
                                            <ng-container *ngFor="let tag of filteredTags; trackBy: trackByFn">
                                                <div
                                                    class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                                                    (click)="toggleTaskTag(tag)"
                                                    matRipple>
                                                    <mat-checkbox
                                                        class="flex items-center h-10 min-h-10 pointer-events-none"
                                                        [checked]="insightForm.get('name').value==tag.label"
                                                        [color]="'primary'"
                                                        [disableRipple]="true">
                                                    </mat-checkbox>
                                                    <div class="ml-1">{{tag.label}}</div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <!-- Tags editing -->
                                        <!-- <ng-container *ngIf="tagsEditMode">
                                            <div class="py-2 space-y-2">
                                                <ng-container *ngFor="let tag of filteredTags; trackBy: trackByFn">
                                                    <div class="flex items-center">
                                                        <mat-form-field
                                                            class="fuse-mat-dense w-full mx-4"
                                                            [subscriptSizing]="'dynamic'">
                                                            <input
                                                                matInput
                                                                [value]="tag.title"
                                                                (input)="updateTagTitle(tag, $event)">
                                                        </mat-form-field>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container> -->
                                        <!-- Create tag -->
                                        <div
                                            class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer hover:bg-hover"
                                            *ngIf="shouldShowCreateTagButton(newTagInput.value)"
                                            (click)="createTag(newTagInput.value); newTagInput.value = ''"
                                            matRipple>
                                            <mat-icon
                                                class="mr-2 icon-size-5"
                                                [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                            <div class="break-all">Create "<b>{{newTagInput.value}}</b>"</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <mat-form-field class="w-full mb-6">
                <mat-label>Location</mat-label>
                <mat-select matInput [formControlName]="'type'" [required]="true" >
                    @for (option of  resourceEmpType; track option) {
                      <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <div>
                <mat-form-field
                    class="w-full mb-6"
                    [subscriptSizing]="'dynamic'">
                    <mat-label class="mb-4">Hourly Rate ({{insightForm.get('currency').value?(getCurrencyIc(insightForm.get('currency').value)):'$'}})</mat-label>
                    <input type="number" min="1" matInput [formControlName]="'rate'">
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="w-full mb-6">
                    <mat-label class="mb-4">Phases</mat-label>
                    <mat-select matInput multiple [formControlName]="'resource_phases'" [required]="true" (selectionChange)="selectionPhase($event)" >
                        @for (option of  phaseTypes; track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
                
            <div>
                <mat-form-field
                    class="w-full mb-6"
                    [subscriptSizing]="'dynamic'">
                    <mat-label class="mb-4">Total Weeks</mat-label>
                    <input matInput readonly type="number" min="1" matInput [formControlName]="'weeks'" >
                    <!-- <input *ngIf="!selectedResource" matInput type="number" min="1" matInput [formControlName]="'weeks'" > -->
                </mat-form-field>
            </div>

            <div>
                <mat-form-field
                    class="w-full mb-6"
                    [subscriptSizing]="'dynamic'">
                    <mat-label class="mb-4">Total Cost ({{insightForm.get('currency').value?(getCurrencyIc(insightForm.get('currency').value)):'$'}})</mat-label>
                    <input  matInput readonly type="number" min="1" [formControlName]="'cost'" >
                </mat-form-field>
            </div>

            <button  mat-flat-button [color]="'primary'" (click)="autoSave()" [disabled]="(download_save_loader || insightForm.invalid)">  
                <mat-progress-spinner *ngIf="download_save_loader" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                Save
            </button>
        </form>
    </div>
</fuse-drawer>