<div *ngIf="(view_component!='workspace')" class="flex actBt flex-wrap items-center mt-4 gap-3 justify-between">
    <div class="subHeadingCont">                                                
        <div class="text-black font-medium text-lg">Configurations</div>
        <div class="text-[#94A3B8] text-md font-normal">Fine-tune settings for optimal performance and functionality.</div> 
    </div>
    <div>
        <button class="addThemeBt" mat-flat-button [color]="'primary'"  (click)="openDialog()">
            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="">Add Configuration </span>
        </button>
    </div>
</div>
<div class="bg-card rounded-lg shadow  py-3 mt-6">
    <!-- Header Section -->
    <section *ngIf="(view_component!='workspace')" class="flex flex-row flex-wrap justify-end px-3">
        <form class="flex flex-wrap gap-2 justify-end" >
            <mat-form-field class="fuse-mat-dense searchTab">
                <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input matInput class="text-sm font-medium" [formControlName]="'search'" [autocomplete]="'off'" [placeholder]="'Search'" >
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <img src="assets/icons/type.svg" class="w-5 mr-2" alt="">
                <mat-select  [placeholder]="'Type'" >
                    <mat-option >test</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <mat-select  [placeholder]="'Themes'" >
                    <mat-option >test</mat-option>
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix  svgIcon="heroicons_outline:squares-plus"></mat-icon>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <mat-select  [placeholder]="'Epic'" >
                    <mat-option >test</mat-option>
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix  svgIcon="heroicons_outline:rectangle-group"></mat-icon>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <img src="assets/icons/folderic.svg" class="w-5 mr-2" alt="">
                <mat-select  [placeholder]="'Category'" >
                    <mat-option >test</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <img src="assets/icons/folder2ic.svg" class="w-5 mr-2" alt="">
                <mat-select  [placeholder]="'Sub Category'" >
                    <mat-option >test</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 max-w-36 ">
                <mat-select  [placeholder]="'Cloud'" >
                    <mat-option >test</mat-option>
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix  svgIcon="heroicons_outline:cloud"></mat-icon>
            </mat-form-field>
        </form>

    </section>

    <div class="tableSec docTable mt-6">
        <div class="tabledata " >
            <div class="flex min-w-full  gap-3 py-5 px-5 tableth ">
                <div class="text-md storyTab text-left font-medium uppercase firstcol">Type</div>
                <div class="text-md smallTab text-left font-medium uppercase ">category</div>
                <div class="text-md smallTab text-left font-medium uppercase ">sub category</div>
                <div class="text-md largeTab text-left font-medium uppercase ">DESCRIPTION</div>
                <div class="text-md  smallTab text-left font-medium uppercase ">Theme</div>
                <div class="text-md  smallTab text-left font-medium uppercase ">Epic</div>
                <div class="text-md smallTab text-left font-medium uppercase ">Cloud</div>
                <div class="text-md smallTab text-left font-medium uppercase ">Linked User Stories</div>
            </div>
              
            <section>
                <div class=" tablebody" >
                    <div  class="relative group   select-none  sm:mt-0 mt-3 customB">
                        <div class="tableRow relative">
                            <div class="flex gap-3 group w-full items-center py-3 px-5  cursor-pointer tabletd border-b hover:bg-gray-100 dark:hover:bg-hover " (click)="viewDrawer.toggle();" >
                            
                                <div class="text-md text-black text-left font-regular firstcol storyTab "  >
                                    Apex Classes
                                </div>
                                <div class="text-md text-black text-left font-regular smallTab" >
                                    Manual
                                </div>
                                <div class="text-md text-black text-left font-regular  smallTab" >
                                    Manual
                                </div>
                                <div class="text-md text-black text-left font-regular largeTab" >
                                    Lorem ipsum dolor sit amet consectetur. Tortor cras sollicitudin urna augue vulputate purus semper mattis.
                                </div>
                                
                                <div class="text-md text-black text-left font-regular smallTab" >
                                    Lead Management
            
                                </div>
                                <div class="text-md text-black text-left font-regular smallTab" >
                                    Lead Capture
            
                                </div>
                                <div class="text-md text-black text-left font-regular smallTab" >
                                    lorem ipsum
            
                                </div>
        
                            
                            
                                <div class="text-md text-black text-left font-regular smallTab" >
                                    <a href="" class="linkedText"> View </a>
        
                                </div> 
                            
                            </div> 
                            <div class="flex justify-end absolute right-0 z-999 opacity-0 editBox transition-opacity duration-300 group-hover:opacity-100">
                            <div class="gap-2 mr-2">
                                <mat-icon  class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" ></mat-icon>
                                <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:trash"></mat-icon>
                            </div>
                            </div>
    
                        </div>                     
                    </div>                              
                </div>
              
            </section> 
        
        </div>
    </div>



</div>
  
  
  
  
  
  
  <fuse-drawer
      class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
      fixed
      [mode]="'over'"
      [name]="'viewDrawer'"
      [position]="'right'" 
      #viewDrawer>
     
      <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex justify-between items-center px-4 py-2" >
            <div class="">
              <h4 class="text-lg font-medium">Edit Configurations</h4>
            </div>
            <div class="actSide flex  items-center" >
                <button  mat-icon-button [matMenuTriggerFor]="moreMenu2" >
                    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'" class="icon-size-4"></mat-icon>
                </button>
                <mat-menu #moreMenu2="matMenu">
                    <button  mat-menu-item >
                        <mat-icon [svgIcon]="'heroicons_outline:pencil-square'" class="icon-size-4"></mat-icon>
                        <span>Edit </span>
                    </button>
                    <button mat-menu-item >
                        <mat-icon [svgIcon]="'heroicons_outline:trash'" class="icon-size-4"></mat-icon>
                        <span>Delete </span>
                    </button>
                   
                </mat-menu>
                <button class="ml-auto" mat-icon-button (click)="viewDrawer.toggle()">
                    <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </div>

        <div class="formSec px-4 py-2 flex flex-col gap-4">
            <form class="flex flex-col flex-auto gap-2 " >
                <div class="detailBox">
                    <div class="innerDetail mb-2">
                    <h4 class="font-medium">Type</h4>
                    <p class=" py-2 text-md text-[#3B4554]">Apex Classes</p>
                    </div>
                    <div class="innerDetail flex justify-between ">
                    <div class="type">
                        <h4 class="font-medium">Category</h4>
                        <p class=" py-2 text-md text-[#3B4554]">Lorem Ipsum</p>
                    </div>
                    <div class="type">
                        <h4 class="font-medium">Sub Category</h4>
                        <p class=" py-2 text-md text-[#3B4554]">Lorem Ipsum</p>
                    </div>
                    <div class="type">
                        <h4 class="font-medium">Cloud</h4>
                        <p class=" py-2 text-md text-[#3B4554]">Lorem Ipsum</p>
                    </div>
                    </div>
                    <div class="innerDetail flex justify-between ">
                        <div class="type">
                        <h4 class="font-medium">Theme</h4>
                        <p class=" py-2 text-md text-[#3B4554]">Lorem Ipsum</p>
                        </div>
                        <div class="type">
                        <h4 class="font-medium">Epic</h4>
                        <p class=" py-2 text-md text-[#3B4554]">Lorem Ipsum</p>
                        </div>
                        <div class="type">
                        <h4 class="font-medium">Work Type</h4>
                        <p class=" py-2 text-md text-[#3B4554]">Configuration/Customization</p>
                        </div>
                    </div>
                    <div class="innerDetail flex justify-between ">
                        <div class="type">
                        <h4 class="font-medium">Action</h4>
                        <p class=" py-2 text-md text-[#3B4554]">New/Update/Delete</p>
                        </div>
                    </div>
                    <div class="innerDetail mb-2">
                    <h4 class="font-medium">Description</h4>
                    <textarea name="" id="" class="textArea" formControlName="description">Lorem ipsum dolor sit amet consectetur. Ultricies est turpis tincidunt enim nec. Facilisis dui posuere pharetra aliquet quam ultrices in. Ultrices mauris in dictumst rhoncus urna pharetra amet. Elementum in vestibulum commodo leo pellentesque cursus. Habitant aliquam rutrum sit arcu in massa id amet. Faucibus nulla lectus interdum in velit phasellus sed. Senectus elit dignissim mauris elementum mattis. Massa interdum quam malesuada cursus elementum cras consectetur turpis porttitor. Eget senectus amet quisque lectus mattis purus. Amet neque rutrum non lacus nisl montes placerat duis. Rutrum egestas mattis eu bibendum velit quisque. Pretium duis ante tincidunt sed tincidunt tristique. Ut quisque nunc at mi at. Varius in risus pulvinar laoreet ac amet sagittis amet velit. In faucibus odio dolor volutpat tortor sit id volutpat. Mauris fames massa vitae pharetra velit vestibulum. Aliquet vitae nec purus eleifend tellus varius cras. Ut vestibulum erat duis at leo quis. Tellus morbi quis orci lectus egestas eget enim fringilla amet.</textarea>
                    </div>

                    <div class="innerDetail mb-2">
                        <h4 class="font-medium">Assumptions</h4>
                        <textarea name="" id="" class="textArea" formControlName="Assumptions">Lorem ipsum dolor sit amet consectetur. Ultricies est turpis tincidunt enim nec. Facilisis dui posuere pharetra aliquet quam ultrices in. Ultrices mauris in dictumst rhoncus urna pharetra amet. Elementum in vestibulum commodo leo pellentesque cursus. Habitant aliquam rutrum sit arcu in massa id amet. Faucibus nulla lectus interdum in velit phasellus sed. Senectus elit dignissim mauris elementum mattis. Massa interdum quam malesuada cursus elementum cras consectetur turpis porttitor. Eget senectus amet quisque lectus mattis purus. Amet neque rutrum non lacus nisl montes placerat duis. Rutrum egestas mattis eu bibendum velit quisque. Pretium duis ante tincidunt sed tincidunt tristique. Ut quisque nunc at mi at. Varius in risus pulvinar laoreet ac amet sagittis amet velit. In faucibus odio dolor volutpat tortor sit id volutpat. Mauris fames massa vitae pharetra velit vestibulum. Aliquet vitae nec purus eleifend tellus varius cras. Ut vestibulum erat duis at leo quis. Tellus morbi quis orci lectus egestas eget enim fringilla amet.</textarea>
                        </div>
                </div>

                <div class="actionBt text-right  mt-4">
                    <!-- <fuse-alert  class="mb-3 mt-3 fullwidAlert" type="success"  [showIcon]="true" [appearance]="'outline'" [dismissed]="false"  [dismissible]="true">
                      <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:check-circle'"> </mat-icon>  
                      Saved successfully!
                    </fuse-alert> -->
                    <button mat-flat-button [color]='"primary"' class="saveBt" > 
                      <!-- <mat-spinner  class="matSpinner"  [diameter]="18"></mat-spinner> -->
                      Save 
                    </button>
                  </div>
            </form>
        </div>        
      </div>
  </fuse-drawer>