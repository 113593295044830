import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PROPOSAL_LIFE_CYCLE_EVENT } from 'app/core/common/constants';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { filter } from 'rxjs';


@Component({
  selector: 'app-generating-progress-bar',
  standalone: true,
  imports: [MatIconModule,MatProgressBarModule,NgStyle],
  templateUrl: './generating-progress-bar.component.html',
  styleUrl: './generating-progress-bar.component.scss'
})
export class GeneratingProgressBarComponent {

 // @Input()
  progressValue=0;

  @Input()
  proposal;

  @Input()
  type="proposal";

  featuresCaptured=0;
  
  generating:string="Initializing Agents";
  eventsCaptured=[];
  isProcessing:boolean=false;



  constructor(private _websocketService:WebsocketService){


    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "proposal_feature")
    ).subscribe((message) => {

      this.featuresCaptured++;
      this.initProgress();

    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_scope")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["scope"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.SCOPE,name:"Generating Scope",completed:false});
      this.onUpdateEvent();
      this.initProgress();
      
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_solutions")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.SOLUTION,name:"Generating Solutions",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_themes")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["themes"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.THEMES,name:"Generating Themes",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_epics")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["epics"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.EPICS,name:"Generating Epics",completed:false});
      this.onUpdateEvent();
      this.initProgress();

    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_executive_summary")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["summary"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.SUMMARY,name:"Generating Executive Summary",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_insights")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["insights"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.INSIGHTS,name:"Generating Insights",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_proposal_conclusion")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["conclusion"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.CONCLUSION,name:"Generating Conclusion",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_stories")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["stories"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.STORIES,name:"Generating Stories",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_test_cases")
    ).subscribe(response=>{
      console.log("generating test cases",response);
      //this.proposal.generationProgress["testcases"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.TEST_CASES,name:"Generating Test Cases",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_strategy")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["strategy"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.STRATEGY,name:"Generating Strategy",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_requirements")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["requirements"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.REQUIREMENTS,name:"Generating Requirements",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "generating_theme_summaries")
    ).subscribe(response=>{
      if(this.proposal && this.proposal.generationProgress)this.proposal.generationProgress["themeSummary"]=true;
      this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.THEME_SUMMARIES,name:"Generating Theme Summary",completed:false});
      this.onUpdateEvent();
      this.initProgress();
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "epics_generated")
    ).subscribe(message=>{
      if(message.data){
        this.proposal.epics=message.data;
      }
      this.initProgress();
    });

  }

  ngOnInit(){
    this.initProgress();
  }



  initProgress(){


    let completed=0;

    if(this.proposal && this.proposal.generationProgress){

     // console.log("Total Features: ",this.proposal.epics.length);

      let totalFeatures=this.proposal.epics.length*20;

      //console.log("Total Features: ",totalFeatures);

      if(this.proposal.generationProgress.summary){
        completed+=5;
      }
      if(this.proposal.generationProgress.scope){
        completed+=5;
      }

      if(this.proposal.generationProgress.insights){
        completed+=5;
      }

      if(this.proposal.generationProgress.themes){
        completed+=5;
      }

      if(this.proposal.generationProgress.epics){
        completed+=5;
      }

      if(this.proposal.generationProgress.conclusion){
        completed+=5;
      }

      if(this.proposal.generationProgress.themeSummary){
        completed+=5;
      }
      if(this.proposal.generationProgress.requirements){
        completed+=5;
      }


      if(this.proposal.generationProgress.stories){
        completed+=5;
      }
      if(this.proposal.generationProgress.testcases){
        completed+=5;
      }

      if(this.proposal.generationProgress.strategy){
        completed+=5;
      }
      if(this.proposal.generationProgress.solutions){
        completed+=50;
      }

      let remaining=100-completed;

      
      if(totalFeatures!=0){
        let perFeatureProgress=remaining/totalFeatures;
        completed+=(perFeatureProgress*this.featuresCaptured);
      }
         


      if(this.type=="proposal"){

      }else if(this.type=="project"){

      }else{
        //test plan

      }

      this.progressValue=Math.min(
        Math.ceil(completed),
        99
      );
      
      if(this.eventsCaptured.length==0 && completed>0){
        this.eventsCaptured.push({event:PROPOSAL_LIFE_CYCLE_EVENT.SOLUTION,name:"Generating Solutions",completed:false});
        this.onUpdateEvent();
      }
    
      /*
      if(this.proposal.generationProgress.solutions){
        this.progressValue=100;
      }
        */

    }


  }

  async onUpdateEvent(){

    if(this.isProcessing){
      return;
    }
    this.eventsCaptured=this.eventsCaptured.sort((a,b)=> a.event-b.event);
    this.isProcessing=true;
    

    for (const event of this.eventsCaptured) {
      if (!event.completed) {
        this.generating = event.name;
        await this.delay(10000);
        event.completed = true;
      }
    }

    this.isProcessing = false;

    
  }

  delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

}
