import { tags } from './../../../../mock-api/apps/tasks/data';
import { trigger } from '@angular/animations';
import { Component, ElementRef, Inject, ViewChild,TemplateRef,ViewContainerRef } from '@angular/core';

import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Overlay, OverlayRef,OverlayContainer } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JIRA } from 'app/core/common/constants';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { DatePipe, NgIf,NgFor, NgClass } from '@angular/common';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { DialogRef } from '@angular/cdk/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertType } from '@fuse/components/alert';
import { CommonService } from 'app/core/common/common.service';
import { firstValueFrom } from 'rxjs';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { environment } from 'environments/environment';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
import { NgxTiptapModule } from 'ngx-tiptap';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

@Component({
  selector: 'app-user-story',
  standalone: true,
  imports: [MatIconModule,NgxTiptapModule,NgIf,MatProgressSpinnerModule,DatePipe,NgFor,NgClass,ReactiveFormsModule,MatDialogModule,MatIcon,MatMenuModule,MatInputModule,MatSelectModule,MatButtonModule,MatOptionModule,MatCheckboxModule,MatFormFieldModule,MatRadioModule],
  templateUrl: './user-story.component.html',
  styleUrl: './user-story.component.scss'
})
export class UserStoryComponent {
  @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
  @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
  isUploadingFiles:boolean=false;
  fileAttachments=[];
  @ViewChild("fileInput") private fileInput:ElementRef;
  userStory:any={_id:""};
  sizeLimitAlert:boolean=false;
  alert: { type: FuseAlertType; message: string } = {
    type   : 'success',
    message: '',
  };

  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Enter " + (this.data['category']) + " Summary ",
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ]
  });
  newStoryForm:UntypedFormGroup;

  tagArray: FormArray<any>;
  filteredTags:any[]=[];
  alltagTypes:any[]=[];
  createdOn=new Date();
  tagsEditMode: boolean = false;
  is_write_allowed: boolean = true;

  private _tagsPanelOverlayRef: OverlayRef;

  themeTypes=[];
  epicTypes=[];
  releaseTypes=[];
  statusTypes=[
    {value:JIRA.TO_DO,label:"To Do"},
    {value:JIRA.IN_PROGRESS,label:"In Progress"},
    {value:JIRA.DONE,label:"Done"}
  ]
  priorityTypes=[
    {value:JIRA.MEDIUM,label:"Medium",icon:'assets/icons/p_medium.svg'},
    {value:JIRA.HIGHEST,label:"Highest",icon:'assets/icons/p_highest.svg'},
    {value:JIRA.HIGH,label:"High",icon:'assets/icons/p_high.svg'},
    {value:JIRA.LOWE,label:"Low",icon:'assets/icons/p_low.svg'},
    {value:JIRA.LOWEST,label:"Lowest",icon:'assets/icons/p_lowest.svg'}
  ];
  user=null;
  proposal;
  isSavingStory:boolean=false;
  constructor(private _formBuilder: UntypedFormBuilder,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private _commonService:CommonService,
  private _userService:UserService,
  private _authService:AuthService,
  private dialog:MatDialogRef<UserStoryComponent>,
  private _proposalService:ProposalService,
  private atlassianService:AtlassianService,private _viewContainerRef: ViewContainerRef,
  private _overlay: Overlay,private overlayContainer: OverlayContainer){

    if(data){
      this.themeTypes=data.themeTypes;
      this.epicTypes=data.epicTypes;
      this.proposal=data.proposal;
      this.userStory=data.story || {_id:""};
      this.is_write_allowed=data.is_write_allowed;
      // console.log("Data: ",data);
    }


    console.log(data);
    console.log('asdf');

    this._userService.user$
    .subscribe((user: User) =>
    {
        this.user = user;

    });

  }

  getStoryID(){
    if(this.userStory._id!=""){
      if(this.userStory.jiraKey && this.userStory.jiraKey!=""){
        return this.userStory.jiraKey;
      }
      return this.userStory.storyID;
    }

    return "";
  }

  initReleaseTypes(){
    
    this.releaseTypes=this.proposal.releases_list.map((release)=>{
      return  {value:release._id,label:release.name};

    });
  }
  ngOnInit(): void {
    this.tagArray = this._formBuilder.array([]) as FormArray;

    this.newStoryForm = this._formBuilder.group({
      title:["",Validators.required],
      description:["",Validators.required],
      theme:["",Validators.required],
      epic:["",Validators.required],
      release:["",Validators.required],
      priority:["",Validators.required],
      status:["",Validators.required],
      tags:this._formBuilder.array([])
    });


    if(this.userStory._id && this.userStory._id!=""){
      this.newStoryForm.get("title").setValue(this.userStory.title);
      this.newStoryForm.get("description").setValue(this.userStory.description);
      this.newStoryForm.get("theme").setValue(this.userStory.theme);
      this.newStoryForm.get("epic").setValue(this.userStory.epic);
      this.newStoryForm.get("release").setValue((this.userStory.release && this.userStory.release._id)?this.userStory.release._id:this.userStory.release);
      this.newStoryForm.get("priority").setValue(this.userStory.priority);
      this.newStoryForm.get("status").setValue(this.userStory.status);



      // Clear any existing controls (optional, if you are updating the entire array)
      this.tagArray.clear();

      // Add a control for each tag
     this.userStory.tags.forEach(tag => {
      this.tagArray.push(this._formBuilder.control(tag));
      });


      if(this.userStory.internalAttachments && this.userStory.internalAttachments.length){
        this.fileAttachments=this.userStory.internalAttachments.map((attachment)=>{
          return {fileName:attachment};
        });
      }

    }

    // this.tagArray = this.newStoryForm.get('tags') as FormArray;

    this.initReleaseTypes();

    if(this.data.release){
      this.newStoryForm.get("release").setValue((this.userStory.release && this.userStory.release._id)?this.userStory.release._id:this.userStory.release);
    }

    

    }

    onClickPushToJira(){
      this.dialog.close({ push: true });


    }
    onClickSelectFile(){
      this.fileInput.nativeElement.value="";
      this.fileInput.nativeElement.click();
    }
    onSaveStory(){
      
      let saveStoryRequest;
      if(this.userStory._id && this.userStory._id!=""){
        //Edit Mode
        saveStoryRequest={
          _id:this.userStory._id,
          proposalID:this.proposal._id,
          title:this.newStoryForm.get("title").value,
          theme:this.newStoryForm.get("theme").value,
          epic:this.newStoryForm.get("epic").value,
          release:this.newStoryForm.get("release").value,
          priority:this.newStoryForm.get("priority").value,
          status:this.newStoryForm.get("status").value,
          tags:this.tagArray.value || [],
          description:this.newStoryForm.get("description").value,
          attachments:this.fileAttachments.map((attachment)=>attachment.fileName),
          dependencies:[]
        };

        

      }else{
        //Create Mode
         saveStoryRequest={
          proposalID:this.proposal._id,
          title:this.newStoryForm.get("title").value,
          theme:this.newStoryForm.get("theme").value,
          epic:this.newStoryForm.get("epic").value,
          release:this.newStoryForm.get("release").value,
          priority:this.newStoryForm.get("priority").value,
          status:this.newStoryForm.get("status").value,
          tags:this.tagArray.value || [],
          description:this.newStoryForm.get("description").value,
          attachments:this.fileAttachments.map((attachment)=>attachment.fileName),
          dependencies:[]
        };
      }
      
      this.isSavingStory=true;
      this.atlassianService.createUpdateStory(saveStoryRequest).subscribe((response)=>{
        this.isSavingStory=false;

        if(response.success){
          this.dialog.close({updated:true});
        }
      })

    }

    getCreatedByInfo(){
      if(this.userStory._id && this.userStory._id!=""){
        //story in edit mode
        const date = new Date(this.userStory.created);
        const formattedDate = date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short', // Use 'short' to get abbreviated month name (e.g., "Oct")
          year: 'numeric'
        });
        return `Created by ${this.userStory.author.personal.name.first} ${this.userStory.author.personal.name.last} on ${formattedDate}`
      }else{
        //new story
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short', // Use 'short' to get abbreviated month name (e.g., "Oct")
          year: 'numeric'
        });
        return `Created by ${this.user.personal.name.first} ${this.user.personal.name.last} on ${formattedDate}`
      }
    }

    getAuthorProfileImage(){
      if(this.userStory._id && this.userStory._id!=""){
        //story in edit mode
        return this.userStory.author.meta.profileImage || "";
      }else{
        //new story
        return this.user.author.meta.profileImage || "";
      }
    }

    getImageURL(url){
      return environment.file_url_cf+ url + '?token='+this._authService.accessToken
    }

  async onFilesSelected(event){
    const maxSizeInBytes = 1048576*20; //20MB
     const input = event.target as HTMLInputElement;
     if (input.files) {
       const files: FileList = input.files;
       // Handle the selected files here
       for(let a=0;a<files.length;a++){
         if(files[a].size>maxSizeInBytes){
           
           this.alert={
             type:"error",
             message:"The maximum allowed file size is 20MB"
           }
           this.sizeLimitAlert=true;
           setTimeout(()=>{
             this.sizeLimitAlert=false;
           },3000);
           return;
       }
     }

      //console.log(files);
       for (let i = 0; i < files.length; i++) {
         if(files[i].size>maxSizeInBytes){
           this.alert={
             type:"error",
             message:"The maximum allowed file size is 20MB"
           }
           this.sizeLimitAlert=true;
           setTimeout(()=>{
             this.sizeLimitAlert=false;
           },3000);
         }else{
          let fileData=this.detectFileType(files[i]);
         this.fileAttachments.push({title:files[i].name,type:fileData.value,uploadURL:"",fileName:"",file:files[i],processed:false,fileSize:files[i].size})

         }
       }
       await this.uploadAttachmentFiles();
     }
     
  }

  detectFileType(file: File): { value: number, label: string } | null {
    const fileTypeMap: { [key: string]: string } = {
      'application/pdf': 'PDF',
      'text/csv': 'CSV',
      'text/plain':"TXT",
      'application/msword': 'DOC',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
      'application/vnd.ms-powerpoint': 'PPT',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
      'image/jpeg': 'JPEG',
      'image/png': 'PNG',
      'image/jpg': 'JPG'
    };
    const fileType = file.type;
    if (fileTypeMap[fileType]) {
      let thisFileType=fileTypeMap[fileType];
      let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.label===thisFileType);
      return { value: fileValue.value, label: thisFileType };
    }
    return null;
  }

  async uploadAttachmentFiles(){
    this.isUploadingFiles=true;
    for(let i=0;i<this.fileAttachments.length;i++){
      if(this.fileAttachments[i].uploadURL===""){
        try{
          let uploadResp=await firstValueFrom(this._proposalService.getSupportingDocumentUploadLink({filename: this.fileAttachments[i].title,filetype: this.fileAttachments[i].file.type}));
          this.fileAttachments[i].uploadURL=uploadResp.uploadURL;
          this.fileAttachments[i].fileName=uploadResp.filename;
        }catch(error){
          console.log("Error:",error);
        }

        await firstValueFrom(this._proposalService.uploadSupportingFile(this.fileAttachments[i].uploadURL,this.fileAttachments[i].file));

        

       
      }
    }

    this.isUploadingFiles=false;
  }

  getFileTypeString(file){
     
    if(file.fileName && file.fileName!=""){
    
      let splits=file.fileName.split(".");
    let fileExtension=splits[splits.length-1];

    return fileExtension.toUpperCase();
    }
    return "";
  }
  
  onRemoveFile(attachment){
    let attachmentIndex=this.fileAttachments.findIndex((att)=>att.fileName==attachment.fileName);

    if(attachmentIndex>=0){
      this.fileAttachments.splice(attachmentIndex,1);
    }
  }

  getFileClass(attachment): string {
     if(attachment.fileName && attachment.fileName!=""){
    let extension=this.getFileTypeString(attachment);
    let fileType:any=this._commonService.fileTypes.find((file)=>file.label==extension);

    if(fileType){
      fileType=fileType.value;

    switch (fileType) {
      case 0: return 'bg-red-600';
      case 1: return 'bg-green-600';
      case 2: return 'bg-gray-600';
      case 3: return 'bg-blue-600';
      case 4: return 'bg-blue-500';
      case 5: return 'bg-yellow-600';
      case 6: return 'bg-yellow-500';
      case 7: return 'bg-orange-600';
      case 8: return 'bg-orange-500';
      case 9: return 'bg-amber-600';
      case 10: return 'bg-green-600';
      default: return 'bg-gray-600';
    }
  }
}
  }


  
  /**
     * Open tags panel
     */
  openTagsPanel(): void
  {
    this.filteredTags = this.alltagTypes;
      // Create the overlay
      this._tagsPanelOverlayRef = this._overlay.create({
          backdropClass   : '',
          hasBackdrop     : true,
          scrollStrategy  : this._overlay.scrollStrategies.block(),
          positionStrategy: this._overlay.position()
              .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
              .withFlexibleDimensions(true)
              .withViewportMargin(64)
              .withLockedPosition(true)
              .withPositions([
                  {
                      originX : 'start',
                      originY : 'bottom',
                      overlayX: 'start',
                      overlayY: 'top',
                  },
              ]),
      });

      // Subscribe to the attachments observable
      this._tagsPanelOverlayRef.attachments().subscribe(() =>
      {
          // Focus to the search input once the overlay has been attached
          this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
      });

      // Create a portal from the template
      const templatePortal = new TemplatePortal(this._tagsPanel, this._viewContainerRef);

      // Attach the portal to the overlay
      this._tagsPanelOverlayRef.attach(templatePortal);

      // Subscribe to the backdrop click
      this._tagsPanelOverlayRef.backdropClick().subscribe(() =>
      {
          // If overlay exists and attached...
          if ( this._tagsPanelOverlayRef && this._tagsPanelOverlayRef.hasAttached() )
          {
              // Detach it
              this._tagsPanelOverlayRef.detach();

              // Reset the tag filter
              this.filteredTags = this.alltagTypes;

              // Toggle the edit mode off
              this.tagsEditMode = false;
          }

          // If template portal exists and attached...
          if ( templatePortal && templatePortal.isAttached )
          {
              // Detach it
              templatePortal.detach();
          }
      });
  }

  /**
     * Filter tags
     *
     * @param event
     */
  filterTags(event): void
  {
      // Get the value
      const value = event.target.value.toLowerCase();

      // Filter the tags
      this.filteredTags = this.alltagTypes.filter(tag => tag.label.toLowerCase().includes(value));
  }

  /**
     * Should the create tag button be visible
     *
     * @param inputValue
     */
  shouldShowCreateTagButton(inputValue: string): boolean
  {
      return !!!(inputValue === '' || this.alltagTypes.findIndex(tag => tag.label.toLowerCase() === inputValue.toLowerCase()) > -1);
  }

  /**
     * Filter tags input key down event
     *
     * @param event
     */
  filterTagsInputKeyDown(event): void
  {
      // Return if the pressed key is not 'Enter'
      if ( event.key !== 'Enter' )
      {
          return;
      }

      // If there is no tag available...
      if ( this.filteredTags.length === 0 )
      {
          // Create the tag
          this.createTag(event.target.value);

          // Clear the input
          event.target.value = '';

          // Return
          return;
      }

      // If there is a tag...
      const tag = this.filteredTags[0];
      const isTagApplied = this.alltagTypes.find(id => id === tag._id);

      // If the found tag is already applied to the task...
      if ( isTagApplied )
      {
          // Remove the tag from the task
          // this.deleteTagFromTask(tag);
      }
      else
      {
          // Otherwise add the tag to the task
          this.addTagToTask(tag);
      }
  }

  toggleTaskTag(event): void
  {   
    // console.log(event);
    this.filteredTags = this.alltagTypes.filter(tag => !tag.label.toLowerCase().includes(event.value));
    this.alltagTypes = this.filteredTags;
    // console.log(this.alltagTypes);
    this.tagArray.clear();
    this.filteredTags.forEach((eachtag) => {
      this.tagArray.push(new FormControl(eachtag.label));
    });
    // Toggle the edit mode off
    this.tagsEditMode = false;
    // this.autoSave();
  }

  /**
   * Create a new tag
   *
   * @param title
   */
  createTag(title: string): void
  {
    const tag = {
        title,
    };
    this.addTagToTask(tag);
    
    // this._tagsPanelOverlayRef.detach();
    this._tagsPanelOverlayRef.dispose();
    this.filteredTags = this.alltagTypes;
    console.log('first');
    console.log(this.filteredTags);
    // Toggle the edit mode off
    this.tagsEditMode = false;
  }
  /**
   * Add tag to the task
   *
   * @param tag
   */
  addTagToTask(tag): void
  {
    // console.log(tag);
    this._tagsPanelOverlayRef.dispose();
    this.tagArray.push(new FormControl(tag.title));
    console.log('second');
    console.log(this.tagArray);

    this.alltagTypes.push({label:tag.title,value:tag.title});
    this.filteredTags = this.alltagTypes;

    // Toggle the edit mode off
    this.tagsEditMode = false;
  }

  viewStoryInJira(story){
    if(!story.jiraSynced){
      return;
    }
    let issueURL=this.atlassianService.constructJiraIssueLink(this.proposal.integrations.jira.site,story.jiraKey);
    window.open(issueURL || "", "_blank");
  }


  
  getPriority(priority:number){

    let priorityFound=this.priorityTypes.find((p)=>p.value==priority);

    if(priorityFound){
      return priorityFound;
    }

    return  this.priorityTypes[0];


  }


  updatePriority(event){
    console.log(event);
    
    this.newStoryForm.get('priority').setValue(event.value);
  }


  
  getStatusString(status){

    if(status==JIRA.TO_DO){
      return "To Do";
    }else if(status==JIRA.IN_PROGRESS){
      return "In Progress";
    }else if(status==JIRA.DONE){
      return "Done";
    }
  }
  ngOnDestroy() {
    this.editor.destroy();
  }

  // Toolbar methods for specific commands
  toggleBold() {
     this.editor.chain().focus().toggleBold().run();
  }

  toggleItalic() {
     this.editor.chain().focus().toggleItalic().run();
  }

  toggleHeading(level: number) {
     // this.editor.chain().focus().toggleHeading([ level ]).run();
  }

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run();
  }

  onChangeTheme(event){
    let selectedThemes=event.value;

    if(selectedThemes.length){
    this.epicTypes=[];
    for(let epic of this.proposal.epics){
      if(selectedThemes.includes(epic.theme)){
        let epics= epic.epics.map((themeEpic) => {
          return { label: themeEpic.name, value: themeEpic.name };
        });
        this.epicTypes.push(...epics);
      }
    }
  }
}
}
