import { Component, ElementRef, Inject, ViewChild,TemplateRef,ViewContainerRef, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, NgZone, ComponentRef, inject } from '@angular/core';

import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Overlay, OverlayRef,OverlayContainer } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JIRA } from 'app/core/common/constants';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { DatePipe, NgIf,NgFor, NgClass, DOCUMENT } from '@angular/common';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { DialogRef } from '@angular/cdk/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { CommonService } from 'app/core/common/common.service';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import { NgxTiptapModule } from 'ngx-tiptap';
import { SelectStoriesComponent } from '../select-stories/select-stories.component';
import { TechnologyDomainService } from 'app/core/technology-domain/technology-domain.service';

@Component({
  selector: 'app-add-configuration',
  standalone: true,
  imports: [MatIconModule,NgIf,MatProgressSpinnerModule,DatePipe,NgFor,NgClass,ReactiveFormsModule,MatDialogModule,MatIcon,MatMenuModule,MatInputModule,MatSelectModule,MatButtonModule,MatOptionModule,MatCheckboxModule,MatFormFieldModule,MatRadioModule,NgxTiptapModule],
  templateUrl: './add-configuration.component.html',
  styleUrl: './add-configuration.component.scss'
})
export class AddConfigurationComponent {

  newConfigForm:UntypedFormGroup;
  themeTypes=[];
  epicTypes=[];
  proposal: any = {};
  existingConfig:any={_id:""};
  build: any = {};
  metadataMap = new Map();
  isLoading: boolean = false;
  isSavingConfig: boolean = false;
  userStoryArray: FormArray<any>;
  statusTypes:any[]=[
    {value:"Not Started",label:"Not Started"},
    {value:"In Progress",label:"In Progress"},
    {value:"Done",label:"Done"},
    {value:"On-Hold",label:"On-Hold"},
    {value:"Cancelled",label:"Cancelled"}
  ];

  workTypes:any[]=[
    {value:"Configuration",label:"Configuration"},
    {value:"Customization",label:"Customization"}
  ];

  actionTypes:any[]=[
    {value:"New",label:"New"},
    {value:"Update",label:"Update"},
    {value:"Delete",label:"Delete"}
  ];
  cloudTypes:any [] = [];
  allMetadataType:any [] = [];
  technologyDomains:any [] = [];
  allMetadataCategory:any[] = [];
  allMetadataSubcategory:any[] = [];

  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
          placeholder: "Enter Description ",
      })
    ],
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if (content === '' || content === '<p></p>') {
        setTimeout(() => {
          this.newConfigForm.get("description").setValue("");
        }, 0);
      }
    }
  });

  editor1 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
          placeholder: "Enter Assumptions ",
      })
    ],
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if (content === '' || content === '<p></p>') {
        setTimeout(() => {
          this.newConfigForm.get("assumption").setValue("");
        }, 0);
      }
    }
  });

  editor2 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
          placeholder: "Enter Developer Notes ",
      })
    ],
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if (content === '' || content === '<p></p>') {
        setTimeout(() => {
          this.newConfigForm.get("developerNote").setValue("");
        }, 0);
      }
    }
  });

  readonly data:any = inject<AddConfigurationComponent>(MAT_DIALOG_DATA);

  constructor(private dialog:MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _commonService:CommonService,
    private proposalService:ProposalService,
    private _changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddConfigurationComponent>
  ){
    
  }

  ngOnInit(): void {
    this.newConfigForm = this._formBuilder.group({
      proposalID: ['', Validators.required],
      buildID: ['', Validators.required],
      label:[''],
      cloud:['',Validators.required],
      status:['',Validators.required],
      metadataType:[''],
      metadataName:['',Validators.required],
      object:[''],
      themeID:['',Validators.required],
      epicID:['',Validators.required],
      solutionCategory:['',Validators.required],
      solutionSubCategory:['',Validators.required],
      workType:['',Validators.required],
      actionType:['',Validators.required],
      description:['',Validators.required],
      assumption:['',Validators.required],
      developerNote:[''],
      stories:this._formBuilder.array([])
    });
    this.userStoryArray = this._formBuilder.array([]) as FormArray;
    if(this.data){
      //console.log(this.data);
      this.proposal=this.data.proposal;
      this.build=this.data.build;
      this.epicTypes=this.data.epicTypes;
      this.existingConfig=this.data.config;
      this.technologyDomains=this.data.technologyDomains;
      this.allMetadataCategory = this.data.allMetadataCategory;
      this.allMetadataType = this.data.allMetadataType;
      this.metadataMap = this.data.metadataMap;
      this.newConfigForm.get('buildID').setValue(this.build._id);
      this.newConfigForm.get('proposalID').setValue(this.proposal._id);
      this.epicTypes = this.data.proposal.epics?.flatMap((epic) => {
        return epic.epics.map((themeEpic) => {
          return { label: themeEpic.name, value: themeEpic._id };
        });
      }) ?? []; 

      this.themeTypes = this.data.proposal.epics?.flatMap((epic) => {
          return { label: epic.theme, value: epic._id };
      }) ?? []; 
    }
    // console.log(this.existingConfig);
    if(this.data.theme)this.newConfigForm.get('themeID').setValue(this.data.theme);
    if(this.data.epic)this.newConfigForm.get('epicID').setValue(this.data.epic);
    if(this.existingConfig && ((this.existingConfig._id && this.existingConfig._id!="") || this.existingConfig.testCase)){
      this.newConfigForm.get("label").setValue((this.existingConfig.label?this.existingConfig.label:''));
      this.newConfigForm.get("description").setValue(this.existingConfig.description?this.existingConfig.description:'');
      this.newConfigForm.get("themeID").setValue(this.existingConfig.themeID?this.existingConfig.themeID:(this.existingConfig.testCase?this.existingConfig.testCase.themeID:''));
      this.newConfigForm.get("epicID").setValue(this.existingConfig.epicID?this.existingConfig.epicID:(this.existingConfig.testCase?this.existingConfig.testCase.epicID:''));
      this.newConfigForm.get("solutionCategory").setValue(this.existingConfig.solutionCategory?this.existingConfig.solutionCategory:'');
      this.onChangeCategory({value:this.existingConfig.solutionCategory});
      this.newConfigForm.get("solutionSubCategory").setValue(this.existingConfig.solutionSubCategory?this.existingConfig.solutionSubCategory:'');
      this.newConfigForm.get("workType").setValue(this.existingConfig.workType?this.existingConfig.workType:'');
      this.newConfigForm.get("status").setValue(this.existingConfig.status?this.existingConfig.status:'');
      this.newConfigForm.get("actionType").setValue(this.existingConfig.actionType?this.existingConfig.actionType:'');
      this.newConfigForm.get("assumption").setValue(this.existingConfig.assumption?this.existingConfig.assumption:'');
      this.newConfigForm.get("developerNote").setValue(this.existingConfig.developerNote?this.existingConfig.developerNote:'');
      this.newConfigForm.get("metadataType").setValue(this.existingConfig.metadataType?this.existingConfig.metadataType:'');
      this.newConfigForm.get("metadataName").setValue(this.existingConfig.metadataName?this.existingConfig.metadataName:'');
      this.newConfigForm.get("object").setValue(this.existingConfig.object?this.existingConfig.object:'');
      this.newConfigForm.get("cloud").setValue(this.existingConfig.cloud);
      this.userStoryArray.clear();
      if(this.existingConfig.stories){
        this.existingConfig.stories.forEach(eachStory => {
          this.userStoryArray.push(this._formBuilder.group(eachStory));
        });
      }

    }
  }

  onChangeTheme(event){
    let selectedThemes=event.value;
    if(selectedThemes.length){
      this.epicTypes=[];
      for(let epic of this.proposal.epics){
        if(selectedThemes.includes(epic._id)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic._id };
          });
          this.epicTypes.push(...epics);
        }
      }
    }
  }

  onChangeEpic(event){

  }

  onChangeMetadata(event) {
    const selectedMetadata = this.metadataMap.get(event.value);
    if (selectedMetadata) {
      // this.allMetadataCategory = [{label:selectedMetadata.category,value:selectedMetadata.category}];
      // this.allMetadataSubcategory = [{label:selectedMetadata.subcategory,value:selectedMetadata.subcategory}];
      this.newConfigForm.get("solutionCategory").setValue(selectedMetadata.category);
      this.onChangeCategory({value:selectedMetadata.category});
      this.newConfigForm.get("solutionSubCategory").setValue(selectedMetadata.subcategory);
      this.newConfigForm.get("workType").setValue(selectedMetadata.workType);
      this.newConfigForm.get("metadataType").setValue(event.value);
    }
  }

  onSave(){
    let saveBuild;
    if(this.existingConfig && this.existingConfig._id && this.existingConfig._id!=""){
      //Edit Mode
      saveBuild={
        _id:this.existingConfig._id,
        proposalID:this.newConfigForm.get("proposalID").value,
        buildID:this.newConfigForm.get("buildID").value,
        label:this.newConfigForm.get("label").value,
        cloud:this.newConfigForm.get("cloud").value,
        metadataType:this.newConfigForm.get("metadataType").value,
        metadataName:this.newConfigForm.get("metadataName").value,
        object:this.newConfigForm.get("object").value,
        themeID:this.newConfigForm.get("themeID").value,
        epicID:this.newConfigForm.get("epicID").value,
        solutionCategory:this.newConfigForm.get("solutionCategory").value,
        solutionSubCategory:this.newConfigForm.get("solutionSubCategory").value,
        workType:this.newConfigForm.get("workType").value,
        status:this.newConfigForm.get("status").value,
        actionType:this.newConfigForm.get("actionType").value,
        description:this.newConfigForm.get("description").value,
        assumption:this.newConfigForm.get("assumption").value,
        developerNote:this.newConfigForm.get("developerNote").value,
        stories:this.userStoryArray.value || []
      };
    }else{
      //Create Mode
      saveBuild={
        proposalID:this.newConfigForm.get("proposalID").value,
        buildID:this.newConfigForm.get("buildID").value,
        label:this.newConfigForm.get("label").value,
        cloud:this.newConfigForm.get("cloud").value,
        metadataType:this.newConfigForm.get("metadataType").value,
        metadataName:this.newConfigForm.get("metadataName").value,
        object:this.newConfigForm.get("object").value,
        themeID:this.newConfigForm.get("themeID").value,
        epicID:this.newConfigForm.get("epicID").value,
        solutionCategory:this.newConfigForm.get("solutionCategory").value,
        solutionSubCategory:this.newConfigForm.get("solutionSubCategory").value,
        workType:this.newConfigForm.get("workType").value,
        status:this.newConfigForm.get("status").value,
        actionType:this.newConfigForm.get("actionType").value,
        description:this.newConfigForm.get("description").value,
        assumption:this.newConfigForm.get("assumption").value,
        developerNote:this.newConfigForm.get("developerNote").value,
        stories:this.userStoryArray.value || []
      };
    }
    this.isSavingConfig=true;
    this.proposalService.createConfiguration(saveBuild).subscribe((response)=>{
      this.isSavingConfig=false;
      if(response.ok || response.success || response.updated){
        let formValues = this.newConfigForm.value;
        formValues['stories'] = this.userStoryArray.value || [];
        formValues['_id'] = response._id;
        this.dialogRef.close({ok:true,story:formValues});
      }
    });
  }

  selectStory(){
    const dialogRefstory = this.dialog.open(SelectStoriesComponent,{ 
      panelClass: 'add-story-dialog',
      disableClose: true,
      data: {
        proposal:this.proposal,
        _buildDetail:this.build,
        themeTypes:this.themeTypes,
        userStoryArray: this.userStoryArray.value
      }
    });
    dialogRefstory.afterClosed().subscribe(result => {
      // console.log(result);
      if (this.userStoryArray && Array.isArray(result) && result.length > 0) {
        this.userStoryArray.clear();
        result.forEach((eachStory) => {
          this.userStoryArray.push(this._formBuilder.group(this._createFormControls(eachStory)));
        });
        this._changeDetectorRef.detectChanges();
      }
    });
  }

  private _createFormControls(data: any): { [key: string]: any } {
    const group: { [key: string]: any } = {};
    Object.keys(data).forEach((key) => {
      group[key] = [data[key]]; // Wrap each property in an array for FormControl
    });
    return group;
  }

  onChangeCategory(event){
    this.allMetadataSubcategory=[];
    let selectedCategories=event.value;
    if(selectedCategories){
      let selectVal = this.allMetadataCategory.find(el=>el.label==selectedCategories);
      if(selectVal)this.allMetadataSubcategory = selectVal.subcategories;
    }
  }

  ngOnDestroy() {
    this.editor.destroy();
    this.editor1.destroy();
    this.editor2.destroy();
    this.dialogRef.close({ok:true,story:this.newConfigForm.value});
  }

  toggleBold(editorname) {

    if(editorname === 'desc'){      
      this.editor.chain().focus().toggleBold().run();
    }
    if(editorname === 'assumption'){      
      this.editor1.chain().focus().toggleBold().run();
    }
    if(editorname === 'notes'){      
      this.editor2.chain().focus().toggleBold().run();
    }

  }


  toggleItalic(editorname) {
    if(editorname === 'desc'){      
       this.editor.chain().focus().toggleItalic().run();
    }
    if(editorname === 'assumption'){      
      this.editor1.chain().focus().toggleItalic().run();
    }
    if(editorname === 'notes'){      
      this.editor2.chain().focus().toggleItalic().run();
    }
  }


  toggleHeading(level: number) {
     // this.editor.chain().focus().toggleHeading([ level ]).run();
  }

  toggleBulletList(editorname) {
    if(editorname === 'desc'){      
      this.editor.chain().focus().toggleBulletList().run();
    }
    if(editorname === 'assumption'){      
      this.editor1.chain().focus().toggleBulletList().run();
    }
    if(editorname === 'notes'){      
      this.editor2.chain().focus().toggleBulletList().run();
    }
    
  }


}
