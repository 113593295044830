
<div class="w-full flex justify-end z-20 mt-1">
    <button  (click)="onClose()">
        <mat-icon
        class="text-black"
        [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
    </button>
    </div>
    <div *ngIf="false" class="w-full flex  justify-center -mt-10 z-10">
    <img src="assets/icons/regenerate_icon.svg" class="w-14 h-14 " >
    </div>
    <div class="min-w-full min-h-full">
            <mat-dialog-content class="mt-2">
                 
                <div class="text-black text-2xl font-semibold text-center">{{data.title}}</div>
                <div class="text-[#64748B] flex justify-center w-full text-center text-md font-normal mt-1">{{data.subtitle}}</div>
        
                <div class="flex gap-5 w-full justify-center mt-8">
                    <button (click)="onClickYes()" class="bg-primary text-white rounded-full py-2 w-32 text-lg font-normal">{{data.yesAction}}</button>
                    <button (click)="onClose()" class="boldbtn text-black rounded-full py-2 w-32 text-lg font-normal">{{data.noAction}}</button>
                </div>
            
            </mat-dialog-content>
              
    
        
    
    </div>