// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  app_name: 'Generative.ai',
  app_name_upper: 'GENERATIVE.AI',
  app_name_lower: 'generative.ai',
  app_name_first_upper: 'Generative.ai',
  copyright_year: 2024,
  server: 'stage',
  parent_domain: 'www.getgenerative.ai',
  domain: 'stage.getgenerative.ai',
  domain_full_url: 'https://stage.getgenerative.ai',
  privacy_policy_path: '/information/#privacy-policy',
  cookie_policy_path: '/information/#cookie-policy',
  api_endpoint: 'https://api.getgenerative.ai/stage/',
  file_url: 'https://file.getgenerative.ai/stage/',
  file_url_cf: 'https://dyuga5mqadb1.cloudfront.net/',
  auth_url: 'https://auth.getgenerative.ai/stage/',
  websocketURL:'wss://wghk8yfrca.execute-api.ap-southeast-2.amazonaws.com/stage/',
  jira_auth:'https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=8LceUH4dGLKOOWMm5Dtj1YVlTEG7hJtA&scope=read%3Aservicedesk-request%20manage%3Aservicedesk-customer%20write%3Aservicedesk-request%20read%3Aservicemanagement-insight-objects%20read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20read%3Ajira-user%20write%3Ajira-work%20manage%3Ajira-webhook%20manage%3Ajira-data-provider%20offline_access&redirect_uri=https%3A%2F%2Fstage.getgenerative.ai%2Fatlassian%2Fcallback&response_type=code&prompt=consent'
};
