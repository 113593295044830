import { ChangeDetectorRef, Component, Inject, inject, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';

@Component({
  selector: 'app-metadata-confirm-box',
  standalone: true,
  imports: [ MatFormFieldModule,NgFor,NgIf,NgClass,MatInputModule, FormsModule, MatButtonModule, MatDialogTitle, MatDialogContent,MatDialogActions,MatDialogClose,MatIcon,MatProgressSpinnerModule],
  templateUrl: './metadata-confirm-box.component.html',
  styleUrl: './metadata-confirm-box.component.scss'
})
export class MetadataConfirmBoxComponent {
  checked:string = '';
  user_orgs: any = [];
  proposal_id: string;
  type: string;
  load_process: boolean = false;
  describeMetadata: any[] = [];
  findMetadata: any = {};

  constructor(
    private cdr: ChangeDetectorRef, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _websocketService: WebsocketService,
    public dialogRef: MatDialogRef<MetadataConfirmBoxComponent>,
    private proposalService:ProposalService
  ){
    if(data){
      this.proposal_id = data.proposal_id;
      this.user_orgs = data.user_orgs;
      this.type = data.type;
    }
  }

  onClickYes(): void {
    this.load_process = true;
    if(this.checked == 'disconnect_remove_all'){
      this.proposalService.delete_login({ id: this.user_orgs[0]._id, proposal: this.proposal_id }).subscribe((response)=>{
        // console.log(response);
        this.load_process = false;
        if(response && response.success)this.saveClose('disconnect_remove_all');
      });
    }
    else if(this.checked == 'disconnect_remove_none'){
      this.proposalService.disconnect_login({ id: this.user_orgs[0]._id, type: 'disconnect_remove_none', proposal: this.proposal_id }).subscribe((response)=>{
        this.load_process = false;
        if(response && response.success)this.saveClose('disconnect_remove_none');
      });
    }
    else if(this.checked == 'disconnect_keep_insights'){
      this.proposalService.disconnect_login({ id: this.user_orgs[0]._id, type: 'disconnect_keep_insights', proposal: this.proposal_id }).subscribe((response)=>{
        this.load_process = false;
        if(response && response.success)this.saveClose('disconnect_keep_insights');
      });
    }
    else if(this.checked == 'refresh_keep_insights'){
      this.saveClose('refresh_keep_insights');
    }
    else if(this.checked == 'refresh_remove_insights'){
      this.saveClose('refresh_remove_insights');
    }
  }

  saveClose(type){
    this.load_process = false;
    this.checked = '';
    this.dialogRef.close({yes:true, type: type});
  }

  onClose(){
    this.dialogRef.close({yes:false});
  }

  checkoption(option_type){
    this.checked = option_type;
  }
}
