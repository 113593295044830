<section class="flex flex-col justify-center items-center min-w-full min-h-120">

    <div class="h-full flex flex-col items-start justify-center w-1/2">
    <mat-progress-bar
    [mode]="isSavingToken?'indeterminate':'determinate'"
    value="100"
    ></mat-progress-bar>

   <div class="w-full flex justify-center mt-5"><img  *ngIf="!isSavingToken" class="w-12 h-12" src="assets/icons/jira_logo.svg"></div> 
   
   <div *ngIf="isSavingToken" class="flex w-full justify-center font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-5 text-black">
        GetGenerative.ai is authenticating with Atlassian
    </div>
    <div *ngIf="!isSavingToken && success" class="flex w-full justify-center font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-1">
        Authentication with Atlassian successful
    </div>
    <div *ngIf="!isSavingToken && !success" class="flex w-full justify-center font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-1">
        Authentication with Atlassian failed
    </div>
</div>
</section>