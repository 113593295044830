// event.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {

    getQualityScore(proposal:any,insights:any[]){

        let summaryQuantityScore=this.getSummaryQuantityScore(proposal.summary);
        let documentsRelevanceScore=this.getDocumentsScore(proposal.supporting_docs || []);
        let insightsTotalScore=0;

        let perInsightScore:number=(80/insights.length);


        for(let insight of insights){
            let insightScore=0;
            let perQuestionScore=perInsightScore/insight.questions.length;
            let questionAnswered=0;
            for(let question of insight.questions){
                if(question.valid){
                    questionAnswered++;
                }
            }

            insightScore=perQuestionScore*questionAnswered;

            insightsTotalScore+=insightScore;

        }




        return Math.ceil((summaryQuantityScore+documentsRelevanceScore+insightsTotalScore));

    }

    getInsightPerQuestionScore(allInsights:any[],insight:any){
        let perInsightScore=80/allInsights.length;
        let perQuestionScore=perInsightScore/insight.questions.length;

        let score=Math.floor(perQuestionScore);

        if(score<1){
            score=1;
        }

        return score;
    }

    getDocumentContributionScore(supportingDoc:any,allInsights:any[],proposal:any){
        let scoreContribution=0;

        /*
        //Documents Relevance Score
        let totalValidDocuments = proposal.supporting_docs.filter((doc) => doc.valid).length;
        let perDocumentScore=0;
        let documentsScore=this.getDocumentsScore(proposal.supporting_docs);

        if(documentsScore>0){
            perDocumentScore=documentsScore/totalValidDocuments;
        }

        if(supportingDoc.valid){
            scoreContribution+=perDocumentScore;
        }
        */

        let insightsTotalScore=0;

        for(let insight of allInsights){
            for(let question of insight.questions){
                if(question.valid && question.reference && question.reference==supportingDoc._id){
                    //This question is answered using this document
                    let perQuestionScore=this.getInsightPerQuestionScore(allInsights,insight);
                    insightsTotalScore+=perQuestionScore;
                }
            }
        }

        scoreContribution+=insightsTotalScore;

        return Math.ceil(scoreContribution);

    }



    private getSummaryQuantityScore(summary:string){

        let summaryWordsCount=this.countWords(summary);

        let score: number;
        if (summaryWordsCount < 100) {
            score = 2;
        } else if (summaryWordsCount <= 200) {
            score = 4;
        } else if (summaryWordsCount <= 300) {
            score = 6;
        } else if (summaryWordsCount <= 400) {
            score = 8;
        } else {
            score = 10;
        }

        return score;
    }
    private countWords(str: string): number {
        const words = str.trim().split(/\s+/);
        // If the string is empty, return 0
        if (str.length === 0) {
            return 0;
        }
        return words.length;
    }
    private getDocumentsScore(supportingDocs:any[]):number{
        let hasValidDocs=false;
        if(supportingDocs.length>0){
            for(let sDoc of supportingDocs){
                if(sDoc.valid && sDoc.valid==true){
                    hasValidDocs=true;
                    break;
                }
            }

            if(hasValidDocs){
                return 10;
            }
        }

        return 0;
    }
}
