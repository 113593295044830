import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs';

export const initialDataResolver = (router: Router) => {
    const messagesService = inject(MessagesService);
    const navigationService = inject(NavigationService);
    const notificationsService = inject(NotificationsService);
    const quickChatService = inject(QuickChatService);
    const shortcutsService = inject(ShortcutsService);

    // const currentRoute = router.url; // Get the current route
    // // console.log(currentRoute);
    // let navigationRequest: Observable<any>;

    // // Check the current route and decide parameter to call navigationService.get()
    // if (currentRoute.length && currentRoute[0]['path'] === 'admin') {
    //     navigationRequest = navigationService.get('admin');
    // } else {
    //     navigationRequest = navigationService.get('user');
    // }

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        navigationService.get(),
        messagesService.getAll(),
        notificationsService.getAll(),
        quickChatService.getChats(),
        shortcutsService.getAll(),
    ]);
};
