<section>
    <div class="bg-card m-bg-card storyTableBox rounded-2xl shadow ml-16 mr-10 my-10">
      <section>  
        <div class="grid table-grid-test  min-w-full px-5 gap-3 py-5">
          <div class="flex justify-start  text-md uppercase font-medium ml-8">Story ID</div>
          <div class="flex justify-start  text-md uppercase font-medium  ">Test ID</div>
          <div class="flex justify-start  text-md uppercase font-medium ">Type</div>
          <div class="flex justify-start  text-md uppercase font-medium ">Title</div>
          <div class="flex justify-start  text-md uppercase font-medium ">Status</div>
          <div class="flex pl-4 justify-center text-md uppercase font-medium ">Jira Connection</div>
        </div>
      </section>
      <section>
        <div class="w-full" cdkDropList (cdkDropListDropped)="dropped($event)" [cdkDropListData]="tableViewList">
          @for(eachtestcase of tableViewList;track eachtestcase){
          <div  class="relative group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0 mt-3 customB border-b" cdkDrag >
            <!-- Content for each section -->
            <div class="grid table-grid-test gap-3 items-center py-3 px-5 tabBorder">
              <!-- <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" class="absolute left-0 pl-3 h-full flex items-center justify-center opacity-0 group-hover:opacity-100">
                <mat-icon class="cursor-move" [svgIcon]="'heroicons_solid:bars-3'" cdkDragHandle></mat-icon>
              </div> -->
              <div class="flex flex-wrap justify-start  text-md text-black font-regular  ml-8">
                <a (click)="(eachtestcase.story?openStory(eachtestcase.story):'')" class="linkTag">
                  {{eachtestcase.story?getStoryID(eachtestcase.story):'-'}}
                </a>
              </div>
              <div class="flex flex-wrap justify-start  text-md text-black font-regular ">
                {{eachtestcase.caseID}} 
              </div>
              <div class="flex flex-wrap justify-start  text-md text-black font-regular">
                {{eachtestcase.type}}
              </div>
              <div class=" text-md text-black font-regular line-clamp-2">
                {{eachtestcase.title}}
              </div>
             
              <div class="flex flex-wrap justify-start  text-md text-black font-regular">
                <span   [ngClass]="{ 'todoTag': (getStatusString(eachtestcase.status) == 'To Do') , 'inprogTag': (getStatusString(eachtestcase.status) == 'In Progress')  ,'doneTag': (getStatusString(eachtestcase.status) == 'Done') }"  *ngIf="eachtestcase.status">{{eachtestcase.status}}</span>
              </div>
              
              <div class="flex flex-wrap  gap-2  text-md text-black font-regular"  [ngClass]="is_write_allowed ? 'justify-end' : 'justify-center'">

                <div  class="flex flex-wrap justify-start gap-2 pl-4 items-center text-md text-black font-regular cursor-pointer">
                  


                  @if(getIntegrationStatus()){
                    <span
                    class="jiraTag cursor-pointer"
                    (click)="syncSingleTestCase(eachtestcase)"
                    *ngIf="!eachtestcase.jiraSynced && !eachtestcase.isSyncing"
                  >
                    <span class="flex gap-1 items-center jiraBlue" >
                      <img  src="assets/icons/jirablue.png" alt=""> Sync
                    </span>
                  </span>

                  <span class="flex gap-1 items-center text-gray-400"  *ngIf="!eachtestcase.jiraSynced && eachtestcase.isSyncing">
                    <img
                    [ngClass]="'rotate-animation'"
                    src="assets/icons/sync_icon.svg"
                    class="w-6 h-6"
                    />
                    <img  src="assets/icons/jiragrey.png" class="w-5 h-5" alt=""> Syncing
                  </span>
                

                <span
                *ngIf="eachtestcase.jiraSynced"
                  class="jiraTag cursor-pointer"
                  (click)="viewStoryInJira(eachtestcase)"
                >
                  <span class="flex gap-1 items-center jiraBlue"  >
                    <img  src="assets/icons/jirablue.png" alt=""> View <mat-icon  class="icon-size-5" svgIcon="heroicons_outline:arrow-top-right-on-square"></mat-icon>
                  </span>
                </span>




                }@else {
                  <span class="flex gap-1 items-center">
                    <img  src="assets/icons/jiragrey.png" class="w-5 h-5" alt=""> Not Synced
                  </span>
                }




                  


                <div *ngIf="is_write_allowed" class="releaseTagoption">
                    <mat-select [value]="((eachtestcase.release && eachtestcase.release._id)?eachtestcase.release._id:'')" class="testSelectOption" (selectionChange)="onReleaseSelection($event,eachtestcase)" >
                      @for (release of releases_list; track release) {
                        <mat-option [value]="release._id">{{release.short_name}}</mat-option>
                      }
                    </mat-select>
                </div>
                <div *ngIf="(!is_write_allowed && eachtestcase.release)" class="releaseTagoption testSelectOption text-center">
                    {{getReleaseValue(eachtestcase.release)}}
                </div> 

                  <div *ngIf="is_write_allowed" class="flex justify-end group gap-2">
                    <div  class="flex gap-2 group-hover:opacity-100 items-center mr-2 opacity-0">
                      <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="onEditTestCase(eachtestcase)" ></mat-icon>
                      <img *ngIf="eachtestcase.delete"   [ngClass]="'rotate-animation'" src="assets/icons/sync_icon.svg" class="w-6 h-6">
                      <mat-icon class="smallIc cursor-pointer"  *ngIf="!eachtestcase.delete" svgIcon="heroicons_outline:trash" (click)="onDeleteTestCase(eachtestcase)"></mat-icon>
                    </div>
                    
                  </div>
                </div>

                
              </div>
              
            </div>
          </div> 
          }                                  
        </div>
      </section>
      <!-- Add Case Button -->
      <div *ngIf="(is_write_allowed)"  class="min-w-full flex pl-10 py-5 items-center justify-start relative border-t cursor-pointer border-b" (click)="openDialog()">
        <mat-icon class="text-[#9AA6B8]" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
        <div class="pl-2 text-md text-[#9AA6B8]">Add Test Case</div>
        
      </div>


      <mat-paginator [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" (page)="onPageChange($event)" class="customPaginator"></mat-paginator>

    </div>
</section>