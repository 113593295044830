<div class="flex flex-col flex-auto min-w-0">

    <!-- <div class="genlogo">
        <img src="assets/icons/whitegen.png" alt="">
    </div> -->

    <span class="close-dialog" [mat-dialog-close]="true" (click)="closeDialog()">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>

    <!-- Main -->
    <div class="flex-auto p-6 m-p-0">

        <div class="max-w-5xl formBox">

            <form
                class="rounded overflow-hidden proposalForm"
                [formGroup]="horizontalStepperForm">
                <mat-horizontal-stepper 
                    [linear]="true"
                    #horizontalStepper >

                    <mat-step
                        [formGroupName]="'step1'"
                        [stepControl]="horizontalStepperForm.get('step1')"
                        #horizontalStepperStep1 >
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 font-22 text-center tracking-tight mt-6">
                            We are delighted to have you as a part of GetGenerative.ai! <br> Your feedback matters to us.
                        </h2>
                        <p class="text-center">Please rate your experience with us</p>
                        <div class="nolist mt-4">
                            <ul class="flex flex-wrap gap-5 ">
                                <li *ngFor="let rating of ratings"
                                    [class.selectedLi]="rating === selectedRating"
                                    (click)="selectRating(rating)">
                                    {{ rating }}
                                </li>
                            </ul>
                        </div>
                        <div class="flex justify-between mb-4 mt-2 smallT">
                            <span>Least Satisfied</span>
                            <span>Most Satisfied</span>
                        </div>
                        <div class="flex justify-center">
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep1.stepControl.invalid"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step
                        [formGroupName]="'step2'"
                        [stepControl]="horizontalStepperForm.get('step2')"
                        #horizontalStepperStep2>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <div class="flex flex-col gt-xs:flex-row">
                            <h2 *ngIf="(horizontalStepperForm.get('step1.score').value<9)" class="font-medium leading-7 mb-4 sm:leading-10 font-22 text-center tracking-tight mt-6">
                                Oops, we just missed to delight you!
                            </h2>
                            <h2 *ngIf="(horizontalStepperForm.get('step1.score').value>8)" class="font-medium leading-7 mb-4 sm:leading-10 font-22 text-center tracking-tight mt-6">
                                Is there anything else you would like to share with us to help you serve better?
                            </h2>
                            <p class="text-center">What are the things we could work on to delight you?</p>
                        </div>
                        <div class="pointlist mt-4">
                            <ul class="flex flex-wrap gap-5 justify-center">
                                 <!-- class for selected li "selectePoint" -->
                                 <li *ngFor="let category of values"
                                 [class.selectePoint]="selectedValues.indexOf(category)>-1"
                                 (click)="selectCategory(category)">
                                 {{ category }}
                             </li>
                            </ul>
                        </div>
                        <div class="flex justify-center mt-4">
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step
                    [formGroupName]="'step3'"
                    [stepControl]="horizontalStepperForm.get('step3')"
                    #horizontalStepperStep3>
                        <ng-template *ngIf="!submit" matStepLabel><span class="blueLine"></span></ng-template>

                        <div *ngIf="!submit" class="beforeSubmit">

                            <h2 class="font-medium leading-7 mb-4 sm:leading-10 font-22 text-center tracking-tight mt-6">
                                Is there anything else you would like to share with us to help you serve better?
                            </h2>
                            <div class="flex flex-col gt-xs:flex-row">
                                <mat-form-field class="flex-auto  gt-xs:pl-3">
                                    <mat-label>Feedback </mat-label>
                                    <textarea
                                        matInput
                                        [formControlName]="'message'"
                                        placeholder="You can utilize this space to give your feedback." 
                                        [rows]="5" cdkTextareaAutosize></textarea>
                                </mat-form-field>
                            </div>
                            
                            <div class="flex justify-center mt-6">
                                <button
                                    class="px-8 mr-2"
                                    mat-flat-button
                                    [color]="'accent'" (click)="submitNps('skip')"
                                    type="button"
                                    >
                                    Skip
                                </button>
                                <button
                                    class="px-8 "
                                    mat-flat-button
                                    [color]="'primary'"
                                    (click)="submitNps('submit')"
                                    type="button"
                                    >
                                    <mat-spinner *ngIf="fetch_loader" class="matSpinner"></mat-spinner>
                                    Submit
                                </button>
                            </div>
                        </div>

                        <!-- after submit  -->

                        <div class="afterSubmit" *ngIf="submit">
                            <h2 class="font-medium leading-7 mb-4 sm:leading-10 font-22 text-center tracking-tight mt-6">
                                Thank you for supporting us and providing your valuable feedback.
                            </h2>
                            
                            <div class="flex justify-center actionAfter mt-6">
                                <!-- <button
                                    class="px-8 mr-2 gap-2"
                                    mat-flat-button
                                    [color]="'accent'"
                                    type="button" (click)="navigate()"
                                    >
                                    <mat-icon svgIcon="heroicons_outline:home"></mat-icon> 
                                     Go Back To Dashboard
                                </button> -->
                                <button
                                    mat-flat-button
                                    [color]="'primary'" (click)="openDialog()" 
                                    >
                                        <img src="assets/icons/whiteaiicon.png" class="aiicon1" width="15" alt="">
                                        <span class="ml-2"> Start New {{category}} with AI</span>
                                </button>
                            </div>
                        </div>
                    </mat-step>

                </mat-horizontal-stepper>
            </form>

        </div>

    </div>

</div>
