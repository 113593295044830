import { filter } from 'rxjs';
import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor,NgIf,NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output, ViewChild,viewChild } from '@angular/core';
import { FormArray, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerComponent, FuseDrawerService } from '@fuse/components/drawer';
import { AddThemeComponent } from "../dialogs/add-theme/add-theme.component";
import { AddEpicComponent } from '../dialogs/add-epic/add-epic.component';
import { AddFeatureComponent } from '../dialogs/add-feature/add-feature.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';


@Component({
  selector: 'app-requirements-view',
  standalone: true,
  imports: [AddThemeComponent,AddFeatureComponent,NgIf,NgClass, AddEpicComponent, MatFormFieldModule, NgFor, MatSelectModule, ReactiveFormsModule, MatIcon, MatInputModule, MatButtonModule, MatExpansionModule, DragDropModule, MatSelectModule, AddThemeComponent, AddFeatureComponent],
  templateUrl: './requirements-view.component.html',
  styleUrl: './requirements-view.component.scss'
})
export class RequirementsViewComponent{
  @ViewChild('addFeature') addFeature: AddFeatureComponent;
  @ViewChild('addTheme') addThemeDrawer: AddThemeComponent;
  @ViewChild('addEpic') addEpicDrawer: AddEpicComponent;
  accordion = viewChild.required(MatAccordion);
  
  _proposalDetail: any = {};

  @Input() set proposal(value: any) {
    if (value) {console.log(value);
      this._proposalDetail = value;
      this.ngOnInit();
      this.cdr.detectChanges();
    }
  }

  @Input()
  themeTypes:any=[];

  @Input()
  _page_id:any;

  @Input()
  is_write_allowed:any;

  epicTypes:any=[];

  @Output()
  onSaveEpics=new EventEmitter<any>();

  @Output()
  onSaveThemeAndEpics=new EventEmitter<any>();

  expandAll:boolean=true;
  expandChild:boolean = true;

  selectedFeature:any=null;

  inputFormGroup:UntypedFormGroup;
  expandState:number=0;
  themesExpanded:boolean[]=[];
  releases_list:any=[];
  all_original_release_list:any=[];
  epicsExpanded:any=[];
  requirements:any=[

  ];
  readonly dialog = inject(MatDialog);

  constructor(private cdr: ChangeDetectorRef,private _formBuilder: UntypedFormBuilder,private fuseDrawer:FuseDrawerService){}

  ngOnInit(){

    this.resetToAllEpics();
    this.resetRequirements();

    this.inputFormGroup = this._formBuilder.group({
      theme: [[]],
      search: [''],
      epic_name: [[]],
      release: [[]]
    });

    this.releases_list = JSON.parse(JSON.stringify(this._proposalDetail.releases_list));
    this.releases_list.forEach(release => {
      release.requirements = release.requirements || [];
    });
    this._proposalDetail.epics.forEach(epic => {
      epic.epics.forEach(subEpic => {
        subEpic.features.forEach(requirement => {
          if (requirement.release) {
            let release = this.releases_list.findIndex(release => release._id === requirement.release._id);
            if (release>-1) {
              requirement['theme'] = {_id: epic._id, title:epic.theme};
              requirement['epic'] = {_id: subEpic._id, title:subEpic.name};
              this.releases_list[release].requirements.push(requirement);
            }
          }
        });
      });
    });
    this.all_original_release_list = JSON.parse(JSON.stringify(this.releases_list));
}

  search(term){
    this.filterRequirements(this.inputFormGroup.get("search").value,
    this.inputFormGroup.get("theme").value,
    this.inputFormGroup.get("epic_name").value,
    this.inputFormGroup.get("release").value
  );
  }

  onRequirementReleaseSelection(event,theme,epic,feature,index){
    this._proposalDetail.epics.forEach(eachtheme => {
      if(eachtheme.theme==theme){
        eachtheme.epics.forEach((eachepic)=>{
          if(eachepic.name==epic){   
            eachepic.features[index].release=event.value; 
          }
        });

      }
    });
    this.onSaveEpics.emit(this._proposalDetail.epics);
  }
  
  resetRequirements(){

   this.requirements=[...this._proposalDetail.epics];
    this.initThemesExpandedState();
  }
  initThemesExpandedState(){
    this.themesExpanded=[];
    this.epicsExpanded=[];
    let tIndex=0;
    
    for(let theme of this.requirements){
      this.themesExpanded.push(true);
      let eIndex=0;
      for(let epic of theme.epics){
        let key=`${tIndex}-${eIndex}`;
        this.epicsExpanded[key]=true;
        eIndex++;
      }
      tIndex++;
    }
  }
  getEpicKey(themeIndex,epicIndex){
    return `${themeIndex}-${epicIndex}`;
  }

  resetToAllEpics(){
    this.epicTypes = this._proposalDetail.epics?.flatMap((epic) => {
      return epic.epics.map((themeEpic) => {
        return { label: themeEpic.name, value: themeEpic.name };
      });
    }) ?? [];    
  }

  onChangeTheme(event){

    let selectedThemes=event.value;

    if(selectedThemes.length){
    this.epicTypes=[];
    for(let epic of this._proposalDetail.epics){
      if(selectedThemes.includes(epic.theme)){
        let epics= epic.epics.map((themeEpic) => {
          return { label: themeEpic.name, value: themeEpic.name };
        });
        this.epicTypes.push(...epics);
      }
    }
  }else{
    this.resetToAllEpics();
  }

  this.filterRequirements(this.inputFormGroup.get("search").value,
    this.inputFormGroup.get("theme").value,
    this.inputFormGroup.get("epic_name").value,
    this.inputFormGroup.get("release").value
  );
  }

  onChangeEpic(event){
    this.filterRequirements(this.inputFormGroup.get("search").value,
      this.inputFormGroup.get("theme").value,
      this.inputFormGroup.get("epic_name").value,
      this.inputFormGroup.get("release").value
    );
  }

  trackByFn(index: number, item: any): any
  {
    return item.id || index;
  }

  dropEpic(event,themeIndex){
    moveItemInArray(this.requirements[themeIndex].epics, event.previousIndex, event.currentIndex);
   // moveItemInArray(this._proposalDetail.epics[themeIndex].epics, event.previousIndex, event.currentIndex);
    this.onSaveThemeAndEpics.emit({epics:this.requirements,businessCapabilities:this._proposalDetail.businessCapabilities});
  }

  dropTheme(event){  
    moveItemInArray(this.requirements, event.previousIndex, event.currentIndex);
    moveItemInArray(this._proposalDetail.epics, event.previousIndex, event.currentIndex);
    this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities});
  }

  onAddNewFeature(){
    this.addFeature.isEdit=false;
    this.addFeature.selectedIndex=-1;
    this.addFeature.themeForm.get("feature").setValue("");
    this.addFeature.themeForm.get("theme").setValue("");
    this.addFeature.themeForm.get("epic").setValue("");
    this.addFeature.themeForm.get("release").setValue("");
    this.addFeature.themeDrawer.toggle();
  }

  dropFeature(event,themeIndex,epicIndex){
    moveItemInArray(this.requirements[themeIndex].epics[epicIndex].features, event.previousIndex, event.currentIndex);
    this.onSaveThemeAndEpics.emit({epics:this.requirements,businessCapabilities:this._proposalDetail.businessCapabilities});
  }

  onEditFeature(theme,epic,feature,index){
    this.addFeature.isEdit=true;
    this.addFeature.selectedIndex=index;
    this.addFeature.themeForm.get("feature").setValue(feature.title);
    this.addFeature.themeForm.get("theme").setValue(theme);
    this.addFeature.filterEpics();
    this.addFeature.themeForm.get("epic").setValue(epic);
    this.addFeature.themeForm.get("release").setValue(feature.release);
    this.addFeature.themeDrawer.toggle();
  }

  onDeleteFeature(selectedTheme,selectedEpic,feature,index){
    this._proposalDetail.epics.forEach(theme => {
      if (theme.theme == selectedTheme) {
        theme.epics.forEach((epic) => {
          if (epic.name == selectedEpic) {
            if (epic.features && index !== -1 && index < epic.features.length) {
              epic.features.splice(index, 1);
            }
          }
        });
      }
  });
  this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onDeleteFeatureCallback(event){
    this.onDeleteFeature(event.theme,event.epic,event.feature,event.index);
  }

  filterRequirements(
    searchTerm: string,
    selectedThemes: string[],
    selectedEpics: string[],
    selectedReleases: string[]
  ) {
   // console.log("On Filter Requirements",searchTerm,selectedThemes,selectedEpics);

    if(searchTerm=="" && !selectedThemes.length && !selectedEpics.length && !selectedReleases.length){
      this.resetRequirements();
      return;
    }

    this.resetRequirements();


    this.requirements=this.requirements
    .filter((requirement) => {
      // Apply theme filter if selectedThemes is not empty
      if (selectedThemes.length > 0) {
        return selectedThemes.includes(requirement.theme);
      }
      return true; // No theme filter applied, include all
    })
    .map((requirement) => {
      // Filter epics within the theme
      const filteredEpics = requirement.epics.filter((epic) => {
        // Apply epic filter only if selectedEpics is not empty
        const matchesEpic =
          selectedEpics.length === 0 || selectedEpics.includes(epic.name);

        // Apply search term filter only if searchTerm is not empty
        const matchesSearchTerm =
          searchTerm === "" ||
          epic.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          requirement.theme.toLowerCase().includes(searchTerm.toLowerCase()) ||
          epic.features.some((feature) =>
            feature.title.toLowerCase().includes(searchTerm.toLowerCase())
          );

        return matchesEpic && matchesSearchTerm;
      });

      return { ...requirement, epics: filteredEpics };
    })
    .filter((requirement) => requirement.epics.length > 0); // Only include requirements with epics
  
    if(selectedReleases.length>0){
      let themes_epic = [];
      let list_requirements = JSON.parse(JSON.stringify(this.requirements));
      list_requirements.forEach(elepics => {
        let epic_epics = [];
        elepics.epics.forEach(epic => {
          epic.features = epic.features.filter(feature => selectedReleases.indexOf(feature.release) >= 0);
          if(epic.features.length)epic_epics.push(epic);
        });
        if(epic_epics.length)themes_epic.push(elepics);
      });
      this.requirements = themes_epic;
    }
  
  }

  onAddTheme(){
    this.addThemeDrawer.isEdit=false;
    this.addThemeDrawer.themeForm.get("themeName").setValue("");
    this.addThemeDrawer.themeDrawer.toggle();
  }

  onAddEpic(){
    this.addEpicDrawer.isEdit=false;
    this.addEpicDrawer.themeForm.get("epicName").setValue("");
    this.addEpicDrawer.themeForm.get("theme").setValue("");
    this.addEpicDrawer.epicDrawer.toggle();
  }

  onSaveTheme(theme){
    if(theme.isEdit){
      let oldTheme=this._proposalDetail.epics[theme.index].theme;
      let capabilityIndex=this._proposalDetail.businessCapabilities.findIndex((cap)=>cap==oldTheme);
      this._proposalDetail.businessCapabilities[capabilityIndex]=theme.theme;
      this._proposalDetail.epics[theme.index].theme=theme.theme;
      let themeTypeIndex=this.themeTypes.findIndex((tType)=>tType.value==oldTheme);
      if(themeTypeIndex>=0){
        this.themeTypes[themeTypeIndex].value=theme.theme;
        this.themeTypes[themeTypeIndex].label=theme.theme;
      }
    }else{
      this._proposalDetail.epics.push({theme:theme.theme,epics:[]});
      this.themeTypes.push({value:theme.theme,label:theme.theme});
      this._proposalDetail.businessCapabilities.push(theme.theme);
    }
    this.resetRequirements();
    this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities});
  }

  onSaveEpic(event){
    if(event.isEdit){
      this._proposalDetail.epics.forEach(theme => {
        if(theme.theme==event.theme){
          let epicIndex=theme.epics.findIndex((ep)=>ep.name==event.oldEpic);
          if(epicIndex>=0){
            theme.epics[epicIndex].name=event.epic;
          }
        }
      });
    }else{
      this._proposalDetail.epics.forEach(theme => {
        if(theme.theme==event.theme){
          theme.epics.push({name:event.epic,features:[]});
        }
      });
    }
    this.onChangeTheme({value:this.inputFormGroup.get("theme").value});
    this.resetRequirements();
    this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onAddNewFeatureForEpic(theme,epic){
    this.addFeature.isEdit=false;
    this.addFeature.selectedIndex=-1;
    this.addFeature.themeForm.get("feature").setValue("");
    this.addFeature.themeForm.get("theme").setValue(theme);
    this.addFeature.filterEpics();
    this.addFeature.themeForm.get("epic").setValue(epic);
    this.addFeature.themeForm.get("release").setValue("");
    this.addFeature.themeDrawer.toggle();
  }

  onEditEpic(theme,epic){
    this.addEpicDrawer.isEdit=true;
    this.addEpicDrawer.oldEpic=epic;
    this.addEpicDrawer.themeForm.get("epicName").setValue(epic);
    this.addEpicDrawer.themeForm.get("theme").setValue(theme);
    this.addEpicDrawer.epicDrawer.toggle();
  }

  onDeleteEpic(theme,selectedEpic){
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {message:"Are you sure you want to delete this epic? All associated features, and, solutions will also be removed."},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.yes===true){
        for(let i=0;i<this._proposalDetail.epics.length;i++){
          if(this._proposalDetail.epics[i].theme==theme){
            let epicIndex=this._proposalDetail.epics[i].epics.findIndex((epic)=>epic.name==selectedEpic);
            if(epicIndex>=0){
              this._proposalDetail.epics[i].epics.splice(epicIndex,1);
            }
            break;
          }
        }
        this.resetRequirements();
        this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities});
      }
    });
  }

  onDeleteTheme(requirement:any){
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {message:"Are you sure you want to delete this theme? All associated epics, requirements, solutions, and insights will also be removed."},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.yes===true){
        for(let i=0;i<this._proposalDetail.epics.length;i++){
          if(this._proposalDetail.epics[i].theme==requirement.theme){
            this._proposalDetail.epics.splice(i,1);
            this.themeTypes.splice(i,1);
            let capIndex=this._proposalDetail.businessCapabilities.findIndex((cap)=>cap==requirement.theme);
            this._proposalDetail.businessCapabilities.splice(capIndex,1);
            break;
          }
        }
        this.resetRequirements();
        this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities});
      }
    });
  }

  onEditTheme(theme:any){
    this.addThemeDrawer.isEdit=true;
    let index=this._proposalDetail.epics.findIndex((req)=>req.theme==theme.theme);
    this.addThemeDrawer.selectedIndex=index;
    this.addThemeDrawer.themeForm.get("themeName").setValue(theme.theme);
    this.addThemeDrawer.themeDrawer.toggle();
  }
     
  onSaveFeature(event:any){
    if(!event.isEdit){
      //Add New Mode
    this._proposalDetail.epics.forEach(theme => {
      if(theme.theme==event.theme){
        theme.epics.forEach((epic)=>{
          if(epic.name==event.epic){
            if(epic.features){
              epic.features.push({title:event.feature,release:event.release});
            }else{
              epic.features=[{title:event.feature,release:event.release}];
            }
          }

        });

      }
    });
    }else{
        //Edit Mode
        if(event.index!=-1){
          this._proposalDetail.epics.forEach(theme => {
            if(theme.theme==event.theme){
              theme.epics.forEach((epic)=>{
                if(epic.name==event.epic){
                  epic.features[event.index].title=event.feature;
                  epic.features[event.index].release=event.release;
                }
              });
            }
          });
        }
    }
    this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onToggleAccordion(expand){

    // this.expandState++;
    // console.log("Current State:",this.expandState);
    // if(this.expandState>=3){
    //   this.expandState=0;
    // }
    this.expandState = expand;
    //0 =expanded all 1= themes expanded , epics collapsed  2= all collapsed
    let expandThemes=true;
    let expandEpics=true
    if(this.expandState==0){
      expandThemes=true;
      expandEpics=true;
    }else if(this.expandState==1){
      expandThemes=true;
      expandEpics=false;
    }else if(this.expandState==2){
      expandThemes=false;
      expandEpics=false;
    }
    
      this.themesExpanded=this.themesExpanded.map((theme)=>expandThemes);

      let tIndex=0;
    
      for(let theme of this.requirements){
        this.themesExpanded.push(true);
        let eIndex=0;
        for(let epic of theme.epics){
          let key=`${tIndex}-${eIndex}`;
          this.epicsExpanded[key]=expandEpics;
          eIndex++;
        }
        tIndex++;
      }
       

      //this._changeDetectorRef.detectChanges();
       

  }
  
}
