<!-- <div class="flex flex-col flex-auto min-w-0 relative" >
       
    <span class="close-dialog" (click)="closeRef()">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>
    <div class="flex-auto">
        <div class="formBox">
            <div class="formHead flex justify-between">
                <h4 class="text-2xl">Select Story ID</h4>
            </div>
            <div class="separator"></div>

            <div class="bg-card   tablebox ">
                <section class="flex flex-row flex-wrap justify-end px-3 w-full">
                    <form class="flex flex-wrap gap-2 justify-center " [formGroup]="headerForm" *ngIf="headerForm">
                        <mat-form-field  class="fuse-mat-dense searchTab min-w-72">
                            <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                            <input matInput class="text-sm font-medium" [formControlName]="'search'" [autocomplete]="'off'" [placeholder]="'Search'" (input)="filterCount()">
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab   ">
                            <mat-select [formControlName]="'selected_theme'" [placeholder]="'Theme'" multiple (selectionChange)="onChangeTheme($event);filterCount()" >
                                @for (option of all_themes; track option) {
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab   ">
                            <mat-select [formControlName]="'selected_epic'" [placeholder]="'Epic'" multiple (selectionChange)="filterCount()" >
                                @for (option of all_epics; track option) {
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                        </mat-form-field>
                        <mat-form-field *ngIf="(all_tags.length>0)" class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 ">
                            <mat-select [formControlName]="'selected_tag'" [placeholder]="'Tags'" multiple (selectionChange)="filterCount()">
                                @for (option of all_tags; track option) {
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:folder'"></mat-icon>
                        </mat-form-field>
                    </form>
                </section>
            </div>

            <div *ngIf="loader_data" class="justify-center flex p-16">

                <mat-progress-spinner  [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
        
            <div class="max-w-full flex flex-col mt-6"  *ngIf="!loader_data">  
                <div class="tabledata " >
                    <div class="innerRow min-w-full  gap-3 py-5 px-5 tableth border-b">
                        <div class="text-md storyTab text-left  firstcol">Story ID</div>
                        <div class="text-md smallTab text-left  ">Title</div>
                        <div class="text-md  smallTab text-left  ">Theme</div>
                        <div class="text-md smallTab text-left  "> Epic</div>     
                    </div>
                    
                    <section class="tableRow">
                        <div class=" tablebody" >
                            <div  class="relative group   select-none  sm:mt-0 mt-3 ">
                                <div class="tableRow relative">
                                    @for(eachstory of storyList;track eachstory){
                                        <div class="innerRow gap-3 group w-full items-center py-3 px-5  cursor-pointer tabletd border-b hover:bg-gray-100 dark:hover:bg-hover">
                                            <div class="text-md text-black text-left font-regular firstcol storyTab ">
                                                {{eachstory.storyID}}
                                            </div>
                                            <div class="text-md text-black text-left font-regular smallTab">
                                                {{eachstory.title}}
                                            </div>
                                            <div class="text-md text-black text-left font-regular smallTab">
                                                {{eachstory.theme}}
                                            </div>
                                            <div class="text-md text-black text-left font-regular  smallTab">
                                                {{eachstory.epic}}
                                            </div>
                                            <div class="text-md text-black text-left font-regular  smallTab">
                                                <button class="selectBt" (click)="selectStory(eachstory)">{{alreadySelect(eachstory)?'Selected':'Select'}}</button>
                                            </div>
                                        </div> 
                                    }
                                </div> 
                            </div>      

                            <div class="flex"  *ngIf="!storyList.length">
                                <div class="flex flex-col gap-4 items-center justify-center notfound p-16 w-full">
                                    <mat-icon svgIcon="heroicons_outline:exclamation-circle"></mat-icon>
                                    <h4 class="text-lg font-medium">No Story Found</h4>
                                </div>
                              </div>
                            
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div> -->




<div class="flex flex-col min-w-0 flex-auto relative " >
    <!-- Main -->
    <div class="flex-auto">
        <div class="formBox">
            <div class="formHead flex justify-between">
                <h4 class="text-2xl">Select Story ID</h4>
                <span class="close-dialog2 cursor-pointer" [mat-dialog-close]="true" (click)="closeRef()"> 
                    <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
                </span>
            </div>
            <div class="separator"></div>

            <div class="bg-card   tablebox ">
                <!-- Header Section -->
                <section class="flex flex-row flex-wrap justify-end px-3 w-full">
                    <form class="flex flex-wrap gap-2 justify-center " [formGroup]="headerForm" *ngIf="headerForm">
                        <mat-form-field  class="fuse-mat-dense searchTab min-w-72">
                            <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                            <input matInput class="text-sm font-medium" [formControlName]="'search'" [autocomplete]="'off'" [placeholder]="'Search'" (input)="filterCount()">
                        </mat-form-field>
                        <!-- Add filters -->
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab   ">
                            <mat-select [formControlName]="'selected_theme'" [placeholder]="'Theme'" multiple (selectionChange)="onChangeTheme($event);filterCount()" >
                                @for (option of all_themes; track option) {
                                    <mat-option [value]="option.label">{{option.label}}</mat-option>
                                }
                            </mat-select>
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab   ">
                            <mat-select [formControlName]="'selected_epic'" [placeholder]="'Epic'" multiple (selectionChange)="filterCount()" >
                                @for (option of all_epics; track option) {
                                    <mat-option [value]="option.label">{{option.label}}</mat-option>
                                }
                            </mat-select>
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                        </mat-form-field>
                        <mat-form-field *ngIf="(all_tags.length>0)" class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 ">
                            <mat-select [formControlName]="'selected_tag'" [placeholder]="'Tags'" multiple (selectionChange)="filterCount()">
                                @for (option of all_tags; track option) {
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:folder'"></mat-icon>
                        </mat-form-field>
                    </form>
                </section>
            </div>
            <div *ngIf="loader_data" class="justify-center flex p-16">
                <mat-progress-spinner  [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <div class="max-w-full flex flex-col" *ngIf="!loader_data">
                <div class="tabledata " >
                    <div class="flex min-w-full  gap-3 py-5 px-5 tableth border-b">
                        <div class="truncate2  checkicm cursor-pointer" [ngClass]="{'selected2':isSelectAll}" (click)="onSelectAll()">
                            <mat-icon svgIcon="heroicons_solid:check-circle" class="cursor-pointer" *ngIf="isSelectAll" ></mat-icon>
                            <mat-icon svgIcon="heroicons_outline:check-circle" class="cursor-pointer" *ngIf="!isSelectAll" ></mat-icon>
                        </div>  
                        <div class="text-md text-left  storyTab">Story ID</div>
                        <div class="text-md titleTab text-left">Title</div>
                        <div class="text-md  smallTab text-left">Theme</div>
                        <div class="text-md smallTab text-left"> Epic</div>   
                    </div>
                    
                    <section class="tableRow">
                        <div class=" tablebody" >
                            @for(eachstory of storyList;track eachstory){
                                <div  class="relative group   select-none  sm:mt-0 mt-3 ">
                                    <!-- Content for each section -->
                                    <div class="tableRow relative">
                                        <div class="flex gap-3 group w-full items-center py-3 px-5  cursor-pointer tabletd border-b hover:bg-gray-100 dark:hover:bg-hover" (click)="selectStory(eachstory)" [ngClass]="{'selectedRow':(alreadySelect(eachstory))}">
                                            <div class="truncate2  checkicm cursor-pointer" [ngClass]="{'selected2':(alreadySelect(eachstory))}">
                                                <mat-icon svgIcon="heroicons_solid:check-circle" class="cursor-pointer" *ngIf="(alreadySelect(eachstory))" ></mat-icon>
                                                <mat-icon svgIcon="heroicons_outline:check-circle" class="cursor-pointer" *ngIf="!(alreadySelect(eachstory))" ></mat-icon>
                                            </div>
                                            <div class="text-md text-black text-left font-regular storyTab ">
                                                {{eachstory.storyID}}
                                            </div>
                                            <div class="text-md text-black text-left font-regular titleTab">
                                                {{eachstory.title}}
                                            </div>
                                            <div class="text-md text-black text-left font-regular smallTab">
                                                {{eachstory.theme}}
                                            </div>
                                            <div class="text-md text-black text-left font-regular  smallTab">
                                                {{eachstory.epic}}
                                            </div>
                                            
                                        </div> 
                            
                                    </div>
                                </div>
                            }

                            <div class="flex"  *ngIf="!storyList.length">
                                <div class="flex flex-col gap-4 items-center justify-center notfound p-16 w-full">
                                    <mat-icon svgIcon="heroicons_outline:exclamation-circle"></mat-icon>
                                    <h4 class="text-lg font-medium">No Story Found</h4>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="quickAction" *ngIf="selected_stories.length">
                <div class="flex gap-6 pl-3 text-white">
                    <div class="flex gap-1 items-center quickBt cursor-pointer" (click)="onRemoveSelections()" >
                        <mat-icon svgIcon="heroicons_outline:x-mark" ></mat-icon>
                        <span>{{selected_stories.length}} Selected</span>
                    </div>
                    <div class="divider"></div>
                    <div class="flex gap-1 items-center quickBt cursor-pointer" (click)="closeRef()" >
                        <mat-icon svgIcon="heroicons_outline:plus" ></mat-icon>
                        <span>Add Story</span>
                    </div>
                </div>
            </div>
            <mat-paginator *ngIf="!loader_data" [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" (page)="onPageChange($event)"  [pageIndex]="currentPage" class="customPaginator w-full"></mat-paginator>
        
        </div> 
    </div>
</div>