<div class="min-w-full max-w-full flex justify-center flex-col">



    <!-- Quality Score -->
    <div  class="min-h-72 min-w-full max-w-full bg-white rounded-md shadow-sm py-5 px-4 relative">
       <button *ngIf="false" matTooltip="Answer as many questions as possible to improve your quality indicator score. Ensure responses are clear, concise, and well-supported." class="absolute right-5 top-3 w-48 h-10  cursor-pointer"
       aria-label="Button that displays a tooltip when focused or hovered over" >
       <div class="flex gap-2 items-center justify-end">
        <div class="text-[#656A77] text-md">Score Calculation</div>
        <img class="w-6 h-6" src="assets/icons/info_icon.svg">
       </div>
    </button>
        <div class="text-black text-2xl tracking-tight font-semibold">{{page_id=='proposal'?'Proposal':'Project'}} Input Quality Indicator</div>
        <p class="text-[#6D7C92]">Enhance input details to achieve optimal output during regeneration.</p>
        <p *ngIf="currentScore<80" class="text-[#353E50] mt-6">Answer the insights below to increase your score by <strong>{{80-currentScore}}</strong>, enabling the {{page_id}} regeneration ability.</p>
        <p *ngIf="currentScore>=80" class="text-[#353E50] mt-6">Provide more insights below before regenerating the {{page_id}} for the best outcome.</p>


        
        <div class="mt-8">
            <div class="text-[#4F45E4]  tracking-tight sm:text-lg text-md font-medium">Quality Score</div>
            <div class="min-w-full flex h-12 mt-1 ">
               <div class="flex items-center h-full flex-auto">
                <div class="bg-[#E7E7E7] w-full relative   min-h-7 rounded-full">




                    <div class="z-10 absolute top-0 bottom-0  min-h-7 rounded-full" [ngStyle]="{
                        'width': currentScore + '%',
                        'background': 'linear-gradient(to right, #4F45E4 0%, #4883F7 40%, #648683 ' + (currentScore >= 80 ? '70%' : '100%') + ')'
                      }" style="min-width: 13px;">
                        <div class="text-[#16A34A] text-xs sm:text-sm absolute sm:-top-5 -top-4 right-0 font-medium">{{getCurrentScoreString()}}</div>
                    </div>


                    <div class="bg-[#B4B4B4] absolute top-0 bottom-0   min-h-7 rounded-full" [ngStyle]="{'width': '80%'}">
                        <div class="text-black text-xs sm:text-sm absolute sm:-top-5 -top-4  right-0 font-medium">80</div>
                        <div class="text-[#94A3B8] text-xs absolute top-9 -right-16 sm:text-md">Regeneration Available</div>
                    </div>
                </div>
               </div>
                <div class="min-w-10 pl-1 text-black flex items-center text-xs sm:text-sm font-medium">
                    100
                </div>
            </div>

            <div *ngIf="((proposalInsights.length>0 || isLoadingInsights) && (accessStatus=='admin'))" (click)="onRegenerateProposal()" class="rounded-full sm:mt-4 mt-5 mb-3 cursor-pointer min-h-3 w-44  p-2 flex gap-1 justify-start items-center" [ngStyle]="{'border': 'solid 1px '+getRegenerateBorderColor(),'box-shadow': currentScore >= 80 ? '0px 0px 15px 0px rgba(79,69, 228, 0.5)' : 'none'}">
                <img [src]="(currentScore>=80 && !isRegeneratingProposal && !isLoadingInsights)?'assets/icons/insights_icon.svg':'assets/icons/gen_icon.svg'" class="w-4 h-4">
                <div class="text-md no-select" [ngStyle]="{'color': getRegenerateTextColor()}">Regenerate {{page_id=='proposal'?'Proposal':'Project'}}</div>
            </div>

            <div *ngIf="((proposalInsights.length==0 && !isLoadingInsights) && (accessStatus=='admin'))" (click)="onGenerateProposalInsights()" class="rounded-full sm:mt-4 mt-5 mb-3 cursor-pointer min-h-4 w-44 p-2 flex gap-1 justify-start items-center" [ngStyle]="{'border': 'solid 1px '+getRegenerateBorderColor(),'box-shadow': currentScore >= 80 ? '0px 0px 15px 0px rgba(79,69, 228, 0.5)' : 'none'}">
                <img [src]="(!isGeneratingInsights && !isLoadingInsights)?'assets/icons/insights_icon.svg':'assets/icons/gen_icon.svg'" class="w-4 h-4">
                <div class="text-md no-select" [ngStyle]="{'color': getGenerateInsightsTextColor()}">Regenerate Insights</div>
            </div>

        </div>
        
    </div>

    <!-- Insights -->
    <div  class="min-h-72 min-w-full max-w-full bg-white rounded-md shadow-sm py-5 mt-5 relative">
        <div class="w-full flex px-4">
            <div class="flex w-full flex-col">
                <div class="flex sm:flex-row flex-col w-full sm:justify-between">
                    <div class="flex flex-col gap-1 sm:mt-0 mt-2">
                        <h4 class="font-semibold text-lg sm:text-2xl">{{page_id=='proposal'?'Proposal':'Project'}} Input Gaps</h4>
                        <div class="flex  gap-1 sm:mt-0 mt-2">
                            <div class="flex sm:text-md text-sm font-normal gap-1">
                                <div class="text-[#4F45E4]">{{getTotalPendingInsights()}}</div>
                                <div class="text-[#656A77]">Pending,</div>
                            </div>
                            <div class="flex sm:text-md text-sm font-normal gap-1">
                                <div class="text-[#4F45E4]">{{getTotalProcessedInsights()}}</div>
                                <div class="text-[#656A77]">Answered</div>
                            </div>
                        </div>
                    </div>
                   

                    <div class="flex self-end sm:mt-0 mt-2  gap-1 sm:gap-5 sm:mr-2 mr-0 ">
                        <div (click)="onChangeInsightType(2)" class="flex sm:text-md text-sm   sm:gap-1 gap-0 no-select cursor-pointer justify-center  font-medium sm:py-2 py-1 sm:px-5 px-2 rounded-full" [ngStyle]="{'background': selectedInsightType==2?'#E2E8F0':'transparent','color':selectedInsightType==2?'#4F45E4':'#64748B'}"><img  *ngIf="selectedInsightType==2" class="w-5 h-5" src="assets/icons/selection_icon.svg"> <div>Pending</div></div>
                        <div (click)="onChangeInsightType(1)" class="flex sm:text-md text-sm sm:gap-1 gap-0 no-select cursor-pointer justify-center  font-medium sm:py-2 py-1 sm:px-5 px-2 rounded-full" [ngStyle]="{'background':selectedInsightType==1?'#E2E8F0':'transparent','color':selectedInsightType==1?'#4F45E4':'#64748B'}"><img  *ngIf="selectedInsightType==1" class="w-5 h-5" src="assets/icons/selection_icon.svg"> <div>Answered</div></div>
                        <div (click)="onChangeInsightType(0)" class="flex sm:text-md text-sm sm:gap-1 gap-0 no-select cursor-pointer justify-center  font-medium sm:py-2 py-1 sm:px-5 px-2 rounded-full" [ngStyle]="{'background':selectedInsightType==0?'#E2E8F0':'transparent','color':selectedInsightType==0?'#4F45E4':'#64748B'}"><img *ngIf="selectedInsightType==0" class="w-5 h-5" src="assets/icons/selection_icon.svg"> <div>All</div></div>
                    </div>
                </div>

              

            </div>
        </div>
         
        <div class="bg-[#E2E8F0] line mt-5 w-full"></div>
            <div *ngIf="(accessStatus=='admin')" class="flex pl-13 items-center gap-3 h-15 cursor-pointer" (click)="onAddNewInsight()">
                <img class="w-6 h-6" src="assets/icons/plus_icon.svg">
                <div class="text-[#94A3B8] sm:text-md text-sm no-select">Add New Insight</div>
            </div>

            <div class="mt-0 max-w-full">
                <ng-container *ngIf="proposalInsights && proposalInsights.length > 0">
                    <div class="max-w-full flex flex-col">
                        <mat-accordion class="max-w-full" [multi]="true">
                            <ng-container *ngFor="let insight of proposalInsights; trackBy: trackByFn">
                                <mat-expansion-panel [expanded]="true" class="border-none w-full" *ngIf="(selectedInsightType==1 && getProcessedInsightQuestionsCount(insight)>0) || (selectedInsightType==2 && getPendingInsightQuestionsCount(insight)>0) || selectedInsightType==0 ">
                                    <mat-expansion-panel-header class="border-t border-b group w-full select-none h-15 text-lg font-semibold bg-[#F8FAFC]">
                                        <mat-panel-title class="flex items-center w-full pl-5" >
                                            <div class="flex-auto mr-2 truncate">
                                                <span>{{insight.capability}}</span>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    
                                    <!-- insight Questions -->
                                    <div  [cdkDropList]="accessStatus === 'admin'" (cdkDropListDropped)="dropped($event)"  [cdkDropListData]="insight.questions" class="questions-container max-w-full">
                                        <ng-container *ngFor="let question of insight.questions">
                                            <div
                                                [id]="'insight-' + question._id"
                                                class="group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0 mt-3  pl-4"
                                                [cdkDrag]="accessStatus === 'admin'"
                                                (click)="onSelectInsightQuestion(question,insight)"
                                                [cdkDragLockAxis]="'y'"
                                                *ngIf="selectedInsightType==0 || (selectedInsightType==1 && question.valid)||(selectedInsightType==2 && !question.valid)"
                                                >
                                                
                                                <!-- Drag preview -->
                                                <div *ngIf="(accessStatus=='admin')">
                                                    <div class="flex flex-0 w-0 h-0" *cdkDragPreview></div>
                                                </div>
                                                <div *ngIf="(accessStatus!='admin')" class="flex flex-0 w-0 h-0"></div>
                                                
                                                <!-- insight content -->
                                                <div class="relative flex items-center h-full pl-7 ">
                                                    
                                                    <!-- Selected indicator -->
                                                    <ng-container *ngIf="selectedQuestion && selectedQuestion._id === question._id">
                                                        <div class="z-10 absolute inset-0 bg-primary opacity-10"></div>
                                                    </ng-container>
                                                    
                                                    <!-- Drag handle -->
                                                    <div *ngIf="accessStatus === 'admin'"
                                                        class="md:hidden absolute flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                                        cdkDragHandle>
                                                        <mat-icon
                                                            class="icon-size-5 text-hint"
                                                            [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                                    </div>
                                                    
                                                    <!-- Complete insight button -->
                                                    <button class="mr-2 leading-none" mat-icon-button>
                                                        <ng-container *ngIf="question.valid">
                                                            <mat-icon class="text-primary" [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                                                        </ng-container>
                                                        <ng-container *ngIf="!question.valid">
                                                            <mat-icon class="text-hint" [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                                                        </ng-container>
                                                    </button>
                                                    
                                                    <!-- insight link -->
                                                    <div class="grid grid-cols-12 sm:gap-2 gap-3 min-w-full   items-center h-full pr-7 cursor-pointer">
                                                        <!-- Title & Placeholder -->
                                                        <div  class="flex sm:col-span-10 col-span-9  w-full  sm:mr-2 mr-1  sm:text-md text-sm">
                                                                {{question.title}}
                                                        </div>
                                                        <!-- Score -->
                                                        <div class="sm:text-sm text-xs  flex justify-end sm:mr-8 mr-6 sm:col-span-2 col-span-3 " [ngStyle]="{'color':getInsightQuestionScoreColor(question)}">
                                                            <!-- +{{getQuestionScore(question,insight)}} Score -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </mat-expansion-panel>
                            </ng-container>               
                        </mat-accordion>
                    </div>
                </ng-container>
            </div>
        </div>

    <div  class="mt-5" *ngIf="all_docs.length>0">
        <div class="text-lg text-black">Information Source</div>
        <div class="text-[#64748B] text-md mt-1">View list of all the inputs and respective insights generated from the documents.</div>

        <div class="flex flex-wrap  -m-2 mt-6 filecont" *ngIf="all_docs.length>0">
            <ng-container *ngFor="let file of all_docs;i as index">
               <div class="flex flex-col" (click)="onSelectFile(file)">
                <div
                    class="flex flex-col w-40 h-40 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card"
                   >
                   <!-- <div class="w-full flex justify-end">
                    <button (click)="onRemoveFile(file.title)"><mat-icon class="icon-size-5 text-black"  [svgIcon]="'heroicons_solid:x-mark'" ></mat-icon></button>
                   </div> -->
                   
                    <div class="aspect-[9/6]">
                        <div class="flex items-center justify-center h-full">
                            <!-- Icons -->
                            <div class="relative">
                                <mat-icon
                                    class="icon-size-14 text-hint opacity-50"
                                    [svgIcon]="'heroicons_solid:document'"></mat-icon>
                                <div
                                    class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                    [ngClass]="getFileClass(file.type)"
                                   >
                                    {{getFileTypeString(file.type)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                        <div
                            class="truncate text-black"
                            [matTooltip]="file.title">{{file.title}}</div>
                    </div>
                </div>
                <!-- <div class="text-md mt-1 ml-1 w-40 text-center" [ngClass]="getDocumentScoreClass(file)" >+{{getDocumentContributionScore(file)}} Score</div> -->
            </div>
            </ng-container>

        </div>

        <div *ngIf="!all_docs || all_docs.length==0" class="text-lg text-black flex w-full text-center mt-5 justify-start">
            No documents found
        </div>

    </div>
</div>


<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'rewriteAIDrawer'"
    [position]="'right'" 
    (openedChanged)="openedChanged($event)"
    #insightDrawer>
    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex items-center justify-between mt-5 ml-3 mr-3">
            <ng-container>
                <div class="flex items-center justify-center">
                    <!-- <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon> -->

                    <ng-container *ngIf="selectedInsightQuestion && selectedInsightQuestion.valid">
                        <mat-icon class="text-primary" [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                    </ng-container>
                    <ng-container *ngIf="selectedInsightQuestion && !selectedInsightQuestion.valid">
                        <mat-icon class="text-hint" [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                    </ng-container>

                    <span class="ml-2 font-semibold">READY TO BE PROCESSED</span>
                </div>
            </ng-container>

            <div class="flex items-center">
                <!-- More menu -->
                <button *ngIf="(accessStatus=='admin')"
                    mat-icon-button
                    [matMenuTriggerFor]="moreMenu">
                    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
                </button>
                <mat-menu #moreMenu="matMenu">
                    <button
                        mat-menu-item
                        (click)="onDeleteInsightQuestion()"
                       >
                        <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                        <span>Delete Insight</span>
                    </button>
                </mat-menu>

                <!-- Close button -->
                <button
                    mat-icon-button
                    (click)="onCloseDrawer()"
                    >
                    <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
       
        <form
        class="flex flex-col flex-auto p-6 pt-10 sm:p-8 sm:pt-10"
        [formGroup]="insightForm">
            <div>
                <mat-form-field
                    class="w-full"
                    [subscriptSizing]="'dynamic'">
                    <mat-label class="font-semibold">Question</mat-label>
                    <textarea [readonly]="(accessStatus!='admin')"
                        matInput
                        [formControlName]="'title'"
                        autosize
                        ></textarea>

                </mat-form-field>
            </div>

            <!-- Business Capability -->
            <div class="mt-5 w-full">
                <mat-form-field class="w-full" *ngIf="selectedInsight!=null">
                    <mat-label class="font-semibold">Theme</mat-label>
                    <mat-select *ngIf="(accessStatus=='admin')" formControlName="capability" (selectionChange)="onChangeCapabilitySelection($event)">
                    @for (capability of capabilities; track capability) {
                        <mat-option  [value]="capability.value">{{capability.label}}</mat-option>
                    }
                    </mat-select>
                    <mat-select *ngIf="(accessStatus!='admin')" formControlName="capability" readonly>
                        @for (capability of capabilities; track capability) {
                            <mat-option readonly [value]="capability.value">{{capability.label}}</mat-option>
                        }
                        </mat-select>
                    <!-- <input matInput *ngIf="(accessStatus!='admin')" formControlName="capability" readonly> -->
                </mat-form-field>
            </div>

            <!-- Relevance Score -->
            <div class="mt-2 w-full">
                    <mat-label class="font-semibold">Input Quality Score</mat-label>
                    <div *ngIf="selectedInsightQuestion && selectedInsightQuestion._id!=''" class="text-md" [ngStyle]="{'color':getInsightScoreColor()}">+{{getSelectedInsightQuestionRelevanceScore()}}</div>
                    <div *ngIf="selectedInsightQuestion && selectedInsightQuestion._id==''" class="text-md" [ngStyle]="{'color': getInsightScoreColor()}">+{{selectedCapabilityScore}}</div>
            </div>

            <!-- Notes -->
            <div class="mt-5">
                <div class="w-full">
                    <mat-label class="font-semibold">Answer</mat-label>
                    <!-- <textarea
                        class="notes"
                        matInput
                        [formControlName]="'notes'"
                        [spellcheck]="false"
                        rows="6"
                        class="relative"
                        cdkTextareaAutosize>

                    </textarea> -->

                    <div class="relative summaryTextEd" *ngIf="(accessStatus=='admin'); else disabledInputanswer">
                        <tiptap-bubble-menu [editor]="editor" id="summary1">
                            <app-editor-controls 
                            [editor]="editor" 
                            [boxWidth]="'360px'"
                            [selectedText]="selectedText"
                            (completeQuickAction)="onCompleteQuickAction($event)">
                            </app-editor-controls>
                        </tiptap-bubble-menu>
                        <tiptap-editor [editor]="editor"
                            [formControlName]="'notes'"
                            (blur)="onTextareaBlur()"
                            (mouseenter)="onMouseEnter()" 
                            (mouseleave)="onMouseLeave()"
                            (mouseup)="onTextSelect($event,'notes')"
                            (select)="onSelectionChange($event,'notes')"
                            (mousedown)="onMouseDown($event)"
                            (keydown)="onSelectionChange($event,'notes')"
                            id="editor-with-bubble-menu" class="summarybox min-h-60">
                        </tiptap-editor>
                        <div class="flex supportDoc bg-transparent absolute bottom-0 left-1">
                            <button class="txt-medium h-8 bg-white ml-1 rounded-full px-3 py-1 flex items-center truncate" (click)="triggerFileInput()" >
                                <img class="w-5 h-5" src="assets/icons/upload_icon.svg" > Supporting Documents</button>

                                <button class="txt-medium h-8 bg-white ml-3 rounded-full px-3 py-1 flex items-center truncate" (click)="startSpeechRecognition()" >
                                    <img class="w-5 h-5" *ngIf="!recording" src="assets/icons/mic_icon.svg">
                                <mat-icon *ngIf="recording" svgIcon="heroicons_outline:stop" color="warn"></mat-icon>
                                <span *ngIf="!recording">Voice Record </span>
                                <span *ngIf="recording">Recording</span>
                                
                                </button>



                            <input type="file" accept=".jpg, .png, .pdf, .docx, .pptx, .txt, .csv"  #fileInput (change)="onFilesSelected($event)" style="display: none;" multiple>
                        </div>
                    </div>
                    <ng-template #disabledInputanswer>
                        <tiptap-editor #answerTextReadonly id="answerTextReadonly" [editor]="editor_readonly" [formControlName]="'notes'" >
                        </tiptap-editor>
                    </ng-template>
                </div>
                <div class="my-1 mb-2" *ngIf="sizeLimitAlert">
                    <fuse-alert
                    [appearance]="'soft'"
                    [showIcon]="false"
                    type="error"
                    [dismissible]="true" [dismissed]="false">
                    The maximum allowed file size is 20MB
                    </fuse-alert>
                </div>
            </div>

            <div class="my-4" *ngIf="isUploadingFiles || isProcessingFiles || isValidatingInsight || !hasProcessedLocalInsight">
                <div *ngIf="isUploadingFiles" class="text-[#64748B] text-sm mb-1">Uploading documents.</div>

                <div *ngIf="isProcessingFiles || !hasProcessedLocalInsight" class="text-[#64748B] text-sm mb-1">Analyzing documents and fetching answers.</div>
                <div *ngIf="isValidatingInsight && hasProcessedLocalInsight" class="text-[#64748B] text-sm mb-1">Analyzing insights and validating answers.</div>

                <mat-progress-bar
                    [mode]="'indeterminate'"
                    value="100"
                    ></mat-progress-bar>
            </div>

            <div class="flex flex-wrap  mt-5 filecont mb-5" *ngIf="selectedSupportingFiles.length">
                <ng-container *ngFor="let file of selectedSupportingFiles;i as index">
                    <div
                        class="flex flex-col w-36 h-36 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card"
                    >
                    <!-- <div class="w-full flex justify-end">
                        <button (click)="onRemoveFile(file.title)"><mat-icon class="icon-size-5 text-black"  [svgIcon]="'heroicons_solid:x-mark'" ></mat-icon></button>
                    </div> -->
                    
                        <div class="aspect-[9/6]">
                            <div class="flex items-center justify-center h-full">
                                <!-- Icons -->
                                <div class="relative">
                                    <mat-icon
                                        class="icon-size-14 text-hint opacity-50"
                                        [svgIcon]="'heroicons_solid:document'"></mat-icon>
                                    <div
                                        class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                        [ngClass]="getFileClass(file.type)"
                                    >
                                        {{getFileTypeString(file.type)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                            <div
                                class="truncate text-black"
                                [matTooltip]="file.title">{{file.title}}</div>
                        </div>
                    </div>
                </ng-container>
            
            </div>

            <div *ngIf="(accessStatus=='admin')" class="flex w-full justify-end mr-5 mt-5">
                <button
                [disabled]="isProcessingSelectedInsight()"
                (click)="onSaveInsightQuestion()"
                class="bg-[#4F45E4] text-white text-lg font-semibold mt-5 py-3 px-10 rounded-lg"
                [class.disabled]="isProcessingSelectedInsight()">
                Save
                </button>
            </div>

            <div class="my-1 mb-2" *ngIf="insightSaved">
                <fuse-alert
                [appearance]="'soft'"
                [showIcon]="false"
                type="success"
                [dismissible]="true" [dismissed]="false">
                Insight saved successfully!
            </fuse-alert>
            </div>
        </form>
    </div>
</fuse-drawer>

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'fileDrawer'"
    [position]="'right'" 
    (openedChanged)="openedChangedFileDrawer($event)"
    
    #filePropertiesDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card">

       

       


            <div class="flex items-center justify-between mt-5 ml-8 mr-8">

            <ng-container>
                <div class="flex items-center justify-center">
                    <img src="assets/icons/info_icon.svg" class="w-6 h-6">
                    <!-- <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon> -->
                    <span class="ml-2 font-semibold text-xl">Document Properties</span>
                </div>
            </ng-container>

            <div class="flex items-center">
                <!-- More menu -->
                <button *ngIf="(accessStatus=='admin')"
                    mat-icon-button
                    [matMenuTriggerFor]="documentMenu">
                    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
                </button>
                <mat-menu #documentMenu="matMenu">
                    <button
                        mat-menu-item
                        (click)="onDeleteSupportingDocument(selectedSupportingDoc)"
                       >
                        <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                        <span>Delete Document</span>
                    </button>
                </mat-menu>

                <!-- Close button -->
                <button
                    mat-icon-button
                    (click)="onCloseFileDrawer()"
                    >
                    <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>

            

        </div>




        <div *ngIf="selectedSupportingDoc"
                    class="flex flex-col w-36 h-36 ml-5 mt-5 p-4 shadow rounded-2xl cursor-pointer bg-card"
                   >
                   <!-- <div class="w-full flex justify-end">
                    <button (click)="onRemoveFile(selectedSupportingDoc.title)"><mat-icon class="icon-size-5 text-black"  [svgIcon]="'heroicons_solid:x-mark'" ></mat-icon></button>
                   </div> -->
                   
                    <div class="aspect-[9/6]">
                        <div class="flex items-center justify-center h-full">
                            <!-- Icons -->
                            <div class="relative">
                                <mat-icon
                                    class="icon-size-14 text-hint opacity-50"
                                    [svgIcon]="'heroicons_solid:document'"></mat-icon>
                                <div
                                    class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                    [ngClass]="getFileClass(selectedSupportingDoc.type)"
                                   >
                                    {{getFileTypeString(selectedSupportingDoc.type)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                        <div
                            class="truncate text-black"
                            >{{selectedSupportingDoc.title}}</div>
                    </div>
                </div>




        <div class="mx-5 mt-2 " *ngIf="selectedSupportingDoc">
        <div class="text-black font-semibold text-xl py-5">
            Information
        </div>

        <div class="bg-[#E2E8F0] line w-full "></div>

        
        <div class="flex justify-between py-3">
            <div class="text-[#64748B] text-lg font-medium">Created By</div>
            <div class="text-black text-lg font-medium">{{ (selectedSupportingDoc.author && selectedSupportingDoc.author.personal && selectedSupportingDoc.author.personal.name && selectedSupportingDoc.author.personal.name.first) ? selectedSupportingDoc.author.personal.name.first.replace('undefined', '').trim() : '' }} {{ (selectedSupportingDoc.author && selectedSupportingDoc.author.personal && selectedSupportingDoc.author.personal.name && selectedSupportingDoc.author.personal.name.last) ? selectedSupportingDoc.author.personal.name.last.replace('undefined', '').trim() : '' }}</div>
        </div>
        <div class="bg-[#E2E8F0] line w-full"></div>
        <div class="flex justify-between py-3">
            <div class="text-[#64748B] text-lg font-medium">Uploaded On</div>
            <div class="text-black text-lg font-medium">{{selectedSupportingDoc.created| date: 'MMMM dd, yyyy'}}</div>
        </div>
        <div class="bg-[#E2E8F0] line w-full"></div>
        <div class="flex justify-between py-3">
            <div class="text-[#64748B] text-lg font-medium">Size</div>
            <div class="text-black text-lg font-medium">{{getSupportingDocFileSize(selectedSupportingDoc)}}</div>
        </div>
        <div class="bg-[#E2E8F0] line w-full"></div>
        <div class="flex justify-between py-3">
            <div class="text-[#64748B] text-lg font-medium">Quality Score</div>
            <div class="text-black text-lg font-medium">{{getDocumentContributionScore(selectedSupportingDoc)}}</div>
        </div>
        <div class="bg-[#E2E8F0] line w-full"></div>

        <div class="text-black font-medium text-lg mt-8">Document Insights</div>
        <div class="content" *ngIf="(accessStatus=='admin'); else disabledDocumentInsight">
            <tiptap-editor [editor]="editor2" [ngModel]="getSupportingDocSummaryFormatted(selectedSupportingDoc.summary)" class="p-5 min-h-60"  min="8" max="12"></tiptap-editor>
        </div>
        <ng-template #disabledDocumentInsight>
            <tiptap-editor #documentTextReadonly id="documentTextReadonly" [editor]="editor2_readonly" [ngModel]="getSupportingDocSummaryFormatted(selectedSupportingDoc.summary)" >
            </tiptap-editor>
        </ng-template>
    </div>
    </div>

</fuse-drawer>