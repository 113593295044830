import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-quick-prompt-tip',
  standalone: true,
  imports: [MatIcon,NgIf],
  templateUrl: './quick-prompt-tip.component.html',
  styleUrl: './quick-prompt-tip.component.scss'
})
export class QuickPromptTipComponent implements OnInit {
  ngOnInit(): void {
    console.log("Visible:",localStorage.getItem("promptTip"));
    if(localStorage.getItem("promptTip")){
   this.isVisible= localStorage.getItem("promptTip")=="1"?true:false;
    }else{
      localStorage.setItem("promptTip","1");
      this.isVisible=true;
    }
  }

  isVisible:boolean=true;

  onClose(){
    this.isVisible=false;
    localStorage.setItem("promptTip",this.isVisible?"1":"0");
  }
}
