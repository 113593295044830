import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor, NgIf, NgTemplateOutlet,NgClass, JsonPipe, NgStyle} from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input,Output,SimpleChanges,ViewChild,viewChild,ElementRef, Renderer2 } from '@angular/core';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor, ReactiveFormsModule, UntypedFormGroup, UntypedFormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerService } from '@fuse/components/drawer';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { marked } from 'marked';
import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor, HTMLElement } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';

import { ProposalService } from 'app/core/proposal/proposal.service';
import { AddEpicComponent } from '../dialogs/add-epic/add-epic.component';
import { AddThemeComponent } from '../dialogs/add-theme/add-theme.component';
import { AddFeatureComponent } from '../dialogs/add-feature/add-feature.component';
import { CommonService } from 'app/core/common/common.service';
import { filter, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { FeatureComponent } from '../feature/feature.component';
import { NpsComponent } from '../../nps/nps.component';
import { DOMSerializer } from '@tiptap/pm/model';
import { StoryTableComponent } from 'app/modules/user/common/story-table/story-table.component';
import { TestCaseTableComponent } from 'app/modules/user/common/test-case-table/test-case-table.component';
import { AiEditorComponent } from 'app/modules/user/common/ai-editor/ai-editor.component';
import { ConfigurationComponent } from "../../common/configuration/configuration.component";
import { AtlassianService } from 'app/core/common/atlassian.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { JiraIntegrationComponent } from 'app/modules/common/jira-integration/jira-integration.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RegenerateInsightsViewComponent } from "../dialogs/regenerate-insights-view/regenerate-insights-view.component";
import { RegeneratingProgressBarComponent } from "../../../common/regenerating-progress-bar/regenerating-progress-bar.component";
import { I } from '@angular/cdk/keycodes';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-solution-drawer',
  standalone: true,
  imports: [MatProgressSpinnerModule, NgIf, FormsModule,SolutionDrawerComponent, NgxTiptapModule, AddThemeComponent, StoryTableComponent, ConfigurationComponent, TestCaseTableComponent, EditorControlsComponent, NgClass, AddFeatureComponent, JsonPipe, FuseDrawerComponent, NgTemplateOutlet, AddEpicComponent, MatFormFieldModule, NgFor, MatSelectModule, ReactiveFormsModule, MatIcon, MatInputModule, MatButtonModule, MatExpansionModule, DragDropModule, MatSelectModule, AddThemeComponent, FuseAlertComponent, RegenerateInsightsViewComponent, RegeneratingProgressBarComponent],
  templateUrl: './solution-drawer.component.html',
  styleUrl: './solution-drawer.component.scss'
})
export class SolutionDrawerComponent {

  selectedSolution:any = {};
  _proposalDetail:any = {};
  solutionSet: boolean = false;
  proposalSet: boolean = false;
  isSavingSolution: boolean = false;
  flashMessage: string;
  showModal;
  flashMessageType: 'success' | 'error' | null = null;
  themeTypes: any[] =[];

  @Input() set proposal(value: any) {
    if (value) {
      this._proposalDetail = value;
      this.proposalSet = true;
      this.checkInitialization();
      if(this._proposalDetail.epics && this._proposalDetail.epics.length){
        for(let epic of this._proposalDetail.epics){
          this.themeTypes.push({value:epic.theme.trim(),label:epic.theme.trim()})
        }
      }
    }
  }

  @Input() set solutionValue(value: any) {
    if (value) {
      this.selectedSolution = value;
      this.solutionSet = true;
      this.checkInitialization();
    }
  }

  @Input() epicTypes:any=[];
  @Input() is_write_allowed;
  @Input() _page_id;
  @Input() releases_list;
  @Input() complexities;
  @Output() solutionValueChange = new EventEmitter<any>();
  newSolutionForm:UntypedFormGroup;
  quickSelectedText="";
  insideTextArea=false;
  mouseDownPos=0;
  show_view_count=false;
  quickSelectionStartIndex=-1;
  quickSelectionEndIndex=-1;
  selectedText: string = '';
  selectedTargetIdEditor: string = '';
  quickAction: boolean = false;
  mouseInitialPosition=null;
  isQuickMenuHighlighted:boolean=false;
  showQuickPromptMenu = false;
  selectedIndex: number;
  menuPosition = { top: '500px', left: '80px' };
  private selectedFeatureData={
    type:"",
    index:0
  }
  solutionreasonEditor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short solution reason.',
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'solutionReason',content);
    }
  });
  featureDescriptionEditor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a short solution description.',
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.captureChangeSummary({}, 'featureDescription',content);
    }
  });

  constructor(private _formBuilder: UntypedFormBuilder,
    private _commonService:CommonService,
    public dialog:MatDialog, 
    private _proposalService:ProposalService,
    private websocketService:WebsocketService,
    private _changeDetectorRef: ChangeDetectorRef,
    private atlassianService:AtlassianService){
      this.newSolutionForm = this._formBuilder.group({
        _id:["",Validators.required],
        epicName:["",Validators.required],
        name:["",Validators.required],
        theme:["",Validators.required],
        epic:["",Validators.required],
        complexity:["",Validators.required],
        release:["",Validators.required],
        componentsCount:[0,Validators.required],
        effort:[0,Validators.required],
        featureDescription: ["",Validators.required],
        explanation: [""],
        solutionAssumption: ["",Validators.required],
        cloud: [""],
        masterFeatureID: [""],
        solutionCategories: [],
        themeID: [],
        epicID: [],
      });

  }

  private checkInitialization() {
    if (this.proposalSet && this.solutionSet) {
      this.initializeComponent();
      this.proposalSet = false;
      this.solutionSet = false;
    }
  }

  initializeComponent(){
    this._changeDetectorRef.detach();
    this.onChangeTheme({value:this.selectedSolution.theme});
    // console.log(this.selectedSolution);
    setTimeout(() => {
      this.newSolutionForm.patchValue(this.selectedSolution, { emitEvent: false });
      this._changeDetectorRef.reattach();
      this._changeDetectorRef.detectChanges();
    }, 15);
  }

  onChangeTheme(event){
    let selectedThemes=event.value;
    if(selectedThemes.length){
      this.epicTypes=[];
      this.newSolutionForm.get('epic').setValue('');
      for(let epic of this._proposalDetail.epics){
        if(selectedThemes.includes(epic.theme)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic.name };
          });
          this.epicTypes.push(...epics);
        }
      }
    }else{
      this.resetToAllEpics();
    }
  }

  onComponentsCountChange(value: any): void {
    let effort_val = 0;
    if(this.selectedSolution.masterFeatureID){
      if(this.selectedSolution.complexity=='Low')effort_val = this.selectedSolution.masterFeatureID['lowEffort'];
      else if(this.selectedSolution.complexity=='Medium')effort_val = this.selectedSolution.masterFeatureID['mediumEffort'];
      else if(this.selectedSolution.complexity=='High')effort_val = this.selectedSolution.masterFeatureID['highEffort'];
      let calcVal = this.selectedSolution.componentsCount*effort_val;
      this.selectedSolution['effort'] = calcVal;
    }
  }

  resetToAllEpics(){
    this.epicTypes = this._proposalDetail.epics?.flatMap((epic) => {
      return epic.epics.map((themeEpic) => {
        return { label: themeEpic.name, value: themeEpic.name };
      });
    }) ?? []; 
  }

  updateSelectedFeature(): void
  {
    this.isSavingSolution = true;
    this._proposalService.update({id:this._proposalDetail._id,feature_action:'update',feature_data:this.newSolutionForm.value})
          .subscribe(
            (value) =>
            {
              if(value.status){
                this.solutionValueChange.emit(this.newSolutionForm.value); 
                this.showFlashMessage('success');
                this.flashMessage = 'Solution updated successfully!';
                if(this.showModal){
                  const dialogRef = this.dialog.open(NpsComponent,{ 
                    panelClass: 'custom-dialog-container-nps',
                    disableClose: true,
                    data: {
                      category: this._proposalDetail.category
                    }
                  });
                }
              }
              else this.showFlashMessage('error');
              this.isSavingSolution = false;
            },
            (response) =>
            {
            },
        );
  }

  showFlashMessage(type: 'success' | 'error'): void
  {
      // Show the message
      this.flashMessageType = type;
      // Hide it after 3 seconds
      setTimeout(() =>
      {
          if(this.flashMessageType=='error' && this.dialog)this.dialog.closeAll();
          this.flashMessageType = null;
      }, 3000);
  }

  updateSelectedText(editor: Editor): void {
    const { from, to } = editor.state.selection;
    const selectedFragment = editor.state.doc.slice(from, to);
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const div = document.createElement('div');
    selectedFragment.content.forEach(node => {
        div.appendChild(serializer.serializeNode(node));
    });
    const selectedHTML = div.innerHTML;
    this.quickSelectionStartIndex = from;
    this.quickSelectionEndIndex = to;
    this.selectedText = selectedHTML;
  }

  captureChangeSummary(event: any,type,content): void {
    console.log("On Update :",type);
    let obj = {id: this._proposalDetail._id};
    
  }

  onCompleteQuickAction(newValue){
    console.log("Value to update:",newValue);
    console.log("Selection:",this.quickSelectedText);
    console.log("selectedIndex:",this.selectedIndex,this.selectedTargetIdEditor);
    console.log(this.quickSelectionStartIndex,this.quickSelectionEndIndex);
    
    let obj = {id: this._proposalDetail._id};
    if(this.selectedIndex==0 || !this.selectedIndex){
      if(this.selectedTargetIdEditor=='solutionreason'){
        const { from, to } = this.solutionreasonEditor.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.solutionreasonEditor.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.solutionreasonEditor.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.solutionreasonEditor.view.dispatch(tr);
        this.solutionreasonEditor.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['solutionAssumption'] = this.newSolutionForm.get('solutionAssumption').value;
        this.captureChangeSummary({}, 'solutionAssumption','');
      }
      else if(this.selectedTargetIdEditor=='featureDescription'){
        const { from, to } = this.featureDescriptionEditor.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.featureDescriptionEditor.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.featureDescriptionEditor.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.featureDescriptionEditor.view.dispatch(tr);
        this.featureDescriptionEditor.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        obj['featureDescription'] = this.newSolutionForm.get('featureDescription').value;
        this.captureChangeSummary({}, 'featureDescription','');
      }
    }else if(this.selectedIndex==1){
      
    }
    this.showQuickPromptMenu=false;
    this.isQuickMenuHighlighted=false;
    document.getSelection().removeAllRanges();   
  }

  onTextareaBlur(){
    if(!this.insideTextArea && !this.isQuickMenuHighlighted){
        this.showQuickPromptMenu=false;
    }
  }

  onMouseEnter(){
    this.insideTextArea=true;
  }

  onMouseLeave(){
    this.insideTextArea=false;
  }

  onMouseEnterQuickPrompt(){
    this.isQuickMenuHighlighted=true;
  }

  onMouseLeaveQuickPrompt(){
    this.isQuickMenuHighlighted=false;
  }

  onClickOutside(event){
     if(!this.isQuickMenuHighlighted){
    this.isQuickMenuHighlighted=false;
    this.showQuickPromptMenu=false;
     }
  }

  onMouseDown(event){
    if(this.isQuickMenuHighlighted){
      return;
    }
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    if(!this.showQuickPromptMenu){
      const screenWidth = window.innerWidth;
      const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;

      this.menuPosition = {
        top: `${event.pageY - 120}px`,
        left: `${xPosition}px`
      };
      this.mouseDownPos=event.pageY-120;
    }
  }

  onSelectionChange(event,targetId){
    if(!this.is_write_allowed){
      return;
    }
    const textarea = event.target as HTMLTextAreaElement;
    this.quickSelectionStartIndex=textarea.selectionStart;
    this.quickSelectionEndIndex=textarea.selectionEnd;
    
    if(targetId.includes("description")||targetId.includes("solution")){
      let data=targetId.split("-");
      this.selectedFeatureData={
        type:data[0],
        index:parseInt(data[1])
      };
    }else{
      this.selectedFeatureData={
        type:"",
        index:0
      };
    }
    
    let clientY=0
    if(this.mouseInitialPosition!=null){
      clientY=this.mouseInitialPosition;
    }
    
    if (this.quickSelectedText.trim().length > 0) {
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    }
  }

  onTextSelect(event,targetId){ 
    // console.log("Event:",event,targetId);
    if(!this.is_write_allowed){
      return;
    }
    this.selectedTargetIdEditor = targetId;
    this.selectedFeatureData={
      type:"",
      index:0
    };
    // console.log('selectedFeatureData', this.selectedFeatureData);
    this.mouseInitialPosition=event.clientY;
    //This code place menu at bottom of selection
    const textarea = event.target as HTMLTextAreaElement;

    const selection = window.getSelection();
    if (selection && selection.toString().trim().length > 0) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        let last=event.pageY-120;
        if(last<this.mouseDownPos){
          const screenWidth = window.innerWidth;
          const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;

        this.menuPosition = {
            top: `${last}px`,
            left: `${xPosition}px`
        };

        }
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    }
  }

}
