<div class="flex flex-col flex-auto min-w-0 relative" >
       
        <!-- <span class="close-dialog" [mat-dialog-close]="true">
            <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
        </span> -->
        <!-- Main -->
        <div class="flex-auto">
            <div class="formBox">
                <div class="formHead" >
                    <h4 class="text-2xl">User Story {{getStoryID()}}</h4>
                    <div class="flex gap-2 items-center">
                    <button *ngIf="(userStory._id && proposal.integrations.jira.site && is_write_allowed)" (click)="(is_write_allowed?viewStoryInJira(userStory):'')" class="jiraBt">
                        <img src="assets/icons/jirablue.png" alt="">
                        <span>View in JIRA</span>
                    </button>
                    <span class="close-dialog2 cursor-pointer"  [mat-dialog-close]="true">
                        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
                    </span>
                    </div>
                </div>
                
                <div class="separator"></div>


                <form action="" class="mainform" [formGroup]="newStoryForm">
                    <div class="desc flex flex-col">
                        <label class="font-medium ">Title*</label>
                        <textarea name="" [formControlName]="'title'" id="" class="border mt-2 rounded w-full p-3" row="2" style="resize: none;" ></textarea>
                    </div>
                    <!-- <mat-form-field class="w-full">
                        <mat-label>Title</mat-label>
                        <textarea name="" id=""></textarea>
                        <input [formControlName]="'title'" matInput >
                    </mat-form-field> -->

                    <div class="flex mt-6 flex-wrap justify-between">
                        <div class="fir flex gap-6 flex-wrap">
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                                <mat-label>Theme</mat-label>
                                <mat-select [placeholder]="'Select Theme'" [formControlName]="'theme'"  class="selectOption2" (selectionChange)="onChangeTheme($event)">
                                        @for (theme of themeTypes;track theme){
                                             
                                                <mat-option [value]="theme.value">{{theme.label}}</mat-option>
                                             
                                        }
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                                <mat-label>Epic</mat-label>
                                <mat-select [placeholder]="'Select Epic'" [formControlName]="'epic'"  class="selectOption2">
                                    @for(epic of epicTypes;track epic){
                                        <mat-option [value]="epic.value">{{epic.label}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                                <mat-label>Release</mat-label>
                                <mat-select [placeholder]="'Select Release'" [formControlName]="'release'"  class="selectOption2">
                                    @for (release of releaseTypes;track release){
                                        <mat-option [value]="release.value">{{release.label}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="sec prioritySec">
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                                <mat-label>  Priority</mat-label>
                                <mat-select  [placeholder]="'Select Priority'" [formControlName]="'priority'" (selectionChange)="updatePriority($event)"  class="selectOption" #proritySelect="matSelect">
                                <mat-select-trigger>
                                    <div class="flex gap-1 items-center">
                                        <img [src]="getPriority(newStoryForm.get('priority').value).icon" class="w-5 h-5">{{getPriority(newStoryForm.get('priority').value).label}}
                                    </div> 
                                </mat-select-trigger>

                                <ng-container *ngFor="let priority of priorityTypes;">
                                    <mat-option [(value)]="priority.value">
                                        <div class="flex gap-1 items-center"><img class="w-3 h-3" [src]="priority.icon"><span>{{priority.label}}</span></div>  
                                    </mat-option>
                                </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="flex justify-between flex-wrap">
                        <div class="tagSec mt-6">
                            <h5 class="text-md font-medium">Tags</h5>
                            <div class="flex flex-wrap items-center ">
                                <!-- Tags -->
                                <ng-container *ngIf="tagArray.controls.length">
                                    <ng-container *ngFor="let eachtag of tagArray.controls; let i = index">
                                        <div class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                                            <span class="text-md text-black whitespace-nowrap">{{eachtag.value}}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <div
                                    class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                                    (click)="openTagsPanel()"
                                    #tagsPanelOrigin>
                                    <ng-container *ngIf="tagArray.controls.length">
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                                        <span class="ml-1.5 text-md font-medium whitespace-nowrap">Edit</span>
                                    </ng-container>
        
                                    <ng-container *ngIf="!tagArray.controls.length">
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                        <span class="ml-1.5 text-md font-medium whitespace-nowrap">Add</span>
                                    </ng-container>
                
                                    <!-- Tags panel -->
                                    <ng-template #tagsPanel>
                                        <div class="w-60 rounded border shadow-md bg-card mt-8">
                                            <!-- Tags panel header -->
                                            <div class="flex items-center m-3 mr-2">
                                                <div class="flex items-center">
                                                    <mat-icon
                                                        class="icon-size-5"
                                                        [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                                    <div class="ml-2">
                                                        <input
                                                            class="w-full min-w-0 py-1 border-0"
                                                            type="text"
                                                            placeholder="Enter Tags"
                                                            (input)="filterTags($event)"
                                                            (keydown)="filterTagsInputKeyDown($event)"
                                                            [maxLength]="30"
                                                            #newTagInput>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
                                                <!-- Tags -->
                                                <ng-container *ngIf="!tagsEditMode">
                                                    <ng-container *ngFor="let eachtag of filteredTags; trackBy: trackByFn">
                                                        <div
                                                            class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                                                            (click)="toggleTaskTag(eachtag)"
                                                            matRipple>
                                                            <mat-checkbox
                                                                class="flex items-center h-10 min-h-10 pointer-events-none"
                                                                [checked]="tagArray.value.includes(eachtag.label)"
                                                                [color]="'primary'"
                                                                [disableRipple]="true">
                                                            </mat-checkbox>
                                                            <div class="ml-1">{{eachtag.label}}</div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <div
                                                    class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer hover:bg-hover"
                                                    *ngIf="shouldShowCreateTagButton(newTagInput.value)"
                                                    (click)="createTag(newTagInput.value); newTagInput.value = ''"
                                                    matRipple>
                                                    <mat-icon
                                                        class="mr-2 icon-size-5"
                                                        [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                                    <div class="break-all">Create "<b>{{newTagInput.value}}</b>"</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>

                        <div class="statusSec mt-6">
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                                <mat-label>Status</mat-label>
                                <mat-select [placeholder]="'Select Status'" [formControlName]="'status'"  class="selectOption" [ngClass]="{ 'todoTag': (getStatusString(newStoryForm.get('status').value) == 'To Do') , 'inprogTag': (getStatusString(newStoryForm.get('status').value) == 'In Progress')  ,'doneTag': (getStatusString(newStoryForm.get('status').value) == 'Done') }">
                                    @for(status of statusTypes;track status){
                                    <mat-option [(value)]="status.value">{{status.label}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                    </div>

                    <!-- Description editor -->
                    <div class="desc flex flex-col mt-6">
                        <label class="font-medium mb-2">Description*</label>
                        <div class="summarybox border border-gray-300 rounded-lg bg-white">
                            <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                                <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" 
                                        (click)="toggleBold('editor1')">
                                    <mat-icon class="icon-size-4">format_bold</mat-icon>
                                </button>
                                <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" 
                                        (click)="toggleItalic('editor1')">
                                    <mat-icon class="icon-size-4">format_italic</mat-icon>
                                </button>
                                <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" 
                                        (click)="toggleBulletList('editor1')">
                                    <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                                </button>
                            </div>
                            <tiptap-editor [editor]="editor" [formControlName]="'description'" id="tiptap-editor" placeholder="Start typing here...">
                            </tiptap-editor>
                        </div>
                    </div>



                    <section>

                        <div class="desc flex flex-col mt-6">
                            <label class=" font-medium mb-2">Low Level Solution Summary</label>
                            <div class="summarybox border border-gray-300 rounded-lg bg-white">
                                <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                                    <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" 
                                            (click)="toggleBold('editor2')">
                                        <mat-icon class="icon-size-4">format_bold</mat-icon>
                                    </button>
                                    <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" 
                                            (click)="toggleItalic('editor2')">
                                        <mat-icon class="icon-size-4">format_italic</mat-icon>
                                    </button>
                                    <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" 
                                            (click)="toggleBulletList('editor2')">
                                        <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                                    </button>
                                </div>
                              
                                <!-- TipTap editor component -->
                                <tiptap-editor 
                                  [editor]="editor2"
                                  [formControlName]="'lowLevelSolutionSummary'"
                                  id="tiptap-editor2"
                                  placeholder="Start typing here...">
                                </tiptap-editor>
                            </div>
                    </div>


                    </section>






                    <div class="border min-h-20 mt-6 p-2 rounded uploadSec">
                        <div class="head flex justify-between">
                            <div class="content">
                                <h4 class="font-medium text-lg">Attachments</h4>
                                <p class="text-[#9AA6B8] text-sm">Seamlessly attach documents, images, and links to enhance your workflow</p>
                                <!-- @for(attachment of fileAttachments;track attachment){
                                    <div class="flex justify-between mt-4">{{attachment.fileName}}</div>
                                } -->
                            </div>
                            <input type="file" accept=".jpg, .png, .pdf, .docx, .pptx, .txt, .csv"  #fileInput (change)="onFilesSelected($event)" style="display: none;" multiple>

                            <button *ngIf="is_write_allowed" (click)="onClickSelectFile()" class="border h-8 flex items-center pl-3 pr-3 rounded text-md uploadBt"> <mat-icon class="icon-size-5" svgIcon="heroicons_outline:arrow-up-tray"></mat-icon> Upload File</button>
                            <div class="my-1 mb-2" *ngIf="sizeLimitAlert">
                                <fuse-alert
                                [appearance]="'soft'"
                                [showIcon]="false"
                                type="error"
                                [dismissible]="true" [dismissed]="false">
                                The maximum allowed file size is 20MB
                                </fuse-alert>
                            </div>
                        </div>
                        <!-- file list -->
                        <div class="filelist p-3 flex flex-wrap" *ngIf="fileAttachments && fileAttachments.length">
                            @for(attachment of fileAttachments;track attachment){
                            <div class="flex gap-2 items-center listBox p-2">
                                <div class="relative inline">                     
                                    <mat-icon class="icon-size-8" matPrefix svgIcon="heroicons_outline:document"></mat-icon>
                                    <div class="typeText absolute bottom-0 left-0 rounded px-1.5 text-xs font-semibold leading-5 text-white" [ngClass]="getFileClass(attachment)"> {{getFileTypeString(attachment)}} </div>
                                </div>
                                <span  class="flex gap-2 items-center">{{attachment.fileName}} <mat-icon (click)="onRemoveFile(attachment)" class="icon-size-5 cursor-pointer" svgIcon="heroicons_outline:trash"></mat-icon></span>
                            </div>
                        }
                        </div>
                    </div>

                    @if (false) {
                    <div class="border min-h-20 mt-6 p-2 rounded dependSec">
                        <div class="head flex justify-between">
                            <div class="content">
                                <h4 class="font-medium text-lg">Dependencies</h4>
                                <p class="text-[#9AA6B8] text-sm">Seamlessly attach documents, images, and links to enhance your workflow</p>
                            </div>
                            <button class="dependBt border h-8 items-center pl-3 pr-3 rounded text-md "> <mat-icon class="icon-size-3"  svgIcon="heroicons_outline:arrows-right-left"></mat-icon> Dependencies</button>
                        </div>

                        <!-- add Dependencies -->
                        <div class="dependCont flex gap-4 items-center mt-4">
                            <div class="linkedTo">
                                <mat-form-field class="flex-auto gt-xs:pl-3 w-full ">
                                    <mat-select [placeholder]="'Linked to'" >
                                            <mat-option>Blocks</mat-option>
                                            <mat-option>Depends On</mat-option>
                                            <mat-option>Duplicate</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <mat-icon class="icon-size-5"  svgIcon="heroicons_outline:arrows-right-left"></mat-icon>
                            <div class="userStory">
                                <mat-form-field class="flex-auto gt-xs:pl-3 w-full ">
                                    <mat-select [placeholder]="'User Story'" multiple>
                                            <mat-option>User Story 1</mat-option>
                                            <mat-option>User Story 2</mat-option>
                                            <mat-option>User Story 3</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="dependList">
                            <div class="listRow">
                                <h4 class="">Depends On</h4>
                                <mat-icon class="icon-size-5"  svgIcon="heroicons_outline:arrows-right-left"></mat-icon>
                                <div class="listTag">
                                    <div class="flex gap-2 items-center innerListTag">
                                        <span>User Story 1</span>
                                        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:x-mark"></mat-icon>
                                    </div>
                                    <div class="flex gap-2 items-center innerListTag">
                                        <span>User Story 2</span>
                                        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:x-mark"></mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="dependAct flex justify-end gap-4">
                            <button  mat-flat-button [color]="'accent'" class="mt-6" > cancel</button>
                            <button  mat-flat-button [color]="'primary'" class="mt-6" > Add Link</button>
                        </div>

                    </div>
                }


                    <div class="formAct flex justify-between mb-8 items-center">
                        <div class="border flex gap-2 items-center mt-6 p-2 userinfo" *ngIf="userStory._id!=''">
                            <img
                            class=" rounded-full"
                            *ngIf="getAuthorProfileImage()!=''"
                            [src]="getImageURL(userStory.author.meta.profileImage)" appUrl>
                        <mat-icon
                        *ngIf="getAuthorProfileImage()==''"
                            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>

                            <p class=""> {{getCreatedByInfo()}}</p>
                        </div>
                        <button *ngIf="is_write_allowed" mat-flat-button [color]="'primary'" [disabled]="!newStoryForm.valid || isUploadingFiles" class="mt-6" (click)="onSaveStory()" >
                            <div class="flex gap-1 items-center">
                            <mat-progress-spinner *ngIf="isSavingStory" mode="indeterminate"></mat-progress-spinner> <span>Save</span>
                            </div>
                        </button>
                    </div>


                </form>

            </div>
        </div>
     
    </div>