import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EventService } from 'app/core/common/event.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { filter, Subscription } from 'rxjs';


@Component({
  selector: 'app-regenerating-progress-bar',
  standalone: true,
  imports: [MatIconModule,MatProgressBarModule,NgStyle],
  templateUrl: './regenerating-progress-bar.component.html',
  styleUrl: './regenerating-progress-bar.component.scss'
})
export class RegeneratingProgressBarComponent {

  progressValue=100;
  private subscription: Subscription;

  generating:string="Initializing Agents";
  eventsCaptured=[];
  isProcessing:boolean=false;
  currentagentid:string;

  @Input()
  type=0;

  @Input()
  set restart(value: boolean) {
     // Add logic here if needed, for example:
     if (value) {
        this.init();
     }
  }
  private _restart: boolean;
  
  get restart(): boolean {
     return this._restart;
  }
  @Input() set generatingtitle(value: any) {
    if (value) {
      this.generating='Extracting ' + value;
    }
  }
  @Input() set agentid(value: any) {
    if (value) {
      this.currentagentid=value;
    }
  }

  constructor(private websocketService:WebsocketService,private eventService: EventService){

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "epic_solutions_regenerated")
    ).subscribe((message:any) => {
      console.log("Epic Solutions Regenerated:",message);
      if(!this.eventsCaptured.includes("solutions")){
      this.eventsCaptured.push("solutions");
      this.generating="Regenerating Solutions";
      }
    });


    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "features_regenerated")
    ).subscribe((message:any) => {
      console.log("Features Regenerated:",message);
      if(!this.eventsCaptured.includes("features")){
      this.eventsCaptured.push("features");
      this.generating="Regenerating Features";
      
      }
      
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "stories_regenerated")
    ).subscribe((message:any) => {
      console.log("Stories Regenerated:",message);
      if(!this.eventsCaptured.includes("stories")){
      this.eventsCaptured.push("stories");
      this.generating="Regenerating User Stories";
      
      }
      
      
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "testcases_regenerated")
    ).subscribe((message:any) => {
      console.log("Test Cases Regenerated:",message);
      if(!this.eventsCaptured.includes("testcases")){
      this.eventsCaptured.push("testcases");
      this.generating="Regenerating Test Cases";

      }
    });
    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "metadata_list_generated")
    ).subscribe((message) => {
      if(message.taskID==this.currentagentid && !this.eventsCaptured.includes("metadata_list_generated")){
        this.eventsCaptured.push("metadata_list_generated");
        this.generating="Extracting Metadata";
      }
    });
    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "validate_associated_doc")
    ).subscribe((message) => {
      // console.log(message);
      if(message.taskID==this.currentagentid && !this.eventsCaptured.includes("validate_associated_doc")){
        this.eventsCaptured.push("validate_associated_doc");
        this.generating="Extracting Metadata";
      }
    });
    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "create_vector")
    ).subscribe((message) => {
      // console.log(message);
      // if(message.data)this.generating=message.data['fullName'] + ' extraction done';
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "processing_metadata")
    ).subscribe((message) => {
        // console.log("Received:", message);
        if(message.taskID==this.currentagentid && message.data)this.generating='Extracting ' + message.data['metadataName'];
    });

    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "processing_metadata_complete")
    ).subscribe((message) => {
        // console.log("Received:", message);
        if(message.taskID==this.currentagentid && message.data)this.generating='Extraction finished';
    });
     
  }

  init(){
    //Type 0=proposal , 1=project , 2=testing
    this.eventsCaptured=[];
    this.generating="Initializing Agents";
    
     
  }



  initProgress(){


    let completed=0;

      if(this.type==0){
        //Proposal
        this.progressValue=this.eventsCaptured.length*0.5;

      }else if(this.type==1){
        //Project
        this.progressValue=this.eventsCaptured.length*0.33;
      }else{
        //test plan
        this.progressValue=this.eventsCaptured.length*0.25;
      }

      this.progressValue=Math.min(
        Math.ceil(completed),
        99
      );

  }


  delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

}
