<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'addEpicDrawer'"
    [position]="'right'"
    (openedChanged)="openedChanged($event)"

    #addEpicDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card">
 


            <div class="flex items-center justify-between mt-5 ml-3 mr-3">

            <ng-container>
                <div class="flex items-center justify-center">
                    <ng-container>
                        <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                    </ng-container>
                    

                    <span class="ml-2 font-semibold text-lg text-black">{{ isEdit?'Edit':'Add' }} Epic</span>
                </div>
            </ng-container>

            <div class="flex items-center">
                <!-- More menu -->
                <button
                    *ngIf="false"
                    mat-icon-button
                    [matMenuTriggerFor]="moreMenu">
                    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
                </button>
                <mat-menu #moreMenu="matMenu">
                    <button
                        mat-menu-item
                        (click)="onDeleteEpic()"
                       >
                        <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                        <span>Delete Epic</span>
                    </button>
                </mat-menu>

                <!-- Close button -->
                <button
                    mat-icon-button
                    (click)="addEpicDrawer.toggle()"
                    >
                    <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>

            

        </div>
       
        <form
        class="flex flex-col flex-auto p-6 pt-10 sm:p-8 sm:pt-10"
        [formGroup]="themeForm">
       
         <div>
            <mat-form-field
                class="w-full"
                >
                <mat-label class="font-semibold subtitle text-black">Epic Name</mat-label>
                <input
                    matInput
                    [formControlName]="'epicName'"
                    >

            </mat-form-field>
        </div>

        <!-- Business Capability -->
        <div class="mt-5 w-full">
        <mat-form-field class="w-full">
            <mat-label class="font-semibold">Theme</mat-label>
            <mat-select formControlName="theme" (selectionChange)="onChangeThemeSelection($event)">
              @for (theme of themeTypes; track theme) {
                <mat-option [disabled]="isEdit"  [value]="theme.value">{{theme.label}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>


        <div class="flex w-full justify-end mr-5 mt-5">
            <button
              mat-flat-button
              [disabled]="isSavingEpic || themeForm.invalid"
              (click)="onSaveEpicEvent()"
              class="bg-[#4F45E4] text-white text-lg font-semibold mt-5 py-3 px-10 rounded-lg"
              [class.disabled]="isSavingEpic || themeForm.invalid">
              Save
            </button>
          </div>
          <div class="my-1 mb-2" *ngIf="epicSaved">
            <fuse-alert
            [appearance]="'soft'"
            [showIcon]="false"
            type="success"
            [dismissible]="true" [dismissed]="false">
            Epic saved successfully!
        </fuse-alert>
        </div>
        

</form>
    </div>

</fuse-drawer>
