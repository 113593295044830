<div class="flex flex-col flex-auto min-w-0 relative shareBox">
    <span class="close-dialog" [mat-dialog-close]="true" >
        <mat-icon svgIcon="heroicons_solid:x-mark" color="black"></mat-icon>
    </span>
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10 m-p-0">
        <div class="formBox">
            <div class="flex font-bold justify-center items-center text-4xl featureHead">
                <mat-icon svgIcon="heroicons_outline:user-group" class="icon1 text-accent"></mat-icon>
                <h2>Share {{_page_id=='proposal'?'Proposal':'Project'}}</h2>
            </div>
            
            <div class="w-full max-w-3xl mt-6 inputB">
                <form
                class="mt-8"
                [formGroup]="shareForm" #shareNgForm="ngForm">
                    <!-- Add team member -->
                    <div class="w-full inputinner">
                        <h3 class="text-2xl">Invite Users</h3>
                        <p class="mt-2">Share {{_page_id=='proposal'?'proposals':'projects'}} with peers and customers for comprehensive review and feedback.</p>
                        <mat-form-field class="w-full mt-4">
                            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'" matPrefix></mat-icon>
                            <input matInput [placeholder]="'Email Address'" [formControlName]="'email'">
                            <mat-progress-spinner
                                *ngIf="loader_invite"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                            <button mat-icon-button matSuffix (click)="invite()">
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                            </button>
                            <mat-error *ngIf="shareForm.get('email').hasError('email')">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
                
                <fuse-alert *ngIf="showAlert" class="mt-4" [type]="alert.type" [appearance]="'soft'" [dismissible]="true" [dismissed]="false" [showIcon]="true">{{alert.message}}
                </fuse-alert>
                <!-- Team members -->
                <div class="flex flex-col mt-8 divide-y ">
                    <mat-progress-spinner
                                *ngIf="loader_getmember"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                    <div *ngIf="!loader_getmember">
                        <div *ngIf="members.length>0;else noMembers">
                        
                            <ng-container *ngFor="let member of members">
                                <div class="flex flex-col sm:flex-row sm:items-center border-b py-6">
                                    <div class="flex items-center">
                                        <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <ng-container *ngIf="member.avatar">
                                                <img
                                                    class="object-cover w-full h-full"
                                                    [src]="member.avatar"
                                                    alt="Contact avatar"/>
                                            </ng-container>
                                            <ng-container *ngIf="!member.avatar">
                                                <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                    {{member.name.charAt(0)}}
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="ml-4">
                                            <div class="font-medium">{{member.email}}</div>
                                            <div class="text-secondary">{{member.status}}</div>
                                        </div>
                                    </div>
                                    <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
                                        <div class="order-2 sm:order-1 ml-4 sm:ml-0">
                                            <mat-form-field
                                                class="fuse-mat-dense w-32">
                                                <mat-select
                                                    [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'"
                                                    [value]="member.role"
                                                    disableOptionCentering
                                                    #roleSelect="matSelect" (selectionChange)="updatePermission(member.user_id,$event.value)">
                                                    <mat-select-trigger class="text-md">
                                                        <span>Role:</span>
                                                        <span class="ml-1 font-medium textCap">{{roleSelect.value}}</span>
                                                    </mat-select-trigger>
                                                    <ng-container *ngFor="let role of roles">
                                                        <mat-option
                                                            class="h-auto py-4 leading-none"
                                                            [value]="role.value" [disabled]="!role.active">
                                                            <div class="font-medium text-md">{{role.label}}</div>
                                                            <div class="mt-1.5 text-sm whitespace-normal leading-normal text-secondary">{{role.description}}</div>
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="order-1 sm:order-2 sm:ml-3">
                                            <button mat-icon-button (click)="deleteMember(member.user_id)">
                                                <mat-icon
                                                    class="text-hint"
                                                    [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
    
                                
    
                            </ng-container>
                        
                        </div>
    
                        <ng-template #noMembers>
                            <div class="p-8 sm:p-16 rounded-l-lg bg-white tracking-tight text-center">
                                <img src="assets/icons/nosearch.svg" class="noimage" alt="">
                                <h4 class="text-4xl font-semibold ">No Users Found</h4>
                                <span class="block mt-3 mb-3">No users have been added yet. They will be displayed once added.</span>
                            </div>
                        </ng-template>
                    </div>
                </div>
            
            </div>
            


        </div>
    </div>
</div>
