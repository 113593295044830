import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectorRef } from '@angular/core';
import { Component, ElementRef, EventEmitter, inject, Input, Output, SimpleChanges, TemplateRef, ViewChild,viewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerComponent, FuseDrawerService } from '@fuse/components/drawer';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { MatDialog } from '@angular/material/dialog';
import { AddConfigurationComponent } from '../add-configuration/add-configuration.component';

@Component({
  selector: 'app-configuration',
  standalone: true,
  imports: [NgIf, MatFormFieldModule,NgFor,FuseAlertComponent,MatMenuModule, NgClass, MatProgressSpinnerModule, MatSelectModule, MatCheckboxModule, ReactiveFormsModule, MatIcon, MatInputModule, MatButtonModule, MatExpansionModule, DragDropModule, MatSelectModule,FuseDrawerComponent],
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.scss'
})
export class ConfigurationComponent {

  @Input()
  themeTypes:any=[];

  @Input()
  view_component:string;



  constructor(public dialog:MatDialog,){
    
  }





   openDialog() {
      let dialogRef = this.dialog.open(AddConfigurationComponent,
        { 
          panelClass: 'add-config-dialog',
          disableClose: true, 
        }
      );
    }
  


}
