import { filter, Subscription,timer } from 'rxjs';

import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, inject, Input, Output, SimpleChanges, TemplateRef, ViewChild,viewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { MatTabsModule,MatTabGroup } from '@angular/material/tabs';
import { ProposalService } from 'app/core/proposal/proposal.service';

import { ConnectSalesforceComponent } from 'app/modules/user/common/connect-salesforce/connect-salesforce.component';
import { MetadataExtractComponent } from 'app/modules/user/common/metadata-extract/metadata-extract.component';
import { TechnicalInsightComponent } from 'app/modules/user/common/technical-insight/technical-insight.component';
import { MetadataConfirmBoxComponent } from 'app/modules/user/common/metadata-confirm-box/metadata-confirm-box.component';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { EventService } from 'app/core/common/event.service';
import { ChatBoxComponent } from '../documented-ai/chat-box/chat-box.component';

@Component({
  selector: 'app-metadata',
  standalone: true,
  imports: [ChatBoxComponent,NgIf, MatFormFieldModule,MatTabsModule,MetadataExtractComponent,TechnicalInsightComponent,NgFor,ConnectSalesforceComponent,FuseAlertComponent,MatDialogModule,MatMenuModule, NgClass, MatProgressSpinnerModule, MatSelectModule, MatCheckboxModule, ReactiveFormsModule, MatIcon, MatInputModule, MatButtonModule, MatExpansionModule, DragDropModule, MatSelectModule,FuseDrawerComponent],
  templateUrl: './metadata.component.html',
  styleUrl: './metadata.component.scss'
})
export class MetadataComponent {
   @ViewChild('tabGroup') tabGroup: MatTabGroup;
   private timeoutSubscription: Subscription | null = null;
   private subscription: Subscription;
  _proposalDetail: any = {};
  _userDetail: any = {};
  showSuccess: boolean = false;
  showFailure: boolean = false;
  showSuccessdisconnect: boolean = false;
  load_extract: boolean = false;
  fetch_meta_data: boolean = false;
  fetch_user_org: boolean = true;
  fetch_vector_data: boolean = false;
  describeMetadata: any[] = [];
  user_orgs: any = [];
  findMetadata : any = {};
  total_message_received: number = 0;
  connectionControl = new FormControl('');

  @Input() set proposal(value: any) {
    if (value) {console.log(value);
      // Use a setter to detect changes in the Input property
      this._proposalDetail = value;
      this.ngOnInit();
      this.cdr.detectChanges(); // Optionally force change detection
    }
  }
  
  @Input() set user(value: any) {
    if (value) {
      this._userDetail = value;
      this.getSalesforceConnection();
    }
  }

  @Input() accessStatus:any;

  @Input() is_write_allowed:any;
  private isSalesforceConnected = false;

  constructor(private overlayContainer: OverlayContainer,private cdr: ChangeDetectorRef,private _websocketService: WebsocketService,public dialog: MatDialog,private proposalService:ProposalService,private eventService: EventService){
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "metadata_list_generated")
    ).subscribe((message) => {
        console.log("Received:", message,this._proposalDetail._id);
        if(message.taskID==this._proposalDetail._id){
          this.eventService.emitEventMetadata({extract:true});
          this.total_message_received = this.total_message_received+1;
          console.log(this.total_message_received + ":" + this.describeMetadata.length);
          if(this.total_message_received==this.describeMetadata.length)this.callStatus();
        }
        // console.log(this.total_message_received, this.describeMetadata.length);
        // let taskidIndex = this.describeMetadata.findIndex(el=>el._id==message.taskID);
        // if(taskidIndex>-1 && message.data && message.data.length){
        //   this.describeMetadata[taskidIndex].results = message.data;
        //   this.cdr.detectChanges();
        // }
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "validate_associated_doc")
    ).subscribe((message) => {
        console.log("Received:", message);
        if(message.taskID==this._proposalDetail._id && message.data){
          this.fetch_meta_data = false;
          this.getSalesforceConnection();
          this.isSalesforceConnected = true;
          // Set a 20-minute timeout (30 * 60 * 1000 ms)
          this.timeoutSubscription = timer(20 * 60 * 1000).subscribe(() => {
            console.log("Timeout reached: Triggering getSalesforceConnection()");
            this.getSalesforceConnection();
          });
        }
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "processing_metadata")
    ).subscribe((message) => {
        console.log("Received:", message);
        if(message.taskID==this._proposalDetail._id && message.data){
          this.eventService.emitEventMetadata({extract:true});
          if (this.isSalesforceConnected) {
            this.getSalesforceConnection();
          }
        }
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "processing_metadata_complete")
    ).subscribe((message) => {
        console.log("Received:", message);
        if(message.taskID==this._proposalDetail._id && message.data){
          this.getSalesforceConnection();
          this.fetch_vector_data = true;
          this.eventService.emitEventMetadata({extract:false,generatingtitle:''});
          // Clear the timeout since the message arrived
          if (this.timeoutSubscription) {
            this.timeoutSubscription.unsubscribe();
            this.timeoutSubscription = null;
          }
        }
    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "create_vector")
    ).subscribe((message) => {
        if(message.taskID==this._proposalDetail._id && message.data){
          console.log("Received:",message);
          this.isSalesforceConnected = true;
          // console.log("id",message.data['_id'] );
          // if(message.data['_id'] && message.data['extracted_data'] && message.data['extracted_data'].length){
          //   let descIndex = (this.findMetadata.describeMetadata && this.findMetadata.describeMetadata.length)?this.findMetadata.describeMetadata.findIndex(el=>el._id==message.data['_id']):-1;
          //   // console.log("descIndex",descIndex);
          //   if(descIndex>-1){
          //     // this.getDescribeExtract(message.data['_id'],descIndex);
          //     this.findMetadata.describeMetadata = [...this.findMetadata.describeMetadata];
          //     this.findMetadata.describeMetadata[descIndex] = message.data;
          //     this.describeMetadata[descIndex] = message.data;
          //     // console.log("Before update", this.describeMetadata);
          //     this.describeMetadata = [...this.describeMetadata];
          //     this.describeMetadata[descIndex] = message.data;
          //     this.cdr.detectChanges();
          //     // console.log("After update", this.describeMetadata);
          //   }
          // }
          this.eventService.emitEventMetadata({extract:true});
        }
    });
    this.subscription = this.eventService.event$.subscribe((data) => {
      if(data.type=='refresh')this.getSalesforceConnection();
    });
  }

  ngOnInit(): void {  
  }

  getSalesforceConnection(){
    this.proposalService.getUserOrg(this._userDetail._id,this._proposalDetail._id).subscribe((response)=>{
      if(response.success && response.user_orgs.length){
        this.user_orgs = response.user_orgs;
        // this.refreshToken();
        if(response.findMetadata && response.findMetadata._id){
          this.findMetadata=response.findMetadata;
          this.describeMetadata = response.findMetadata.describeMetadata;
          if(this.describeMetadata.length){
            const activeMetadata = this.describeMetadata.filter(item => item.active === true);
            // console.log("activeMetadata",activeMetadata.length);
            const allExtractedDataEmpty = activeMetadata.every(item => item.extracted_data.length === 0);
            const nextUnprocessedIndex = activeMetadata.find(item => (!item.processed && item.extracted_data.length));
            const allProcessedHaveData = activeMetadata.every(item => 
              item.extracted_data.length === 0 || (item.processed && item.extracted_data.length > 0)
            );
            const noneProcessedData = activeMetadata.every(item => item.processed);
            console.log("nextUnprocessedIndex",nextUnprocessedIndex);
            console.log("allProcessedHaveData",allProcessedHaveData);
            console.log("allExtractedDataEmpty",allExtractedDataEmpty);
            console.log("noneProcessedData",noneProcessedData);
            // console.log("activeMetadata",activeMetadata); || (!nextUnprocessedIndex && allExtractedDataEmpty)
            if(nextUnprocessedIndex){
              let data = {};
              if((!nextUnprocessedIndex && allExtractedDataEmpty)){
                data = activeMetadata[0];
              }
              else{
                data = nextUnprocessedIndex;
              }
              this.eventService.emitEventMetadata({extract:true,generatingtitle:data['fullName']});
            }
            else if(allExtractedDataEmpty){
              this.eventService.emitEventMetadata({extract:true,generatingtitle:' metadata'});
            }
            else if(allProcessedHaveData){
              this.fetch_vector_data = true;
              this.eventService.emitEventMetadata({extract:false,generatingtitle:''});
            }
            else {
              this.eventService.emitEventMetadata({extract:true,generatingtitle:'metadata'});
            }
          }
        }
        else {
          this.findMetadata = {};
          this.describeMetadata = [];
        }
      }
      else {
        this.user_orgs = [];
        this.findMetadata = {};
        this.describeMetadata = [];
      }
      this.fetch_user_org = false;
      this.cdr.detectChanges();
    });
  }

  refreshToken(){
    this.eventService.emitEventMetadata({extract:false,generatingtitle:''});
    this.proposalService.refresh_login({ id: this.user_orgs[0]._id, proposal: this._proposalDetail._id , login_type: 'refresh' }).subscribe((response)=>{
      this.connectionControl.setValue('');
      if(response.status){
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 1500);
        this.user_orgs = [response.data];
        this.cdr.detectChanges();
      }
      else {
        this.showFailure = true;
        setTimeout(() => {
          this.showFailure = false;
        }, 1500);
      }
    });
  }

  linkSalesforce(){
    const dialogRef = this.dialog.open(ConnectSalesforceComponent,{ 
      panelClass: 'connect-salesforce-form',
      disableClose: true,
      data: {
        user_orgs: this.user_orgs,
        proposal_id: this._proposalDetail._id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSalesforceConnection();
    });
  }

  manageconnect(event){
    if(event.value == 'refresh' || event.value == 'disconnect'){      
      const dialogRef = this.dialog.open(MetadataConfirmBoxComponent,{ 
        panelClass: 'confirm-meta-connect',
        disableClose: true,
        data: {
          type: event.value,
          user_orgs: this.user_orgs,
          proposal_id: this._proposalDetail._id
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.connectionControl.setValue('');
        if(result.yes==true){
          this.fetch_vector_data = false;
          if(result.type == 'refresh_keep_insights' || result.type == 'refresh_remove_insights'){
            this.showSuccess = true;
            setTimeout(() => {
              this.showSuccess = false;
            }, 1500);
            this.eventService.emitEventMetadata({type:'insight'});
            this.metadataExtract(result.type);
          }
          else {
            this.showSuccessdisconnect = true;
            setTimeout(() => {
              this.showSuccessdisconnect = false;
            }, 1500);
            this.eventService.emitEventMetadata({extract:false,generatingtitle:''});
            this.getSalesforceConnection();
          }
        }
      });
    } else if(event.value == 'reauthorize') {
      this.fetch_vector_data = true;
      this.refreshToken();
    } else if(event.value == 'connect') {
      if(this.findMetadata && this.findMetadata._id){
        this.fetch_vector_data = true;
        this.refreshToken();
      }
      else {
        this.fetch_vector_data = false;
        this.connectionControl.setValue('');
        this.metadataExtract('');
      }
    } else{
      const dialogRef = this.dialog.open(ConnectSalesforceComponent,{ 
        panelClass: 'connect-salesforce-form',
        disableClose: true,
        data: {
          type: event.value,
          user_orgs: this.user_orgs,
          proposal_id: this._proposalDetail._id
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.fetch_vector_data = false;
        this.connectionControl.setValue('');
        this.getSalesforceConnection();
      });
    }

  }

  metadataExtract(type){
    this.fetch_meta_data = true;
    this.load_extract = true;
    this.proposalService.refresh_login({ id: this.user_orgs[0]._id, proposal: this._proposalDetail._id, login_type: 'refresh' }).subscribe((response)=>{
      if(response.status){
        this.user_orgs = [response.data];
        this.proposalService.metadata_describe({access_token:this.user_orgs[0].access_token,salesForces_url:this.user_orgs[0].instance_url,orgId:this.user_orgs[0].organizationId,proposal:this._proposalDetail._id, type: type}).subscribe((response)=>{
          if(response.status && response.data){
            this.findMetadata=response.data;
            this.load_extract = false;
            this.describeMetadata = response.data.describeMetadata;
            if(this.describeMetadata.length)this.callSocket();
            this.cdr.detectChanges();
          }
          else {
            this.load_extract = false;
          }
        });
      }
      else this.load_extract = false;
    });
  }

  
  async callSocket() {
    // console.log(this.describeMetadata);
    // this.describeMetadata.forEach(el=>{
    //   // console.log(el._id);
    //   this._websocketService.sendMetadataListCommand(this.findMetadata._id,el._id,(el.hasOwnProperty('childXmlNames')?el.childXmlNames:el.xmlName),el.inFolder,this._proposalDetail._id,this.user_orgs[0].access_token,this.user_orgs[0].instance_url);
    // });
    
    this.eventService.emitEventMetadata({ extract: true });
    this.fetch_meta_data = true;

    for (const el of this.describeMetadata) {
        await new Promise(resolve => setTimeout(resolve, 500)); // Delay between each request

        console.log(`Sending WebSocket for ${el._id}`);
        this._websocketService.sendMetadataListCommand(
            this.findMetadata._id,
            el._id,
            el.hasOwnProperty('childXmlNames') ? el.childXmlNames : el.xmlName,
            el.inFolder,
            this._proposalDetail._id,
            this.user_orgs[0].access_token,
            this.user_orgs[0].instance_url
        );
    }
  }


  callStatus(){
    this.total_message_received = 0;
    this._websocketService.sendMetadataRequestCommand(this.findMetadata._id,this._proposalDetail._id,this.user_orgs[0].access_token,this.user_orgs[0].instance_url);
  }

  openChatWithAI(){
        
        const dialogRef = this.dialog.open(ChatBoxComponent,
          { 
            panelClass: 'add-feature-dialog',
            disableClose: true,
            autoFocus: false,  
            restoreFocus: false,
            data : {
              proposalID: this._proposalDetail._id,
              proposal : this._proposalDetail,
              document: null,
              type:"metadata",
              metadataID:this.findMetadata._id,
              accessStatus: this.accessStatus,
              is_write_allowed: this.is_write_allowed
            }
          }
        );
        dialogRef.beforeClosed().subscribe(result => {
          // console.log(result);
          // this.onKCDocUpload.emit({refresh:true});
          const overlayContainerElement = this.overlayContainer.getContainerElement();
          if (overlayContainerElement) {
            const overlayContainers = overlayContainerElement.querySelectorAll('.cdk-overlay-pane');
            overlayContainers.forEach(overlayPane => {
              overlayPane.remove();  // Only removes the specific dialog content
            });
            // overlayContainerElement.innerHTML = '';  // Clear the contents of the overlay container
          }
          this.cdr.detectChanges();
        });

  }

  getDescribeExtract(metadataid,descIndex){
    this.proposalService.describemetadata_detail(this.findMetadata._id,metadataid).subscribe((response)=>{
      console.log(response);
      // this.findMetadata.describeMetadata = [...this.findMetadata.describeMetadata];
      // this.findMetadata.describeMetadata[descIndex] = response;
      // this.describeMetadata[descIndex] = response;
      // // console.log("Before update", this.describeMetadata);
      // this.describeMetadata = [...this.describeMetadata];
      // this.describeMetadata[descIndex] = response;
      // this.cdr.detectChanges();
    });
  }

}
