
<!-- <div *ngIf="showQuickPromptMenu" [ngStyle]="{'top': menuPosition.top, 'left': menuPosition.left}" class="quickPromptPopup">
    <div class="menu rounded-xl border-0 flex items-center h-full w-64">
        <img class="w-6 h-6 ml-5" src="assets/icons/aiicon1.svg">
        <button mat-button [matMenuTriggerFor]="menu" class="custom-purple-text text-lg ml-1 mr-3">Rewrite with AI <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.11328 10L12.1133 15L17.1133 10H7.11328Z" fill="#4F46E5" fill-opacity="0.87"/>
            </svg>
             </button>
        <mat-menu #menu="matMenu">
                <button *ngFor="let quickPrompt of promptTemplates" mat-menu-item class="mr-8 flex" (click)="onSelectQuickAIOption(quickPrompt)">
                    <img class="h-5 w-5" [src]="quickPrompt.icon">
                          <div class="text-lg ml-3">{{quickPrompt.title}}</div></button>
        </mat-menu>
        
      </div>
      <div class="textbox rounded-lg min-h-30 bg-white min-w-1/2 flex flex-col justify-between mt-2 overflow-clip">
        <textarea placeholder="Write Custom Prompt" class="p-2 min-h-20 max-h-24"></textarea>
        <button class="bg-[#4F45E4] w-30 h-7 ml-3 mb-1 rounded-lg text-white">Apply</button>
    </div>
      <div class="textbox rounded-lg min-h-30 bg-white min-w-1/2 flex flex-col justify-between mt-2 overflow-clip">
        <textarea class="p-2 min-h-24 max-h-24"></textarea>
        <div class="min-h-5 bg-[#F1F5F9] w-full flex items-center">
            <img class="w-4 h-4 ml-3" src="assets/icons/aiicon1.svg">
            <button mat-button [matMenuTriggerFor]="menuActions" class="custom-purple-text text-md mr-3">Rewriting with AI... <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"></svg></button>
            <mat-menu #menuActions="matMenu">
                <button *ngFor="let quickPromptAction of quickPromptActions" mat-menu-item class="mr-8 flex" (click)="onSelectQuickAIOption(quickPrompt)">
                    <img class="h-5 w-5" [src]="quickPromptAction.value">
                          <div class="text-lg ml-3">{{quickPromptAction.label}}</div></button>
        </mat-menu>
        </div>
    </div>
</div> -->

<div class="min-w-full min-h-full overflow-y-scroll relative">
    <!-- <app-quick-prompt
    (mouseenter)="onMouseEnterQuickPrompt()" 
    (mouseleave)="onMouseLeaveQuickPrompt()"
    *ngIf="showQuickPromptMenu" 
    (onCompleteQuickAction)="onCompleteQuickAction($event)" 
    [quickActionSelection]="quickSelectedText" 
    [menuPosition]="menuPosition">
    </app-quick-prompt> -->

<div class="flex flex-col flex-auto min-w-0" (mousedown)="onClickOutside($event)">
   
   
    <!-- Blank state -->
    <div class="bg-card" *ngIf="(!_proposal_id)">
        <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">Proposal doesn't exist!</div>
    </div>
    <!-- Header -->
    <div class="bg-card -mt-px border-t flex-auto sm:px-18 proCont" *ngIf="proposal_detail._id">
        <div class="flex flex-col w-full max-w-screen-xl mx-auto">
            <a class="inline-flex items-center leading-6 text-primary hover:underline  mb-2 mt-5 pl-4 cursor-pointer"  (click)="navigateBack()">
                <span class="inline-flex items-center">
                    <mat-icon svgIcon="heroicons_outline:arrow-small-left" class="icon-size-5 text-[#4F45E4]"></mat-icon>
                    <span class="ml-1.5 font-medium leading-5">Back to Pre-Sales Agents</span>
                </span>
            </a>
            <div class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 mb-6">
                <!-- Avatar and name -->
                <div class="flex flex-auto items-center min-w-0">
                    <div class="flex flex-col min-w-0 ml-4">
                        <ng-container>
                            <div class="text-2xl md:text-4xl font-semibold tracking-tight leading-7 md:leading-snug truncate">{{stripHTML(proposal_detail.name)}}</div>
                        </ng-container>
                        <div class="flex flex-col gap-2">
                            <!-- <div class="ml-1.5 leading-6 truncate text-secondary">{{proposal_detail.client_name?proposal_detail.client_name+',':''}} {{proposal_detail.proposal_meta.location}}</div> -->
                            <div class="leading-6 truncate text-secondary">{{proposal_detail.proposal_meta.product}} implementation for {{proposal_detail.proposal_meta.industry}} industry</div>
                            
                           <div class="viewTag">
                            <span class="viewonlyIc" *ngIf="proposal_acess_status=='view'">
                                <mat-icon svgIcon="heroicons_outline:eye" color="blue"></mat-icon> View Only</span>
                            <span class="viewonlyIc2" *ngIf="(proposal_acess_status=='edit' || proposal_acess_status=='admin')"><mat-icon svgIcon="heroicons_outline:calendar-days" color="gray"></mat-icon> 
                                Last Updated: <span class="blueText font-medium">{{tabGroupForm.get('last_edit_history_on').value}},</span> <b class="font-medium text-black">by {{tabGroupForm.get('last_edit_history_by').value}}</b></span>
                           </div>
                        </div>
                    </div>
                </div>
                <!-- Actions -->
                <div class="flex items-center mt-6 sm:mt-0 sm:ml-2 gap-2">
                    <button *ngIf="(_page_id=='proposal' && is_share_visible)" class=" getAi " mat-flat-button [color]="'blue2'" (click)="sharepop()" >
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:share'"></mat-icon>
                        <span class="ml-2">Share</span>
                    </button>
                    <button *ngIf="_page_id=='proposal'"
                        class="bg-accent"
                        mat-flat-button
                        [color]="'accent'" [disabled]="proposal_detail.status!=2" (click)="downloadDrawer.toggle()">
                        <mat-icon *ngIf="!download_loader"
                            class="icon-size-5"
                            [svgIcon]="'heroicons_outline:arrow-down-tray'"></mat-icon>
                        <!-- <mat-progress-spinner
                                    *ngIf="download_loader"
                                    [diameter]="24"
                                    [mode]="'indeterminate'"></mat-progress-spinner> -->
                        <span class="ml-2">Download</span>
                    </button>
                    <!-- (click)="download()" (click)="downloadDrawer.toggle()" -->
                    <button *ngIf="(_page_id=='proposal' && is_write_allowed)" mat-flat-button
                        [color]="'primary'" (click)="openSetting()" >
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_outline:cog-6-tooth'" ></mat-icon>
                        <span class="ml-2">Settings</span>
                    </button>
                    <!-- <button mat-flat-button class="bg-[#0F66E0] text-white" (click)="onClickJiraIntegrations()">
                        <img src="assets/icons/jira_icon.svg">
                        <span class="ml-2">Manage Jira</span>
                    </button> -->
                    <button *ngIf="_page_id=='template'" mat-flat-button
                        [color]="'primary'" (click)="chooseTemplate()" >
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_outline:rectangle-stack'" ></mat-icon>
                        <span class="ml-2">{{isPaid?'Use Template':'Subscribe to Use Template'}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Main -->
    
    

    <div *ngIf="proposal_detail._id" class="-mt-px border-t flex-auto sm:px-18 proCont" >

        <div class="w-full max-w-screen-xl mx-auto position-relative">
            <!-- <div class="shimmer-effect h-60 mt-2 rounded"  *ngIf="loadingFeature" ></div>     -->
            <form *ngIf="!loadingFeature" [formGroup]="tabGroupForm" id="propsalStat">
                    <div class="flex border-t -mt-px pt-4 sm:pt-6 gap-8 summaryBoxes mb-3">
                        <div  class="tableHead flex flex-col flex-wrap W-50 bg-card shadow rounded-2xl px-4 py-3">
                            <div class="headingTag">
                                <h4 class="font-medium text-xl">Progress</h4>
                                <!-- <span class="font-medium smallText"> <span class="blueText">{{tabGroupForm.get('progress_indicator_incompleted').value}}</span> Pending , <span class="blueText">{{tabGroupForm.get('progress_indicator_completed').value}}</span> Completed </span> -->
                            </div>

                            <div class="progress gap-2 flex flex-auto items-center">
                                <!-- <mat-progress-bar mode="determinate" [value]="tabGroupForm.get('progress_indicator_completed').value*20"></mat-progress-bar> -->
                                <div class="multi-progress-bar">
                                    <div class="progress-bar primary-bar" [style.width.%]="tabGroupForm.get('progress_indicator_completed').value*20"></div>
                                    <div class="progress-bar accent-bar " [ngClass]="{'singledot': (tabGroupForm.get('progress_indicator_completed').value*20 < 100 )}" [style.width.%]="((tabGroupForm.get('progress_indicator_completed').value*20 < 100)?((tabGroupForm.get('progress_indicator_completed').value+1)*20):100)"></div>
                                  </div>
                                <span class="w-14 font-medium">{{tabGroupForm.get('progress_indicator_completed').value*20 }}% </span>
                            </div>
                            <button class="nxtAction mb-3">Activities</button>
                           <div class="timline">
                                <div class="scroll-container "  >
                                    <div *ngFor="let item of indicators; let i = index" class="scroll-item finaltag flex flex-row gap-2" [class.highlight]="nextAct == i" [ngClass]="{'completed':(item.status=='Completed'),'lastItem':(i==indicators.length-1)}" [id]="(nextAct == i)? 'nextAct':''">
                                        <mat-icon svgIcon="heroicons_outline:check" [ngClass]="{'complete-activity':(item.status=='Completed')}"></mat-icon>
                                        <div style="width: calc(100% - 30px);">
                                            <h4 class="font-medium text-md">{{item.name}}</h4>
                                            <p class="d-block line-clamp-2">{{item.description}}</p>
                                        </div>
                                    </div>
                              </div>
                                <!-- -->
                           </div>
                            <!-- <button class="showAll mt-3">Show All Actions <mat-icon svgIcon="heroicons_outline:chevron-down"></mat-icon></button> -->
                        </div>
                        <div class="W-50 max-w-screen-xl mx-auto">
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-8 w-full min-w-0 mobGrid-2">
                                
                                <!-- <div class="flex">
                                    <div class="flex items-start justify-start">
                                        <mat-icon color="blue" svgIcon="heroicons_outline:banknotes"></mat-icon>
                                    </div>
                                    <div class="flex flex-col items-center justify-start">
                                        <div class="flex items-start justify-start">
                                    
                                            <div class="text-sm font-medium tracking-tight leading-6 truncate">Total Value ({{tabGroupForm.get('proposal_meta.currency').value}})</div>
                                        </div>
                                        <div class="flex flex-col items-start mt-2">
                                            <div class="text-3xl font-medium tracking-tight leading-none break-all">{{ getCurrencyIc(tabGroupForm.get('proposal_meta.currency').value) }}{{formatMoney(tabGroupForm.get('proposal_stats.total_value').value)}}</div>
                                        </div>
                                    </div>
                                </div> -->
                                
                                <div class="flex flex-auto  gap-2 bg-card  justify-start shadow rounded-2xl overflow-hidden">
                                    <!-- <div class="flex items-center justify-center iconNew">
                                        <mat-icon color="blue" svgIcon="heroicons_outline:banknotes"></mat-icon>
                                    </div> -->
                                    <div class="flex flex-col pt-6 pb-6 pl-4" *ngIf="tabGroupForm.get('proposal_stats.total_value').value">
                                        <div class="flex items-start justify-start">
                                    
                                            <div class="font-medium leading-6 text-[#94A3B8] text-sm tracking-tight truncate">Total Value ({{tabGroupForm.get('proposal_meta.currency').value}})</div>
                                        </div>
                                        <div class="flex items-start mt-2">
                                            <div class="text-3xl font-normal tracking-tight leading-none break-all">{{ getCurrencyIc(tabGroupForm.get('proposal_meta.currency').value) }}{{formatMoney(tabGroupForm.get('proposal_stats.total_value').value)}}</div>
                                        </div>
                                    </div>
                                    <div class="shimmer-effect w-full" style="min-height: 95px;" *ngIf="!tabGroupForm.get('proposal_stats.total_value').value"></div>
                                </div>
                                <div class="flex flex-auto  gap-2 bg-card  justify-start shadow rounded-2xl overflow-hidden">
                                    <!-- <div class="flex items-center justify-center iconNew">
                                        <mat-icon color="blue" svgIcon="heroicons_outline:arrow-trending-up"></mat-icon>
                                    </div> -->
                                    <div class="flex flex-col pt-6 pb-6 pl-4"  *ngIf="tabGroupForm.get('proposal_stats.total_hours').value">
                                        <div class="flex items-start justify-start">
                                            <div class="font-medium leading-6 text-[#94A3B8] text-sm tracking-tight truncate">Total Hours</div>
                                        </div>
                                        <div class="flex items-start mt-2">
                                            <div class="text-3xl font-normal tracking-tight leading-none break-all">{{tabGroupForm.get('proposal_stats.total_hours').value}}</div>
                                        </div>
                                    </div>
                                    <div class="shimmer-effect w-full" style="min-height: 95px;" *ngIf="!tabGroupForm.get('proposal_stats.total_hours').value"></div>
                                </div>
                                <div class="flex flex-auto  gap-2 bg-card  justify-start shadow rounded-2xl overflow-hidden">
                                    <!-- <div class="flex items-center justify-center iconNew">
                                        <mat-icon color="blue" svgIcon="heroicons_outline:queue-list"></mat-icon>
                                    </div> -->
                                    <div class="flex flex-col pt-6 pb-6 pl-4" *ngIf="tabGroupForm.get('proposal_stats.total_feature').value">
                                        <div class="flex items-start justify-start">
                                            <div class="font-medium leading-6 text-[#94A3B8] text-sm tracking-tight truncate">Total Features</div>
                                        </div>
                                        <div class="flex items-start mt-2">
                                            <div class="text-3xl font-normal tracking-tight leading-none ">{{tabGroupForm.get('proposal_stats.total_feature').value}}</div>
                                        </div>
                                    </div>
                                    <div class="shimmer-effect w-full" style="min-height: 95px;" *ngIf="!tabGroupForm.get('proposal_stats.total_feature').value"></div>
                                </div>
                                <div class="flex flex-auto  gap-2 bg-card  justify-start shadow rounded-2xl overflow-hidden">
                                    <!-- <div class="flex items-center justify-center iconNew">
                                        <mat-icon color="blue" svgIcon="heroicons_outline:arrows-pointing-out"></mat-icon>
                                    </div> -->
                                    <div class="flex flex-col pt-6 pb-6 pl-4" *ngIf="tabGroupForm.get('proposal_stats.total_phase_weeks').value">
                                        <div class="flex items-start justify-start">
                                            <div class="font-medium leading-6 text-[#94A3B8] text-sm tracking-tight truncate">Total Weeks</div>
                                        </div>
                                        <div class="flex items-start mt-2">
                                            <div class="text-3xl font-normal tracking-tight leading-none ">{{tabGroupForm.get('proposal_stats.total_phase_weeks').value}}</div>
                                        </div>
                                    </div>
                                    <div class="shimmer-effect w-full" style="min-height: 95px;" *ngIf="!tabGroupForm.get('proposal_stats.total_phase_weeks').value"></div>
                                </div>    
                                                    
                            </div>
                        </div>
                    </div>
                </form>
                <!-- <div *ngIf="false" class="loadingf1">


                    <div class="flex loaderAlert flex-wrap">
                        <img src="assets/icons/aiicon1.svg" alt="">
                        <p>GetGenerative.ai is fetching features and generating a summary.</p>
                        <div class="loaderPercent">
                            <span>{{progressValue}}%</span>
                            <mat-progress-bar mode="determinate" class="smallProgress" [value]="progressValue"></mat-progress-bar>
                        </div>
                        <span *ngIf="progressValue===100" class="closeIcon" (click)="loadingFeature=false;">
                            <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
                        </span>
                    </div>

                </div> -->

                <div *ngIf="loadingFeature" class="w-full mt-5">
                    <app-generating-progress-bar [proposal]="proposal_detail" [type]="_page_id"></app-generating-progress-bar>
                </div>
                <div *ngIf="loadingMetadata" class="w-full mt-5">
                    <app-regenerating-progress-bar [restart]="loadingMetadata" [generatingtitle]="generatingtitle" [agentid]="proposal_detail._id" type="3"></app-regenerating-progress-bar>
                </div>
                <!-- Tabs -->
                <mat-tab-group #tabGroup
                    class="sm:px-2" [selectedIndex]="selectedIndex" (selectedIndexChange)="selectedIndexChange($event)"
                    mat-stretch-tabs="false"
                    [animationDuration]="'0'" id="matTab">

                    <!-- Summary -->
                    <mat-tab >
                        <form [formGroup]="tabGroupForm">
                            <ng-template mat-tab-label>
                                <span class="inline-flex items-center space-x-2">
                                    <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                                    <span class="">Summary</span>
                                    
                                </span>
                            </ng-template>
                            <ng-template matTabContent>
                                <div *ngIf="(show_view_count && proposal_detail.admin_details && proposal_detail.admin_details.personal)"  class="min-w-full min-h-11 mb-5 custom-note rounded-lg flex justify-between items-center" >
                                    <div class="flex items-center ">
                                        <mat-icon svgIcon="heroicons_outline:eye" class="ml-5" color="blue"></mat-icon>
                                        <div class="ml-5 text-[#4F45E4]">
                                            This proposal is shared by {{proposal_detail.admin_details.personal.name.first}} {{proposal_detail.admin_details.personal.name.last}} with {{proposal_acess_status}}-only access.
                                        </div>
                                    </div>
                                    <a class="mr-5 cursor-pointer" (click)="closeView();">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.05957 15L15.1796 5M5.05957 5L15.1796 15" stroke="#4F45E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>
                                </div>
                                
                                <app-quick-prompt-tip *ngIf="is_write_allowed"></app-quick-prompt-tip>
                                <!-- <div class="flex flex-col gt-xs:flex-row">
                                    <div *ngIf="(_page_id=='proposal' && is_write_allowed); else disabledInput">
                                        <span class="font-medium block mb-2">Executive Summary</span>
                                        <tiptap-bubble-menu [editor]="editor" id="summaryText1">
                                            <app-editor-controls 
                                              [editor]="editor" 
                                              [selectedText]="selectedText"
                                              (completeQuickAction)="onCompleteQuickAction($event)">
                                            </app-editor-controls>
                                        </tiptap-bubble-menu>
                                        
                                        <tiptap-editor 
                                        id="summaryText" 
                                        [editor]="editor" 
                                        [formControlName]="'executiveSummary'"
                                        (blur)="onTextareaBlur()"
                                        (mouseenter)="onMouseEnter()"
                                        (mouseleave)="onMouseLeave()"
                                        (mousedown)="onMouseDown($event, 'summaryText')"
                                        (mouseup)="onTextSelect($event, 'summaryText')"
                                        (select)="onSelectionChange($event, 'summaryText')"
                                        (keydown)="onSelectionChange($event, 'summaryText')">
                                        </tiptap-editor>
                                    </div>
                                    <ng-template #disabledInput>
                                        <span class="font-medium block mb-2">Executive Summary</span>
                                        <tiptap-editor #summaryText2 id="summaryText2" [editor]="editor_summary_readonly" [formControlName]="'executiveSummary'" >
                                        </tiptap-editor>
                                    </ng-template>
                                </div> -->


                                <div class="summaryBox">
                                    <h3 class="font-medium mb-4 text-lg ">Executive Summary</h3>
                                    <div class="content" *ngIf="(_page_id=='proposal' && is_write_allowed && tabGroupForm.get('summaryOverview').value.length);">
                                        <tiptap-bubble-menu [editor]="summaryEditor1" id="summaryEditor1">
                                            <app-editor-controls 
                                            [editor]="summaryEditor1" 
                                            [selectedText]="selectedText"
                                            (completeQuickAction)="onCompleteQuickAction($event)">
                                            </app-editor-controls>
                                        </tiptap-bubble-menu>
                                        <tiptap-editor 
                                            id="summaryEditor1" 
                                            [formControlName]="'summaryOverview'"
                                            [editor]="summaryEditor1" 
                                            (blur)="onTextareaBlur()"
                                            (mouseenter)="onMouseEnter()"
                                            (mouseleave)="onMouseLeave()"
                                            (mousedown)="onMouseDown($event, 'summaryEditor1')"
                                            (mouseup)="onTextSelect($event, 'summaryEditor1')"
                                            (select)="onSelectionChange($event, 'summaryEditor1')"
                                            (keydown)="onSelectionChange($event, 'summaryEditor1')">
                                        </tiptap-editor>
                                    </div>
                                    <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                        <tiptap-editor #summaryTextReadonly1 id="summaryTextReadonly1" [editor]="editor_summaryOverview_readonly" [formControlName]="'summaryOverview'" >
                                        </tiptap-editor>
                                    </div>
                                    <div class="shimmer-effect h-60 rounded mb-4" *ngIf="!tabGroupForm.get('summaryOverview').value.length"></div>

                                    <div class="innerSummaryBox">
                                        <div class="flex font-medium gap-2 items-center mb-4 sectionhead">
                                            <img src="assets/icons/aboutcustomer.svg" alt="">
                                            <span> About the Customer </span>
                                        </div>
                                        <div class="content" *ngIf="(_page_id=='proposal' && is_write_allowed && tabGroupForm.get('aboutCustomer').value!='');" >
                                            <tiptap-bubble-menu [editor]="summaryEditor2" id="summaryEditor2">
                                                <app-editor-controls 
                                                  [editor]="summaryEditor2" 
                                                  [selectedText]="selectedText"
                                                  (completeQuickAction)="onCompleteQuickAction($event)">
                                                </app-editor-controls>
                                            </tiptap-bubble-menu>
                                            <tiptap-editor 
                                                id="summaryEditor2" 
                                                [formControlName]="'aboutCustomer'"
                                                [editor]="summaryEditor2" 
                                                (blur)="onTextareaBlur()"
                                                (mouseenter)="onMouseEnter()"
                                                (mouseleave)="onMouseLeave()"
                                                (mousedown)="onMouseDown($event, 'summaryEditor2')"
                                                (mouseup)="onTextSelect($event, 'summaryEditor2')"
                                                (select)="onSelectionChange($event, 'summaryEditor2')"
                                                (keydown)="onSelectionChange($event, 'summaryEditor2')">
                                            </tiptap-editor>
                                        </div>
                                        <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                            <tiptap-editor #summaryTextReadonly2 id="summaryTextReadonly2" [editor]="editor_aboutCustomer_readonly" [formControlName]="'aboutCustomer'" >
                                            </tiptap-editor>
                                        </div>
                                        <div class="shimmer-effect h-60 rounded" *ngIf="!tabGroupForm.get('aboutCustomer').value.length"></div>
                                    </div>

                                 

                                    <div class="innerSummaryBox" >
                                        <div class="flex font-medium gap-2 items-center mb-4 sectionhead">
                                            <img src="assets/icons/projectrequirenments.svg" alt="">
                                            <span>Project Requirements</span>
                                        </div>
                                        <div class="content" *ngIf="(_page_id=='proposal' && is_write_allowed && tabGroupForm.get('projectRequirements').value!='');">
                                            <tiptap-bubble-menu [editor]="summaryEditor4" id="summaryEditor4">
                                                <app-editor-controls 
                                                  [editor]="summaryEditor4" 
                                                  [selectedText]="selectedText"
                                                  (completeQuickAction)="onCompleteQuickAction($event)">
                                                </app-editor-controls>
                                            </tiptap-bubble-menu>
                                            <tiptap-editor 
                                                id="summaryEditor4" 
                                                [editor]="summaryEditor4" 
                                                (blur)="onTextareaBlur()"
                                                [formControlName]="'projectRequirements'"
                                                (mouseenter)="onMouseEnter()"
                                                (mouseleave)="onMouseLeave()"
                                                (mousedown)="onMouseDown($event, 'summaryEditor4')"
                                                (mouseup)="onTextSelect($event, 'summaryEditor4')"
                                                (select)="onSelectionChange($event, 'summaryEditor4')"
                                                (keydown)="onSelectionChange($event, 'summaryEditor4')">
                                            </tiptap-editor>
                                        </div>
                                        <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                            <tiptap-editor #summaryTextReadonly4 id="summaryTextReadonly4" [editor]="editor_projectRequirements_readonly" [formControlName]="'projectRequirements'" >
                                            </tiptap-editor>
                                        </div>
                                        <div class="shimmer-effect h-60 rounded" *ngIf="!tabGroupForm.get('projectRequirements').value.length"></div>
                                    </div>
                                    <div class="innerSummaryBox" >
                                        <div class="flex font-medium gap-2 items-center mb-4 sectionhead">
                                            <img src="assets/icons/projectchallange.svg" alt="">
                                            <span> Project Challenges </span>
                                        </div>
                                        <div class="content" *ngIf="(_page_id=='proposal' && is_write_allowed && tabGroupForm.get('projectChallenges').value!='');">
                                            <tiptap-bubble-menu [editor]="summaryEditor3" id="summaryEditor3">
                                                <app-editor-controls 
                                                  [editor]="summaryEditor3" 
                                                  [selectedText]="selectedText"
                                                  (completeQuickAction)="onCompleteQuickAction($event)">
                                                </app-editor-controls>
                                            </tiptap-bubble-menu>
                                            <tiptap-editor 
                                                id="summaryEditor3" 
                                                [editor]="summaryEditor3" 
                                                [formControlName]="'projectChallenges'"
                                                (blur)="onTextareaBlur()"
                                                (mouseenter)="onMouseEnter()"
                                                (mouseleave)="onMouseLeave()"
                                                (mousedown)="onMouseDown($event, 'summaryEditor3')"
                                                (mouseup)="onTextSelect($event, 'summaryEditor3')"
                                                (select)="onSelectionChange($event, 'summaryEditor3')"
                                                (keydown)="onSelectionChange($event, 'summaryEditor3')">

                                            </tiptap-editor>
                                        </div>
                                        <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                            <tiptap-editor #summaryTextReadonly3 id="summaryTextReadonly3" [editor]="editor_projectChallenges_readonly" [formControlName]="'projectChallenges'" >
                                            </tiptap-editor>
                                        </div>
                                        <div class="shimmer-effect h-60 rounded" *ngIf="!tabGroupForm.get('projectChallenges').value.length"></div>
                                    </div>

                                    <div class="innerSummaryBox" >
                                        <div class="flex font-medium gap-2 items-center mb-4 sectionhead">
                                            <img src="assets/icons/expectedoutcome.svg" alt="">
                                            <span>Expected Outcome</span>
                                        </div>
                                        <div class="content" *ngIf="(_page_id=='proposal' && is_write_allowed && tabGroupForm.get('expectedOutcomes').value!='');">
                                            <tiptap-bubble-menu [editor]="summaryEditor5" id="summaryEditor5">
                                                <app-editor-controls 
                                                  [editor]="summaryEditor5" 
                                                  [selectedText]="selectedText"
                                                  (completeQuickAction)="onCompleteQuickAction($event)">
                                                </app-editor-controls>
                                            </tiptap-bubble-menu>
                                            <tiptap-editor 
                                                id="summaryEditor5" 
                                                [formControlName]="'expectedOutcomes'"
                                                [editor]="summaryEditor5" 
                                                (blur)="onTextareaBlur()"
                                                (mouseenter)="onMouseEnter()"
                                                (mouseleave)="onMouseLeave()"
                                                (mousedown)="onMouseDown($event, 'summaryEditor5')"
                                                (mouseup)="onTextSelect($event, 'summaryEditor5')"
                                                (select)="onSelectionChange($event, 'summaryEditor5')"
                                                (keydown)="onSelectionChange($event, 'summaryEditor5')">
                                            </tiptap-editor>
                                        </div>
                                        <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                            <tiptap-editor #summaryTextReadonly5 id="summaryTextReadonly5" [editor]="editor_expectedOutcomes_readonly" [formControlName]="'expectedOutcomes'" >
                                            </tiptap-editor>
                                        </div>
                                        <div class="shimmer-effect h-60 rounded" *ngIf="!tabGroupForm.get('expectedOutcomes').value.length"></div>
                                    </div>

                                    <div class="innerSummaryBox">
                                        <div class="flex font-medium gap-2 items-center mb-4 sectionhead">
                                            <img src="assets/icons/aboutus.svg" alt="">
                                            <span>About Us</span>
                                        </div>
                                        <div class="content" *ngIf="(_page_id=='proposal' && is_write_allowed);">
                                            <tiptap-bubble-menu [editor]="summaryEditor6" id="summaryEditor6">
                                                <app-editor-controls 
                                                
                                                  [editor]="summaryEditor6" 
                                                  [selectedText]="selectedText"
                                                  (completeQuickAction)="onCompleteQuickAction($event)">
                                                </app-editor-controls>
                                            </tiptap-bubble-menu>
                                            <tiptap-editor 
                                                id="summaryEditor6" 
                                                [editor]="summaryEditor6" 
                                                [formControlName]="'aboutus'"
                                                (blur)="onTextareaBlur()"
                                                (mouseenter)="onMouseEnter()"
                                                (mouseleave)="onMouseLeave()"
                                                (mousedown)="onMouseDown($event, 'summaryEditor6')"
                                                (mouseup)="onTextSelect($event, 'summaryEditor6')"
                                                (select)="onSelectionChange($event, 'summaryEditor6')"
                                                (keydown)="onSelectionChange($event, 'summaryEditor6')">

                                            </tiptap-editor>
                                        </div>
                                        <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                            <tiptap-editor #summaryTextReadonly6 id="summaryTextReadonly6" [editor]="editor_aboutus_readonly" [formControlName]="'aboutus'" >
                                            </tiptap-editor>
                                        </div>
                                        <!-- <div class="shimmer-effect h-60 rounded" *ngIf="!tabGroupForm.get('aboutus').value.length"></div> -->
                                    </div>

                                    <div class="innerSummaryBox">
                                        <div class="flex font-medium gap-2 items-center mb-4 sectionhead">
                                            <img src="assets/icons/terms.svg" alt="">
                                            <span>Terms and Conditions</span>
                                        </div>
                                        <div class="content" *ngIf="(_page_id=='proposal' && is_write_allowed);">
                                            <tiptap-bubble-menu [editor]="summaryEditor7" id="summaryEditor7">
                                                <app-editor-controls 
                                                  [editor]="summaryEditor7" 
                                                  [selectedText]="selectedText"
                                                  (completeQuickAction)="onCompleteQuickAction($event)">
                                                </app-editor-controls>
                                            </tiptap-bubble-menu>
                                            <tiptap-editor 
                                                id="summaryEditor7" 
                                                [editor]="summaryEditor7" 
                                                [formControlName]="'terms'"
                                                (blur)="onTextareaBlur()"
                                                (mouseenter)="onMouseEnter()"
                                                (mouseleave)="onMouseLeave()"
                                                (mousedown)="onMouseDown($event, 'summaryEditor7')"
                                                (mouseup)="onTextSelect($event, 'summaryEditor7')"
                                                (select)="onSelectionChange($event, 'summaryEditor7')"
                                                (keydown)="onSelectionChange($event, 'summaryEditor7')">

                                            </tiptap-editor>
                                        </div>
                                        <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                            <tiptap-editor #summaryTextReadonly7 id="summaryTextReadonly7" [editor]="editor_terms_readonly" [formControlName]="'terms'" >
                                            </tiptap-editor>
                                        </div>
                                        <!-- <div class="shimmer-effect h-60 rounded" *ngIf="!tabGroupForm.get('terms').value.length"></div> -->
                                    </div>
                                </div>                            
                                <div class="buttonSec flex justify-start"> 
                                    <button *ngIf="_page_id=='template'" mat-flat-button [color]="'primary'" (click)="chooseTemplate()" >
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_outline:rectangle-stack'" ></mat-icon>
                                        <span class="ml-2">{{isPaid?'Use Template':'Subscribe to Use Template'}}</span>
                                    </button>
                                </div>
                                <div *ngIf="(_page_id=='proposal' && is_write_allowed)" class="flex justify-end pt-9">
                                    <mat-slide-toggle class="mt-8 sm:mt-0 sm:ml-auto reviewToggle" [color]="'primary'" [formControlName]="'progress_indicator_summary'"(change)="toggleChange($event,'summary')">
                                        Complete Review
                                    </mat-slide-toggle>
                                </div>
                            </ng-template>
                        </form>
                    </mat-tab>

                    <!-- Scope -->
                    <mat-tab > 
                        <form [formGroup]="tabGroupForm">
                            
                            <ng-template mat-tab-label>
                                <span class="inline-flex items-center space-x-2">
                                    <mat-icon svgIcon="heroicons_outline:pencil-square"></mat-icon>
                                    <span class="">Scope</span>
                                    
                                </span>
                            </ng-template>
                            <ng-template matTabContent>
                                <div *ngIf="(show_view_count && proposal_detail.admin_details && proposal_detail.admin_details.personal)"   class="min-w-full min-h-11 mb-5 custom-note rounded-lg flex justify-between items-center" >
                                    <div class="flex items-center">
                                        <mat-icon svgIcon="heroicons_outline:eye" color="blue" class="ml-5"></mat-icon>
                                    <div class="ml-5 text-[#4F45E4]">
                                        This proposal is shared by {{proposal_detail.admin_details.personal.name.first}} {{proposal_detail.admin_details.personal.name.last}} with {{proposal_acess_status}}-only access.
                                    </div>
                                    </div>
                                    <a class="mr-5 cursor-pointer" (click)="closeView();">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.05957 15L15.1796 5M5.05957 5L15.1796 15" stroke="#4F45E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>
                                </div>
                                <app-quick-prompt-tip *ngIf="is_write_allowed"></app-quick-prompt-tip>
                                <!-- <div class="flex flex-col gt-xs:flex-row">
                                    <div *ngIf="(_page_id=='proposal' && is_write_allowed); else disabledInput">
                                        <span class="font-medium block mb-2">Project Scope</span>
                                        <tiptap-bubble-menu [editor]="editor1" id="scope1">
                                            <app-editor-controls 
                                              [editor]="editor1" 
                                              [selectedText]="selectedText"
                                              (completeQuickAction)="onCompleteQuickAction($event)">
                                            </app-editor-controls>
                                        </tiptap-bubble-menu>
                                        
                                        <tiptap-editor 
                                        id="scope" 
                                        [editor]="editor1" 
                                        [formControlName]="'scope'"
                                        (blur)="onTextareaBlur()"
                                        (mouseenter)="onMouseEnter()"
                                        (mouseleave)="onMouseLeave()"
                                        (mousedown)="onMouseDown($event, 'scope')"
                                        (mouseup)="onTextSelect($event, 'scope')"
                                        (select)="onSelectionChange($event, 'scope')"
                                        (keydown)="onSelectionChange($event, 'scope')">
                                        </tiptap-editor>
                                    </div>
                                    <ng-template #disabledInput>
                                        <span class="font-medium block mb-2">Project Scope</span>
                                        <tiptap-editor #scopeText2 id="scopeText2" [editor]="editor_scope_readonly"  [formControlName]="'scope'" >
                                        </tiptap-editor>
                                    </ng-template>
                                </div> -->


                                <div class="summaryBox">
                                    <h3 class="font-medium mb-4 text-lg ">Project Scope</h3>
                                    <div *ngIf="(_page_id=='proposal' && is_write_allowed && tabGroupForm.get('scopeOverview').value!='');">
                                        <tiptap-bubble-menu [editor]="scopeeditor1" id="scopeeditor1">
                                            <app-editor-controls 
                                            [editor]="scopeeditor1" 
                                            [selectedText]="selectedText"
                                            (completeQuickAction)="onCompleteQuickAction($event)">
                                            </app-editor-controls>
                                        </tiptap-bubble-menu>

                                        <tiptap-editor 
                                            id="scopeeditor1" 
                                            [editor]="scopeeditor1" 
                                            (blur)="onTextareaBlur()"
                                            [formControlName]="'scopeOverview'"
                                            (mouseenter)="onMouseEnter()"
                                            (mouseleave)="onMouseLeave()"
                                            (mousedown)="onMouseDown($event, 'scopeeditor1')"
                                            (mouseup)="onTextSelect($event, 'scopeeditor1')"
                                            (select)="onSelectionChange($event, 'scopeeditor1')"
                                            (keydown)="onSelectionChange($event, 'scopeeditor1')">
                                            </tiptap-editor>
                                    </div>
                                    <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                        <tiptap-editor #scopeTextReadonly1 id="scopeTextReadonly1" [editor]="editor_scopeOverview_readonly" [formControlName]="'scopeOverview'" >
                                        </tiptap-editor>
                                    </div>
                                    <div class="shimmer-effect h-60 rounded mb-4" *ngIf="!tabGroupForm.get('scopeOverview').value.length"></div>
                                    <div class="innerSummaryBox" >
                                        <div class="flex font-medium gap-2 items-center mb-4 sectionhead">
                                            <img src="assets/icons/detailedscope.svg" alt="">
                                            <span>Detailed Scope</span>
                                        </div>
                                        <div class="content" *ngIf="(_page_id=='proposal' && is_write_allowed && tabGroupForm.get('projectScope').value!='');">
                                            <tiptap-bubble-menu [editor]="scopeeditor2" id="scopeeditor2">
                                                <app-editor-controls 
                                                  [editor]="scopeeditor2" 
                                                  [selectedText]="selectedText"
                                                  (completeQuickAction)="onCompleteQuickAction($event)">
                                                </app-editor-controls>
                                            </tiptap-bubble-menu>
                                            <tiptap-editor 
                                            id="scopeeditor2" 
                                            [editor]="scopeeditor2" 
                                            [formControlName]="'projectScope'"
                                            (blur)="onTextareaBlur()"
                                            (mouseenter)="onMouseEnter()"
                                            (mouseleave)="onMouseLeave()"
                                            (mousedown)="onMouseDown($event, 'scopeeditor2')"
                                            (mouseup)="onTextSelect($event, 'scopeeditor2')"
                                            (select)="onSelectionChange($event, 'scopeeditor2')"
                                            (keydown)="onSelectionChange($event, 'scopeeditor2')">
                                            </tiptap-editor>
                                        </div>
                                        <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                            <tiptap-editor #scopeTextReadonly2 id="scopeTextReadonly2" [editor]="editor_projectScope_readonly" [formControlName]="'projectScope'" >
                                            </tiptap-editor>
                                        </div>
                                        <div class="shimmer-effect h-60 rounded" *ngIf="!tabGroupForm.get('projectScope').value.length"></div>
                                    </div>


                                    <div class="innerSummaryBox">
                                        <div class="flex font-medium gap-2 items-center mb-4 sectionhead">
                                            <img src="assets/icons/outofscope.svg" alt="">
                                            <span>Out of Scope</span>
                                        </div>
                                        <div class="content" *ngIf="(_page_id=='proposal' && is_write_allowed && tabGroupForm.get('outOfScope').value!='');">
                                            <tiptap-bubble-menu [editor]="scopeeditor3" id="scopeeditor3">
                                                <app-editor-controls 
                                                  [editor]="scopeeditor3" 
                                                  [selectedText]="selectedText"
                                                  (completeQuickAction)="onCompleteQuickAction($event)">
                                                </app-editor-controls>
                                            </tiptap-bubble-menu>
                                            <tiptap-editor 
                                            id="scopeeditor3" 
                                            [editor]="scopeeditor3" 
                                            [formControlName]="'outOfScope'"
                                            (blur)="onTextareaBlur()"
                                            (mouseenter)="onMouseEnter()"
                                            (mouseleave)="onMouseLeave()"
                                            (mousedown)="onMouseDown($event, 'scopeeditor3')"
                                            (mouseup)="onTextSelect($event, 'scopeeditor3')"
                                            (select)="onSelectionChange($event, 'scopeeditor3')"
                                            (keydown)="onSelectionChange($event, 'scopeeditor3')">
                                            </tiptap-editor>
                                        </div>
                                        <div *ngIf="(_page_id=='template' || !is_write_allowed)">
                                            <tiptap-editor #scopeTextReadonly3 id="scopeTextReadonly3" [editor]="editor_outOfScope_readonly" [formControlName]="'outOfScope'" >
                                            </tiptap-editor>
                                        </div>
                                        <div class="shimmer-effect h-60 rounded" *ngIf="!tabGroupForm.get('outOfScope').value.length"></div>
                                    </div>

                                </div>    
                                <div class="buttonSec flex justify-start "> 
                                    <button *ngIf="_page_id=='template'" mat-flat-button [color]="'primary'" (click)="chooseTemplate()" >
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_outline:rectangle-stack'" ></mat-icon>
                                        <span class="ml-2">{{isPaid?'Use Template':'Subscribe to Use Template'}}</span>
                                    </button>
                                </div>
                                <div *ngIf="(_page_id=='proposal' && is_write_allowed)" class="flex justify-end pt-9">
                                    <mat-slide-toggle class="mt-8 sm:mt-0 sm:ml-auto reviewToggle" [color]="'primary'" [formControlName]="'progress_indicator_scope'"(change)="toggleChange($event,'scope')">
                                        Complete Review
                                    </mat-slide-toggle>
                                </div>
                            </ng-template>
                        </form>
                    </mat-tab>

                    <!-- Features -->
                    <mat-tab id="SolutionTab">              
                        <form [formGroup]="tabGroupForm">
                            
                            <ng-template mat-tab-label>
                                <span class="inline-flex items-center space-x-2">
                                    <!-- <mat-icon svgIcon="heroicons_outline:square-3-stack-3d"></mat-icon> -->
                                    <img  [src]="selectedIndex==2?'assets/icons/solutionicon-white.svg':'assets/icons/solutionicon.svg'">
                                    <span class="">Solution </span>
                                </span>
                            </ng-template>
                            <ng-template matTabContent>
                                <div *ngIf="(show_view_count && proposal_detail.admin_details && proposal_detail.admin_details.personal)"   class="min-w-full min-h-11 mb-5 custom-note rounded-lg flex justify-between items-center" >
                                    <div class="flex items-center  ml-5">
                                        <mat-icon  svgIcon="heroicons_outline:eye" color="blue"></mat-icon>
                                        <div class="ml-5 text-[#4F45E4]">
                                            This proposal is shared by {{proposal_detail.admin_details.personal.name.first}} {{proposal_detail.admin_details.personal.name.last}} with {{proposal_acess_status}}-only access.
                                        </div>
                                    </div>
                                    <a class="mr-5 cursor-pointer" (click)="closeView();">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.05957 15L15.1796 5M5.05957 5L15.1796 15" stroke="#4F45E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>
                                </div>
                                <app-quick-prompt-tip *ngIf="is_write_allowed"></app-quick-prompt-tip>
                                <!-- <app-solution-header *ngIf="hasWorkspaceView" [totalThemes]="themeTypes.length" [totalEpics]="getTotalEpics()" [totalHours]="totalDevHours" [totalFeatures]="tabGroupForm.get('proposal_stats.total_feature').value" (changeWorkspaceEvent)="onChangeWorkspace($event)" [page_id]="_page_id"></app-solution-header> -->



                                <mat-tab-group  #tabGroup2 class="sm:px-2 viewTabs "  mat-stretch-tabs="false"
                                [animationDuration]="'0'" id="matTab2">

                                <mat-tab id="workspaceView">
                                    <ng-template mat-tab-label>
                                        <span class="inline-flex items-center space-x-2">
                                            <span class="flex items-center gap-1"><mat-icon class="icon-size-5 mat-tab-activeic" svgIcon="heroicons_outline:check"></mat-icon> Workspace View </span>
                                        </span>
                                        
                                    </ng-template>
                                    
                                    <ng-template matTabContent >

                                        <app-workspace-view #workspace (onRefreshProposal)="getProposal(true)" (onSaveThemeAndEpics)="onSaveThemeAndEpics($event)" (onFeatureUpdate)="onFeatureUpdate($event)" (onSaveEpics)="onSaveEpics($event)"  [proposal]="proposal_detail" [themeTypes]="themeTypes" [_page_id]="_page_id" [is_write_allowed]="is_write_allowed" *ngIf="hasWorkspaceView && selectedWorkSpace==0" [receiveData]="parentData"></app-workspace-view>
            
                                    </ng-template>
                                </mat-tab>
                                <mat-tab id="solutionsView">
                                    <ng-template mat-tab-label>
                                        <span class="inline-flex items-center space-x-2">
                                            <span class="flex items-center gap-1"><mat-icon class="icon-size-5 mat-tab-activeic" svgIcon="heroicons_outline:check"></mat-icon>  Solution View </span>
                                        </span>
                                        
                                    </ng-template>
                                    
                                    <ng-template matTabContent >

                                        <div class="position-relative mt-8">
                                            <div [ngClass]="{'blur': loadingFeature}" class="">
                                                
                                            <!-- Header -->
                                                <!-- Loader -->
                                                <!-- <div
                                                    class="absolute inset-x-0 bottom-0">
                                                    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                                                </div> -->
                                                <!-- Actions -->
                                                <div class="flex actBt flex-wrap items-center gap-3  justify-between" *ngIf="(_page_id=='proposal' && is_write_allowed)">
                                                    <div class="subHeadingCont">                                                
                                                        <div class="text-black font-medium text-lg">Detailed Solution</div>
                                                        <div class="text-[#94A3B8] mb-5 text-md font-normal">Breakdown of solutions by modules with corresponding effort.</div> 
                                                    </div>
                                                        <!-- Add product button -->
                                                    <div class="add-get-ai-bt flex gap-3">
                                                        <button class="" mat-flat-button [color]="'primary'" (click)="openDialog('addfeature')"  >
                                                            <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                                            <span class="">Add New Solution </span>
                                                        </button>
                                                        <button class=" getAi mw-100 shadow2 mr-2" mat-flat-button [color]="'white'" (click)="openDialog('genai');" >
                                                            <img src="assets/icons/aiicon1.svg" style="width: 15px;" alt="">
                                                            <span class="">Get AI Recommendations</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
    
                                        <div [ngClass]="{'blur': loadingFeature}" class="mt-4 sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card shadow featureTableCard dark:bg-transparent  ">
    
                                            <div  class="tableHead flex flex-row flex-wrap justify-end">
                                                <!-- <div class="headingTag">
                                                    <h4 class="font-semibold text-2xl">Features List</h4>
                                                    <span class="font-medium smallText"> <span class="blueText">{{tabGroupForm.get('featuresCount').value}} </span> {{tabGroupForm.get('featuresCount').value < 2?'Feature':'Features'}} , <span class="blueText">{{tabGroupForm.get('totalDevHours').value}} </span> Dev Hours </span>
                                                </div> -->
                                                
                                                    
                                                <div class="flex  flex-wrap items-center gap-3 mt-6 sm:mt-0 sm:ml-4">
                                                    <!-- Search -->
                                                    <mat-form-field
                                                        class="fuse-mat-dense searchTab">
                                                        <mat-icon
                                                            class="icon-size-5 mr-1 "
                                                            matPrefix
                                                            [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                                        <input
                                                            matInput formControlName="search"
                                                            [autocomplete]="'off'"
                                                            [placeholder]="'Search Solution'" (input)="search($event)">
                                                    </mat-form-field>
                                                    <!-- Add filters -->
                                                    <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm  ">
                                                        <mat-select
                                                            [formControlName]="'technology_domain'"
                                                            [placeholder]="'Technology'" multiple (selectionChange)="filterCount()">
                                                            <!-- <mat-option #allSelected [value]="0">All</mat-option> -->
                                                            @for (option of cloudTypes; track option) {
                                                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                            }
                                                        </mat-select>
                                                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cpu-chip'"></mat-icon>
                                                    </mat-form-field>
                                                    <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop  ">
                                                        <mat-select
                                                            [formControlName]="'epic_name'"
                                                            [placeholder]="'Solution Module'" multiple (selectionChange)="filterCount()">
                                                            @for (option of epicTypes; track option) {
                                                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                            }
                                                        </mat-select>
                                                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:document-text'"></mat-icon>
                                                    </mat-form-field>
                                                    <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm  ">
                                                        <mat-select
                                                            [formControlName]="'complexity_value'"
                                                            [placeholder]="'Complexity'" multiple (selectionChange)="filterCount()">
                                                            @for (option of complexities; track option) {
                                                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                            }
                                                        </mat-select>
                                                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:arrows-pointing-out'"></mat-icon>
                                                    </mat-form-field>
                                                    <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab  mw-100 ">
                                                        <mat-select
                                                            [formControlName]="'release'"
                                                            [placeholder]="'Release'" multiple (selectionChange)="filterCount()">
                                                            @for (option of releases_list; track option) {
                                                                <mat-option [value]="option._id">{{option.name}}</mat-option>
                                                            }
                                                        </mat-select>
                                                        <!-- <img src="assets/icons/epic_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
                                                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
                                                      </mat-form-field>
                                                </div>
                                            </div>
    
                                        
                                            
                                            <!-- Main -->
                                            <fuse-alert class="mb-3 pl-6 pr-6" [dismissed]="false"  [dismissible]="true" *ngIf="flashMessageDelete" type="warn" [showIcon]="true" [appearance]="'soft'"> Solution deleted successfully from your proposal.</fuse-alert>
                                            <div  class="flex flex-auto overflow-hidden">
                                            
                                                <!-- feature list -->
                                                <div class="flex flex-col flex-auto tableBox mt-6">
                                                        <ng-container *ngIf="pro_data">
                                                            <ng-container *ngIf="pro_data.length>0;else noFeatures">
                                                                <div class="grid " [ngClass]="(_page_id=='proposal' && is_write_allowed)? 'inventory' : 'template-inventory'">
                                                                    <!-- Header -->
                                                                    <div class="border-b dark:bg-black font-semibold gap-4 grid md:px-8 ng-tns-c3037872216-3 px-6 py-4 sticky text-md text-secondary top-0 z-10 bg-white"  [ngClass]="(proposal_detail && proposal_detail.epics && proposal_detail.epics.length)?'inventory-grid' : 'small-inventory-grid'">
                                                                        <div class="font-bold text-default checkicm" *ngIf="(_page_id=='proposal' && is_write_allowed)" [ngClass]="{'selected2':(quickActionIds.length == featuresArray.controls.length)}">
                                                                            <mat-icon svgIcon="heroicons_outline:check-circle" class="cursor-pointer" (click)="openfixedBox('all')"></mat-icon>
                                                                        </div>
                                                                        <div *ngIf="(proposal_detail && proposal_detail.epics && proposal_detail.epics.length)" class="font-medium text-md uppercase text-secondary contentm ">Theme</div>
                                                                        <div *ngIf="(proposal_detail && proposal_detail.epics && proposal_detail.epics.length)" class="font-medium text-md uppercase text-secondary contentm ">Epic</div>
                                                                        <div class="font-medium text-md uppercase text-secondary contentm ">Module</div>
                                                                        <div class="font-medium text-md uppercase text-secondary contentm"> Sub-Module</div>
                                                                        <div class="font-medium text-md uppercase text-secondary contentm">Technology Domain</div>
                                                                        <!-- <div class="font-medium text-md uppercase text-secondary text-center contentm" > Complexity </div> -->
                                                                        <div class="font-medium text-md uppercase text-secondary text-center contentm"> Effort</div>
    
                                                                    
                                                                        <div class=" font-medium text-md uppercase text-secondary text-center checkicm">Action</div>
                                                                    </div>
                                                                    <!-- Rows -->
                                                                    <div class="tableData" >
                                                                        
                                                                        <ng-container formArrayName="features">
                                                                            <ng-container *ngFor="let feature of featuresArray.controls; let i = index" >
                                                                                <div class="grid items-center gap-4 py-3 px-6 md:px-8 border-b cursor-pointer"  [ngClass]="displaySelected(feature.get('_id').value)">
                                                                                    <div class="truncate2  checkicm " *ngIf="(_page_id=='proposal' && is_write_allowed)">
                                                                                        <mat-icon svgIcon="heroicons_outline:check-circle" class="cursor-pointer" (click)="openfixedBox(feature.get('_id').value)"></mat-icon>
                                                                                    </div>
                                                                                    <div *ngIf="(proposal_detail && proposal_detail.epics && proposal_detail.epics.length)" class="truncate2  contentm" (click)="is_write_allowed && toggleDetails(feature.get('_id').value,i)">
                                                                                        {{feature.get('theme').value}}
                                                                                    </div>
                                                                                    <div *ngIf="(proposal_detail && proposal_detail.epics && proposal_detail.epics.length)" class="truncate2  contentm" (click)="is_write_allowed && toggleDetails(feature.get('_id').value,i)">
                                                                                        {{feature.get('epic').value}}
                                                                                    </div>
                                                                                    <div class="truncate2  contentm" (click)="is_write_allowed && toggleDetails(feature.get('_id').value,i)">
                                                                                        {{feature.get('epicName').value}}
                                                                                    </div>
                                                                                    <div class="truncate2 contentm" (click)="is_write_allowed && toggleDetails(feature.get('_id').value,i)">
                                                                                        {{feature.get('name').value}}
                                                                                    </div>
                                                                                    <div class="truncate contentm" (click)="is_write_allowed && toggleDetails(feature.get('_id').value,i)">
                                                                                        {{feature.get('cloud').value}}
                                                                                    </div>
                                                                                    <!-- <div class="text-center contentm">
                                                                                        {{feature.get('complexity').value}}
                                                                                    </div> -->
                                                                                    <div class="text-center contentm break-all" (click)="is_write_allowed && toggleDetails(feature.get('_id').value,i)">
                                                                                        {{feature.get('effort').value}}
                                                                                    </div>
                                                                                    
                                                                                   
                                                                                    <div class="text-center checkicm flex  gap-2 items-center"  [ngClass]="is_write_allowed ? 'justify-end' : 'justify-center'">
                                                                                       
                                                                                        <button
                                                                                            class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                                                                            mat-stroked-button
                                                                                            (click)="toggleDetails(feature.get('_id').value,i)">
                                                                                            <mat-icon
                                                                                                class="icon-size-5"
                                                                                                [svgIcon]="selectedFeature?._id === feature.get('_id').value ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'"></mat-icon>
                                                                                        </button>
                                                                                        <div *ngIf="(_page_id === 'template' || !is_write_allowed)" class="text-center selectOption" style="width: 60px;">
                                                                                            {{feature.get('releaseName').value}}
                                                                                        </div>
                                                                                        <div *ngIf="((_page_id === 'project' || _page_id === 'proposal') && is_write_allowed)" class="releaseTag">
                                                                                            <mat-select [value]="feature.get('release').value" class="selectOption" (selectionChange)="onSolutionReleaseSelection($event,feature,i)">
                                                                                              @for (release of releases_list; track release) {
                                                                                                <mat-option [value]="release._id">{{release.short_name}}</mat-option>
                                                                                              }
                                                                                            </mat-select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="grid" *ngIf="(selectedFeature?._id === feature.get('_id').value && selectedFeature?.index === i)">
                                                                                    <ng-container *ngTemplateOutlet="rowDetailsTemplate; context: { inputFormGroup: featuresArray.at(i), index: i }"></ng-container>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    <ng-template #rowDetailsTemplate let-inputFormGroup="inputFormGroup" let-index="index">
                                                        <div class="shadow-lg overflow-hidden">
                                                        <div class="flex border-b">
                                                            <!-- Selected feature form -->
                                                            <form class="flex flex-col w-full" [formGroup]="inputFormGroup">
                                                            <div class="flex flex-col sm:flex-row p-8">
                                                                <div class="flex flex-auto flex-wrap">
                                                                    <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                                                    <div class="sm:col-span-2">
                                                                        <mat-form-field class="w-full">
                                                                        <mat-label>Solution Sub-Module Name</mat-label>
                                                                        <input matInput formControlName="name" [readonly]="(_page_id=='template' || !is_write_allowed)">
                                                                        </mat-form-field>
                                                                    </div>
                                                                    
                                                                    <div class="sm:col-span-2">
                                                                        <mat-form-field class="w-full">
                                                                        <mat-label>Solution Module Name</mat-label>
                                                                        <input matInput formControlName="epicName" [readonly]="(_page_id=='template' || !is_write_allowed)">
                                                                        </mat-form-field>
                                                                    </div>
                                                                    <div class="sm:col-span-2" *ngIf="themeListTypes.length">
                                                                        <mat-form-field class="w-full">
                                                                            <mat-label>Theme</mat-label>
                                                                            <mat-select *ngIf="(_page_id=='proposal' && is_write_allowed)" formControlName="theme"[placeholder]="'Select Option'" required (selectionChange)="featureThemeChange($event,inputFormGroup)">
                                                                            @for (option of themeListTypes; track option) {
                                                                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                                            }
                                                                            </mat-select>
                                                                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
                                                                            <input matInput *ngIf="(_page_id=='template' || !is_write_allowed)" formControlName="theme" [readonly]="(_page_id=='template' || !is_write_allowed)">
                                                                        </mat-form-field>
                                                                    </div>
                                                                    <div class="sm:col-span-2" *ngIf="themeListTypes.length">
                                                                        <mat-form-field class="w-full">
                                                                            <mat-label>Epic</mat-label>
                                                                            <mat-select *ngIf="(_page_id=='proposal' && is_write_allowed)" formControlName="epic" [placeholder]="'Select Option'" required>
                                                                                <ng-container *ngFor="let option of inputFormGroup.get('epicList').value">
                                                                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                                                </ng-container>
                                                                            </mat-select>
                                                                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                                                                            <input matInput *ngIf="(_page_id=='template' || !is_write_allowed)" formControlName="epic" [readonly]="(_page_id=='template' || !is_write_allowed)">
                                                                        </mat-form-field>
                                                                    </div>
                                                                        <div class="sm:col-span-2">
                                                                            <mat-form-field class="w-full">
                                                                                <mat-label>Complexity</mat-label>
                                                                                <mat-select *ngIf="(_page_id=='proposal' && is_write_allowed)" formControlName="complexity"[placeholder]="'Select Option'" required (selectionChange)="featureComplexityChange($event,inputFormGroup)">
                                                                                @for (option of complexities; track option) {
                                                                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                                                                }
                                                                                </mat-select>
                                                                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:arrows-pointing-out'"></mat-icon>
                                                                                <input matInput *ngIf="(_page_id=='template' || !is_write_allowed)" formControlName="complexity" [readonly]="(_page_id=='template' || !is_write_allowed)">
                                                                            </mat-form-field>
                                                                        </div>
                                                                        <div class="sm:col-span-2">
                                                                            <mat-form-field class="w-full">
                                                                            <mat-label>Components Count</mat-label>
                                                                            <input type="number" matInput formControlName="componentsCount" [readonly]="(_page_id=='template' || !is_write_allowed)"(change)="onComponentsCountChange($event,inputFormGroup)" min="0">
                                                                            </mat-form-field>
                                                                        </div>
                                                                        <div class="sm:col-span-2">
                                                                            <mat-form-field class="w-full">
                                                                            <mat-label>Effort</mat-label>
                                                                            <input type="number" matInput formControlName="effort" [readonly]="(_page_id=='template' || !is_write_allowed)" min="0" required>
                                                                            </mat-form-field>
                                                                        </div>
                                                                        <div class="sm:col-span-2">
                                                                            <mat-form-field class="w-full">
                                                                                <mat-label>Release</mat-label>
                                                                                <mat-select *ngIf="(_page_id=='proposal' && is_write_allowed)" formControlName="release" [placeholder]="'Select Option'" required (selectionChange)="featureReleaseChange($event,inputFormGroup)">
                                                                                @for (option of releases_list; track option) {
                                                                                    <mat-option [value]="option._id">{{option.name}}</mat-option>
                                                                                }
                                                                                </mat-select>
                                                                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
                                                                                <input matInput *ngIf="(_page_id=='template' || !is_write_allowed)" formControlName="complexity" [readonly]="(_page_id=='template' || !is_write_allowed)">
                                                                            </mat-form-field>
                                                                        </div>
                                                                    </div>
                                                                    <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                                                    <div class="sm:col-span-4">
                                                                        <div *ngIf="(_page_id=='proposal' && is_write_allowed); else disabledInput">
                                                                            <span class="font-medium block mb-2">Solution Description</span>
                                                                            <tiptap-bubble-menu [editor]="editor2" [id]="'description1-'+index">
                                                                                <app-editor-controls 
                                                                                [editor]="editor2" 
                                                                                [selectedText]="selectedText"
                                                                                (completeQuickAction)="onCompleteQuickAction($event)">
                                                                                </app-editor-controls>
                                                                            </tiptap-bubble-menu>
                                                                            
                                                                            <tiptap-editor 
                                                                            [id]="'description-'+index"
                                                                            [editor]="editor2" 
                                                                            [formControlName]="'featureDescription'"
                                                                            (blur)="onTextareaBlur()"
                                                                            (mouseenter)="onMouseEnter()"
                                                                            (mouseleave)="onMouseLeave()"
                                                                            (mousedown)="onMouseDown($event, 'description-'+index)"
                                                                            (mouseup)="onTextSelect($event, 'description-'+index)"
                                                                            (select)="onSelectionChange($event, 'description-'+index)"
                                                                            (keydown)="onSelectionChange($event, 'description-'+index)" >
                                                                            </tiptap-editor>
                                                                        </div>
                                                                        <ng-template #disabledInput>
                                                                            <span class="font-medium block mb-2">Solution Description</span>
                                                                            <tiptap-editor #featureEditor [id]="'descriptionreadonly-'+index" [editor]="editor_feature_readonly"  [formControlName]="'featureDescription'" >
                                                                            </tiptap-editor>
                                                                    </ng-template>
                                                                    </div>

                                                                        <!-- <div class="sm:col-span-4 -mt-6">
                                                                              <span class="font-medium block mb-2 text-black">Solution Reason</span>
      
                                                                              <div class="red-editor">
                                                                                <div class="tiptap" >
                                                                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque quis voluptates placeat obcaecati debitis mollitia unde similique facere pariatur ab quia delectus nisi ullam ex aperiam deserunt, impedit, maiores dolore?
                                                                                </div>
                                                                              </div>
                                                                        </div> -->

                                                                    <!-- <div class="sm:col-span-2">
                                                                        <div *ngIf="(_page_id=='proposal' && is_write_allowed); else solutionInput">
                                                                            <span class="font-medium block mb-2">Solution Description</span>
                                                                            <tiptap-bubble-menu [editor]="editor3" [id]="'solution1-'+index">
                                                                                <app-editor-controls 
                                                                                [editor]="editor3" 
                                                                                [selectedText]="selectedText"
                                                                                (completeQuickAction)="onCompleteQuickAction($event)">
                                                                                </app-editor-controls>
                                                                            </tiptap-bubble-menu>
                                                                            
                                                                            <tiptap-editor 
                                                                            [id]="'solution-'+index"
                                                                            [editor]="editor3" 
                                                                            [formControlName]="'solutionAssumption'"
                                                                            (blur)="onTextareaBlur()"
                                                                            (mouseenter)="onMouseEnter()"
                                                                            (mouseleave)="onMouseLeave()"
                                                                            (mousedown)="onMouseDown($event, 'solution-'+index)"
                                                                            (mouseup)="onTextSelect($event, 'solution-'+index)"
                                                                            (select)="onSelectionChange($event, 'solution-'+index)"
                                                                            (keydown)="onSelectionChange($event, 'solution-'+index)" >
                                                                            </tiptap-editor>
                                                                        </div>
                                                                        <ng-template #solutionInput>
                                                                            <span class="font-medium block mb-2">Solution Description</span>
                                                                            <tiptap-editor #solutionEditor [id]="'solutionreadonly-'+index" [editor]="editor_solution_readonly"  [formControlName]="'solutionAssumption'" >
                                                                            </tiptap-editor>
                                                                    </ng-template>
                                                                    </div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="flex items-center  w-full border-t px-8 py-4 gap-2" *ngIf="(_page_id=='proposal' && is_write_allowed)" [ngClass]="flashMessageType?'justify-between':'justify-end' ">
    
                                                                <fuse-alert  *ngIf="flashMessageType === 'success' && flashMessageType" type="success" [showIcon]="true" [appearance]="'soft'"> {{flashMessage}}</fuse-alert>
    
                                                                <fuse-alert *ngIf="flashMessageType === 'error' && flashMessageType" type="warn" [showIcon]="true" [appearance]="'soft'"> An error occurred, try again!</fuse-alert>
    
                                                                
                                                                <div class="flex items-center gap-3">
                                                                    <button class="-ml-4 dark" mat-button [color]="'black'" (click)="deleteSelectedFeature(inputFormGroup)">Delete</button>
                                                                    <button mat-flat-button [color]="'primary'" [disabled]="inputFormGroup.get('effort')?.value === null || inputFormGroup.get('effort')?.value === undefined || inputFormGroup.get('effort')?.value === ''" (click)="updateSelectedFeature(inputFormGroup)">Update</button>
                                                                </div>
                                                            </div>
                                                            </form>
                                                        </div>
                                                        </div>
                                                    </ng-template>
                                                    
                                                    <ng-template #noFeatures>
                                                        <div class="p-8 sm:p-16 border-t tracking-tight text-center">
                                                            <mat-icon class="icon-size-18" [svgIcon]="'heroicons_outline:document-minus'"></mat-icon>
                                                            <h4 class="text-4xl font-semibold ">No Solution Found</h4>
                                                            <span class="block mt-3 mb-3">No solutions have been added yet. They will be displayed in the solution table once added.</span>
                                                            <button *ngIf="is_write_allowed" class="" mat-flat-button [color]="'primary'" (click)="openDialog('addfeature')"  >
                                                                <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                                                <span class="">Add New Solution </span>
                                                            </button>
                                                        </div>
                                                    </ng-template>
                                                    
    
                                                    <div class="quickAction" *ngIf="quickActionIds.length">
                                                        <div class="flex gap-6 pl-3 text-white">
                                                            <div>{{quickActionIds.length}} {{quickActionIds.length>1?'Fetures':'Feature'}} Selected</div>
                                                            <div class="divider"></div>
                                                            <div class="flex gap-1 items-center quickBt cursor-pointer" (click)="bulkDelete()">
                                                                <mat-icon svgIcon="heroicons_outline:trash" ></mat-icon>
                                                                <span>Bulk Delete</span>
                                                            </div>
                                                            <div class="divider"></div>
                                                            <div class="flex gap-1 items-center quickBt cursor-pointer" (click)="bulkExport()">
                                                                <mat-icon svgIcon="heroicons_outline:bars-arrow-down" ></mat-icon>
                                                                <span>Bulk Export</span>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>
                                                
                                            </div>
                                            
                                            
                                        </div>
                                        <!-- <div *ngIf="loadingFeature" class="loadingf2">
                                            <div class="flex loaderAlert flex-wrap ">
                                                <img src="assets/icons/aiicon1.svg" alt="">
                                                <p>GetGenerative.ai is fetching features and generating a summary.</p>
                                                <div class="loaderPercent">
                                                    <span>{{progressValue}}%</span>
                                                    <mat-progress-bar mode="determinate" class="smallProgress" [value]="progressValue"></mat-progress-bar>
                                                </div>
                                                <span *ngIf="progressValue===100" class="closeIcon" (click)="loadingFeature=false;">
                                                    <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
                                                </span>
                                            </div>
                        
                                        </div> -->
    
                                        </div>
            
                                    </ng-template>
                                </mat-tab>
                                <mat-tab id="requireView">
                                    <ng-template mat-tab-label>
                                        <span class="inline-flex items-center space-x-2">
                                            <span class="flex items-center gap-1"><mat-icon class="icon-size-5 mat-tab-activeic" svgIcon="heroicons_outline:check"></mat-icon> Requirements View </span>
                                        </span>
                                        
                                    </ng-template>
                                    
                                    <ng-template matTabContent >

                                        <app-requirements-view (onSaveThemeAndEpics)="onSaveThemeAndEpics($event)" (onSaveEpics)="onSaveEpics($event)" [proposal]="proposal_detail" [themeTypes]="themeTypes" [_page_id]="_page_id" [is_write_allowed]="is_write_allowed" (onRefreshProposal)="getProposal(true)"></app-requirements-view>
            
                                    </ng-template>
                                </mat-tab>

                                <mat-tab id="requireView">
                                    <ng-template mat-tab-label>
                                        <span class="inline-flex items-center space-x-2">
                                            <span class="flex items-center gap-1"><mat-icon class="icon-size-5 mat-tab-activeic" svgIcon="heroicons_outline:check"></mat-icon>  Manage Releases </span>
                                        </span>
                                        
                                    </ng-template>
                                    
                                    <ng-template matTabContent >

                                        
                                

                                    <app-manage-release   [proposal]="proposal_detail" [_page_id]="_page_id" [is_write_allowed]="is_write_allowed" [themeTypes]="themeTypes" (onRefreshProposal)="getProposal(true)" (onSaveEpics)="onSaveEpics($event)" (onFeatureUpdate)="onFeatureUpdate($event)"></app-manage-release>
            
                                    </ng-template>
                                </mat-tab>

                                </mat-tab-group>



                                


                                <div *ngIf="(_page_id=='proposal' && is_write_allowed)" class="flex justify-end pt-9">
                                    <mat-slide-toggle class="mt-8 sm:mt-0 sm:ml-auto reviewToggle" [color]="'primary'" [formControlName]="'progress_indicator_solutions'"(change)="toggleChange($event,'solution')">
                                        Complete Review
                                    </mat-slide-toggle>
                                </div>
                            </ng-template>
                        </form>
                    </mat-tab>

                    <!-- Assumptions -->
                    <mat-tab id="assumTab">
                        <form [formGroup]="tabGroupForm">
                            <ng-template mat-tab-label>
                                <span class="inline-flex items-center space-x-2">
                                    <mat-icon svgIcon="heroicons_outline:adjustments-horizontal"></mat-icon>
                                    <span class="">Project Plan</span>
                                    
                                </span>
                            </ng-template>
                            <span *ngIf="flashMessageType === 'success'">All your changes are saved successfully</span>
                            <ng-template matTabContent>
                                <div *ngIf="(show_view_count && proposal_detail.admin_details && proposal_detail.admin_details.personal)"   class="min-w-full min-h-11 mb-5 custom-note rounded-lg flex justify-between items-center pl-2" >
                                    <div class="flex items-center">
                                        <mat-icon svgIcon="heroicons_outline:eye" color="blue"></mat-icon>
                                    <div class="ml-5 text-[#4F45E4]">
                                        This proposal is shared by {{proposal_detail.admin_details.personal.name.first}} {{proposal_detail.admin_details.personal.name.last}} with {{proposal_acess_status}}-only access.
                                    </div>
                                    </div>
                                    <a class="mr-2 cursor-pointer" (click)="closeView();">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.05957 15L15.1796 5M5.05957 5L15.1796 15" stroke="#4F45E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>
                                </div>

                                <div class="position-relative">                            
                                    
                                    <div>
                                        <div class="flex  justify-between leading-6 text-2xl tracking-tight">
                                            Project Plan Details
                                        </div>
                                        <p class="mt-2 lightgrey" >AI-generated recommendations for resource allocation based on project complexity. Includes resource costs and a breakdown of project phases with time and cost distributions.</p>
                                    </div>

                                    <div class="bg-white costDist mt-6 p-6 rounded-2xl shadow">
                                        <div class="costHeading flex gap-10 mb-6 justify-between">
                                            <div class="headingTag">
                                                <h4 class="font-semibold text-2xl">Duration and Cost Distribution</h4>
                                                <p>Project phases timeline with resourcing and cost Distribution based on development hours.</p>
                                            </div>
                                            <div *ngIf="_page_id=='proposal'" class="downloadSum">
                                                <button mat-flat-button (click)="downloadSummary()" [disabled]="(download_summary_loader)">
                                                    <mat-progress-spinner *ngIf="download_summary_loader" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                                                    Download Summary
                                                </button>
                                            </div>
                                        </div>
                                        <div class="distrib flex mb-6" *ngIf="(!isMobile && pro_phase_data.length>0)" formArrayName="phases">
                                            <div *ngFor="let eachPhase of phasesArray.controls; let i = index" [formGroupName]="i" class="boxGrp" [ngClass]="{'discoveryBox':eachPhase.get('order').value==1,'devBox':eachPhase.get('order').value==2,'uatBox':eachPhase.get('order').value==3,'depBox':eachPhase.get('order').value==4,'careBox':eachPhase.get('order').value==5}">
                                                <div class="singleBox">
                                                    {{eachPhase.get('name').value}} <br><b>{{eachPhase.get('weeks').value}} Weeks</b>
                                                </div>
                                                <div class="hoverBox">
                                                    <img src="assets/icons/blueconnect.png" alt="">
                                                    <div class="shadow rounded-2xl bg-white p-5" >
                                                        <div class="head">
                                                            <h3> {{eachPhase.get('name').value}}</h3>
                                                        </div>
                                                        <div class="flex flex-wrap keyp">
                                                            <span><b>Total Cost</b> - {{getCurrencyIc(eachPhase.get('currency').value)}}{{formatMoney(eachPhase.get('cost').value)}}</span>
                                                            <span><b>Total Hours</b> - {{eachPhase.get('hours').value}} </span>
                                                            <span><b>Resources</b></span>
                                                        </div>
                                                        
                                                        <div *ngIf="getPhaseResources(i).length">
                                                            <ul class="resourcesTag rounded-2xl" formArrayName="phase_resources" >
                                                                <li *ngFor="let resource of getPhaseResources(i).controls; let j = index">
                                                                    {{ resource.get('short_name').value }} ({{ resource.get('count').value }})
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <mat-tab-group class="planBox" *ngIf="(isMobile && pro_phase_data.length>0)" formArrayName="phases">
                                            <mat-tab *ngFor="let eachPhase of phasesArray.controls; let i = index" [formGroupName]="i" >
                                                <ng-template mat-tab-label>
                                                    <div class="boxGrp" [ngClass]="{'discoveryBox':eachPhase.get('order').value==1,'devBox':eachPhase.get('order').value==2,'uatBox':eachPhase.get('order').value==3,'depBox':eachPhase.get('order').value==4,'careBox':eachPhase.get('order').value==5}">
                                                        <div class="singleBox">
                                                            {{eachPhase.get('name').value}}: <br><b>{{eachPhase.get('weeks').value}} Weeks</b>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <div class="content">
                                                    <div class="head">
                                                        <h3> {{eachPhase.get('name').value}}</h3>
                                                    </div>
                                                    <div class="flex flex-wrap keyp">
                                                        <span><b>Total Cost</b> - {{getCurrencyIc(eachPhase.get('currency').value)}} {{formatMoney(eachPhase.get('cost').value)}}</span>
                                                        <span><b>Total Hours</b> - {{eachPhase.get('hours').value}} </span>
                                                        <span><b>Resources</b></span>
                                                    </div>
                                                    <div *ngIf="getPhaseResources(i).length">
                                                        <ul class="resourcesTag rounded-2xl" formArrayName="phase_resources" >
                                                            <li *ngFor="let resource of getPhaseResources(i).controls; let j = index">
                                                                {{ resource.get('short_name').value }} ({{ resource.get('count').value }})
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </mat-tab>
                                        </mat-tab-group>

                                    </div>

                                    <div>
                                        <app-resource-allocation [(proposalID)]="_proposal_id" [(proposal_detail)]="proposal_detail" [(resources)]="resourcesArray" [(phases)]="phasesArray" [(is_write_allowed)]="is_write_allowed" [(_page_id)]="_page_id" (onResourceModify)="onResourceModifyAction($event)"></app-resource-allocation>
                                    </div>


                                    <!-- <div *ngIf="loadingFeature" class="loadingf3">                    
                                        <div class="flex loaderAlert flex-wrap ">
                                            <img src="assets/icons/aiicon1.svg" alt="">
                                            <p>GetGenerative.ai is fetching features and generating a summary.</p>
                                            <div class="loaderPercent">
                                                <span>{{progressValue}}%</span>
                                                <mat-progress-bar mode="determinate" class="smallProgress" [value]="progressValue"></mat-progress-bar>
                                            </div>
                                            <span *ngIf="progressValue===100" class="closeIcon" (click)="loadingFeature=false;">
                                                <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
                                            </span>
                                        </div>
                    
                                    </div> -->
                                </div>
                                <div class="buttonSec flex justify-start mt-6" *ngIf="_page_id=='template'"> 
                                    <button mat-flat-button [color]="'primary'" (click)="chooseTemplate()" >
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_outline:rectangle-stack'" ></mat-icon>
                                        <span class="ml-2">{{isPaid?'Use Template':'Subscribe to Use Template'}}</span>
                                    </button>
                                </div>
                                <div *ngIf="(_page_id=='proposal' && is_write_allowed)" class="flex justify-end pt-9">
                                    <mat-slide-toggle class="mt-8 sm:mt-0 sm:ml-auto reviewToggle" [color]="'primary'" [formControlName]="'progress_indicator_project'"(change)="toggleChange($event,'project')">
                                        Complete Review
                                    </mat-slide-toggle>
                                </div>
                            </ng-template>
                        </form>
                    </mat-tab>

                    <mat-tab id="insights" *ngIf="showInsights">
                        <ng-template mat-tab-label>
                            <span class="inline-flex items-center space-x-2">
                                <img  [src]="selectedIndex==4?'assets/icons/insights_icon.svg':'assets/icons/insights_disabled.svg'">
                                <span class="">AI Insights</span>
                            </span>
                            
                        </ng-template>
                        
                        <ng-template matTabContent >

                            <app-ai-insights class="min-w-full" [(proposalID)]="_proposal_id" [(proposal)]="proposal_detail" [(user)]="user" [(accessStatus)]="proposal_acess_status" (onKCDocUpload)="onKCDocUpload($event)"></app-ai-insights>

                        </ng-template>
                    </mat-tab>

                    <mat-tab id="documentai">
                        <ng-template mat-tab-label>
                            <span class="inline-flex items-center space-x-2">
                                <!-- <mat-icon svgIcon="heroicons_outline:document-magnifying-glass"></mat-icon> -->
                                <img *ngIf="is_write_allowed" [src]="selectedIndex==5?'assets/icons/blue-document-ai.svg':'assets/icons/document-ai.svg'">
                                <img *ngIf="!is_write_allowed" [src]="selectedIndex==4?'assets/icons/blue-document-ai.svg':'assets/icons/document-ai.svg'">
                                <span class="">Document AI</span>
                            </span>
                        </ng-template>
                        <ng-template matTabContent >
                            <app-documented-ai class="min-w-full" [(proposalID)]="_proposal_id" [(proposal)]="proposal_detail" [(user)]="user" [(accessStatus)]="proposal_acess_status" (onKCDocUpload)="onKCDocUpload($event)" [receiveData]="parentData"></app-documented-ai>
                        </ng-template>
                    </mat-tab>

                    <mat-tab id="metadata">
                        <ng-template mat-tab-label>
                            <span class="inline-flex items-center space-x-2">
                                <!-- <mat-icon svgIcon="heroicons_outline:document-magnifying-glass"></mat-icon> -->
                                <img  *ngIf="is_write_allowed"   [src]="selectedIndex==6?'assets/icons/metadata-blue.svg':'assets/icons/metadata-gray.svg'">
                                <img  *ngIf="!is_write_allowed"   [src]="selectedIndex==5?'assets/icons/metadata-blue.svg':'assets/icons/metadata-gray.svg'">
                                <span class="">Metadata</span>
                            </span>
                        </ng-template>
                        <ng-template matTabContent >
                            <app-metadata class="min-w-full" [(proposalID)]="_proposal_id" [(proposal)]="proposal_detail" [(user)]="user" [(accessStatus)]="proposal_acess_status" [is_write_allowed]="is_write_allowed"></app-metadata>
                        </ng-template>
                    </mat-tab>

                    <!-- <mat-tab id="design">
                        <ng-template mat-tab-label>
                            <span class="inline-flex items-center space-x-2">
                                <img [src]="selectedIndex==6?'assets/icons/insights_icon.svg':'assets/icons/insights_disabled.svg'">
                                <span class="">Design</span>
                            </span>
                        </ng-template>
                        
                        <ng-template matTabContent >
                            <app-userstory-view [(proposal)]="proposal_detail" [_page_id]="_page_id" [is_write_allowed]="is_write_allowed" [(themeTypes)]="themeTypes" [(epicTypes)]="allEpics" (onSaveEpics)="onSaveEpics($event)" (onFeatureUpdate)="onFeatureUpdate($event)"></app-userstory-view>
                        </ng-template>
                    </mat-tab> -->

                </mat-tab-group>

            </div>
    </div>

</div>


</div>


<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'downloadDrawer'"
    [position]="'right'" (openedChanged)="openedChanged($event)"
    #downloadDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex flex-row h-20 items-center justify-center relative min-h-20  px-6 text-center w-full"> 
            <div class="ml-3 text-2xl font-semibold tracking-tight">Export Proposal</div>
            <button
                class="absolute right-0"
                mat-icon-button
                (click)="downloadDrawer.close();clear();">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    
            </button>
        </div>

        <div class="flex flex-col p-6">
            <form [formGroup]="downloadGroupForm">
                <div class="actionHead">
                    <ul>
                        <li class="actHead font-medium text-lg">Action</li>
                        <li class="flex gap-2">
                            <button class="px-8 exportBt" mat-flat-button  type="button" (click)="download('pdf')" [disabled]="(download_pdf_loader || download_doc_loader)">
                                <mat-icon *ngIf="!download_pdf_loader" [svgIcon]="'heroicons_outline:arrow-down-tray'"></mat-icon>
                                <mat-progress-spinner
                                    *ngIf="download_pdf_loader"
                                    [diameter]="24"
                                    [mode]="'indeterminate'"></mat-progress-spinner>
                                Export To PDF
                            </button>
                            <button class="px-8 exportBt" mat-flat-button  type="button" (click)="download('doc')" [disabled]="(download_pdf_loader || download_doc_loader)">
                                <mat-icon *ngIf="!download_doc_loader" [svgIcon]="'heroicons_outline:arrow-down-tray'"></mat-icon>
                                <mat-progress-spinner
                                    *ngIf="download_doc_loader"
                                    [diameter]="24"
                                    [mode]="'indeterminate'"></mat-progress-spinner>
                                Export To Word Doc
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="mt-6 logoSec"> 
                    <h4 class="actHead font-medium text-gray-700 w-full mb-4">Logo <span class="font-medium text-gray-400 text-sm">Max 4MB</span></h4>
                    <div class="logoInner flex justify-between">
                        <div *ngIf="!imageChangedEvent" class="chooseFile">
                            <div (click)="selectLogo()">
                                <span *ngIf="!downloadGroupForm.get('logo').value" class="fileSelect">
                                    Choose File
                                </span>
                                <img *ngIf="downloadGroupForm.get('logo').value" [src]="getImageURL(downloadGroupForm.get('logo').value)" alt="">
                                <input id="file" name="file" style="display:none;" type="file" (change)="fileChangeEvent($event)" />
                            </div>
                            <span *ngIf="downloadGroupForm.get('logo').value" class="removeImg" (click)="removeImage()"> 
                                <mat-icon
                                class="text-current"
                                [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                            </span>
                        </div>
                        <image-cropper *ngIf="imageChangedEvent" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
                        [aspectRatio]="4 / 3" format="png" output="base64" (imageCropped)="imageCropped($event)"></image-cropper>
                        <!-- <img src="assets/icons/nikeLogo.png" alt=""> -->
                    </div>
                
                    
                    
                </div>

                <div class="pSetting mt-6">
                    <h4 class="actHead font-medium text-lg text-gray-700 w-full">Print Settings</h4>
                    <div class="grid sm:grid-cols-4 gap-6 w-full mt-4">
                        <div class="sm:col-span-4 flex items-center justify-between">
                            <div
                                class="flex-auto cursor-pointer">
                                <div class="leading-6 font-medium">Executive Summary</div>
                            </div>
                            <mat-slide-toggle
                                class="ml-4" [formControlName]="'executiveSummary'"
                                [color]="'primary'">
                            </mat-slide-toggle>
                        </div>
                        <div class="sm:col-span-4 flex items-center justify-between">
                            <div
                                class="flex-auto cursor-pointer">
                                <div class="leading-6 font-medium">About Us</div>
                            </div>
                            <mat-slide-toggle
                                class="ml-4" [formControlName]="'company_bio'"
                                [color]="'primary'">
                            </mat-slide-toggle>
                        </div>
                        <div class="sm:col-span-4 flex items-center justify-between">
                            <div
                                class="flex-auto cursor-pointer">
                                <div class="leading-6 font-medium">Project Scope</div>
                            </div>
                            <mat-slide-toggle
                                class="ml-4" [formControlName]="'scope'"
                                [color]="'primary'">
                            </mat-slide-toggle>
                        </div>
                        <div class="sm:col-span-4 flex items-center justify-between">
                            <div
                                class="flex-auto cursor-pointer">
                                <div class="leading-6 font-medium">Project Commercials</div>
                            </div>
                            <mat-slide-toggle
                                class="ml-4" [formControlName]="'commercials'"
                                [color]="'primary'">
                            </mat-slide-toggle>
                        </div>
                        <div class="sm:col-span-4 flex items-center justify-between">
                            <div
                                class="flex-auto cursor-pointer">
                                <div class="leading-6 font-medium">Project Deliverables	</div>
                            </div>
                            <mat-slide-toggle
                                class="ml-4" [formControlName]="'deliverables'"
                                [color]="'primary'">
                            </mat-slide-toggle>
                        </div>
                        <div class="sm:col-span-4 flex items-center justify-between">
                            <div
                                class="flex-auto cursor-pointer">
                                <div class="leading-6 font-medium">Terms and Conditions</div>
                            </div>
                            <mat-slide-toggle
                                class="ml-4" [formControlName]="'company_tnc'"
                                [color]="'primary'">
                            </mat-slide-toggle>
                        </div>
                        <div class="sm:col-span-4 flex items-center justify-between">
                            <div
                                class="flex-auto cursor-pointer">
                                <div class="leading-6 font-medium">Project Conclusion</div>
                            </div>
                            <mat-slide-toggle
                                class="ml-4" [formControlName]="'conclusion'"
                                [color]="'primary'">
                            </mat-slide-toggle>
                        </div>

                    </div>
                </div>   
            </form>
        </div>

    </div>

</fuse-drawer>