import { MatFormFieldModule } from '@angular/material/form-field';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-add-theme',
  standalone: true,
  imports: [FuseDrawerComponent,NgIf,MatIcon,MatMenuModule,ReactiveFormsModule,MatFormFieldModule,MatInputModule],
  templateUrl: './add-theme.component.html',
  styleUrl: './add-theme.component.scss'
})
export class AddThemeComponent {
  @ViewChild("themeDrawer")public themeDrawer:FuseDrawerComponent;
  isSavingTheme:boolean=false;
  themeForm:UntypedFormGroup;
  themeSaved:boolean=false;

  isEdit:boolean=false;
  selectedIndex:number=0;

  @Output()
  onSaveTheme=new EventEmitter<any>();

  constructor(private _formBuilder:UntypedFormBuilder){
    this.themeForm=this._formBuilder.group({
      themeName:["",Validators.required]
    });
  }

  ngOnInit(){
   
  }

  openedChanged(event){
    
  }
  onDeleteTheme(){

  }
  onCloseDrawer(){
    this.themeForm.reset();
  }
  onSaveThemeEvent(){
    console.log("Theme to save:",this.isEdit,this.selectedIndex);
    this.onSaveTheme.emit({theme:this.themeForm.get("themeName").value,isEdit:this.isEdit,index:this.selectedIndex});
    this.themeForm.reset();
    this.themeDrawer.close();
  } 
}
