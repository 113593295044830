import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';

import { IPrompt } from './prompts.types';
import { IPagination } from '../knowledge-base/knowledge-base.types';
import { environment } from 'environments/environment';
import { UserService } from '../user/user.service';
import { AuthService } from '../auth/auth.service';

@Injectable({providedIn: 'root'})
export class PromptsService
{
    private _authService=inject(AuthService);
    private _httpClient = inject(HttpClient);
    private _prompts: ReplaySubject<IPrompt[]> = new ReplaySubject<IPrompt[]>(1);
    private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);
    private _templatePrompts:ReplaySubject<IPrompt[]>=new ReplaySubject<IPrompt[]>(1);

    public promptActions=[
            
        { value: "assets/icons/replace_icon.svg", label: 'Replace Selection' },
        { value: "assets/icons/insert_icon.svg", label: 'Insert Below' },
        { value: "assets/icons/try_again_icon.svg", label: 'Try Again' },
        { value: "assets/icons/delete_icon.svg", label: 'Delete' }
        ];
   public  promptTypes=[
        { value: 0, label: 'Internal' },
        { value: 1, label: 'External' },
        { value: 2, label: 'Content Creation' },
        ];
    
        public promptIcons=[
            
            { value: "assets/icons/more_context.svg", label: 'Icon1' },
            { value: "assets/icons/summary_less.svg", label: 'Icon2' },
            { value: "assets/icons/summary_more.svg", label: 'Icon3' },
            { value: "assets/icons/make_pro.svg", label: 'Icon4' },
            { value: "assets/icons/custom_prompt.svg", label: 'Icon5' },
            ];

        public screenTypes=[

            { value: 0, label: 'Rewrite Requirements' },
            { value: 1, label: 'Refine Requirements' },
            { value: 2, label: 'Define Goal' },
            { value: 3, label: 'Suggest Project Names' },
            { value: 4, label: 'Executive Summary' },
            { value: 5, label: 'Master Feature Extraction' },
            { value: 6, label: 'Feature Complexity' },
            { value: 7, label: 'Identify No of Components' },
            { value: 8, label: 'Effort Assumption' },
            { value: 9, label: 'Proposal Conclusion' },
            { value: 10, label: 'Personalize Feature Description' },
            { value: 11, label: 'Template' },
            { value: 12, label: 'Summary-Overview' },
            { value: 13, label: 'Summary-About Customer' },
            { value: 14, label: 'Summary-Project Challenges' },
            { value: 15, label: 'Summary-Project Requirements' },
            { value: 16, label: 'Summary-ExpectedOutcome' },

            { value: 17, label: 'Scope-Overview' },
            { value: 18, label: 'Scope-Project Scope' },
            { value: 19, label: 'Scope-Out Of Scope' },



            { value: 20, label: 'Test Plan - Test Strategy Overview' },
            { value: 21, label: 'Test Plan - Testing Scope' },
            { value: 22, label: 'Test Plan - Defect Management' },
            { value: 23, label: 'Test Plan - Test Tools' },
            { value: 24, label: 'Test Plan - Test Environment' },
            { value: 25, label: 'Test Plan - Out of Scope' },


            { value: 26, label: 'Test Plan - Create Test Cases' },
            { value: 27, label: 'Test Plan - Type of Testing Recommendations' },
            { value: 28, label: 'Test Plan - Extract Testing information from document' },







            ];
    
    
            public   promptVariables=[
                { value: 0, label: 'Context' },
                { value: 1, label: 'Username' },
                { value: 2, label: 'Project Name' },
                { value: 3, label: 'Feature' },
                { value: 4, label: 'Technology' },
                { value: 5, label: 'Industry' },
                { value: 6, label: 'Cloud' },
                { value: 7, label: 'Epic Name' },
                { value: 8, label: 'Executive Summary' },
                { value: 9, label: 'Project Scope' },
                { value: 10, label: 'Feature Description' },
                { value: 11, label: 'Solution Description' },
                { value: 12, label: 'Complexity' },
                { value: 13, label: 'No Of Components' },
                { value: 14, label: 'Efforts' },
                { value: 15, label: 'Calculated Effort' },
                ];
    
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for knowledgebase
     *
     * @param value
     */
    set prompts(value: IPrompt[])
    {
        // Store the value
        this._prompts.next(value);
    }

    get prompts$(): Observable<IPrompt[]>
    {
        return this._prompts.asObservable();
    }

     /**
     * Getter for pagination
     */
     get pagination$(): Observable<IPagination>
     {
         return this._pagination.asObservable();
     }
     get templatePrompts$():Observable<IPrompt[]>
     {
        return this._templatePrompts.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(pageIndex:number): Observable<IPrompt[]>
    {
        let skip=pageIndex*10;

       return this._httpClient.get(environment.api_endpoint + 'prompts?skip='+skip+'&limit=10', { headers: { method: 'get_prompts',Authorization: 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                console.log("response",response);
                
                if(response.success){
                    
                        this._pagination.next({
                            length: response.total,
                            size: 10,
                            page: pageIndex,
                            lastPage: 0,
                            startIndex: 0,
                            endIndex: (response.total-1)
                        });

                        this._prompts.next(response.data);
                        return of(response.data);

                }else{
                    return of([]);
                }
            }),
        );


    }
    getTemplatePrompts(): Observable<IPrompt[]>
    {
        
       return this._httpClient.get(environment.api_endpoint + 'prompts', { headers: { method: 'get_template_prompts',Authorization: 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                console.log("response",response);
                
                if(response.success){

                        this._templatePrompts.next(response.data);
                        return of(response.data);

                }else{
                    return of([]);
                }
            }),
        );


    }

    savePrompt(prompt:IPrompt): Observable<boolean>
    {
        

       return this._httpClient.post(environment.api_endpoint + 'prompts',JSON.stringify(prompt), { headers: { method: 'create','Authorization': 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                if(response.success){

                    
                    return of(true);

                    
                }else{
                    return of(false);
                }
            }),
        );

    }
    
    deletePrompt(promptID:string): Observable<boolean>
    {

       return this._httpClient.post(environment.api_endpoint + 'prompts',JSON.stringify({_id:promptID}), { headers: { method: 'delete','Authorization': 'Bearer ' + this._authService.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                if(response.success){

                    
                    return of(true);

                    
                }else{
                    return of(false);
                }
            }),
        );

    }

    getPromptType(type:number){

        for(let pType of this.promptTypes){
            if(pType.value==type){
                return pType.label;
            }
        }

        return "";

      }

      getPromptApplication(type:number){
        for(let pType of this.screenTypes){
            if(pType.value==type){
                return pType.label;
            }
        }

        return "";
      }
    
}
