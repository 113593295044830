<form class="flex flex-col flex-auto p-2" [formGroup]="newStoryForm">
    <div class="flex flex-col ml-3 mr-3">
        <div class="innerDetail mb-4">
            <h4 class="font-medium text-md mb-2 text-black">Story ID</h4>
            <p class="text-black">{{ getStoryID() }}</p>
        </div>
        <div class="inputSec">
            <div class="desc flex flex-col">
                <label class="font-medium text-black">Title*</label>
                <textarea name="" [formControlName]="'title'" id="" class="border mt-2 rounded w-full px-3 py-1 text-black" [style.height]="height"  style="resize: none;" #textarea (input)="autoGrow($event)" rows="1" style="min-height: 60px;"></textarea>
            </div>
        </div>
        <div class="flex mt-6 gap-10 flex-wrap justify-between">
            <mat-form-field class="flex-auto gt-xs:pl-3 ">
                <mat-label>Theme</mat-label>
                <mat-select [placeholder]="'Select Theme'" [formControlName]="'theme'"  class="" (selectionChange)="onChangeTheme($event)">
                    @for (theme of themeTypes;track theme){
                        <mat-option [value]="theme.value">{{theme.label}}</mat-option> 
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 ">
                <mat-label>Epic</mat-label>
                <mat-select [placeholder]="'Select Epic'" [formControlName]="'epic'"  class="">
                    @for(epic of epicTypes;track epic){
                        <mat-option [value]="epic.value">{{epic.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            
        </div>
        <div class="flex mt-6 gap-10 flex-wrap justify-between">
            <mat-form-field class="flex-auto gt-xs:pl-3 ">
                <mat-label>Release</mat-label>
                <mat-select [placeholder]="'Select Release'" [formControlName]="'release'"  class="">
                    @for (release of releaseTypes;track release){
                        <mat-option [value]="release.value">{{release.label}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto  " >
                <mat-label>  Priority</mat-label>
                <mat-select  [placeholder]="'Select Priority'" [formControlName]="'priority'" (selectionChange)="updatePriority($event)"  class="" #proritySelect="matSelect">
                    <mat-select-trigger>
                        <div class="flex gap-1 items-center">
                            <img [src]="getPriority(newStoryForm.get('priority').value).icon" class="w-5 h-5">{{getPriority(newStoryForm.get('priority').value).label}}
                        </div> 
                    </mat-select-trigger>

                    <ng-container *ngFor="let priority of priorityTypes;">
                        <mat-option [(value)]="priority.value">
                            <div class="flex gap-1 items-center"><img class="w-3 h-3" [src]="priority.icon"><span>{{priority.label}}</span></div>  
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="flex-auto">
                <mat-label>  Status</mat-label>
                <mat-select [placeholder]="'Select Status'" [formControlName]="'status'"   #statusSelect="matSelect">
                <mat-select-trigger>
                    <div class="flex gap-1 items-center">
                        <!-- <img [src]="getStatus(testCaseForm.get('status').value).icon" class="w-5 h-5"> -->
                            <span class="w-2 h-2 " [ngClass]="{ 'todoTag2': (getStatusString(newStoryForm.get('status').value) == 'To Do') , 'inprogTag2': (getStatusString(newStoryForm.get('status').value) == 'In Progress')  ,'doneTag2': (getStatusString(newStoryForm.get('status').value) == 'Done') }"></span>
                        {{getStatusString(newStoryForm.get('status').value)}}
                    </div> 
                </mat-select-trigger>

                <ng-container *ngFor="let status of statusTypes">
                    <mat-option [value]="status.value">
                        <div class="flex gap-1 items-center">
                            <!-- <img class="w-3 h-3" [src]="status.icon"> -->
                            <span>{{status.label}}</span></div>  
                    </mat-option>
                </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex justify-between flex-wrap">
            <div class="tagSec mt-6">
                <h5 class="text-md font-medium mb-2 text-black">Tags</h5>
                <div class="flex flex-wrap items-center">
                    <ng-container *ngIf="tagArray.controls.length">
                        <ng-container *ngFor="let eachtag of tagArray.controls; let i = index">
                            <div class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                                <span class="text-md text-black whitespace-nowrap">{{eachtag.value}}</span>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div
                        class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                        (click)="openTagsPanel()"
                        #tagsPanelOrigin>
                        <ng-container *ngIf="tagArray.controls.length">
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                            <span class="ml-1.5 text-md font-medium whitespace-nowrap">Edit</span>
                        </ng-container>

                        <ng-container *ngIf="!tagArray.controls.length">
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                            <span class="ml-1.5 text-md font-medium whitespace-nowrap">Add</span>
                        </ng-container>
                        <ng-template #tagsPanel>
                            <div class="w-60 rounded border shadow-md bg-card mt-2">
                                <div class="flex items-center m-3 mr-2">
                                    <div class="flex items-center">
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                        <div class="ml-2">
                                            <input
                                                class="w-full min-w-0 py-1 border-0"
                                                type="text"
                                                placeholder="Enter Tags"
                                                (input)="filterTags($event)"
                                                (keydown)="filterTagsInputKeyDown($event)"
                                                [maxLength]="30"
                                                #newTagInput>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
                                    <ng-container *ngIf="!tagsEditMode">
                                        <ng-container *ngFor="let eachtag of filteredTags; trackBy: trackByFn">
                                            <div
                                                class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                                                (click)="toggleTaskTag(eachtag)"
                                                matRipple>
                                                <mat-checkbox
                                                    class="flex items-center h-10 min-h-10 pointer-events-none"
                                                    [checked]="tagArray.value.includes(eachtag.label)"
                                                    [color]="'primary'"
                                                    [disableRipple]="true">
                                                </mat-checkbox>
                                                <div class="ml-1">{{eachtag.label}}</div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <div
                                        class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer hover:bg-hover"
                                        *ngIf="shouldShowCreateTagButton(newTagInput.value)"
                                        (click)="createTag(newTagInput.value); newTagInput.value = ''"
                                        matRipple>
                                        <mat-icon
                                            class="mr-2 icon-size-5"
                                            [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                        <div class="break-all">Create "<b>{{newTagInput.value}}</b>"</div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="desc flex flex-col mt-6">
            <label class=" font-medium mb-2 text-black">Description*</label>
            <div class="summarybox border border-gray-300 rounded-lg bg-white">
                <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                    <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBold('desc')">
                    <mat-icon class="icon-size-4">format_bold</mat-icon>
                    </button>
                    <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleItalic('desc')">
                    <mat-icon class="icon-size-4">format_italic</mat-icon>
                    </button>
                    <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBulletList('desc')">
                    <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                    </button>
                </div>
                
                <tiptap-editor 
                    [editor]="editor"
                    id="tiptap-editor"
                    [formControlName]="'description'"
                    placeholder="Start typing here...">
                </tiptap-editor>
            </div>
        </div>
        <div class="desc flex flex-col mt-6">
            <label class=" font-medium mb-2 text-black">Low Level Solution</label>
            <div class="summarybox border border-gray-300 rounded-lg bg-white">
                <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300" *ngIf="!story.isGeneratingLowLevelSolutions">
                    <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBold('lowlevel')">
                    <mat-icon class="icon-size-4">format_bold</mat-icon>
                    </button>
                    <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleItalic('lowlevel')">
                    <mat-icon class="icon-size-4">format_italic</mat-icon>
                    </button>
                    <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBulletList('lowlevel')">
                    <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                    </button>
                </div>
                
                <tiptap-editor 
                    [editor]="editor2"
                    id="tiptap-editor"
                    [formControlName]="'lowLevelSolutionSummary'"
                    placeholder="Start typing here..." *ngIf="!story.isGeneratingLowLevelSolutions">
                </tiptap-editor>
                
                @if(story.isGeneratingLowLevelSolutions){
                    
                        <div class="rounded  p-4">
                            <app-regenerating-progress-bar [generationType]="'story-lls'" [(taskID)]="story._id" class="sidepr "   [restart]="'true'" (type)="getRegenerationType()"  ></app-regenerating-progress-bar>
                            <div class="shimmer-effect mt-2  rounded-2xl"></div>
                          
                        </div>
                }

                @if(!story.isGeneratingLowLevelSolutions){
                    <div class="flex" style="box-shadow: rgb(204, 204, 204) 0px 2px 2px inset;">
                        <div 
                            [matTooltip]="(isRegeneratingWorkbook || story.isGeneratingConfigurationWorkbook)?'Please wait while the configuration workbook is being generated':'Click to regenerate the low level solution design for this user story'"
                            class="m-3">
                            <button *ngIf="is_write_allowed" 
                                    mat-flat-button 
                                    [color]="'primary'" 
                                    [disabled]="!newStoryForm.valid || isUploadingFiles || story.isGeneratingConfigurationWorkbook" 
                                    class="bg-white flex items-center justify-center gap-1 regen-btn px-2 py-1 text-md rounded-md" 
                                    [ngClass]="{'text-black': !(!newStoryForm.valid || isUploadingFiles || story.isGeneratingConfigurationWorkbook), 'text-gray-400': !newStoryForm.valid || isUploadingFiles || story.isGeneratingConfigurationWorkbook}"
                                    (click)="onRegenerateSolution()">
                                <div class="flex gap-1 items-center" [ngClass]="{'text-black': !(!newStoryForm.valid || isUploadingFiles || story.isGeneratingConfigurationWorkbook), 'text-gray-400': !newStoryForm.valid || isUploadingFiles || story.isGeneratingConfigurationWorkbook}">
                                    <img [ngClass]="{ 'spin': isRegenerating }" class="w-4 h-4" src="./assets/icons/regen_icon.svg">
                                    <span>Regenerate Solution</span>
                                </div>
                            </button>
                        </div>
                    </div>
                }

            </div>

           

        </div>
            
        @if(buildID && buildID!==''){
        <div class="innerDetail mb-2 my-4">
            <h4 class="font-medium text-black">Configuration Workbook</h4>
            
                @if(story.isGeneratingConfigurationWorkbook){
                    <div class="border rounded mt-2 p-4">
                        <app-regenerating-progress-bar [generationType]="'story-workbook'" [(taskID)]="story._id" class="sidepr "   [restart]="'true'" (type)="getRegenerationType()"  ></app-regenerating-progress-bar>
                        <div class="shimmer-effect mt-2  rounded-2xl"></div>
                      
                    </div>

                    }@else{
                    
                    <div class="linkedTable">
                        <div class="table-container" >
                            <table>
                                <thead>
                                    <tr>
                                        <th class="font-medium uppercase text-secondary">Type</th>
                                        <th class="font-medium uppercase text-secondary">Category</th>
                                        <th class="font-medium uppercase text-secondary">Sub Category</th>
                                        <th class="font-medium uppercase text-secondary">Description</th>
                                        <th class="font-medium uppercase text-secondary">Cloud</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  *ngIf="!storyConfigurations.length">
                                        <td colspan="5" class="text-center">No configuration workbook found.</td>
                                    </tr>
                                    @for(config of storyConfigurations;track config){
                                    <tr  (click)="selectBuild(config)" class="cursor-pointer">
                                        <td (click)="selectBuild(config)">{{getMetadataTypeDisplayName(config.metadataType)}}</td>
                                        <td>{{config.solutionCategory}}</td>
                                        <td>{{config.solutionSubCategory}}</td>
                                        <td [innerHTML]="config.description"></td>
                                        <td>{{config.cloud}}</td>
                                    
                                    </tr>
                                }
                                </tbody>
                            </table>
                            <div class="flex"  style="box-shadow: rgb(204, 204, 204) 0px 2px 2px inset;">
                                <div 
                                [matTooltip]="getWorkbookTooltip()"
                                    class="m-3">
                                    <button
                                        *ngIf="is_write_allowed" 
                                        mat-flat-button 
                                        [color]="'primary'" 
                                        [disabled]="!newStoryForm.valid || isUploadingFiles || story.isGeneratingLowLevelSolutions || newStoryForm.get('lowLevelSolutionSummary').value==''" 
                                        class="bg-white flex items-center justify-center gap-1 regen-btn px-2 py-1 text-md rounded-md"
                                        (click)="onGenerateConfigurationWorkbook()">
                                        <div class="flex gap-1 items-center">
                                            <img [ngClass]="{ 'spin': isRegeneratingWorkbook }" class="w-4 h-4" src="./assets/icons/regen_icon.svg">
                                            @if(story.isGeneratingLowLevelSolutions){
                                                <span class="text-gray-400">Regenerate Workbook</span>
                                            }@else{
                                                <span class="text-black">Regenerate Workbook</span>
                                            }
                                        </div>
                                    </button>
                                </div>                           
                             </div>
                        </div>
                    </div>
                }
            </div>
        }
            
      
        <div class="border min-h-20 mt-6 p-2 rounded uploadSec mb-20 ">
            <div class="head flex justify-between">
                <div class="content">
                    <h4 class="font-medium text-lg text-black">Attachments</h4>
                    <p class="text-[#9AA6B8] text-sm">Seamlessly attach documents, images, and links to enhance your workflow</p>
                    
                </div>
                <input type="file" accept=".jpg, .png, .pdf, .docx, .pptx, .txt, .csv"  #fileInput (change)="onFilesSelected($event)" style="display: none;" multiple>

                <button *ngIf="is_write_allowed" (click)="onClickSelectFile()" class="border gap-1 h-8 flex items-center pl-3 pr-3 rounded text-sm uploadBt"> <mat-icon class="icon-size-4" svgIcon="heroicons_outline:arrow-up-tray"></mat-icon> Upload File</button>
                <div class="my-1 mb-2" *ngIf="sizeLimitAlert">
                    <fuse-alert
                    [appearance]="'soft'"
                    [showIcon]="false"
                    type="error"
                    [dismissible]="true" [dismissed]="false">
                    The maximum allowed file size is 20MB
                    </fuse-alert>
                </div>
            </div>
            <div class="filelist p-3 flex flex-wrap" *ngIf="fileAttachments && fileAttachments.length">
                @for(attachment of fileAttachments;track attachment){
                <div class="flex gap-2 items-center listBox p-2">
                    <div class="relative inline">                     
                        <mat-icon class="icon-size-8" matPrefix svgIcon="heroicons_outline:document"></mat-icon>
                        <div class="typeText absolute bottom-0 left-0 rounded px-1.5 text-xs font-semibold leading-5 text-white" [ngClass]="getFileClass(attachment)"> {{getFileTypeString(attachment)}} </div>
                    </div>
                    <span  class="flex gap-2 items-center">{{attachment.title?attachment.title:attachment.fileName}} <mat-icon (click)="onRemoveFile(attachment)" class="icon-size-5 cursor-pointer" svgIcon="heroicons_outline:trash"></mat-icon></span>
                </div>
                }
            </div>
        </div>
       
        @if(is_write_allowed && (!story.isGeneratingLowLevelSolutions && !story.isGeneratingConfigurationWorkbook && !isRegenerating && !isRegeneratingWorkbook)){
        <div *ngIf="newStoryForm.dirty" class="formAct flex  mb-8 items-center gap-1" [ngClass]="{'justify-between' :(flashMessageType) , 'justify-end' :(!flashMessageType)  }">

            <fuse-alert class="text-md" *ngIf="flashMessageType === 'success' && flashMessageType" type="success" [showIcon]="true" [appearance]="'soft'" [dismissible]="true" [dismissed]="false"> Updated successfully</fuse-alert>

            <fuse-alert class="text-md" *ngIf="flashMessageType === 'error' && flashMessageType" type="warn" [showIcon]="true" [appearance]="'soft'" [dismissible]="true" [dismissed]="false"> An error occurred, try again!</fuse-alert>

            <button *ngIf="is_write_allowed && (!story.isGeneratingLowLevelSolutions && !story.isGeneratingConfigurationWorkbook && !isRegenerating && !isRegeneratingWorkbook) " mat-flat-button [color]="'primary'" [disabled]="!newStoryForm.valid || isUploadingFiles || isSavingStory"  (click)="onSaveStory()" >
                <div class="flex gap-1 items-center">
                <mat-progress-spinner *ngIf="isSavingStory" mode="indeterminate"></mat-progress-spinner> <span>Save</span>
                </div>
            </button>
        </div>
    }

    </div>
</form> 



<fuse-drawer
    class="w-screen z-999 sideDraw"
    fixed
    [mode]="'over'"
    [name]="'viewDrawer'"
    [position]="'right'" 
    (openedChanged)="openedChanged($event)"
    #viewDrawer>
    
    <div *ngIf="isDrawerConfigOpen && selectedBuild" class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex justify-between items-center px-4 py-2" >
            <div class="">
                <h4 class="text-lg font-medium text-black flex items-center">
                    <mat-icon svgIcon="heroicons_outline:pencil-square" class="icon-size-5 text-black"></mat-icon>
                    Edit Configuration
                </h4>
            </div>
            <div class="actSide flex  items-center" >
                <button *ngIf="is_write_allowed" mat-icon-button [matMenuTriggerFor]="moreMenu2" >
                    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'" class="icon-size-4"></mat-icon>
                </button>
                <mat-menu #moreMenu2="matMenu">
                    <button mat-menu-item (click)="onDelete(selectedBuild)">
                        <mat-icon [svgIcon]="'heroicons_outline:trash'" class="icon-size-4"></mat-icon>
                        <span>Delete </span>
                    </button>
                    
                </mat-menu>
                <button class="ml-auto" mat-icon-button (click)="viewDrawer.toggle()">
                    <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </div>

        <div class="formSec px-4 py-2 flex flex-col gap-4">
            <form class="flex flex-col flex-auto gap-2" [formGroup]="configForm">
                <div class="detailBox">
                    <div class="innerDetail mb-2">
                        <h4 class="font-medium text-black">Name</h4>
                        <!-- <textarea [formControlName]="'metadataName'" name="" id="" class="border mt-2 rounded w-full p-3 auto-grow text-black" row="2"  #textarea (input)="autoGrow(textarea)" rows="1" style="min-height: 60px; resize:none;" [style.height]="height"></textarea> -->
                        <input class="border mt-2 rounded w-full p-3 auto-grow text-black" [formControlName]="'metadataName'">
                    </div>
                    <div class="flex gap-10 innerDetail my-4">
                        <mat-form-field class="flex-auto gt-xs:pl-3 ">
                            <mat-label> Type</mat-label>
                            <mat-select [placeholder]="'Select Type'" [formControlName]="'metadataType'" class="" (selectionChange)="onChangeFormMetadata($event)">
                                @for(option of allMetadataTypeList;track option){
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 ">
                            <mat-label>Theme</mat-label>
                            <mat-select [placeholder]="'Theme'" [formControlName]="'themeID'" class="" (selectionChange)="onChangeFormTheme($event)">
                                @for(option of themeTypesConfig;track option){
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex gap-10 innerDetail mb-2 my-4">
                        <mat-form-field class="flex-auto gt-xs:pl-3 ">
                            <mat-label>Epic</mat-label>
                            <mat-select [placeholder]="'Epic'" [formControlName]="'epicID'" class="" (selectionChange)="onChangeEpic($event)">
                                @for(option of epicTypes;track option){
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 ">
                            <mat-label> Category</mat-label>
                            <mat-select [placeholder]="'Select Category'" [formControlName]="'solutionCategory'" class="" readonly (selectionChange)="onChangeFormCategory($event)">
                                @for(option of allMetadataCategory;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>                        
                    </div>
                    <div class="flex gap-10 innerDetail mb-2 my-4">
                        <mat-form-field class="flex-auto gt-xs:pl-3 ">
                            <mat-label>Sub-Category</mat-label>
                            <mat-select [placeholder]="'Select Sub Category'" [formControlName]="'solutionSubCategory'" class="" readonly>
                                @for(option of allMetadataSubCategoryForm;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 ">
                            <mat-label>Cloud</mat-label>
                            <mat-select [formControlName]="'cloud'" [placeholder]="'Cloud'" class="">
                                @for (option of technologyDomains; track option) {
                                    <mat-option [value]="option.name">{{option.name}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="flex gap-10 innerDetail mb-2 my-4">
                        <mat-form-field class="flex-auto gt-xs:pl-3 ">
                            <mat-label>Work Type</mat-label>
                            <mat-select [placeholder]="'Type'" [formControlName]="'workType'" class="" readonly>
                                @for(option of workTypes;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto gt-xs:pl-3 ">
                            <mat-label>Action</mat-label>
                            <mat-select [placeholder]="'Action'" [formControlName]="'actionType'" class="">
                                @for(option of actionTypes;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        
                    </div>
                    <div class="flex  gap-10 innerDetail mb-2 my-4 w-1/2 pr-4">
                        <mat-form-field class="flex-auto gt-xs:pl-3 ">
                            <mat-label>Status</mat-label>
                            <mat-select [placeholder]="'Status'" [formControlName]="'status'" class="">
                                @for(option of statusTypesConfig;track option){
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="desc flex flex-col mt-6">
                        <label class="font-medium text-black">Description</label>
                        <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                            <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBold('desc2')">
                                <mat-icon class="icon-size-4">format_bold</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleItalic('desc2')">
                                <mat-icon class="icon-size-4">format_italic</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBulletList('desc2')">
                                <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                            </button>
                            </div>
                            <tiptap-editor 
                            [editor]="editor3"
                            [formControlName]="'description'"
                            id="tiptap-editor"
                            placeholder="Start typing here...">
                            </tiptap-editor>
                        </div>
                    </div> 

                    <div class="desc flex flex-col mt-6">
                        <label class="font-medium text-black">Assumption</label>
                        <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                            <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBold('assumption')">
                                <mat-icon class="icon-size-4">format_bold</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleItalic('assumption')">
                                <mat-icon class="icon-size-4">format_italic</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBulletList('assumption')">
                                <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                            </button>
                            </div>
                        
                            <tiptap-editor 
                            [editor]="editor4"
                            [formControlName]="'assumption'"
                            id="tiptap-editor2"
                            placeholder="Start typing here...">
                            </tiptap-editor>
                        </div>
                    </div> 

                    <div class="desc flex flex-col mt-6">
                        <label class="font-medium text-black">Developer Notes</label>
                        <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                            <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBold('notes')">
                                <mat-icon class="icon-size-4">format_bold</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleItalic('notes')">
                                <mat-icon class="icon-size-4">format_italic</mat-icon>
                            </button>
                            <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center flex items-center p-2" (click)="toggleBulletList('notes')">
                                <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                            </button>
                            </div>
                        
                            <tiptap-editor 
                            [editor]="editor5"
                            [formControlName]="'developerNote'"
                            id="tiptap-editor2"
                            placeholder="Start typing here...">
                            </tiptap-editor>
                        </div>
                    </div> 


                    <div class="innerDetail mb-2 my-4">
                        <h4 class="font-medium text-black">Linked User Stories</h4>
                        <div class="linkedTable">
                            <div class="table-container" >
                                <table>
                                    <thead>
                                        <tr>
                                            <th class="font-medium">Story ID</th>
                                            <th class="font-medium">Title</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  *ngIf="(!userStoryArray || !userStoryArray.value || !userStoryArray.value.length)">
                                            <td colspan="2" class="border-b text-center">No Story Found</td>
                                        </tr>
                                        <tr *ngFor="let eachstory of userStoryArray.value; let i = index" >
                                            <td>{{eachstory.storyID}}</td>
                                            <td>{{eachstory.title}}</td>
                                        </tr>
                                        <tr class="cursor-pointer">
                                            <td colspan="2" (click)="selectStory()"> 
                                                <div class="flex items-center text-md gap-1 ">
                                                    <mat-icon svgIcon="heroicons_outline:plus-circle" class="icon-size-5"></mat-icon> <span>Add User Story </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="border  items-center max-w-60 mb-15 userinfo" >
                    <img class="w-7 h-7 mr-1 rounded-full" *ngIf="(configForm.get('author.meta.profileImage').value)" [src]="getImageURL(configForm.get('author.meta.profileImage').value)" appUrl>
                    <mat-icon *ngIf="!(configForm.get('author.meta.profileImage').value)" [svgIcon]="'heroicons_outline:user-circle'" class="icon-size-8"></mat-icon>

                    <div class="mr-3"> 
                        <p>Updated by {{configForm.get('author.personal.name.first').value}},{{format(configForm.get('created').value)}}</p>
                    </div>
                </div>

                <div class="actionBt text-right  mt-4" *ngIf="configForm.dirty"  [ngClass]="{'justify-between' :(flashMessageType) , 'justify-end' :(!flashMessageType)  }">

                    <fuse-alert class="text-md" *ngIf="flashMessageType === 'success' && flashMessageType" type="success" [showIcon]="true" [appearance]="'soft'"> Updated successfully</fuse-alert>

                    <fuse-alert class="text-md" *ngIf="flashMessageType === 'error' && flashMessageType" type="warn" [showIcon]="true" [appearance]="'soft'"> An error occurred, try again!</fuse-alert>

                    <button  mat-flat-button [color]="'primary'" [disabled]="(!configForm.valid || isSavingConfig)" (click)="onSave()" >
                        <div class="flex gap-1 items-center">
                        <mat-progress-spinner *ngIf="isSavingConfig" mode="indeterminate"></mat-progress-spinner> <span>Save</span>
                        </div>
                    </button>
                </div>
            </form>
        </div>        
    </div>
</fuse-drawer>