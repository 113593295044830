import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, from, map, Observable, of, ReplaySubject, switchMap, tap, throwError } from 'rxjs';
import { ICategoryList, IProposal } from './proposal.types';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({providedIn: 'root'})
export class ProposalService
{
    private _httpClient = inject(HttpClient);
    private _authService=inject(AuthService);
    private _proposal: ReplaySubject<IProposal[]> = new ReplaySubject<IProposal[]>(1);
    private _currentLoadingEvent:ReplaySubject<number>=new ReplaySubject<number>(1);

    private _proposalCache = new Map<string, ReplaySubject<IProposal[]>>();
    private _statsCache = new Map<string, ReplaySubject<any>>();

    private _pendingDefectsCallbacks:ReplaySubject<boolean>=new ReplaySubject<boolean>(1);
    private _categoryList = new BehaviorSubject<ICategoryList[] | null>(null); 
    private insightsCache = new BehaviorSubject<any[]>([]);
    public readonly categoryList$ = this._categoryList.asObservable();
    private cache = new Map<string, any>();

    private _buildCache = new ReplaySubject<any>(1);
    private _buildStatsCache = new Map<string, ReplaySubject<any>>();
    private _testPlanCache = new ReplaySubject<any>(1);
    private _testPlanStatsCache = new Map<string, ReplaySubject<any>>();

    private hasPendingProposalUpdates:boolean=false;
    private hasPendingProjectUpdates:boolean=false;
    private hasPendingBuildUpdates:boolean=false;
    private hasPendingTestPlanUpdates:boolean=false;
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for knowledgebase
     *
     * @param value
     */

    set pendingDefectsCallbacks(hasEvent:boolean){
        this._pendingDefectsCallbacks.next(hasEvent);
    }
    get pendingDefectsCallbacks$():Observable<boolean>{
        return this._pendingDefectsCallbacks.asObservable();
    }

    set proposal(value: IProposal[])
    {
        // Store the value
        this._proposal.next(value);
    }

    get proposal$(): Observable<IProposal[]>
    {
        return this._proposal.asObservable();
    }

    set currentLoadingEvent(value:number){
        this._currentLoadingEvent.next(value);
    }
    get currentLoadingEvent$():Observable<number>
    {
        return this._currentLoadingEvent.asObservable();
    }

    incrementCurrentLoadingEvent(current){
        this._currentLoadingEvent.next(current+1);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /*
    get(category): Observable<IProposal[]>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?category=' + category, { headers: { method: 'get_all' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response.data && response.data.length){
                    this._proposal.next(response.data);
                    return this._proposal.asObservable();
                }else{
                    return of([]);
                }
            }),
        );
    }
*/
get(category): Observable<IProposal[]>
{
    // Check if we have cached data for this category
// Clear proposal cache if there are any pending updates
// Check for pending updates and clear caches if needed
if (this.hasPendingProposalUpdates || this.hasPendingProjectUpdates) {
    // Force clear both caches
    this._proposalCache = new Map<string, ReplaySubject<IProposal[]>>();
    this._statsCache = new Map<string, ReplaySubject<any>>();
    // Reset pending update flags after clearing cache
    this.hasPendingProposalUpdates = false;
    this.hasPendingProjectUpdates = false;
}

    if (!this._proposalCache.has(category)) {
        this._proposalCache.set(category, new ReplaySubject<IProposal[]>(1));
        
        // Fetch from API only if no cached data
        this._httpClient.get(environment.api_endpoint + 'proposals?category=' + category, { headers: { method: 'get_all' } }).pipe(
            catchError(() => of([])),
        ).subscribe((response: any) => {
            if(response.data && response.data.length){
                this._proposal.next(response.data);
                this._proposalCache.get(category).next(response.data);
            } else {
                this._proposalCache.get(category).next([]);
            }
        });
    }

    return this._proposalCache.get(category).asObservable();
}
    getListofType(category): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?category=' + category, { headers: { method: 'get_all' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response.data && response.data.length){
                    return of(response);
                }else{
                    return of([]);
                }
            }),
        );
    }

    getTestPlan(): Observable<any>
{
    if (this.hasPendingTestPlanUpdates) {
        this._testPlanCache=new ReplaySubject<any>(1) ;
        this._testPlanStatsCache.clear();
        // Reset pending update flags after clearing cache
        this.hasPendingTestPlanUpdates = false;
    }
    // Check if we have cached data
    if (!this._testPlanCache.observed) {
        this._httpClient.get(environment.api_endpoint + 'testing', { headers: { method: 'get_all' } }).pipe(
            catchError(() => of([]))
        ).subscribe((response: any) => {
            if(response.data && response.data.length){
                this._testPlanCache.next(response);
            } else {
                this._testPlanCache.next({ data: [], meta: {} });
            }
        });
    }

    return this._testPlanCache.asObservable();
}

getTestPlanStats(payload: { testPlanIds: string[], testPlanPermission }) {
    const cacheKey = JSON.stringify(payload.testPlanIds);

    if (!this._testPlanStatsCache.has(cacheKey)) {
        this._testPlanStatsCache.set(cacheKey, new ReplaySubject<any>(1));

        this._httpClient.post(environment.api_endpoint + 'testing', payload, { headers: { method: 'get_all_stats' } }).pipe(
            catchError(() => of(false))
        ).subscribe((response: any) => {
            this._testPlanStatsCache.get(cacheKey).next(response);
        });
    }

    return this._testPlanStatsCache.get(cacheKey).asObservable();
}

    clearCache(){
        // Clear all caches
        this._proposalCache = new Map<string, ReplaySubject<IProposal[]>>();
        this._statsCache = new Map<string, ReplaySubject<any>>();
        this._buildCache = new ReplaySubject<any>(1);
        this._buildStatsCache = new Map<string, ReplaySubject<any>>();
        this._testPlanCache = new ReplaySubject<any>(1);
        this._testPlanStatsCache = new Map<string, ReplaySubject<any>>();
        this.cache = new Map<string, any>();
        this.insightsCache.next([]);

        // Reset pending update flags
        this.hasPendingProposalUpdates = false;
        this.hasPendingProjectUpdates = false;
        this.hasPendingBuildUpdates = false;
        this.hasPendingTestPlanUpdates = false;
    }
    getBuildStats(payload: { buildIds: string[], buildPermission }) {
        const cacheKey = JSON.stringify(payload.buildIds);
    
        if (!this._buildStatsCache.has(cacheKey)) {
            this._buildStatsCache.set(cacheKey, new ReplaySubject<any>(1));
    
            this._httpClient.post(environment.api_endpoint + 'buildagent', payload, { headers: { method: 'get_all_stats' } }).pipe(
                catchError(() => of(false))
            ).subscribe((response: any) => {
                this._buildStatsCache.get(cacheKey).next(response);
            });
        }
    
        return this._buildStatsCache.get(cacheKey).asObservable();
    }

    getBuild(): Observable<any>
    {
        if (this.hasPendingBuildUpdates) {
            this._buildCache=new ReplaySubject<any>(1);
            this._buildStatsCache.clear();
            this.hasPendingBuildUpdates = false;
        }

        // Check if we have cached data
        if (!this._buildCache.observed) {
            this._httpClient.get(environment.api_endpoint + 'buildagent', { headers: { method: 'get_all' } }).pipe(
                catchError(() => of([]))
            ).subscribe((response: any) => {
                if(response.data && response.data.length){
                    this._buildCache.next(response);
                } else {
                    this._buildCache.next({ data: [], meta: {} });
                }
            });
        }
    
        return this._buildCache.asObservable();
    }

    getExtraStats(payload: { proposalIds: string[], category: string }) {
        return this._httpClient.post(environment.api_endpoint + 'proposals',payload, { headers: { method: 'get_all_stats' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    getTestPlanDetail(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?id=' + id, { headers: { method: 'get_by_id' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getBuildDetail(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'buildagent?id=' + id, { headers: { method: 'get_by_id' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getTBuildStatsDetails(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'buildagent?id=' + id, { headers: { method: 'get_by_id_stats' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    get_all_categories(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?id=' + id, { headers: { method: 'get_all_categories' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getTestPlanStatsDetails(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?id=' + id, { headers: { method: 'get_by_id_stats' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getTestCases(offset: number, pageSize: number, id, theme, epic, selected_tag, selected_category, selected_type, release, priority, search, sort_order): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?testPlan=' + id + '&theme='+ theme + '&epic=' + epic + '&offset=' + offset + '&pageSize=' + pageSize + '&selected_tag=' + selected_tag + '&selected_category=' + selected_category + '&selected_type=' + selected_type + '&release=' + release + '&priority=' + priority + '&search=' + search + '&sort_order=' + sort_order, { headers: { method: 'get_test_cases' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getTestCaseDefects(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?_id=' + id, { headers: { method: 'get_case_defects' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getDefects(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?testPlan=' + id, { headers: { method: 'get_defects' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getTestRuns(id,limit,skip): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?testPlan=' + id+'&limit='+limit+"&skip="+skip, { headers: { method: 'get_test_run' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getTestRunById(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?testRunId=' + id, { headers: { method: 'test_run_by_id' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getConfigurations(
        offset: number, 
        pageSize: number, 
        id: string, 
        theme: string, 
        epic: string, 
        selected_subcategory: string[], 
        selected_category: string[], 
        selected_type: string[], 
        selected_status: string[], 
        selected_actiontype: string[], 
        selected_worktype: string[], 
        cloud: string[], 
        search: string
      ): Observable<any> {
        // const cacheKey = `${id}-${theme}-${epic}-${offset}-${pageSize}-${selected_subcategory}-${selected_category}-${selected_type}-${selected_status}-${selected_actiontype}-${selected_worktype}-${cloud}-${search}`;
        
        // // Return cached data if available
        // if (this.cache.has(cacheKey)) {
        //   console.log('Returning cached data for:', cacheKey);
        //   return of(this.cache.get(cacheKey));
        // }
    
        return this._httpClient.get(
          `${environment.api_endpoint}buildagent?buildID=${id}&theme=${theme}&epic=${epic}&offset=${offset}&pageSize=${pageSize}&selected_subcategory=${selected_subcategory}&selected_category=${selected_category}&selected_type=${selected_type}&selected_status=${selected_status}&selected_actiontype=${selected_actiontype}&selected_worktype=${selected_worktype}&cloud=${cloud}&search=${search}`, 
          { headers: { method: 'get_configuration' } }
        ).pipe(
          tap(response => {
            console.log('Fetched API Response:', response);
            // this.cache.set(cacheKey, response);  // Cache the response
          }),
          map(response => response),
          catchError(err => {
            console.error('Error in API:', err);
            return of([]);
          })
        );
    }
    
    refreshCache(offset: number, pageSize: number): void {
        // Remove cache entries matching the offset and pageSize
        [...this.cache.keys()].forEach(key => {
          if (key.includes(`-${offset}-${pageSize}-`)) {
            this.cache.delete(key);
          }
        });
        console.log(`Cache cleared for offset ${offset} and pageSize ${pageSize}`);
    }

    getStoryConfigurations(buildID:string,storyID:string): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'buildagent?buildID=' + buildID + '&storyID='+ storyID, { headers: { method: 'get_story_configurations' } }).pipe(
            tap(response => console.log('API Response:')),  
            map(response => response),  // Just pass the response as-is
            catchError(err => {
                console.error('Error in API:', err);
                return of([]);
            })
        );
    }

    getAllStats(category): Observable<any>
{
    // Check if we have cached data for this category
    if (!this._statsCache.has(category)) {
        this._statsCache.set(category, new ReplaySubject<any>(1));
        
        // Fetch from API only if no cached data
        this._httpClient.get(environment.api_endpoint + 'proposals?category=' + category, { headers: { method: 'all_stats' } }).pipe(
            catchError(() => of([])),
        ).subscribe((response: any) => {
            this._statsCache.get(category).next(response);
        });
    }

    return this._statsCache.get(category).asObservable();
}

    getAllTemplates(): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals', { headers: { method: 'get_all_templates' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getDetail(id,type): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?id='+id + '&type='+type, { headers: { method: 'proposal_details' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response._id){
                    return of(response);
                }else{
                    return of([]);
                }
            }),
        );
    }

    listMembers(id,page_id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?proposal='+id + '&category=' + page_id, { headers: { method: 'list_members' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getList(obj): Observable<IProposal[]>
    {
        let headers_object = new HttpHeaders().set('method', 'get_all_features');
        let params = new HttpParams();
        if (obj.id) params = params.set('id', obj.id);
        if (obj.technology) params = params.set('technology', obj.technology);
        if (obj.skip) params = params.set('skip', obj.skip);
        if (obj.limit) params = params.set('limit', obj.limit);
        if (obj.search_text) params = params.set('search_text', obj.search_text);
        if (obj.cloud) params = params.set('cloud', obj.cloud);
        let options = { params: params, headers: headers_object };

        //return this.http.get<any>(environment.api_endpoint + 'program', options);
        return this._httpClient.get(environment.api_endpoint + 'proposals', options).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getCloudTypes(): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals', { headers: { method: 'get_all_clouds' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getUserResource(proposalID): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?proposalID='+proposalID, { headers: { method: 'get_user_resource' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getProgressIndicators(proposal_id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?id='+proposal_id, { headers: { method: 'get_user_progress_indicator' } }).pipe(
            catchError(() =>
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getRecommendation(requirement): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'features?requirement='+ requirement, { headers: { method: 'ai_recommended' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getRecommendationAI(requirement): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'features/unauth?requirement='+ requirement, { headers: { method: 'gen_recommended' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getDocInsights(obj): Observable<any> {
        // Check if cached data exists
        if (this.insightsCache.value.hasOwnProperty('data') && this.insightsCache.value['data'].length > 0) {
            return this.insightsCache.asObservable(); // Return cached data as an observable
        }

        let headers_object = new HttpHeaders().set('method', 'get_doc_insights');
        let params = new HttpParams();
        if (obj.supportingDocId) params = params.set('supportingDocId', obj.supportingDocId);
        if (obj.proposalID) params = params.set('proposalID', obj.proposalID);
        if (obj.metadataID) params = params.set('metadataID', obj.metadataID);
        
        let options = { params: params, headers: headers_object };

        return this._httpClient.get(environment.api_endpoint + 'proposals', options).pipe(
            catchError(() => {
                // Return an empty array on error
                return of([]);
            }),
            switchMap((response: any) => {
                // Cache the response
                this.insightsCache.next(response); // Update the BehaviorSubject with new data
                return of(response); // Return the response
            }),
        );
    }

    resetInsightsCache() {
        this.insightsCache.next([]);
    }

    getDocHistory(obj): Observable<any>
    {
        let headers_object = new HttpHeaders().set('method', 'get_interaction');
        let params = new HttpParams();
        if (obj.supportingDocId) params = params.set('supportingDocId', obj.supportingDocId);
        if (obj.proposalID) params = params.set('proposalID', obj.proposalID);
        if (obj.metadataID) params = params.set('metadataID', obj.metadataID);
        let options = { params: params, headers: headers_object };

        return this._httpClient.get(environment.api_endpoint + 'knowledge-center', options).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getDocHistoryByDate(obj): Observable<any>
    {
        let headers_object = new HttpHeaders().set('method', 'get_interaction_by_date');
        let params = new HttpParams();
        if (obj.supportingDocId) params = params.set('supportingDocId', obj.supportingDocId);
        if (obj.proposalID) params = params.set('proposalID', obj.proposalID);
        if (obj.metadataID) params = params.set('metadataID', obj.metadataID);
        if (obj.selectedDate) params = params.set('selectedDate', obj.selectedDate);
        let options = { params: params, headers: headers_object };

        //return this.http.get<any>(environment.api_endpoint + 'program', options);
        return this._httpClient.get(environment.api_endpoint + 'knowledge-center', options).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    fixGrammar(requirement): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'ai',JSON.stringify(requirement), { headers: { method: 'fix_grammar' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    inviteUser(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'invite_user' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    inviteUserTP(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(proposal), { headers: { method: 'invite_user' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    inviteUserBA(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'buildagent',JSON.stringify(proposal), { headers: { method: 'invite_user' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updatePermission(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'update_permission' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    save(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'save_proposal' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                this.hasPendingProjectUpdates=true;
                this.hasPendingProposalUpdates=true;
              return of(response);
            }),
        );
    }

    create(project): Observable<any>
    {
        
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(project), { headers: { method: 'create_project' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                this.hasPendingProjectUpdates=true;
                     this.hasPendingProposalUpdates=true;
              return of(response);
            }),
        );
    }

    updateViewCount(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'update_view_count' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updateTestCase(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(proposal), { headers: { method: 'update_test_run_case' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    update(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'update_proposal' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updateName(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'update_name' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteResource(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'delete_resource' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteDefect(defect): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(defect), { headers: { method: 'delete_defect' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteConfiguration(defect): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'buildagent',JSON.stringify(defect), { headers: { method: 'delete_configuration' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    saveResource(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'save_resource' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    saveRelease(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'create_release' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createDefect(defectObject): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(defectObject), { headers: { method: 'create_defect' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createConfiguration(configObject): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'buildagent',JSON.stringify(configObject), { headers: { method: 'create_configuration' } }).pipe(
            catchError(() =>
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updateList(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'update_list' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    savePhase(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'save_phases' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createTestCases(caseObject): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(caseObject), { headers: { method: 'create_test_case' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createTestRun(testRun): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(testRun), { headers: { method: 'create_test_run' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createTestPlan(testPlan): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(testPlan), { headers: { method: 'create_test_plan' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                this.hasPendingTestPlanUpdates=true;
              return of(response);
            }),
        );
    }

    createBuild(buildObject): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'buildagent',JSON.stringify(buildObject), { headers: { method: 'create' } }).pipe(
            catchError(() =>
                of(false),
            ),
            switchMap((response: any) =>
            {
                this.hasPendingBuildUpdates=true;
              return of(response);
            }),
        );
    }

    cloneTestRun(id:string,type:string,title:string): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify({_id:id,type:type,title:title}), { headers: { method: 'clone_test_run' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    deleteTestRun(id:string): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify({_id:id}), { headers: { method: 'delete_test_run' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }


    download(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'download',JSON.stringify(proposal), { headers: { method: 'download_proposal' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    downloadTestCase(tesplan): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'download',JSON.stringify(tesplan), { headers: { method: 'download_test_case' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    downloadStories(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'download',JSON.stringify(proposal), { headers: { method: 'download_project_stories' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    downloadSummary(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'download',JSON.stringify(proposal), { headers: { method: 'download_proposal_cost_summary' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    rewrite(summary_detail): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'ai',JSON.stringify(summary_detail), { headers: { method: 'refine_requirements' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    saveSupportingDocument(supportingDocument:any):Observable<any>{
        return this._httpClient.post(environment.api_endpoint + 'knowledgebase',JSON.stringify(supportingDocument), { headers: { method: 'create_supporting_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    getSupportingDocumentUploadLink(data:any){
        return this._httpClient.post(environment.api_endpoint + 'upload/supporting',JSON.stringify(data), { headers: { method: 'test' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    uploadSupportingFile(uploadUrl: string,file: File, ): Observable<any> {
        return from(
            fetch(uploadUrl, {
              method: 'PUT',
              body: file,
              headers: {
                'Content-Type': file.type
              }
            })
          ).pipe(
            catchError(error => {
              console.error('Error uploading file:', error);
              return throwError(error);
            })
          );
    }

    getProposalInsights(proposalID:string){
        return this._httpClient.get(environment.api_endpoint + 'proposals?_id='+proposalID, { headers: { method: 'get_proposal_insights' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    getProposalFeatures(proposalID:string){
        return this._httpClient.get(environment.api_endpoint + 'proposals?_id='+proposalID, { headers: { method: 'get_proposal_features' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
     
    createUpdateInsightQuestion(insightID:string,title:string,notes:string,associatedDocs:string[],id:string,linkedDocs:string[],oldInsightID:string,add_via_interaction:boolean,reference:string,epic:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({epic:epic,oldInsight:oldInsightID,insightID:insightID,title:title,notes:notes,_id:id,associatedDocs:associatedDocs,linkedDocs:linkedDocs,add_via_interaction:add_via_interaction,reference:reference}), { headers: { method: 'create_insight' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createMetadataInsight(object){
        return this._httpClient.post(environment.api_endpoint + 'metadata',JSON.stringify(object), { headers: { method: 'create_insight' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteInsightQuestion(insightID:string,id:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({insightID:insightID,_id:id}), { headers: { method: 'delete_insight_question' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteRelease(releaseObj){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(releaseObj), { headers: { method: 'delete_release' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteTestCase(releaseObj){
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(releaseObj), { headers: { method: 'delete_test_case' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    bulkDeleteTestRunCases(request){
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(request), { headers: { method: 'bulk_delete_test_run_cases' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    getDefectsCount(testRuns){
        return this._httpClient.post(environment.api_endpoint + 'testing',{testRuns:testRuns}, { headers: { method: 'get_defects_count' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    saveInteraction(chatObject){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(chatObject), { headers: { method: 'save_interaction' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updateProposalSupportingDoc(proposalID:string,documentID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({documentID:documentID,_id:proposalID}), { headers: { method: 'update_proposal_supporting_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    updateProposalDoc(updateValue){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(updateValue), { headers: { method: 'update_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    deleteProposalSupportingDoc(proposalID:string,documentID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({documentID:documentID,_id:proposalID}), { headers: { method: 'delete_supporting_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    deleteProposalKCDoc(proposalID:string,documentID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({documentID:documentID,_id:proposalID}), { headers: { method: 'delete_knowledge_center_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    regenerateProposal(proposalID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({_id:proposalID}), { headers: { method: 'regenerate_proposal' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    regenerateEpicConfigurationWorkbook(proposalID:string,buildID:string,themeID:string,epicID:string,technology:string,clouds:any){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({proposalID,buildID,themeID,epicID,technology,clouds}), { headers: { method: 'regenerate_epic_configuration_workbook' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    generateStoryConfigurationWorkbook(proposalID:string,buildID:string,themeID:string,epicID:string,technology:string,clouds:any,storyID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({proposalID,buildID,themeID,epicID,technology,clouds,storyID}), { headers: { method: 'generate_story_configuration_workbook' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    refreshLLSSummary(proposalID:string,themeID:string,epicID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({proposalID,themeID,epicID}), { headers: { method: 'refresh_lls_epic_summary' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    generateStoryLLS(proposalID:string,themeID:string,epicID:string,storyID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({proposalID,themeID,epicID,storyID}), { headers: { method: 'generate_story_lls' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    generateEpicLowLevelSolution(proposalID:string,themeID:string,epicID:string,buildID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({proposalID,themeID,epicID,buildID}), { headers: { method: 'generate_low_level_solution' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    saveEpicLowLevelSolutionSummary(proposalID:string,themeID:string,epicID:string,lowLevelSolutionSummary:any){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({proposalID,themeID,epicID,lowLevelSolutionSummary}), { headers: { method: 'save_epic_lls' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    getUserOrg(user_id:string,proposal:string){
        return this._httpClient.get(environment.api_endpoint + 'salesforce/unauth?user_id=' + user_id + '&proposal='+proposal, { headers: { method: 'get_user_org' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    authenticate_login(credentials): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'salesforce/api',JSON.stringify(credentials), { headers: { method: 'salesforceLogin' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    refresh_login(credentials): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'salesforce/api',JSON.stringify(credentials), { headers: { method: 'refreshSession' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    delete_login(credentials: any) {
        return this._httpClient.post(environment.api_endpoint + 'salesforce',JSON.stringify(credentials), { headers: { method: 'delete_user_org' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    
    disconnect_login(credentials: any) {
        return this._httpClient.post(environment.api_endpoint + 'salesforce',JSON.stringify(credentials), { headers: { method: 'disconnect_user_org' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    metadata_describe(credentials): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'metadata/api',JSON.stringify(credentials), { headers: { method: 'describe' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    describemetadata_detail(metadata_id,metadata_object_id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'metadata?_id=' + metadata_id + '&metadataID=' + metadata_object_id, { headers: { method: 'get_describemetadata' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    metadata_list(): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'metadata', { headers: { method: 'get_metadata' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    fetchCategoryList(): Observable<ICategoryList[]> {
        return this._httpClient
            .get<ICategoryList[]>(`${environment.api_endpoint}metadata`, { 
                headers: { method: 'get_category' }
            })
            .pipe(
                tap((newAgentList) => {
                    const currentAgentList = this._categoryList.value || [];
                    const updatedAgentList = [...currentAgentList, ...newAgentList['data']];
                    this._categoryList.next(updatedAgentList);
                })
            );
    }
    
    getCategoryList(): Observable<ICategoryList[]> {
        if (this._categoryList.value && this._categoryList.value.length > 0) {
            return this.categoryList$;
        } else {
            const fetchAllBatches = (): Observable<ICategoryList[]> => {
                return this.fetchCategoryList().pipe(
                    switchMap((newAgents) => {
                        return this.categoryList$;
                    })
                );
            };

            return fetchAllBatches();
        }
    }

    metadata_xml(metadata_id,metadata_object_id,metadata_extract_id,xml_data): Observable<any> {
        return this._httpClient.get(environment.api_endpoint + 'metadata?_id=' + metadata_id + '&metadata_object_id=' + metadata_object_id + '&metadata_extract_id=' + metadata_extract_id + '&xml_data=' + xml_data, { headers: { method: 'get_xmldata' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    extractDelete(credentials): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'metadata',JSON.stringify(credentials), { headers: { method: 'delete_metadata_extract' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    extractUpdate(credentials): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'metadata',JSON.stringify(credentials), { headers: { method: 'update_metadata_extract' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

}
