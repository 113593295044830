import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { inject, Injectable } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as introJs from 'intro.js';
import { Observable, of, ReplaySubject, tap, takeUntil, Subject } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'app/core/user/user.types';
import { EventService } from 'app/core/common/event.service';


@Component({
  selector: 'app-product-tour',
  standalone: true,
  imports      : [MatIconModule, MatDialogModule, MatFormFieldModule, MatOptionModule, MatButtonModule, MatCheckboxModule],
  templateUrl: './product-tour.component.html',
  styleUrl: './product-tour.component.scss'
})
export class ProductTourComponent {

  introJS = introJs.default();

  user: User;
  private _userService=inject(UserService);
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private eventService: EventService
  ){
    this.getUser();
  }


  startTour(){
    //     const intro  = this.introJS.setOptions({
//       steps: [
//         {
//           element: document.getElementById('dashboard'),
//           title: 'Dashboard',
//           position: 'right',
//           intro: 'Your control center allows you to access all your important data and monitor your progress directly from the dashboard.'
//         },
//         {
//           element: document.getElementById('keystatics'),
//           title: 'Key Metrics',
//           position: 'bottom',
//           intro: 'Track all your proposal insights in one place and view their status, value, and hours saved in one place.'
//         },
//         {
//           element: document.getElementById('templates'),
//           title: 'Proposal',
//           position: 'right',
//           intro: 'Go back to your last active proposal and continue making it awesome with AI.'
//         },
      
//   ],
//   nextLabel: "Next",
//   doneLabel: "Next",
//   showProgress: false,
//   showBullets: false,
//   disableInteraction: false,
//   exitOnOverlayClick: false
  
  
//   })

//   this.introJS.onchange((targetElement) => {
//     if(this.introJS._currentStep == 3){
//   });

//   this.introJS.oncomplete(() => {
//     localStorage.setItem('productTour', 'true');
//     this._router.navigate(['/proposal/'+this.user.last_proposal_state]);
//   });

// this.introJS.start();

    localStorage.setItem('dashboardTour', 'true');
    if(this._activatedRoute.snapshot['_routerState'].url.includes('proposal')){
      this.eventService.triggerTour('start');
    }
    else this._router.navigate(['/proposal/']);

  }





  getUser(){
    this._userService.user$
    .pipe((takeUntil(this._unsubscribeAll)))
    .subscribe((user: User) =>
    {
        this.user = user;
    });
  }
  // onNextStep(targetElement: HTMLElement): void {
  //   // console.log(`Current step: ${this.introJs._currentStep}`);
  //   console.log("Next button clicked!", targetElement);
  //   // Perform some action here
  // }
  // onNextStep(targetElement: HTMLElement): void {
  //   console.log("Next button clicked!", targetElement);
  //   // Perform some action here
  // }

  // onBeforeNextStep(targetElement: HTMLElement): void {
  //   console.log("Before next step:", targetElement);
  //   // Perform some action here
  // }

}
