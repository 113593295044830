import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { PricingPlanService } from 'app/core/pricing-plan/pricing-plan.service';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { catchError, Observable, of, switchMap, throwError, Subject, takeUntil } from 'rxjs';
import { AnalyticsService } from '../common/analytics_service';
import analytics_event from '../common/analytics_event';

@Injectable({providedIn: 'root'})
export class AuthService
{
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _pricingService = inject(PricingPlanService);
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(credentials: {email: string}): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'user', credentials, { headers: { method: 'send_reset_password' } });
    }

    /**
     * Check reset password link
     *
     * @param password
     */
    checkResetPassword(credentials: {token: string}): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'user', credentials, { headers: { method: 'check_reset_link' } });
    }

    /**
     * Reset password
     *
     * @param credentials
     */
    resetPassword(credentials: {token: string; password: string ; device_type: 'web'}): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'user', credentials, { headers: { method: 'reset_password' } });
    }

    /**
     * Update password
     *
     * @param credentials
     */
    updatePassword(credentials: {password: string; confirm_password: string ;}): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'user/auth', credentials, { headers: { method: 'save_profile' } });
    }

    /**
     * Update company info
     *
     * @param credentials
     */
    updateCompany(credentials: {company_bio: string; company_tnc: string ;}): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'user/auth', credentials, { headers: { method: 'save_profile' } });
    }

    /**
     * Sign in
     *
     * @param credentials
     */ 
    signIn(credentials: { username: string; password: string ; device_type: 'web', is_social: boolean}): Observable<any>
    {
        this.clearAgentList();
        // Throw error, if the user is already logged in
       /*
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }
        */

        return this._httpClient.post(environment.api_endpoint + 'user', credentials, { headers: { method: 'login' } }).pipe(
            switchMap((response: any) =>
            {
                if(response && response.session_id){
                    // Store the access token in the local storage
                    this.accessToken = response.session_id;
                    // Set the authenticated flag to true
                    this._authenticated = true;
                    // Store the user on the user service
                    this._userService.user = response.user_data;
                    // Return a new observable with the response
                    return of(response);
                }
                else return of(response);
            }),
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        this.clearAgentList();
        console.log("Signin using token: ",this.accessToken);
        // Sign in using the token
        return this._httpClient.post(environment.api_endpoint + 'user', {
            accessToken: this.accessToken,
        }, { headers: { method: 'verify','Authorization': 'Bearer ' + this.accessToken } }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                console.log("response",response);
                if(response.verify){
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if ( response.session )
                    {
                        this.accessToken = response.session;
                    }
    
                    // Set the authenticated flag to true
                    this._authenticated = true;
    
                    // Store the user on the user service
                    this._userService.user = response.user_data;
    
                    // Return true
                    return of({ isAuthenticated: true , additionalData: response.user_data});
                }
                else {
                    // Remove the access token from the local storage
                    localStorage.removeItem('accessToken');

                    // Set the authenticated flag to false
                    this._authenticated = false;

                    // Return the observable
                    return of({ isAuthenticated: false });
                }
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {   
        this.clearAgentList();
        return this._httpClient.post(environment.api_endpoint + 'user', {}, { headers: { method: 'logout' } }).pipe(
            switchMap((response: any) =>
            { 
                // console.log("sign-out",response);
                // Remove the access token from the local storage
                localStorage.removeItem('accessToken');
                localStorage.removeItem('temp_proposal');
                localStorage.removeItem('selectedIndex');
                localStorage.removeItem('start_tour');
                localStorage.removeItem('referalSession');
                localStorage.removeItem('dashboardTour');
                localStorage.removeItem("promptTip");
                localStorage.removeItem("plan");
                localStorage.removeItem("type");
                localStorage.removeItem("productTour");
                localStorage.removeItem("projectTour");
                localStorage.removeItem("sugID");
                localStorage.removeItem('referalSession');
                localStorage.removeItem('panel');
                localStorage.removeItem('singleProjectTour');
                localStorage.removeItem('singleTestingTour');
                localStorage.removeItem('first_tour');
                localStorage.removeItem('testingTour');
                localStorage.clear();

                // Set the authenticated flag to false
                this._authenticated = false;

                this.accessToken = '';

                // Return the observable
                return of(true);
            }),
        );
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { firstName: string; lastName: string; email: string; password: string; company: string; business_user: boolean;  device_type: 'web'; is_social_signup: boolean, signup_user_token: string,captchaToken: string}): Observable<any>
    {
        this.clearAgentList();
        return this._httpClient.post(environment.api_endpoint + 'user', user, { headers: { method: 'signup' } }).pipe(
            switchMap((response: any) =>
            {
                // console.log("signup_response",response);
                if(response && response.success){
                    // Store the access token in the local storage
                    this.accessToken = response.session;
                    // Set the authenticated flag to true
                    this._authenticated = true;
                    // Store the user on the user service
                    this._userService.user = response.user_data;
                    // Return a new observable with the response
                    return of(response);
                }
                else return of(response);
            }),
        );
    }

    /**
     * join
     *
     * @param user
     */
    join(user: { token: string; }): Observable<any>
    {
        this.clearAgentList();
        return this._httpClient.post(environment.api_endpoint + 'user', user, { headers: { method: 'join' } }).pipe(
            switchMap((response: any) =>
            {
                // console.log("join",response);
                if(response && response.status){
                    // Store the access token in the local storage
                    this.accessToken = response.session;
                    // Set the authenticated flag to true
                    this._authenticated = true;
                    // Store the user on the user service
                    this._userService.user = response.user_data;
                    // Return a new observable with the response
                    return of(response);
                }
                else return of(response);
            }),
        );
    }

    /**
     * join
     *
     * @param user
     */
    register(user: { email: string; device_type: 'web'; }): Observable<any>
    {
        this.clearAgentList();
        return this._httpClient.post(environment.api_endpoint + 'user', user, { headers: { method: 'register' } }).pipe(
            switchMap((response: any) =>
            {
                // console.log("join",response);
                if(response && response.success){
                    // Store the access token in the local storage
                    this.accessToken = response.session;
                    // Set the authenticated flag to true
                    this._authenticated = true;
                    // Store the user on the user service
                    this._userService.user = response.user_data;
                    // Return a new observable with the response
                    return of(response);
                }
                else return of(response);
            }),
        );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<{ isAuthenticated: boolean, additionalData?: any }>
    {
        this._userService.user$.pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) =>
            {
                this.user = user;
            });
        // Check if the user is logged in
        if ( this._authenticated )
        {   
            return of({ isAuthenticated: true , additionalData: this.user});
        }

        // Check the access token availability
        if ( !this.accessToken )
        {   
            return of({ isAuthenticated: false });
        }

        // Check the access token expire date
        // if ( AuthUtils.isTokenExpired(this.accessToken) )
        // {
        //     return of(false);
        // }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    /**
     * Sign in
     *
     * @param credentials
     */ 
    launch(credentials: { email: string; }): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'user', credentials, { headers: { method: 'prelaunch_users' } }).pipe(
            switchMap((response: any) =>
            {
                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    private clearAgentList() {
        this._userService.clearAgentStats();
        this._userService.clearAgentList();
        this._pricingService.clearFetchPlan();
        this._pricingService.clearFetchStatus();
    }
}
