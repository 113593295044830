import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, JsonPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Component, OnDestroy, OnInit, ViewEncapsulation,ViewChild, Input, AfterViewInit, TemplateRef, AfterViewChecked, ElementRef, ViewChildren, QueryList, HostListener, Output, EventEmitter, Renderer2  } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators,UntypedFormGroup,UntypedFormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';


@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [ MatIconModule, MatButtonModule,MatDialogModule, MatSelectModule, MatPaginator, MatPaginatorModule, FormsModule, MatRippleModule, ReactiveFormsModule, MatMenuModule, MatTabsModule, MatFormFieldModule, NgTemplateOutlet, MatInputModule, MatButtonToggleModule, NgFor, NgIf, MatTableModule, NgClass, MatProgressBarModule, CurrencyPipe, JsonPipe, CommonModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {


  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    const script = this.renderer.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    script.onload = () => {
      (window as any).hbspt.forms.create({
        portalId: '19601658',
        formId: '0d348862-22a7-459e-8c49-ae998463d19e',
        target: '#hubspotFormContainer',
      });
    };
    this.renderer.appendChild(this.el.nativeElement, script);
  }

}
