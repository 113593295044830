import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, JsonPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Component, OnDestroy, OnInit, ViewEncapsulation,ViewChild, Input, AfterViewInit, TemplateRef, AfterViewChecked, ElementRef, ViewChildren, QueryList, HostListener, Output, EventEmitter, Renderer2  } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators,UntypedFormGroup,UntypedFormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';


@Component({
  selector: 'app-integrations',
  standalone: true,
  imports: [ MatIconModule, MatButtonModule, MatSelectModule, MatPaginator, MatPaginatorModule, FormsModule, MatRippleModule, ReactiveFormsModule, MatMenuModule, MatTabsModule, MatFormFieldModule, NgTemplateOutlet, MatInputModule, MatButtonToggleModule, NgFor, NgIf, MatTableModule, NgClass, MatProgressBarModule, CurrencyPipe, JsonPipe, CommonModule],
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss'
})
export class IntegrationsComponent {

}
