<div class="flex items-center flex-auto min-w-0 relative popup-content overflow-visible" >
    <span class="close-dialog" [mat-dialog-close]="true"  (click)="closeDialog()">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>
    <!-- Main -->
     
    <div class="flex-auto  m-p-0 relative overflow-visible" >
         <div class="formBox">
            <div class="flex justify-start gap-2 featureHead">
                <h2 class="text-4xl  font-bold ">Feedback</h2>
            </div>
        <form class="bg-white mt-2 p-6 proposalForm rounded rounded-xl" [formGroup]="feedbackForm" #feedbackNgForm="ngForm">
            <div>
                <div class="beforeSubmit">
                    <div class="flex flex-col gt-xs:flex-row">
                        <mat-form-field class="flex-auto  gt-xs:pl-3 mb-6">
                            <mat-label>Subject </mat-label>
                            <input class="w-full"  matInput [formControlName]="'subject'">
                            <mat-error *ngIf="feedbackForm.get('subject').hasError('required')">
                                Subject is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="flex flex-col gt-xs:flex-row mb-6">
                        <mat-form-field class="flex-auto  gt-xs:pl-3">
                            <mat-label>Message </mat-label>
                            <textarea class="w-full" matInput [formControlName]="'message'" placeholder="You can utilize this space to give your feedback." [rows]="5" cdkTextareaAutosize></textarea>
                            <mat-error *ngIf="feedbackForm.get('message').hasError('required')">
                                Message is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <mat-form-field class="w-full"> -->
                        <div *ngIf="selectedFileNames.length">
                            

                            <div class="flex flex-wrap gap-4" >
                                <div class="fileBox flex flex-col items-center mb-4 relative" *ngFor="let preview of selectedFileNames;let i = index;">
                                    <mat-icon svgIcon="heroicons_outline:document" class="fileic"></mat-icon>
                                    <span class="break-all line-clamp-3">{{preview}}</span>
                                    <!-- <span>Uploaded</span> -->
                                    <span class="deleteImg cursor-pointer" (click)="deleteImage(i)"><mat-icon svgIcon="heroicons_solid:x-mark" color="black"></mat-icon></span>
                                </div>
                            </div>


                                <!-- <input matInput [value]="selectedFileNames.length ? selectedFileNames : 'Select Image'" /> -->

                                <!-- <button mat-flat-button  [color]="'accent'"  [disabled]="!selectedFiles" (click)="uploadFiles()">
                                    <mat-icon svgIcon="heroicons_outline:arrow-up-on-square"></mat-icon>
                                    Upload
                                </button> -->

                            <!-- <input class="mb-5" type="file" id="fileInput" name="fileInput" accept="image/*" (change)="selectFiles($event)" /> -->

                            <!-- <div class="max-w-48 max-h-48" *ngIf="uploadedFileName==''">
                                <img *ngFor='let preview of previews' [src]="preview" class="preview" alt="genai">
                            </div>

                            <div class="max-w-48 max-h-48" *ngIf="uploadedFileName!=''">
                                <img [src]="getImageURL(uploadedFileName)" class="preview" alt="genai">
                            </div> -->

                        </div>
                    <!-- </mat-form-field> -->

                    <div class="flex justify-between">
                        <div class="chooseFile">
                                <span  class="fileSelect" (click)="selectDoc()">
                                    <mat-icon svgIcon="heroicons_outline:arrow-up-on-square" color="white"></mat-icon>
                                    Upload Document
                                </span>
                                <input id="filefeedback" name="filefeedback" style="display:none;" type="file" (change)="selectFiles($event)"  multiple="multiple" />
                        </div>
                        <button class="px-8 submitBt" mat-flat-button [color]="'primary'" (click)="save()" type="button" [disabled]="feedbackForm.invalid">
                            <mat-spinner *ngIf="feedbak_loader" class="matSpinner" [diameter]="24"
                            [mode]="'indeterminate'"></mat-spinner>
                            Submit Feedback
                        </button>
                    </div>
                    <!-- Alert -->
                    <fuse-alert class="mt-8 mb-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="true"  [dismissible]="true" [type]="alert.type">
                        {{alert.message}}
                    </fuse-alert>
                </div>
            </div>
        </form>
        </div>
    </div>
</div>