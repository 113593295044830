
<div class="flex flex-col flex-auto min-w-0 relative" (mousedown)="onClickOutside()">

    <!-- <app-quick-prompt (mouseenter)="onMouseEnterQuickPrompt()" (mouseleave)="onMouseLeaveQuickPrompt()" *ngIf="showQuickPromptMenu" (onCompleteQuickAction)="onCompleteQuickAction($event)" [quickActionSelection]="quickSelectedText" [menuPosition]="menuPosition"></app-quick-prompt> -->


    <div class="genlogo">
        <img src="assets/icons/whitegen.png" alt="">
    </div>

    <span class="close-dialog" [mat-dialog-close]="true" (click)="closeDialog()">
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span>

    <!-- Main -->
    <div class="flex-auto p-6 m-p-0">
        <div class="max-w-5xl formBox">
            <form
                class="p-4  rounded proposalForm"
                [formGroup]="horizontalStepperForm">
                <mat-horizontal-stepper
                    (selectionChange)="onStepChange($event)"
                    [linear]="true"
                    #horizontalStepper [ngClass]="overflowbubble?'overflowVisible':'' " >

                    <mat-step *ngIf="(data['category']=='Project')"
                        [formGroupName]="'step0'"
                        [stepControl]="horizontalStepperForm.get('step0')"
                        #horizontalStepperStep0 >
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>

                        <!-- <img src="assets/icons/form1.png" class="icogif" alt=""> -->

                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight w-80 mt-6">
                            Do you want to create a new project or start from an existing proposal? 
                        </h2>
                        
                        <div class="flex flex-col gt-xs:flex-row">
                            <mat-radio-group class="flex flex-col" [formControlName]="'projectType'">
                                <mat-radio-button class="customRadio" value="new" aria-colspan="">
                                    <div class="radioBox">
                                        <h4 class="text-lg font-medium">Create New Project</h4>
                                        <p class="text-[#656A77] text-sm">Create new project from scratch.</p>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button class="customRadio" value="existing" aria-colspan="">
                                    <div class="radioBox">
                                        <h4 class="font-medium text-lg">Start from an existing Proposal</h4>
                                        <p class="text-[#656A77] text-sm">Convert existing proposal to a project.</p>
                                    </div>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="flex justify-center mt-6">
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep0.stepControl.invalid"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step *ngIf="(data['category']=='Testing')"
                        [formGroupName]="'step1'"
                        [stepControl]="horizontalStepperForm.get('step1')"
                        #horizontalStepperStep1 >
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>

                        <!-- <img src="assets/icons/form1.png" class="icogif" alt=""> -->

                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight w-80 mt-6">
                            Do you want to create from scratch or start from an existing project? 
                        </h2>
                        
                        <div class="flex flex-col gt-xs:flex-row">
                            <mat-radio-group class="flex flex-col" [formControlName]="'testingType'">
                                <mat-radio-button class="customRadio" value="new" aria-colspan="">
                                    <div class="radioBox">
                                        <h4 class="text-lg font-medium">Create new test plan</h4>
                                        <p class="text-[#656A77] text-sm">Create new test cases from scratch.</p>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button class="customRadio" value="existing" aria-colspan="">
                                    <div class="radioBox">
                                        <h4 class="font-medium text-lg">Start from an existing project</h4>
                                        <p class="text-[#656A77] text-sm">Link existing project information to test plan.</p>
                                    </div>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="flex justify-center mt-6">
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep1.stepControl.invalid"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step *ngIf="(horizontalStepperForm.get('step0.projectType')?.value === 'new' || (data['category']=='Proposal') || horizontalStepperForm.get('step1.testingType')?.value === 'new')"
                        [formGroupName]="'step2'"
                        [stepControl]="horizontalStepperForm.get('step2')"
                        #horizontalStepperStep2 >
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>

                        <!-- <img src="assets/icons/form1.png" class="icogif" alt=""> -->

                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-6">
                            What technology do you plan to use for delivering this {{data['category']=='Proposal'?'proposal':'project'}}?
                        </h2>
                        
                        <div class="flex flex-col gt-xs:flex-row">
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectoption">
                                <mat-select
                                matInput [formControlName]="'technology'"
                                [placeholder]="'Select Technology'" (selectionChange)="getcloudTypes($event.value)" required>
                                    @for (option of technologiesTypes; track option) {
                                      <mat-option [value]="option.value" [disabled]="!option.active">{{option.label}}</mat-option>
                                    }
                                  </mat-select>
                                  <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cpu-chip'"></mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="flex justify-center">
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep2.stepControl.invalid"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step *ngIf="(horizontalStepperForm.get('step0.projectType')?.value === 'new' || (data['category']=='Proposal') || horizontalStepperForm.get('step1.testingType')?.value === 'new')"
                        [formGroupName]="'step3'"
                        [stepControl]="horizontalStepperForm.get('step3')"
                        #horizontalStepperStep3>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <!-- <img src="assets/icons/form2.png" class="icogif" alt=""> -->
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-6">
                            Which {{ horizontalStepperForm.get('step2.technology').value }} product do you intend to utilize for the implementation of this {{data['category']=='Proposal'?'proposal':'project'}}?
                        </h2>
                        <div class="flex flex-col gt-xs:flex-row">
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectoption">
                                <mat-select
                                    [formControlName]="'product'"
                                    [placeholder]="'Select Product'"
                                    required multiple>
                                    @for (option of cloudTypes; track option) {
                                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                                    }
                                </mat-select>
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cloud'"></mat-icon>
                            </mat-form-field>
                        </div>
                        
                        <div class="flex justify-center">
                            <button
                                class="px-8 mr-2 "
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep3.stepControl.pristine || horizontalStepperStep3.stepControl.invalid"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step *ngIf="(horizontalStepperForm.get('step0.projectType')?.value === 'new' || (data['category']=='Proposal') || horizontalStepperForm.get('step1.testingType')?.value === 'new')"
                    [formGroupName]="'step4'"
                    [stepControl]="horizontalStepperForm.get('step4')"
                    #horizontalStepperStep4>
                    <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                    <!-- <img src="assets/icons/form3.png" class="icogif" alt=""> -->
                    <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-6">
                        What industry does this {{data['category']=='Proposal'?'proposal':'project'}} belong to?
                    </h2>
                    <div class="flex flex-col gt-xs:flex-row relative">
                        <!-- <mat-form-field class="flex-auto gt-xs:pl-3 selectoption">
                            <mat-select
                                [formControlName]="'industry'"
                                [placeholder]="'Select Industry'"
                                required>
                                @for (option of industries; track option) {
                                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                                }
                            </mat-select>
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                        </mat-form-field> -->
                        <div class="flex flex-wrap items-center -m-1.5 relative">
                            <!-- Tags -->
                            <div *ngIf="horizontalStepperForm.get('step4.industry').value" class="flex-auto gt-xs:pl-3 selectoption">
                                    <div (click)="openTagsPanel('industry')" #tagsPanelOrigin>
                                        <mat-form-field class="flex-auto gt-xs:pl-3 selectoption industryopt ">
                                            <mat-select [placeholder]="horizontalStepperForm.get('step4.industry').value" class="black-placeholder">
                                              
                                            </mat-select>
                                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                                        </mat-form-field> 
                                    </div>

                                <!-- <div>
                                    <div class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                                        <span class="text-md font-medium whitespace-nowrap">{{horizontalStepperForm.get('step3.industry').value}}</span>
                                    </div>
                                </div> -->
                            </div>
                            <div *ngIf="!horizontalStepperForm.get('step4.industry').value" class="flex-auto gt-xs:pl-3 selectoption">
                                   <div (click)="openTagsPanel('industry')"
                                   #tagsPanelOrigin>
                                        <mat-form-field class="flex-auto gt-xs:pl-3 selectoption industryopt">
                                            <mat-select  [placeholder]="'Select Industry'" >
                                            </mat-select>
                                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                                        </mat-form-field> 
                                   </div>
                            </div>
                            <div class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700" >
                                <!-- <ng-container *ngIf="horizontalStepperForm.get('step3.industry').value">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                                    <span class="ml-1.5 text-md font-medium whitespace-nowrap">Edit</span>
                                </ng-container>
    
                                <ng-container *ngIf="!horizontalStepperForm.get('step3.industry').value">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                    <span class="ml-1.5 text-md font-medium whitespace-nowrap">Add</span>
                                </ng-container> -->
            
                                <!-- Tags panel -->
                                <ng-template #tagsPanel>
                                    <div class="w-60 rounded border shadow-md bg-card dropdownC">
                                        <!-- Tags panel header -->
                                        <div class="flex items-center m-3 mr-2">
                                            <div class="flex items-center">
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                                <div class="ml-2">
                                                    <input
                                                        class="w-full min-w-0 py-1 border-0"
                                                        type="text"
                                                        placeholder="Enter Industry"
                                                        (input)="filterTags($event,'industry')"
                                                        (keydown)="filterTagsInputKeyDown($event,'industry')"
                                                        [maxLength]="30"
                                                        #newTagInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
                                            <!-- Tags -->
                                            <ng-container *ngIf="!tagsEditMode">
                                                <ng-container *ngFor="let tag of filteredTags; trackBy: trackByFn">
                                                    <div
                                                        class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                                                        (click)="toggleTaskTag(tag,'industry')"
                                                        matRipple>
                                                        <mat-checkbox
                                                            class="flex items-center h-10 min-h-10 pointer-events-none"
                                                            [checked]="horizontalStepperForm.get('step4.industry').value==tag.label"
                                                            [color]="'primary'"
                                                            [disableRipple]="true">
                                                        </mat-checkbox>
                                                        <div class="ml-1">{{tag.label}}</div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <!-- Create tag -->
                                            <div class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer hover:bg-hover"
                                                (click)="createTag(newTagInput.value,'industry'); newTagInput.value = ''"
                                                matRipple>
                                                <mat-icon
                                                    class="mr-2 icon-size-5"
                                                    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                                <div class="break-all">Create "<b>{{newTagInput.value}}</b>"</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    
                    <div class="flex justify-center mt-6">
                        <button
                            class="px-8 mr-2"
                            mat-flat-button
                            [color]="'accent'"
                            type="button"
                            matStepperPrevious>
                            Back
                        </button>
                        <button
                            class="px-8 "
                            mat-flat-button
                            [color]="'primary'"
                            [disabled]="!horizontalStepperForm.get('step4.industry').value"
                            type="button"
                            matStepperNext>
                            Next
                        </button>
                    </div>
                    </mat-step>

                    <!-- <mat-step
                    [formGroupName]="'step4'"
                    [stepControl]="horizontalStepperForm.get('step4')"
                    #horizontalStepperStep4>
                    <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                    <img src="assets/icons/dot.gif" class="icogif" alt="">
                    <h2 class="text-3xl md:text-4xl font-bold tracking-tight leading-7 sm:leading-10  text-center mb-8">
                        Where will you deliver this proposal?
                    </h2>
                    <div class="flex flex-col gt-xs:flex-row">
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectoption">
                            <mat-select
                                [formControlName]="'location'"
                                [placeholder]="'Select Option'"
                                required>
                                @for (option of country_list; track option) {
                                    <mat-option [value]="option.code">{{option.name}}</mat-option>
                                }
                            </mat-select>
                            <mat-icon
                                class="icon-size-5"
                                matPrefix
                                [svgIcon]="'heroicons_outline:map-pin'"></mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="flex flex-col gt-xs:flex-row">
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectoption">
                            <mat-label>Project Currency</mat-label>
                            <mat-select
                                [formControlName]="'currency'"
                                [placeholder]="'Select Option'"
                                required>
                                <mat-option value="select-1">USD</mat-option>
                                <mat-option value="select-2">Indian Rupee</mat-option>
                                <mat-option value="select-3">select 3</mat-option>
                            </mat-select>
                            <mat-icon
                                class="icon-size-5"
                                matPrefix
                                [svgIcon]="'heroicons_outline:currency-dollar'"></mat-icon>
                        </mat-form-field>
                    </div>
                    
                    <div class="flex justify-center">
                        <!- <button
                            class="px-8 mr-2"
                            mat-flat-button
                            [color]="'accent'"
                            type="button"
                            matStepperPrevious>
                            Back
                        </button> ->
                        <button
                            class="px-8 "
                            mat-flat-button
                            [color]="'primary'"
                            [disabled]="horizontalStepperStep4.stepControl.pristine || horizontalStepperStep4.stepControl.invalid"
                            type="button"
                            matStepperNext>
                            Next
                        </button>
                    </div>
                </mat-step> -->

                    <mat-step *ngIf="(horizontalStepperForm.get('step0.projectType')?.value === 'new' || (data['category']=='Proposal') || horizontalStepperForm.get('step1.testingType')?.value === 'new')"
                        [formGroupName]="'step5'"
                        [stepControl]="horizontalStepperForm.get('step5')"
                        #horizontalStepperStep5>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <!-- <img src="assets/icons/form4.png" class="icogif" alt=""> -->
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                            Could you summarize your {{data['category']=='Proposal'?'proposal':'project'}} in 200 words or less?
                        </h2>

                       
                        
                        <div class="flex flex-col gt-xs:flex-row textField relative">
                           <!-- <span class="font-medium block mb-2"> Proposal Summary *</span> -->
                            <!-- <mat-form-field class="flex-auto  gt-xs:pl-3"> -->
                                <!-- <mat-label>Proposal Summary </mat-label> -->
                                <!-- <textarea
                                class="pb-9"
                                    #summary
                                    id="summary"
                                    (blur)="onTextareaBlur()"
                                    (mouseenter)="onMouseEnter()" 
                                    (mouseleave)="onMouseLeave()"
                                    (mouseup)="onTextSelect($event)"
                                    (select)="onSelectionChange($event)"
                                    (mousedown)="onMouseDown($event)"
                                    (keydown)="onSelectionChange($event)"
                                    matInput
                                    [formControlName]="'summary'"
                                    placeholder="Don't know what to add? Here's an example" (input)="typingStart($event)"
                                    [rows]="5" cdkTextareaAutosize></textarea> -->
                                    <div class="relative summaryTextEd" >
                                        <tiptap-bubble-menu [editor]="editor" id="summary1">
                                            <app-editor-controls 
                                              [editor]="editor" 
                                              [selectedText]="selectedText"
                                              (completeQuickAction)="onCompleteQuickAction($event)">
                                            </app-editor-controls>
                                        </tiptap-bubble-menu>
                                        
                                        <tiptap-editor 
                                        [editor]="editor" 
                                        [formControlName]="'summary'"
                                        (blur)="onTextareaBlur()"
                                        (mouseenter)="onMouseEnter()" 
                                        (mouseleave)="onMouseLeave()"
                                        (mouseup)="onTextSelect($event,'summary')"
                                        (select)="onSelectionChange($event,'summary')"
                                        (mousedown)="onMouseDown($event,'summary')"
                                        (keydown)="onSelectionChange($event,'summary')"
                                        id="editor-with-bubble-menu" class="summarybox" placeholder="Enter Proposal Summary ">
                                        </tiptap-editor>
                                        <div class="flex absolute supportDoc">
                                            <button class="txt-medium h-8 bg-white ml-2 rounded-full px-3 py-1 flex items-center truncate" (click)="triggerFileInput('summary')"><img class="w-5 h-5" src="assets/icons/upload_icon.svg" > Supporting Documents</button>
                                            <button class="txt-medium h-8 bg-white ml-3 rounded-full px-3 py-1 flex items-center truncate" (click)="startSpeechRecognition('summary')" >
                                                <img class="w-5 h-5" *ngIf="!recording" src="assets/icons/mic_icon.svg">
                                            <mat-icon *ngIf="recording" svgIcon="heroicons_outline:stop" color="warn"></mat-icon>
                                            <span *ngIf="!recording">Voice Record </span>
                                            <span *ngIf="recording">Recording</span>
                                            
                                            </button>
                                            <input type="file" accept=".jpg, .png, .pdf, .docx, .pptx, .txt, .csv"  #fileInputSummary style="display: none;" (change)="onFilesSelected($event,'summary')" multiple>
                                           
                                        </div>
                                            <!-- </mat-form-field> -->
                                        
                                           
                                    </div>
                                    <div class="my-1 mb-2" *ngIf="sizeLimitAlert">
                                        <fuse-alert 
                                            [appearance]="'soft'"
                                            [showIcon]="false"
                                            type="error"
                                            [dismissible]="true" [dismissed]="false">
                                            {{alert.message}}
                                        </fuse-alert>
                                    </div>
                                    <div class="my-1 mb-2" *ngIf="infoAlertSummary">
                                        <fuse-alert 
                                            [appearance]="'soft'"
                                            [showIcon]="false"
                                            type="info"
                                            [dismissible]="true" [dismissed]="false" class="infoAlert">
                                            <mat-icon svgIcon="heroicons_outline:x-circle"></mat-icon>
                                            Your input seems too brief or missing key details. Please provide more information or upload supporting documents to ensure accurate {{data['category']=='Proposal'?'proposal':'project'}} generation.
                                        </fuse-alert>
                                    </div>
                                    
                                <app-quick-prompt-tip></app-quick-prompt-tip>

                            <div class="flex flex-wrap -m-2 mt-1 filecont">
                                    <ng-container *ngFor="let file of selectedSupportingFiles;i as index">
                                        <div
                                            class="flex flex-col w-40 h-40 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card"
                                           >
                                           <div class="w-full flex justify-end">
                                            <button (click)="onRemoveFile(file.title,'summary')"><mat-icon class="icon-size-5 text-black"  [svgIcon]="'heroicons_solid:x-mark'" ></mat-icon></button>
                                           </div>
                                           
                                            <div class="aspect-[9/6]">
                                                <div class="flex items-center justify-center h-full">
                                                    <!-- Icons -->
                                                    <div class="relative">
                                                        <mat-icon
                                                            class="icon-size-14 text-hint opacity-50"
                                                            [svgIcon]="'heroicons_solid:document'"></mat-icon>
                                                        <div
                                                            class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                                            [ngClass]="getFileClass(file.type)"
                                                           >
                                                            {{getFileTypeString(file.type)}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                                                <div
                                                    class="truncate text-black"
                                                    [matTooltip]="file.title">{{file.title}}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="my-4" *ngIf="selectedSupportingFiles.length>0">
                                    <div class="text-[#64748B] text-sm mb-1">{{ isProcessingFiles?'Analyzing documents and fetching summary.':'Insight from the document added.'}}</div>
                                    <mat-progress-bar
                                        [mode]="isProcessingFiles?'indeterminate':'determinate'"
                                        value="100"
                                        ></mat-progress-bar>
                                </div>
                        </div>
                       
                      

                        
 
                        <!-- <div class="buttonSec flex justify-center flex-wrap gap-3" >
                            <button class="px-8 mr-2" mat-raised-button [color]="'accent'" type="button" (click)="startSpeechRecognition()"> 
                                <mat-icon *ngIf="!recording" svgIcon="mat_outline:keyboard_voice"></mat-icon> 
                                <mat-icon *ngIf="recording" svgIcon="heroicons_outline:stop" color="warn"></mat-icon>
                                <span *ngIf="!recording">Voice Record </span>
                                <span *ngIf="recording">Recording</span>
                            </button> 
                            <button class="px-8 mr-2" mat-raised-button [color]="'accent'" type="button" disabled> <mat-icon svgIcon="mat_outline:file_upload"></mat-icon> Upload Project </button>  -->
                         
                            <!-- <button class="px-8 mr-2 rewriteAiBt shadow2" mat-raised-button color="white" type="button" (click)="toggleDrawer('summary');rewriteAIDrawer.toggle()" [disabled]="!horizontalStepperForm.get('step4.summary').value">
                                <img src="assets/icons/aiicon1.svg" *ngIf="!rewriteAlert" alt=""> 
                                <mat-progress-spinner
                                *ngIf="rewriteAlert"
                                [diameter]="18"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                                <span class="flex items-center">
                                    Rewrite with AI 
                                </span>
                               
                            </button>  -->
                        <!-- </div> -->
                        
                        
                        <div class="flex justify-center mt-6 flex-wrap btHideSec" id="btnnepre">
                            <button
                                class="px-8 mr-2"
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button *ngIf="displayinfoSummary"
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="getStatus()"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                            <button *ngIf="!displayinfoSummary"
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="getStatus()" (click)="enableInfoSummary()"
                                type="button">
                                Next
                            </button>
                        </div>
                    </mat-step>
                    <!-- 

                    <mat-step
                        [formGroupName]="'step5'"
                        [stepControl]="horizontalStepperForm.get('step5')"
                        #horizontalStepperStep5>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <h2 class="text-3xl md:text-4xl font-bold tracking-tight leading-7 sm:leading-10  text-center mb-8">
                            Could you summarize your proposal in 2000 words or less?
                        </h2>
                        <div class="flex flex-col gt-xs:flex-row">
                            <mat-form-field class="flex-auto  gt-xs:pl-3">
                                <mat-label>Proposal Summary </mat-label>
                                <textarea
                                    matInput
                                    [formControlName]="'summary'"
                                    [placeholder]="'Describe your project in up to 1500 words, including an overview, information about the customer, objectives, and business outcomes.'"
                                    [rows]="5"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="buttonSec flex justify-center">
                            <button class="px-8 mr-2" mat-raised-button [color]="'accent'" type="button" disabled> <mat-icon svgIcon="mat_outline:file_upload"></mat-icon> Upload Project </button> 
                            <button class="px-8 mr-2" mat-raised-button [color]="'accent'" type="button"> <mat-icon svgIcon="mat_outline:keyboard_voice"></mat-icon> Voice Record </button> 
                            <button class="px-8 mr-2 rewriteAiBt" mat-raised-button color="white" type="button" > <img src="assets/icons/aiicon1.svg" alt=""> Rewrite with AI </button> 
                        </div>

                        <div class="flex flex-col gt-xs:flex-row mt-6" >
                            <mat-form-field class="flex-auto  gt-xs:pl-3">
                                <mat-label>Feature Details </mat-label>
                                <textarea
                                    matInput
                                    [formControlName]="'summary'"
                                    [placeholder]="'Write a short description about your project summary'"
                                    [rows]="5"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="buttonSec flex justify-start">
                            <button class="px-8 mr-2" mat-raised-button [color]="'accent'" type="button"> Replace </button> 
                            <button class="px-8 mr-2 " mat-raised-button [color]="'primary'" type="button" > Append </button> 
                        </div>

                        <div class="flex justify-center mt-6">
                            <!- <button
                                class="px-8 mr-2"
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button> ->
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep5.stepControl.pristine || horizontalStepperStep5.stepControl.invalid"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step> -->

                    <mat-step *ngIf="(horizontalStepperForm.get('step0.projectType')?.value === 'new' || (data['category']=='Proposal') || horizontalStepperForm.get('step1.testingType')?.value === 'new')"
                    [formGroupName]="'step6'"
                    [stepControl]="horizontalStepperForm.get('step6')"
                    #horizontalStepperStep6>
                    <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                    <!-- <img src="assets/icons/form4.png" class="icogif" alt=""> -->
                    <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                        What key high-level {{data['category']=='Proposal'?'proposal':'project'}} themes do you aim to achieve with this {{data['category']=='Proposal'?'proposal':'project'}}?
                    </h2>

                    <div class="w-full flex justify-center">
                        <mat-chip-grid #chipGrid aria-label="Enter Capability" class="chip-grid-centered">
                          <mat-chip-row
                            *ngFor="let capability of businessCapabilities"
                            (removed)="onRemoveCapability(capability)"
                            [editable]="true"
                            (edited)="onEditCapability(capability, $event)"
                            [aria-description]="'press enter to edit ' + capability"
                            class="custom-chip">
                            {{ capability }}
                            <button matChipRemove [attr.aria-label]="'remove ' + capability">
                              <mat-icon class="custom-icon">close</mat-icon>
                            </button>
                          </mat-chip-row>
                        
                           <mat-form-field class="custom-input mx-5 themeInput">
                                <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon>
                                <input matInput placeholder="Add More Themes" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                 [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="onAddCapability($event,chipInput)" #chipInput/>
                            </mat-form-field>
                        </mat-chip-grid>

                      </div>
                      <div class="my-1 mb-2" *ngIf="infoAlertBusiness">
                        <fuse-alert 
                            [appearance]="'soft'"
                            [showIcon]="false"
                            type="info"
                            [dismissible]="true" [dismissed]="false" class="infoAlert">
                            <mat-icon svgIcon="heroicons_outline:exclamation-circle"></mat-icon>
                            Double check the selected {{data['category']=='Proposal'?'proposal':'project'}} themes, as they will shape the foundation for your proposal and future {{data['category']=='Proposal'?'proposal':'project'}} stages.
                        </fuse-alert>
                    </div>
                    <div class="flex justify-center mt-6 flex-wrap">
                        <button
                            class="px-8 mr-2"
                            mat-flat-button
                            [color]="'accent'"
                            type="button"
                            matStepperPrevious>
                            Back
                        </button>
                        <button *ngIf="infoAlertBusiness"
                            class="px-8 "
                            mat-flat-button
                            [color]="'primary'"
                            [disabled]="horizontalStepperStep6.stepControl.disabled || horizontalStepperStep6.stepControl.invalid"
                            type="button"
                            matStepperNext>
                            Next
                        </button>
                        <button *ngIf="!infoAlertBusiness"
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep6.stepControl.disabled || horizontalStepperStep6.stepControl.invalid" (click)="enableInfoBusiness()"
                                type="button">
                                Next
                        </button>
                    </div>
                    </mat-step>

                    <mat-step *ngIf="(horizontalStepperForm.get('step0.projectType')?.value === 'new' || (data['category']=='Proposal') || horizontalStepperForm.get('step1.testingType')?.value === 'new')"
                    [formGroupName]="'step7'"
                    [stepControl]="horizontalStepperForm.get('step7')"
                    #horizontalStepperStep7>
                    <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                    <!-- <img src="assets/icons/form4.png" class="icogif" alt=""> -->
                    <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                        What key business outcomes do you aim to achieve with this {{data['category']=='Proposal'?'proposal':'project'}}?
                    </h2>

                    <div class="w-full flex justify-center">
                        <mat-chip-grid #chipGridOutcome aria-label="Enter Outcome" class="chip-grid-centered">
                        <mat-chip-row
                            *ngFor="let outcome of outcomes"
                            (removed)="onRemoveOutcome(outcome)"
                            [editable]="true"
                            (edited)="onEditOutcome(outcome, $event)"
                            [aria-description]="'press enter to edit ' + outcome"
                            class="custom-chip">
                            {{ outcome }}
                            <button matChipRemove [attr.aria-label]="'remove ' + outcome">
                            <mat-icon class="custom-icon">close</mat-icon>
                            </button>
                        </mat-chip-row>
                        
                        <mat-form-field class="custom-input ml-5 themeInput">
                            <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon>
                        <input
                        matInput
                        placeholder="Add More"
                                [matChipInputFor]="chipGridOutcome"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="onAddOutcome($event,chipInput)"
                                #chipInput
                                />
                                </mat-form-field>
                        </mat-chip-grid>

                    </div>

                    <div class="flex justify-center mt-6 flex-wrap">
                        <button
                            class="px-8 mr-2"
                            mat-flat-button
                            [color]="'accent'"
                            type="button"
                            matStepperPrevious>
                            Back
                        </button>
                        <button
                            class="px-8 "
                            mat-flat-button
                            [color]="'primary'"
                            [disabled]="horizontalStepperStep7.stepControl.disabled || horizontalStepperStep7.stepControl.invalid"
                            type="button"
                            matStepperNext>
                            Next
                        </button>
                    </div>
                    </mat-step>

                    <mat-step *ngIf="(data['category']=='Testing' && horizontalStepperForm.get('step1.testingType')?.value === 'existing')"
                        [formGroupName]="'step8'"
                        [stepControl]="horizontalStepperForm.get('step8')"
                        #horizontalStepperStep8 >
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>

                        <!-- <img src="assets/icons/form1.png" class="icogif" alt=""> -->

                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight w-80">
                            Select from the existing list of projects
                        </h2>

                        <div *ngIf="(!allProject.length && loader_project)" class="justify-center flex p-16">

                            <mat-progress-spinner  [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                        </div>
                        
                        <div class="proposalList border rounded" *ngIf="allProject.length">
                            <table mat-table [dataSource]="allProject">
                                <ng-container matColumnDef="id">
                                  <th mat-header-cell *matHeaderCellDef>  </th>
                                  <td mat-cell *matCellDef="let element" (click)="showProject(element._id)"> 
                                    <mat-icon svgIcon="heroicons_outline:eye" class="eyeIc"></mat-icon> 
                                  </td>
                                </ng-container>
                              
                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef> Project </th>
                                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                </ng-container>
                              
                                <!-- technology Column -->
                                <ng-container matColumnDef="technology">
                                  <th mat-header-cell *matHeaderCellDef> Technology </th>
                                  <td mat-cell *matCellDef="let element"> {{element.proposal_meta.technology}} </td>
                                </ng-container>
                              
                                <!-- created Column -->
                                <ng-container matColumnDef="created">
                                  <th mat-header-cell *matHeaderCellDef> Created On </th>
                                  <td mat-cell *matCellDef="let element"> {{format(element.created)}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>  </th>
                                    <td mat-cell *matCellDef="let element" (click)="selectProject(element)"> <mat-icon class="selectPr" svgIcon="heroicons_solid:check-circle"></mat-icon> </td>
                                  </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" ></tr>
                                <tr mat-row (click)="selectRowProject(row)" [class.row_selected]="selectedRowProject === row" *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>



                        <div class="flex justify-center mt-6">
                            <button
                                class="px-8 mr-2"
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep8.stepControl.invalid"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step *ngIf="(data['category']=='Testing')"
                        [formGroupName]="'step9'"
                        [stepControl]="horizontalStepperForm.get('step9')"
                        #horizontalStepperStep9>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <!-- <img src="assets/icons/form2.png" class="icogif" alt=""> -->
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-6">
                            Which type of testing will be performed for this project?
                        </h2>
                        <div class="flex flex-col gt-xs:flex-row">
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectoption">
                                <mat-select
                                    [formControlName]="'testtype'"
                                    [placeholder]="'Select Type'"
                                    required>
                                    @for (option of testingTypes; track option) {
                                        <mat-option [value]="option.value" [disabled]="!option.active">{{option.label}}</mat-option>
                                    }
                                </mat-select>
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cloud'"></mat-icon>
                            </mat-form-field>
                        </div>
                        
                        <div class="flex justify-center">
                            <button
                                class="px-8 mr-2 "
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep9.stepControl.invalid"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>


                    <mat-step *ngIf="(data['category']=='Testing')"
                        [formGroupName]="'step10'"
                        [stepControl]="horizontalStepperForm.get('step10')"
                        #horizontalStepperStep10>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <!-- <img src="assets/icons/form5.png" class="icogif" alt=""> -->
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                            Please provide any additional information about the test plan for this project. 
                        </h2>

                        <div class="flex flex-col gt-xs:flex-row textField relative">
                            <label for="">Test Plan Summary</label>
                            <div class="relative summaryTextEd" >
                                <tiptap-bubble-menu [editor]="editor2" id="summary2">
                                    <app-editor-controls 
                                    [editor]="editor2" 
                                    [selectedText]="selectedText"
                                    (completeQuickAction)="onCompleteQuickAction($event)">
                                    </app-editor-controls>
                                </tiptap-bubble-menu>
                                
                                <tiptap-editor 
                                [editor]="editor2" 
                                [formControlName]="'testplansummary'"
                                (blur)="onTextareaBlur()"
                                (mouseenter)="onMouseEnter()" 
                                (mouseleave)="onMouseLeave()"
                                (mouseup)="onTextSelect($event,'testplansummary')"
                                (select)="onSelectionChange($event,'testplansummary')"
                                (mousedown)="onMouseDown($event,'testplansummary')"
                                (keydown)="onSelectionChange($event,'testplansummary')"
                                id="editor-with-bubble-menu" class="summarybox" placeholder="Please upload all relevant test strategy documents, including Objective, Scope, Out of Scope, Test Environments, Testing Tools, Defect Management, Requirements, User Profile, System Environment, Test Cases, Acceptance Criteria, and Dependencies">
                                </tiptap-editor>
                                <div class="flex absolute supportDoc">
                                    <button class="txt-medium h-8 bg-white ml-2 rounded-full px-3 py-1 flex items-center truncate" (click)="triggerFileInput('testplansummary')"><img class="w-5 h-5" src="assets/icons/upload_icon.svg" > Supporting Documents</button>
                                    <button class="txt-medium h-8 bg-white ml-3 rounded-full px-3 py-1 flex items-center truncate" (click)="startSpeechRecognition('testplansummary')" >
                                        <img class="w-5 h-5" *ngIf="!recording" src="assets/icons/mic_icon.svg">
                                    <mat-icon *ngIf="recording" svgIcon="heroicons_outline:stop" color="warn"></mat-icon>
                                    <span *ngIf="!recording">Voice Record </span>
                                    <span *ngIf="recording">Recording</span>
                                    
                                    </button>
                                    <input type="file" accept=".jpg, .png, .pdf, .docx, .pptx, .txt, .csv"  #fileInputPlanSummary style="display: none;" (change)="onFilesSelected($event,'testplansummary')" multiple>
                                
                                </div>
                                    <!-- </mat-form-field> -->
                                
                                
                            </div>
                            <div class="my-1 mb-2" *ngIf="sizeLimitAlert">
                                <fuse-alert 
                                    [appearance]="'soft'"
                                    [showIcon]="false"
                                    type="error"
                                    [dismissible]="true" [dismissed]="false">
                                    {{alert.message}}
                                </fuse-alert>
                            </div>
                            <div class="my-1 mb-2" *ngIf="infoAlertPlanSummary">
                                <fuse-alert 
                                    [appearance]="'soft'"
                                    [showIcon]="false"
                                    type="info"
                                    [dismissible]="true" [dismissed]="false" class="infoAlert">
                                    <mat-icon svgIcon="heroicons_outline:x-circle"></mat-icon>
                                    Your input seems too brief or missing key details. Please provide more information or upload supporting documents to ensure accurate test plan generation.
                                </fuse-alert>
                            </div>
                                     
                            <app-quick-prompt-tip></app-quick-prompt-tip>
 
                            <div class="flex flex-wrap -m-2 mt-1 filecont">
                                <ng-container *ngFor="let file of selectedPlanSummarySupportingFiles;i as index">
                                    <div
                                        class="flex flex-col w-40 h-40 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card"
                                    >
                                    <div class="w-full flex justify-end">
                                        <button (click)="onRemoveFile(file.title,'testplansummary')"><mat-icon class="icon-size-5 text-black"  [svgIcon]="'heroicons_solid:x-mark'" ></mat-icon></button>
                                    </div>
                                    
                                        <div class="aspect-[9/6]">
                                            <div class="flex items-center justify-center h-full">
                                                <!-- Icons -->
                                                <div class="relative">
                                                    <mat-icon
                                                        class="icon-size-14 text-hint opacity-50"
                                                        [svgIcon]="'heroicons_solid:document'"></mat-icon>
                                                    <div
                                                        class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
                                                        [ngClass]="getFileClass(file.type)"
                                                    >
                                                        {{getFileTypeString(file.type)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
                                            <div
                                                class="truncate text-black"
                                                [matTooltip]="file.title">{{file.title}}</div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="my-4" *ngIf="selectedPlanSummarySupportingFiles.length>0">
                                <div class="text-[#64748B] text-sm mb-1">{{ isProcessingPlanFiles?'Analyzing documents and fetching summary.':'Insight from the document added.'}}</div>
                                <mat-progress-bar
                                    [mode]="isProcessingPlanFiles?'indeterminate':'determinate'"
                                    value="100"
                                    ></mat-progress-bar>
                            </div>
                        </div>
                        <div class="flex justify-center mt-6">
                            <button
                                class="px-8 mr-2"
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="getPlanStatus()"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>
                    <!-- (click)="(horizontalStepperForm.get('step1.testingType')?.value === 'new')?'':createProposal()" -->
                    <mat-step *ngIf="(data['category']=='Testing')"
                        [formGroupName]="'step11'"
                        [stepControl]="horizontalStepperForm.get('step11')"
                        #horizontalStepperStep11>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <!-- <img src="assets/icons/form2.png" class="icogif" alt=""> *ngFor="let capability of businessCapabilities"-->
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                            Which type of test cases will be generated for this project?
                        </h2>
                        <div class="flex flex-wrap gap-4 justify-center" *ngIf="testtypeArray.length">
                            <div *ngFor="let eachtype of testtypeArray;index as i" class="typeCase">
                                {{ eachtype }}
                                <mat-icon svgIcon="heroicons_outline:x-mark" class="icon-size-5" (click)="removeTypeCase(eachtype,i)"></mat-icon>
                            </div>
                        </div>
                        <div class="flex flex-col gt-xs:flex-row">
                            <mat-form-field appearance="fill" class="flex-auto gt-xs:pl-3 testselectoption">  
                                <mat-label class="flex gap-2 items-center justify-center "  >
                                    <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon> 
                                    Add More Types
                                </mat-label>                                
                                <mat-select formControlName="selectedTestCaseTypes" multiple class="generateTestCase">
                                    <mat-select-trigger class="flex gap-2 bg-[#f1f5f9]">
                                        <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon> Add More Types 
                                    </mat-select-trigger>  
                                    <mat-option *ngFor="let option of typeList" [value]="option.value">
                                        {{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <div class="flex gap-4 justify-center">
                                <div *ngFor="let eachtype of testtypeArray.value;i as index" class="typeCase">
                                    {{ eachtype }}
                                    <mat-icon svgIcon="heroicons_outline:x-mark" class="icon-size-5" (click)="removeTypeCase(eachtype)"></mat-icon>
                                </div>

                            </div> -->

<!-- 
                            <mat-form-field class="testselectoption">
                                <mat-label class="flex gap-2 items-center justify-center "  ><mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon> Add More Types</mat-label>
                                <mat-select (openedChange)="openedChangeTestCaseType($event)" multiple class="generateTestCase">
                                    <mat-select-trigger class="flex gap-2">
                                        <!- <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon> Add More Types ->
                                    </mat-select-trigger>
                                    <div class="select-container">
                                        <mat-optgroup class="block">
                                            <mat-form-field style="width:100%;">
                                                <input #searchTestCaseType autocomplete="off" placeholder="Search" aria-label="Search" matInput [formControl]="searchTextboxControlTestCaseType" >
                                                <button [disableRipple]="true" *ngIf="searchTextboxControlTestCaseType.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchTestCaseType($event)">
                                                    <mat-icon >close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </mat-optgroup>
                                        <!- <mat-optgroup *ngIf="(filteredOptionsTestCaseType | async).length == 0">
                                            <div>No results found!</div>
                                        </mat-optgroup> ->
                                        <mat-option (onSelectionChange)="selectionChangeTestCaseType($event)" *ngFor="let option of filteredOptionsTestCaseType | async" [value]="option.value">
                                            {{option.label}}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field> -->
                        </div>
                        
                        <div class="flex justify-center">
                            <button
                                class="px-8 mr-2 "
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="!horizontalStepperForm.get('step11.selectedTestCaseTypes').value?.length"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step *ngIf="(data['category']=='Testing')"
                        [formGroupName]="'step12'"
                        [stepControl]="horizontalStepperForm.get('step12')"
                        #horizontalStepperStep12>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <!-- <img src="assets/icons/form2.png" class="icogif" alt=""> -->
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                            Which platforms will be primarily used to test the application?
                        </h2>
                        <div class="flex flex-wrap gap-4 justify-center" *ngIf="testdeviceArray.length">
                            <div *ngFor="let eachtype of testdeviceArray; index as i" class="typeCase">
                                {{ eachtype }}
                                <mat-icon svgIcon="heroicons_outline:x-mark" class="icon-size-5" (click)="removeDeviceCase(eachtype,i)"></mat-icon>
                            </div>
                        </div>
                        <div class="flex flex-col gt-xs:flex-row ">
                            <mat-form-field appearance="fill" class="flex-auto gt-xs:pl-3 testselectoption">
                                <mat-label class="flex gap-2 items-center justify-center "  >
                                    <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon> 
                                    Add More Platform
                                </mat-label> 
                                <mat-select formControlName="selectedDeviceTypes" multiple class="generateTestCase">
                                    <mat-select-trigger class="flex gap-2 bg-[#f1f5f9]">
                                        <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon> Add More Platform 
                                    </mat-select-trigger>  
                                    <mat-option *ngFor="let option of deviceList" [value]="option.value">
                                        {{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                        <div class="flex justify-center">
                            <button
                                class="px-8 mr-2 "
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="!horizontalStepperForm.get('step12.selectedDeviceTypes').value?.length"
                                type="button"
                                matStepperNext>
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step *ngIf="(data['category']=='Testing')"
                        [formGroupName]="'step13'"
                        [stepControl]="horizontalStepperForm.get('step13')"
                        #horizontalStepperStep13>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <!-- <img src="assets/icons/form2.png" class="icogif" alt=""> -->
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight">
                            Which testing tools will you primarily use to manage test cases?
                        </h2>
                        <div class="flex flex-wrap gap-4 justify-center mb-2" *ngIf="platformArray.controls.length">
                            <div *ngFor="let eachtag of platformArray.controls;index as i" class="typeCase">
                                {{ eachtag.value }}
                                <mat-icon svgIcon="heroicons_outline:x-mark" class="icon-size-5" (click)="removeToolCase(eachtag.value,i)"></mat-icon>
                            </div>
                        </div>
                        <div class="flex flex-wrap items-center -m-1.5 relative justify-center mb-4">
                            <!-- Tags -->
                            <div
                                class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700 border border-black"
                                (click)="openTagsPanel('platform')"
                                #tagsPanelOriginPlatform>
    
                                <ng-container >
                                    <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon> 
                                    <span class="ml-1.5 text-md font-medium whitespace-nowrap">Add More Tools</span>
                                </ng-container>
            
                                <!-- Tags panel -->
                                <ng-template #tagsPanelPlatform>
                                    <div class="w-60 rounded border shadow-md bg-card mt-8">
                                        <!-- Tags panel header -->
                                        <div class="flex items-center m-3 mr-2">
                                            <div class="flex items-center">
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                                <div class="ml-2">
                                                    <input
                                                        class="w-full min-w-0 py-1 border-0"
                                                        type="text"
                                                        placeholder="Enter Platform"
                                                        (input)="filterTags($event,'platform')"
                                                        (keydown)="filterTagsInputKeyDown($event,'platform')"
                                                        [maxLength]="30"
                                                        #newTagInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
                                            <!-- Tags -->
                                            <ng-container *ngIf="!tagsEditModePlatform">
                                                <ng-container *ngFor="let eachtag of filteredTagsPlatform; trackBy: trackByFn">
                                                    <div
                                                        class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                                                        (click)="toggleTaskTag(eachtag,'platform')"
                                                        matRipple>
                                                        <mat-checkbox
                                                            class="flex items-center h-10 min-h-10 pointer-events-none"
                                                            [checked]="platformArray.value.includes(eachtag.label)"
                                                            [color]="'primary'"
                                                            [disableRipple]="true">
                                                        </mat-checkbox>
                                                        <div class="ml-1">{{eachtag.label}}</div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <div
                                                class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer hover:bg-hover"
                                                *ngIf="shouldShowCreateTagButton(newTagInput.value,'platform')"
                                                (click)="createTag(newTagInput.value,'platform'); newTagInput.value = ''"
                                                matRipple>
                                                <mat-icon
                                                    class="mr-2 icon-size-5"
                                                    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                                <div class="break-all">Create "<b>{{newTagInput.value}}</b>"</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        
                        <div class="flex justify-center">
                            <button
                                class="px-8 mr-2 "
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                type="button"
                                matStepperNext (click)="(horizontalStepperForm.get('step1.testingType')?.value === 'new')?'':createProposal()">
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step *ngIf="(data['category']=='Project' && horizontalStepperForm.get('step0.projectType')?.value === 'existing')"
                        [formGroupName]="'step14'"
                        [stepControl]="horizontalStepperForm.get('step14')"
                        #horizontalStepperStep14 >
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>

                        <!-- <img src="assets/icons/form1.png" class="icogif" alt=""> -->

                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight w-80">
                            Select from the existing list of proposals
                        </h2>
                        <div *ngIf="(!allProposal.length && loader_proposal)" class="justify-center flex p-16">
                            <mat-progress-spinner  [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                        </div>
                        
                        <div class="proposalList border rounded" *ngIf="(allProposal.length && !loader_proposal)">
                            <table mat-table [dataSource]="allProposal">
                                <ng-container matColumnDef="id">
                                  <th mat-header-cell *matHeaderCellDef>  </th>
                                  <td mat-cell *matCellDef="let element" (click)="showProposal(element._id)"> 
                                    <mat-icon svgIcon="heroicons_outline:eye" class="eyeIc"></mat-icon> 
                                  </td>
                                </ng-container>
                              
                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef> Proposal </th>
                                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                </ng-container>
                              
                                <!-- technology Column -->
                                <ng-container matColumnDef="technology">
                                  <th mat-header-cell *matHeaderCellDef> Technology </th>
                                  <td mat-cell *matCellDef="let element"> {{element.proposal_meta.technology}} </td>
                                </ng-container>
                              
                                <!-- created Column -->
                                <ng-container matColumnDef="created">
                                  <th mat-header-cell *matHeaderCellDef> Created On </th>
                                  <td mat-cell *matCellDef="let element"> {{format(element.created)}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>  </th>
                                    <td mat-cell *matCellDef="let element" (click)="selectProposal(element._id)"> <mat-icon class="selectPr" svgIcon="heroicons_solid:check-circle"></mat-icon> </td>
                                  </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" ></tr>
                                <tr mat-row (click)="selectRow(row)" [class.row_selected]="selectedRow === row" *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                        <div class="flex justify-center mt-6">
                            <button
                                class="px-8 mr-2"
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button
                                class="px-8 "
                                mat-flat-button
                                [color]="'primary'"
                                [disabled]="horizontalStepperStep14.stepControl.invalid"
                                type="button"
                                matStepperNext (click)="createProposal()">
                                Next
                            </button>
                        </div>
                    </mat-step>

                    <mat-step *ngIf="(horizontalStepperForm.get('step0.projectType')?.value === 'new' || (data['category']=='Proposal') || horizontalStepperForm.get('step1.testingType')?.value === 'new')"
                        [formGroupName]="'step15'"
                        [stepControl]="horizontalStepperForm.get('step15')"
                        #horizontalStepperStep15 >
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>

                        <!-- <img src="assets/icons/form1.png" class="icogif" alt=""> -->

                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-6">
                            What is the name of your {{data['category']=='Proposal'?'proposal':'project'}}?
                        </h2>
                        
                        <div class="flex flex-col gt-xs:flex-row textField mb-4">
                            <div class="relative summaryTextEd" >
                                <!-- <tiptap-bubble-menu [editor]="editor1" id="summary1">
                                    <app-editor-controls 
                                    [editor]="editor1" 
                                    [selectedText]="selectedText"
                                    (completeQuickAction)="onCompleteQuickAction($event)">
                                    </app-editor-controls>
                                </tiptap-bubble-menu>
                                
                                <tiptap-editor 
                                [editor]="editor1" 
                                [formControlName]="'name'"
                                (blur)="onTextareaBlur()"
                                (mouseenter)="onMouseEnter()" 
                                (mouseleave)="onMouseLeave()"
                                (mouseup)="onTextSelect($event,'name')"
                                (select)="onSelectionChange($event,'name')"
                                (mousedown)="onMouseDown($event,'name')"
                                (keydown)="onSelectionChange($event,'name')"
                                id="editor-with-bubble-menu" class="summarybox" placeholder="Enter Test Plan Summary ">
                                </tiptap-editor> -->
                                <div class="flex-auto">
                                    <input id="name" class="customInp"  [formControlName]="'name'"
                                        [placeholder]="'Salesforce CRM Implementation'">
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-center">
                            <button
                                class="px-8 mr-2"
                                mat-flat-button
                                [color]="'accent'"
                                type="button"
                                matStepperPrevious>
                                Back
                            </button>
                            <button
                            class="px-8 "
                            mat-flat-button
                            [color]="'primary'"
                            [disabled]="horizontalStepperStep15.stepControl.invalid"
                            type="button"
                            matStepperNext (click)="createProposal()">
                            Next
                        </button>
                        </div>
                    </mat-step>

                    <mat-step [formGroupName]="'step16'"
                        [stepControl]="horizontalStepperForm.get('step16')" #horizontalStepperStep16>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <!-- <img src="assets/icons/form6.png" class="icogif" alt=""> -->
                        <mat-progress-bar mode="indeterminate" class="matProg"></mat-progress-bar>
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-6">
                            GetGenerative.ai is creating your {{data['category']=='Proposal'?'proposal':(data['category']=='Project'?'project':'test plan')}}..
                            <!-- <div class="flex items-center mr-4" *ngIf="flashMessageType">
                                <ng-container *ngIf="flashMessageType === 'error'">
                                    <mat-icon class="text-red-500" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                    <span class="ml-2">{{flashMessage}}</span>
                                </ng-container>
                            </div> -->
                        </h2>
                        <fuse-alert class="mb-8"  *ngIf="flashMessageType" [type]="flashMessageType" [showIcon]="true" [appearance]="'soft'" [dismissible]="true" [dismissed]="false"> {{ flashMessage }}</fuse-alert>
                    </mat-step>

                    <mat-step [formGroupName]="'step17'"
                    [stepControl]="horizontalStepperForm.get('step17')" #horizontalStepperStep17>
                        <ng-template matStepLabel><span class="blueLine"></span></ng-template>
                        <!-- <img src="assets/icons/form6.png" class="icogif" alt=""> -->
                        <mat-progress-bar mode="determinate" value="100" class="matProg"></mat-progress-bar>
                        <h2 class="font-medium leading-7 mb-4 sm:leading-10 text-3xl text-center tracking-tight mt-6">
                            Your {{data['category']=='Proposal'?'Proposal':(data['category']=='Project'?'Project':'Test Plan')}} is ready.
                        </h2>
                        <div class="flex justify-center mt-8">
                            <button
                                class="px-8"
                                mat-flat-button
                                [color]="'primary'"
                                type="reset"
                                (click)="navigate()">
                                {{button_text}}
                            </button>
                        </div>
                    </mat-step>

                </mat-horizontal-stepper>
            </form>
        </div>
    </div>
</div>

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'rewriteAIDrawer'"
    [position]="'right'" (openedChanged)="openedChanged($event)"
    #rewriteAIDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex flex-row h-20 items-center justify-center relative min-h-20  px-6 text-center w-full">
            <img src="assets/icons/aiicon1.svg" style="width: 20px;" alt=""> 
            <div class="ml-3 text-2xl font-semibold tracking-tight">Rewrite with AI</div>
            <button
                class="absolute right-0"
                mat-icon-button
                (click)="showcustomprompt=false;rewriteAIDrawer.close();clear();">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    
            </button>
        </div>

        <div class="flex flex-col p-6">
            <form [formGroup]="drawerGroupForm">
                <div class="flex flex-col gt-xs:flex-row">
                    <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>Original Text </mat-label>
                        <textarea
                        id="original"
                            matInput [formControlName]="'original'"
                            [placeholder]="'Write a short description about your project summary'"
                            cdkTextareaAutosize [rows]="5"></textarea>
                    </mat-form-field>
                </div>
                <div class="buttonSec flex justify-center"> 
                    <button class="px-8 mr-2 fixgramBt"  mat-raised-button color="primary" type="button" (click)="showcustomprompt=false;fixGrammar()" [disabled]="(!drawerGroupForm.get('original').value || generate_grammar || generate_loader)"> 
                        <img *ngIf="!generate_grammar" src="assets/icons/whiteaiicon.png" style="width: 15px; margin-right: 5px;" alt=""> 
                        <mat-spinner *ngIf="generate_grammar" class="matSpinner" [diameter]="18"></mat-spinner>
                        Fix Grammar </button> 

                    <button class="px-8 mr-2 rewriteAiBt gap-2"  mat-raised-button color="white" type="button" (click)="showcustomprompt=true;" [disabled]="(generate_grammar || generate_loader)"> 
                        <mat-icon color="white" svgIcon="heroicons_outline:squares-plus"></mat-icon> 
                        Custom Prompt 
                    </button> 
                    
                </div>


                <div *ngIf="showcustomprompt" class="flex flex-col gt-xs:flex-row mt-6">
                    <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>Custom Prompt </mat-label>
                        <textarea
                        id="custom"
                            matInput [formControlName]="'custom'"
                            [placeholder]="'Write a short description about your project summary'"
                            cdkTextareaAutosize [rows]="5"></textarea>
                    </mat-form-field>
                </div>
                <div *ngIf="showcustomprompt" class="buttonSec flex justify-center"> 
                    <button class="px-8 mr-2 fixgramBt"  mat-raised-button color="white" type="button" [disabled]="(!drawerGroupForm.get('custom').value || generate_loader)" (click)="rewiteSummary()"> 
                        <!-- <img src="assets/icons/whiteaiicon.png" style="width: 15px; margin-right: 5px;" alt="">  -->
                        <img *ngIf="!generate_loader" src="assets/icons/aiicon1.svg" style="width: 15px; margin-right: 5px;" alt=""> 
                        <mat-spinner  *ngIf="generate_loader" class="matSpinner"  [diameter]="18"></mat-spinner>
                        Generate </button>  
                </div>

                <div class="flex flex-col gt-xs:flex-row mt-6">
                    <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>New Text </mat-label>
                        <textarea
                            id="modified"
                            matInput [formControlName]="'modified'"
                            [placeholder]="'Write a short description about your project summary'"
                            cdkTextareaAutosize [rows]="5"></textarea>
                    </mat-form-field>
                </div>
                <div class="buttonSec flex justify-center"> 

                    <button
                        class="px-8 mr-2 replaceBt"
                        mat-flat-button
                        [color]="'accent'"
                        type="button" [disabled]="(!drawerGroupForm.get('modified').value || generate_grammar || generate_loader)" (click)="newContent('replace')"
                        >
                        <mat-spinner  *ngIf="generate_replace" class="matSpinner"  [diameter]="18"></mat-spinner>
                        Replace
                    </button>
                    <button
                        class="px-8 appendBt"
                        mat-flat-button
                        [color]="'primary'"
                        type="button" [disabled]="(!drawerGroupForm.get('modified').value || generate_grammar || generate_loader)" (click)="newContent('append')"
                        >
                        <mat-spinner  *ngIf="generate_append" class="matSpinner"  [diameter]="18"></mat-spinner>
                        Append
                    </button>
                    
                </div>
            </form>
        </div>

    </div>

</fuse-drawer>