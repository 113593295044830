import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';

import { IPagination } from '../knowledge-base/knowledge-base.types';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';
import { INps } from './nps.types';


@Injectable({providedIn: 'root'})
export class NpsService
{
    private _authService=inject(AuthService);
    private _httpClient = inject(HttpClient);
    private _nps: ReplaySubject<INps[]> = new ReplaySubject<INps[]>(1);
    private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for knowledgebase
     *
     * @param value
     */
    set nps(value: INps[])
    {
        // Store the value
        this._nps.next(value);
    }

    get nps$(): Observable<INps[]>
    {
        return this._nps.asObservable();
    }

     /**
     * Getter for pagination
     */
     get pagination$(): Observable<IPagination>
     {
         return this._pagination.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    getStatus(): Observable<INps[]>
    {
       return this._httpClient.get(environment.api_endpoint + 'nps', { headers: { method: 'get_status'} }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response){
                    this._nps.next(response);
                    return of(response);
                }else{
                    return of([]);
                }
            }),
        );
    }

    getFrequency(): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'nps', { headers: { method: 'get_frequency'} }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response){
                    return of(response);
                }else{
                    return of([]);
                }
            }),
        );
    }
    
    saveFrequency(npsFrequency): Observable<any>
    {
       return this._httpClient.post(environment.api_endpoint + 'nps',JSON.stringify(npsFrequency), { headers: { method: 'save_frequency' } }).pipe(
            catchError(() =>
                // Return false
                of({success:false}),
            ),
            switchMap((response: any) =>
            {
                if(response.success){
                    return of({success:true});
                }else{
                    return of({success:false});
                }
            }),
        );
    }
    
    save(npsData): Observable<any>
    {
       return this._httpClient.post(environment.api_endpoint + 'nps',JSON.stringify(npsData), { headers: { method: 'save' } }).pipe(
            catchError(() =>
                // Return false
                of({success:false}),
            ),
            switchMap((response: any) =>
            {
                if(response.success){
                    return of({success:true});
                }else{
                    return of({success:false});
                }
            }),
        );
    }

}
