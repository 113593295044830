<div class="flex flex-auto flex-col">


    <div class="bg-card flex flex-col items-start border-b p-6 dark:bg-transparent sm:flex-row sm:items-center sm:justify-between sm:py-12 md:px-8">
        <div>
          <div class="text-4xl font-medium leading-none tracking-tight"> Developer Agent <span class="soonTag">Coming Soon</span></div>
          <div class="mt-3 flex items-center text-[#1E293B] ">AI-powered Workspace and Agents to manage the entire development lifecycle for your Salesforce project.</div>
        </div>
        <div class="mt-4 sm:mt-0">
            <button mat-flat-button [color]="'primary'" class="text-md rounded-lg" (click)="openDialog()">
                <span class="ml-2 mr-1">Request Early Access</span>
            </button>
        </div>
    </div>



      <div class="flex justify-between p-6 cardBox flex-wrap">
        <div  class="bg-card card flex flex-col gap-2 px-4 py-6 rounded-lg">
            <div  class="text-secondary">Total Metadata Components</div>
            <div  class="font-medium text-4xl">8</div>
        </div>
        <div  class="bg-card card flex flex-col gap-2 px-4 py-6 rounded-lg">
            <div  class="text-secondary">Configuration Completion %</div>
            <div  class="font-medium text-4xl">30%</div>
        </div>
        <div  class="bg-card card flex flex-col gap-2 px-4 py-6 rounded-lg">
            <div  class="text-secondary">Development Completion %</div>
            <div  class="font-medium text-4xl">25%</div>
        </div>
        <div  class="bg-card card flex flex-col gap-2 px-4 py-6 rounded-lg">
            <div  class="text-secondary">Code Coverage %</div>
            <div  class="font-medium text-4xl">15%</div>
        </div>
    </div>


    <div class="tabBox">
        <mat-tab-group #tabGroup class="sm:px-2" mat-stretch-tabs="false" [animationDuration]="'0'" id="matTab">

            <mat-tab id="low_level_design">
                <ng-template mat-tab-label>
                    <span class="inline-flex items-center space-x-2">
                        <mat-icon svgIcon="heroicons_outline:code-bracket"></mat-icon>
                        <span class=""> Low Level Design</span>
                    </span>
                </ng-template>
                <ng-template matTabContent >
                    <app-low-level-design></app-low-level-design>
                </ng-template>
            </mat-tab>
            <mat-tab id="configuration">
                <ng-template mat-tab-label>
                    <span class="inline-flex items-center space-x-2">
                        <mat-icon svgIcon="heroicons_outline:adjustments-horizontal"></mat-icon>
                        <span class=""> Configuration</span>
                    </span>
                </ng-template>
                <ng-template matTabContent >
                    <app-configuration></app-configuration>
                </ng-template>
            </mat-tab>
            <mat-tab id="integrations">
                <ng-template mat-tab-label>
                    <span class="inline-flex items-center space-x-2">
                        <mat-icon svgIcon="heroicons_outline:link"></mat-icon>
                        <span class=""> Integrations</span>
                    </span>
                </ng-template>
                <ng-template matTabContent >
                    <app-integrations></app-integrations>
                </ng-template>
            </mat-tab>
            <mat-tab id="review_code">
                <ng-template mat-tab-label>
                    <span class="inline-flex items-center space-x-2">
                        <mat-icon svgIcon="heroicons_outline:cpu-chip"></mat-icon>
                        <span class=""> Review Existing Code Metadata</span>
                    </span>
                </ng-template>
                <ng-template matTabContent >
                    <app-review-code></app-review-code>
                </ng-template>
            </mat-tab>

        </mat-tab-group>
    </div>


</div>




