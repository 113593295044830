// event.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private eventSubject = new Subject<any>();

  triggerTour(data: any) {
    this.eventSubject.next(data);
  }

  getEventObservable() {
    return this.eventSubject.asObservable();
  }
}
