import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, FormGroup, FormControl, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { TechnologyDomainService } from 'app/core/technology-domain/technology-domain.service';
import { NpsService } from 'app/core/nps/nps.service';
import { CommonService } from 'app/core/common/common.service';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {v4 as uuidv4} from 'uuid';
import { filter } from 'rxjs';
import { NpsComponent } from '../../nps/nps.component';
import { QuickPromptComponent } from 'app/modules/common/quick-prompt/quick-prompt.component';
import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';

import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { Heading } from '@tiptap/extension-heading';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { DOMSerializer, DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';

@Component({
  selector: 'app-add-feature',
  standalone: true,
  imports      : [EditorControlsComponent, NgxTiptapModule, MatIconModule, FormsModule, FuseDrawerComponent, CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, MatDialogModule,MatProgressBarModule,ReactiveFormsModule, MatStepperModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule, MatProgressSpinnerModule,QuickPromptComponent],
  templateUrl: './add-feature.component.html',
  styleUrl: './add-feature.component.scss'
})
export class AddFeatureComponent {
  @ViewChild('tabGroupUpNgForm') tabGroupUpNgForm: NgForm;
  @ViewChild(FuseDrawerComponent) rewriteAIDrawer: FuseDrawerComponent;

  tabGroupForm: UntypedFormGroup;
  drawerGroupForm: UntypedFormGroup;
  insert_loader= false;
  generate_loader = false;
  generate_replace = false;
  generate_append = false;
  generate_grammar = false;
  showcustomprompt = false;
  complexities: any[] = [];
  cloudTypes: any[] = [];
  themeEpicTypes: any[] = [];
  themeListTypes: any[] = [];
  releases_list:any=[];
  showModal;
  mouseInitialPosition=null;
  isQuickMenuHighlighted:boolean=false;
  showQuickPromptMenu = false;
  menuPosition = { top: '0px', left: '0px' };
  quickSelectedText="";
  insideTextArea=false;
  solutionError=false;
  featureError=false;
  selectedTextAreaType="";
  boxWidth='30rem';
  selectedText: string = '';
  quickSelectionStartIndex=-1;
  quickSelectionEndIndex=-1;
  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a description',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });
  editor1 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a description',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });

  constructor( 
    private _formBuilder: UntypedFormBuilder,
    private _proposalService: ProposalService,
    private _npsService: NpsService,
    private _commonService: CommonService,
    private _websocketService: WebsocketService,
    private _technologyDomainService:TechnologyDomainService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {proposal_detail: object}
  ){
    this.complexities = this._commonService.complexities;
    // this.cloudTypes = this._commonService.cloudTypes['Salesforce'];
    this._technologyDomainService.getWithTechnology(0,100,"Salesforce").subscribe();
    this._technologyDomainService.technologyDomains$.subscribe((response)=>{
      if(response.length){this.cloudTypes=[];
        response.forEach(el =>{
          if(el.active)this.cloudTypes.push({label:el.name,value:el.name});
        })
      }
    });
    
    this._npsService.getStatus().subscribe();
    this._npsService.nps$.subscribe((response)=>{
      if(response)this.showModal=response['showModal'];
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "connection")
    ).subscribe((message) => {
        console.log("Received:", message);
    });
  
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "fix_grammar")
    ).subscribe((message) => {
        // console.log("Received:", message);
        if(!message.isCompleted && message.taskID == this.drawerGroupForm.get('taskID').value){
          let stripContent = this.drawerGroupForm.get('modified').value + message.data;
          this.drawerGroupForm.get('modified').setValue(stripContent);
        }
        else if(message.isCompleted){
          this.generate_grammar = false;
          this.generate_loader = false;
        }
    });
    
  }

  ngOnInit(): void
  {
    // Create the selected product form
    this.tabGroupForm = this._formBuilder.group({
      featureName: ['',Validators.required],
      setupType: ['',Validators.required],
      complexity: ['Medium',Validators.required],
      effort: [0],
      featureDetails: ['',Validators.required],
      noOfStepToConfigureFeatures: [0],
      product: ['',Validators.required],
      epic: ['',Validators.required],
      theme: ['',Validators.required],
      release: ['',Validators.required]
    });

    this.drawerGroupForm = this._formBuilder.group({
      type: [''],
      taskID: [''],
      original: ['', Validators.required],
      custom: [''],
      modified: ['']
    });
    // console.log(this.data['selected_theme']);console.log(this.data['selected_epic']);
    if(this.data['epics'] && this.data['epics'].length){
      this.themeListTypes = [];
      this.data['epics'].forEach(element => {
        let current = {label:element.theme,value:element.theme,epics:[]};
        if(element.epics && element.epics.length){
          element.epics.forEach(epicelement => {
            current['epics'].push({label:epicelement.name,value:epicelement.name})
          });
        }
        this.themeListTypes.push(current);
      });
    }
    if(this.data['selected_theme'] && this.data['selected_epic']){
      let findTheme = this.themeListTypes.find(el=>el.value==this.data['selected_theme']);
      if(findTheme && findTheme.epics){
        this.themeEpicTypes = findTheme.epics;
      }
      this.tabGroupForm.get('epic').setValue(this.data['selected_epic']);
      this.tabGroupForm.get('theme').setValue(this.data['selected_theme']);
    }
    else {
      this.tabGroupForm.get('epic').setValue(' ');
      this.tabGroupForm.get('theme').setValue(' ');
    }
    this.releases_list = JSON.parse(JSON.stringify(this.data['releases_list']));
    this.releases_list.forEach(release => {
      release.requirements = release.requirements || [];
    });
    this.tabGroupForm.get('release').setValue(this.releases_list[0]._id);

  }

  updateSelectedText(editor: Editor): void {
    const { from, to } = editor.state.selection;
    // this.selectedText = editor.state.doc.textBetween(from, to, ' ');
    // this.quickSelectedText = editor.state.doc.textBetween(from, to, ' ');
    // Get the selected content as a ProseMirror Fragment
    const selectedFragment = editor.state.doc.slice(from, to);

    // Convert the Fragment to a DOM Node
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const div = document.createElement('div');
    selectedFragment.content.forEach(node => {
        div.appendChild(serializer.serializeNode(node));
    });

    // Get the selected HTML
    const selectedHTML = div.innerHTML;

    // Store the selection details
    this.quickSelectionStartIndex = from;
    this.quickSelectionEndIndex = to;
    this.selectedText = selectedHTML;
    this.quickSelectedText = selectedHTML;
  }

  toggleDrawer(type){
    this.drawerGroupForm.get('type').setValue(type);
    if(type=='featureDetails')this.drawerGroupForm.get('original').setValue(this.tabGroupForm.get('featureDetails').value);
    else if(type=='effortSummary')this.drawerGroupForm.get('original').setValue(this.tabGroupForm.get('effortSummary').value);
  }

  featureThemeChange(event,inputFormGroupelement){
    // console.log(event);
    let findTheme = this.themeListTypes.find(el=>el.value==event.value);
    if(findTheme && findTheme.epics){
      this.themeEpicTypes = findTheme.epics;
    }
    // console.log("findTheme",findTheme);console.log("infoValues",this.themeEpicTypes);
  }

  newContent(type){
    let obj = {id: this.data['_id']};
    let text = "";
    if(type=='replace'){
      this.generate_replace = true;
      text = this.drawerGroupForm.get('modified').value;
      this.drawerGroupForm.get('original').setValue(text);
    }
    else {
      this.generate_append = true;
      text = this.drawerGroupForm.get('original').value + " " + this.drawerGroupForm.get('modified').value;
      this.drawerGroupForm.get('original').setValue(text);
    }
    if(text){
      this.showcustomprompt=false;
      this.generate_replace = false;
      this.generate_append = false;
      this.drawerGroupForm.get('custom').setValue('');
      if(this.drawerGroupForm.get('type').value=='featureDetails')this.tabGroupForm.get('featureDetails').setValue(text);
      else if(this.drawerGroupForm.get('type').value=='effortSummary')this.tabGroupForm.get('effortSummary').setValue(text);
      // this.drawerGroupForm.get('modified').setValue('');
      // this.drawerGroupForm.get('original').setValue('');
      // this.drawerGroupForm.get('type').setValue('');
      // this.rewriteAIDrawer.close();
    }   
  }

  openedChanged(event){
    if(event===false){
      this.clear();
    }
  }

  clear(){
    this.showcustomprompt=false;
    this.generate_replace = false;
    this.generate_append = false;
    this.drawerGroupForm.get('modified').setValue('');
    this.drawerGroupForm.get('original').setValue('');
    this.drawerGroupForm.get('type').setValue('');
    this.drawerGroupForm.get('custom').setValue('');
  }

  /**
   * Rewrite with AI
  */
  rewiteSummary(){
    this.generate_loader = true;
    let type = this.drawerGroupForm.get('type').value;
    let requirement= this.drawerGroupForm.get('custom').value;
    let taskID=uuidv4();
    this.drawerGroupForm.get('taskID').setValue(taskID);
    if(this._websocketService.isConnected){
      this._websocketService.sendFixGrammarCommand(this.drawerGroupForm.get('original').value, requirement,taskID);
    }
    else {
      setTimeout(() => {
        this.rewiteSummary();
      }, 3000);
    }
    // this._proposalService.fixGrammar({input: this.drawerGroupForm.get('original').value,prompt: requirement})
    // .subscribe(
    //     (value) =>
    //     {
    //         if(value.success){
    //           const regexForStripHTML = /<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi;
    //           const stripContent = value.data.replaceAll(regexForStripHTML, '');
              
    //           this.drawerGroupForm.get('modified').setValue(stripContent);
    //         }
    //         this.generate_loader = false;
    //     },
    //     (response) =>
    //     {
         
    //     },
    // );
  }

  fixGrammar(){
    this.generate_grammar = true;
    let type = this.drawerGroupForm.get('type').value;
    let taskID=uuidv4();
    this.drawerGroupForm.get('taskID').setValue(taskID);
    if(this._websocketService.isConnected){
      this._websocketService.sendFixGrammarCommand(this.drawerGroupForm.get('original').value, "",taskID);
    }
    else {
      
      setTimeout(() => {
        this.fixGrammar();
      }, 3000);
    }
    // this._proposalService.fixGrammar({input: requirement})
    // .subscribe(
    //     (value) =>
    //     {
    //         if(value.success){
    //           const regexForStripHTML = /<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi;
    //           const stripContent = value.data.replaceAll(regexForStripHTML, '');
              
    //           this.drawerGroupForm.get('modified').setValue(stripContent);
    //         }
    //         this.generate_grammar = false;
    //     },
    //     (response) =>
    //     {
         
    //     },
    // );
  }

  insertSelectedFeature(): void
  { //console.log(this.tabGroupForm.invalid);
    if( this.tabGroupForm.invalid || !this.tabGroupForm.get('featureDetails').value || this.tabGroupForm.get('featureDetails').value=='<p></p>')
    {
        // if(!this.tabGroupForm.get('effortSummary').value || this.tabGroupForm.get('effortSummary').value=='<p></p>'){
        //   this.tabGroupForm.get('effortSummary').setValue('');
        //   this.solutionError = true;
        // }
        if(!this.tabGroupForm.get('featureDetails').value || this.tabGroupForm.get('featureDetails').value=='<p></p>'){
          this.tabGroupForm.get('featureDetails').setValue('');
          this.featureError = true;
        }
        return;
    }

    // console.log('asdf');

    // return;
    // Disable the form
    this.tabGroupForm.disable();

    this.insert_loader = true;
    this._proposalService.update({id:this.data['_id'],feature_action:"add",feature_data:{name:this.tabGroupForm.get('featureName').value,epicName:this.tabGroupForm.get('setupType').value,technology:this.data['proposal_meta'].technology,complexity:this.tabGroupForm.get('complexity').value,componentsCount:this.tabGroupForm.get('noOfStepToConfigureFeatures').value,effort:this.tabGroupForm.get('effort').value,cloud:this.tabGroupForm.get('product').value,featureDescription:this.tabGroupForm.get('featureDetails').value,epic:this.tabGroupForm.get('epic').value,theme:this.tabGroupForm.get('theme').value,release:this.tabGroupForm.get('release').value}})
    .subscribe(
      (value) =>
      {
        if(this.insert_loader)this.insert_loader = false;
        this.dialog.closeAll();
        if(this.showModal){
          const dialogRef = this.dialog.open(NpsComponent,{ 
            panelClass: 'custom-dialog-container-nps',
            disableClose: true,
            data: {
              category: this.data['category']
            }
          });
        }
      },
      (response) =>
      {
      },
    );
  }




  onTextSelect(event,targetId){
    
    // console.log("Event:",event);
 
    //  const targetId = (event.target as HTMLElement).id;
    // console.log('Target element ID:', targetId);
    this.selectedTextAreaType=targetId;
     
     
 
     this.mouseInitialPosition=event.pageY;
     //This code place menu at bottom of selection
     const textarea = event.target as HTMLTextAreaElement;
    //  const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
     //console.log('Selected text:', selectedText);
    //  this.quickSelectedText=selectedText;
     const selection = window.getSelection();
     if (selection && selection.toString().trim().length > 0) {
         const range = selection.getRangeAt(0);
         const rect = range.getBoundingClientRect();
 
         /*
         let last=event.pageY-220;
       
         if(last<this.mouseInitialPosition){
         
         this.menuPosition = {
             top: `${last}px`,
             left: this.menuPosition.left
         };
 
         }
         */

         


         /*
         this.menuPosition = {
             top: `${event.clientY + window.scrollY -30}px`,
             left: `${event.clientX+window.scrollX}px`
         };
         */
         this.showQuickPromptMenu = true;
     } else {
         this.quickSelectedText="";
         this.showQuickPromptMenu = false;
     }
     
 
   }
 
   onCompleteQuickAction(newValue){
 
    // console.log(this.selectedTextAreaType);
 
    if(this.selectedTextAreaType=="details"){
      // let updated=this.tabGroupForm.get("featureDetails").value.replace(this.quickSelectedText,newValue);
      // this.tabGroupForm.get("featureDetails").setValue(updated);
      const { from, to } = this.editor.state.selection;
      const parser = ProseMirrorDOMParser.fromSchema(this.editor.schema);
      const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
      const tr: Transaction = this.editor.state.tr.replaceWith(from, to, replacementNode);
      const newTo = from + replacementNode.size;
      this.editor.view.dispatch(tr);
      this.editor.chain().focus().setTextSelection({ from, to: newTo }).run();
    }else{
      // let updated=this.tabGroupForm.get("effortSummary").value.replace(this.quickSelectedText,newValue);
      // this.tabGroupForm.get("effortSummary").setValue(updated);
      const { from, to } = this.editor1.state.selection;
      const parser = ProseMirrorDOMParser.fromSchema(this.editor1.schema);
      const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
      const tr: Transaction = this.editor1.state.tr.replaceWith(from, to, replacementNode);
      const newTo = from + replacementNode.size;
      this.editor1.view.dispatch(tr);
      this.editor1.chain().focus().setTextSelection({ from, to: newTo }).run();
    }
     
    this.showQuickPromptMenu=false;
    this.isQuickMenuHighlighted=false;
    document.getSelection().removeAllRanges();
     
   }

   onTextareaBlur(){
      
     if(!this.insideTextArea && !this.isQuickMenuHighlighted){
         this.showQuickPromptMenu=false;
     }
 
   }
 
   onMouseEnter(){
     
     this.insideTextArea=true;
   }
   onMouseLeave(){
     
     this.insideTextArea=false;
   }
   onMouseEnterQuickPrompt(){
     this.isQuickMenuHighlighted=true;
   }
   onMouseLeaveQuickPrompt(){
     this.isQuickMenuHighlighted=false;
   }
   onSelectionChange(event,targetId){ 
      const textarea = event.target as HTMLTextAreaElement;
      this.solutionError = false;this.featureError = false;
    //  const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    // console.log("Event:",selectedText);
 
    // const targetId = (event.target as HTMLElement).id;
    // console.log('Target element ID:', targetId);
    this.selectedTextAreaType=targetId;

     let clientY=0
     if(this.mouseInitialPosition!=null){
       clientY=this.mouseInitialPosition;
     }
     
    //  this.quickSelectedText=selectedText;
     if (this.quickSelectedText.trim().length > 0) {
 
      /*
         this.menuPosition = {
             top: `${clientY + window.scrollY -30}px`,
             left: `${window.scrollX}px`
         };
         */
         this.showQuickPromptMenu = true;
     } else {
         this.quickSelectedText="";
         this.showQuickPromptMenu = false;
     }
 
 
   
     
   }

   onMouseDown(event,targetId){

    

    if(this.isQuickMenuHighlighted){
      return;
    }

  //  const targetId = (event.target as HTMLElement).id;
     
   this.selectedTextAreaType=targetId;

    
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    if(!this.showQuickPromptMenu){
      const screenWidth = window.innerWidth;
      const xPosition = (event.pageX >= (screenWidth / 2)) ? 350  : 0;
      

      if(this.selectedTextAreaType=="details"){
        this.boxWidth='30rem';
        this.menuPosition = {
          top: `${350}px`,
          left: `${xPosition}px`
        };
      }else{
        this.boxWidth='24rem';
        this.menuPosition = {
          top: `${350}px`,
          left: `${xPosition}px`
        };
      }

      /*
      this.menuPosition = {
        top: `${event.pageY - 220}px`,
        left: `${xPosition}px`
      };
      this.mouseInitialPosition=event.pageY-220;
      */
    }
  }
  onClickOutside(event){
    if(!this.isQuickMenuHighlighted){
   this.isQuickMenuHighlighted=false;
   this.showQuickPromptMenu=false;
    }
    
 }
}
