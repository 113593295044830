import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, from, map, Observable, of, ReplaySubject, switchMap, tap, throwError } from 'rxjs';
import { IProposal } from './proposal.types';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({providedIn: 'root'})
export class ProposalService
{
    private _httpClient = inject(HttpClient);
    private _authService=inject(AuthService);
    private _proposal: ReplaySubject<IProposal[]> = new ReplaySubject<IProposal[]>(1);
    private _currentLoadingEvent:ReplaySubject<number>=new ReplaySubject<number>(1);


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for knowledgebase
     *
     * @param value
     */
    set proposal(value: IProposal[])
    {
        // Store the value
        this._proposal.next(value);
    }

    get proposal$(): Observable<IProposal[]>
    {
        return this._proposal.asObservable();
    }

    set currentLoadingEvent(value:number){
        this._currentLoadingEvent.next(value);
    }
    get currentLoadingEvent$():Observable<number>
    {
        return this._currentLoadingEvent.asObservable();
    }

    incrementCurrentLoadingEvent(current){
        this._currentLoadingEvent.next(current+1);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    
    get(category): Observable<IProposal[]>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?category=' + category, { headers: { method: 'get_all' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response.data && response.data.length){
                    this._proposal.next(response.data);
                    return this._proposal.asObservable();
                }else{
                    return of([]);
                }
            }),
        );
    }

    getListofType(category): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?category=' + category, { headers: { method: 'get_all' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response.data && response.data.length){
                    return of(response);
                }else{
                    return of([]);
                }
            }),
        );
    }

    getTestPlan(): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing', { headers: { method: 'get_all' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response.data && response.data.length){
                    return of(response);
                }else{
                    return of([]);
                }
            }),
        );
    }

    getTestPlanDetail(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?id=' + id, { headers: { method: 'get_by_id' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getTestCases(offset: number, pageSize: number, id, theme, epic, selected_tag, selected_category, selected_type, release, priority, search, sort_order): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?testPlan=' + id + '&theme='+ theme + '&epic=' + epic + '&offset=' + offset + '&pageSize=' + pageSize + '&selected_tag=' + selected_tag + '&selected_category=' + selected_category + '&selected_type=' + selected_type + '&release=' + release + '&priority=' + priority + '&search=' + search + '&sort_order=' + sort_order, { headers: { method: 'get_test_cases' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getTestCaseDefects(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?_id=' + id, { headers: { method: 'get_case_defects' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getDefects(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?testPlan=' + id, { headers: { method: 'get_defects' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getTestRuns(id,limit,skip): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?testPlan=' + id+'&limit='+limit+"&skip="+skip, { headers: { method: 'get_test_run' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }
    getTestRunById(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'testing?testRunId=' + id, { headers: { method: 'test_run_by_id' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }


    getAllStats(category): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?category=' + category, { headers: { method: 'all_stats' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getAllTemplates(): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals', { headers: { method: 'get_all_templates' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getDetail(id,type): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?id='+id + '&type='+type, { headers: { method: 'proposal_details' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response._id){
                    return of(response);
                }else{
                    return of([]);
                }
            }),
        );
    }

    listMembers(id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?proposal='+id, { headers: { method: 'list_members' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getList(obj): Observable<IProposal[]>
    {
        let headers_object = new HttpHeaders().set('method', 'get_all_features');
        let params = new HttpParams();
        if (obj.id) params = params.set('id', obj.id);
        if (obj.technology) params = params.set('technology', obj.technology);
        if (obj.skip) params = params.set('skip', obj.skip);
        if (obj.limit) params = params.set('limit', obj.limit);
        if (obj.search_text) params = params.set('search_text', obj.search_text);
        if (obj.cloud) params = params.set('cloud', obj.cloud);
        let options = { params: params, headers: headers_object };

        //return this.http.get<any>(environment.api_endpoint + 'program', options);
        return this._httpClient.get(environment.api_endpoint + 'proposals', options).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getCloudTypes(): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals', { headers: { method: 'get_all_clouds' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getUserResource(proposalID): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?proposalID='+proposalID, { headers: { method: 'get_user_resource' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getProgressIndicators(proposal_id): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?id='+proposal_id, { headers: { method: 'get_user_progress_indicator' } }).pipe(
            catchError(() =>
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getRecommendation(requirement): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'features?requirement='+ requirement, { headers: { method: 'ai_recommended' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getRecommendationAI(requirement): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'features/unauth?requirement='+ requirement, { headers: { method: 'gen_recommended' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getDocInsights(proposalID,supportingDocId): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'proposals?proposalID=' + proposalID + '&supportingDocId=' + supportingDocId, { headers: { method: 'get_doc_insights' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getDocHistory(proposalID,supportingDocId): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'knowledge-center?proposalID=' + proposalID + '&supportingDocId=' + supportingDocId, { headers: { method: 'get_interaction' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    getDocHistoryByDate(proposalID,supportingDocId,selectedDate): Observable<any>
    {
        return this._httpClient.get(environment.api_endpoint + 'knowledge-center?proposalID=' + proposalID + '&supportingDocId=' + supportingDocId + '&selectedDate=' + selectedDate, { headers: { method: 'get_interaction_by_date' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    fixGrammar(requirement): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'ai',JSON.stringify(requirement), { headers: { method: 'fix_grammar' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    inviteUser(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'invite_user' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updatePermission(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'update_permission' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    save(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'save_proposal' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    create(project): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(project), { headers: { method: 'create_project' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updateViewCount(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'update_view_count' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updateTestCase(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(proposal), { headers: { method: 'update_test_run_case' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    update(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'update_proposal' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteResource(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'delete_resource' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteDefect(defect): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(defect), { headers: { method: 'delete_defect' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    saveResource(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'save_resource' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    saveRelease(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'create_release' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createDefect(defectObject): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(defectObject), { headers: { method: 'create_defect' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updateList(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'update_list' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    savePhase(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(proposal), { headers: { method: 'save_phases' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createTestCases(caseObject): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(caseObject), { headers: { method: 'create_test_case' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createTestRun(testRun): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(testRun), { headers: { method: 'create_test_run' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    createTestPlan(testPlan): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(testPlan), { headers: { method: 'create_test_plan' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    cloneTestRun(id:string,type:string,title:string): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify({_id:id,type:type,title:title}), { headers: { method: 'clone_test_run' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    deleteTestRun(id:string): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify({_id:id}), { headers: { method: 'delete_test_run' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }


    download(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'download',JSON.stringify(proposal), { headers: { method: 'download_proposal' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    downloadSummary(proposal): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'download',JSON.stringify(proposal), { headers: { method: 'download_proposal_cost_summary' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    rewrite(summary_detail): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'ai',JSON.stringify(summary_detail), { headers: { method: 'refine_requirements' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    saveSupportingDocument(supportingDocument:any):Observable<any>{
        return this._httpClient.post(environment.api_endpoint + 'knowledgebase',JSON.stringify(supportingDocument), { headers: { method: 'create_supporting_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    getSupportingDocumentUploadLink(data:any){
        return this._httpClient.post(environment.api_endpoint + 'upload/supporting',JSON.stringify(data), { headers: { method: 'test' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    uploadSupportingFile(uploadUrl: string,file: File, ): Observable<any> {
        return from(
            fetch(uploadUrl, {
              method: 'PUT',
              body: file,
              headers: {
                'Content-Type': file.type
              }
            })
          ).pipe(
            catchError(error => {
              console.error('Error uploading file:', error);
              return throwError(error);
            })
          );
    }

    getProposalInsights(proposalID:string){
        return this._httpClient.get(environment.api_endpoint + 'proposals?_id='+proposalID, { headers: { method: 'get_proposal_insights' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
     
    createUpdateInsightQuestion(insightID:string,title:string,notes:string,associatedDocs:string[],id:string,linkedDocs:string[],oldInsightID:string,add_via_interaction:boolean,reference:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({oldInsight:oldInsightID,insightID:insightID,title:title,notes:notes,_id:id,associatedDocs:associatedDocs,linkedDocs:linkedDocs,add_via_interaction:add_via_interaction,reference:reference}), { headers: { method: 'create_insight' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteInsightQuestion(insightID:string,id:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({insightID:insightID,_id:id}), { headers: { method: 'delete_insight_question' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteRelease(releaseObj){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(releaseObj), { headers: { method: 'delete_release' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    deleteTestCase(releaseObj){
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(releaseObj), { headers: { method: 'delete_test_case' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    bulkDeleteTestRunCases(request){
        return this._httpClient.post(environment.api_endpoint + 'testing',JSON.stringify(request), { headers: { method: 'bulk_delete_test_run_cases' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    getDefectsCount(testRuns){
        return this._httpClient.post(environment.api_endpoint + 'testing',{testRuns:testRuns}, { headers: { method: 'get_defects_count' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    saveInteraction(chatObject){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(chatObject), { headers: { method: 'save_interaction' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }

    updateProposalSupportingDoc(proposalID:string,documentID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({documentID:documentID,_id:proposalID}), { headers: { method: 'update_proposal_supporting_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    updateProposalDoc(updateValue){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(updateValue), { headers: { method: 'update_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    deleteProposalSupportingDoc(proposalID:string,documentID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({documentID:documentID,_id:proposalID}), { headers: { method: 'delete_supporting_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    deleteProposalKCDoc(proposalID:string,documentID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({documentID:documentID,_id:proposalID}), { headers: { method: 'delete_knowledge_center_doc' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
    regenerateProposal(proposalID:string){
        return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({_id:proposalID}), { headers: { method: 'regenerate_proposal' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }


}
