import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';

import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonService } from 'app/core/common/common.service';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertComponent } from '@fuse/components/alert';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { ResourceService } from 'app/core/resource/resource.service';
import { PhaseService } from 'app/core/phase/phase.service';
import { IResource } from 'app/core/resource/resource.types';
import { IPhase } from 'app/core/phase/phase.types';
import { Observable } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'app-resource-allocation',
  standalone: true,
  imports:[MatSelectModule,NgStyle,MatMenuModule,MatMenuTrigger,NgClass,FuseDrawerComponent, MatIconModule,  FormsModule, FuseAlertComponent, NgIf, MatDialogModule,MatProgressBarModule, ReactiveFormsModule,CdkDropList,CdkDragHandle,CdkDrag, CdkDragPreview, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule,MatChipsModule,NgFor],
  templateUrl: './resource-allocation.component.html',
  styleUrl: './resource-allocation.component.scss'
})
export class ResourceAllocationComponent {
  @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
  @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
  @Input() proposalID:string;
  @Input() proposal_detail:any;
  @Input() resources:any;
  @Input() phases:any;
  @Input() is_write_allowed:any;
  @Input() _page_id:any;
  @Output() onResourceModify = new EventEmitter<string>();
  tabGroupForm: UntypedFormGroup;
  @ViewChild(FuseDrawerComponent) insightDrawer: FuseDrawerComponent;
  resourceTypes=[];
  phases$: Observable<IPhase[]>;
  resources$: Observable<IResource[]>;
  filteredTags: IResource[];
  phaseTypes: any[]= [];
  tagsEditMode: boolean = false;
  download_summary_loader = false;
  delete_resource_loader = false;
  download_save_loader = false;
  resourcesArray: FormArray<any>;
  phasesArray: FormArray<any>;
  insightForm:UntypedFormGroup;
  resourceEmpType=[{label:'Onshore',value:'Onshore'},{label:'Offshore',value:'Offshore'}];
  capabilities:string[]=["Business Capability 1","Business Capability 2","Business Capability 3","Business Capability 4"]
  selectedResource=null;
  resource_type='';
  private _tagsPanelOverlayRef: OverlayRef;
  
  constructor(private _changeDetectorRef: ChangeDetectorRef,
    private _commonService:CommonService,
    private _overlay: Overlay,
    public _phaseService:PhaseService,
    public _resourceService:ResourceService,
    private _proposalService: ProposalService,
    private _viewContainerRef: ViewContainerRef,
    private _formBuilder: UntypedFormBuilder){  
  }

  ngOnInit(): void {
    this.phases$=this._phaseService.get(0);
    this.tabGroupForm = this._formBuilder.group({
      phases: new FormArray([
        new FormGroup({
          _id: new FormControl(''),
          name: new FormControl(''),
          currency: new FormControl(''),
          weeks: new FormControl(''),
          hours: new FormControl(''),
          cost: new FormControl(''),
          order: new FormControl(''),
          phase_resources: new FormArray([
            new FormGroup({
              _id: new FormControl(''),
              name: new FormControl(''),
              short_name: new FormControl(''),
              count: new FormControl('')
            })
          ])
        })
      ]),
      resources: new FormArray([
        new FormGroup({
          _id: new FormControl(''),
          name: new FormControl(''),
          title: new FormControl(''),
          short_name: new FormControl(''),
          source_type: new FormControl(''),
          reference_name: new FormControl(''),
          currency: new FormControl(''),
          weeks: new FormControl(''),
          hours: new FormControl(''),
          rate: new  FormControl(''),
          cost: new FormControl(''),
          order: new FormControl(''),
          active: new FormControl(''),
          resource: new FormControl(''),
          type: new FormControl(''),
          resource_phases: new FormArray([
            new FormGroup({
              _id: new FormControl(''),
              phaseId: new FormControl(''),
              proposalphaseId: new FormControl(''),
              name: new FormControl(''),
              weeks: new FormControl('')
            })
          ])
        })
      ])
    });
    this.insightForm = this._formBuilder.group({
      _id: [''],
      title: ['', Validators.required],
      name: ['', Validators.required],
      short_name: [''],
      source_type: [''],
      reference_name: [''],
      currency: [''],
      weeks: [1, Validators.required],
      hours: [1, Validators.required],
      rate: [1, Validators.required],
      cost: [1, Validators.required],
      order: [0, Validators.required],
      active: [true],
      resource: ['', Validators.required],
      type: ['', Validators.required],
      resource_phases: [[''], Validators.required]
    });
    this.resourcesArray = this.tabGroupForm.get('resources') as FormArray;
    this.resourcesArray = this.resources;
    this.phasesArray = this.tabGroupForm.get('phases') as FormArray;
    this.phasesArray = this.phases;
    this.iteratePhases();
    this.setupValueChangeListeners();
    this.iterateResources();
  }

  iteratePhases(): void {
    this.phaseTypes = [];
    this.phasesArray.controls.forEach((control: FormGroup, index: number) => {
      this.phaseTypes.push({label:control.value.name,value:control.value._id,weeks:control.value.weeks,order:control.value.order,proposalphaseId:control.value._id,phaseId:control.value.phase});
    });
  }

  iterateResources(): void {
    this.resourceTypes = [];
    this._proposalService.getUserResource(this.proposalID).subscribe(
      (value) =>
        {
         if(value.data && value.data.length && value.data[0].resources && value.data[0].resources.length){
          value.data[0].resources.forEach(resource => {
            // Process each resource here
            this.resourceTypes.push({label:resource.name,value:resource.resourceId,order:resource.order,type:resource.type,short_name:resource.short_name,currency:resource.currency,rate:resource.rate,source_type:(resource.source_type)?resource.source_type:'Master'});
          });
          if(this.resourceTypes.length){
            this.resourceTypes = this.resourceTypes.sort((a, b) => {
              let orderA = a.order || 0; 
              let orderB = b.order || 0; 
              return orderA - orderB;
            });
            this.filteredTags = this.resourceTypes;
          }
         }
        },
        (response) =>
        {
          console.log(response);
        },
    );
    // this.resources$.subscribe(resources => {
    //   resources = resources.sort((a, b) => {
    //     let orderA = a.order || 0; 
    //     let orderB = b.order || 0; 
    //     return orderA - orderB;
    //   });
    //   // Iterate over the resources array
    //   resources.forEach(resource => {
    //     // Process each resource here
    //     this.resourceTypes.push({label:resource.name,value:resource._id,order:resource.order,type:resource.type,short_name:resource.short_name,currency:resource.rate[0].currency,rate:resource.rate[0].price});
    //   });
    //   this.filteredTags = this.resourceTypes;
    // }, error => {
    //   // Handle any errors
    //   console.error('Error fetching resources:', error);
    // });
  }

  setupValueChangeListeners(): void {
    this.insightForm.get('rate').valueChanges.subscribe(() => {
      this.calculateTotalCost();
    });
  }

  calculateTotalCost(): void {
    const rate = this.insightForm.get('rate').value || 0;
    const weeks = this.insightForm.get('weeks').value || 0;

    let totalHours = weeks * 40;
    const totalCost = rate * totalHours;
    this.insightForm.get('hours').setValue(totalHours);
    this.insightForm.get('cost').setValue(totalCost);//console.log(this.insightForm.value)
  }

  selectionResource(event){
    // console.log(event);
    let findResource = this.resourceTypes.find(el=>el.value==event.value);
    if(findResource){
      this.insightForm.get('_id').setValue('');
      this.insightForm.get('resource').setValue(event.value);
      this.insightForm.get('name').setValue(findResource.label);
      this.insightForm.get('order').setValue(findResource.order);
      this.insightForm.get('type').setValue(findResource.type);
      this.insightForm.get('short_name').setValue(findResource.short_name);
      let source_type = findResource.source_type?findResource.source_type:'Master';
      this.insightForm.get('source_type').setValue(source_type);
      this.insightForm.get('currency').setValue(findResource.currency);
      this.insightForm.get('rate').setValue(findResource.rate);
    }
  }

  /**
     * Toggle task tag
     *
     * @param tag
     */
  toggleTaskTag(event): void
  {   
    // console.log(event);
    let findResource = this.resourceTypes.find(el=>el.value==event.value);
    if(findResource){console.log(findResource);
      this.insightForm.get('_id').setValue('');
      this.insightForm.get('resource').setValue(event.value);
      this.insightForm.get('name').setValue(findResource.label);
      this.insightForm.get('order').setValue(findResource.order);
      this.insightForm.get('type').setValue(findResource.type);
      this.insightForm.get('short_name').setValue(findResource.short_name);
      let source_type = findResource.source_type?findResource.source_type:'Master';
      this.insightForm.get('source_type').setValue(source_type);
      this.insightForm.get('currency').setValue(findResource.currency);
      this.insightForm.get('rate').setValue(findResource.rate);
      //console.log(this.insightForm.value);
    }
  }

  selectionPhase(event){
    let weeks = 0;
    event.value.forEach(el=>{
      let findPhase = this.phaseTypes.find(element => element.value==el);
      if(findPhase)weeks += findPhase.weeks;
    });
    if(weeks>0)this.insightForm.get('weeks').setValue(weeks);
    this.calculateTotalCost();
  }

  getTotalHours(){
    let totalHours=0;let totalCost = 0;
    this.resourcesArray.controls.forEach((control)=>{
      totalHours += parseInt(control.get('hours').value);
      totalCost+= parseInt(control.get('cost').value);
    });
    return totalHours;
  }

  formatMoney(value) {
    return value?value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):0;
  }

  getCurrencyIc(currency){   
    if(currency == 'USD' || currency == 'AUD'){
      return '$';
    }
    if(currency == 'INR'){
      return '₹';
    }
    if(currency == 'EUR'){
      return '€';
    }
    if(currency == 'GBP'){
      return '£';
    }else{
      return '$';
    }
  }
  
  onSelectInsight(eachResource){
    console.log("On select insight:",eachResource);
    if(this._page_id=='proposal' && this.is_write_allowed){
      if(eachResource.value && eachResource.value._id){
        this.selectedResource = eachResource.value._id;
        this.insightForm.patchValue({
          _id: eachResource.value._id,
          title: eachResource.value.title,
          name: eachResource.value.name,
          short_name: eachResource.value.short_name,
          source_type: eachResource.value.source_type,
          reference_name: eachResource.value.reference_name,
          currency: eachResource.value.currency,
          weeks: eachResource.value.weeks,
          hours: eachResource.value.hours,
          count: eachResource.value.count,
          rate: eachResource.value.rate,
          cost: eachResource.value.cost,
          order: eachResource.value.order,
          active: true,
          resource: eachResource.value.resource,
          type: eachResource.value.type,
          resource_phases: eachResource.value.resource_phases.map(el => el.proposalphaseId)
        });
      }
      else {
        this.clear();
      }
      this.insightDrawer.open();
    }
  }

  autoSave() {
    // Implement your save logic here
    console.log('Auto-saving form data:', this.insightForm.value);//return;
    // if (this.insightForm.invalid) return;
    this.download_save_loader = true;
    let all_values = this.insightForm.value;
    let resource_phase = [];
    if(all_values._id!=''){
      this.resourcesArray.controls.forEach((control: FormGroup, index: number) => {
        if(control.value._id==all_values._id && control.value.resource_phases.length){
          all_values.resource_phases.forEach((el, index) => {
            if(control.value.resource_phases.find(element=>element.proposalphaseId==el)){
              resource_phase.push(control.value.resource_phases.find(element=>element.proposalphaseId==el));
            }
            else {
              let findPhase = this.phaseTypes.find(eachphase => eachphase.proposalphaseId==el);
              resource_phase.push({proposalphaseId: el, phaseId: findPhase.phaseId});
            }
          });
        }
        else if(control.value._id==all_values._id && !control.value.resource_phases.length){
          all_values.resource_phases.forEach((el, index) => {
            let findPhase = this.phaseTypes.find(eachphase => eachphase.proposalphaseId==el);
            resource_phase.push({proposalphaseId: el, phaseId: findPhase.phaseId});
          });
        }
      });
    }
    else {
      all_values.resource_phases.forEach((el, index) => {
        let findPhase = this.phaseTypes.find(eachphase => eachphase.proposalphaseId==el);
        resource_phase.push({proposalphaseId: el, phaseId: findPhase.phaseId});
      });
    }

    let updateObject = {
      _id: all_values._id,
      title: all_values.title,
      name: all_values.name,
      short_name: all_values.short_name,
      currency: all_values.currency,
      weeks: all_values.weeks,
      hours: all_values.hours,
      rate: all_values.rate,
      cost: all_values.cost,
      order: all_values.order,
      source_type: all_values.source_type,
      reference_name: all_values.reference_name,
      active: true,
      resource: all_values.resource,
      type: all_values.type,
      phases: resource_phase
    }
    console.log('updateObject:', JSON.stringify(updateObject));
    // return;
    this._proposalService.saveResource({id:this.proposalID,resource:updateObject}).subscribe(
      (value) =>
        {
          if(value.ok){
            this.pushResources(value.resources);
            this.iterateResources();
          }
        },
        (response) =>
        {
        },
    );
  }

  savePhases(){
    let finalPhases = [];
    this.proposal_detail.phases.forEach(currentPhase => {
      let totalCost = 0, totalHours = 0, total_phase_resource_count = 0,  total_phase_resources = [];
      this.resourcesArray.controls.forEach((control: FormGroup, index: number) => {
        let currentResource = control.value;
        let findPhase = currentResource.resource_phases.find(el=>el.proposalphaseId==currentPhase._id);
        if(findPhase){
          total_phase_resource_count += 1;
          totalCost += currentPhase.weeks * 40 * currentResource.rate;
          totalHours += 1 * currentPhase.weeks * 40;
          let exist_type_resource_name = total_phase_resources.findIndex(el2 => el2.resource == currentResource.resource);
          if(exist_type_resource_name>-1){
            total_phase_resources[exist_type_resource_name].count = total_phase_resources[exist_type_resource_name].count+1;
          }
          else {
            total_phase_resources.push({
              resource: currentResource.resource,
              name: currentResource.name,
              short_name: currentResource.short_name,
              type: currentResource.type,
              count: 1
            });
          }
        }
      });
      if(total_phase_resources.length){
        let updateData = {
          ...currentPhase,
          cost: totalCost,
          hours: totalHours,
          phase_resource_count: total_phase_resource_count,
          phase_resources: total_phase_resources
        };
        // console.log("updateData",updateData);
        finalPhases.push(updateData);
      }
    });
    if(finalPhases.length){
      // console.log("finalPhases",finalPhases);
      let totalHours=0;let totalCost = 0;
      this.resourcesArray.controls.forEach((control)=>{
        totalHours += parseInt(control.get('hours').value);
        totalCost+= parseInt(control.get('cost').value);
      });
      this._proposalService.savePhase({id:this.proposalID,phases:finalPhases,totalHours:totalHours,totalCost:totalCost}).subscribe(
        (value) =>
          {
            if(value.ok){
              this.clear();
              this.onResourceModify.emit('false');
              this.insightDrawer.close();
            }
            this.download_save_loader = false;
          },
          (response) =>
          {
          },
      );
    }
  }

  dropped(event: CdkDragDrop<any[]>): void
  {

    // Move the item in the array
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    // Mark for check
    this._changeDetectorRef.markForCheck();
    let fresh_resources = [];
    this.resourcesArray.controls.forEach((eachResource,eachResourceIndex) => {
      eachResource.value['order'] = (eachResourceIndex+1);
      fresh_resources.push(eachResource.value);
    });
    if(fresh_resources.length){
      this._proposalService.saveResource({id:this.proposalID,resources:fresh_resources}).subscribe(
        (value) =>
          {
            if(value.ok){
            }
          },
          (response) =>
          {
          },
      );
    }
  }

  downloadSummary(){
    this.download_summary_loader = true;
     this._proposalService.downloadSummary({id:this.proposalID})
    .subscribe(
      (value) =>
      {
        if(value.status)window.open(value.data, '_blank');
        this.download_summary_loader = false;
      },
      (response) =>
      {
        // console.log(response);
      },
    );
  }

  deleteResource(){
    if(this.selectedResource){
      this.delete_resource_loader = true;
      this._proposalService.deleteResource({id:this.proposalID,resource:{_id:this.selectedResource}}).subscribe(
      (value) =>
        {
          if(value.ok){
            this.pushResources(value.resources);
            this.delete_resource_loader = false;
          }
        },
        (response) =>
        {
        },
      );
    }
    else this.clear();
  }

  pushResources(array){
    this.resourcesArray.clear();
    if (array.length) {
      array.forEach((detail, index) => {
        const resourcesPhaseArray = this._formBuilder.array(
          detail.phases.map(eachPhase => this._formBuilder.group({
            _id: [eachPhase._id],
            phaseId: [eachPhase.phaseId],
            proposalphaseId: [eachPhase.proposalphaseId],
            name: this.phasesArray.value.find(el=>el._id==eachPhase.proposalphaseId)?this.phasesArray.value.find(el=>el._id==eachPhase.proposalphaseId).name:'',
            weeks: this.phasesArray.value.find(el=>el._id==eachPhase.proposalphaseId)?this.phasesArray.value.find(el=>el._id==eachPhase.proposalphaseId).weeks:0
          }))
        );
        this.resourcesArray.push(this._formBuilder.group({
          _id: detail._id,
          name: detail.name,
          title: detail.title,
          short_name: detail.short_name,
          source_type: detail.source_type,
          reference_name: detail.reference_name,
          currency: detail.currency,
          weeks: detail.weeks,
          hours: detail.hours,
          rate: detail.rate,
          cost: detail.cost,
          order: detail.order,
          active: detail.active,
          resource: detail.resource,
          type: detail.type,
          resource_phases: resourcesPhaseArray
        }));
      });
    }
    if(this.resourcesArray.length)this.savePhases();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any
  {
      return item.id || index;
  }

  openedChanged(event){
    if(event===false){
      this.clear();
    }
  }

  clear(){
    //reset values
    this.selectedResource = null;
    this.insightForm.reset();
  }

  onCloseDrawer(){
    this.insightDrawer.close();
  }

  /**
     * Open tags panel
     */
  openTagsPanel(): void
  {
      // Create the overlay
      this._tagsPanelOverlayRef = this._overlay.create({
          backdropClass   : '',
          hasBackdrop     : true,
          scrollStrategy  : this._overlay.scrollStrategies.block(),
          positionStrategy: this._overlay.position()
              .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
              .withFlexibleDimensions(true)
              .withViewportMargin(64)
              .withLockedPosition(true)
              .withPositions([
                  {
                      originX : 'start',
                      originY : 'bottom',
                      overlayX: 'start',
                      overlayY: 'top',
                  },
              ]),
      });

      // Subscribe to the attachments observable
      this._tagsPanelOverlayRef.attachments().subscribe(() =>
      {
          // Focus to the search input once the overlay has been attached
          this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
      });

      // Create a portal from the template
      const templatePortal = new TemplatePortal(this._tagsPanel, this._viewContainerRef);

      // Attach the portal to the overlay
      this._tagsPanelOverlayRef.attach(templatePortal);

      // Subscribe to the backdrop click
      this._tagsPanelOverlayRef.backdropClick().subscribe(() =>
      {
          // If overlay exists and attached...
          if ( this._tagsPanelOverlayRef && this._tagsPanelOverlayRef.hasAttached() )
          {
              // Detach it
              this._tagsPanelOverlayRef.detach();

              // Reset the tag filter
              this.filteredTags = this.resourceTypes;

              // Toggle the edit mode off
              this.tagsEditMode = false;
          }

          // If template portal exists and attached...
          if ( templatePortal && templatePortal.isAttached )
          {
              // Detach it
              templatePortal.detach();
          }
      });
  }

  /**
     * Filter tags
     *
     * @param event
     */
  filterTags(event): void
  {
      // Get the value
      const value = event.target.value.toLowerCase();

      // Filter the tags
      this.filteredTags = this.resourceTypes.filter(tag => tag.label.toLowerCase().includes(value));
  }

  /**
     * Should the create tag button be visible
     *
     * @param inputValue
     */
  shouldShowCreateTagButton(inputValue: string): boolean
  {
      return !!!(inputValue === '' || this.resourceTypes.findIndex(tag => tag.label.toLowerCase() === inputValue.toLowerCase()) > -1);
  }

  /**
     * Filter tags input key down event
     *
     * @param event
     */
  filterTagsInputKeyDown(event): void
  {
      // Return if the pressed key is not 'Enter'
      if ( event.key !== 'Enter' )
      {
          return;
      }

      // If there is no tag available...
      if ( this.filteredTags.length === 0 )
      {
          // Create the tag
          this.createTag(event.target.value);

          // Clear the input
          event.target.value = '';

          // Return
          return;
      }

      // If there is a tag...
      const tag = this.filteredTags[0];
      const isTagApplied = this.resourceTypes.find(id => id === tag._id);

      // If the found tag is already applied to the task...
      if ( isTagApplied )
      {
          // Remove the tag from the task
          // this.deleteTagFromTask(tag);
      }
      else
      {
          // Otherwise add the tag to the task
          this.addTagToTask(tag);
      }
  }

  /**
   * Create a new tag
   *
   * @param title
   */
  createTag(title: string): void
  {
    const tag = {
        title,
    };
    this.addTagToTask(tag);
    
    // this._tagsPanelOverlayRef.detach();
    this._tagsPanelOverlayRef.dispose();
    this.filteredTags = this.resourceTypes;

    // Toggle the edit mode off
    this.tagsEditMode = false;
      // // Create tag on the server
      // this._tasksService.createTag(tag)
      //     .subscribe((response) =>
      //     {
      //         // Add the tag to the task
      //         this.addTagToTask(response);
      //     });
  }
  /**
   * Add tag to the task
   *
   * @param tag
   */
  addTagToTask(tag): void
  {
    // console.log(tag);
    this.insightForm.get('_id').setValue('');
    this.insightForm.get('resource').setValue('create_new');
    this.insightForm.get('name').setValue(tag.title);
    this.insightForm.get('order').setValue(this.resourceTypes.length+1 );
    let result1 = this.createAbbreviation(tag.title);
    this.insightForm.get('short_name').setValue(result1);
    this.insightForm.get('currency').setValue('USD');
    this._tagsPanelOverlayRef.dispose();
    this.filteredTags = this.resourceTypes;

    // Toggle the edit mode off
    this.tagsEditMode = false;
      // // Add the tag
      // this.task.tags.unshift(tag.id);

      // // Update the task form
      // this.taskForm.get('tags').patchValue(this.task.tags);

      // // Mark for check
      // this._changeDetectorRef.markForCheck();
  }

  createAbbreviation(inputString) {
    // Split the string by spaces and filter out any empty values
    let words = inputString.trim().split(/\s+/);
    
    let abbreviation;
    
    if (words.length === 1) {
        // If there is only one word, take the first two letters
        abbreviation = words[0].substring(0, 2).toUpperCase();
    } else {
        // If there are multiple words, take the first letter of each word
        abbreviation = words.map(word => word[0].toUpperCase()).join('');
    }
    
    return abbreviation;
  }
}
