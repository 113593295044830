import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';

import { IPagination } from '../knowledge-base/knowledge-base.types';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';
import { IFeedback } from './feedback.types';


@Injectable({providedIn: 'root'})
export class FeedbackService
{
    private _authService=inject(AuthService);
    private _httpClient = inject(HttpClient);
    private _feedbacks: ReplaySubject<IFeedback[]> = new ReplaySubject<IFeedback[]>(1);
    private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for knowledgebase
     *
     * @param value
     */
    set feedbacks(value: IFeedback[])
    {
        // Store the value
        this._feedbacks.next(value);
    }

    get feedbacks$(): Observable<IFeedback[]>
    {
        return this._feedbacks.asObservable();
    }

     /**
     * Getter for pagination
     */
     get pagination$(): Observable<IPagination>
     {
         return this._pagination.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(pageIndex:number,search:string=""): Observable<IFeedback[]>
    {
        let skip=pageIndex*10;
        let query='feedback?skip='+skip+'&limit=10';
        return this._httpClient.get(environment.api_endpoint + query, { headers: { method: 'get' } }).pipe(
            catchError(() =>

                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                // console.log("response",response);
                if(response.total>0){
                        this._pagination.next({
                            length: response.total,
                            size: 10,
                            page: pageIndex,
                            lastPage: 0,
                            startIndex: 0,
                            endIndex: (response.total-1)
                        });

                        this._feedbacks.next(response.data);
                        return of(response.data);

                }else{
                    return of([]);
                }
            }),
        );
    }

    save(feedback): Observable<any>
    {
       return this._httpClient.post(environment.api_endpoint + 'feedback',JSON.stringify(feedback), { headers: { method: 'save' } }).pipe(
            catchError(() =>
                // Return false
                of({success:false}),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    status(feedback): Observable<any>
    {
       return this._httpClient.post(environment.api_endpoint + 'feedback',JSON.stringify(feedback), { headers: { method: 'status' } }).pipe(
            catchError(() =>
                // Return false
                of({success:false}),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }
}
