import { tags } from './../../../../mock-api/apps/tasks/data';
import { trigger } from '@angular/animations';
import { Component, ElementRef, Inject, ViewChild,TemplateRef,ViewContainerRef,Input, EventEmitter, Output } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Overlay, OverlayRef,OverlayContainer } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JIRA } from 'app/core/common/constants';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { DatePipe, NgIf,NgFor, NgClass } from '@angular/common';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { DialogRef } from '@angular/cdk/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertType,FuseAlertComponent } from '@fuse/components/alert';
import { CommonService } from 'app/core/common/common.service';
import { filter, firstValueFrom } from 'rxjs';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { environment } from 'environments/environment';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import { NgxTiptapModule } from 'ngx-tiptap';
import moment from 'moment';
import { FuseDrawerComponent, FuseDrawerService } from '@fuse/components/drawer';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { RegeneratingProgressBarComponent } from 'app/modules/common/regenerating-progress-bar/regenerating-progress-bar.component';
import { SelectStoriesComponent } from '../select-stories/select-stories.component';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProgressEventTracker } from 'app/core/common/progress_event_tracker';


@Component({
  selector: 'app-edit-user-story',
  standalone: true,
  imports: [NgClass,MatTooltipModule,FuseAlertComponent,RegeneratingProgressBarComponent,MatProgressBarModule,MatIconModule,NgxTiptapModule,NgIf,MatProgressSpinnerModule,DatePipe,NgFor,NgClass,ReactiveFormsModule,MatDialogModule,MatIcon,MatMenuModule,MatInputModule,MatSelectModule,MatButtonModule,MatOptionModule,MatCheckboxModule,MatFormFieldModule,MatRadioModule,FuseDrawerComponent],
  templateUrl: './edit-user-story.component.html',
  styleUrl: './edit-user-story.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditUserStoryComponent {
    @ViewChild(FuseDrawerComponent) viewDrawer: FuseDrawerComponent;
    @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
    @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
    isUploadingFiles:boolean=false;
    flashMessageType: 'success' | 'error' | null = null;
    fileAttachments=[];
    @ViewChild("fileInput") private fileInput:ElementRef;
    userStory:any={_id:""};
    story: any={};
    sizeLimitAlert:boolean=false;
    alert: { type: FuseAlertType; message: string } = {
      type   : 'success',
      message: '',
    };

    isRegenerating:boolean=false;
    allMetadataTypeList: any[]= [];
    themeTypesConfig: any[]= [];
    themeTypesList: any[]= [];
    
    _proposalDetail: any = {};
    private proposalSet: boolean = false;
    private storySet: boolean = false;


    userStoryArray: FormArray<any>;


    @Input() set proposal(value: any) {
      if (value) {
        this._proposalDetail = value;
        this.proposalSet = true;
        this.checkInitialization();
        if(this._proposalDetail.epics && this._proposalDetail.epics.length){
          for(let epic of this._proposalDetail.epics){
            this.themeTypesConfig.push({value:epic._id,label:epic.theme})
          }
        }
      }
    }

    @Input() set storyValue(value: any) {
      if (value) {
        this.story = value;
        this.storySet = true;
        this.checkInitialization();
      }
    }

    @Input() buildID:string;

    @Input()
    metadataMap = new Map();

    @Input()
    themeTypes:any=[];

    @Input()
    epicTypes:any=[];

    @Input()
    technologyDomains:any[]=[];

    @Input()
    release:any=[];

    @Input()
    themeID:string;

    @Input()
    epicID:string;

    @Input()
    allMetadataCategory:any[]=[];

    @Input() set allMetadataType(value: any) {
      if(value)this.allMetadataTypeList=value;
    }

    @Output() storyValueChange = new EventEmitter<any>();
    @Output() saveBuildEvent = new EventEmitter<any>();
    @Output() clearBuildEvent = new EventEmitter<any>();
    @Output() onSaveConfiguration=new EventEmitter<any>();

    editor = new Editor({
      extensions: [
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
        }),
        Underline,
        TextStyle,
        Color,
        Placeholder.configure({
          placeholder: "Enter Description ",
        })
      ]
    });
  
    editor2 = new Editor({
      extensions: [
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
        }),
        Underline,
        TextStyle,
        Color,
        Placeholder.configure({
          placeholder: "Enter Low Level Solution ",
        })
      ]
    });
    isRegeneratingWorkbook=false;
    editor3 = new Editor({
      extensions: [
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
        }),
        Underline,
        TextStyle,
        Color,
        Placeholder.configure({
          placeholder: "Enter Description ",
        })
      ]
    });

    editor4 = new Editor({
      extensions: [
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
        }),
        Underline,
        TextStyle,
        Color,
        Placeholder.configure({
          placeholder: "Enter Assumptions ",
        })
      ]
    });

    editor5 = new Editor({
      extensions: [
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
          },
        }),
        Underline,
        TextStyle,
        Color,
        Placeholder.configure({
          placeholder: "Enter Developer Notes ",
        })
      ]
    });

    newStoryForm:UntypedFormGroup;
    height: string = 'auto';
    tagArray: FormArray<any>;
    filteredTags:any[]=[];
    alltagTypes:any[]=[];
    createdOn=new Date();
    tagsEditMode: boolean = false;
    is_write_allowed: boolean = true;
    isSavingConfig=false;
    storyConfigurations=[];
    allMetadataSubCategoryForm:any[] = [];
    originalLowLevelSolutionSummary:any={};
  
    private _tagsPanelOverlayRef: OverlayRef;
  
    releaseTypes=[];
    statusTypes=[
      {value:JIRA.TO_DO,label:"To Do"},
      {value:JIRA.IN_PROGRESS,label:"In Progress"},
      {value:JIRA.DONE,label:"Done"}
    ]
    priorityTypes=[
      {value:JIRA.MEDIUM,label:"Medium",icon:'assets/icons/p_medium.svg'},
      {value:JIRA.HIGHEST,label:"Highest",icon:'assets/icons/p_highest.svg'},
      {value:JIRA.HIGH,label:"High",icon:'assets/icons/p_high.svg'},
      {value:JIRA.LOWE,label:"Low",icon:'assets/icons/p_low.svg'},
      {value:JIRA.LOWEST,label:"Lowest",icon:'assets/icons/p_lowest.svg'}
    ];

    statusTypesConfig:any[]=[
      {value:"Not Started",label:"Not Started"},
      {value:"In Progress",label:"In Progress"},
      {value:"Done",label:"Done"},
      {value:"On-Hold",label:"On-Hold"},
      {value:"Cancelled",label:"Cancelled"}
    ];
    workTypes:any[]=[
      {value:"Configuration",label:"Configuration"},
      {value:"Customization",label:"Customization"}
    ];
    actionTypes:any[]=[
      {value:"New",label:"New"},
      {value:"Update",label:"Update"},
      {value:"Delete",label:"Delete"}
    ];
    user=null;
    isSavingStory:boolean=false;
    constructor(private _formBuilder: UntypedFormBuilder,
      private progressTracker:ProgressEventTracker,
       private _commonService:CommonService,
    private _userService:UserService,
    public dialog:MatDialog, 
    private _authService:AuthService,
    private _proposalService:ProposalService,
    private websocketService:WebsocketService,
    private _changeDetectorRef: ChangeDetectorRef,
    private atlassianService:AtlassianService,private _viewContainerRef: ViewContainerRef,
    private _overlay: Overlay,private overlayContainer: OverlayContainer){
      if(this.story){
        this.userStory=this.story.story || {_id:""};
        this.story.release=this.release;
        // console.log("Data: ",data);
      }
      this._userService.user$
      .subscribe((user: User) =>
      {
          this.user = user;
  
      });
      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "low_level_solution_generated")
      ).subscribe((message:any) => {
        this.isRegenerating=false;
        this.story['isGeneratingLowLevelSolutions']=false;

        if(message.data && message.data.solutions && message.data.solutions.length){
          let summary=message.data.solutions[0].low_level_solution_summary.summary || "";
          if(summary && summary!=""){
            this.story.lowLevelSolutionSummary.summary=summary;
            this.newStoryForm.get("lowLevelSolutionSummary").setValue(summary);
            this._changeDetectorRef.detectChanges();
          }
        }
      });

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "configuration_workbook_generated")
      ).subscribe((message:any) => {
        if(this.story.isGeneratingConfigurationWorkbook){
          this.storyConfigurations=[];
          this.story['isGeneratingConfigurationWorkbook']=false;
          this._changeDetectorRef.detectChanges();
          this.initConfigurations();
        }
      });


      this.configForm = this._formBuilder.group({
        _id: new FormControl(''),
        name: new FormControl(''),
        description: new FormControl(''),
        label: new FormControl(''),
        object: new FormControl(''),
        cloud: new FormControl(''),
        metadataName: new FormControl(''),
        metadataType: new FormControl(''),
        proposalID: new FormControl(''),
        buildID: new FormControl(''),
        themeID: new FormControl(''),
        epicID: new FormControl(''),
        status: new FormControl(''),
        solutionCategory: new FormControl(''),
        solutionSubCategory: new FormControl(''),
        workType: new FormControl(''),
        actionType: new FormControl(''),
        assumption: new FormControl(''),
        developerNote: new FormControl(''),
        created: new FormControl(''),
        author: this._formBuilder.group({
          personal: this._formBuilder.group({
            name: this._formBuilder.group({
              first: new FormControl(''),
              last: new FormControl('')
            })
          }),
          contact: this._formBuilder.group({
            email: new FormControl('')
          }),
          meta: this._formBuilder.group({
            profileImage: new FormControl('')
          })
        }),
        stories: this._formBuilder.array([])
      });

      this.configForm.valueChanges.subscribe((changes) => {
        this.onFieldChange(changes);
      });

      this.userStoryArray = this._formBuilder.array([]) as FormArray;
  
    }
    getRegenerationType(){
      return 1;
    }
    getStoryID(){
      if(this.story._id!=""){
        if(this.story.jiraKey && this.story.jiraKey!=""){
          return this.story.jiraKey;
        }
        return this.story.storyID;
      }
  
      return "";
    }
  
    initReleaseTypes(){
      this.releaseTypes=this._proposalDetail.releases_list.map((release)=>{
        return  {value:release._id,label:release.name};
      });
    }

    ngOnInit(): void {
      this.autoGrow();


      this.initConfigurations();


      if(!this.themeID || this.themeID==""){
       this.initThemeID(this.story.theme);
      }

      if(!this.epicID || this.epicID==""){
        this.initEpicID(this.story.epic,this.story.theme);
      }

    }

    initThemeID(theme){
      this._proposalDetail.epics.forEach(epic => {
        if(epic.theme==theme){
          this.themeID=epic._id;
        }
      });
    }
    initEpicID(epicName,themeName){
      this._proposalDetail.epics.forEach(epic => {
        if(epic.theme==themeName){
          epic.epics.forEach(subEpic => {
            if(subEpic.name==epicName){
              this.epicID=subEpic._id;
            }
          });
        }
      });
    }


    private initConfigurations(){
     
      if(this.buildID && this.buildID!==""){
        this._proposalService.getStoryConfigurations(this.buildID,this.story._id).subscribe((response:any)=>{
          if(response.success){
           this.storyConfigurations=response.data;
           this._changeDetectorRef.detectChanges();
          
         }
        });
      }
    }
    
  
    private checkInitialization() {
      //console.log(this.proposalSet,this.storySet);
      if (this.proposalSet && this.storySet) {
        this.initializeComponent();
        this.proposalSet = false;
        this.storySet = false;
      }
    }

    initializeComponent(){
      this.isRegenerating=false;
      this.tagArray = this._formBuilder.array([]) as FormArray;
  
      this.newStoryForm = this._formBuilder.group({
        title:["",Validators.required],
        description:["",Validators.required],
        theme:["",Validators.required],
        epic:["",Validators.required],
        release:["",Validators.required],
        priority:["",Validators.required],
        status:["",Validators.required],
        tags:this._formBuilder.array([]),
        lowLevelSolutionSummary:[""]
      });
      this._changeDetectorRef.detach();
      console.log(this.story);
      if (this.story._id && this.story._id !== "") {
        const formValues = {
          description: this.story.description,
          epic: this.story.epic,
          release: this.story.release?._id ? this.story.release._id : this.story.release,
          priority: this.story.priority,
          status: this.story.status,
          lowLevelSolutionSummary: this.story.lowLevelSolutionSummary && this.story.lowLevelSolutionSummary.summary?this.story.lowLevelSolutionSummary.summary:'',
        };
      
        /*
        if (this.story.lowLevelSolutionSummary && this.story.lowLevelSolutionSummary.summary) {
          this.originalLowLevelSolutionSummary = JSON.parse(JSON.stringify(this.story.lowLevelSolutionSummary || {}));
          formValues.lowLevelSolutionSummary = `<p>${this.story.lowLevelSolutionSummary.summary}</p>` +
            this.story.lowLevelSolutionSummary.solutionCategories.map(category => 
              `<b style="font-weight: 600">${category.category}</b>
              <ul>${category.details.map(detail => `<li>${detail}</li>`).join('')}</ul>`
            ).join('') +
            (this.story.lowLevelSolutionSummary.assumptions?.length 
              ? `<b style="font-weight: 600">Assumptions</b>
                <ul>${this.story.lowLevelSolutionSummary.assumptions.map(a => `<li>${a}</li>`).join('')}</ul>` 
              : ''
            );
        }
            */
           this.onChangeTheme({value:[this.story.theme]});
        setTimeout(() => {
          this.newStoryForm.patchValue(formValues, { emitEvent: false });
          setTimeout(() => {
            this.newStoryForm.get('title').setValue(this.story.title, { emitEvent: false });
          }, 5);
          setTimeout(() => {
            this.newStoryForm.get('theme').setValue(this.story.theme, { emitEvent: false });
          }, 10);
          this._changeDetectorRef.reattach();
          this._changeDetectorRef.detectChanges();
        }, 15);
        this.tagArray.clear();
        if (this.story.tags.length) {
          this.story.tags.forEach(tag => {
            this.tagArray.push(new FormControl(tag));
          });
        }
        
        
        this.fileAttachments = this.story.internalAttachments?.map(att => ({ fileName: att })) || [];
      }      
  
      // this.tagArray = this.newStoryForm.get('tags') as FormArray;
  
      this.initReleaseTypes();
  
      if(this.story.release){
        this.newStoryForm.get("release").setValue((this.story.release && this.story.release._id)?this.story.release._id:this.story.release);
      }
    }

      openedChangedEdit(event){
        if(event===false){
          this.clear();
        }
      }

      clear(){
        this.newStoryForm.reset();
      }

    
      onClickSelectFile(){
        this.fileInput.nativeElement.value="";
        this.fileInput.nativeElement.click();
      }
      onSaveStory(){
        
        let saveStoryRequest;
        if(this.story._id && this.story._id!=""){
          //Edit Mode
          saveStoryRequest={
            _id:this.story._id,
            proposalID:this._proposalDetail._id,
            title:this.newStoryForm.get("title").value,
            theme:this.newStoryForm.get("theme").value,
            epic:this.newStoryForm.get("epic").value,
            release:this.newStoryForm.get("release").value,
            priority:this.newStoryForm.get("priority").value,
            status:this.newStoryForm.get("status").value,
            tags:this.tagArray.value || [],
            description:this.newStoryForm.get("description").value,
            attachments:this.fileAttachments.map((attachment)=>attachment.fileName),
            dependencies:[],
            internalAttachments:this.fileAttachments.map((attachment)=>attachment.fileName),
            lowLevelSolutionSummary:{...this.story.lowLevelSolutionSummary,summary:this.newStoryForm.get("lowLevelSolutionSummary").value}
          };
        }else{
          //Create Mode
           saveStoryRequest={
            proposalID:this._proposalDetail._id,
            title:this.newStoryForm.get("title").value,
            theme:this.newStoryForm.get("theme").value,
            epic:this.newStoryForm.get("epic").value,
            release:this.newStoryForm.get("release").value,
            priority:this.newStoryForm.get("priority").value,
            status:this.newStoryForm.get("status").value,
            tags:this.tagArray.value || [],
            description:this.newStoryForm.get("description").value,
            lowLevelSolutionSummary:{summary:this.newStoryForm.get("lowLevelSolutionSummary").value},
            attachments:this.fileAttachments.map((attachment)=>attachment.fileName),
            internalAttachments:this.fileAttachments.map((attachment)=>attachment.fileName),
            dependencies:[]
          };
        }
        
        this.isSavingStory=true;
        this.atlassianService.createUpdateStory(saveStoryRequest).subscribe((response)=>{
          this.isSavingStory=false;
          this.storyValueChange.emit(saveStoryRequest); 
          this.showFlashMessage('success');   
        })
  
      }
  
      getCreatedByInfo(){
        if(this.userStory._id && this.userStory._id!=""){
          //story in edit mode
          const date = new Date(this.userStory.created);
          const formattedDate = date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short', // Use 'short' to get abbreviated month name (e.g., "Oct")
            year: 'numeric'
          });
          return `Created by ${this.userStory.author.personal.name.first} ${this.userStory.author.personal.name.last} on ${formattedDate}`
        }else{
          //new story
          const date = new Date();
          const formattedDate = date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short', // Use 'short' to get abbreviated month name (e.g., "Oct")
            year: 'numeric'
          });
          return `Created by ${this.user.personal.name.first} ${this.user.personal.name.last} on ${formattedDate}`
        }
      }
  
      getAuthorProfileImage(){
        if(this.userStory._id && this.userStory._id!=""){
          //story in edit mode
          return this.userStory.author.meta.profileImage || "";
        }else{
          //new story
          return this.user.author.meta.profileImage || "";
        }
      }
  
      getImageURL(url){
        return environment.file_url_cf+ url + '?token='+this._authService.accessToken
      }
  
    async onFilesSelected(event){
      const maxSizeInBytes = 1048576*20; //20MB
       const input = event.target as HTMLInputElement;
       if (input.files) {
         const files: FileList = input.files;
         // Handle the selected files here
         for(let a=0;a<files.length;a++){
           if(files[a].size>maxSizeInBytes){
             
             this.alert={
               type:"error",
               message:"The maximum allowed file size is 20MB"
             }
             this.sizeLimitAlert=true;
             setTimeout(()=>{
               this.sizeLimitAlert=false;
             },3000);
             return;
         }
       }
  
        //console.log(files);
         for (let i = 0; i < files.length; i++) {
           if(files[i].size>maxSizeInBytes){
             this.alert={
               type:"error",
               message:"The maximum allowed file size is 20MB"
             }
             this.sizeLimitAlert=true;
             setTimeout(()=>{
               this.sizeLimitAlert=false;
             },3000);
           }else{
            let fileData=this.detectFileType(files[i]);
           this.fileAttachments.push({title:files[i].name,type:fileData.value,uploadURL:"",fileName:"",file:files[i],processed:false,fileSize:files[i].size})
  
           }
         }
         await this.uploadAttachmentFiles();
       }
       
    }
  
    detectFileType(file: File): { value: number, label: string } | null {
      const fileTypeMap: { [key: string]: string } = {
        'application/pdf': 'PDF',
        'text/csv': 'CSV',
        'text/plain':"TXT",
        'application/msword': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'image/jpeg': 'JPEG',
        'image/png': 'PNG',
        'image/jpg': 'JPG'
      };
      const fileType = file.type;
      if (fileTypeMap[fileType]) {
        let thisFileType=fileTypeMap[fileType];
        let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.label===thisFileType);
        return { value: fileValue.value, label: thisFileType };
      }
      return null;
    }
  
    async uploadAttachmentFiles(){
      this.isUploadingFiles=true;
      for(let i=0;i<this.fileAttachments.length;i++){
        if(this.fileAttachments[i].uploadURL===""){
          try{
            let uploadResp=await firstValueFrom(this._proposalService.getSupportingDocumentUploadLink({filename: this.fileAttachments[i].title,filetype: this.fileAttachments[i].file.type}));
            this.fileAttachments[i].uploadURL=uploadResp.uploadURL;
            this.fileAttachments[i].fileName=uploadResp.filename;
          }catch(error){
            console.log("Error:",error);
          }
          await firstValueFrom(this._proposalService.uploadSupportingFile(this.fileAttachments[i].uploadURL,this.fileAttachments[i].file));   
        }
      }
  
      this.isUploadingFiles=false;
      this.newStoryForm.markAsDirty();
      this._changeDetectorRef.detectChanges();
    }
  
    getFileTypeString(file){
      if(file.title && file.title!=""){
        let splits=file.title.split(".");
        let fileExtension=splits[splits.length-1];
        return fileExtension.toUpperCase();
      }
      else if(file.fileName && file.fileName!=""){
        let splits=file.fileName.split(".");
        let fileExtension=splits[splits.length-1];
        return fileExtension.toUpperCase();
      }
      return "";
    }
    
    onRemoveFile(attachment){
      let attachmentIndex=this.fileAttachments.findIndex((att)=>att.fileName==attachment.fileName);
  
      if(attachmentIndex>=0){
        this.fileAttachments.splice(attachmentIndex,1);
        this.newStoryForm.markAsDirty();
        this._changeDetectorRef.detectChanges();
      }
    }
  
    getFileClass(attachment): string {
       if(attachment.fileName && attachment.fileName!=""){
      let extension=this.getFileTypeString(attachment);
      let fileType:any=this._commonService.fileTypes.find((file)=>file.label==extension);
  
      if(fileType){
        fileType=fileType.value;
  
      switch (fileType) {
        case 0: return 'bg-red-600';
        case 1: return 'bg-green-600';
        case 2: return 'bg-gray-600';
        case 3: return 'bg-blue-600';
        case 4: return 'bg-blue-500';
        case 5: return 'bg-yellow-600';
        case 6: return 'bg-yellow-500';
        case 7: return 'bg-orange-600';
        case 8: return 'bg-orange-500';
        case 9: return 'bg-amber-600';
        case 10: return 'bg-green-600';
        default: return 'bg-gray-600';
      }
    }
  }
    }
    
    /**
       * Open tags panel
       */
    openTagsPanel(): void
    {
      this.filteredTags = this.alltagTypes;
        // Create the overlay
        this._tagsPanelOverlayRef = this._overlay.create({
            backdropClass   : '',
            hasBackdrop     : true,
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
                .withFlexibleDimensions(true)
                .withViewportMargin(64)
                .withLockedPosition(true)
                .withPositions([
                    {
                        originX : 'start',
                        originY : 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                ]),
        });
  
        // Subscribe to the attachments observable
        this._tagsPanelOverlayRef.attachments().subscribe(() =>
        {
            // Focus to the search input once the overlay has been attached
            this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
        });
  
        // Create a portal from the template
        const templatePortal = new TemplatePortal(this._tagsPanel, this._viewContainerRef);
  
        // Attach the portal to the overlay
        this._tagsPanelOverlayRef.attach(templatePortal);
  
        // Subscribe to the backdrop click
        this._tagsPanelOverlayRef.backdropClick().subscribe(() =>
        {
            // If overlay exists and attached...
            if ( this._tagsPanelOverlayRef && this._tagsPanelOverlayRef.hasAttached() )
            {
                // Detach it
                this._tagsPanelOverlayRef.detach();
  
                // Reset the tag filter
                this.filteredTags = this.alltagTypes;
  
                // Toggle the edit mode off
                this.tagsEditMode = false;
            }
  
            // If template portal exists and attached...
            if ( templatePortal && templatePortal.isAttached )
            {
                // Detach it
                templatePortal.detach();
            }
        });
    }
  
    /**
       * Filter tags
       *
       * @param event
       */
    filterTags(event): void
    {
        // Get the value
        const value = event.target.value.toLowerCase();
  
        // Filter the tags
        this.filteredTags = this.alltagTypes.filter(tag => tag.label.toLowerCase().includes(value));
    }
  
    /**
       * Should the create tag button be visible
       *
       * @param inputValue
       */
    shouldShowCreateTagButton(inputValue: string): boolean
    {
        return !!!(inputValue === '' || this.alltagTypes.findIndex(tag => tag.label.toLowerCase() === inputValue.toLowerCase()) > -1);
    }
  
    /**
       * Filter tags input key down event
       *
       * @param event
       */
    filterTagsInputKeyDown(event): void
    {
        // Return if the pressed key is not 'Enter'
        if ( event.key !== 'Enter' )
        {
            return;
        }
  
        // If there is no tag available...
        if ( this.filteredTags.length === 0 )
        {
            // Create the tag
            this.createTag(event.target.value);
  
            // Clear the input
            event.target.value = '';
  
            // Return
            return;
        }
  
        // If there is a tag...
        const tag = this.filteredTags[0];
        const isTagApplied = this.alltagTypes.find(id => id === tag._id);
  
        // If the found tag is already applied to the task...
        if ( isTagApplied )
        {
            // Remove the tag from the task
            // this.deleteTagFromTask(tag);
        }
        else
        {
            // Otherwise add the tag to the task
            this.addTagToTask(tag);
        }
    }
  
    toggleTaskTag(event): void
    {   
      // console.log(event);
      this.filteredTags = this.alltagTypes.filter(tag => !tag.label.toLowerCase().includes(event.value));
      this.alltagTypes = this.filteredTags;
      // console.log(this.alltagTypes);
      this.tagArray.clear();
      this.filteredTags.forEach((eachtag) => {
        this.tagArray.push(new FormControl(eachtag.label));
      });
      // Toggle the edit mode off
      this.tagsEditMode = false;
      this._changeDetectorRef.detectChanges();
      // this.autoSave();
    }
  
    /**
     * Create a new tag
     *
     * @param title
     */
    createTag(title: string): void
    {
      //console.log('second',title);
      const tag = {
          title,
      };
      this.addTagToTask(tag);
      
      // this._tagsPanelOverlayRef.detach();
      this._tagsPanelOverlayRef.dispose();
      this.filteredTags = this.alltagTypes;
      // console.log(this.filteredTags);
      this.newStoryForm.markAsDirty();
      // Toggle the edit mode off
      this.tagsEditMode = false;
      // console.log(this.tagsEditMode);
      this._changeDetectorRef.detectChanges();
    }
    /**
     * Add tag to the task
     *
     * @param tag
     */
    addTagToTask(tag): void
    {
      // console.log(tag);
      this._tagsPanelOverlayRef.dispose();
      this.tagArray.push(new FormControl(tag.title));
      // console.log('second');
      // console.log(this.tagArray);
  
      this.alltagTypes.push({label:tag.title,value:tag.title});
      this.filteredTags = this.alltagTypes;
      // console.log(this.filteredTags);
      // Toggle the edit mode off
      this.tagsEditMode = false;
    }
  
    viewStoryInJira(story){
      if(!story.jiraSynced){
        return;
      }
      let issueURL=this.atlassianService.constructJiraIssueLink(this._proposalDetail.integrations.jira.site,story.jiraKey);
      window.open(issueURL || "", "_blank");
    }
  
  
    
    getPriority(priority:number){
  
      let priorityFound=this.priorityTypes.find((p)=>p.value==priority);
  
      if(priorityFound){
        return priorityFound;
      }
  
      return  this.priorityTypes[0];
  
  
    }
  
  
    updatePriority(event){
      // console.log(event);
      
      this.newStoryForm.get('priority').setValue(event.value);
    }
  
  
    
    getStatusString(status){
  
      if(status==JIRA.TO_DO){
        return "To Do";
      }else if(status==JIRA.IN_PROGRESS){
        return "In Progress";
      }else if(status==JIRA.DONE){
        return "Done";
      }
    }
    ngOnDestroy() {
      this.editor.destroy();
    }
  
   
    // Toolbar methods for specific commands
  //   toggleBold() {
  //     this.editor.chain().focus().toggleBold().run();
  //     this.editor3.chain().focus().toggleBold().run();
  //     this.editor4.chain().focus().toggleBold().run();
  //     this.editor5.chain().focus().toggleBold().run();
  //  }
 
  //  toggleItalic() {
  //     this.editor.chain().focus().toggleItalic().run();
  //     this.editor3.chain().focus().toggleItalic().run();
  //     this.editor4.chain().focus().toggleItalic().run();
  //     this.editor5.chain().focus().toggleItalic().run();
  //  }
 

 
  //  toggleBulletList() {
  //    this.editor2.chain().focus().toggleBulletList().run();
  //    this.editor3.chain().focus().toggleBulletList().run();
  //    this.editor4.chain().focus().toggleBulletList().run();
  //    this.editor5.chain().focus().toggleBulletList().run();
  //  }



  toggleBold(editorname) {

    if(editorname === 'desc'){      
      this.editor.chain().focus().toggleBold().run();
    }
    if(editorname === 'lowlevel'){      
      this.editor2.chain().focus().toggleBold().run();
    }
    if(editorname === 'desc2'){      
      this.editor3.chain().focus().toggleBold().run();
    }
    if(editorname === 'assumption'){      
      this.editor4.chain().focus().toggleBold().run();
    }
    if(editorname === 'notes'){      
      this.editor5.chain().focus().toggleBold().run();
    }

  }


  toggleItalic(editorname) {

    if(editorname === 'desc'){      
      this.editor.chain().focus().toggleItalic().run();
    }
    if(editorname === 'lowlevel'){      
      this.editor2.chain().focus().toggleItalic().run();
    }
    if(editorname === 'desc2'){      
      this.editor3.chain().focus().toggleItalic().run();
    }
    if(editorname === 'assumption'){      
      this.editor4.chain().focus().toggleItalic().run();
    }
    if(editorname === 'notes'){      
      this.editor5.chain().focus().toggleItalic().run();
    }



  }

  toggleBulletList(editorname) {
    
    if(editorname === 'desc'){      
      this.editor.chain().focus().toggleBulletList().run();
    }
    if(editorname === 'lowlevel'){      
      this.editor2.chain().focus().toggleBulletList().run();
    }
    if(editorname === 'desc2'){      
      this.editor3.chain().focus().toggleBulletList().run();
    }
    if(editorname === 'assumption'){      
      this.editor4.chain().focus().toggleBulletList().run();
    }
    if(editorname === 'notes'){      
      this.editor5.chain().focus().toggleBulletList().run();
    }

    
  }



   
   toggleBoldEditor2() {
    this.editor2.chain().focus().toggleBold().run();
 }

 toggleItalicEditor2() {
    this.editor2.chain().focus().toggleItalic().run();
 }

 toggleHeadingEditor2(level: number) {
    // this.editor.chain().focus().toggleHeading([ level ]).run();
 }

 toggleBulletListEditor2() {
   this.editor2.chain().focus().toggleBulletList().run();
 }

 
 onRegenerateSolution(){
  if(this.isRegeneratingWorkbook || this.story.isGeneratingConfigurationWorkbook){
    return;
  }
  this.isRegenerating=true;

  this.progressTracker.saveProgressEvent("story-lls",this.story._id,"");
  this._proposalService.generateStoryLLS(this._proposalDetail._id,this.themeID,this.epicID,this.story._id).subscribe((response)=>{

    if(response){
      this.isRegenerating=false;
      this.story.isGeneratingLowLevelSolutions=true;
      this._changeDetectorRef.detectChanges();
    }
  });
 }


  
    onChangeTheme(event){
      let selectedThemes=event.value;
      if(selectedThemes.length){
      this.epicTypes=[];
      this.newStoryForm.get('epic').setValue('');
      for(let epic of this._proposalDetail.epics){
        if(selectedThemes.includes(epic.theme)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic.name };
          });
          this.epicTypes.push(...epics);
        }
      }
    }
  }



  // autoGrow(textarea: HTMLTextAreaElement) {
  //   textarea.style.height = 'auto'; // Reset height
  //   textarea.style.height = textarea.scrollHeight + 'px'; // Set new height
  // }
  autoGrow(event?: Event): void {
    const textarea = event ? (event.target as HTMLTextAreaElement) : document.querySelector('textarea') as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scroll height
      this.height = textarea.style.height;
    }
  }

  configForm:UntypedFormGroup;
  selectedBuild:any = {};

  isDrawerConfigOpen = false;

  onFieldChange(changes: any): void {
    this.configForm.markAsDirty();
    this._changeDetectorRef.detectChanges();
  }

  selectBuild(eachtbuild) {
   
    this.viewDrawer.open();
    // this._changeDetectorRef.detach(); // 🛑 Stop change detection
    console.log(eachtbuild);
    this.onChangeFormTheme({ value: [eachtbuild.themeID] });
    this.onChangeFormCategory({ value: eachtbuild.solutionCategory });
    setTimeout(() => {
      this.configForm.patchValue({
        _id: eachtbuild._id,
        name: eachtbuild.name,
        description: eachtbuild.description,
        cloud: eachtbuild.cloud,
        status: eachtbuild.status,
        label: eachtbuild.label,
        metadataType: eachtbuild.metadataType,
        proposalID: this._proposalDetail._id,
        buildID: this.buildID,
        themeID: eachtbuild.themeID,
        epicID: eachtbuild.epicID,
        solutionCategory: eachtbuild.solutionCategory,
        solutionSubCategory: eachtbuild.solutionSubCategory,
        workType: eachtbuild.workType,
        actionType: eachtbuild.actionType,
        developerNote: eachtbuild.developerNote,
        assumption: eachtbuild.assumption,
        author: eachtbuild.author,
        created: eachtbuild.created,
      });
      setTimeout(() => {
        this.configForm.get('metadataName').setValue(eachtbuild.metadataName, { emitEvent: false });
      }, 5);

      this.userStoryArray.clear();
      if (eachtbuild.stories?.length) {
        this.batchProcessStories(eachtbuild.stories);
      }
      console.log(this.configForm.value);
      this.selectedBuild = eachtbuild;
      // this._changeDetectorRef.reattach(); // ✅ Re-enable change detection
      this._changeDetectorRef.detectChanges(); // ✅ Force UI update once
    }, 10);
  }

  batchProcessStories(stories) {
    const batchSize = 10; // Update 10 at a time
    let index = 0;
    const total = stories.length;
  
    const processBatch = () => {
      for (let i = 0; i < batchSize && index < total; i++, index++) {
        this.userStoryArray.push(this._formBuilder.group(stories[index]));
      }
      if (index < total) setTimeout(processBatch, 10); // Prevent blocking UI
    };
  
    processBatch();
  }

  selectStory(){
    const dialogRefstory = this.dialog.open(SelectStoriesComponent,{ 
      panelClass: 'add-story-dialog',
      disableClose: true,
      data: {
        proposal:this._proposalDetail,
        _buildDetail:{_id:this.buildID},
        themeTypes:this.themeTypes,
        userStoryArray: this.userStoryArray.value
      }
    });
    dialogRefstory.afterClosed().subscribe(result => {
      // console.log(result);
      if(result && result.length){
        this.userStoryArray.clear();
        result.forEach((eachStory) => {
          this.userStoryArray.push(this._formBuilder.group(eachStory));
        });
        this.configForm.markAsDirty();
        this._changeDetectorRef.detectChanges();
      }
    });
  }

  onChangeFormTheme(event){
    let selectedThemes=event.value;
    this.epicTypes=[];
    if(selectedThemes.length){
      for(let epic of this._proposalDetail.epics){
        if(selectedThemes.includes(epic._id)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic._id };
          });
          this.epicTypes.push(...epics);
        }
      }
    }
  }

  onChangeFormMetadata(event) {
    const selectedMetadata = this.metadataMap.get(event.value);
    if (selectedMetadata) {
    }
  }

  onChangeFormCategory(event){
    this.allMetadataSubCategoryForm=[];
    let selectedCategories=event.value;
    if(selectedCategories){
      let selectVal = this.allMetadataCategory.find(el=>el.label==selectedCategories);
      if(selectVal)this.allMetadataSubCategoryForm = selectVal.subcategories;
    }
  }

  onSave(){
    let saveBuild;
    if(this.selectedBuild && this.selectedBuild._id && this.selectedBuild._id!=""){
      //Edit Mode
      saveBuild={
        _id:this.selectedBuild._id,
        proposalID:this.configForm.get("proposalID").value,
        buildID:this.configForm.get("buildID").value,
        label:this.configForm.get("label").value,
        cloud:this.configForm.get("cloud").value,
        metadataType:this.configForm.get("metadataType").value,
        metadataName:this.configForm.get("metadataName").value,
        object:this.configForm.get("object").value,
        themeID:this.configForm.get("themeID").value,
        epicID:this.configForm.get("epicID").value,
        solutionCategory:this.configForm.get("solutionCategory").value,
        solutionSubCategory:this.configForm.get("solutionSubCategory").value,
        workType:this.configForm.get("workType").value,
        status:this.configForm.get("status").value,
        actionType:this.configForm.get("actionType").value,
        description:this.configForm.get("description").value,
        assumption:this.configForm.get("assumption").value,
        developerNote:this.configForm.get("developerNote").value,
        stories:this.userStoryArray.value || []
      };
    }
    this.isSavingConfig=true;
    this._proposalService.createConfiguration(saveBuild).subscribe((response)=>{
      if(response.ok || response.success || response.update){
        this.isSavingConfig=false;
        const index = this.storyConfigurations.findIndex(el => el._id === this.selectedBuild._id);
        if (index > -1) {
          if (this.themeID && this.epicID && (this.themeID !== saveBuild.themeID || this.epicID !== saveBuild.epicID)) {
            console.log(`Removing build ${this.selectedBuild._id} due to theme/epic change.`);
            this.storyConfigurations.splice(index, 1);
            this.saveBuildEvent.emit(saveBuild);
          } else {
            this.storyConfigurations[index] = { ...this.storyConfigurations[index], ...saveBuild };
            this.onSaveConfiguration.emit({ValueChanged:true,edit:true,config:saveBuild});
          }
        }
        this.showFlashMessage('success');
      }else{
        this.showFlashMessage('error');
      }
    });
  }


  showFlashMessage(type: 'success' | 'error'): void
  {
      // Show the message
      this.flashMessageType = type;
      this._changeDetectorRef.detectChanges();
      // Hide it after 3 seconds
      setTimeout(() =>
      {          
          this.flashMessageType = null;
      }, 3000);
  }


  onDelete(config){
    this.viewDrawer.close();
    if(config.delete){
      return;
    }
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {
        message:"Are you sure you want to delete this configuration?",
        title:"Confirm Delete",
        buttonTitle:"Delete"
      }
    });
    dialogRef.beforeClosed().subscribe(result => {
      if(result && result.yes===true){
        config.delete=true;
        this._proposalService.deleteConfiguration({config:config._id}).subscribe((response)=>{
          if(response && (response.ok || response.updated || response.success)){
            const index = this.storyConfigurations.findIndex(el => el._id === config._id);
            if (index > -1) {
              this.storyConfigurations.splice(index, 1);
              this._changeDetectorRef.detectChanges();
            }
          }
        });
      }
    });
  }

  openedChanged(event){
    this.isDrawerConfigOpen = event;
    if(event===false){
      this.onCloseDrawer();
    }
  }

  onCloseDrawer(){
    this.configForm.reset();
    this.selectedBuild = {};
    this.viewDrawer.close();
  }

  format(time) {
    return moment(time).format('MMM D, YYYY');
  }
  onGenerateConfigurationWorkbook(){

    if(this.story.isGeneratingLowLevelSolutions || this.isRegenerating){
      return;
    }
    this.progressTracker.saveProgressEvent("story-workbook",this.story._id,"");
    this.isRegeneratingWorkbook=true;
    this._proposalService.generateStoryConfigurationWorkbook(this._proposalDetail._id,this.buildID,this.themeID,this.epicID,this._proposalDetail.proposal_meta.technology,this._proposalDetail.proposal_meta.product,this.story._id).subscribe((response)=>{
      this.isRegeneratingWorkbook=false;
      if(response){
        this.story["isGeneratingConfigurationWorkbook"]=true;
      }
      this._changeDetectorRef.detectChanges();
    });
  }
  getMetadataTypeDisplayName(metadataType){
    
   let type=this.allMetadataTypeList.find((displayNameType)=>displayNameType.value==metadataType);

   if(type){
    return type.label;
   }
   return metadataType;
  }
  getWorkbookTooltip(): string {
    if (this.isRegenerating || this.story.isGeneratingLowLevelSolutions) {
        return 'Please wait while the low level solution is being generated';
    }else if(this.newStoryForm.get("lowLevelSolutionSummary").value == '' || this.newStoryForm.get("lowLevelSolutionSummary").value == null){
      return 'Please generate a low level solution before generating the configuration workbook';
    }
    return 'Click to regenerate the configuration workbook for this user story';
  }
}



