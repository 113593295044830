import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, JsonPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Component, OnDestroy, OnInit, ViewEncapsulation,ViewChild, Input, AfterViewInit, TemplateRef, AfterViewChecked, ElementRef, ViewChildren, QueryList, HostListener, Output, EventEmitter, Renderer2  } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators,UntypedFormGroup,UntypedFormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { marked } from 'marked';
import { filter, interval, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-review-code',
  standalone: true,
  imports: [ MatIconModule, MatButtonModule, MatSelectModule, MatPaginator, MatPaginatorModule, FormsModule, MatRippleModule, ReactiveFormsModule, MatMenuModule, MatTabsModule, MatFormFieldModule, NgTemplateOutlet, MatInputModule, MatButtonToggleModule, NgFor, NgIf, MatTableModule, NgClass, MatProgressBarModule, CurrencyPipe, JsonPipe, CommonModule],
  templateUrl: './review-code.component.html',
  styleUrl: './review-code.component.scss'
})
export class ReviewCodeComponent {
  @ViewChild('scrollableDiv') private scrollableDiv: ElementRef;
  chatClick:boolean = false;

  chat: any= {
    messages: [
      { 
        isMine: true,
        type: 'question',
        isStreaming: true,
        value: "Can you analyze the dependencies of the OpportunityRevenueCalculation.apex class?"
      },
      { 
        isMine: false,
        type: 'answer',
        isStreaming: true,
        value: "Certainly! The OpportunityRevenueCalculation.apex class has dependencies across several Salesforce components. Here’s a breakdown: <ul><li>Related Objects: It directly references the Opportunity and OpportunityLineItem objects to calculate revenue based on product line items.</li><li>Triggers: The class is called by a trigger on OpportunityLineItem updates to recalculate revenue whenever line items are modified.</li><li>Referenced Apex Classes: It invokes methods from CurrencyConversionUtility and DiscountRateManager classes for accurate revenue calculations in multi-currency setups.</li><li>Workflow Rules and Processes: Several workflow rules are triggered upon the update of revenue fields, which in turn fire notifications to the sales team when thresholds are met.</li><li>This class plays a crucial role in revenue reporting and is tightly integrated with various automation processes and utility classes.</li></ul>"
      },
      { 
        isMine: true,
        type: 'question',
        isStreaming: true,
        value: "Can you list the flows and process builders that interact with the Lead object and describe their functions?"
      },
      { 
        isMine: false,
        type: 'answer',
        isStreaming: true,
        value: "Sure! Here is a summary of the active flows and process builders interacting with the Lead object: <ul><li>Lead Qualification Flow: This flow is triggered when a lead’s status changes to 'Qualified.' It automatically creates a new Opportunity and assigns it to a sales rep based on predefined rules. Additionally, it updates related fields on the lead record to mark it as converted.</li><li>Lead Nurturing Process (Process Builder): This process builder is designed to handle leads that are not yet sales-ready. It sets reminders and follow-up tasks at scheduled intervals and sends automated emails to nurture leads based on their last interaction.</li><li>Lead Scoring Flow: This flow calculates a lead score based on engagement data, such as email opens, website visits, and interactions with marketing campaigns. It updates a custom Lead Score field, allowing sales reps to prioritize high-scoring leads.</li><li>Duplicate Check Process (Process Builder): This process builder checks for duplicate records when a new lead is created. If a match is found based on the email or phone number, it notifies the sales admin team to review and resolve duplicates.</li></ul></br>These automations collectively streamline lead handling, improve lead qualification, and maintain data quality across the sales process."
      },
    ]
  };


 


  tabActive=0; 

  tabActivefunc(tab){
    this.tabActive = tab;
  }


  chatSubmit(){
    this.chatClick = true;
    
  }

  scrollToBottom(): void {
    try {
      if(this.scrollableDiv && this.scrollableDiv.nativeElement)
      this.scrollableDiv.nativeElement.scrollTo({
        top: this.scrollableDiv.nativeElement.scrollHeight,
        behavior: 'smooth'
      });
    } catch (err) {
    }
  }



  async streamMessage(message): Promise<void> {
    let lastMessage=this.chat.messages[this.chat.messages.length-1];
    let typingSpeed = 10;
    if(message.data && lastMessage && lastMessage.taskID==message.taskID){
      if (!lastMessage || !lastMessage.editor) {
        console.error('Editor is not initialized for lastMessage');
        return;
      }
      delete lastMessage.taskID;
      const messageHTML = message.data; // Use the full HTML content to stream
      let text = await marked.parse(messageHTML);
        // Create an observable for streaming
        const typingStream = interval(10).pipe(take(text.length));
        let currentContent = '';
        // lastMessage['value'] = text;
        // lastMessage.editor.commands.setContent(text, false); 
        typingStream.subscribe((index) => {
          currentContent += text[index]; // Append one character at a time
          lastMessage['value'] += text[index];
          // Update TipTap editor with the current streamed content
          // message.editor.commands.setContent(currentContent, false); // false avoids reinitialization
          lastMessage.editor.commands.setContent(currentContent, false); 
          // When finished streaming
          
          if (index === text.length - 1) {   
            // this.scrollToBottom();       
            lastMessage['isStreaming'] = false;
          }
          setTimeout(()=>{
              this.scrollToBottom();
          },1000);
          // this._changeDetectorRef.detectChanges();
        });
    }
  }



}
