<section>
  <div class="featureBox">
    <div class="innerFeature">
    <!-- <div class="bg-card m-bg-card storyTableBox rounded-2xl shadow ml-16 mr-10 my-10"> -->
      <!-- <div class="flex justify-between items-center w-full px-5 py-5">
        <div class="text-lg font-semibold text-black">User Stories</div>       
      </div> -->
       <section>  
        <div class="grid table-grid  min-w-full px-5 gap-3 py-5">
          <div class="flex justify-start  text-md font-medium uppercase ml-8  ">Story ID</div>
          <div class="flex justify-start  text-md font-medium uppercase ">Title</div>
          <div class="flex justify-start  text-md font-medium uppercase ">Status</div>
          <div class="flex justify-start  text-md font-medium uppercase ">Priority</div>
          <div class="flex pl-4 justify-center text-md font-medium uppercase ">Jira Connection</div>
        </div>
       </section>
          @if(!isLoading && storyViewList.length>0){
          <section>
            <div class="w-full" cdkDropList (cdkDropListDropped)="dropped($event)" [cdkDropListData]="storyViewList">
              
                  @for(story of storyViewList;track story){
                    <div  class="relative group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0 mt-3 customB border-b" cdkDrag >
                      <!-- Content for each section -->
                      <div class="grid table-grid gap-3 items-center py-3 px-5 tabBorder cursor-pointer">
                        <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing' || _page_id === 'build') && is_write_allowed)" class="absolute left-0 pl-3 h-full flex items-center justify-center opacity-0 group-hover:opacity-100">
                          <mat-icon class="cursor-move" [svgIcon]="'heroicons_solid:bars-3'" cdkDragHandle></mat-icon>
                        </div>
                        <div class="flex flex-wrap justify-start  text-md text-black font-regular ml-8 "  (click)="is_write_allowed && onEditStory(story)">
                          {{getStoryID(story)}}
                        </div>
                        <div class=" text-md text-black font-regular line-clamp-2"  (click)="is_write_allowed && onEditStory(story)">
                          {{story.title}}
                        </div>
                        <div class="flex flex-wrap justify-start  text-md text-black font-regular"  (click)="is_write_allowed && onEditStory(story)">
                          <span   [ngClass]="{ 'todoTag': (getStatusString(story.status) == 'To Do') , 'inprogTag': (getStatusString(story.status) == 'In Progress')  ,'doneTag': (getStatusString(story.status) == 'Done') }"  *ngIf="story.status">{{getStatusString(story.status)}}</span>
                        </div>
                        <div class="flex flex-wrap justify-start  text-md text-black font-regular" (click)="is_write_allowed && onEditStory(story)" >
                          <span class="med-story">
                            <img [src]="getPriority(story.priority).icon"> {{getPriority(story.priority).label}}
                          </span>
                        </div>
                       
                        <div class="flex flex-wrap  text-md text-black font-regular"  [ngClass]="is_write_allowed ? 'justify-end' : 'justify-center'">
      
                          <div class="flex flex-wrap justify-start gap-2 pl-4 items-center text-md text-black font-regular cursor-pointer">
                            
      
      
                            @if(getIntegrationStatus()){
                              <span
                              class="jiraTag cursor-pointer"
                              (click)="(is_write_allowed?syncSingleStory(story):'')"
                              *ngIf="!story.jiraSynced && !story.isSyncing"
                            >
                              <span class="flex gap-1 items-center jiraBlue" >
                                <img  src="assets/icons/jirablue.png" alt=""> Sync
                              </span>
                            </span>
      
                            <span class="flex gap-1 items-center text-gray-400"  *ngIf="!story.jiraSynced && story.isSyncing">
                              <img
                              [ngClass]="'rotate-animation'"
                              src="assets/icons/sync_icon.svg"
                              class="w-6 h-6"
                              />
                              <img  src="assets/icons/jiragrey.png" class="w-5 h-5" alt=""> Syncing
                            </span>
                          
      
                          <span
                          *ngIf="story.jiraSynced"
                            class="jiraTag cursor-pointer"
                            (click)="(is_write_allowed?viewStoryInJira(story):'')"
                          >
                            <span class="flex gap-1 items-center jiraBlue"  >
                              <img  src="assets/icons/jirablue.png" alt=""> View  <mat-icon *ngIf="is_write_allowed" class="icon-size-5" svgIcon="heroicons_outline:arrow-top-right-on-square"></mat-icon>
                            </span>
                          </span>
      
      
      
      
                          }@else {
                            <span class="flex gap-1 items-center">
                              <img  src="assets/icons/jiragrey.png" class="w-5 h-5" alt=""> Not Synced
                            </span>
                          }
      
      
      
      
                            
      
      
                          <div *ngIf="is_write_allowed" class="releaseTagoption">
                              <mat-select [value]="(story.release && story.release['_id']?story.release['_id']:(story.release?story.release:''))" class="selectOption" (selectionChange)="onStoryReleaseSelection($event,story)">
                                @for (release of releases_list; track release) {
                                  <mat-option [value]="release._id">{{release.short_name}}</mat-option>
                                }
                              </mat-select>
                          </div>
                          <div *ngIf="(!is_write_allowed && story.release && story.release['_id'])" class="releaseTagoption selectOption text-center" >
                              {{getReleaseValue(story.release['_id'])}}
                          </div> 
      
                            <div *ngIf="is_write_allowed" class="flex justify-end group gap-2">
                              <div  class="flex gap-1 group-hover:opacity-100 items-center mr-2 opacity-0">
                                <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="onEditStory(story)" ></mat-icon>
                                <img *ngIf="story.delete"   [ngClass]="'rotate-animation'" src="assets/icons/sync_icon.svg" class="w-6 h-6">
                                <mat-icon class="smallIc cursor-pointer"  *ngIf="!story.delete"  svgIcon="heroicons_outline:trash" (click)="onDeleteStory(story)"></mat-icon>
                              </div>
                              
                            </div>
                          </div>
      
                         
                        </div>
                       
                      </div>
                    </div> 
                  }
                     
                                               
            </div>
          
          </section>
        }@else {
          <div *ngIf="isLoading" class="w-full flex items-center justify-center min-h-48">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </div>
          <section *ngIf="!isLoading && !storyViewList.length">
            <div class="flex flex-col gap-4 items-center justify-center notfound p-16 w-full">
                <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="text-[#000000]"></mat-icon>
                <h4 class="text-lg font-medium">No Story Found </h4>
            </div>
          </section>
          
        } 
          
          <!-- <section>
              <p class="noSolution">No User Story Found</p>
          </section> -->

          
            <!-- Add Feature Button -->
            <div *ngIf="(is_write_allowed)"  class="min-w-full flex pl-10 py-5 items-center justify-start relative border-t cursor-pointer" (click)="openDialog()">
              <mat-icon class="text-[#9AA6B8]" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
              <div class="pl-2 text-md text-[#9AA6B8]">Add User Story</div>
             
            </div>

    <!-- </div> -->

    
  </div>
</div>
</section>


<fuse-drawer  
class="w-screen min-w-screen sm:w-100  releaseD z-999 editstoryDrawer"
fixed
[mode]="'over'"
[name]="'editDrawer'"
[position]="'right'"
(openedChanged)="openedChangedEdit($event)"
#editDrawer>

  <div class="flex flex-col w-full overflow-auto bg-card">
    <div class="flex justify-between mb-4 pl-5 pr-5 pt-4 " >
        <div class="heading">
            <h5 class="font-medium text-lg break-all flex items-center gap-2 text-black"> 
              <mat-icon svgIcon="heroicons_outline:pencil-square" class="icon-size-5 text-black"></mat-icon> Edit User Story</h5>
        </div>
        <button mat-icon-button (click)="onCloseDrawer()" >
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <app-edit-user-story [(buildID)]="buildID" *ngIf="isDrawerOpen && selectedStory" [themeID]="themeID" [epicID]="epicID" [(storyValue)]="selectedStory" [(proposal)]="proposal" [(themeTypes)]="themeTypes" [(epicTypes)]="epicTypes" [(release)]="release" (storyValueChange)="onStoryUpdated($event)" [(metadataMap)]="metadataMap" [(allMetadataType)]="allMetadataType" [(technologyDomains)]="technologyDomains" [(allMetadataCategory)]="allMetadataCategory"></app-edit-user-story>
  </div>
</fuse-drawer>
