<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'rewriteAIDrawer'"
    [position]="'right'"
    (openedChanged)="openedChanged($event)"

    #themeDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card">
 


            <div class="flex items-center justify-between mt-5 ml-3 mr-3">

            <ng-container>
                <div class="flex items-center justify-center">
                    <ng-container>
                        <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                    </ng-container>
                    

                    <span class="ml-2 font-semibold text-lg text-black" >{{ isEdit?'Edit':'Add' }} Requirement</span>
                </div>
            </ng-container>

            <div class="flex items-center">
                <!-- More menu -->
                <button
                    *ngIf="isEdit"
                    mat-icon-button
                    [matMenuTriggerFor]="moreMenu">
                    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
                </button>
                <mat-menu #moreMenu="matMenu">
                    <button
                        mat-menu-item
                        (click)="onDeleteFeatureEvent()"
                       >
                        <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                        <span>Delete Feature</span>
                    </button>
                </mat-menu>

                <!-- Close button -->
                <button
                    mat-icon-button
                    (click)="themeDrawer.toggle()"
                    >
                    <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>

            

        </div>
       
        <form
        class="flex flex-col flex-auto p-6 pt-10 sm:p-8 sm:pt-10"
        [formGroup]="themeForm">
       
         <div>
            <mat-form-field
                class="w-full"
                >
                <mat-label class="font-semibold subtitle text-black">Insert Feature</mat-label>
                <textarea
                    matInput

                    [formControlName]="'feature'"
                    ></textarea>
                    <mat-error *ngIf="themeForm?.hasError('required') && themeForm?.touched">
                      Please enter feature details
                    </mat-error>
                
            </mat-form-field>
        </div>

        <!-- Theme -->
        <div class="mt-5 w-full">
        <mat-form-field class="w-full">
            <mat-label class="font-semibold">Theme</mat-label>
            <mat-select formControlName="theme" (selectionChange)="onChangeThemeSelection($event)" [disabled]="isEdit">
              @for (theme of themes; track theme) {
                <mat-option  [value]="theme.value">{{theme.label}}</mat-option>
              }
            </mat-select>
            <mat-error *ngIf="!themes.length">
              Please add themes
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mt-5 w-full">
          <mat-form-field class="w-full">
            <mat-label class="font-semibold">Epic</mat-label>
            <mat-select formControlName="epic" (selectionChange)="onChangeThemeSelection($event)" [disabled]="isEdit">
              @for (epic of filteredEpics; track epic) {
                <mat-option  [value]="epic.value">{{epic.label}}</mat-option>
              }
            </mat-select>
            <mat-error *ngIf="!filteredEpics.length">
              Please add epics against themes
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mt-5 w-full">
          <mat-form-field class="w-full">
            <mat-label class="font-semibold">Release</mat-label>
            <mat-select formControlName="release">
              @for (release of releases_list; track release) {
                <mat-option [value]="release._id">{{release.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>


        <div class="flex w-full justify-end mr-5 mt-5">
            <button
              mat-flat-button
              [disabled]="isSavingFeature || themeForm.invalid"
              (click)="onSaveFeatureEvent()"
              class="bg-[#4F45E4] text-white text-lg font-semibold mt-5 py-3 px-10 rounded-lg"
              [class.disabled]="isSavingFeature || themeForm.invalid">
              Save
            </button>
          </div>
          <div class="my-1 mb-2" *ngIf="featureSaved">
            <fuse-alert
            [appearance]="'soft'"
            [showIcon]="false"
            type="success"
            [dismissible]="true" [dismissed]="false">
            Feature saved successfully!
        </fuse-alert>
        </div>
        

</form>
    </div>

</fuse-drawer>
