import { ChangeDetectorRef, ChangeDetectionStrategy, Component, ViewChild, OnInit, ViewEncapsulation, Inject, ElementRef, Renderer2, HostListener } from '@angular/core';
import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOption, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AddFeatureComponent } from 'app/modules/user/common/add-feature/add-feature.component';
import { NpsService } from 'app/core/nps/nps.service';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { CommonService } from 'app/core/common/common.service';
import { finalize, Subject, takeUntil, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, filter } from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import { NpsComponent } from '../../nps/nps.component';
import { QuickPromptComponent } from 'app/modules/common/quick-prompt/quick-prompt.component';
import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';

import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { Heading } from '@tiptap/extension-heading';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { DOMSerializer, DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';

export interface Feature
{
  _id: string;
  featureName: string;
  technology: string;
  setupType: string
  complexity: string;
  cloud: string;
  effort: string;
  featureDetails: string;
  effortSummary: string;
  solutionDescription: string;
  noOfComponents: string;
  epicName: string;
  epic: string;
  theme: string;
  release: string;
  insert_loader: boolean;
}


@Component({
  selector: 'app-feature',
  standalone: true,
  imports      : [NgxTiptapModule, EditorControlsComponent, MatIconModule, FuseDrawerComponent, FuseAlertComponent,FormsModule,CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule, ReactiveFormsModule, MatStepperModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule,QuickPromptComponent],
  templateUrl: './feature.component.html',
  styleUrl: './feature.component.scss'
})
export class FeatureComponent {
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild(FuseDrawerComponent) rewriteAIDrawer: FuseDrawerComponent;
  @ViewChild('allSelected') private allSelected: MatOption;
  
  private searchTerm$ = new Subject<string>();
  private unsubscribe$ = new Subject<void>();
  flashMessage: 'success' | 'error' | null = null;
  isLoading: boolean = false;
  showModal;
  is_feature_inserted = false;
  insert_loader = false;
  generate_loader = false;
  generate_replace = false;
  generate_append = false;
  quickAction: boolean = false;
  showcustomprompt = false;
  generate_grammar = false;
  is_genai_interacted = false;
  fetch_loader = false;
  fetch_list = true;
  bulkInsertFeature = false;
  bulkMessage = false;
  bulkMessageContent = '';
  reommendation_text = '';
  selected_theme = '';
  selected_epic = '';
  multitabGroupForm: UntypedFormGroup;
  tabGroupForm: UntypedFormGroup;
  drawerGroupForm: UntypedFormGroup;
  selectedFeature: Feature | null = null;
  getAIDrawer:boolean =  false;
  // selectedInsertFeature: any = {};
  selectedInsertFeature: any [] = [];
  featuresArray: FormArray<any>;
  allfeaturesArray: FormArray<any>;
  allaifeaturesArray: FormArray<any>;
  featureDetails$: Observable<any>;
  cloudTypes: any []= [];
  epicTypes: any[] = [];
  allEpicTypes: any[] = [];
  complexities: any[] = [];
  pro_data: Feature[] = [];
  quickActionIds: any[]=[];
  themeEpicTypes: any[] = [];
  themeListTypes: any[] = [];
  releases_list: any[] = [];
  original_features: Feature[] = [];
  original_ai_features: Feature[] = [];
  previousSelection: any[];
  quickSelectionStartIndex=-1;
  quickSelectionEndIndex=-1;

  mouseInitialPosition=null;
  isQuickMenuHighlighted:boolean=false;
  showQuickPromptMenu = false;
  menuPosition = { top: '0px', left: '0px' };
  quickSelectedText="";
  insideTextArea=false;
  selectedFeatureData={
    type:"",
    index:0
  };
  selectedText: string = '';

  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a description',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });

  editor1 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Write a description',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });

  editor2 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Please enter your specific needs in the field below to tailor the solution list accordingly. After you have input your requirements, click Fetch Solutions to retrieve the solutions that best match your needs.',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
  });


  constructor( 
    private _formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private _npsService: NpsService,
    private _proposalService: ProposalService,
    private _websocketService: WebsocketService,
    private _confirmationService:FuseConfirmationService,
    private _commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: {proposal_detail: object}
  ){
    this.complexities = this._commonService.complexities;
    this.getCloud();
    this._npsService.getStatus().subscribe();
    this._npsService.nps$.subscribe((response)=>{
      if(response)this.showModal=response['showModal'];
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "connection")
    ).subscribe((message) => {
        console.log("Received:", message);
    });
  
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "fix_grammar")
    ).subscribe((message) => {
        // console.log("Received:", message);
        if(!message.isCompleted && message.taskID == this.drawerGroupForm.get('taskID').value){
          let stripContent = this.drawerGroupForm.get('modified').value + message.data;
          this.drawerGroupForm.get('modified').setValue(stripContent);
        }
        else if(message.isCompleted){
          this.generate_grammar = false;
          this.generate_loader = false;
        }
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "personalize_feature")
    ).subscribe((message) => {
        // console.log("Received:", message);
        if((message.taskID == this.drawerGroupForm.get('taskID').value) || this.selectedInsertFeature.find(el=>el.taskID==message.taskID)){
          let insert_feature = JSON.parse(JSON.stringify(message.data));
          let selectedFeature = this.selectedInsertFeature.find(el=>el.taskID==message.taskID);
          // console.log("selectedFeature:",selectedFeature);
          insert_feature['componentsCount']=selectedFeature.noOfComponents;
          insert_feature['epic']=selectedFeature.epic;
          insert_feature['theme']=selectedFeature.theme;
          insert_feature['release']=selectedFeature.release;
          insert_feature['epicName']=selectedFeature.epicName;
          insert_feature['name']=selectedFeature.name;
          insert_feature['effort']=selectedFeature.effort;
          insert_feature['complexity']=selectedFeature.complexity;
          // console.log("insert_feature:",insert_feature);
          if(this.bulkInsertFeature){
            insert_feature['_id'] = selectedFeature.feature_id;
            this._proposalService.update({id:this.data['_id'],feature_action:"update",feature_data:insert_feature})
            .subscribe(
              (value) =>
              {
              },
              (response) =>
              {
              },
            );
          }
          else {
           this._proposalService.update({id:this.data['_id'],feature_action:"add",feature_data:insert_feature})
            .subscribe(
              (value) =>
              {
                this.showFlashMessage('success');
                if(selectedFeature.insert_loader)selectedFeature.selectedFeature.get('insert_loader').setValue(false);
                // this.dialog.closeAll();
                setTimeout(() => {
                  this.toggleDetails(message.data['masterFeatureID']);
                }, 2000);
              },
              (response) =>
              {
              },
            );
          }
        }
    });

  }
  
  
  ngOnInit(): void
  {
    this.releases_list = [];
    this.multitabGroupForm = this._formBuilder.group({
      product : [''],
      epic_name : ['']
    });
    this.tabGroupForm = this._formBuilder.group({
      summary: ['', Validators.required],
      scope: ['', Validators.required],
      features: new FormArray([
        new FormGroup({
          _id: new FormControl(''),
          featureName: new FormControl('', Validators.required),
          cloud: new FormControl('', Validators.required),
          technology: new FormControl('', Validators.required),
          setupType: new FormControl(''),
          complexity: new FormControl('', Validators.required),
          effort: new FormControl('', Validators.required),
          featureDetails: new FormControl(''),
          effortSummary: new FormControl(''),
          solutionDescription: new FormControl(''),
          noOfComponents: new FormControl(''),
          insert_loader: new FormControl(''),
          epicName: new FormControl(''),
          epic: new FormControl('', Validators.required),
          theme: new FormControl('', Validators.required),
          release: new FormControl('', Validators.required),
          epicList: new FormArray([
            new FormGroup({
              label: new FormControl(''),
              value: new FormControl(''),
            }),
          ]),
          releases_list: new FormArray([
            new FormGroup({
              name: new FormControl(''),
              _id: new FormControl(''),
            }),
          ]),
        }),
      ])
    });
    this.drawerGroupForm = this._formBuilder.group({
      index: [0],
      type: [''],
      taskID: [''],
      original: ['', Validators.required],
      custom: [''],
      modified: ['']
    });
    this.featuresArray = this.tabGroupForm.get('features') as FormArray;
    // this.allfeaturesArray = this.tabGroupForm.get('features') as FormArray;
    // this.allaifeaturesArray = this.tabGroupForm.get('features') as FormArray;

    this.searchTerm$
      .pipe(
        debounceTime(500),
        switchMap((searchTerm) =>
        this._proposalService.getList({id:this.data['_id'],technology:this.data['proposal_meta'].technology,cloud:this.multitabGroupForm.get('product').value,skip:0,limit:100,search_text:searchTerm})
            .pipe(takeUntil(this.unsubscribe$))
        )
      )
      .subscribe((response) => {
        if(response && response['data'] && response['data'].length){
          this.pro_data = response['data'];
          this.original_features = JSON.parse(JSON.stringify(response['data']));
          if (this.featuresArray instanceof FormArray) {
            this.featuresArray.clear();
            this.quickActionIds = [];
            response['data'].forEach(detail => {
                // Determine the selected theme
                let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
                // Find the corresponding theme object from the theme list
                let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

                // Set the themeEpics based on the selected theme
                if (findTheme && findTheme.epics) {
                  this.themeEpicTypes = findTheme.epics;
                }

                // Prepare the additionalInfo array based on the selected theme
                let additionalInfoArray = this.themeEpicTypes.map(epic => {
                  return this._formBuilder.group({
                    label: epic.label || '', // Default to empty if label not found
                    value: epic.value || '', // Default to empty if value not found
                  });
                });
                let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
                const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';

                this.featuresArray.push(this._formBuilder.group({
                    _id               : [detail._id],
                    featureName              : [detail.featureName],
                    cloud : [detail.cloud],
                    technology              : [detail.technology],
                    setupType          : [detail.setupType],
                    featureDetails            : [detail.featureDetails],
                    complexity           : ['Medium'],
                    effort            : [detail.mediumEffort],
                    effortSummary            : [detail.effortSummary],
                    solutionDescription: [detail.solutionDescription],
                    epicName          : [detail.epicName],
                    theme: [selectedTheme],
                    releases_list: this._formBuilder.array(additionalReleaseArray),
                    release: [detail.release?detail.release:initialReleaseValue],
                    noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                    insert_loader: [false],
                    epicList: this._formBuilder.array(additionalInfoArray),
                    epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
                }));
            });
            this.featureDetails$ = this.featuresArray.valueChanges;
          }
        }
        else{
          this.featuresArray.clear();
          this.quickActionIds = [];
          this.pro_data = [];
        }
      },
      (error) => {
      });
      // console.log(this.data);
      if(this.data['epics'] && this.data['epics'].length){
        this.themeListTypes = [];
        this.data['epics'].forEach(element => {
          let current = {label:element.theme,value:element.theme,epics:[]};
          if(element.epics && element.epics.length){
            element.epics.forEach(epicelement => {
              current['epics'].push({label:epicelement.name,value:epicelement.name})
            });
          }
          this.themeListTypes.push(current);
        });
      }

      if(this.data['dialog_type']=='addfeature'){
        this.getProposal({id:this.data['_id'],technology:this.data['proposal_meta'].technology,cloud:this.data['proposal_meta'].product,skip:0,limit:1000});
      }
      else {
        this.fetch_list = false;
        this.getAIDrawer = true;
        this.multitabGroupForm.get('epic_name').setValue('');
        this.multitabGroupForm.get('product').setValue('');
        if (this.searchInput) this.searchInput.nativeElement.value = '';
        this.pro_data = [];
        if(this.featuresArray.length){
          this.featuresArray.clear();
          this.quickActionIds = [];
        }
      }
      // console.log(this.data['releases_list']);
      this.releases_list = JSON.parse(JSON.stringify(this.data['releases_list']));
      this.releases_list.forEach(release => {
        release.requirements = release.requirements || [];
      });
      this.data['epics'].forEach(epic => {
        epic.epics.forEach(subEpic => {
          subEpic.features.forEach(requirement => {
            if (requirement.release) {
              let release = this.releases_list.findIndex(release => release._id === requirement.release);
              if (release>-1) {
                requirement['theme'] = {_id: epic._id, title:epic.theme};
                requirement['epic'] = {_id: subEpic._id, title:subEpic.name};
                this.releases_list[release].requirements.push(requirement);
              }
            }
          });
        });
      });
  }

  updateSelectedText(editor: Editor): void {
    const { from, to } = editor.state.selection;
    // this.selectedText = editor.state.doc.textBetween(from, to, ' ');
    // this.quickSelectedText = editor.state.doc.textBetween(from, to, ' ');
    // Get the selected content as a ProseMirror Fragment
    const selectedFragment = editor.state.doc.slice(from, to);

    // Convert the Fragment to a DOM Node
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const div = document.createElement('div');
    selectedFragment.content.forEach(node => {
        div.appendChild(serializer.serializeNode(node));
    });

    // Get the selected HTML
    const selectedHTML = div.innerHTML;

    // Store the selection details
    this.quickSelectionStartIndex = from;
    this.quickSelectionEndIndex = to;
    this.selectedText = selectedHTML;
    this.quickSelectedText = selectedHTML;
  }

// -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
  * Get Plan
  */
  getProposal(params): void
  {
      // Return if the form is invalid
      if ( !this.data['_id'] )
      {
          return;
      }
      // Send the request to the server
      this._proposalService.getList(params)
          .pipe(
              finalize(() =>
              {
                if(this.multitabGroupForm.get('product').value && this.multitabGroupForm.get('product').value.length){
                  this.epicTypes = [];
                  this.original_features.forEach(element => {
                    if(this.multitabGroupForm.get('product').value.includes(element.cloud) && element.epicName!='' && !this.epicTypes.find(el=>el.value==element.epicName))this.epicTypes.push({label:element.epicName, value: element.epicName});
                  });
                  this.epicTypes.sort((a, b) => a.label.localeCompare(b.label));
                }
              }),
          )
          .subscribe(
              (response) =>
              {
                this.fetch_list = false;
                if(response && response['data'] && response['data'].length){
                  this.pro_data = response['data'];
                  this.original_features = JSON.parse(JSON.stringify(response['data']));
                  // console.log(this.original_features);
                  if (this.featuresArray instanceof FormArray) {
                    this.featuresArray.clear();
                    this.quickActionIds = [];
                    response['data'].forEach(detail => {
                        // Determine the selected theme
                        let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
                        // Find the corresponding theme object from the theme list
                        let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

                        // Set the themeEpics based on the selected theme
                        if (findTheme && findTheme.epics) {
                          this.themeEpicTypes = findTheme.epics;
                        }

                        // Prepare the additionalInfo array based on the selected theme
                        let additionalInfoArray = this.themeEpicTypes.map(epic => {
                          return this._formBuilder.group({
                            label: epic.label || '', // Default to empty if label not found
                            value: epic.value || '', // Default to empty if value not found
                          });
                        });

                        let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
                        const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';

                        this.featuresArray.push(this._formBuilder.group({
                            _id               : [detail._id],
                            featureName              : [detail.featureName],
                            cloud : [detail.cloud],
                            technology              : [detail.technology],
                            setupType          : [detail.setupType],
                            featureDetails            : [detail.featureDetails],
                            complexity           : ['Medium'],
                            effort            : [detail.mediumEffort],
                            effortSummary            : [detail.effortSummary],
                            solutionDescription: [detail.solutionDescription],
                            epicName          : [detail.epicName],
                            theme: [selectedTheme],
                            releases_list: this._formBuilder.array(additionalReleaseArray),
                            release: [detail.release?detail.release:initialReleaseValue],
                            noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                            insert_loader: [false],
                            epicList: this._formBuilder.array(additionalInfoArray),
                            epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
                        }));
                    });
                    this.allfeaturesArray = new FormArray(
                      this.featuresArray.controls.map(control => {
                        return this._formBuilder.group(control.value); // Create a new FormGroup with the same value
                      })
                    );
                    this.featureDetails$ = this.featuresArray.valueChanges;
                  }
                }
                else {
                    
                }
              },
              (error) =>
              {
                  
              },
          );
  }

  getCloud(): void
  {
    this.cloudTypes = [];
    this.epicTypes = [];
    this._proposalService.getCloudTypes()
        .pipe(
            finalize(() =>
            {
              if(this.data['dialog_type']=='addfeature')this.multitabGroupForm.get('product').setValue(this.data['proposal_meta'].product);
              else this.epicTypes = JSON.parse(JSON.stringify(this.allEpicTypes));

              this.cloudTypes = this.reorderArray(this.cloudTypes, this.data['proposal_meta'].product);
            }),
        )
        .subscribe(
            (response) =>
            {
              if(response.cloud){
                response.cloud.forEach(element => {
                  this.cloudTypes.push({label:element,value:element});
                });
              }
              if(response.epics){
                response.epics.forEach(element => {
                  this.allEpicTypes.push({label:element,value:element});
                });
              }
            },
            (error) =>
            {
            },
        );
  }

  reorderArray(objArray, orderArray) {
    const orderMap = new Map();
    
    // Create a map for quick lookup
    orderArray.forEach((key, index) => {
      orderMap.set(key, index);
    });
  
    // Filter and sort the objects based on orderArray
    const orderedObjects = objArray
      .filter(obj => orderMap.has(obj.value))
      .sort((a, b) => orderMap.get(a.value) - orderMap.get(b.value));
    
    // Get the remaining objects
    const remainingObjects = objArray
      .filter(obj => !orderMap.has(obj.value));
    // Concatenate the ordered objects with the remaining ones
    return [...orderedObjects, ...remainingObjects];
  }

  toggleDetails(productId: string): void
  {
      // If the product is already selected...
      if ( this.selectedFeature && this.selectedFeature['_id'] === productId )
      {
        // Close the details
        this.closeDetails();
        return;
      }else{
        this.selectedFeature = this.pro_data.find(o => o._id === productId);
        // console.log(this.selectedFeature);
        // this.selectedFeature.theme = this.data["selected_theme"]?this.data["selected_theme"]:this.themeListTypes[0].value;
        // if(this.selectedFeature.theme){
        //   let findTheme = this.themeListTypes.find(el=>el.value==this.selectedFeature.theme);
        //   if(findTheme && findTheme.epics){
        //     this.themeEpicTypes = findTheme.epics;
        //   }
        //   this.setEpicAndThemeValues(this.data["selected_epic"],this.data["selected_theme"]);
        // }
        // else {
        //   this.setEpicAndThemeValues(this.themeEpicTypes[0].value, this.selectedFeature.theme);
        // }
       



        this.cdr.detectChanges();
      }
  }

  setEpicAndThemeValues(epicValue: string, themeValue: string) {
    //console.log(epicValue,themeValue);
    const featuresArray = this.tabGroupForm.get('features') as FormArray;
    featuresArray.controls.forEach((featureGroup: FormGroup) => {
      featureGroup.patchValue({
        epic: epicValue,
        theme: themeValue
      });
    });
  }

  search(event){
    if(event.target.value){
      if(this.getAIDrawer){
        let searchText = event.target.value.toLowerCase();
        let totalRecomArray = JSON.parse(JSON.stringify(this.original_ai_features));
        const filteredData = totalRecomArray.filter((obj) => ((obj.featureName.toLowerCase().includes(searchText)) || (obj.featureDetails.toLowerCase().includes(searchText)) || (obj.cloud.toLowerCase().includes(searchText))));//console.log(filteredData);
        this.pro_data = filteredData;
        this.featuresArray.clear();
        this.quickActionIds = [];
        if(filteredData.length){
          if (this.featuresArray instanceof FormArray) {
            filteredData.forEach(detail => {
                // Determine the selected theme
                let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
                // Find the corresponding theme object from the theme list
                let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

                // Set the themeEpics based on the selected theme
                if (findTheme && findTheme.epics) {
                  this.themeEpicTypes = findTheme.epics;
                }

                // Prepare the additionalInfo array based on the selected theme
                let additionalInfoArray = this.themeEpicTypes.map(epic => {
                  return this._formBuilder.group({
                    label: epic.label || '', // Default to empty if label not found
                    value: epic.value || '', // Default to empty if value not found
                  });
                });
                let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
                const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';
                // if(!this.cloudTypes.find(el=>el.value==detail.cloud))this.cloudTypes.push({label:detail.cloud,value:detail.cloud});
                this.featuresArray.push(this._formBuilder.group({
                    _id               : [detail._id],
                    featureName              : [detail.featureName],
                    cloud              : [detail.cloud],
                    technology              : [detail.technology],
                    setupType          : [detail.setupType],
                    featureDetails            : [detail.featureDetails],
                    complexity           : ['Medium'],
                    effort            : [detail.mediumEffort],
                    effortSummary            : [detail.effortSummary],
                    solutionDescription:[detail.solutionDescription],
                    epicName          : [detail.epicName],
                    theme: [selectedTheme],
                    releases_list: this._formBuilder.array(additionalReleaseArray),
                    release: [detail.release?detail.release:initialReleaseValue],
                    noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                    insert_loader: [false],
                    epicList: this._formBuilder.array(additionalInfoArray),
                    epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
                }));
            });
            this.featureDetails$ = this.featuresArray.valueChanges;
          }
        }
      }
      else{
        this.searchTerm$.next(event.target.value);
        // Unsubscribe from previous API call
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$ = new Subject<void>();
      }
    }
    else{
      if(this.getAIDrawer)this.pro_data = JSON.parse(JSON.stringify(this.original_ai_features));
      else {
        this.getProposal({id:this.data['_id'],technology:this.data['proposal_meta'].technology,cloud:this.multitabGroupForm.get('product').value,skip:0,limit:1000});
      }
    }
  }

  toggleDrawer(type,index){
    this.drawerGroupForm.get('type').setValue(type);
    this.drawerGroupForm.get('index').setValue(index);
    if(type=='featureDetails')this.drawerGroupForm.get('original').setValue(this.featuresArray.at(index).get('featureDetails').value);
    else if(type=='solutionDescription')this.drawerGroupForm.get('original').setValue(this.featuresArray.at(index).get('solutionDescription').value);
  }

  newContent(type){
    let obj = {id: this.data['_id']};
    let text = "";
    if(type=='replace'){
      this.generate_replace = true;
      text = this.drawerGroupForm.get('modified').value;
      this.drawerGroupForm.get('original').setValue(text);
    }
    else {
      this.generate_append = true;
      text = this.drawerGroupForm.get('original').value + " " + this.drawerGroupForm.get('modified').value;
      this.drawerGroupForm.get('original').setValue(text);
    }
    if(text){
      if(this.drawerGroupForm.get('type').value=='featureDetails')this.featuresArray.at(this.drawerGroupForm.get('index').value).get('featureDetails').setValue(text);
      else if(this.drawerGroupForm.get('type').value=='solutionDescription')this.featuresArray.at(this.drawerGroupForm.get('index').value).get('solutionDescription').setValue(text);
      this.showcustomprompt=false;
      this.generate_replace = false;
      this.generate_append = false;
      this.drawerGroupForm.get('custom').setValue('');
      // this.drawerGroupForm.get('modified').setValue('');
      // this.drawerGroupForm.get('original').setValue('');
      // this.drawerGroupForm.get('type').setValue('');
      // this.drawerGroupForm.get('custom').setValue('');
      // this.rewriteAIDrawer.close();
      // if(this.drawerGroupForm.get('type').value=='featureDetails')obj['summary'] = text;
      // else if(this.drawerGroupForm.get('type').value=='effortSummary')obj['scope'] = text;
      // this._proposalService.update(obj)
      //   .subscribe(
      //     (value) =>
      //     {
      //       if(value.status){
      //         this.showcustomprompt=false;
      //         this.rewriteAIDrawer.close();
      //       }
      //       else this.showFlashMessage('error');
      //     },
      //     (response) =>
      //     {
      //     },
      // );
    }   
  }

  selectionChange(event){
    const currentSelection = event.value;
    // console.log(event,this.allSelected.selected,this.previousSelection);
    if (this.allSelected.selected && event.value.indexOf(0)>-1) {  
      this.multitabGroupForm.controls.product
        .patchValue([...this.cloudTypes.map(item => item.value), 0]);
    }
    else if (!this.allSelected.selected && this.previousSelection && this.previousSelection.length && this.previousSelection.indexOf(0)>-1){
      this.multitabGroupForm.get('product').patchValue([]);
      this.allSelected.deselect();
      // this.multitabGroupForm.get('product').setValue(event.value);
    }
    else {
      this.multitabGroupForm.get('product').setValue(event.value);
    }
    // console.log(this.multitabGroupForm.get('product').value);
    this.previousSelection = currentSelection;
    if(this.getAIDrawer){
      // console.log(this.multitabGroupForm.get('product').value);
      let totalRecomArray = JSON.parse(JSON.stringify(this.original_ai_features));//console.log(totalRecomArray);
      if(this.multitabGroupForm.get('product').value.length){
        const filteredData = totalRecomArray.filter((obj) => this.multitabGroupForm.get('product').value.includes(obj.cloud));//console.log(filteredData);
        this.pro_data = filteredData;
        this.featuresArray.clear();
        this.quickActionIds = [];
        if(filteredData.length){
          this.epicTypes = [];
          if (this.featuresArray instanceof FormArray) {
            filteredData.forEach(detail => {
                // Determine the selected theme
                let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
                // Find the corresponding theme object from the theme list
                let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

                // Set the themeEpics based on the selected theme
                if (findTheme && findTheme.epics) {
                  this.themeEpicTypes = findTheme.epics;
                }

                // Prepare the additionalInfo array based on the selected theme
                let additionalInfoArray = this.themeEpicTypes.map(epic => {
                  return this._formBuilder.group({
                    label: epic.label || '', // Default to empty if label not found
                    value: epic.value || '', // Default to empty if value not found
                  });
                });
                let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
                const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';

                if(this.multitabGroupForm.get('product').value.includes(detail.cloud) && detail.epicName!='' && !this.epicTypes.find(el=>el.value==detail.epicName))this.epicTypes.push({label:detail.epicName, value: detail.epicName});
                this.featuresArray.push(this._formBuilder.group({
                    _id               : [detail._id],
                    featureName              : [detail.featureName],
                    cloud              : [detail.cloud],
                    technology              : [detail.technology],
                    setupType          : [detail.setupType],
                    featureDetails            : [detail.featureDetails],
                    complexity           : ['Medium'],
                    effort            : [detail.mediumEffort],
                    effortSummary            : [detail.effortSummary],
                    solutionDescription: [detail.solutionDescription],
                    epicName          : [detail.epicName],
                    theme: [selectedTheme],
                    releases_list: this._formBuilder.array(additionalReleaseArray),
                    release: [detail.release?detail.release:initialReleaseValue],
                    noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                    insert_loader: [false],
                    epicList: this._formBuilder.array(additionalInfoArray),
                    epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
                }));
            });
            this.featureDetails$ = this.featuresArray.valueChanges;
          }
        }
      }
      else{
        this.pro_data = totalRecomArray;
        this.epicTypes = JSON.parse(JSON.stringify(this.allEpicTypes));
        this.featuresArray.clear();
        this.quickActionIds = [];
        if (this.featuresArray instanceof FormArray) {
          totalRecomArray.forEach(detail => {
              // Determine the selected theme
              let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
              // Find the corresponding theme object from the theme list
              let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

              // Set the themeEpics based on the selected theme
              if (findTheme && findTheme.epics) {
                this.themeEpicTypes = findTheme.epics;
              }

              // Prepare the additionalInfo array based on the selected theme
              let additionalInfoArray = this.themeEpicTypes.map(epic => {
                return this._formBuilder.group({
                  label: epic.label || '', // Default to empty if label not found
                  value: epic.value || '', // Default to empty if value not found
                });
              });
              let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
              const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';
              // if(!this.cloudTypes.find(el=>el.value==detail.cloud))this.cloudTypes.push({label:detail.cloud,value:detail.cloud});
              this.featuresArray.push(this._formBuilder.group({
                  _id               : [detail._id],
                  featureName              : [detail.featureName],
                  cloud              : [detail.cloud],
                  technology              : [detail.technology],
                  setupType          : [detail.setupType],
                  featureDetails            : [detail.featureDetails],
                  complexity           : ['Medium'],
                  effort            : [detail.mediumEffort],
                  effortSummary            : [detail.effortSummary],
                  solutionDescription: [detail.solutionDescription],
                  epicName          : [detail.epicName],
                  theme: [selectedTheme],
                  releases_list: this._formBuilder.array(additionalReleaseArray),
                  release: [detail.release?detail.release:initialReleaseValue],
                  noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                  insert_loader: [false],
                  epicList: this._formBuilder.array(additionalInfoArray),
                  epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
              }));
          });
          this.featureDetails$ = this.featuresArray.valueChanges;
        }
      }
    }
    else{
      this.getProposal({id:this.data['_id'],technology:this.data['proposal_meta'].technology,cloud:this.multitabGroupForm.get('product').value,skip:0,limit:1000});
    }
  }

  epicChange(event){
    if(this.getAIDrawer){
      let totalRecomArray = JSON.parse(JSON.stringify(this.original_ai_features));
      if(this.multitabGroupForm.get('epic_name').value.length){
        const filteredData = totalRecomArray.filter((obj) => this.multitabGroupForm.get('epic_name').value.includes(obj.epicName));//console.log(filteredData);
        this.pro_data = filteredData;
        this.featuresArray.clear();
        this.quickActionIds = [];
        if(filteredData.length){
          if (this.featuresArray instanceof FormArray) {
            filteredData.forEach(detail => {
                // Determine the selected theme
                let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
                // Find the corresponding theme object from the theme list
                let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

                // Set the themeEpics based on the selected theme
                if (findTheme && findTheme.epics) {
                  this.themeEpicTypes = findTheme.epics;
                }

                // Prepare the additionalInfo array based on the selected theme
                let additionalInfoArray = this.themeEpicTypes.map(epic => {
                  return this._formBuilder.group({
                    label: epic.label || '', // Default to empty if label not found
                    value: epic.value || '', // Default to empty if value not found
                  });
                });
                let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
                const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';

                this.featuresArray.push(this._formBuilder.group({
                    _id               : [detail._id],
                    featureName              : [detail.featureName],
                    cloud              : [detail.cloud],
                    technology              : [detail.technology],
                    setupType          : [detail.setupType],
                    featureDetails            : [detail.featureDetails],
                    complexity           : ['Medium'],
                    effort            : [detail.mediumEffort],
                    effortSummary            : [detail.effortSummary],
                    solutionDescription: [detail.solutionDescription],
                    epicName          : [detail.epicName],
                    theme: [selectedTheme],
                    releases_list: this._formBuilder.array(additionalReleaseArray),
                    release: [detail.release?detail.release:initialReleaseValue],
                    noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                    insert_loader: [false],
                    epicList: this._formBuilder.array(additionalInfoArray),
                    epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
                }));
            });
            this.featureDetails$ = this.featuresArray.valueChanges;
          }
        }
      }
      else if(this.multitabGroupForm.get('product').value.length){
        const filteredData = totalRecomArray.filter((obj) => this.multitabGroupForm.get('product').value.includes(obj.cloud));//console.log(filteredData);
        this.pro_data = filteredData;
        this.featuresArray.clear();
        this.quickActionIds = [];
        if(filteredData.length){
          this.epicTypes = [];
          if (this.featuresArray instanceof FormArray) {
            filteredData.forEach(detail => {
                // Determine the selected theme
                let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
                // Find the corresponding theme object from the theme list
                let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

                // Set the themeEpics based on the selected theme
                if (findTheme && findTheme.epics) {
                  this.themeEpicTypes = findTheme.epics;
                }

                // Prepare the additionalInfo array based on the selected theme
                let additionalInfoArray = this.themeEpicTypes.map(epic => {
                  return this._formBuilder.group({
                    label: epic.label || '', // Default to empty if label not found
                    value: epic.value || '', // Default to empty if value not found
                  });
                });
                let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
                const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';
                if(this.multitabGroupForm.get('product').value.includes(detail.cloud) && detail.epicName!='' && !this.epicTypes.find(el=>el.value==detail.epicName))this.epicTypes.push({label:detail.epicName, value: detail.epicName});
                this.featuresArray.push(this._formBuilder.group({
                    _id               : [detail._id],
                    featureName              : [detail.featureName],
                    cloud              : [detail.cloud],
                    technology              : [detail.technology],
                    setupType          : [detail.setupType],
                    featureDetails            : [detail.featureDetails],
                    complexity           : ['Medium'],
                    effort            : [detail.mediumEffort],
                    effortSummary            : [detail.effortSummary],
                    solutionDescription: [detail.solutionDescription],
                    epicName          : [detail.epicName],
                    theme: [selectedTheme],
                    releases_list: this._formBuilder.array(additionalReleaseArray),
                    release: [detail.release?detail.release:initialReleaseValue],
                    noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                    insert_loader: [false],
                    epicList: this._formBuilder.array(additionalInfoArray),
                    epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
                }));
            });
            this.featureDetails$ = this.featuresArray.valueChanges;
          }
        }
      }
      else{
        this.pro_data = totalRecomArray;
        this.featuresArray.clear();
        this.quickActionIds = [];
        if (this.featuresArray instanceof FormArray) {
          totalRecomArray.forEach(detail => {
              // Determine the selected theme
              let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
              // Find the corresponding theme object from the theme list
              let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

              // Set the themeEpics based on the selected theme
              if (findTheme && findTheme.epics) {
                this.themeEpicTypes = findTheme.epics;
              }

              // Prepare the additionalInfo array based on the selected theme
              let additionalInfoArray = this.themeEpicTypes.map(epic => {
                return this._formBuilder.group({
                  label: epic.label || '', // Default to empty if label not found
                  value: epic.value || '', // Default to empty if value not found
                });
              });
              let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
              const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';
              this.featuresArray.push(this._formBuilder.group({
                  _id               : [detail._id],
                  featureName              : [detail.featureName],
                  cloud              : [detail.cloud],
                  technology              : [detail.technology],
                  setupType          : [detail.setupType],
                  featureDetails            : [detail.featureDetails],
                  complexity           : ['Medium'],
                  effort            : [detail.mediumEffort],
                  effortSummary            : [detail.effortSummary],
                  solutionDescription: [detail.solutionDescription],
                  epicName          : [detail.epicName],
                  theme: [selectedTheme],
                  releases_list: this._formBuilder.array(additionalReleaseArray),
                  release: [detail.release?detail.release:initialReleaseValue],
                  noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                  insert_loader: [false],
                  epicList: this._formBuilder.array(additionalInfoArray),
                  epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
              }));
          });
          this.featureDetails$ = this.featuresArray.valueChanges;
        }
      }
    }
    else{
      let totalRecomArray = JSON.parse(JSON.stringify(this.original_features));
      if(this.multitabGroupForm.get('epic_name').value.length){
        const filteredData = totalRecomArray.filter((obj) => this.multitabGroupForm.get('epic_name').value.includes(obj.epicName));//console.log(filteredData);
        this.pro_data = filteredData;
        this.featuresArray.clear();
        this.quickActionIds = [];
        if(filteredData.length){
          if (this.featuresArray instanceof FormArray) {
            filteredData.forEach(detail => {
                // Determine the selected theme
                let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
                // Find the corresponding theme object from the theme list
                let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

                // Set the themeEpics based on the selected theme
                if (findTheme && findTheme.epics) {
                  this.themeEpicTypes = findTheme.epics;
                }

                // Prepare the additionalInfo array based on the selected theme
                let additionalInfoArray = this.themeEpicTypes.map(epic => {
                  return this._formBuilder.group({
                    label: epic.label || '', // Default to empty if label not found
                    value: epic.value || '', // Default to empty if value not found
                  });
                });
                let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
                const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';
                this.featuresArray.push(this._formBuilder.group({
                    _id               : [detail._id],
                    featureName              : [detail.featureName],
                    cloud              : [detail.cloud],
                    technology              : [detail.technology],
                    setupType          : [detail.setupType],
                    featureDetails            : [detail.featureDetails],
                    complexity           : ['Medium'],
                    effort            : [detail.mediumEffort],
                    effortSummary            : [detail.effortSummary],
                    solutionDescription: [detail.solutionDescription],
                    epicName          : [detail.epicName],
                    theme: [selectedTheme],
                    releases_list: this._formBuilder.array(additionalReleaseArray),
                    release: [detail.release?detail.release:initialReleaseValue],
                    noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                    insert_loader: [false],
                    epicList: this._formBuilder.array(additionalInfoArray),
                    epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
                }));
            });
            this.featureDetails$ = this.featuresArray.valueChanges;
          }
        }
      }
      else{
        this.pro_data = totalRecomArray;
        this.featuresArray.clear();
        this.quickActionIds = [];
        if (this.featuresArray instanceof FormArray) {
          totalRecomArray.forEach(detail => {
              // Determine the selected theme
              let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
              // Find the corresponding theme object from the theme list
              let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

              // Set the themeEpics based on the selected theme
              if (findTheme && findTheme.epics) {
                this.themeEpicTypes = findTheme.epics;
              }

              // Prepare the additionalInfo array based on the selected theme
              let additionalInfoArray = this.themeEpicTypes.map(epic => {
                return this._formBuilder.group({
                  label: epic.label || '', // Default to empty if label not found
                  value: epic.value || '', // Default to empty if value not found
                });
              });
              let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
              const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';
              this.featuresArray.push(this._formBuilder.group({
                  _id               : [detail._id],
                  featureName              : [detail.featureName],
                  cloud              : [detail.cloud],
                  technology              : [detail.technology],
                  setupType          : [detail.setupType],
                  featureDetails            : [detail.featureDetails],
                  complexity           : ['Medium'],
                  effort            : [detail.mediumEffort],
                  effortSummary            : [detail.effortSummary],
                  solutionDescription: [detail.solutionDescription],
                  epicName          : [detail.epicName],
                  theme: [selectedTheme],
                  releases_list: this._formBuilder.array(additionalReleaseArray),
                  release: [detail.release?detail.release:initialReleaseValue],
                  noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                  insert_loader: [false],
                  epicList: this._formBuilder.array(additionalInfoArray),
                  epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
              }));
          });
          this.featureDetails$ = this.featuresArray.valueChanges;
        }
      }
    }
  }

  complexityChange(event,inputFormGroupelement){
    let infoValues = inputFormGroupelement.value;
    let findFeature;
    if(this.getAIDrawer)findFeature = this.original_ai_features.find(el=>el._id==infoValues._id);
    else findFeature = this.original_features.find(el=>el._id==infoValues._id);
    let component = parseInt(infoValues['noOfComponents']);
    if(component>0){
      if(event.value=='Low')inputFormGroupelement.get('effort').setValue(component*findFeature['lowEffort']);
      else if(event.value=='Medium')inputFormGroupelement.get('effort').setValue(component*findFeature['mediumEffort']);
      else if(event.value=='High')inputFormGroupelement.get('effort').setValue(component*findFeature['highEffort']);
    }
  }

  featureThemeChange(event,inputFormGroupelement){
    // Find the selected theme
    let themeEpicTypes = [];
    let findTheme = this.themeListTypes.find(el => el.value == event.value);
    if (findTheme && findTheme.epics) {
      themeEpicTypes = findTheme.epics;
    }

    // Create a FormArray based on the themeEpicTypes
    const additionalInfoArray = this._formBuilder.array(
      themeEpicTypes.map(epic => {
        return this._formBuilder.group({
          label: epic.label || '', // Default to empty if label not found
          value: epic.value || '', // Default to empty if value not found
        });
      })
    );
    let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));


    // Replace the 'additionalInfo' FormArray with the newly created one
    inputFormGroupelement.setControl('epicList', additionalInfoArray);
    inputFormGroupelement.setControl('releases_list', additionalReleaseArray);
    // Set the 'epic' value
    inputFormGroupelement.get('epic').setValue(themeEpicTypes[0].value);
    inputFormGroupelement.get('release').setValue('');

    // console.log("findTheme",findTheme);console.log("infoValues",this.themeEpicTypes);
  }

  onComponentsCountChange(value: any,inputFormGroupelement): void {
    let infoValues = inputFormGroupelement.value;
    let findFeature;
    if(this.getAIDrawer)findFeature = this.original_ai_features.find(el=>el._id==infoValues._id);
    else findFeature = this.original_features.find(el=>el._id==infoValues._id);
    let effort_val;
    if(infoValues.complexity=='Low')effort_val = findFeature['lowEffort'];
    else if(infoValues.complexity=='Medium')effort_val = findFeature['mediumEffort'];
    else if(infoValues.complexity=='High')effort_val = findFeature['highEffort'];
    inputFormGroupelement.get('effort').setValue(infoValues.noOfComponents*effort_val);
  }

  deselectAll(event: MouseEvent) {
    event.preventDefault(); // Prevent the default behavior of selecting the "All" option
    this.multitabGroupForm.get('product').patchValue([]); // Deselect all options by setting an empty array
  }

  clear(){
    this.showcustomprompt=false;
    this.generate_replace = false;
    this.generate_append = false;
    this.drawerGroupForm.get('modified').setValue('');
    this.drawerGroupForm.get('original').setValue('');
    this.drawerGroupForm.get('type').setValue('');
    this.drawerGroupForm.get('custom').setValue('');
  }

  /**
   * Rewrite with AI
  */
  rewiteSummary(){
    this.generate_loader = true;
    let type = this.drawerGroupForm.get('type').value;
    let requirement= this.drawerGroupForm.get('custom').value;
    let taskID=uuidv4();
    this.drawerGroupForm.get('taskID').setValue(taskID);
    if(this._websocketService.isConnected){
      this._websocketService.sendFixGrammarCommand(this.drawerGroupForm.get('original').value, requirement,taskID);
    }
    else {
      
      setTimeout(() => {
        this.rewiteSummary();
      }, 3000);
    }
    // this._proposalService.fixGrammar({input: this.drawerGroupForm.get('original').value,prompt: requirement})
    // .subscribe(
    //     (value) =>
    //     {
    //         if(value.success){
    //           const regexForStripHTML = /<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi;
    //           const stripContent = value.data.replaceAll(regexForStripHTML, '');
              
    //           this.drawerGroupForm.get('modified').setValue(stripContent);
    //         }
    //         this.generate_loader = false;
    //     },
    //     (response) =>
    //     {
         
    //     },
    // );
  }

  openedChanged(event){
    if(event===false){
      this.clear();
    }
  }

  fixGrammar(){
    this.generate_grammar = true;
    let type = this.drawerGroupForm.get('type').value;
    let taskID=uuidv4();
    this.drawerGroupForm.get('taskID').setValue(taskID);
    if(this._websocketService.isConnected){
      this._websocketService.sendFixGrammarCommand(this.drawerGroupForm.get('original').value, "",taskID);
    }
    else {
       
      setTimeout(() => {
        this.fixGrammar();
      }, 3000);
    }
    // this._proposalService.fixGrammar({input: requirement})
    // .subscribe(
    //     (value) =>
    //     {
    //         if(value.success){
    //           const regexForStripHTML = /<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi;
    //           const stripContent = value.data.replaceAll(regexForStripHTML, '');
              
    //           this.drawerGroupForm.get('modified').setValue(stripContent);
    //         }
    //         this.generate_grammar = false;
    //     },
    //     (response) =>
    //     {
         
    //     },
    // );
  }

  closeDetails(): void
  {
    this.selectedFeature = null;
  }

  insertSelectedFeature(selectedFeature,bulk): void
  {
    selectedFeature.get('insert_loader').setValue(true);
    this.is_feature_inserted = true;
    // console.log(selectedFeature.value);
    // console.log(selectedFeature.get('release')?.value);
    let taskID=uuidv4();
    if(!this.selectedInsertFeature.find(el=>el._id==selectedFeature.get('_id').value))
      this.selectedInsertFeature.push({
        _id:selectedFeature.get('_id')?.value,
        complexity:selectedFeature.get('complexity')?.value,
        effort:selectedFeature.get('effort')?.value,
        noOfComponents:selectedFeature.get('noOfComponents')?.value, 
        insert_loader: selectedFeature.get('insert_loader')?.value,
        epicName:selectedFeature.get('epicName')?.value, 
        epic:selectedFeature.get('epic')?.value, 
        theme:selectedFeature.get('theme')?.value, 
        release:selectedFeature.get('release')?.value, 
        name:selectedFeature.get('featureName')?.value, 
        taskID: taskID,
        feature_id:selectedFeature.get('feature_id')?.value,
        selectedFeature:selectedFeature
      });
    this.drawerGroupForm.get('taskID').setValue(taskID);
    // console.log(this.selectedInsertFeature);
    if(this._websocketService.isConnected){
      setTimeout(() => {
        this._websocketService.sendPersonalizeFeatureCommand(selectedFeature.get('_id').value,this.data['proposal_meta'].industry,this.data['scope'],taskID);
      }, 3000);
    }
    else {
      setTimeout(() => {
        this.insertSelectedFeature(selectedFeature,bulk);
      }, 3000);
    }
    
    this.quickActionIds = [];
    setTimeout(() => {
      this.bulkMessage =false;
    }, 4000);
  }

  clickgenAI(){
    if(this.getAIDrawer){
      this.multitabGroupForm.get('epic_name').setValue('');
      this.multitabGroupForm.get('product').setValue('');
      if (this.searchInput) this.searchInput.nativeElement.value = '';
      this.pro_data = [];
      if(this.featuresArray.length){
        this.featuresArray.clear();
        this.quickActionIds = [];
      }
    }
    else this.closegenAI();
  }

  getRecommendation(){
    this.fetch_loader = true;
    // Send the request to the server
    let taskID=uuidv4();
    // this.fetch_list = true;
    // this._websocketService.sendPersonalizeFeatureCommand
    this._proposalService.getRecommendation(this.reommendation_text)
    .pipe(
        finalize(() =>
        {
          this.is_genai_interacted = true;
        }),
    )
    .subscribe(
        (response) =>
        {
          this.fetch_loader = false;
          this.fetch_list = false;
            if(response && response['data'] && response['data'].length){
              // this.previousSelection = [];
              // this.cloudTypes = [];
              this.pro_data = response['data'];
              this.original_ai_features = JSON.parse(JSON.stringify(response['data']));
              if (this.featuresArray instanceof FormArray) {
                this.featuresArray.clear();
                this.quickActionIds = [];
                response['data'].forEach(detail => {
                    // Determine the selected theme
                    let selectedTheme = this.data['selected_theme'] ? this.data['selected_theme'] : (detail.theme ? detail.theme : this.themeListTypes[0].value);
                    // Find the corresponding theme object from the theme list
                    let findTheme = this.themeListTypes.find(el => el.value === selectedTheme);

                    // Set the themeEpics based on the selected theme
                    if (findTheme && findTheme.epics) {
                      this.themeEpicTypes = findTheme.epics;
                    }

                    // Prepare the additionalInfo array based on the selected theme
                    let additionalInfoArray = this.themeEpicTypes.map(epic => {
                      return this._formBuilder.group({
                        label: epic.label || '', // Default to empty if label not found
                        value: epic.value || '', // Default to empty if value not found
                      });
                    });
                    let additionalReleaseArray = JSON.parse(JSON.stringify(this.releases_list));
                    const initialReleaseValue = additionalReleaseArray.length > 0 ? additionalReleaseArray[0]._id : '';
                    // if(!this.cloudTypes.find(el=>el.value==detail.cloud))this.cloudTypes.push({label:detail.cloud,value:detail.cloud});
                    this.featuresArray.push(this._formBuilder.group({
                        _id               : [detail._id],
                        featureName              : [detail.featureName],
                        cloud              : [detail.cloud],
                        technology              : [detail.technology],
                        setupType          : [detail.setupType],
                        featureDetails            : [detail.featureDetails],
                        complexity           : ['Medium'],
                        effort            : [detail.mediumEffort],
                        effortSummary            : [detail.effortSummary],
                        solutionDescription : [detail.solutionDescription],
                        epicName            : [detail.epicName],
                        theme: [selectedTheme],
                        releases_list: this._formBuilder.array(additionalReleaseArray),
                        release: [detail.release?detail.release:initialReleaseValue],
                        noOfComponents:[(detail.noOfComponents>0)?detail.noOfComponents:0],
                        insert_loader: [false],
                        epicList: this._formBuilder.array(additionalInfoArray),
                        epic          : [this.data['selected_epic']?this.data['selected_epic']:(detail.epic?detail.epic:this.themeEpicTypes[0].value)]
                    }));
                });
                this.allaifeaturesArray = new FormArray(
                  this.featuresArray.controls.map(control => {
                    return this._formBuilder.group(control.value); // Create a new FormGroup with the same value
                  })
                );
                this.featureDetails$ = this.featuresArray.valueChanges;
              }
            }
            else {
              
            }
        },
        (error) =>
        {
            
        },
    );
  }

  closegenAI(){
    if (this.searchInput) this.searchInput.nativeElement.value = '';
    this.is_genai_interacted = false;
    this.fetch_list = true;
    this.multitabGroupForm.get('product').setValue(this.data['proposal_meta'].product);
    this.getProposal({id:this.data['_id'],technology:this.data['proposal_meta'].technology,skip:0,limit:1000});
  }

  showFlashMessage(type: 'success' | 'error'): void
  {
      // Show the message
      this.flashMessage = type;


      // Hide it after 3 seconds
      setTimeout(() =>
      {
          this.flashMessage = null;

      }, 3000);
  }

  openAddForm() {
    this.quickActionIds = [];
    const dialogRef = this.dialog.open(AddFeatureComponent,{ 
      panelClass: 'add-feature-form',
      disableClose: true,
      data: this.data,
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  shownps(){
    // console.log(this.is_feature_inserted);
    if(this.is_feature_inserted && this.showModal){
      const dialogRef = this.dialog.open(NpsComponent,{ 
        panelClass: 'custom-dialog-container-nps',
        disableClose: true,
        data: {
          category: this.data['category']
        }
      });
    }
  }

  onTextSelect(event,targetId){
    // console.log("Event:",event);
    // const targetId = (event.target as HTMLElement).id; 
    if(targetId.includes("solutionDescription")||targetId.includes("featureDetails")){
      let data=targetId.split("-");
      this.selectedFeatureData={
        type:data[0],
        index:parseInt(data[1])
      };
    }else{
      this.selectedFeatureData={
        type:targetId,
        index:0
      };
    }
    this.mouseInitialPosition=event.pageY;
    //This code place menu at bottom of selection
    //  const textarea = event.target as HTMLTextAreaElement;
    //  const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    //  //console.log('Selected text:', selectedText);
    //  this.quickSelectedText=selectedText;
     const selection = window.getSelection();
     if (selection && selection.toString().trim().length > 0) {
        //  const range = selection.getRangeAt(0);
        //  const rect = range.getBoundingClientRect();
 
         /*
         this.menuPosition = {
             top: `${event.clientY + window.scrollY +60}px`,
             left: `${event.clientX+window.scrollX}px`
         };
         */
         this.showQuickPromptMenu = true;
     } else {
         this.quickSelectedText="";
         this.showQuickPromptMenu = false;
     }
  }
 
  onCompleteQuickAction(newValue){
  // console.log(newValue);console.log("selectedFeatureData:",this.selectedFeatureData);
  if(this.selectedFeatureData.type=="solutionDescription" || this.selectedFeatureData.type=="featureDetails"){

    // let updatedValue=this.featuresArray.at(this.selectedFeatureData.index).get(this.selectedFeatureData.type).value.replace(this.quickSelectedText,newValue);
    // this.featuresArray.at(this.selectedFeatureData.index).get(this.selectedFeatureData.type).setValue(updatedValue);
    if(this.selectedFeatureData.type=="solutionDescription"){
      const { from, to } = this.editor1.state.selection;
      const parser = ProseMirrorDOMParser.fromSchema(this.editor1.schema);
      const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
      const tr: Transaction = this.editor1.state.tr.replaceWith(from, to, replacementNode);
      const newTo = from + replacementNode.size;
      this.editor1.view.dispatch(tr);
      this.editor1.chain().focus().setTextSelection({ from, to: newTo }).run();
    }
    else if(this.selectedFeatureData.type=="featureDetails"){
      const { from, to } = this.editor.state.selection;
      const parser = ProseMirrorDOMParser.fromSchema(this.editor.schema);
      const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
      const tr: Transaction = this.editor.state.tr.replaceWith(from, to, replacementNode);
      const newTo = from + replacementNode.size;
      this.editor.view.dispatch(tr);
      this.editor.chain().focus().setTextSelection({ from, to: newTo }).run();
    }

  }else if(this.selectedFeatureData.type=="recommended"){
    const { from, to } = this.editor2.state.selection;
    const parser = ProseMirrorDOMParser.fromSchema(this.editor2.schema);
    const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
    const tr: Transaction = this.editor2.state.tr.replaceWith(from, to, replacementNode);
    const newTo = from + replacementNode.size;
    this.editor2.view.dispatch(tr);
    this.editor2.chain().focus().setTextSelection({ from, to: newTo }).run();
    // this.reommendation_text=this.reommendation_text.replace(this.quickSelectedText,newValue);
  }

  this.showQuickPromptMenu=false;
  this.isQuickMenuHighlighted=false;
  document.getSelection().removeAllRanges();

  }

  onTextareaBlur(){
    
    if(!this.insideTextArea && !this.isQuickMenuHighlighted){
        this.showQuickPromptMenu=false;
    }

  }
 
  onMouseEnter(){
    this.insideTextArea=true;
  }

  onMouseLeave(){
    this.insideTextArea=false;
  }

  onMouseEnterQuickPrompt(){
    this.isQuickMenuHighlighted=true;
  }

  onMouseLeaveQuickPrompt(){
    this.isQuickMenuHighlighted=false;
  }

  onSelectionChange(event,targetId){ 
    // console.log("Event:",event);
    const textarea = event.target as HTMLTextAreaElement;
    //  const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    // console.log("Event:",selectedText);

    // const targetId = (event.target as HTMLElement).id;
    // console.log('Target element ID:', targetId);

    if(targetId.includes("solutionDescription")||targetId.includes("featureDetails")){
      let data=targetId.split("-");
      this.selectedFeatureData={
        type:data[0],
        index:parseInt(data[1])
      };
    }else{
      this.selectedFeatureData={
        type:targetId,
        index:1
      };
    }

    let clientY=0
    if(this.mouseInitialPosition!=null){
      clientY=this.mouseInitialPosition;
    }
    
    if (this.quickSelectedText.trim().length > 0) {
      /*
        this.menuPosition = {
            top: `${clientY + window.scrollY +60}px`,
            left: `${window.scrollX}px`
        };
        */
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    }

  }

  onMouseDown(event){
    
    // console.log("on Mouse Down:",(event.pageY-120));

    if(this.isQuickMenuHighlighted){
      return;
    }
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    if(!this.showQuickPromptMenu){
      const screenWidth = window.innerWidth;
      const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;
      
      if(this.selectedFeatureData.type!='solutionDescription' && this.selectedFeatureData.type!='featureDetails'){
       
      this.menuPosition = {
        top: `${event.pageY - 120}px`,
        left: `${xPosition}px`
      };
      
      }else{
        this.menuPosition = { top: '0px', left: '0px' };
      }
      this.mouseInitialPosition=event.pageY-120;
    }
  }

  onClickOutside(event){
    if(!this.isQuickMenuHighlighted){
      this.isQuickMenuHighlighted=false;
      this.showQuickPromptMenu=false;
    }
  }

  openfixedBox(id){
    let indexId = this.quickActionIds.indexOf(id);
    if(indexId>-1)this.quickActionIds.splice(indexId, 1);
    else this.quickActionIds.push(id);
  }

  displaySelected(id){
    let indexId = this.quickActionIds.indexOf(id);
    if(indexId>-1)return 'selected';
  }

  bulkInsert(){
    const dialogRef = this._confirmationService.open({
      dismissible:true,
      title:"Confirmation",
      message:"Are you sure you want to insert " + this.quickActionIds.length + " selected solutions?",
      icon       : {
          show : true,
          name : 'heroicons_outline:information-circle',
          color: 'info',
      },
      actions:{
        confirm:{
          show:true,
          label:"Yes",
          color: 'primary',
        },
        cancel:{
          show:true,
          label:"Cancel"
        }
      }
     
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.selectedInsertFeature = [];
      if(result=='confirmed'){ 
        // Combine controls from both arrays into one array
        // let allControls = [
        //   ...(this.allaifeaturesArray?.controls || []), 
        //   ...(this.allfeaturesArray?.controls || [])
        // ];
        // Filter based on matching IDs and store them in filteredControls
        const filteredControls = this.featuresArray.controls.filter(control => 
          this.quickActionIds.includes(control.value._id)
        );
        // console.log(this.data);
        let insertFeatures = [];
        if(filteredControls.length){
          for (let index = 0; index < filteredControls.length; index++) {
            const selectedFeature = filteredControls[index];
            // console.log(selectedFeature);
            insertFeatures.push({cloud:selectedFeature.get('cloud').value,complexity:selectedFeature.get('complexity').value,componentsCount:selectedFeature.get('noOfComponents').value,effort:selectedFeature.get('effort').value, epic:selectedFeature.get('epic').value, epicName:selectedFeature.get('epicName').value, featureDescription:selectedFeature.get('featureDetails').value, masterFeatureID:selectedFeature.get('_id').value, name:selectedFeature.get('featureName').value, solutionAssumption:selectedFeature.get('solutionDescription').value, technology:selectedFeature.get('technology').value, theme:selectedFeature.get('theme').value, release:selectedFeature.get('release').value});
          }
          if(insertFeatures.length){
            this._proposalService.update({id:this.data['_id'],feature_action:"bulk_insert",feature_data:insertFeatures})
            .subscribe(
              (value) =>
              {
                this.bulkMessage = true; this.bulkInsertFeature = true;
                this.bulkMessageContent =  this.quickActionIds.length +' New solution added successfully to your '+ (this.data['category']=='Proposal'?'proposal':'project') +'.';
                if(value.data && value.data.features && value.data.features.length){
                  let newly_inserted = value.data.features.slice(0, insertFeatures.length);
                  // console.log(newly_inserted);
                  this.callWebSocket(filteredControls,newly_inserted);
                }
              },
              (response) =>
              {
              },
            );
          } 
        }
      }
    });  
  }

  callWebSocket(filteredControls,newly_inserted){
    for (let index = 0; index < filteredControls.length; index++) {
      const element = filteredControls[index];
      let findInserted = newly_inserted.find(el=>el.masterFeatureID==element.get('_id').value);
      // console.log(findInserted);
      // If a matching entry is found, patch the value
      if (findInserted) {
        filteredControls[index].get('release')?.patchValue(findInserted.release);
        filteredControls[index].get('feature_id')?.patchValue(findInserted._id);
        element.value.feature_id = findInserted._id;
        element.value.release = findInserted.release;
        element.get('feature_id')?.patchValue(findInserted._id);
        element.get('release')?.patchValue(findInserted.release);
      }

      
      this.insertSelectedFeature(element,true);
    }
  }

}
