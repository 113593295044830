import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor, NgIf, NgTemplateOutlet,NgClass, JsonPipe, NgStyle} from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input,Output,SimpleChanges,ViewChild,viewChild,ElementRef, Renderer2, ChangeDetectionStrategy } from '@angular/core';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor, ReactiveFormsModule, UntypedFormGroup, UntypedFormBuilder, FormArray, FormControl, Validators, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerService } from '@fuse/components/drawer';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { marked } from 'marked';
import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';
import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor, HTMLElement } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Underline } from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';

import { ProposalService } from 'app/core/proposal/proposal.service';
import { AddEpicComponent } from '../dialogs/add-epic/add-epic.component';
import { AddThemeComponent } from '../dialogs/add-theme/add-theme.component';
import { AddFeatureComponent } from '../dialogs/add-feature/add-feature.component';
import { CommonService } from 'app/core/common/common.service';
import { filter, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { FeatureComponent } from '../feature/feature.component';
import { NpsComponent } from '../../nps/nps.component';
import { DOMSerializer } from '@tiptap/pm/model';
import { StoryTableComponent } from 'app/modules/user/common/story-table/story-table.component';
import { TestCaseTableComponent } from 'app/modules/user/common/test-case-table/test-case-table.component';
import { AiEditorComponent } from 'app/modules/user/common/ai-editor/ai-editor.component';
import { ConfigurationComponent } from "../../common/configuration/configuration.component";
import { AtlassianService } from 'app/core/common/atlassian.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { JiraIntegrationComponent } from 'app/modules/common/jira-integration/jira-integration.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RegenerateInsightsViewComponent } from "../dialogs/regenerate-insights-view/regenerate-insights-view.component";
import { RegeneratingProgressBarComponent } from "../../../common/regenerating-progress-bar/regenerating-progress-bar.component";
import { I } from '@angular/cdk/keycodes';
import { DomSanitizer } from '@angular/platform-browser';
import { SolutionDrawerComponent } from 'app/modules/user/common/solution-drawer/solution-drawer.component';
import { ProgressEventTracker } from 'app/core/common/progress_event_tracker';

@Component({
  selector: 'app-workspace-view',
  standalone: true,
  imports: [MatProgressSpinnerModule, NgIf, FormsModule,SolutionDrawerComponent, NgxTiptapModule, AddThemeComponent, StoryTableComponent, ConfigurationComponent, TestCaseTableComponent, EditorControlsComponent, NgClass, AddFeatureComponent, JsonPipe, FuseDrawerComponent, NgTemplateOutlet, AddEpicComponent, MatFormFieldModule, NgFor, MatSelectModule, ReactiveFormsModule, MatIcon, MatInputModule, MatButtonModule, MatExpansionModule, DragDropModule, MatSelectModule, AddThemeComponent, FuseAlertComponent, RegenerateInsightsViewComponent, RegeneratingProgressBarComponent],
  templateUrl: './workspace-view.component.html',
  styleUrl: './workspace-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceViewComponent {

  @ViewChild(FuseDrawerComponent) insightDrawer: FuseDrawerComponent;
  @ViewChild("lowlevelDrawer") lowlevelDrawer: FuseDrawerComponent;
  @ViewChild("solutionDrawer") solutionDrawer: FuseDrawerComponent;
  private unlistenClickOutside: () => void;
  accordion = viewChild.required(MatAccordion);
  //expandAll:boolean=true;
  expandState:number=0
  //expandChild:boolean = true;
  isSyncingProject:boolean=false;
  private selectedFeatureData={
    type:"",
    index:0
  }
  _proposalDetail: any = {};
  _testplanDetail: any = {};
  _buildDetail: any = {};
  notSyncedTestCases=0;
  isRegenerating=false;
  delayedRender=false;
  selectedLowLevelSolution;
  @ViewChild('addFeature') addFeature: AddFeatureComponent;
  @ViewChild('addTheme') addThemeDrawer: AddThemeComponent;
  @ViewChild('addEpic') addEpicDrawer: AddEpicComponent;
  @ViewChild("regenDrawer") regenDrawer:RegenerateInsightsViewComponent;
 
  private proposalSet: boolean = false;
  private buildSet: boolean = false;
  private testplanSet: boolean = false;
  _page_id: string;

  @Input() set pageId(value: any) {
    if (value) {
      this._page_id = value;
      if(value=='project' || value=='proposal'){
        this.testplanSet = true;
        this.buildSet = true;
      }
      else if(value=='testing'){
        this.buildSet = true;
      }
      else if(value=='build'){
        this.testplanSet = true;
        this.initBuildConfiguration();
        setTimeout(() => {
          this.delayedRender = true;
        }, 50);
      }
    }
  }

  @Input() set proposal(value: any) {
    if (value) {
      this._proposalDetail = value;
      this.proposalSet = true;
      this.checkInitialization();
    }
  }

  @Input() set testplan(value: any) {
    if (value) {
      this._testplanDetail = value;
      this.testplanSet = true;
      this.initTestCasesSyncStats();
      this.checkInitialization();
    }
  }

  @Input() set build(value: any) {
    if (value) {
      this._buildDetail = value;
      this.testplanSet = true;
      this.buildSet = true;
      this.checkInitialization();
    }
  }

  @Input()
  themeTypes:any=[];

  @Input()
  all_categories:any[]=[];

  @Input()
  allMetadataType:any[]=[];

  @Input()
  allMetadataCategory:any[]=[];

  @Input()
  technologyDomains:any[]=[];

  @Input()
  metadataMap = new Map();

  @Input()
  is_write_allowed:any;

  flashMessage: string;
  showModal;
  flashMessageType: 'success' | 'error' | null = null;
  epicTypes:any=[];
  proposalInsights:any[]=[];
  answerEditors_readonly: Editor[] = []; 
  selectedInsight=null;
  complexities: any[] = [];
  filterApplied: boolean = false;
  searchSolAct: boolean =false;
  isLLSDrawerOpen: boolean =false;
  isSolutionDrawerOpen: boolean =false;
  // questionsArray: FormArray<any>;

  paymentDetails$: Observable<any>;
  inputFormGroup:UntypedFormGroup;
  insightquestionForm:UntypedFormGroup;
  drawerGroupForm:UntypedFormGroup;
  selectedFeature: any;
  llsdconfigForm:UntypedFormGroup;
  requirements:any=[];
  original_requirements:any=[];
  themeListTypes: any [] = [];
  themeEpicTypes: any[] = [];
  themesExpanded:boolean[]=[];
  releases_list:any=[];
  all_original_release_list:any=[];
  epicsExpanded:any=[];
  featuresExpanded:any=[];
  storiesExpanded:any=[];
  hlsolutionExpanded:any=[];
  llsolutionExpanded:any=[];
  configExpanded:any=[];
  casesExpanded:any=[];
  testcaseList:any[] = [];
  filterselectedEpics: any[]=[];
  filterselectedThemes: any[]=[];
  filterselectedReleases: any[]=[];
  allConfigurations: any[]=[];

  quickSelectionStartIndex=-1;
  quickSelectionEndIndex=-1;
  selectedText: string = '';
  insideTextArea=false;
  isQuickMenuHighlighted:boolean=false;
  showQuickPromptMenu = false;
  quickSelectedText="";
  menuPosition = { top: '500px', left: '80px' };
  mouseDownPos=0;
  mouseInitialPosition=null;
  selectedTargetIdEditor: string = '';
  selectedSolution: any = {};
  isJiraError:boolean=false;
  jiraErrorMessage="";
  isViaConstructorString:string;
  @Output()
  onRefreshProposal=new EventEmitter();
  
  @Output()
  onSaveEpics=new EventEmitter<any>();

  @Input() receiveData: string;

  @Output()
  onSaveThemeAndEpics=new EventEmitter<any>();

  @Output() onFeatureUpdate=new EventEmitter<any>();

  llsSummaryCombinedEditor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        },
      }),
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: "Enter Low Level Solution Summary ",
      })
    ],
    onSelectionUpdate: ({ editor }) => {
      this.updateSelectedText(editor);
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if(content && content!='<p></p>')this.selectedLowLevelSolution.selectedEpic.llsSummaryCombined = content;
    }
  });

  isSavingLLSSummary=false;
  readonly dialog = inject(MatDialog);
  constructor(private progressTracker:ProgressEventTracker,private sanitizer: DomSanitizer,private websocketService:WebsocketService,private _formBuilder: UntypedFormBuilder,private atlassianService:AtlassianService,
    private _commonService: CommonService, private _proposalService:ProposalService,private _changeDetectorRef: ChangeDetectorRef,private fuseDrawer:FuseDrawerService,private renderer: Renderer2, private el: ElementRef){
      this.isViaConstructorString = 'constructor';
      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "sync_project_with_jira")
      ).subscribe((message:any) => {
        this.isSyncingProject=false;
       this.initProjectStories();
      });


      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "generating_configuration_workbook")
      ).subscribe((message) => {
        console.log("generating_configuration_workbook:",message);
        this.onRefreshProposal.emit();
      });


      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "low_level_solution_generated")
      ).subscribe((message:any) => {
        console.log("low_level_solution_generated:",message);
        this.onRefreshProposal.emit();
      });

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "epic_solutions_regenerated")
      ).subscribe((message:any) => {
        console.log("epic_solutions_regenerated:",message);
        this.onRefreshProposal.emit();
      });

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "epic_regenerated")
      ).subscribe((message:any) => {
        console.log("Epic Regenerated:",message);
       this.onRefreshProposal.emit();
      });

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "theme_regenerated")
      ).subscribe((message:any) => {
        console.log("Theme Regenerated:",message);
       this.onRefreshProposal.emit();
      });

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "jira_sync_error")
      ).subscribe((message:any) => {
        this.isSyncingProject=false;
        this.isJiraError=true;
        const errorValues = Object.values(message.data.errors)
        .map((value, index) => `${index + 1}. ${value} `);
        this.jiraErrorMessage=`Jira sync failed: ${errorValues}`;
      });


      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "story_synced")
      ).subscribe((message:any) => {
        console.log("Story Synced:",message);
        this.updateSyncedStory(message.data);
      });

      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "test_case_synced")
      ).subscribe((message:any) => {
        console.log("Workspace Test Case Synced:",message);
        this.updateSyncedTestCase(message.data);
        this.initTestCasesSyncStats();
      });


      this.websocketService.messageSubject.pipe(
        filter((message) => message.type === "fix_grammar")
      ).subscribe((message) => {
          // console.log("Received:", message);
          if(!message.isCompleted ){
            let stripContent = this.drawerGroupForm.get('modified').value + message.data;
            this.drawerGroupForm.get('modified').setValue(stripContent);
          }
          else if(message.isCompleted){
            
          }
      });
  



  }
  
  allStories=[];

  ngOnInit(){
    this.drawerGroupForm = this._formBuilder.group({
      index: [0],
      taskID: [''],
      type: [''],
      original: ['', Validators.required],
      custom: [''],
      modified: ['']
    });
    this.inputFormGroup = this._formBuilder.group({
      theme: [[]],
      search: [''],
      epic_name: [[]],
      release: [[]],
    });
    // this.insightquestionForm = this._formBuilder.group({
    //   questions: new FormArray([
    //     new FormGroup({
    //       title: new FormControl(''),
    //       notes: new FormControl(''),
    //       capability: new FormControl('')
    //     })
    //   ])
    // });
    // this.questionsArray = this.insightquestionForm.get('questions') as FormArray;
    this.insightquestionForm = this._formBuilder.group({
      questions: this._formBuilder.array([])  // ✅ Correct key used
    });
    if (this.proposalSet && this.buildSet && this.testplanSet) {
      this.initializeComponent();
    }
  }

  get questionsArray(): FormArray {
    return this.insightquestionForm.get('questions') as FormArray;
  }

  private checkInitialization() {
    if (this.proposalSet && this.buildSet && this.testplanSet) {
      this.initializeComponent();
      this._changeDetectorRef.detectChanges();
      
      this.proposalSet = false;
      this.buildSet = false;
      this.testplanSet = false;
    }
    else if(this.receiveData=='refresh'){
      this.initializeComponent();
      this._changeDetectorRef.detectChanges();
    }
  }

  private initializeComponent(){
    console.log('init Data received from Parent: ', this.receiveData,this.isViaConstructorString);
    let theRefreshCounter = (this.receiveData=='refresh' && (!this.isViaConstructorString || this.isViaConstructorString!='constructor'))?false: true;
    // console.log(theRefreshCounter);
    this.resetRequirements(theRefreshCounter);
    this.resetToAllEpics();
    this.initProposalInsights();
    this.complexities = this._commonService.complexities;
    if(this._proposalDetail['epics'] && this._proposalDetail['epics'].length){
      this.themeListTypes = [];
      this._proposalDetail['epics'].forEach(element => {
        let current = {label:element.theme,value:element.theme,epics:[]};
        if(element.epics && element.epics.length){
          element.epics.forEach(epicelement => {
            current['epics'].push({label:epicelement.name,value:epicelement.name})
          });
        }
        this.themeListTypes.push(current);
      });
    }
    this.releases_list = (this._proposalDetail && this._proposalDetail.releases_list && this._proposalDetail.releases_list)?JSON.parse(JSON.stringify(this._proposalDetail.releases_list)):[];
    this.releases_list.forEach(release => {
      release.requirements = release.requirements || [];
    });
    this._proposalDetail.epics.forEach(epic => {
      if (!epic.epics) return; // Prevent undefined errors
    
      epic.epics.forEach(subEpic => {
        if (!subEpic.features) return;
    
        subEpic.features.forEach(requirement => {
          if (requirement.release) {
            const releaseIndex = this.releases_list.findIndex(r => r._id === requirement.release._id);
            
            if (releaseIndex > -1) {
              requirement['theme'] = { _id: epic._id, title: epic.theme };
              requirement['epic'] = { _id: subEpic._id, title: subEpic.name };
              this.releases_list[releaseIndex].requirements.push(requirement);
            }
          }
        });
        epic.epics = epic.epics.map((epic, index) => {
          // console.log(`Processing Epic #${index + 1}:`, epic);
    
          if (!epic.llsSummaryCombined) {
            const lowLevelSummary = epic.lowLevelSolutionSummary;
    
            if (lowLevelSummary) {
              const summaryHtml = lowLevelSummary.summary ? `<p>${lowLevelSummary.summary}</p>` : '';
    
              const categoriesHtml = (lowLevelSummary.solutionCategories && lowLevelSummary.solutionCategories.length)?lowLevelSummary.solutionCategories
                .map(category => {
                  const formattedDetails = category.details
                    ? category.details.map(detail => `<li>${detail}</li>`).join('')
                    : '';
                  return `<b style="font-weight: 600">${category.category}</b><ul>${formattedDetails}</ul>`;
                })
                .join(''):'';
    
              const assumptionsHtml =
                lowLevelSummary.assumptions && lowLevelSummary.assumptions.length > 0
                  ? `<b style="font-weight: 600">Assumptions</b><ul>${lowLevelSummary.assumptions.map(assumption => `<li>${assumption}</li>`).join('')}</ul>`
                  : '';
    
              epic.llsSummaryCombined = summaryHtml + categoriesHtml + assumptionsHtml;
    
              // Ensure it's not empty
              if (!epic.llsSummaryCombined.trim()) {
                epic.llsSummaryCombined = '';
              }
            } else {
              epic.llsSummaryCombined = '';
            }
          }
    
          return { ...epic }; // Ensure immutability
        });
      });
    });
    
    this.all_original_release_list = JSON.parse(JSON.stringify(this.releases_list));
    if(this._page_id=='build' && this.receiveData=='refresh'){
      this.initBuildConfiguration();
    }
    if(this._page_id=='testing' && this.receiveData=='refresh'){
      this.initTestCasesSyncStats();
    }
    this._changeDetectorRef.detectChanges();
    this._changeDetectorRef.markForCheck();
  }

  initTestCasesSyncStats(){
    if(this._testplanDetail && this._testplanDetail._id){
    this.atlassianService.getTestCaseNotSyncedCount(this._testplanDetail._id).subscribe((response)=>{
      if(response && response.success){
        this.notSyncedTestCases=response.data;
      }
    });
  }
  }

  initProjectStories(){
    this.allStories=[];
    this.atlassianService.getProjectStories(this._proposalDetail._id,0,10000).subscribe((response)=>{
      if(response.success){
      this.allStories=response.data;
      this.initStories();
      }
    });
  }

  initBuildConfiguration(){
    this._proposalService.getConfigurations(
      0, 
      10000, 
      this._buildDetail._id, 
      '',
      '',
      [], 
      [], 
      [], 
      [],
      [],
      [],
      [],
      ''
    ).subscribe((response)=>{
      if (response.data && response.data.length) {
        this.allConfigurations = response.data;
        this.initConfigs();
      } else {
      }
      this._changeDetectorRef.markForCheck();
    });
  }

  trackByEpics(index: number, epic: any) {
    return epic._id; // ✅ Prevents unnecessary re-renders
  }

  isRefreshSummaryEnabled(stories){
    if(!stories){
      return false;
    }
    let hasPendingStories=false;

    for(let story of stories){
      if(story && story.lowLevelSolutionSummary && !story.lowLevelSolutionSummary.usedInEpicSummary){
        hasPendingStories=true;
        break;
      }
    }

    return hasPendingStories;
  }

  onClickRefreshSummary(themeID,epic){

    if(!this.isRefreshSummaryEnabled(epic.stories)){
      return;
    }

    this.progressTracker.saveProgressEvent("lls",epic._id,"");
    epic["isGeneratingLLS"]=true;
    this._proposalService.refreshLLSSummary(this._proposalDetail._id,themeID,epic._id).subscribe((response)=>{
      if(response.success){
      }
    });
  }

  updateSyncedTestCase(data){
    for(let testcase of this.testcaseList){
      if(testcase._id==data.testCase){   
        testcase.jiraID=data.jiraID;
        testcase.jiraKey=data.jiraKey;
        testcase.jiraSynced=true;
        testcase.isSyncing=false;

      }
    }
  }

  updateSyncedStory(data){
    for(let story of this.allStories){
      if(story._id==data.story){
        
        story.jiraIssueID=data.jiraID;
        story.jiraKey=data.jiraKey;
        story.jiraSynced=true;
        story.isSyncing=false;



          /*
        this.requirements.forEach((theme)=>{
          theme.epics.forEach((epic)=>{
            epic.stories.forEach((myStory)=>{
              
              if(myStory._id==data.story){
                myStory.jiraIssueID=data.jiraID;
                myStory.jiraKey=data.jiraKey;
                myStory.jiraSynced=true;
                myStory.isSyncing=false;

                console.log("Story Found",myStory);
              }
            })
          });
        });
        */
         
       // this.initStories();



        break;
      }
    }
  }

  onStartStorySyncing(storyID){
    for(let story of this.allStories){
      if(story._id==storyID){
      story.isSyncing=true;
      break;
      }
    }
  }

  initThemesExpandedState(){
    this.themesExpanded=[];
    this.epicsExpanded=[];
    let tIndex=0;
    
    for(let theme of this.requirements){
      this.themesExpanded.push(true);
      let eIndex=0;
      for(let epic of theme.epics){
        let key=`${tIndex}-${eIndex}`;
        this.epicsExpanded[key]=true;
        this.featuresExpanded[key]=true;
        this.storiesExpanded[key]=true;
        this.casesExpanded[key]=true;
        this.hlsolutionExpanded[key]=true;
        this.llsolutionExpanded[key]=true;
        this.configExpanded[key]=true;
        eIndex++;
      }
      tIndex++;
    }
  }

  getEpicKey(themeIndex,epicIndex){
    return `${themeIndex}-${epicIndex}`;
  }

  getEpicExpanded(themeIndex,epicIndex){
    let key=`${themeIndex}-${epicIndex}`;
    return this.epicsExpanded[key];
  }

  toggleExpansion(themeIndex: number, epicIndex: number, isExpanded: boolean) {
    const epicKey = `${themeIndex}-${epicIndex}`;
    // console.log(`Toggle Event: ${epicKey} => ${isExpanded}`);
  
    this.epicsExpanded[epicKey] = isExpanded;
    this.featuresExpanded[epicKey] = isExpanded;
    this.storiesExpanded[epicKey] = isExpanded;
    this.casesExpanded[epicKey] = isExpanded;
    this.hlsolutionExpanded[epicKey] = isExpanded;
    this.llsolutionExpanded[epicKey] = isExpanded;
    this.configExpanded[epicKey] = isExpanded;
    // Force UI update
    this._changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes['receiveData'] && this.receiveData=='refresh') {
    //   console.log('Data received from Parent: ', this.receiveData);
    //   this.resetRequirements(false);
    //   this.resetToAllEpics();
    //   this.initProposalInsights();
    // }
  }

  private getThemeEpicSolutions(theme:string,epic:string){
    let allSolutions=[];
    for(let feature of this._proposalDetail.features){
      if(feature.theme==theme && feature.epic==epic){
        if(feature.release){
          let release_shortname = this.releases_list.find(el=>el._id==feature.release);
          if(release_shortname)feature['release_shortname'] = release_shortname.short_name;
        }
        allSolutions.push(feature);
      }
    }
    return allSolutions;
  }

  updateSelectedText(editor: Editor): void {
    // const { from, to } = editor.state.selection;
    // this.selectedText = editor.state.doc.textBetween(from, to, '\n');
    // this.quickSelectedText = editor.state.doc.textBetween(from, to, ' ');
    // this.quickSelectionStartIndex = from;
    // this.quickSelectionEndIndex = to; console.log(from, to);
    // const selectedHTML = editor.state.doc.textBetween(from, to, '\n');
    // this.beforeHTML = editor.state.doc.textBetween(0, from, '\n');
    // this.afterHTML = editor.state.doc.textBetween(to, editor.state.doc.content.size, '\n');
    // console.log(this.tabGroupForm.get('executiveSummary').value);
    // console.log("beforeHTML:", this.beforeHTML);
    // console.log("afterHTML:", this.afterHTML);
    // console.log("editor:", editor);
    const { from, to } = editor.state.selection;
    // console.log("from:", from);
    // console.log("to:", to);
    // Get the selected content as a ProseMirror Fragment
    const selectedFragment = editor.state.doc.slice(from, to);

    // Convert the Fragment to a DOM Node
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const div = document.createElement('div');
    selectedFragment.content.forEach(node => {
        div.appendChild(serializer.serializeNode(node));
    });

    // Get the selected HTML
    const selectedHTML = div.innerHTML;

    // Store the selection details
    this.quickSelectionStartIndex = from;
    this.quickSelectionEndIndex = to;
    this.selectedText = selectedHTML;
  }

  initSolutions() {
    this.requirements.forEach((theme) => {
      theme.epics.forEach((epic) => {
        let solutions = this.getThemeEpicSolutions(theme.theme, epic.name);
        epic.solutions = solutions;
      });
    });
    this.original_requirements=[...this.requirements];
    this._changeDetectorRef.detectChanges();
    this._changeDetectorRef.markForCheck();
  }

  initUpdateSolutions(){
    //either fetch fresh all proposal features and seggregate or fetch the particular theme epic feature
    //case 1
    this._proposalService.getProposalFeatures(this._proposalDetail._id).subscribe((response)=>{
      if(response.success){
        this._proposalDetail.features = response.features;
        this.initSolutions();
      }
    });
    //case 2
    // let solutions = this.getThemeEpicSolutions(theme, epic);
    // epic.solutions = solutions;
  }
  
  search(term){
    this.filterRequirements(this.inputFormGroup.get("search").value,
    this.inputFormGroup.get("theme").value,
    this.inputFormGroup.get("epic_name").value,
    this.inputFormGroup.get("release").value
    );
  // this.initProjectStories();

  }

  resetRequirements(type){console.log(type);
    this.requirements=[...this._proposalDetail.epics];
    this.original_requirements=[...this.requirements];
    let index=0;
    for(let i=0;i<this.requirements.length;i++){
      for(let j=0;j<this.requirements[i].epics.length;j++){
        this.requirements[i].epics[j]["index"]=index;
        index++;
      }
    }
    this.initSolutions();
    this.initProjectStories();
    if(type)this.initThemesExpandedState();
    this.isViaConstructorString='';
    this._changeDetectorRef.detectChanges();
    this._changeDetectorRef.markForCheck();
  }

  initStories() {
    // Use setTimeout to update stories after initial change detection
    setTimeout(() => {
      this.requirements = this.requirements.map((theme) => ({
        ...theme,
        epics: theme.epics.map((epic) => ({
          ...epic,
          stories: this.getEpicStories(theme.theme, epic.name) || []
        }))
      }));
      this.original_requirements=[...this.requirements];
      this._changeDetectorRef.detectChanges();
      this._changeDetectorRef.markForCheck();
    });
  }

  initConfigs(){
    setTimeout(() => {
      this.requirements = this.requirements.map((theme) => ({
        ...theme,
        epics: theme.epics.map((epic) => ({
          ...epic,
          configurations: this.getEpicConfigs(theme._id, epic._id) || []
        }))
      }));
      this._changeDetectorRef.detectChanges();
      this._changeDetectorRef.markForCheck();
    });
  }

  getEpicConfigs(theme:string,epic:string){
    let epicConfigs=[];
    for(let eachconfig of this.allConfigurations){
      if(eachconfig.themeID==theme && eachconfig.epicID==epic){
        epicConfigs.push(eachconfig);
      }
    }
    epicConfigs = epicConfigs.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    epicConfigs=epicConfigs.reverse();
    return epicConfigs;
  }

  resetToAllEpics(){
    this.epicTypes = this._proposalDetail.epics?.flatMap((epic) => {
      return epic.epics.map((themeEpic) => {
        return { label: themeEpic.name, value: themeEpic.name };
      });
    }) ?? [];    
  }

  toggleDetails(solution,themeID,epicID): void
    {
      this.selectedSolution = solution;
      this.selectedSolution['themeID'] = themeID;
      this.selectedSolution['epicID'] = epicID;
        // If the product is already selected...
        // if ( this.selectedFeature && this.selectedFeature['_id'] === productId && this.selectedFeature['index']=== index )
        // {
        //   this.closeDetails();
        //   return;
        // }else{
        //   this.selectedFeature = pro_data.find(o => o._id === productId);
        //   this.selectedFeature['index'] = index;
        //   if(this.selectedFeature['theme']){
        //     let findTheme = this.themeListTypes.find(el=>el.value==this.selectedFeature.theme);
        //     if(findTheme && findTheme.epics){
        //       this.themeEpicTypes = findTheme.epics;
        //     }
        //   }
        //   this._changeDetectorRef.detectChanges();
        // }

        this.solutionDrawer.open();
  }

  onSolutionUpdated(updatedSolution){
    this.requirements.forEach((elepics) => {
      elepics.epics.forEach((themeEpic) => {
        let solutionIndex = themeEpic.solutions.findIndex(sol => sol._id === updatedSolution._id);
    
        if (solutionIndex > -1) {
          // Found the solution, update it
          themeEpic.solutions[solutionIndex] = {
            ...themeEpic.solutions[solutionIndex],
            ...updatedSolution
          };
          // console.log(updatedSolution.themeID,elepics._id);
          // console.log(updatedSolution.epicID,themeEpic._id);
          // If themeID or epicID has changed, remove it and move it to the correct place
          if (updatedSolution.themeID !== updatedSolution.theme || updatedSolution.epicID !== updatedSolution.epic) {
            // console.log(`Moving solution ${updatedSolution._id} to the correct theme/epic.`);
            
            // Remove from old location
            themeEpic.solutions.splice(solutionIndex, 1);
    
            // Find the new theme and epic
            let newTheme = this.requirements.find(req => req.theme === updatedSolution.theme);
            // console.log('newTheme',newTheme);
            if (newTheme) {
              let newEpic = newTheme.epics.find(epic => epic.name === updatedSolution.epic);
              // console.log('newEpic',newEpic);
              if (newEpic) {
                newEpic.solutions.push(updatedSolution);
              } else {
                // console.error(`Epic with ID ${updatedSolution.epicID} not found.`);
              }
            } else {
              // console.error(`Theme with ID ${updatedSolution.themeID} not found.`);
            }
          }
        }
      });
    });
    // this.onRefreshProposal.emit();

    this.onFeatureUpdate.emit({refresh:true});
  }

  featureComplexityChange(event,solution){
    // let findFeature = this.original_features.find(el=>el._id==infoValues._id);
    // console.log("event",event);
    // console.log("solution",solution);
    let component = parseInt(solution['componentsCount']);
    if(component>0 && solution.masterFeatureID){
      if(event.value=='Low')solution['effort'] = component*solution.masterFeatureID['lowEffort'];
      else if(event.value=='Medium')solution['effort'] = component*solution.masterFeatureID['mediumEffort'];
      else if(event.value=='High')solution['effort'] = component*solution.masterFeatureID['highEffort'];
    }
    // console.log("solution",solution);
  }

  featureThemeChange(event,inputFormGroupelement){
    // console.log(event);
    let findTheme = this.themeListTypes.find(el=>el.value==event.value);
    if(findTheme && findTheme.epics){
      this.themeEpicTypes = findTheme.epics;
    }
    // console.log("findTheme",findTheme);console.log("infoValues",this.themeEpicTypes);
  }

  onComponentsCountChange(value: any,solution): void {
    let effort_val = 0;
    if(solution.masterFeatureID){
      if(solution.complexity=='Low')effort_val = solution.masterFeatureID['lowEffort'];
      else if(solution.complexity=='Medium')effort_val = solution.masterFeatureID['mediumEffort'];
      else if(solution.complexity=='High')effort_val = solution.masterFeatureID['highEffort'];
      let calcVal = solution.componentsCount*effort_val;
      solution['effort'] = calcVal;
    }
  }

  updateSelectedFeature(solution): void
  {
    
    // console.log(inputFormGroup.value);return;
    this._proposalService.update({id:this._proposalDetail._id,feature_action:'update',feature_data:solution})
          .subscribe(
            (value) =>
            {
              if(value.status){
                this.showFlashMessage('success');
                this.flashMessage = 'Solution updated successfully!';
                if(this.showModal){
                  const dialogRef = this.dialog.open(NpsComponent,{ 
                    panelClass: 'custom-dialog-container-nps',
                    disableClose: true,
                    data: {
                      category: this._proposalDetail.category
                    }
                  });
                }
                setTimeout(() =>
                {
                  this.initUpdateSolutions();
                  // this.closeDetails();
                  // this.onFeatureUpdate.emit({refresh:true});
                }, 2000);
              }
              else this.showFlashMessage('error');
            },
            (response) =>
            {
            },
        );
  }

  showFlashMessage(type: 'success' | 'error'): void
  { 
    // Show the message
    this.flashMessageType = type;
    this._changeDetectorRef.detectChanges();
    this._changeDetectorRef.markForCheck();
    console.log(this.flashMessageType);

      // Hide it after 3 seconds
      setTimeout(() =>
      {
          this.flashMessageType = null;
          console.log(this.flashMessageType);

      }, 3000);
  }

  onTextareaBlur(){
    if(!this.insideTextArea && !this.isQuickMenuHighlighted){
        this.showQuickPromptMenu=false;
    }
  }

  onMouseEnter(){ 
    this.insideTextArea=true;
  }

  onMouseLeave(){ 
    this.insideTextArea=false;
  }

  onMouseEnterQuickPrompt(){
    this.isQuickMenuHighlighted=true;
  }

  onRegenerateEpicInitiated(event){
    console.log("Regenerate Epic called",event);
    let isTestPlan=this._page_id=="testing"?true:false;
    let testPlan="";
    if(isTestPlan){
      testPlan=this._testplanDetail._id;
    }
    this.websocketService.sendRegenerateEpicCommand(isTestPlan,event.theme._id,event.epic._id,this._proposalDetail._id,testPlan,"12345");

    for(let theme of this._proposalDetail.epics){
      if(theme._id==event.theme._id){
        for(let epic of theme.epics){
          if(epic._id==event.epic._id){
            epic.isRegenerating=true;
           break;
          }
        }
      }
    }

    for(let theme of this.requirements){
      if(theme._id==event.theme._id){
        for(let epic of theme.epics){
          if(epic._id==event.epic._id){
            epic.isRegenerating=true;
           break;
          }
        }
      }
    }
    this.isRegenerating=true;
    this.isRegenerating=false;
    this._changeDetectorRef.detectChanges();
    this._changeDetectorRef.markForCheck();

  }

  getRegeneratorViewType(){
    if(this._page_id=="testing"){
      return 2;
    }
    
    if(this._proposalDetail.category=="Proposal"){
      return 0;
    }
    if(this._proposalDetail.category=="Project"){
      return 1;
    }
  }

  onRegenerateThemeInitiated(event){
    console.log("Regenerate Theme called",event);

    let isTestPlan=this._page_id=="testing"?true:false;
    let testPlan="";
    if(isTestPlan){
      testPlan=this._testplanDetail._id;
    }
    this.websocketService.sendRegenerateThemeCommand(isTestPlan,event.theme._id,"",this._proposalDetail._id,testPlan,"12345");

    for(let theme of this._proposalDetail.epics){
      if(theme._id==event.theme._id){
        theme.isRegenerating=true;
       break;
      }
    }
    for(let theme of this.requirements){
      if(theme._id==event.theme._id){
        theme.isRegenerating=true;
      }
    }
    this.isRegenerating=true;
    this.isRegenerating=false;
    this._changeDetectorRef.detectChanges();
    this._changeDetectorRef.markForCheck();
  }

  onMouseDown(event){
    if(this.isQuickMenuHighlighted){
      return;
    }
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    if(!this.showQuickPromptMenu){
      const screenWidth = window.innerWidth;
      const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;

      this.menuPosition = {
        top: `${event.pageY - 120}px`,
        left: `${xPosition}px`
      };
      this.mouseDownPos=event.pageY-120;
    }
  }

  onSelectionChange(event,targetId){
    if(!this.is_write_allowed){
      return;
     }
    // console.log("On Selection Change:",event.clientX,event.clientY);
     const textarea = event.target as HTMLTextAreaElement;
    // const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    // console.log("Event:",selectedText);
    this.quickSelectionStartIndex=textarea.selectionStart;
    this.quickSelectionEndIndex=textarea.selectionEnd;
    //const targetId = (event.target as HTMLElement).id;
    //console.log('Target element ID:', targetId);

    this.selectedFeatureData={
      type:"",
      index:0
    };
    
    let clientY=0
    if(this.mouseInitialPosition!=null){
      clientY=this.mouseInitialPosition;
    }
    
    if (this.quickSelectedText.trim().length > 0) {
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    }
  }

  onTextSelect(event,targetId){ 
    console.log("Event:",event);
    if(!this.is_write_allowed){
     return;
    }
     // const targetId = (event.target as HTMLElement).id;
    console.log('Target element ID:', targetId);
 
    this.selectedTargetIdEditor = targetId;
    this.selectedFeatureData={
      type:"",
      index:0
    };
     // console.log('selectedFeatureData', this.selectedFeatureData);
     this.mouseInitialPosition=event.clientY;
     //This code place menu at bottom of selection
     const textarea = event.target as HTMLTextAreaElement;
 
     const selection = window.getSelection();
     if (selection && selection.toString().trim().length > 0) {
         const range = selection.getRangeAt(0);
         const rect = range.getBoundingClientRect();
         let last=event.pageY-120;
         if(last<this.mouseDownPos){
           const screenWidth = window.innerWidth;
           const xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-200)  : 90;
 
         this.menuPosition = {
             top: `${last}px`,
             left: `${xPosition}px`
         };
 
         }
         this.showQuickPromptMenu = true;
     } else {
         this.quickSelectedText="";
         this.showQuickPromptMenu = false;
     }
  }

  closeDetails(): void
    {
      this.selectedFeature = null;
    }

  onChangeTheme(event){
    // console.log(event.value);
    let selectedThemes=event.value;
    this.filterselectedThemes = event.value;
    if(selectedThemes.length){
      this.filterApplied = true;
      this.epicTypes=[];
      for(let epic of this._proposalDetail.epics){
        if(selectedThemes.includes(epic.theme)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic.name };
          });
          this.epicTypes.push(...epics);
        }
      }
    }else{
      if(!this.filterselectedEpics.length && !this.filterselectedThemes.length && !this.filterselectedReleases.length)this.filterApplied = false;
      this.resetToAllEpics();
    }
    
    // this.initProjectStories();
    this.filterRequirements(this.inputFormGroup.get("search").value,
    this.inputFormGroup.get("theme").value,
    this.inputFormGroup.get("epic_name").value,
    this.inputFormGroup.get("release").value
    );

  
  }

  onChangeEpic(event){
    // console.log(event.value);
    this.filterselectedEpics=event.value;
    this.filterselectedReleases=this.inputFormGroup.get("release").value;
    if(this.filterselectedEpics.length || this.filterselectedReleases.length){
      this.filterApplied = true;
    }else{
      if(!this.filterselectedEpics.length && !this.filterselectedThemes.length && !this.filterselectedReleases.length)this.filterApplied = false;
    }
    // this.initProjectStories();
    this.filterRequirements(this.inputFormGroup.get("search").value,
      this.inputFormGroup.get("theme").value,
      this.inputFormGroup.get("epic_name").value,
      this.inputFormGroup.get("release").value
    );
  }

  trackByFn(index: number, item: any): any
  {
      return item.id || index;
  }

  dropTheme(event){
    moveItemInArray(this.requirements, event.previousIndex, event.currentIndex);
    moveItemInArray(this._proposalDetail.epics, event.previousIndex, event.currentIndex);
    this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities});
    if(this._page_id!='proposal' && this.allStories && this.allStories.length)this.updateViewOrder();
  }

  dropEpic(event,themeIndex){
    moveItemInArray(this.requirements[themeIndex].epics, event.previousIndex, event.currentIndex);
    moveItemInArray(this._proposalDetail.epics[themeIndex].epics, event.previousIndex, event.currentIndex);
    this.onSaveThemeAndEpics.emit({epics:this.requirements,businessCapabilities:this._proposalDetail.businessCapabilities});
    if(this._page_id!='proposal' && this.allStories && this.allStories.length)this.updateViewOrder();
  }

  updateViewOrder(): void {  
    this.atlassianService.updateOrder({proposalID:this._proposalDetail._id,delete_userstoryview_order:true}).subscribe(
      response => console.log('Order updated successfully', response),
      error => console.error('Error updating order', error)
    );
  }

  dropSolution(event,themeIndex,epicIndex){
    //console.log("On Drop Solution:",event,themeIndex,epicIndex);
    moveItemInArray(this.requirements[themeIndex].epics[epicIndex].solutions, event.previousIndex, event.currentIndex);
      

  }

  dropRequirement(event,themeIndex,epicIndex){
    //On Drop Requirement
    moveItemInArray(this.requirements[themeIndex].epics[epicIndex].features, event.previousIndex, event.currentIndex);
    this.onSaveThemeAndEpics.emit({epics:this.requirements,businessCapabilities:this._proposalDetail.businessCapabilities});
  }

  onAddNewFeature(){
    this.fuseDrawer.getComponent("themeDrawer").toggle();
  }

  onToggleAccordion(expand){
    // this.expandState++;
    // console.log("Current State:",this.expandState);
    // if(this.expandState>=3){
    //   this.expandState=0;
    // }

    this.expandState = expand;
    //0 =expanded all 1= themes expanded , epics collapsed  2= all collapsed
    let expandThemes=true;
    let expandEpics=true
    if(this.expandState==0){
      expandThemes=true;
      expandEpics=true;
    }else if(this.expandState==1){
      expandThemes=true;
      expandEpics=false;
    }else if(this.expandState==2){
      expandThemes=false;
      expandEpics=false;
    }
    
      this.themesExpanded=this.themesExpanded.map((theme)=>expandThemes);

      let tIndex=0;
    
      for(let theme of this.requirements){
        this.themesExpanded.push(true);
        let eIndex=0;
        for(let epic of theme.epics){
          let key=`${tIndex}-${eIndex}`;
          this.epicsExpanded[key]=expandEpics;
          eIndex++;
        }
        tIndex++;
      }
      this._changeDetectorRef.detectChanges();
      this._changeDetectorRef.markForCheck();
  }

  filterRequirements(
    searchTerm: string,
    selectedThemes: string[],
    selectedEpics: string[],
    selectedReleases: string[]
  ) {
    let filterRequirements = JSON.parse(JSON.stringify(this.original_requirements));
    // console.log(filterRequirements);
    if (searchTerm === "" && !selectedThemes.length && !selectedEpics.length && !selectedReleases.length) {
      // console.log(1);
      this.requirements = JSON.parse(JSON.stringify(filterRequirements));;
      return;
    }
    // console.log(this.requirements);
    // console.log(selectedThemes);
    this.requirements = filterRequirements
      .filter((requirement) => {
        return selectedThemes.length === 0 ||
        selectedThemes.map(theme => theme.trim()).includes(requirement.theme.trim()) ||
        selectedThemes.includes(requirement._id);
      })
      .map((requirement) => {
        // Filter epics within the theme
        const filteredEpics = requirement.epics.filter((epic) => {
          const matchesEpic = selectedEpics.length === 0 || selectedEpics.map(epic => epic.trim()).includes(epic.name.trim());
  
          const matchesSearchTerm =
            searchTerm === "" ||
            epic.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            requirement.theme.toLowerCase().includes(searchTerm.toLowerCase()) ||
            epic.features.some((feature) =>
              feature.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
  
          return matchesEpic && matchesSearchTerm;
        });
  
        return { ...requirement, epics: filteredEpics };
      })
      .filter((requirement) => requirement.epics.length > 0);
  
    if (selectedReleases.length > 0) {
      let themes_epic = [];
      let list_requirements = JSON.parse(JSON.stringify(this.requirements));
  
      list_requirements.forEach((elepics) => {
        let epic_epics = [];
        elepics.epics.forEach((epic) => {
          epic.features = epic.features.filter((feature) =>
            selectedReleases.includes(feature.release)
          );
          epic.solutions = epic.solutions.filter((solution) =>
            selectedReleases.includes(solution.release)
          );
          if (epic.stories && epic.stories.length) {
            epic.stories = epic.stories.filter((story) =>
              selectedReleases.includes(story.release._id?story.release._id:story.release)
            );
          }
          if (epic.features.length || epic.solutions.length || (epic.stories && epic.stories.length)) {
            epic_epics.push(epic);
          }
        });
        if (epic_epics.length) themes_epic.push({ ...elepics, epics: epic_epics });
      });
  
      this.requirements = themes_epic;
    }
  }  

  onClickAddSolution(theme,epic){  
    this._proposalDetail['dialog_type'] = "addfeature";
    this._proposalDetail['selected_theme'] = theme;
    this._proposalDetail['selected_epic'] = epic;

    const dialogRef = this.dialog.open(FeatureComponent,
      {
        panelClass: 'add-feature-dialog',
        disableClose: true,
        data: this._proposalDetail,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      // console.log("on Add Feature: ",result);
      // this.onRefreshProposal.emit();
      this.initUpdateSolutions();
    });

  }

  getThemeFeatures(theme){
    let allFeatures=[];
    for(let epic of theme.epics){
      allFeatures.push(...epic.features);
    }
    return allFeatures;
  }

  onClickAddTheme(){
    this.addThemeDrawer.isEdit=false;
      this.addThemeDrawer.themeForm.get("themeName").setValue("");
      this.addThemeDrawer.themeDrawer.toggle();

  }

  onClickDeleteSolution(featureID,theme,epic){
    this._proposalService.update({id:this._proposalDetail._id,feature_action:'delete',feature_data:{_id:featureID}})
        .subscribe(
          (value) =>
          {
            if(value.status){
              this._proposalDetail.features.forEach((feature, index) => {
                if (feature._id === featureID && feature.theme==theme && feature.epic==epic) {
                  this._proposalDetail.features.splice(index, 1);
                  this.initSolutions();
                }
              });
            }
          
            });

  }

  onDeleteTheme(requirement:any){
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {message:"Are you sure you want to delete this theme? All associated epics, requirements, solutions, and insights will also be removed."},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.yes===true){
        for(let i=0;i<this._proposalDetail.epics.length;i++){
          if(this._proposalDetail.epics[i].theme==requirement.theme){
            this._proposalDetail.epics.splice(i,1);
            this.themeTypes.splice(i,1);
            let capIndex=this._proposalDetail.businessCapabilities.findIndex((cap)=>cap==requirement.theme);
            this._proposalDetail.businessCapabilities.splice(capIndex,1);
            this._proposalDetail.ValueChanged = {oldTheme:requirement.theme,newTheme:''};
            break;
          }
        }
        this.resetRequirements(false);
        // this.initProjectStories();
        this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities,ValueChanged:this._proposalDetail.ValueChanged});
      }
    });
  }

  onEditTheme(theme:any){
    this.addThemeDrawer.isEdit=true;
    let index=this._proposalDetail.epics.findIndex((req)=>req.theme==theme.theme);
    this.addThemeDrawer.selectedIndex=index;
    this.addThemeDrawer.themeForm.get("themeName").setValue(theme.theme);
    this.addThemeDrawer.themeDrawer.toggle();
  }

  onClickAddEpic(){
      this.addEpicDrawer.isEdit=false;
      this.addEpicDrawer.themeForm.get("epicName").setValue("");
      this.addEpicDrawer.themeForm.get("theme").setValue("");
      this.addEpicDrawer.epicDrawer.toggle();
  }

  onClickAddFeature(theme,epic){
    // console.log(this.addFeature);
    this.addFeature.isEdit=false;
    this.addFeature.selectedIndex=-1;
    this.addFeature.themeForm.get("feature").setValue("");
    this.addFeature.themeForm.get("theme").setValue(theme);
    this.addFeature.filterEpics();
    this.addFeature.themeForm.get("epic").setValue(epic);
    this.addFeature.themeForm.get("release").setValue("");
    this.addFeature.themeDrawer.toggle();
  }

  onSaveTheme(theme){
    if(theme.isEdit){
        let oldTheme=this._proposalDetail.epics[theme.index].theme;
        let capabilityIndex=this._proposalDetail.businessCapabilities.findIndex((cap)=>cap==oldTheme);
        this._proposalDetail.businessCapabilities[capabilityIndex]=theme.theme;
        this._proposalDetail.ValueChanged = {oldTheme:oldTheme,newTheme:theme.theme};

      this._proposalDetail.epics[theme.index].theme=theme.theme;
      this._proposalDetail.epics[theme.index].pendingJiraUpdates=true;

      let themeTypeIndex=this.themeTypes.findIndex((tType)=>tType.value==oldTheme);
      if(themeTypeIndex>=0){
        this.themeTypes[themeTypeIndex].value=theme.theme.trim();
        this.themeTypes[themeTypeIndex].label=theme.theme.trim();
      }
    }else{
      this._proposalDetail.epics.push({theme:theme.theme.trim(),epics:[]});
      this.themeTypes.push({value:theme.theme.trim(),label:theme.theme.trim()});
      this._proposalDetail.businessCapabilities.push(theme.theme);
      this._proposalDetail.ValueChanged = {oldTheme:'',newTheme:theme.theme.trim()};
    }
    this.resetRequirements(false);
    this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities,ValueChanged:this._proposalDetail.ValueChanged});
  }

  onEditEpic(theme,epic){
    this.addEpicDrawer.isEdit=true;
    this.addEpicDrawer.oldEpic=epic;
    this.addEpicDrawer.themeForm.get("epicName").setValue(epic);
    this.addEpicDrawer.themeForm.get("theme").setValue(theme);
    this.addEpicDrawer.epicDrawer.toggle();
  }
  
  onDeleteEpic(theme,selectedEpic){
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {message:"Are you sure you want to delete this epic? All associated features, and, solutions will also be removed."},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.yes===true){
        for(let i=0;i<this._proposalDetail.epics.length;i++){
          if(this._proposalDetail.epics[i].theme==theme){
            let epicIndex=this._proposalDetail.epics[i].epics.findIndex((epic)=>epic.name==selectedEpic);
            if(epicIndex>=0){
              this._proposalDetail.epics[i].epics.splice(epicIndex,1);
            }
            break;
          }
        }
        this.resetRequirements(false);
        this.onSaveThemeAndEpics.emit({epics:this._proposalDetail.epics,businessCapabilities:this._proposalDetail.businessCapabilities});
      }
    });
  }

  onSaveEpic(event){
    if(event.isEdit){
      this._proposalDetail.epics.forEach(theme => {

        if(theme.theme==event.theme){
          let epicIndex=theme.epics.findIndex((ep)=>ep.name==event.oldEpic);
          if(epicIndex>=0){
            theme.epics[epicIndex].name=event.epic;
            this._proposalDetail.ValueChanged = {oldEpic:event.oldEpic,newEpic:event.epic};
            theme.epics[epicIndex].pendingJiraUpdates=true;
          }
        }
      });
    }else{
    this._proposalDetail.epics.forEach(theme => {

      if(theme.theme==event.theme){
        theme.epics.push({name:event.epic,features:[]});

      }

    });
  }
    
    this.onChangeTheme({value:this.inputFormGroup.get("theme").value});

    this.resetRequirements(false);
    this.onSaveEpics.emit({epics:this._proposalDetail.epics,ValueChanged:this._proposalDetail.ValueChanged});
  }

  onSaveFeature(event:any){
    if(!event.isEdit){
      //Add New Mode
    this._proposalDetail.epics.forEach(theme => {
      if(theme.theme==event.theme){
        theme.epics.forEach((epic)=>{
          if(epic.name==event.epic){
            if(epic.features){
            epic.features.push({title:event.feature,release:event.release});
            }else{
              epic.features=[{title:event.feature,release:event.release}];
            }
          }
        });
      }
    });
  }else{
      //Edit Mode
      if(event.index!=-1){
        this._proposalDetail.epics.forEach(theme => {
          if(theme.theme==event.theme){
  
            theme.epics.forEach((epic)=>{
  
              if(epic.name==event.epic){
                
                epic.features[event.index]={title:event.feature,release:event.release};
                
              }
  
            });
  
          }
        });
      }
  }
  this._changeDetectorRef.detectChanges();
  this._changeDetectorRef.markForCheck();
  this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onDeleteFeatureCallback(event){
    this.onDeleteFeature(event.theme,event.epic,event.feature,event.index);
  }

  onDeleteFeature(selectedTheme,selectedEpic,feature,index){

    this._proposalDetail.epics.forEach(theme => {
      if (theme.theme == selectedTheme) {
          theme.epics.forEach((epic) => {
              if (epic.name == selectedEpic) {
                  if (epic.features && index !== -1 && index < epic.features.length) {

                          epic.features.splice(index, 1);
                  }
              }
          });
      }
  });
  this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onEditFeature(theme,epic,feature,index){
    this.addFeature.isEdit=true;
    this.addFeature.selectedIndex=index;
    this.addFeature.themeForm.get("feature").setValue(feature.title);
    this.addFeature.themeForm.get("theme").setValue(theme);
    this.addFeature.themeForm.get("release").setValue(feature.release);
    this.addFeature.filterEpics();
    this.addFeature.themeForm.get("epic").setValue(epic);
    this.addFeature.themeDrawer.toggle();
    // console.log(this.addFeature);
  }

  initProposalInsights(){
    this._proposalService.getProposalInsights(this._proposalDetail._id).subscribe((response)=>{
      if(response.success==true){
        this.proposalInsights=response.data;
      }
    });
  }

  onRequirementReleaseSelection(event,theme,epic,feature,index){
    this._proposalDetail.epics.forEach(eachtheme => {
      if(eachtheme.theme==theme){
        eachtheme.epics.forEach((eachepic)=>{
          if(eachepic.name==epic){   
            eachepic.features[index].release=event.value; 
          }
        });

      }
    });
    this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onSolutionReleaseSelection(event,feature,index){
    feature['release'] = event.value;
    this.updateSelectedFeature(feature);
  }

  async onRegenerateTheme(theme,themeIndex){

    // console.log("on Regenerate Themes");
    if(theme.isRegenerating){
      return;
    }

    this.regenDrawer.drawer.toggle();
    
    this.regenDrawer.initRegenView(this.proposalInsights,theme,null,0);

    this.themesExpanded[themeIndex]=true;

  }

  async onRegenerateEpic(theme,epic,themeIndex,epicIndex){

    if(epic.isRegenerating){
      return;
    }

    this.regenDrawer.drawer.toggle();
    
    this.regenDrawer.initRegenView(this.proposalInsights,theme,epic,1);

    this.epicsExpanded[this.getEpicKey(themeIndex,epicIndex)]=true;

  }

  createQuestionGroup(question): FormGroup {
    return this._formBuilder.group({
      title: new FormControl(question.title || ''),
      notes: new FormControl(question.notes || ''),
      capability: new FormControl(question.capability || '')
    });
  }

  async onSelectInsight(theme, epic) {
    let newQuestions = [];
    // const newQuestions = [
    //   { title: "Question 1", notes: "Answer 1", capability: "Capability 1" },
    //   { title: "Question 2", notes: "Answer 2", capability: "Capability 2" }
    // ];

    // this.questionsArray.clear();
    // newQuestions.forEach(q => this.questionsArray.push(this.createQuestionGroup(q)));
    // this.insightDrawer.open();
    if (this.proposalInsights.length) {
      let insight = this.proposalInsights.filter(el => el.capability === theme);
      // console.log(insight);

      this.questionsArray.clear(); 
      if (insight.length && insight[0]['questions']) {
        this.selectedInsight = insight[0];

        for (let question of insight[0]['questions']) {
          if (epic != null && question['epic'] !== epic.name) {
            continue;
          }
          const plainText = await this.processNotes(question.notes);
          newQuestions.push({title:question.title?question.title:'',notes:plainText?plainText:'',capability:question.capability?question.capability:''});
          // console.log(this.questionsArray.value);
        }
        if(newQuestions.length){
          newQuestions.forEach(q => this.questionsArray.push(this.createQuestionGroup(q)));
        }
      }
    }
    this.insightDrawer.open();
  }
  async processNotes(notes: string): Promise<string> {
    const parsedNotes = await marked.parse(notes);
    return this.stripHtml(parsedNotes);
  }

  // async onSelectInsight(theme,epic){
  //   console.log(theme);console.log(this.proposalInsights.length);
  //   if(this.proposalInsights.length){
  //     let insight = this.proposalInsights.filter(el=>el.capability==theme);
  //     console.log(insight);
  //     // return;
  //     this.questionsArray.clear();
  //     this.answerEditors_readonly = [];
  //     if(insight.length && insight[0]['questions']){
  //       this.selectedInsight=insight[0];
  //       for (let question of insight[0]['questions']) {
  //         if(epic!=null){
  //           if(question['epic']!=epic.name){
  //           continue;
  //           }
  //         }
  //         let parsedNotes = await marked.parse(question.notes);
  //         const plainText = this.stripHtml(parsedNotes);
  //         // const newEditor2 = new Editor({
  //         //   content: parsedNotes,
  //         //   extensions: [
  //         //     StarterKit.configure({
  //         //       bulletList: {
  //         //         keepMarks: true,
  //         //         keepAttributes: false, 
  //         //       },
  //         //       orderedList: {
  //         //         keepMarks: true,
  //         //         keepAttributes: false, 
  //         //       },
  //         //     }),
  //         //     Underline,
  //         //     TextStyle,
  //         //     Color,
  //         //     Placeholder.configure({
  //         //       placeholder: 'Describe your current process or how you envision it.',
  //         //     })
  //         //   ],
  //         //   editable: false,
  //         //   enablePasteRules: false,
  //         //   parseOptions: {
  //         //     preserveWhitespace: "full",
  //         //   },
  //         //   editorProps: {
  //         //     attributes: {
  //         //       class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
  //         //       spellCheck: 'false',
  //         //     },
  //         //   }
  //         // });
  //         // this.answerEditors_readonly.push(newEditor2);
          
  //         this.questionsArray.push(this._formBuilder.group({
  //           title     : [question.title || ''],
  //           notes     : [plainText || ''],
  //           capability: [question.capability || '']
  //         }));console.log(this.questionsArray.value);
  //       };
  //     }
  //   }console.log(this.questionsArray.value);
  //   this.paymentDetails$ = this.questionsArray.valueChanges;
  //   this._changeDetectorRef.detectChanges();
  //   this._changeDetectorRef.markForCheck();
  //   setTimeout(()=>{
  //     this.insightDrawer.open();
  //   },500);
    
  // }

  // onCompleteQuickAction(newValue){
  //   this.showQuickPromptMenu=false;
  //   this.isQuickMenuHighlighted=false;
  //   document.getSelection().removeAllRanges();   
  // }

  selectedIndex: number;
  onCompleteQuickAction(newValue){
    // console.log("Value to update:",newValue);
    // console.log("Selection:",this.quickSelectedText);
    // console.log("selectedIndex:",this.selectedIndex,this.selectedTargetIdEditor);
    // console.log(this.quickSelectionStartIndex,this.quickSelectionEndIndex);
    
    let obj = {id: this._proposalDetail._id};
    if(this.selectedIndex==0 || !this.selectedIndex){
      if(this.selectedTargetIdEditor=='description-llsSummary'){
        const { from, to } = this.llsSummaryCombinedEditor.state.selection;
        const parser = ProseMirrorDOMParser.fromSchema(this.llsSummaryCombinedEditor.schema);
        const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
        // Perform the replacement
        let tr: Transaction = this.llsSummaryCombinedEditor.state.tr.replaceWith(from, to, replacementNode);
        // Calculate the new positions
        const newTo = from + replacementNode.size;
        // Dispatch the replacement transaction
        this.llsSummaryCombinedEditor.view.dispatch(tr);
        this.llsSummaryCombinedEditor.chain().focus().setTextSelection({ from, to: newTo }).run();
        this.showQuickPromptMenu=false;
        
      }
    }else if(this.selectedIndex==1){
      
    }
    this.showQuickPromptMenu=false;
    this.isQuickMenuHighlighted=false;
    document.getSelection().removeAllRanges();   
  }

  stripHtml(html: string): string {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent || '';
  }

  regenOpenedChanged(event){
    // console.log("Regenerate Drwaer opend event");
    if(event===false){
      this.clear();
    }
  }

  openedChanged(event){
    if(event===false){
      this.clear();
    }
  }

  openDialog(type,theme,epic) {
    this._proposalDetail['dialog_type'] = type;
    this._proposalDetail['selected_theme'] = theme;
    this._proposalDetail['selected_epic'] = epic;
    const dialogRef = this.dialog.open(FeatureComponent,
      { 
        panelClass: 'add-feature-dialog',
        disableClose: true,
        data: this._proposalDetail,
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      this.onFeatureUpdate.emit({refresh:true});
    });
  }

  onClickJiraIntegrations(){
    const dialogRef = this.dialog.open(JiraIntegrationComponent,{ panelClass: 'custom-dialog-container',disableClose: true,data:{proposal:this._proposalDetail,proposalID:this._proposalDetail._id}  });
    dialogRef.afterClosed().subscribe(result => {
      if(result.saved){
        this.ngOnInit();
      }
    });
  }

  clear(){
    //reset values
    this.onCloseDrawer();
  }

  onCloseDrawer(){
    this.selectedInsight=null;
    this.insightDrawer.close();
    this.lowlevelDrawer.close();
    this.solutionDrawer.close();
  }

  onThemeUpdate(event){
    console.log(event);
  }
    
  onUpdateStories(pull:boolean){
    // console.log(this.inputFormGroup.get("theme").value);
    // console.log(this.inputFormGroup.get("epic_name").value);
    this.initProjectStories();
    // this.onFeatureUpdate.emit({refresh:true});
    if(this.inputFormGroup.get("theme").value || this.inputFormGroup.get("epic_name").value){
      this.filterRequirements(this.inputFormGroup.get("search").value,
      this.inputFormGroup.get("theme").value,
      this.inputFormGroup.get("epic_name").value,
      this.inputFormGroup.get("release").value
    );
    }
  }

  getSolutionCategoryForRequirement(requirementID){
    const solutionCategory = this._proposalDetail.features.find(feature => feature.requirementID && feature.requirementID === requirementID);
    if(solutionCategory){
      return solutionCategory.solutionCategories.join(",");
    }

    return "";
  }

  getEpicStories(theme:string,epic:string){
    let epicStories=[];
    for(let eachstory of this.allStories){
      if(eachstory.theme==theme && eachstory.epic==epic){
        epicStories.push(eachstory);
      }
    }
    // epicStories = epicStories.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    // epicStories=epicStories.reverse();
    epicStories = epicStories.sort((a, b) => {
      if (a.workspaceview_order !== undefined && b.workspaceview_order !== undefined) {
          return a.workspaceview_order - b.workspaceview_order;
      }
      if (a.workspaceview_order !== undefined) return -1;
      if (b.workspaceview_order !== undefined) return 1;
      // If workspaceview_order is not present, sort by created date (newest first)
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    });
  
    return epicStories;
  }

  getSyncLabel(){
    if(this._page_id=="testing"){
      return "Test Cases"
    }

    return "Stories";
  }

  getNotSyncedCount(){
    if(this._page_id=="testing"){
      return this.notSyncedTestCases;
    }else{
    let notSynced=0;
    if(!this.allStories){
      return 0;
    }
    for(let story of this.allStories){
      if(story.jiraSynced==false || story.pendingJiraUpdates){
        notSynced++;
      }
    }

    if(notSynced==0){
      this.isSyncingProject=false;
    }

    return notSynced;
  }
  }

  getIntegrationStatus(){
    if(this._proposalDetail && this._proposalDetail.integrations && this._proposalDetail.integrations.jira && this._proposalDetail.integrations.jira.active==true){
      return true;
    }

    return false;
  }

  onSyncProjectWithJira(){
    if(this.isSyncingProject){
      return;
    }
    this.isSyncingProject=true;

    if(this._page_id=="testing"){
      this.atlassianService.jiraSync({proposalID:this._proposalDetail._id,testPlanID:this._testplanDetail._id,type:"testcases"}).subscribe((response)=>{
        if(response.success){
         
        }
      });
    }else{
      this.atlassianService.syncProjectWithJira(this._proposalDetail._id).subscribe((response)=>{
        if(response.success){
         
        }
      });
    }

    
  }

  onSaveBuildReceived(saveBuild: any) {
    const themeIndex = this.requirements.findIndex(el => el._id === saveBuild.themeID);
    // console.log('themeIndex',themeIndex);
    if (themeIndex !== -1) {
      const epicIndex = this.requirements[themeIndex].epics.findIndex(el => el._id === saveBuild.epicID);
      // console.log('epicIndex',epicIndex);
      if (epicIndex !== -1) {
        this.requirements[themeIndex].epics[epicIndex].refreshTrigger = saveBuild.epicID; // Timestamp to trigger change detection
            
        this.requirements[themeIndex].epics = [...this.requirements[themeIndex].epics];
        this._changeDetectorRef.detectChanges();
        this._changeDetectorRef.markForCheck();
        // console.log(`Re-triggered app-configuration for themeID: ${saveBuild.themeID}, epicID: ${saveBuild.epicID}`);
      }
    }
  }

  onSaveStoryReceived(saveBuild: any) {
    const themeIndex = this.requirements.findIndex(el => el.theme === saveBuild.theme);
    if (themeIndex !== -1) {
        const epicIndex = this.requirements[themeIndex].epics.findIndex(el => el.name === saveBuild.epic);
        // console.log('epicIndex', epicIndex);

        if (epicIndex !== -1) {
          if(saveBuild.updateType=='newTheme')
            this.requirements[themeIndex].epics[epicIndex].stories = [
                ...this.requirements[themeIndex].epics[epicIndex].stories,
                saveBuild
            ];
          else if(saveBuild.updateType=='oldTheme')
            this.requirements[themeIndex].epics[epicIndex].stories[saveBuild.storyindex] = saveBuild;
            
      this._changeDetectorRef.detectChanges();
      this._changeDetectorRef.markForCheck();
        }
    }

    const themeOriginalIndex = this.original_requirements.findIndex(el => el.theme === saveBuild.theme);
    if (themeIndex !== -1) {
        const epicOriginalIndex = this.original_requirements[themeOriginalIndex].epics.findIndex(el => el.name === saveBuild.epic);

        if (epicOriginalIndex !== -1) {
          if(saveBuild.updateType=='newTheme')
            this.original_requirements[themeOriginalIndex].epics[epicOriginalIndex].stories = [
                ...this.original_requirements[themeOriginalIndex].epics[epicOriginalIndex].stories,
                saveBuild
            ];
          else if(saveBuild.updateType=='oldTheme')
            this.original_requirements[themeOriginalIndex].epics[epicOriginalIndex].stories[saveBuild.storyindex] = saveBuild;

          this._changeDetectorRef.detectChanges();
          this._changeDetectorRef.markForCheck();
        }
    }
  }


  onRegenerateSolutions(proposalID,themeID,epicID,epic){


    if(epic.isRegenerating){
      return;
    }
    epic.isRegenerating=true;

    // console.log("On Regenerate Epic Solutions:",proposalID,themeID,epicID);

    this.websocketService.sendRegenerateSolutionCommand(proposalID,themeID,epicID);
  }

  serchSol(event: MouseEvent): void {
    event.stopPropagation(); // Prevent triggering outside click logic
    this.searchSolAct = !this.searchSolAct;

    if (this.searchSolAct) {
      this.attachOutsideClickListener();
    } else if (this.unlistenClickOutside) {
      this.unlistenClickOutside();
    }
  }

  private attachOutsideClickListener(): void {
    this.unlistenClickOutside = this.renderer.listen('document', 'click', (event: Event) => {
      const clickedInside = this.el.nativeElement.contains(event.target);
      if (!clickedInside) {
        this.searchSolAct = false;
        this.unlistenClickOutside();
      }
    });
  }

  onClickGenerateLLS(themeID,epic){
    //console.log("On Generate LLS ",themeID,epic._id);
    this.progressTracker.saveProgressEvent("lls",epic._id,"");
    epic['isGeneratingLLS']=true;
    this._proposalService.generateEpicLowLevelSolution(this._proposalDetail._id,themeID,epic._id,((this._buildDetail && this._buildDetail._id)?this._buildDetail._id:'')).subscribe((response)=>{
      if(response.success){
      }
    });
  }

  onClickRegenerateWorkbook(themeID,epic){
    
    epic['isRegeneratingWorkbook']=true;

    this.progressTracker.saveProgressEvent("workbook",epic._id,"");

    this._proposalService.regenerateEpicConfigurationWorkbook(this._proposalDetail._id,((this._buildDetail && this._buildDetail._id)?this._buildDetail._id:''),themeID,epic._id,this._proposalDetail.proposal_meta.technology,this._proposalDetail.proposal_meta.product).subscribe((response)=>{
      if(response.success){
      }
    });

  }

  onDivClick(event: MouseEvent): void {
    event.stopPropagation();
  }
  onSaveLowLevelSolutionSummary(){
    this.isSavingLLSSummary=true;

    this._proposalService.saveEpicLowLevelSolutionSummary(this._proposalDetail._id,this.selectedLowLevelSolution.themeID,this.selectedLowLevelSolution.epicID,this.selectedLowLevelSolution.selectedEpic.llsSummaryCombined).subscribe((response)=>{
      if(response.success){
        this.isSavingLLSSummary=false;
        this.selectedLowLevelSolution.epic.llsSummaryCombined=this.selectedLowLevelSolution.selectedEpic.llsSummaryCombined;
        
        this.showFlashMessage('success');
      }else{
        this.showFlashMessage('error');
        
      }
    });
  }

  openedChangedLLS(event){
    this.isLLSDrawerOpen = event;
  }

  openedChangedSolution(event){
    this.isSolutionDrawerOpen = event;
  }

  onEditLowLevelSolutionSummary(themeID,epicID,summary,solutionCategories,assumptions,epic){
    this.selectedLowLevelSolution={
      themeID,
      epicID,
      summary,
      assumptions,
      solutionCategories,
      epic,
      selectedEpic:{...epic}
    }
    if (this.llsSummaryCombinedEditor && this.selectedLowLevelSolution.selectedEpic.llsSummaryCombined) {
      this.llsSummaryCombinedEditor.commands.setContent(this.selectedLowLevelSolution.selectedEpic.llsSummaryCombined, false);
    }
    this.lowlevelDrawer.open();
  }

  onRegeneratingFunctionCall(myIndex){
    this._proposalService.incrementCurrentLoadingEvent(myIndex);
  }
} 
