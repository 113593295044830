<div class="divide-x" data-test-id="bubble-menu">
    <!-- <button (click)="editor.chain().focus().toggleBold().run()" [disabled]="!editor.can().chain().focus().toggleBold().run()" [ngClass]="{'is-active': editor.isActive('bold')}">
        Bold
    </button>
    <button (click)="editor.chain().focus().toggleItalic().run()" [disabled]="!editor.can().chain().focus().toggleItalic().run()" [ngClass]="{'is-active': editor.isActive('italic')}">
        Italic
    </button>
    <button (click)="editor.chain().focus().toggleStrike().run()" [disabled]="!editor.can().chain().focus().toggleStrike().run()" [ngClass]="{'is-active': editor.isActive('strike')}">
        Strike
    </button> -->
    <!-- <app-quick-prompt (mouseenter)="onMouseEnterQuickPrompt()" (mouseleave)="onMouseLeaveQuickPrompt()" (onCompleteQuickAction)="onCompleteQuickAction($event)" [quickActionSelection]="quickSelectedText" [menuPosition]="{top: '0px', left: '0px'}" >
    </app-quick-prompt> -->

    <div class="edbox shadow2 toolbar">
        <div class="aiicon">
            <!-- <app-quick-prompt (mouseenter)="onMouseEnterQuickPrompt()" (mouseleave)="onMouseLeaveQuickPrompt()" (onCompleteQuickAction)="onCompleteQuickAction($event)" [quickActionSelection]="quickSelectedText" [menuPosition]="{top: '0px', left: '0px'}" >
            </app-quick-prompt> -->
            <div [ngStyle]="{'top': menuPosition.top, 'left': menuPosition.left}" class="quickPromptPopup" >
                <div class="flex items-center">
                    <img class="w-3" src="assets/icons/aiicon1.svg">
                    <button (click)="onClickMenuButton();" mat-button [matMenuTriggerFor]="menu" class="custom-purple-text">
                        <div class="">Rewrite with AI</div>
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: -8px;margin-top: -6px;">
                            <path d="M7.11328 10L12.1133 15L17.1133 10H7.11328Z" fill="#4F46E5" fill-opacity="0.87"/>
                        </svg>
                    </button>
                    <mat-menu #menu="matMenu" (closed)="closeaimenu($event)">
                            <button *ngFor="let quickPrompt of promptTemplates" mat-menu-item class="mr-8 flex aimenubt" (click)="onSelectQuickPrompt(quickPrompt)">
                                <img class="h-5 w-5" [src]="quickPrompt.icon">
                                        <div class="ml-3">{{quickPrompt.title}}</div></button>
                    </mat-menu>
                    
                    </div>
                    <div *ngIf="showCustomPrompt" class="textbox rounded-lg  bg-white flex flex-col justify-between mt-2 overflow-clip">
                    <textarea [(ngModel)]="customPrompt" placeholder="Write Custom Prompt" class="p-2 textareainp" min="5" max="8"></textarea>
                    <button 
                        *ngIf="!isLoading" 
                        (click)="onApplyCustomPrompt()" 
                        [disabled]="isLoading||customPrompt==''" 
                        [ngClass]="{'bg-[#4F45E4] text-white': (!isLoading&&customPrompt!=''), 'bg-gray-400 text-gray-700': isLoading||customPrompt==''}" 
                        class="w-30 h-7 ml-3 mb-1 rounded-lg ">
                        Apply
                    </button>
                </div>
                    <div *ngIf="selectedQuickPrompt!=null" class="textbox rounded-lg  bg-white  flex flex-col justify-between mt-2 relative" [ngStyle]="{'width':boxWidth}">
                    <!-- <textarea #textArea  class="p-2 textareainp" [(ngModel)]="quickPromptOutput" min="5" max="8"  ></textarea> -->
                    <tiptap-editor #textArea  class="textareainp" [id]="'descriptionreadonly-'+index" [editor]="editor_prompt" [(ngModel)]="quickPromptOutput" min="5" max="8" ></tiptap-editor>
                    <div class=" bg-[#F1F5F9] rounded-lg w-full flex items-center relative">
                        <!-- <img class="w-4 h-4 ml-3" src="assets/icons/aiicon1.svg">  -->
                        <!-- <div *ngIf="isLoading"  class="custom-purple-text text-md mr-3 ml-3   flex items-center">
                            <app-loading-dots></app-loading-dots> <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
                        </div> -->
                        <div *ngIf="showQuickActions()" class="bg-white shadow-sm shadow-gray-400 absolute quickactTop" >
                            <button mat-menu-item  *ngFor="let quickPromptAction of quickPromptActions" (click)="onSelectionQuickAction(quickPromptAction)" class="aimenubt">
                                    <div class="flex items-center">
                                        <img class="h-5 w-5" [src]="quickPromptAction.value">
                                        <div class="text-lg ml-3">{{quickPromptAction.label}}</div>
                                    </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div *ngIf="!isAIVisible" class="flex">
            <div class="divider"></div>
            <div class="icon2">
                <button  mat-button [matMenuTriggerFor]="headingmenu" class="heading-menu">
                    <div class="">Text</div>
                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: -8px;margin-top: -6px;">
                        <path d="M7.11328 10L12.1133 15L17.1133 10H7.11328Z" fill="#787774" fill-opacity="0.87"/>
                    </svg>
                </button>
                <mat-menu #headingmenu="matMenu" >
                    <div class="dropTab" >
                        <button class="" (click)="editor.chain().focus().setParagraph().run()" [ngClass]="{'is-active': editor.isActive('paragraph')}">
                            Paragraph
                        </button>
                    </div>
                    <div class="dropTab" >
                        <button class="" (click)="editor.chain().focus().toggleHeading({ level: 1 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 1 })}">
                            Heading 1
                        </button>
                    </div>
                    <div class="dropTab">
                        <button class="" (click)="editor.chain().focus().toggleHeading({ level: 2 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 2 })}">
                            Heading 2
                        </button>
                    </div>
                    <div class="dropTab">
                        <button class="" (click)="editor.chain().focus().toggleHeading({ level: 3 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 3 })}">
                            Heading 3
                        </button>
                    </div>
                    <div class="dropTab">
                        <button class="" (click)="editor.chain().focus().toggleHeading({ level: 4 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 4 })}">
                            Heading 4
                        </button>
                    </div>
                    <div class="dropTab">
                        <button class="" (click)="editor.chain().focus().toggleHeading({ level: 5 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 5 })}">
                            Heading 5
                        </button>
                    </div>
                    <div class="dropTab">
                        <button class="" (click)="editor.chain().focus().toggleHeading({ level: 6 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 6 })}">
                            Heading 6
                        </button>
                    </div>
                </mat-menu>
            </div>
            <div class="divider"></div>
            <div class="icon" [ngClass]="{ 'activeTab': editor.isActive('italic') }">
                <button type="button" class="px-1 hover:text-white" (click)="editor.chain().focus().toggleItalic().run()" >
                    <svg role="graphics-symbol" viewBox="0 0 16 16" class="italic" style="width: 16px; height: 16px; display: block; fill: inherit; flex-shrink: 0;"><g><path d="M5.02 13.014c-.397 0-.67-.24-.67-.63 0-.382.266-.614.663-.614H6.42l1.593-7.404H6.612c-.396 0-.67-.239-.67-.629 0-.383.267-.615.663-.615h4.382c.397 0 .663.232.663.615 0 .39-.273.63-.67.63H9.586l-1.6 7.403h1.409c.396 0 .663.232.663.615 0 .39-.274.629-.67.629H5.02z"></path></g></svg>
                </button>
            </div>
            <div class="icon" [ngClass]="{ 'activeTab': editor.isActive('bold') }">
                <button type="button" class="px-1 hover:text-white" (click)="editor.chain().focus().toggleBold().run()"  >
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"
                        />
                    </svg> -->
                    <svg role="graphics-symbol" viewBox="0 0 16 16" class="bold"  style="width: 16px; height: 16px; display: block; fill: inherit; flex-shrink: 0;">
                        <g><path d="M5.067 13.014c-.772 0-1.223-.451-1.223-1.238V4.36c0-.786.45-1.237 1.223-1.237h3.5c1.949 0 3.131.943 3.131 2.488 0 1.1-.793 1.97-1.948 2.12v.061c1.401.068 2.4 1.046 2.4 2.386 0 1.716-1.347 2.837-3.466 2.837H5.067zm1.238-5.81h1.34c1.025 0 1.633-.459 1.633-1.225 0-.738-.553-1.168-1.483-1.168h-1.49v2.392zm0 4.142h1.6c1.148 0 1.742-.472 1.742-1.347 0-.854-.642-1.32-1.804-1.32H6.305v2.667z"></path></g>
                    </svg>
                </button>
            </div>
            <div class="icon" [ngClass]="{ 'activeTab': editor.isActive('code') }">
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleCode().run()" [disabled]="!editor.can().chain().focus().toggleCode().run()" [ngClass]="{'is-active': editor.isActive('code')}">
                    <svg role="graphics-symbol" viewBox="0 0 16 16" class="code" style="width: 16px; height: 16px; display: block; fill: inherit; flex-shrink: 0;"><g><path d="M.056 8.122a.72.72 0 01.07-.306.91.91 0 01.204-.285l3.39-3.05a.745.745 0 01.542-.215.675.675 0 01.505.236c.14.158.202.337.188.537a.737.737 0 01-.264.51L1.855 8.122l2.836 2.573a.737.737 0 01.264.51.716.716 0 01-.188.537.694.694 0 01-.505.242.766.766 0 01-.543-.22L.33 8.708a.87.87 0 01-.204-.274.756.756 0 01-.07-.312zm6.43 5.21a.675.675 0 01-.446-.355.782.782 0 01-.027-.58l2.605-8.942c.068-.226.186-.384.354-.473a.714.714 0 01.559-.054.678.678 0 01.446.344.69.69 0 01.048.553l-2.621 8.98a.738.738 0 01-.35.463.711.711 0 01-.569.064zm9.453-5.21a.695.695 0 01-.065.312.869.869 0 01-.204.274l-3.39 3.056a.773.773 0 01-.547.22.677.677 0 01-.5-.242.707.707 0 01-.193-.537.738.738 0 01.263-.51l2.841-2.573-2.84-2.573a.738.738 0 01-.264-.51.7.7 0 01.193-.537.659.659 0 01.5-.236.75.75 0 01.548.214l3.389 3.051a.91.91 0 01.204.285c.047.1.068.202.064.306z"></path></g></svg>
                </button>
            </div>

            <div class="icon" [ngClass]="{ 'activeTab': editor.isActive('underline') }">
                <button (click)="editor.chain().focus().toggleUnderline().run()" [disabled]="!editor.can().chain().focus().toggleUnderline().run()" [ngClass]="{'is-active': editor.isActive('underline')}">
                    <svg role="graphics-symbol" viewBox="0 0 16 16" class="underline" style="width: 16px; height: 16px; display: block; fill: inherit; flex-shrink: 0;"><g><path d="M8 13.191c-.843 0-1.572-.152-2.188-.458a3.46 3.46 0 01-1.421-1.292c-.333-.551-.5-1.191-.5-1.92V3.724c0-.242.071-.431.213-.568.14-.141.33-.212.567-.212.246 0 .44.071.58.212.147.137.22.326.22.568v5.653c0 .488.098.914.294 1.278.2.36.49.64.868.841.378.2.834.3 1.367.3.798 0 1.417-.22 1.86-.662.446-.442.67-1.028.67-1.757V3.724c0-.242.07-.431.211-.568.142-.141.33-.212.568-.212.246 0 .44.071.58.212.147.137.22.326.22.568V9.52c0 .729-.167 1.369-.5 1.92-.332.552-.809.982-1.428 1.292-.616.306-1.342.458-2.181.458zm-3.623 2.174a.487.487 0 01-.349-.136.464.464 0 01-.136-.342c0-.137.045-.25.136-.342a.487.487 0 01.349-.137h7.24c.14 0 .257.046.348.137a.453.453 0 01.143.342c0 .136-.048.25-.143.341a.472.472 0 01-.349.137H4.377z"></path></g></svg>
                </button>
            </div>
            <div class="icon" [ngClass]="{ 'activeTab': editor.isActive('strike') }">
                <button (click)="editor.chain().focus().toggleStrike().run()" [disabled]="!editor.can().chain().focus().toggleStrike().run()" [ngClass]="{'is-active': editor.isActive('strike')}">
                    <svg role="graphics-symbol" viewBox="0 0 16 16" class="strikethrough" style="width: 16px; height: 16px; display: block; fill: inherit; flex-shrink: 0;"><g><path d="M7.973 13.191a5.695 5.695 0 01-1.75-.253 3.694 3.694 0 01-1.306-.71 2.376 2.376 0 01-.71-1.101 1.514 1.514 0 01-.062-.424c0-.205.061-.369.184-.492a.71.71 0 01.513-.185c.155 0 .28.034.376.103a.92.92 0 01.273.328c.128.296.31.554.547.772.237.215.52.379.848.493.328.114.69.17 1.087.17.45 0 .847-.066 1.19-.198.345-.136.614-.321.806-.553.196-.237.294-.506.294-.807 0-.383-.15-.688-.451-.916-.301-.232-.796-.43-1.484-.595L7.043 8.53c-.939-.21-1.636-.528-2.092-.957-.451-.433-.677-.998-.677-1.695 0-.574.158-1.082.472-1.524.32-.443.757-.787 1.313-1.033.556-.25 1.191-.376 1.907-.376.601 0 1.148.09 1.64.267.497.173.91.415 1.238.725.328.31.547.67.656 1.08a.82.82 0 01.034.177c.01.055.014.11.014.164a.625.625 0 01-.185.472c-.123.119-.29.178-.499.178-.287 0-.506-.132-.656-.397a2.244 2.244 0 00-.506-.745 1.98 1.98 0 00-.752-.458 2.897 2.897 0 00-.984-.157c-.406 0-.766.064-1.08.191a1.742 1.742 0 00-.745.527 1.233 1.233 0 00-.267.78c0 .36.148.648.444.867.301.214.784.399 1.45.554l1.169.267c1.016.227 1.756.553 2.221.977.47.42.704.998.704 1.736 0 .611-.162 1.147-.485 1.607-.32.456-.77.809-1.354 1.06-.578.25-1.262.375-2.05.375zM2.552 8.386a.363.363 0 01-.267-.11.378.378 0 01-.11-.266c0-.1.037-.187.11-.26a.352.352 0 01.267-.116h10.89c.104 0 .193.039.266.116.073.073.11.16.11.26s-.037.189-.11.266a.363.363 0 01-.267.11H2.551z"></path></g></svg>
                </button>
            </div>
            <!-- <div class="icon" [ngClass]="{ 'activeTab': editor.isActive('paragraph') }">
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().setParagraph().run()" [ngClass]="{'is-active': editor.isActive('paragraph')}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 6v15h-2v-5a6 6 0 1 1 0-12h10v2h-3v15h-2V6h-3zm-2 0a4 4 0 1 0 0 8V6z" />
                    </svg>
                </button>
            </div> -->
            <div class="divider last-divider"></div>
            <!-- <div class="icon" >
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleHeading({ level: 1 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 1 })}">
                    H1
                </button>
            </div>
            <div class="icon">
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleHeading({ level: 2 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 2 })}">
                    H2
                </button>
            </div>
            <div class="icon">
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleHeading({ level: 3 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 3 })}">
                    H3
                </button>
            </div>
            <div class="icon">
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleHeading({ level: 4 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 4 })}">
                    H4
                </button>
            </div>
            <div class="icon">
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleHeading({ level: 5 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 5 })}">
                    H5
                </button>
            </div>
            <div class="icon">
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleHeading({ level: 6 }).run()" [ngClass]="{'is-active': editor.isActive('heading', { level: 6 })}">
                    H6
                </button>
            </div>
            <div class="divider"></div> -->
            
            <!-- <div class="icon" [ngClass]="{ 'activeTab': editor.isActive('strike') }">
                <button (click)="editor.chain().focus().toggleStrike().run()" [disabled]="!editor.can().chain().focus().toggleStrike().run()" [ngClass]="{'is-active': editor.isActive('strike')}">
                    Strike
                </button>
            </div> -->
            <div class="icon bullet-list" [ngClass]="{ 'activeTab': editor.isActive('bulletList') }">
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleBulletList().run()" [ngClass]="{'is-active': editor.isActive('bulletList')}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke-width="1.5" stroke="currentColor" aria-hidden="true" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false" width="15" height="15" style="margin-top: -3px;">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"></path>
                    </svg>
                </button>
            </div>
            <div class="icon order-list" [ngClass]="{ 'activeTab': editor.isActive('orderedList') }">
                <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleOrderedList().run()" [ngClass]="{'is-active': editor.isActive('orderedList')}">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: -1px;">
                        <path d="M6.60881 13.521C6.64684 13.5207 6.68487 13.5204 6.72406 13.52C6.85152 13.5193 6.97894 13.5201 7.1064 13.5208C7.19777 13.5206 7.28914 13.5203 7.3805 13.5199C7.6287 13.5191 7.87687 13.5197 8.12507 13.5205C8.38464 13.5212 8.64422 13.5208 8.9038 13.5206C9.33974 13.5204 9.77567 13.5211 10.2116 13.5223C10.7158 13.5237 11.22 13.5237 11.7242 13.5229C12.209 13.5223 12.6937 13.5225 13.1785 13.5233C13.3849 13.5236 13.5914 13.5235 13.7978 13.5232C14.0408 13.5229 14.2838 13.5234 14.5269 13.5246C14.6161 13.5249 14.7054 13.5249 14.7947 13.5246C14.9164 13.5242 15.038 13.5249 15.1597 13.5258C15.1952 13.5255 15.2306 13.5251 15.2672 13.5247C15.4356 13.5272 15.5175 13.5353 15.6631 13.6293C15.772 13.7448 15.7874 13.7848 15.8053 13.9458C15.7895 14.1034 15.7895 14.1034 15.707 14.2184C15.5338 14.3519 15.4122 14.347 15.2003 14.3442C15.1621 14.3445 15.1239 14.3448 15.0845 14.3452C14.9564 14.346 14.8284 14.3454 14.7004 14.3447C14.6086 14.345 14.5168 14.3454 14.4251 14.3458C14.1757 14.3468 13.9264 14.3464 13.6771 14.3457C13.4163 14.3452 13.1556 14.3457 12.8948 14.346C12.4288 14.3465 11.9629 14.3458 11.4969 14.3447C11.0745 14.3437 10.652 14.3439 10.2296 14.3449C9.73894 14.346 9.2483 14.3465 8.75765 14.3458C8.49795 14.3455 8.23826 14.3455 7.97857 14.3462C7.73443 14.3468 7.49032 14.3464 7.24619 14.3451C7.15651 14.3448 7.06684 14.3449 6.97717 14.3454C6.85489 14.346 6.73269 14.3452 6.61041 14.3442C6.55699 14.3449 6.55699 14.3449 6.50249 14.3456C6.31995 14.3425 6.25224 14.3337 6.10446 14.214C6.02381 14.1069 5.99219 14.0642 5.99481 13.9324C5.99427 13.9051 5.99372 13.8778 5.99316 13.8496C6.09314 13.5345 6.33014 13.517 6.60881 13.521Z" />
                        <path d="M6.61041 8.69032C6.64862 8.68996 6.68682 8.6896 6.72618 8.68922C6.85422 8.68827 6.98222 8.68885 7.11027 8.68942C7.20205 8.68906 7.29383 8.68861 7.38561 8.68808C7.63494 8.68692 7.88424 8.68712 8.13357 8.68764C8.3416 8.68794 8.54962 8.68758 8.75765 8.68721C9.27636 8.68631 9.79506 8.6867 10.3138 8.6877C10.7362 8.68848 11.1586 8.68802 11.5811 8.68666C12.0717 8.6851 12.5624 8.68439 13.053 8.68493C13.3127 8.68521 13.5724 8.68512 13.8321 8.6841C14.0762 8.6832 14.3203 8.68353 14.5645 8.68483C14.6542 8.68511 14.7438 8.68494 14.8335 8.6843C14.9558 8.68351 15.078 8.68429 15.2003 8.68547C15.2359 8.68487 15.2715 8.68427 15.3082 8.68365C15.5424 8.68823 15.5424 8.68823 15.6485 8.77368C15.7276 8.86643 15.7844 8.94615 15.8068 9.06797C15.802 9.09519 15.7972 9.1224 15.7922 9.15044C15.7864 9.19159 15.7864 9.19159 15.7805 9.23357C15.7319 9.35488 15.6826 9.40677 15.579 9.48698C15.4494 9.50385 15.3349 9.51025 15.2053 9.50897C15.1672 9.50927 15.129 9.50956 15.0897 9.50987C14.9615 9.51065 14.8334 9.51021 14.7052 9.50977C14.6134 9.51007 14.5216 9.51045 14.4299 9.51089C14.1804 9.51185 13.9309 9.51172 13.6814 9.51134C13.4732 9.51113 13.2651 9.51144 13.057 9.51175C12.538 9.51252 12.019 9.51224 11.5 9.51149C11.0773 9.5109 10.6546 9.51133 10.2319 9.51249C9.74106 9.51382 9.25021 9.51444 8.75936 9.51403C8.49952 9.51382 8.23969 9.51392 7.97986 9.51479C7.73555 9.51558 7.49126 9.51532 7.24696 9.51427C7.15722 9.51406 7.06747 9.51421 6.97774 9.51475C6.85536 9.51543 6.73307 9.51479 6.6107 9.51382C6.57505 9.51433 6.53941 9.51483 6.50269 9.51535C6.32004 9.51241 6.25232 9.50371 6.10444 9.38397C6.02382 9.27688 5.99219 9.23417 5.99481 9.10236C5.99427 9.07504 5.99372 9.04771 5.99316 9.01956C6.09332 8.70384 6.33132 8.68682 6.61041 8.69032Z" />
                        <path d="M6.61041 3.8622C6.64862 3.86184 6.68682 3.86147 6.72618 3.8611C6.85422 3.86015 6.98222 3.86072 7.11027 3.86129C7.20205 3.86093 7.29383 3.86048 7.38561 3.85995C7.63494 3.85879 7.88424 3.859 8.13357 3.85951C8.3416 3.85982 8.54962 3.85945 8.75765 3.85909C9.27636 3.85819 9.79506 3.85858 10.3138 3.85958C10.7362 3.86036 11.1586 3.85989 11.5811 3.85854C12.0717 3.85697 12.5624 3.85627 13.053 3.8568C13.3127 3.85708 13.5724 3.85699 13.8321 3.85598C14.0762 3.85507 14.3203 3.85541 14.5645 3.85671C14.6542 3.85698 14.7438 3.85681 14.8335 3.85618C14.9558 3.85538 15.078 3.85616 15.2003 3.85735C15.2359 3.85675 15.2715 3.85615 15.3082 3.85553C15.5424 3.86011 15.5424 3.86011 15.6484 3.94556C15.7274 4.03814 15.7841 4.11877 15.8083 4.23985C15.8038 4.26706 15.7994 4.29427 15.7948 4.32231C15.7914 4.34975 15.7881 4.37718 15.7846 4.40545C15.7265 4.53448 15.6548 4.5827 15.5369 4.65885C15.4111 4.68374 15.2876 4.68211 15.1597 4.68085C15.1027 4.68129 15.1027 4.68129 15.0445 4.68174C14.917 4.68253 14.7896 4.68209 14.6622 4.68165C14.5708 4.68195 14.4794 4.68232 14.3881 4.68276C14.1399 4.68373 13.8917 4.6836 13.6435 4.68322C13.4364 4.683 13.2293 4.68331 13.0222 4.68362C12.5338 4.68435 12.0454 4.6842 11.557 4.68348C11.0527 4.68276 10.5486 4.68344 10.0444 4.68482C9.61167 4.68596 9.179 4.68627 8.74632 4.68591C8.4878 4.6857 8.22929 4.68579 7.97077 4.68667C7.72774 4.68745 7.48473 4.6872 7.24171 4.68615C7.15244 4.68593 7.06317 4.68608 6.97391 4.68662C6.85219 4.6873 6.73054 4.68667 6.60881 4.6857C6.57337 4.6862 6.53792 4.68671 6.50139 4.68723C6.31921 4.68427 6.25206 4.67539 6.10456 4.55584C6.02381 4.44874 5.99219 4.40611 5.99481 4.27424C5.99427 4.24691 5.99372 4.21959 5.99316 4.19144C6.09332 3.87572 6.33132 3.85869 6.61041 3.8622Z" />
                        <path d="M4.12101 12.3694C4.34222 12.5335 4.53008 12.7568 4.58926 13.0339C4.63058 13.4118 4.58787 13.6681 4.41459 14.0022C4.36212 14.112 4.36212 14.112 4.44716 14.2519C4.48016 14.2872 4.51316 14.3224 4.54716 14.3587C4.64682 14.6622 4.63662 15.0263 4.50505 15.3176C4.35772 15.5762 4.14308 15.7762 3.87347 15.8972C3.46473 15.9915 3.1781 15.9336 2.81558 15.7423C2.59059 15.5536 2.42011 15.2912 2.35768 14.9998C2.33914 14.7852 2.35319 14.659 2.484 14.4869C2.64136 14.3805 2.71866 14.3819 2.90505 14.4015C3.04184 14.4709 3.10002 14.53 3.15357 14.6759C3.17519 14.7514 3.19522 14.8275 3.21344 14.9039C3.236 15.0073 3.236 15.0073 3.3261 15.0852C3.48947 15.1037 3.58502 15.108 3.7261 15.0185C3.80294 14.9127 3.80294 14.9127 3.78663 14.7594C3.76084 14.6042 3.76084 14.6042 3.62084 14.5297C3.57395 14.5182 3.52705 14.5068 3.47874 14.495C3.30037 14.4356 3.22491 14.3852 3.11558 14.2305C3.08585 14.1094 3.08609 14.0098 3.11558 13.8886C3.22474 13.7294 3.31778 13.6694 3.49979 13.6296C3.66006 13.6033 3.66006 13.6033 3.74979 13.5147C3.80548 13.3791 3.79081 13.301 3.74716 13.1622C3.68076 13.0733 3.68076 13.0733 3.57874 13.0339C3.44902 13.0393 3.3621 13.051 3.25505 13.1274C3.19288 13.2097 3.19288 13.2097 3.13926 13.3331C3.07143 13.4653 3.03447 13.522 2.90505 13.5895C2.7397 13.6145 2.67427 13.6043 2.534 13.5094C2.42073 13.3977 2.40268 13.3619 2.38663 13.2022C2.40995 12.904 2.51108 12.6871 2.72874 12.4837C3.14003 12.1548 3.65595 12.0942 4.12101 12.3694Z" />
                        <path d="M4.04217 7.39358C4.2795 7.53592 4.44908 7.81338 4.53263 8.07851C4.63279 8.64891 4.34906 9.075 4.04217 9.53033C3.9038 9.73121 3.76293 9.93023 3.62112 10.1286C3.69952 10.131 3.69952 10.131 3.7795 10.1335C3.84864 10.1363 3.91777 10.1391 3.98691 10.142C4.03848 10.1434 4.03848 10.1434 4.0911 10.1449C4.28936 10.1539 4.42931 10.1687 4.58954 10.2996C4.68894 10.4509 4.69786 10.5059 4.67375 10.6842C4.58686 10.8307 4.50014 10.9281 4.33691 10.9833C4.21874 10.989 4.10166 10.9924 3.98345 10.9935C3.94872 10.9938 3.91398 10.9942 3.87819 10.9945C3.80468 10.9951 3.73117 10.9956 3.65766 10.9959C3.54565 10.9967 3.43369 10.9985 3.32169 11.0004C3.25017 11.0009 3.17865 11.0012 3.10714 11.0015C3.07384 11.0023 3.04054 11.003 3.00623 11.0038C2.78453 11.0029 2.66113 10.9598 2.48427 10.8124C2.39944 10.7038 2.39988 10.6492 2.40319 10.5096C2.43748 10.2896 2.57327 10.1369 2.70533 9.96569C2.75892 9.89399 2.81248 9.82227 2.86602 9.75052C2.89343 9.71415 2.92085 9.67779 2.9491 9.64032C3.07965 9.46636 3.2073 9.29032 3.33427 9.11366C3.36837 9.06716 3.36837 9.06716 3.40315 9.01971C3.56626 8.79299 3.70738 8.58903 3.70269 8.29903C3.67675 8.16235 3.67675 8.16235 3.58954 8.10138C3.47993 8.07357 3.39673 8.06271 3.28427 8.07734C3.15423 8.18643 3.1037 8.31469 3.03839 8.4688C2.97775 8.5664 2.92644 8.59245 2.82112 8.63289C2.66577 8.65302 2.58898 8.64399 2.45006 8.56879C2.32804 8.42725 2.30503 8.30274 2.31585 8.12007C2.37232 7.8335 2.53495 7.59401 2.76931 7.42546C2.8407 7.38268 2.91511 7.34519 2.98954 7.30811C3.02167 7.29048 3.0538 7.27285 3.08691 7.25469C3.43171 7.15703 3.72976 7.23135 4.04217 7.39358Z" />
                        <path d="M3.27654 2.25286C3.31456 2.25198 3.35258 2.2511 3.39175 2.25019C3.6 2.24895 3.74944 2.24959 3.91601 2.39175C4.01615 2.5442 4.01295 2.65321 4.01312 2.82926C4.01347 2.86062 4.01382 2.89197 4.01418 2.92427C4.0152 3.028 4.01557 3.13171 4.01585 3.23543C4.01622 3.30756 4.01661 3.37968 4.017 3.4518C4.01769 3.60304 4.01802 3.75428 4.01814 3.90553C4.01836 4.0992 4.01995 4.29284 4.02187 4.48651C4.02313 4.6355 4.02343 4.78449 4.02347 4.93349C4.02365 5.00488 4.02417 5.07627 4.02505 5.14765C4.02619 5.24762 4.02598 5.34749 4.02546 5.44745C4.02608 5.47685 4.02671 5.50625 4.02735 5.53654C4.02489 5.70061 4.00301 5.76438 3.89881 5.89291C3.75346 6.01091 3.75346 6.01091 3.62127 6.0082C3.58089 6.00903 3.58089 6.00903 3.53969 6.00987C3.4012 5.96461 3.33497 5.87938 3.24233 5.76782C3.21701 5.63991 3.21701 5.63991 3.21843 5.49642C3.21844 5.44253 3.21846 5.38864 3.21847 5.33313C3.21955 5.27475 3.22065 5.21638 3.22177 5.15801C3.22216 5.09795 3.22246 5.0379 3.22267 4.97784C3.22347 4.82024 3.22554 4.66268 3.22787 4.5051C3.23003 4.34411 3.23099 4.18311 3.23205 4.02211C3.2343 3.70656 3.23789 3.39104 3.24233 3.07551C3.1627 3.07716 3.1627 3.07716 3.08147 3.07885C2.91773 3.08225 2.82469 3.05575 2.69496 2.94731C2.5971 2.81015 2.59072 2.68598 2.61075 2.51996C2.80003 2.23178 2.95971 2.25467 3.27654 2.25286Z" />
                        </svg>
                        
                </button>
            </div>
            <div class="color-icon" >
                <input type="color" class="colorInput" (input)="setColor($event)" data-testid="setColor" />
            </div>
        </div>
        
    
    <!-- <button class="px-1 hover:text-white" (click)="editor.chain().focus().unsetAllMarks().run()">
        Clear marks
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().clearNodes().run()">
        Clear nodes
    </button> -->
   
   
   
   
    
   
    
    
   
    <!-- <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleCodeBlock().run()" [ngClass]="{'is-active': editor.isActive('codeBlock')}">
        Code block
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleBlockquote().run()" [ngClass]="{'is-active': editor.isActive('blockquote')}">
        Blockquote
    </button> -->
    <!-- <button class="px-1 hover:text-white" (click)="editor.chain().focus().setHorizontalRule().run()">
        Horizontal rule
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().setHardBreak().run()">
        Hard break
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().undo().run()" [disabled]="!editor.can().chain().focus().undo().run()">
        Undo
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().redo().run()" [disabled]="!editor.can().chain().focus().redo().run()">
        Redo
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().toggleHighlight().run()" [ngClass]="{'is-active': editor.isActive('highlight')}">
        Highlight
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().setTextAlign('left').run()" [ngClass]="{'is-active': editor.isActive({ textAlign: 'left' })}">
        Left
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().setTextAlign('center').run()" [ngClass]="{'is-active': editor.isActive({ textAlign: 'center' })}">
        Center
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().setTextAlign('right').run()" [ngClass]="{'is-active': editor.isActive({ textAlign: 'right' })}">
        Right
    </button>
    <button class="px-1 hover:text-white" (click)="editor.chain().focus().setTextAlign('justify').run()" [ngClass]="{'is-active': editor.isActive({ textAlign: 'justify' })}">
        Justify
    </button> -->
    <!-- <button type="button" class="px-1 hover:text-white" (click)="editor.chain().focus().toggleBlockquote().run()">
        Toggle blockquote
    </button>
    <button type="button" class="px-1 hover:text-white" (click)="editor.chain().focus().setBlockquote().run()">
        Set blockquote
    </button>
    <button type="button" class="px-1 hover:text-white" (click)="editor.chain().focus().unsetBlockquote().run()">
        Unset blockquote
    </button> -->
    
    
    <!-- <button type="button" class="px-1 hover:text-white" (click)="editor.chain().focus().toggleStrike().run()" [ngClass]="{ 'bg-gray-900 text-white': editor.isActive('strike') }">
        strike
    </button> -->
    <!-- <button class="rounded h-7 w-7 flex justify-center items-center ml-1" [ngClass]="{ 'bg-black text-white': editor.isActive('paragraph') }" (click)="editor.chain().focus().setParagraph().run()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 6v15h-2v-5a6 6 0 1 1 0-12h10v2h-3v15h-2V6h-3zm-2 0a4 4 0 1 0 0 8V6z" />
        </svg>
    </button> -->
    
    </div>
</div>
