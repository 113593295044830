import { Component, inject, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-delete-confirmation-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIcon
  ],
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrl: './delete-confirmation-dialog.component.scss'
})
export class DeleteConfirmationDialogComponent {
  readonly dialogRef = inject(MatDialogRef<DeleteConfirmationDialogComponent>);
  readonly data = inject<DeleteConfirmationDialogData>(MAT_DIALOG_DATA);

  onClickYes(): void {
    this.dialogRef.close({yes:true});
  }
  onClose(){
    this.dialogRef.close({yes:false});
  }
}

export interface DeleteConfirmationDialogData{
  message:string;
  title?:string;
  buttonTitle?:string
}