<div class="flex bg-card py-3 px-3  dashCard shadow rounded-2xl overflow-hidden">
    <!-- <fuse-alert [appearance]="'soft'" [dismissible]="true" [dismissed]="false" [name]="'alertBox2'" [type]="'info'">
        Generative.ai is fetching features and generating a summary
    </fuse-alert>                  -->

        <div class="flex gap-1 flex-1 items-center">
        <img class="w-6 h-6 iconAnim" src="assets/icons/loading_icon.svg" alt="">
        <p class="fade">{{generating}}</p>

    </div>
        <div class="flex gap-2 flex-1 items-center">

            <!-- <mat-progress-bar mode="determinate" class="smallProgress gradient-progress-bar flex-1" [value]="progressValue"></mat-progress-bar> -->
            <!-- <div class="w-full rounded-lg overflow-hidden bg-gray-300 smallProgress">
                <div
                  class="h-full rounded-lg progressFilled"
                  [ngStyle]="{ width: progressValue + '%' }"
                ></div>
              </div> -->

              <div class="w-full h-2 rounded-full bg-[#D9D9D9] flex justify-start items-center overflow-clip">

                <div class="h-full rounded-full bg-[#4F45E4] animate-progress" [style.width.%]="30"></div>

            </div>

            <!-- <div class="whitespace-nowrap">{{progressValue}}% Completed</div> -->

    </div>


</div>