 
import { Component, inject, model } from '@angular/core';
import { DatePipe,NgFor,NgIf,NgClass,NgTemplateOutlet, JsonPipe } from '@angular/common';
  import { FormsModule } from '@angular/forms';
  import { MatButtonModule } from '@angular/material/button';
  import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
  import { MatFormFieldModule } from '@angular/material/form-field';
  import { MatIcon } from '@angular/material/icon';
  import { MatInputModule } from '@angular/material/input';
@Component({
  selector: 'app-generic-confirmation',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatIcon,
    NgIf
  ],
  templateUrl: './generic-confirmation.component.html',
  styleUrl: './generic-confirmation.component.scss'
})
export class GenericConfirmationComponent {

    readonly dialogRef = inject(MatDialogRef<GenericConfirmationComponent>);
    readonly data = inject<GenericConfirmationDialogData>(MAT_DIALOG_DATA);
  
    onClickYes(): void {
      this.dialogRef.close({yes:true});
    }
    onClose(){
      this.dialogRef.close({yes:false});
    }
  }
  
  export interface GenericConfirmationDialogData{
    title:string;
    subtitle:string;
    yesAction:string;
    noAction:string;

  }