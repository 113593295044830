import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { WebsocketService } from './core/websockets/websocket.service';
import { filter } from 'rxjs';
import {v4 as uuidv4} from 'uuid';
@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent
{
    private activityTimeout: any;
    private hasInitiatedReconnect:boolean=false;

    /**
     * Constructor
     */
    constructor(private _websocketService:WebsocketService)
    {


        /*
        this._websocketService.messageSubject.pipe(
            filter((message) => message.type === "connection")
        ).subscribe((message) => {
            console.log("Received:", message);
            // You don't need to check message.type again here since we've already filtered messages where type is "connection"
            let taskID=uuidv4();
            console.log("Task ID:",taskID);
            this._websocketService.sendFixGrammarCommand("Who you is?", "",taskID);
        });

        this._websocketService.messageSubject.pipe(
            filter((message) => message.type === "fix_grammar")
        ).subscribe((message) => {
            console.log("Received:", message);
            // You don't need to check message.type again here since we've already filtered messages where type is "connection"
            //this._websocketService.sendFixGrammarCommand("Who you is?", "");
        });
        */
    }
/*
    //@HostListener('document:mousemove')
  @HostListener('document:keydown')
  @HostListener('document:click')
  onUserActivity() {
     
    if(this.hasInitiatedReconnect){
        return;
    }
   
    console.log("on User Activity:",this._websocketService.isConnected);
  

    if (!this._websocketService.isConnected) {
        this.hasInitiatedReconnect=true;
        this.resetActivityTimeout();
    }
  }

  private resetActivityTimeout() {
    clearTimeout(this.activityTimeout);
    this.activityTimeout = setTimeout(() => {
        this.hasInitiatedReconnect=false;
    }, 1 * 60 * 1000); // 1 minutes of inactivity
  }
    */
}
