<div class="flex actBt flex-col gap-3 mb-6 ">
    <div class="bg-white mt-6 p-2 rounded searchBar text-right">
      <form class="flex flex-wrap gap-2 justify-end " [formGroup]="headerForm" *ngIf="headerForm">                                         
        <mat-form-field class="fuse-mat-dense searchTab min-w-60">
            <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
            <input matInput [readonly]="fetch_meta_data_val" [placeholder]="'Search'" [autocomplete]="'off'" [formControlName]="'search'">
        </mat-form-field>
        <mat-form-field class="flex-auto gt-xs:pl-3  custom-select">          
          <img src="assets/icons/folderic.svg" class="w-5" alt="">
          <mat-select [formControlName]="'selected_category'" [placeholder]="'Category'" (selectionChange)="onChangeCategory($event)" class="ml-2">
            <mat-option>All</mat-option>
            @for (option of category_list; track option) {
                <mat-option [value]="option.value">{{option.label}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="flex-auto gt-xs:pl-3  custom-select" [ngClass]="{'disabled':(subcategory_list.length == 0)}">          
          <img src="assets/icons/folder2ic.svg" class="w-5" alt="">
          <mat-select [formControlName]="'selected_subcategory'" [placeholder]="'Sub Category'" class="ml-2">
            @for (option of subcategory_list; track option) {
              <mat-option [value]="option.value">{{option.label}}</mat-option>
          }
          </mat-select>
        </mat-form-field>
      </form>       
    </div>


    <div class="tab-panel flex flex-row">
        <div class="left-side-panel rounded" >
            <ul *ngIf="describeMetadataList" class="p-2">
                <section *ngFor="let eachObject of describeMetadataList;i as index">
                  <!-- && !fetch_meta_data_val -->
                  <li *ngIf="eachObject.active" class="font-medium sideTabs" [ngClass]="{'active': (activeTab == eachObject.fullName), 'disableTab': (!eachObject.extracted_data.length)}" (click)="changeTab(eachObject)">{{eachObject.fullName}}<span class="tabCounter">({{eachObject.extracted_data.length}})</span></li>
                </section>
            </ul>
            <div *ngIf="!describeMetadataList" class="shimmer-effect" style="min-height: 600px;"></div>
        </div>
        <div class="right-panel pl-4">
          <!-- objects -->
          <div *ngIf="describeMetadataList">
            <div *ngFor="let eachObject of describeMetadataList;i as index">
              <div class="objectBox" *ngIf="activeTab == eachObject.fullName">
                <div class="shimmer-effect h-60 rounded" *ngIf="(!filterCountActive && !eachObject.extracted_data.length)"></div>
                <div *ngIf="(filterCountActive && !eachObject.extracted_data.length)" class="flex bg-white rounded"  >
                  <div class="flex flex-col gap-4 items-center justify-center notfound p-16 w-full">
                      <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="text-[#000000]"></mat-icon>
                      <h4 class="text-lg font-medium">No Results Found </h4>
                      <p class="text-secondary font-medium">No Data Available: Try Refining Your Search or Adjusting Filters</p>
                  </div>
                </div> 
                <div class="tableBox bg-card rounded" *ngIf="(eachObject.extracted_data.length && eachObject.active)">
                  <table mat-table [dataSource]="eachObject.extracted_data">                      
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef style="max-width: 85px;"> Name </th>
                      <td mat-cell *matCellDef="let element" style="max-width: 85px; word-break: break-all;"> {{getDisplayName(element.filefullName)}} </td>
                    </ng-container>

                    <ng-container *ngIf="hasObjectValue" matColumnDef="object">
                      <th mat-header-cell *matHeaderCellDef style="max-width: 85px;"> Object </th>
                      <td mat-cell *matCellDef="let element" style="max-width: 85px; word-break: break-all;"> {{element.object?element.object:'-'}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="description">
                      <th mat-header-cell *matHeaderCellDef class="descTab"> Description </th>
                      <td mat-cell *matCellDef="let element" class="descTab" [innerHTML]="element.description"></td>
                    </ng-container>
              
                    <!-- <ng-container matColumnDef="completion">
                      <th mat-header-cell *matHeaderCellDef> Versions </th>
                      <td mat-cell *matCellDef="let element" > 
                        {{element.version?element.version:'-'}}
                      </td>
                    </ng-container> -->

                    <!-- <ng-container matColumnDef="insights">
                      <th mat-header-cell *matHeaderCellDef> Insights </th>
                      <td mat-cell *matCellDef="let element" > 
                        {{element.insights?element.insights:'-'}} 
                      </td>
                    </ng-container> -->
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectMetadata(row,eachObject)"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
           <div *ngIf="!describeMetadataList" class="shimmer-effect" style="min-height: 400px;"></div>
           
           
        </div>
    </div>
</div>
  
<fuse-drawer
class="custom-drawer z-999"
fixed
[mode]="'over'"
[name]="'viewDrawer'"
(openedChanged)="openedChanged($event)"
[position]="'right'" 
#viewDrawer>

<div  class="flex flex-col w-full overflow-auto bg-card">
    <div class="flex justify-between items-center px-4 py-2" >
        <div class="">
          <span class="text-sm">Info</span>
          <h4 class="text-lg font-medium">Metadata</h4>
        </div>
        <div class="actSide flex  items-center" *ngIf="is_write_allowed">
            <button  mat-icon-button [matMenuTriggerFor]="moreMenu2" *ngIf="!editMeta">
                <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'" class="icon-size-4"></mat-icon>
            </button>
            <mat-menu #moreMenu2="matMenu">
                <button  mat-menu-item (click)="editMeta = true;">
                    <mat-icon [svgIcon]="'heroicons_outline:pencil-square'" class="icon-size-4"></mat-icon>
                    <span>Edit </span>
                </button>
                <button mat-menu-item (click)="onDelete()">
                    <mat-icon [svgIcon]="'heroicons_outline:trash'" class="icon-size-4"></mat-icon>
                    <span>Delete </span>
                </button>
               
            </mat-menu>
            <button class="ml-auto" mat-icon-button (click)="clear()">
                <mat-icon class="icon-size-4" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
    </div>

    <div class="formSec px-4 py-2 flex flex-col gap-4">
      <form class="flex flex-col flex-auto gap-2 " [formGroup]="extractForm">

        <div class="detailBox" *ngIf="!editMeta">
            <div class="innerDetail mb-2">
              <h4 class="font-medium">Name</h4>
              <p class=" py-2 text-md text-[#3B4554]">{{extractForm.get('fullName').value}}</p>
            </div>

            <div class="innerDetail flex justify-between ">
              <div class="type">
                <h4 class="font-medium">Type</h4>
                <p class=" py-2 text-md text-[#3B4554]">{{extractForm.get('type').value}}</p>
              </div>
              <div class="type">
                <h4 class="font-medium">Category</h4>
                <p class=" py-2 text-md text-[#3B4554]">{{extractForm.get('category').value?extractForm.get('category').value:'-'}}</p>
              </div>
              <div class="type">
                <h4 class="font-medium">Sub-Category</h4>
                <p class=" py-2 text-md text-[#3B4554]">{{extractForm.get('sub_category').value?extractForm.get('sub_category').value:'-'}}</p>
              </div>
            </div>
            
            <div class="innerDetail mb-2">
              <h4 class="font-medium">Description</h4>
              <p class=" py-2 text-md text-[#3B4554]" [innerHTML]="extractForm.get('description').value"></p>
            </div>
            <div class="showxml mb-3 flex justify-between">
              <button class="showxmlbt" *ngIf="!extractForm.get('xmlCode').value" (click)="displayXML();"> <mat-icon svgIcon="heroicons_outline:eye" class="icon-size-4"></mat-icon> Show  {{showText}}</button>
              <button class="showxmlbt" *ngIf="extractForm.get('xmlCode').value" (click)="copyMessage()"><mat-icon svgIcon="heroicons_outline:square-2-stack"  class="icon-size-4" ></mat-icon></button>
              <fuse-alert *ngIf="alert_xml" class="border-alert" [appearance]="'soft'" [showIcon]="false" type="success" [dismissible]="true" [dismissed]="false">
                <mat-icon  class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:check-circle'"> </mat-icon>  
                {{showText}} code copied successfully!
              </fuse-alert>
            </div>
            <div *ngIf="loader_xml" class="shimmer-effect h-32 mt-6" ></div>
            <div class="xmlBox" *ngIf="extractForm.get('xmlCode').value">
                <div class="xmlDisplay">
                  <pre><code [innerText]="extractForm.get('xmlCode').value"></code></pre>
                </div>
            </div>
            <!-- <div class="insightBox ">
              <h4 class="font-medium">Insights</h4>
              <div class="insightInner border-b mb-6">
                <p class=" py-2 text-md text-[#3B4554]">Lorem ipsum dolor sit amet consectetur. Ac at egestas pellentesque tristique commodo in quis. Eget nam amet cursus risus ornare sed ullamcorper nunc arcu. </p>
                <div class="smallDetail flex justify-between p-2 pb-6">
                  <span class="text-md text-secondary">Created on 2 Sep, 2024</span>
                  <span class="inTag text-md">Dependancy</span>
                </div>
              </div>
              <div class="insightInner border-b mb-6">
                <p class=" py-2 text-md text-[#3B4554]">Lorem ipsum dolor sit amet consectetur. Ac at egestas pellentesque tristique commodo in quis. Eget nam amet cursus risus ornare sed ullamcorper nunc arcu. </p>
                <div class="smallDetail flex justify-between p-2 pb-6">
                  <span class="text-md text-secondary">Created on 2 Sep, 2024</span>
                  <span class="inTag text-md">Dependancy</span>
                </div>
              </div>
            </div> -->
        </div>
        <div class="detailBox" *ngIf="editMeta">
          <div class="innerDetail mb-2">
            <h4 class="font-medium">Name</h4>
            <p class=" py-2 text-md text-[#3B4554]">{{extractForm.get('fullName').value}}</p>
          </div>
          <div class="innerDetail flex justify-between ">
            <div class="type">
              <h4 class="font-medium">Type</h4>
              <p class=" py-2 text-md text-[#3B4554]">{{extractForm.get('type').value}}</p>
            </div>
            <div class="type">
              <h4 class="font-medium">Category</h4>
              <p class=" py-2 text-md text-[#3B4554]">{{extractForm.get('category').value?extractForm.get('category').value:'-'}}</p>
            </div>
            <div class="type">
              <h4 class="font-medium">Sub-Category</h4>
              <p class=" py-2 text-md text-[#3B4554]">{{extractForm.get('sub_category').value?extractForm.get('sub_category').value:'-'}}</p>
            </div>
          </div>
          <div class="innerDetail mb-2">
            <h4 class="font-medium">Description</h4>
            <textarea name="" id="" class="textArea" formControlName="description"></textarea>
          </div>
          <div class="showxml mb-3 flex justify-between">
            <button class="showxmlbt" *ngIf="!extractForm.get('xmlCode').value" (click)="displayXML();"> <mat-icon svgIcon="heroicons_outline:eye" class="icon-size-4"></mat-icon> Show  {{showText}}</button>
            <button class="showxmlbt" *ngIf="extractForm.get('xmlCode').value" (click)="copyMessage()"> <mat-icon svgIcon="heroicons_outline:square-2-stack"  class="icon-size-4"></mat-icon> </button>
            <fuse-alert *ngIf="alert_xml" class="border-alert" [appearance]="'soft'" [showIcon]="false"  type="success" [dismissible]="true" [dismissed]="false">
              <mat-icon  class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:check-circle'"> </mat-icon>  
              {{showText}} code copied successfully!
            </fuse-alert>
          </div>
          <div *ngIf="loader_xml" class="shimmer-effect h-32 mt-6" ></div>
          <div class="xmlBox" *ngIf="extractForm.get('xmlCode').value">
              <div class="xmlDisplay">
                <pre><code [innerText]="extractForm.get('xmlCode').value"></code></pre>
              </div>
          </div>
          <!-- <div class="insightBox ">
            <h4 class="font-medium">Insights</h4>
            <div class="insightInner border-b mb-6">
              <textarea name="" id="" class="textArea"> Lorem ipsum dolor sit amet consectetur. Ac at egestas pellentesque tristique commodo in quis. Eget nam amet cursus risus ornare sed ullamcorper nunc arcu. </textarea>
              <div class="smallDetail flex justify-end p-2 pb-6">
                <mat-select class="tagSelect">
                    <mat-option value="Dependancy" selected="selected">Dependancy</mat-option>
                    <mat-option value="Improvement">Improvement</mat-option>
                    <mat-option value="Optimization">Optimization</mat-option>
                    <mat-option value="Governance">Governance</mat-option>
                    <mat-option value="Enhancement">Enhancement</mat-option>
                    <mat-option value="Risk Mitigation">Risk Mitigation</mat-option>
                  </mat-select>
              </div>
            </div>
            <div class="insightInner border-b mb-6">
              <textarea name="" id="" class="textArea"> Lorem ipsum dolor sit amet consectetur. Ac at egestas pellentesque tristique commodo in quis. Eget nam amet cursus risus ornare sed ullamcorper nunc arcu. </textarea>
              <div class="smallDetail flex justify-end p-2 pb-6">
                <mat-select class="tagSelect">
                    <mat-option value="Dependancy" selected="selected">Dependancy</mat-option>
                    <mat-option value="Improvement">Improvement</mat-option>
                    <mat-option value="Optimization">Optimization</mat-option>
                    <mat-option value="Governance">Governance</mat-option>
                    <mat-option value="Enhancement">Enhancement</mat-option>
                    <mat-option value="Risk Mitigation">Risk Mitigation</mat-option>
                  </mat-select>
              </div>
            </div>
          </div> -->


         



          <div class="actionBt text-right  mt-4" [ngClass]="{'justify-between flex': extractSaved}">
            <fuse-alert *ngIf="extractSaved" class="mb-3 mt-3 fullwidAlert" type="success"  [showIcon]="true" [appearance]="'outline'" [dismissed]="false"  [dismissible]="true">
              <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:check-circle'"> </mat-icon>  
              Saved successfully!
            </fuse-alert>
            <button mat-flat-button [color]='"primary"' class="saveBt" (click)="onEditSave()"[disabled]="edit_extract"> 
              <mat-spinner *ngIf="edit_extract" class="matSpinner"  [diameter]="18"></mat-spinner>
              Save 
            </button>
          </div>
         

        </div>
      </form>
    </div>

</div>
</fuse-drawer>