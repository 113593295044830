import { filter } from 'rxjs';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgFor,NgIf,NgClass } from '@angular/common';

import { ChangeDetectionStrategy,Component, ChangeDetectorRef, EventEmitter, inject, Input, Output, ViewChild,viewChild, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatCalendarCellClassFunction, MatDatepickerModule} from '@angular/material/datepicker';
import {provideNativeDateAdapter} from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerComponent, FuseDrawerService } from '@fuse/components/drawer';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { AddFeatureComponent } from '../dialogs/add-feature/add-feature.component';
import moment from 'moment';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Card, List } from '../manage-release/manage-release.models';

@Component({
  selector: 'app-manage-release',
  standalone: true,
  imports: [NgIf, NgClass,FuseAlertComponent, MatFormFieldModule,MatDatepickerModule,FuseDrawerComponent, NgFor,CdkScrollable, CdkDropList, CdkDropListGroup,CdkDrag, CdkDragHandle, MatSelectModule, ReactiveFormsModule, MatIcon, MatInputModule, MatButtonModule, MatExpansionModule, MatSelectModule,MatMenuModule, MatProgressSpinnerModule,AddFeatureComponent],
  providers: [provideNativeDateAdapter()],
  templateUrl: './manage-release.component.html',
  styleUrl: './manage-release.component.scss'
})

export class ManageReleaseComponent {
  @ViewChild('addFeature') addFeature: AddFeatureComponent;
  @ViewChild(FuseDrawerComponent) relDrawer: FuseDrawerComponent;
  
  
  inputFormGroup:UntypedFormGroup;
  drawerGroupForm: UntypedFormGroup;
  _proposalDetail: any = {};
  today: Date = new Date();

  @Input() set proposal(value: any) {
    if (value) {
      //console.log(value);
      this._proposalDetail = value;
      this.getRelease();
      this.cdr.detectChanges();
    }
  }

  @Input()
  _page_id:any;

  @Input()
  is_write_allowed:any;

  @Output()
  onSaveEpics=new EventEmitter<any>();

  @Output() onFeatureUpdate=new EventEmitter<any>();

  docSaved: boolean = false;
  epicTypes:any=[];
  releases_list:any=[];
  themeTypes:any[]=[];
  all_original_release_list:any=[];
  load_release: boolean = true;
  save_loader: boolean = false;
  private readonly _positionStep: number = 65536;
  private readonly _maxListCount: number = 200;
  private readonly _maxPosition: number = this._positionStep * 500;


  constructor(private cdr: ChangeDetectorRef,private _proposalService: ProposalService,private _formBuilder: UntypedFormBuilder,private _confirmationService:FuseConfirmationService,private fuseDrawer:FuseDrawerService){
  }

  ngOnInit(){
    this.releases_list = [];
    this.resetToAllEpics();
    this.inputFormGroup = this._formBuilder.group({
      theme: [[]],
      search: [''],
      epic_name: [[]],
    });
    this.drawerGroupForm = this._formBuilder.group({
      name: ['', Validators.required],
      default: [false],
      order: [0],
      description: ['', Validators.required],
      proposal: [this._proposalDetail._id],
      short_name: ['', [Validators.required, Validators.maxLength(3)]],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      author: [''],
      created: [''],
      _id: ['']
    }, { validator: this.endDateAfterStartDate });
    this.getRelease();
  }

  endDateAfterStartDate(group: FormGroup) {
    const startDate = group.get('startDate')?.value;
    const endDate = group.get('endDate')?.value;
    return endDate && startDate && endDate >= startDate ? null : { endDateInvalid: true };
  }

  getRelease(){
    this.themeTypes = [];
    this.releases_list = JSON.parse(JSON.stringify(this._proposalDetail.releases_list));
    this.releases_list.forEach(release => {
      release.boardId = release._id;
      release.requirements = release.requirements || [];
    });
    this._proposalDetail.epics.forEach(epic => {
      this.themeTypes.push({label:epic.theme, value:epic.theme})
      epic.epics.forEach(subEpic => {
        subEpic.features.forEach(requirement => {
          if (requirement.release) {
            let release = this.releases_list.findIndex(release => release._id === requirement.release);
            if (release>-1) {
              requirement['theme'] = {_id: epic._id, title:epic.theme};
              requirement['epic'] = {_id: subEpic._id, title:subEpic.name};
              this.releases_list[release].requirements.push(requirement);
            }
          }
        });
      });
    });
    this.all_original_release_list = JSON.parse(JSON.stringify(this.releases_list));
    this.load_release = false;
    this.cdr.detectChanges();
  }

  resetToAllEpics(){
    this.epicTypes = this._proposalDetail.epics?.flatMap((epic) => {
      return epic.epics.map((themeEpic) => {
        return { label: themeEpic.name, value: themeEpic.name };
      });
    }) ?? [];    
  }

  search(event) {
    const searchString = event.target.value.toLowerCase();
    let all_docs = JSON.parse(JSON.stringify(this.all_original_release_list));
    this.releases_list = all_docs.map(release => {
      let filteredRequirements = release.requirements.filter(requirement => {
        return (
          requirement.title?.toLowerCase().includes(searchString) ||
          requirement.theme?.title?.toLowerCase().includes(searchString) ||
          requirement.epic?.title?.toLowerCase().includes(searchString)
        );
      });
      if (filteredRequirements.length > 0) {
        return { ...release, requirements: filteredRequirements };
      } else {
        return { ...release, requirements: [] };
      }
    }).filter(release => release !== null);
  
    if (!searchString) {
      this.releases_list = JSON.parse(JSON.stringify(this.all_original_release_list));
    }
  }

  filterCount() {
    let all_docs = JSON.parse(JSON.stringify(this.all_original_release_list));
    this.releases_list = all_docs;
    const themeFilter = this.inputFormGroup.get('theme').value;
    const epicFilter = this.inputFormGroup.get('epic_name').value;
    this.releases_list = this.releases_list.map(release => {
      let filteredRequirements = release.requirements.filter(requirement => {
        let themeMatch = !themeFilter?.length || themeFilter.includes(requirement.theme?.title);
        let epicMatch = !epicFilter?.length || epicFilter.includes(requirement.epic?.title);
        return themeMatch && epicMatch;
      });
  
      if (filteredRequirements.length > 0) {
        return { ...release, requirements: filteredRequirements };
      } else {
        return { ...release, requirements: [] };
      }
    }).filter(release => release !== null);
    
    if (!themeFilter?.length && !epicFilter?.length) {
      this.releases_list = JSON.parse(JSON.stringify(this.all_original_release_list));
    }
  }
  
  onClickAddFeature(theme,epic,release){
    this.addFeature.isEdit=false;
    this.addFeature.selectedIndex=-1;
    this.addFeature.themeForm.get("feature").setValue("");
    this.addFeature.themeForm.get("theme").setValue(theme);
    this.addFeature.themeForm.get("release").setValue(release);
    this.addFeature.filterEpics();
    this.addFeature.themeForm.get("epic").setValue(epic);
    this.addFeature.themeDrawer.toggle();
  }

  onEditFeature(theme,epic,feature,index){
    this.addFeature.isEdit=true;
    this.addFeature.selectedIndex=index;
    this.addFeature.themeForm.get("feature").setValue(feature.title);
    this.addFeature.themeForm.get("theme").setValue(theme);
    this.addFeature.themeForm.get("release").setValue(feature.release);
    this.addFeature.filterEpics();
    this.addFeature.themeForm.get("epic").setValue(epic);
    this.addFeature.themeDrawer.toggle();
    // console.log(this.addFeature);
  }

  onDeleteFeatureCallback(event){
    this.onDeleteFeature(event.theme,event.epic,event.feature,event.index);
  }

  onDeleteFeature(selectedTheme,selectedEpic,feature,index){
    this._proposalDetail.epics.forEach(theme => {
      if (theme.theme == selectedTheme) {
        theme.epics.forEach((epic) => {
          if (epic.name == selectedEpic) {
            if (epic.features) {
              let findIndex = epic.features.findIndex(el=>el._id==index);
              epic.features.splice(findIndex, 1);
            }
          }
        });
      }
    });
    this.onSaveEpics.emit(this._proposalDetail.epics);
  }

  onSaveFeature(event:any){
    if(!event.isEdit){
      //Add New Mode
      this._proposalDetail.epics.forEach(theme => {
        if(theme.theme==event.theme){
          theme.epics.forEach((epic)=>{
            if(epic.name==event.epic){
              if(epic.features){
                epic.features.push({title:event.feature,release:event.release});
              }else{
                epic.features=[{title:event.feature,release:event.release}];
              }
            }
          });
        }
      });
    }else{
      //Edit Mode
      if(event.index!=-1){
        this._proposalDetail.epics.forEach(theme => {
          if(theme.theme==event.theme){
            theme.epics.forEach((epic)=>{
              if(epic.name==event.epic){
                let findIndex = epic.features.findIndex(el=>el._id==event.index);
                epic.features[findIndex].title=event.feature;  
                epic.features[findIndex].release=event.release;     
              }
            });
          }
        });
      }
    }
    this.onSaveEpics.emit(this._proposalDetail.epics);
  }
  
  onCloseDrawer(){
    this.relDrawer.close();
  }

  trackByFn(index: number, item: any): any {
    return (item)?item._id:index;
  }

  editList(eachRelease){
    // console.log("On select release:",eachRelease);
    this.drawerGroupForm.patchValue({
      _id: eachRelease._id,
      name: eachRelease.name,
      short_name: eachRelease.short_name,
      description: eachRelease.description,
      default: eachRelease.default,
      proposal: eachRelease.proposal,
      startDate: new Date(eachRelease.startDate),
      endDate:  new Date(eachRelease.endDate),
      author: eachRelease.author,
      created: eachRelease.created
    });
    this.relDrawer.open();
  }

  autoSave(){
    this.save_loader = true;
    let updateObject = JSON.parse(JSON.stringify(this.drawerGroupForm.value));
    if(updateObject){
      updateObject['startDate'] = moment(updateObject['startDate']).format();
      updateObject['endDate'] = moment(updateObject['endDate']).format();
    }
    let type='edit';
    if(!updateObject['_id']){
      type = 'add';
      delete updateObject['_id'];
      delete updateObject['author'];
      delete updateObject['created'];
      updateObject['order'] = this.all_original_release_list.length+1;
    }
    
    this._proposalService.saveRelease(updateObject).subscribe(
      (value) =>
        {
          console.log(value);
          if(value.success){
            this._proposalDetail.releases_list.push(value.response);
            this.docSaved = true;
            setTimeout(()=>{
              this.docSaved = false;
              if(type=='add'){
                this.openedChanged(false);
              }
            },1000);
          }
          this.save_loader = false;
        },
        (response) =>
        {
        },
    );
  }

  deleteList(releaseid){
    const dialogRef = this._confirmationService.open({
      dismissible:true,
      title:"Delete Release?",
      message:"Are you sure you want to delete this release?",
      actions:{
        confirm:{
          show:true,
          label:"Delete",
          color:'warn'
        },
        cancel:{
          show:true,
          label:"Cancel"
        }
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result=='confirmed'){ 
        this._proposalService.deleteRelease({proposalID:this._proposalDetail._id,_id:releaseid}).subscribe(
          (value) =>
            {
              this.getRelease();
              this.openedChanged(false);
              this.onFeatureUpdate.emit({refresh:true});
            },
            (response) =>
            {
            },
        );
      }
    });    
  }

  listDropped(event: CdkDragDrop<List[]>): void {
    // console.log(event);
    if(this.is_write_allowed){
      // Move the item
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  
      // Calculate the positions
      const updated = this._calculatePositions(event);
      // console.log(updated);
      // Update the lists
      this.updateLists(event.container.data);
    }
  }

  updateLists(updated){
    let updateObject = {id:this._proposalDetail._id,releases:[]};
    for(let i=0;i<updated.length;i++){
      updateObject['releases'].push({_id:updated[i]._id,order:(i+1)})
    }
    this._proposalService.updateList(updateObject).subscribe(
      (value) =>
        {
          // console.log(value);
          if(value.success){
            this.getRelease();
            this.onFeatureUpdate.emit({refresh:true});
          }
          this.save_loader = false;
        },
        (response) =>
        {
        },
    );
  }

  private _calculatePositions(event: CdkDragDrop<any[]>): any[]
    {
        // Get the items
        let items = event.container.data;
        const currentItem = items[event.currentIndex];
        const prevItem = items[event.currentIndex - 1] || null;
        const nextItem = items[event.currentIndex + 1] || null;

        // If the item moved to the top...
        if ( !prevItem )
        {
            // If the item moved to an empty container
            if ( !nextItem )
            {
                currentItem.position = this._positionStep;
            }
            else
            {
                currentItem.position = nextItem.position / 2;
            }
        }
        // If the item moved to the bottom...
        else if ( !nextItem )
        {
            currentItem.position = prevItem.position + this._positionStep;
        }
        // If the item moved in between other items...
        else
        {
            currentItem.position = (prevItem.position + nextItem.position) / 2;
        }

        // Check if all item positions need to be updated
        if ( !Number.isInteger(currentItem.position) || currentItem.position >= this._maxPosition )
        {
            // Re-calculate all orders
            items = items.map((value, index) =>
            {
                value.position = (index + 1) * this._positionStep;
                return value;
            });

            // Return items
            return items;
        }

        // Return currentItem
        return [currentItem];
  }

  cardDropped(event: CdkDragDrop<Card[]>): void
    {
      if(this.is_write_allowed){
        // Move or transfer the item
        if ( event.previousContainer === event.container )
        {
            // Move the item
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else
        {
            // Transfer the item
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

            // Update the card's list it
            event.container.data[event.currentIndex].release = event.container.id;
        }

        // Calculate the positions
        const updated = this._calculatePositions(event);
        
        if(updated.length){
          this._proposalDetail.epics.forEach(theme => {
            if(theme.theme==updated[0].theme.title){
              theme.epics.forEach((epic)=>{
                if(epic.name==updated[0].epic.title){ 
                  let findFeature = epic.features.findIndex(el=>el._id==updated[0]._id);
                  epic.features[findFeature]={title:updated[0].title,release:updated[0].release};     
                }
              });
            }
          });
          this.onSaveEpics.emit(this._proposalDetail.epics);
        }
        // Update the cards
        // this._scrumboardService.updateCards(updated).subscribe();
      }
  }

  openedChanged(event){
    if(event===false){
      this.clear();
      this.getRelease();
      this.onFeatureUpdate.emit({refresh:true});
    }
    else {
      this.save_loader=false;
      // console.log(this.drawerGroupForm.value);
    }
  }

  clear(){
    this.drawerGroupForm.reset();
    this.relDrawer.close();
  }

}
