<form class="flex flex-col w-full" [formGroup]="newSolutionForm">
    <div class="flex flex-col sm:flex-row px-8">
        <div class="flex flex-auto flex-wrap">
            <div class="grid sm:grid-cols-4 gap-6 w-full ">
                <!-- Epic Name -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Module</mat-label>
                        <input matInput [formControlName]="'epicName'" name="epicName" [readonly]="_page_id === 'template' || !is_write_allowed" class="text-black">
                    </mat-form-field>
                </div>
                <!-- Feature Name -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Sub-Module</mat-label>
                        <input matInput [formControlName]="'name'" name="name" [readonly]="_page_id === 'template' || !is_write_allowed" class="text-black">
                    </mat-form-field>
                </div>

                <!-- Themes -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Theme</mat-label>
                        <mat-select [placeholder]="'Select Theme'" [formControlName]="'theme'"  class="" (selectionChange)="onChangeTheme($event)">
                            @for (theme of themeTypes;track theme){
                                <mat-option [value]="theme.value">{{theme.label}}</mat-option> 
                            }
                        </mat-select>
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
                    </mat-form-field>
                </div>
                <!-- Epic -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Epic</mat-label>
                        <mat-select [placeholder]="'Select Epic'" [formControlName]="'epic'"  class="">
                            @for(epic of epicTypes;track epic){
                                <mat-option [value]="epic.value">{{epic.label}}</mat-option>
                            }
                        </mat-select>
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                    </mat-form-field>
                </div>


                <!-- Complexity -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Complexity</mat-label>
                        <mat-select name="complexity" [formControlName]="'complexity'" placeholder="Select Option" required >
                            @for(complexity of complexities;track complexity){
                                <mat-option [value]="complexity.value">{{complexity.label}}</mat-option>
                            }
                        </mat-select>
                        <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_outline:arrows-pointing-out"></mat-icon>
                    </mat-form-field>
                </div>

                <!-- Release -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Release</mat-label>
                        <mat-select name="release" [formControlName]="'release'" placeholder="Select Option" required >
                            @for(release of releases_list;track release){
                                <mat-option [value]="release._id">{{release.name}}</mat-option>
                            }
                        </mat-select>
                        <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_outline:cube-transparent"></mat-icon>
                    </mat-form-field>
                </div>

            </div>

            <!-- More fields -->
            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                <!-- Components Count -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Components Count</mat-label>
                        <input type="number" matInput [formControlName]="'componentsCount'" name="componentsCount" (change)="onComponentsCountChange($event)" [readonly]="_page_id === 'template' || !is_write_allowed" class="text-black">
                    </mat-form-field>
                </div>

                <!-- Effort -->
                <div class="sm:col-span-2">
                    <mat-form-field class="w-full">
                        <mat-label>Effort</mat-label>
                        <input type="number" matInput required [formControlName]="'effort'" name="effort" [readonly]="_page_id === 'template' || !is_write_allowed" min="0" class="text-black">
                    </mat-form-field>
                </div>
            </div>

            <!-- Descriptions -->
            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                <div class="sm:col-span-4">
                    <div >
                        <span class="font-medium block mb-2 text-black">Solution Description</span>
                        <div >
                            <tiptap-bubble-menu [editor]="featureDescriptionEditor" [id]="'featureDescription'">
                                <app-editor-controls 
                                [editor]="featureDescriptionEditor" 
                                [selectedText]="selectedText"
                                (completeQuickAction)="onCompleteQuickAction($event)">
                                </app-editor-controls>
                            </tiptap-bubble-menu>
                            
                            <tiptap-editor 
                            [id]="'featureDescription'"
                            [editor]="featureDescriptionEditor" 
                            [formControlName]="'featureDescription'" name="featureDescription"
                            (blur)="onTextareaBlur()"
                            (mouseenter)="onMouseEnter()"
                            (mouseleave)="onMouseLeave()"
                            (mousedown)="onMouseDown($event)"
                            (mouseup)="onTextSelect($event, 'featureDescription')"
                            (select)="onSelectionChange($event, 'featureDescription')"
                            (keydown)="onSelectionChange($event, 'featureDescription')" >
                            </tiptap-editor>
                        </div>
                    </div>
                </div>
            </div>


            <!-- <div class="grid sm:grid-cols-4 gap-6 w-full mt-2" *ngIf="newSolutionForm.get('explanation').value" >
                <div class="sm:col-span-4">
                    <div >
                        <span class="font-medium block mb-2 text-black">Solution Explanation</span>
                        <textarea class="w-full" [formControlName]="'explanation'" name="solutionExplanation"></textarea>
                    </div>
                </div>
            </div> -->


            <div class="grid sm:grid-cols-4 gap-6 w-full mt-2 mb-20">
                <div class="sm:col-span-4 pb-6">
                    <div >
                        <span class="font-medium block mb-2 text-black">Solution Reason</span>

                        <div class="red-editor">
                            <!-- <tiptap-bubble-menu [editor]="solutionreasonEditor" [id]="'solutionreason'">
                                <app-editor-controls 
                                [editor]="solutionreasonEditor" 
                                [selectedText]="selectedText"
                                (completeQuickAction)="onCompleteQuickAction($event)">
                                </app-editor-controls>
                            </tiptap-bubble-menu>
                            
                            <tiptap-editor 
                            [id]="'solutionreason'"
                            [editor]="solutionreasonEditor" 
                            [formControlName]="'solutionAssumption'" name="solutionReason"
                            (blur)="onTextareaBlur()"
                            (mouseenter)="onMouseEnter()"
                            (mouseleave)="onMouseLeave()"
                            (mousedown)="onMouseDown($event)"
                            (mouseup)="onTextSelect($event, 'solutionreason')"
                            (select)="onSelectionChange($event, 'solutionreason')"
                            (keydown)="onSelectionChange($event, 'solutionreason')" >
                            </tiptap-editor> -->

                            <div class="tiptap" [innerHtml]="newSolutionForm.get('solutionAssumption').value">
          
                            </div>
                        </div>

                    </div>
                    <!-- <ng-template #disabledInput>
                        <span class="font-medium block mb-2 text-black">Solution Reason</span>
                        <tiptap-editor #featureEditor [id]="'llmdescriptionreadonly-'+solution._id" [editor]="editor_feature_readonly" [(ngModel)]="solution.solutionAssumption" name="solutionAssumption">
                        </tiptap-editor>
                    </ng-template> -->
                </div>
            </div>

        </div>
    </div>

    <!-- Buttons -->
    <div class="flex items-center  w-full border-t mt-6 py-4 gap-2 actionBt" *ngIf="(newSolutionForm.dirty && is_write_allowed)"  [ngClass]="{'justify-between' :(flashMessageType) , 'justify-end' :(!flashMessageType)  }" >
        <fuse-alert class="text-md" *ngIf="flashMessageType === 'success' && flashMessageType" type="success" [showIcon]="true" [appearance]="'soft'"> {{flashMessage}}</fuse-alert>

        <fuse-alert class="text-md" *ngIf="flashMessageType === 'error' && flashMessageType" type="warn" [showIcon]="true" [appearance]="'soft'"> An error occurred, try again!</fuse-alert>
        <div class="flex items-center gap-3">
            <button mat-flat-button [color]="'primary'" [disabled]="(!newSolutionForm.valid || isSavingSolution)" (click)="updateSelectedFeature()">
                <mat-progress-spinner *ngIf="isSavingSolution" mode="indeterminate"></mat-progress-spinner>
                Save
            </button>
        </div>
    </div>
</form>