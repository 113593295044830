import { JiraIntegrationComponent } from './modules/common/jira-integration/jira-integration.component';
import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { DetailComponent } from './modules/user/proposal/detail/detail.component';
import { OnboardingComponent } from './modules/user/onboarding/onboarding.component';
import { AgentsComponent } from './modules/user/agents/agents.component';
import { BuildComponent } from './modules/user/deploy/build.component';
import { AtlassianCallbackComponent } from './modules/user/atlassian-callback/atlassian-callback.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    //  {path: '', pathMatch : 'full', redirectTo: ''},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: redirectHandler()},

    // // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: '', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
            {path: 'copilot/:token', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')},
            {path: 'join/:token', loadChildren: () => import('app/modules/auth/join/join.routes')},
            {path: 'register', loadChildren: () => import('app/modules/auth/register/register.routes')},
            {path: 'knowledge-base', loadChildren: () => import('app/modules/admin/knowledge-base/knowledge-base.routes')},
            {path: 'prompts', loadChildren: () => import('app/modules/admin/prompts/prompts.routes')},
            {path: 'master-features', loadChildren: () => import('app/modules/admin/master-features/master-features.routes')},
            {path: 'coming-soon', loadChildren: () => import('app/modules/auth/coming-soon/coming-soon.routes')},
            {path: 'new-proposal', loadChildren: () => import('app/modules/auth/new-proposal/new-proposal.routes')},
            {path: 'get-recommendation', loadChildren: () => import('app/modules/auth/get-recommendation/get-recommendation.routes')},
            
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'onboarding',data: { roles: ["User"] }, component:OnboardingComponent},
            // {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
    //     ]
    // },

    // Common routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            // Settings
            {path: 'settings', loadChildren: () => import('app/modules/common/settings/settings.routes')},

        ]
    },

    // User routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { roles: ["User"] },
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'proposal', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/proposal/proposal.routes')},
            {path: 'project', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/project/project.routes')},
            {path: 'testing', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/testing/testing.routes')},
            {path: 'pay', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/pay/pay.routes')},
            {path: 'refer', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/refer/refer.routes')},
            {path: 'template', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/template/template.routes')},
            {path: 'template/:id', data: { roles: ["User"] }, component: DetailComponent},
            {path: 'atlassian/callback', data: { roles: ["User"] }, component: AtlassianCallbackComponent},
            {path: 'jira',data: { roles: ["User"] }, component:JiraIntegrationComponent},
            {path: 'onboarding',data: { roles: ["User"] }, component:OnboardingComponent},
            {path: 'agents',data: { roles: ["User"] }, component:AgentsComponent},
            {path: 'builds', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/builds/builds.routes')},
            {path: 'deploy',data: { roles: ["User"] }, component:BuildComponent},
            {path: 'support', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/support-agent/support-agent.routes')},
            {path: 'team', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/team/team.routes')},
            {path: 'help-center', data: { roles: ["User"] }, loadChildren: () => import('app/modules/user/help-center/help-center.routes')},
        ]
    },

    // PM routes
    {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { roles: ["Admin"] },
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'dashboard', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/dashboard/dashboard.routes')},
            {path: 'knowledge-base', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/knowledge-base/knowledge-base.routes')},
            {path: 'master-features', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/master-features/master-features.routes')},
            {path: 'technology-domains', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/technology-domains/technology-domain.routes')},
            {path: 'epics', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/epics/epic.routes')},
            {path: 'prompts', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/prompts/prompts.routes')},
            {path: 'pricing-plan', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/pricing-plan/pricing-plan.routes')},
            {path: 'feedback', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/feedback/feedback.routes')},
            {path: 'settings', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/settings/settings.routes')},
            {path: 'suggestions', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/suggestions/suggestions.routes')},
            {path: 'nps', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/nps/nps.routes')},
            {path: 'resources', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/resources/resources.routes')},
            {path: 'metadata', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/metadata/metadata.routes')},
            {path: 'phases', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/phases/phases.routes')},
            {path: 'playground', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/playground/playground.routes')},
            {path: 'datamodels', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/datamodels/datamodels.routes')},
            {path: 'knowledge-center', data: { roles: ["Admin"] }, loadChildren: () => import('app/modules/admin/playground/knowledge-center/knowledge-center.routes')}
        ]
    },

    // SME routes
    {
        path: 'sme',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { roles: ["SME"] },
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'dashboard', data: { roles: ["SME"] }, loadChildren: () => import('app/modules/admin/dashboard/dashboard.routes')},
            {path: 'knowledge-base', data: { roles: ["SME"] }, loadChildren: () => import('app/modules/admin/knowledge-base/knowledge-base.routes')}
        ]
    }
];

function redirectHandler(): string {
   // if(userSer)
    return "admin/dashboard";
}
