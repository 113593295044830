import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';

import { IPagination } from '../knowledge-base/knowledge-base.types';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';
import { IPhase } from './phase.types';

@Injectable({providedIn: 'root'})
export class PhaseService
{
    private _authService=inject(AuthService);
    private _httpClient = inject(HttpClient);
    private _phases: ReplaySubject<IPhase[]> = new ReplaySubject<IPhase[]>(1);
    private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for knowledgebase
     *
     * @param value
     */
    set resources(value: IPhase[])
    {
        // Store the value
        this._phases.next(value);
    }

    get resources$(): Observable<IPhase[]>
    {
        return this._phases.asObservable();
    }

     /**
     * Getter for pagination
     */
     get pagination$(): Observable<IPagination>
     {
         return this._pagination.asObservable();
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(pageIndex:number,search:string=""): Observable<IPhase[]>
    {
        let skip=pageIndex*10;

        let query='proposals?skip='+skip+'&limit=10';
        if(search!=""){
            query='proposals?skip='+skip+'&limit=10&searchText='+search;
        }

        return this._httpClient.get(environment.api_endpoint + query, { headers: { method: 'get_master_phase' } }).pipe(
            catchError(() =>
                // Return false
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response.success){      
                    this._pagination.next({
                        length: response.total,
                        size: 10,
                        page: pageIndex,
                        lastPage: 0,
                        startIndex: 0,
                        endIndex: (response.total-1)
                    });
                    this._phases.next(response.data);
                    return of(response.data);
                }else{
                    return of([]);
                }
            }),
        );
    }

    getByID(id:string): Observable<IPhase>
    {
        let query='proposals?id='+id;
        return this._httpClient.get(environment.api_endpoint + query, { headers: { method: 'get_phase_id' } }).pipe(
            catchError(() =>
                of([]),
            ),
            switchMap((response: any) =>
            {
                if(response.success){
                    return of(response.data);
                }else{
                    return of([]);
                }
            }),
        );
    }

    save(eachPhase:IPhase): Observable<any>
    {
       return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify(eachPhase), { headers: { method: 'save_master_phase' } }).pipe(
            catchError(() =>
                of({success:false}),
            ),
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }
    
    delete(phaseID:string): Observable<boolean>
    {
       return this._httpClient.post(environment.api_endpoint + 'proposals',JSON.stringify({_id:phaseID}), { headers: { method: 'delete_master_phase' } }).pipe(
            catchError(() =>
                of(false),
            ),
            switchMap((response: any) =>
            {
                if(response.success){
                    return of(true);
                }else{
                    return of(false);
                }
            }),
        );

    }

}
