<app-add-feature #addFeature (onDeleteFeature)="onDeleteFeatureCallback($event)"  (onSaveFeature)="onSaveFeature($event)" [themes]="themeTypes" [epics]="_proposalDetail.epics"  [releases_list]="releases_list"></app-add-feature>
<app-add-epic (onSaveEpic)="onSaveEpic($event)" [themeTypes]="themeTypes" #addEpic></app-add-epic>
<app-add-theme (onSaveTheme)="onSaveTheme($event)"  #addTheme></app-add-theme>

<div class="flex actBt flex-wrap items-center mt-8 mb-8 gap-3 justify-between">
  <div class="subHeadingCont">                                                
      <div class="text-black font-medium text-lg">Requirement Features</div>
      <div class="text-[#94A3B8] text-md font-normal">Project requirements categorized into themes, epics, and features.</div> 
  </div>
      <!-- Add product button -->
  <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="add-get-ai-bt flex gap-3">
      <button class="addThemeBt" mat-flat-button [color]="'primary'" (click)="onAddTheme()"  >
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="">Add Theme </span>
      </button>
      <button class="bg-[#2C3344] text-white addEpicBt" mat-flat-button  (click)="onAddEpic()"  >
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="">Add Epic </span>
    </button>
    <button class="addRequirementBt" mat-flat-button  (click)="onAddNewFeature()" >
      <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
      <span class="">Add Requirement </span>
  </button>
  </div>
</div>
<div class="bg-card rounded-lg shadow  py-3 mt-6">
    <!-- Header Section -->
<section class="flex flex-row flex-wrap justify-between px-3">
  <div class="flex expandBox border" >
    <mat-icon svgIcon="heroicons_outline:bars-4" (click)="onToggleAccordion(0)" [ngClass]="{'active':expandState==0 }"></mat-icon>
    <mat-icon svgIcon="heroicons_outline:bars-3" (click)="onToggleAccordion(1)" [ngClass]="{'active':expandState==1 }"></mat-icon>
    <mat-icon svgIcon="heroicons_outline:bars-2" (click)="onToggleAccordion(2)" [ngClass]="{'active':expandState==2 }"></mat-icon>
      <!-- <mat-icon *ngIf="expandState==0 || expandState==1" class="expandIc" svgIcon="heroicons_outline:arrows-pointing-out"></mat-icon>
      <mat-icon *ngIf="expandState==2" class="collapseIc" svgIcon="heroicons_outline:arrows-pointing-in"></mat-icon> -->
  </div>
    <form class="flex flex-wrap gap-2 justify-center " [formGroup]="inputFormGroup">
        <mat-form-field class="fuse-mat-dense searchTab">
            <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
            <input matInput formControlName="search" [autocomplete]="'off'" [placeholder]="'Search'" (input)="search($event)">
        </mat-form-field>
        <!-- Add filters -->
        <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 ">
            <mat-select
                [formControlName]="'theme'"
                [placeholder]="'Themes'" multiple (selectionChange)="onChangeTheme($event)">
                <!-- <mat-option #allSelected [value]="0">All</mat-option> -->
                @for (option of themeTypes; track option) {
                    <mat-option [value]="option.value">{{option.label}} </mat-option>
                }
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
            <!-- <img src="assets/icons/theme_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
        </mat-form-field>
        <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab  mw-100 ">
            <mat-select
                [formControlName]="'epic_name'"
                [placeholder]="'Epic Name'" multiple (selectionChange)="onChangeEpic($event)">
                @for (option of epicTypes; track option) {
                    <mat-option [value]="option.value">{{option.label}}</mat-option>
                }
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
            <!-- <img src="assets/icons/epic_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
        </mat-form-field>
        <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab  mw-100 ">
          <mat-select
              [formControlName]="'release'"
              [placeholder]="'Release'" multiple (selectionChange)="onChangeEpic($event)">
              @for (option of releases_list; track option) {
                  <mat-option [value]="option._id">{{option.name}}</mat-option>
              }
          </mat-select>
          <!-- <img src="assets/icons/epic_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
        </mat-form-field>


        <!-- <div class="flex gap-2 justify-center">
          <div class="relative">
              <button class="" mat-flat-button [color]="'primary'" (click)="onAddTheme()"> 
              
              <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="">Add Theme </span>

          </button>
        
        </div>
      
        <div class="relative">
          <button class="bg-[#2C3344] text-white" mat-flat-button (click)="onAddEpic()">
              <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="">Add Epic </span>
          </button>
      
        </div>
        </div> -->


    </form>

</section>

<!-- Requirements Section -->

<ng-container>

    <div class="max-w-full flex flex-col mt-6">


          <mat-accordion class="max-w-full" [multi]="true" [displayMode]="'flat'" [hideToggle]="false" cdkDropList (cdkDropListDropped)="dropTheme($event)" [cdkDropListData]="requirements">
            <ng-container *ngFor="let requirement of requirements;let themeIndex=index; trackBy: trackByFn" >
                <mat-expansion-panel
                    class="border-none w-full parentTab"
                    [(expanded)]="themesExpanded[themeIndex]"
                    [cdkDrag]="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                    [cdkDragLockAxis]="'y'"
                    >
        
                    <mat-expansion-panel-header class="group w-full select-none h-15  font-semibold border-b border-t " >
                        <mat-panel-title class="flex items-center w-full pl-5" >
                            <div class="flex justify-between items-center flex-auto mr-2 truncate text-lg">
                                <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                                        class="md:hidden absolute ml-2 flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                        cdkDragHandle>
                                        <mat-icon
                                            class="icon-size-5 text-hint"
                                            [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                    </div>
                                <span>{{(themeIndex+1)}}. {{requirement.theme}}</span>
                                
                                <div class="flex justify-end group" *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" >
                                  <div class="gap-2 mr-2 opacity-0 group-hover:opacity-100">
                                    <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="onEditTheme(requirement);$event.stopPropagation()"></mat-icon>
                                    <mat-icon (click)="onDeleteTheme(requirement);$event.stopPropagation()" class="smallIc cursor-pointer" svgIcon="heroicons_outline:trash"></mat-icon>
                                  </div>
                                </div>


                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
        
                    <!-- Theme Requirements inside each accordion item -->
                    <div class="questions-container  max-w-full">
                        
                        <mat-accordion [multi]="true"  [cdkDropList]="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                        [cdkDropListData]="requirement.epics"
                        (cdkDropListDropped)="dropEpic($event,themeIndex)">
                            <ng-container *ngFor="let epic of requirement.epics;let epicIndex=index;" >
                              <mat-expansion-panel [(expanded)]="epicsExpanded[getEpicKey(themeIndex,epicIndex)]" class="childTab" [cdkDrag]="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                              [cdkDragLockAxis]="'y'" >
                                <mat-expansion-panel-header class="pl-14 tabBorder group w-full select-none h-15 font-semibold border-b border-t"
                               
                                >
                                  <mat-panel-title class="flex items-center w-full pl-5" 
                                  >

                                    <div class="flex justify-between items-center flex-auto mr-2 truncate text-lg">
                                      <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                                              class="md:hidden absolute ml-12 flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                              cdkDragHandle
                                              >
                                              <mat-icon
                                                  class="icon-size-5 text-hint"
                                                  [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                          </div>
                                      <span>{{(themeIndex+1)}}.{{(epicIndex+1)}}. {{epic.name}}</span>
                                      
                                      <div class="flex justify-end group">
                                        <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="gap-2 mr-2 opacity-0 group-hover:opacity-100" >
                                          <mat-icon class="smallIc" (click)="onEditEpic(requirement.theme,epic.name);$event.stopPropagation()" svgIcon="heroicons_outline:pencil-square"></mat-icon>
                                          <mat-icon class="smallIc"  (click)="onDeleteEpic(requirement.theme,epic.name);$event.stopPropagation()"  svgIcon="heroicons_outline:trash"></mat-icon>
                                        </div>
                                      </div>
                                      </div>

                                     

                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                
                                <div  [cdkDropList]="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                                [cdkDropListData]="epic.features"
                                (cdkDropListDropped)="dropFeature($event,themeIndex,epicIndex)">
                                <ng-container *ngFor="let feature of epic.features; let i = index">
                                  <div
                                  [cdkDrag]="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                                  [cdkDragLockAxis]="'y'"
                                    [id]="'requirement-' + i"
                                    class="relative group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0 mt-3 customB ">
                                    <!-- Requirement content -->
                                    <div class="relative flex items-center h-full py-2" >
                                      
                                      <div class="grid pl-12 grid-cols-12 sm:gap-2 gap-3 min-w-full items-center h-full pr-7 cursor-pointer ">
                                        <div class="flex sm:col-span-10 col-span-9 w-full sm:mr-2 mx-2 sm:text-md text-md text-black font-regular textTab">
                                          <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)"
                                          class="md:hidden absolute -ml-2 flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                          cdkDragHandle
                                          
                                          >
                                          <mat-icon
                                              class="icon-size-5 text-hint"
                                              [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                      </div>
                                          <div class="ml-6">
                                          {{ feature.title }}
                                          </div>
                                        </div>
                                        <div class="flex justify-end col-span-2 group actionReq" *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)">
                                          <div class="flex justify-end col-span-1 group">
                                            <mat-select [value]="feature.release" class="selectOption"  (selectionChange)="onRequirementReleaseSelection($event,requirement.theme,epic.name,feature,i)">
                                              @for (release of releases_list; track release) {
                                                <mat-option [value]="release._id">{{release.short_name}}</mat-option>
                                              }
                                            </mat-select>
                                          </div>
                                          <div class="gap-2 mr-2 opacity-0 group-hover:opacity-100">
                                            <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="onEditFeature(requirement.theme,epic.name,feature,i)"></mat-icon>
                                            <mat-icon (click)="onDeleteFeature(requirement.theme,epic.name,feature,i)" class="smallIc cursor-pointer" svgIcon="heroicons_outline:trash"></mat-icon>
                                          </div>
                                          
                                        </div>
                                      </div>
                                      <div class="min-w-full separator left-0 right-0 absolute bottom-0"></div>
                                    </div>
                                  </div>
                                </ng-container>
                                </div>
                                <div  *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="cursor-pointer ml-15 text-md py-5 textTab2 flex" (click)="onAddNewFeatureForEpic(requirement.theme,epic.name)">  
                                  <mat-icon class="text-[#9AA6B8]" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                  <div  class="pl-2 text-md text-[#9AA6B8]">Add Requirement</div>
                                </div>
                          
                              </mat-expansion-panel>
                            </ng-container>
                          </mat-accordion>
                          
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
        
        

    </div>

</ng-container>



</div>

