<section>
        
    <div class="bg-card m-bg-card storyTableBox rounded-2xl shadow ml-16 mr-10 my-10">

       <section>  
        <div class="grid table-grid  min-w-full px-5 gap-3 py-5">
          <div class="flex justify-start  text-md font-medium uppercase ml-8  ">Story ID</div>
          <div class="flex justify-start  text-md font-medium uppercase ">Title</div>
          <div class="flex justify-start  text-md font-medium uppercase ">Status</div>
          <div class="flex justify-start  text-md font-medium uppercase ">Priority</div>
          <div class="flex pl-4 justify-center text-md font-medium uppercase ">Jira Connection</div>
        </div>
       </section>
          
          <section>
            <div class="w-full" cdkDropList (cdkDropListDropped)="dropped($event)" [cdkDropListData]="storyViewList">
              
              @for(story of storyViewList;track story){
              <div  class="relative group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0 mt-3 customB border-b" cdkDrag >
                <!-- Content for each section -->
                <div class="grid table-grid gap-3 items-center py-3 px-5 tabBorder">
                  <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" class="absolute left-0 pl-3 h-full flex items-center justify-center opacity-0 group-hover:opacity-100">
                    <mat-icon class="cursor-move" [svgIcon]="'heroicons_solid:bars-3'" cdkDragHandle></mat-icon>
                  </div>
                  <div class="flex flex-wrap justify-start  text-md text-black font-regular ml-8 ">
                    {{getStoryID(story)}}
                  </div>
                  <div class=" text-md text-black font-regular line-clamp-2">
                    {{story.title}}
                  </div>
                  <div class="flex flex-wrap justify-start  text-md text-black font-regular">
                    <span   [ngClass]="{ 'todoTag': (getStatusString(story.status) == 'To Do') , 'inprogTag': (getStatusString(story.status) == 'In Progress')  ,'doneTag': (getStatusString(story.status) == 'Done') }"  *ngIf="story.status">{{getStatusString(story.status)}}</span>
                  </div>
                  <div class="flex flex-wrap justify-start  text-md text-black font-regular">
                    <span class="med-story">
                      <img [src]="getPriority(story.priority).icon"> {{getPriority(story.priority).label}}
                    </span>
                  </div>
                 
                  <div class="flex flex-wrap  text-md text-black font-regular"  [ngClass]="is_write_allowed ? 'justify-end' : 'justify-center'">

                    <div  class="flex flex-wrap justify-start gap-2 pl-4 items-center text-md text-black font-regular cursor-pointer">
                      


                      @if(getIntegrationStatus()){
                        <span
                        class="jiraTag cursor-pointer"
                        (click)="syncSingleStory(story)"
                        *ngIf="!story.jiraSynced && !story.isSyncing"
                      >
                        <span class="flex gap-1 items-center jiraBlue" >
                          <img  src="assets/icons/jirablue.png" alt=""> Sync
                        </span>
                      </span>

                      <span class="flex gap-1 items-center text-gray-400"  *ngIf="!story.jiraSynced && story.isSyncing">
                        <img
                        [ngClass]="'rotate-animation'"
                        src="assets/icons/sync_icon.svg"
                        class="w-6 h-6"
                        />
                        <img  src="assets/icons/jiragrey.png" class="w-5 h-5" alt=""> Syncing
                      </span>
                    

                    <span
                    *ngIf="story.jiraSynced"
                      class="jiraTag cursor-pointer"
                      (click)="viewStoryInJira(story)"
                    >
                      <span class="flex gap-1 items-center jiraBlue"  >
                        <img  src="assets/icons/jirablue.png" alt=""> View  <mat-icon  class="icon-size-5" svgIcon="heroicons_outline:arrow-top-right-on-square"></mat-icon>
                      </span>
                    </span>




                    }@else {
                      <span class="flex gap-1 items-center">
                        <img  src="assets/icons/jiragrey.png" class="w-5 h-5" alt=""> Not Synced
                      </span>
                    }




                      


                    <div *ngIf="is_write_allowed" class="releaseTagoption">
                        <mat-select [value]="(story.release && story.release['_id']?story.release['_id']:'')" class="selectOption" (selectionChange)="onStoryReleaseSelection($event,story)">
                          @for (release of releases_list; track release) {
                            <mat-option [value]="release._id">{{release.short_name}}</mat-option>
                          }
                        </mat-select>
                    </div>
                    <div *ngIf="(!is_write_allowed && story.release && story.release['_id'])" class="releaseTagoption selectOption text-center">
                        {{getReleaseValue(story.release['_id'])}}
                    </div> 

                      <div *ngIf="is_write_allowed" class="flex justify-end group gap-2">
                        <div  class="flex gap-2 group-hover:opacity-100 items-center mr-2 opacity-0">
                          <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="onEditStory(story)" ></mat-icon>
                          <img *ngIf="story.delete"   [ngClass]="'rotate-animation'" src="assets/icons/sync_icon.svg" class="w-6 h-6">
                          <mat-icon class="smallIc cursor-pointer"  *ngIf="!story.delete"  svgIcon="heroicons_outline:trash" (click)="onDeleteStory(story)"></mat-icon>
                        </div>
                        
                      </div>
                    </div>

                   
                  </div>
                 
                </div>
              </div> 
            }
                                                  
            </div>
          
          </section>
          
          <!-- <section>
              <p class="noSolution">No User Story Found</p>
          </section> -->

          
            <!-- Add Feature Button -->
            <div *ngIf="(is_write_allowed)"  class="min-w-full flex pl-10 py-5 items-center justify-start relative border-t cursor-pointer" (click)="openDialog()">
              <mat-icon class="text-[#9AA6B8]" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
              <div class="pl-2 text-md text-[#9AA6B8]">Add User Story</div>
             
            </div>

    </div>


</section>