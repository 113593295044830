<div class="flex actBt flex-col gap-3 mb-6 ">
    <div class="subHeadingCont">                                                
        <div class="text-black font-medium text-lg">Integrations</div>
        <div class="text-[#94A3B8] text-md font-normal mt-1">Integrates with all AI development tools to automate code creation.</div> 
    </div>


    <div class="integrationList flex flex-col w-10/12 gap-3">

        <div class="flex bg-card rounded p-2 justify-between items-center">
            <div class="flex gap-2 items-center">
                <img src="assets/icons/agentforce.png" class="w-10 h-10" alt="">
                <div>
                    <h4 class="font-medium text-lg">Agentforce for Developers</h4>
                    <p class="font-normal text-md text-secondary">Empower Developers with Real-Time Assistance</p>
                </div>
            </div>
            <span class="connectedBt">Connected</span>
        </div>
        <div class="flex bg-card rounded p-2 justify-between items-center">
            <div class="flex gap-2 items-center">
                <img src="assets/icons/agentforce.png" class="w-10 h-10" alt="">
                <div>
                    <h4 class="font-medium text-lg">Agentforce for Admins</h4>
                    <p class="font-normal text-md text-secondary">Empower Administrators with Real-Time Assistance</p>
                </div>
            </div>
            <span class="connectedBt">Connected</span>
        </div>
        <div class="flex bg-card rounded p-2 justify-between items-center">
            <div class="flex gap-2 items-center">
                <img src="assets/icons/codegen.png" class="w-10 h-10" alt="">
                <div>
                    <h4 class="font-medium text-lg">Codegen</h4>
                    <p class="font-normal text-md text-secondary">Automate Code Generation for Faster Development</p>
                </div>
            </div>
            <span class="connectedBt">Connected</span>
        </div>

        <div class="flex bg-card rounded p-2 justify-between items-center">
            <div class="flex gap-2 items-center">
                <img src="assets/icons/githubs.png" class="w-10 h-10" alt="">
                <div>
                    <h4 class="font-medium text-lg">Github</h4>
                    <p class="font-normal text-md text-secondary">Streamline Collaboration and Code Management</p>
                </div>
            </div>
            <span class="connectBt">Connect</span>
        </div>

        <div class="flex bg-card rounded p-2 justify-between items-center">
            <div class="flex gap-2 items-center">
                <img src="assets/icons/codean.png" class="w-10 h-10" alt="">
                <div>
                    <h4 class="font-medium text-lg">Code Analyzer 5.0</h4>
                    <p class="font-normal text-md text-secondary">Enhance Code Quality with In-Depth Analysis</p>
                </div>
            </div>
            <span class="connectBt">Connect</span>
        </div>

        <div class="flex bg-card rounded p-2 justify-between items-center">
            <div class="flex gap-2 items-center">
                <img src="assets/icons/atlassian.png" class="w-10 h-10" alt="">
                <div>
                    <h4 class="font-medium text-lg">Atlassian</h4>
                    <p class="font-normal text-md text-secondary">Organize and Track Your Projects Efficiently</p>
                </div>
            </div>
            <span class="connectBt">Connect</span>
        </div>

        <div class="flex bg-card rounded p-2 justify-between items-center">
            <div class="flex gap-2 items-center">
                <img src="assets/icons/gears.png" class="w-10 h-10" alt="">
                <div>
                    <h4 class="font-medium text-lg">Gearset</h4>
                    <p class="font-normal text-md text-secondary">Simplify Salesforce Deployments and Rollbacks</p>
                </div>
            </div>
            <span class="connectBt">Connect</span>
        </div>
        <div class="flex bg-card rounded p-2 justify-between items-center">
            <div class="flex gap-2 items-center">
                <img src="assets/icons/copado.png" class="w-10 h-10" alt="">
                <div>
                    <h4 class="font-medium text-lg">Copado</h4>
                    <p class="font-normal text-md text-secondary">Manage Salesforce DevOps with Ease</p>
                </div>
            </div>
            <span class="connectBt">Connect</span>
        </div>

    </div>
   
</div>