<div class="flex actBt flex-wrap items-center mb-8 gap-3 justify-between">
  <div class="subHeadingCont">                                                
      <div class="text-black font-medium text-lg">File Manager</div>
      <div class="text-[#94A3B8] text-md font-normal">View list of all the inputs and respective insights generated from the documents.</div> 
  </div>
      <!-- Add product button -->
  <div  class="add-get-ai-bt flex gap-3">
        <div class="w-full">
            <input id="file" name="file" style="display:none;" type="file" #fileInput (change)="onFilesSelected($event)">
        </div>
        <button *ngIf="(accessStatus!='view')" class="uploadBt" mat-flat-button [color]="'primary'" (click)="selectDoc()">
            <mat-icon *ngIf="!upload_loader" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <mat-spinner *ngIf="upload_loader" class="matSpinner" [diameter]="24" [mode]="'indeterminate'"></mat-spinner>
            <span class="">Upload File </span>
        </button>
  </div>
</div>
<div class="bg-card rounded-lg shadow  py-3 mt-6">
    <!-- Header Section -->
    <section class="flex flex-row flex-wrap justify-end px-3">
        <form class="flex flex-wrap gap-2" [formGroup]="headerForm" *ngIf="headerForm">
            <mat-form-field class="fuse-mat-dense searchTab">
                <mat-icon class="icon-size-5 mr-1" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                <input matInput class="text-sm font-medium" [formControlName]="'search'" [autocomplete]="'off'" [placeholder]="'Search'" (input)="search($event)">
            </mat-form-field>
            <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 ">
                <mat-select [formControlName]="'selected_tag'" [placeholder]="'Tags'" multiple (selectionChange)="filterCount()">
                    @for (option of all_tags; track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix  svgIcon="heroicons_outline:tag"></mat-icon>
            </mat-form-field>
        </form>

    </section>

    <div class="tableSec mt-6">
        <div class="tablehead p-2" *ngIf="all_docs.length>0">
            <div class="iconb2"></div>
            <div class="fileName">
                <h4 class="text-md font-medium text-secondary uppercase">File Name</h4>
            </div>
            <div class="tagsd">
                <h4 class="text-md font-medium text-secondary uppercase">Tags</h4>
            </div>
            <div class="iconb">

            </div>
        </div>
        <div class="tablecontentRow" *ngIf="all_docs.length>0">
            <div class="tableContent p-2" *ngFor="let file of all_docs;i as index">
                <div class="iconb2" (click)="selectDocument(file);docDrawer.toggle()">
                    <div class="relative inline">
                        <mat-icon class="icon-size-12" matPrefix svgIcon="heroicons_outline:document"></mat-icon>
                        <div class="typeText absolute bottom-0 left-0 rounded px-1.5 text-xs font-semibold leading-5 text-white" [ngClass]="getFileClass(file.type)"> {{getFileTypeString(file.type)}} </div>
                    </div>
                </div>
                <div class="fileName flex flex-col" (click)="selectDocument(file);docDrawer.toggle()">
                    <h6 class="font-semibold break-all" [matTooltip]="file.title">{{file.title}}</h6>
                    <p class="font-normal">{{format(file.created,'initial')}}, by {{ (file.author && file.author.personal && file.author.personal.name && file.author.personal.name.first) ? file.author.personal.name.first.replace('undefined', '').trim() : '' }} {{ (file.author && file.author.personal && file.author.personal.name && file.author.personal.name.last) ? file.author.personal.name.last.replace('undefined', '').trim() : '' }}</p>
                </div>
                <div class="tagsd flex items-center" (click)="selectDocument(file);docDrawer.toggle()">
                    <ul *ngIf="file.tags?.length">
                        <li *ngFor="let eachtag of file.tags.slice(0, 4)">{{ eachtag }}</li>
                        <li *ngIf="file.tags.length > 4">+ {{ file.tags.length - 4 }} More</li>
                    </ul>

                    <button *ngIf="(!file.tags || (file.tags && !file.tags.length))" class="addTagBt" > 
                        <mat-icon *ngIf="(accessStatus!='view')" svgIcon="heroicons_solid:plus-circle"></mat-icon> 
                        {{(accessStatus!='view')?'Add':'View'}} 
                    </button>
                </div>
                <div class="iconb flex items-center justify-between pr-5">
                    <button class="chatBt" (click)="openDialog(file)">
                        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:chat-bubble-left-right"></mat-icon> Chat 
                    </button>
                    <span class="pointer" (click)="selectDocument(file);docDrawer.toggle()">
                        <mat-icon svgIcon="heroicons_outline:chevron-right"></mat-icon>
                    </span>
                </div>
            
            </div>
        </div>
        <div *ngIf="(!all_docs || all_docs.length==0)" class="flex flex-col gap-3 justify-center mt-5 ng-star-inserted notfound p-16 text-[#64748B] text-center w-full">
            <div class="notfound">
                <mat-icon svgIcon="heroicons_outline:document-minus"></mat-icon>
                No documents yet. Upload one to start your analysis. Ask questions in the chat and save key insights to display here
            </div>
        </div>
    </div>



</div>






<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'documentDrawer'"
    [position]="'right'" 
    (openedChanged)="openedChanged($event)"
    #docDrawer>
   
    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex justify-end w-100" >
            <button
                mat-icon-button
                [matMenuTriggerFor]="moreMenu">
                <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
            </button>
            <mat-menu #moreMenu="matMenu">
                <button mat-menu-item *ngIf="(accessStatus!='view')" (click)="onDeleteSupportingDocument(selectedFile)">
                    <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                    <span>Delete </span>
                </button>
                <button (click)="downloadFile(selectedFile.fileName)" mat-menu-item>
                    <mat-icon [svgIcon]="'heroicons_outline:archive-box-arrow-down'"></mat-icon>
                    <span>Download </span>
                </button>
            </mat-menu>
            <button mat-icon-button (click)="onCloseDrawer()" >
                <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <form class="flex flex-col flex-auto p-2"
        [formGroup]="documentForm" *ngIf="documentForm">
            <div class="flex flex-col ml-3 mr-3" *ngIf="selectedFile">
                <h5 class="font-medium text-xl break-all">{{selectedFile.title}}</h5>
                <div>                
                    <span class="bottom-0 left-0 rounded px-1.5 text-xs font-semibold leading-5 text-white " [ngClass]="getFileClass(selectedFile.type)"> {{getFileTypeString(selectedFile.type)}} </span>
                </div>

                <div class="infoTab mt-6">
                    <h5 class="text-md font-medium border-b p-2">Information</h5>
                    <div class="flex justify-between text-md border-b p-2 ">
                        <span class="text-gray-400">Created By</span>
                        <span>{{ (selectedFile.author && selectedFile.author.personal && selectedFile.author.personal.name && selectedFile.author.personal.name.first) ? selectedFile.author.personal.name.first.replace('undefined', '').trim() : '' }} {{ (selectedFile.author && selectedFile.author.personal && selectedFile.author.personal.name && selectedFile.author.personal.name.last) ? selectedFile.author.personal.name.last.replace('undefined', '').trim() : '' }}</span>
                    </div>
                    <div class="flex justify-between text-md border-b p-2">
                        <span class="text-gray-400">Created At</span>
                        <span>{{format(selectedFile.created,'comma')}}</span>
                    </div>
                    <div class="flex justify-between text-md border-b p-2">
                        <span class="text-gray-400">Size</span>
                        <span>{{formatFileSize(selectedFile.fileSize)}}</span>
                    </div>

                </div>

                <div class="descSec">
                    <div class="descEdit flex justify-between border-b p-2">
                        <h5 class="font-medium">Description</h5>
                        <span><mat-icon class="icon-size-3" svgIcon="heroicons_solid:pencil"></mat-icon></span>
                        
                    </div>
                    <mat-form-field class="flex-auto w-full mt-2  gt-xs:pl-3">
                        <textarea matInput [formControlName]="'description'"  placeholder="Click here to add a description" id="" class="border m-0 p-2 rounded w-full text-sm" style="resize: none;" [rows]="5" cdkTextareaAutosize [readonly]="(accessStatus=='view')"></textarea>
                    </mat-form-field>
                </div>
                <div class="tagSec mt-6">
                    <h5 class="text-md font-medium">Tags</h5>
                    <div class="flex flex-wrap items-center ">
                        <!-- Tags -->
                        <ng-container *ngIf="tagArray.controls.length">
                            <ng-container *ngFor="let eachtag of tagArray.controls; let i = index">
                                <div class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                                    <span class="text-md font-medium whitespace-nowrap">{{eachtag.value}}</span>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="(accessStatus!='view')"
                            class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                            (click)="openTagsPanel()"
                            #tagsPanelOrigin>
                            <ng-container *ngIf="tagArray.controls.length">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                                <span class="ml-1.5 text-md font-medium whitespace-nowrap">Edit</span>
                            </ng-container>

                            <ng-container *ngIf="!tagArray.controls.length">
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                <span class="ml-1.5 text-md font-medium whitespace-nowrap">Add</span>
                            </ng-container>
        
                            <!-- Tags panel -->
                            <ng-template #tagsPanel>
                                <div class="w-60 rounded border shadow-md bg-card mt-8">
                                    <!-- Tags panel header -->
                                    <div class="flex items-center m-3 mr-2">
                                        <div class="flex items-center">
                                            <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                            <div class="ml-2">
                                                <input
                                                    class="w-full min-w-0 py-1 border-0"
                                                    type="text"
                                                    placeholder="Enter Tags"
                                                    (input)="filterTags($event)"
                                                    (keydown)="filterTagsInputKeyDown($event)"
                                                    [maxLength]="30"
                                                    #newTagInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
                                        <!-- Tags -->
                                        <ng-container *ngIf="!tagsEditMode">
                                            <ng-container *ngFor="let eachtag of filteredTags; trackBy: trackByFn">
                                                <div
                                                    class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                                                    (click)="toggleTaskTag(eachtag)"
                                                    matRipple>
                                                    <mat-checkbox
                                                        class="flex items-center h-10 min-h-10 pointer-events-none"
                                                        [checked]="tagArray.value.includes(eachtag.label)"
                                                        [color]="'primary'"
                                                        [disableRipple]="true">
                                                    </mat-checkbox>
                                                    <div class="ml-1">{{eachtag.label}}</div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <!-- Tags editing -->
                                        <!-- <ng-container *ngIf="tagsEditMode">
                                            <div class="py-2 space-y-2">
                                                <ng-container *ngFor="let tag of filteredTags; trackBy: trackByFn">
                                                    <div class="flex items-center">
                                                        <mat-form-field
                                                            class="fuse-mat-dense w-full mx-4"
                                                            [subscriptSizing]="'dynamic'">
                                                            <input
                                                                matInput
                                                                [value]="tag.title"
                                                                (input)="updateTagTitle(tag, $event)">
                                                        </mat-form-field>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container> -->
                                        <!-- Create tag -->
                                        <div
                                            class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer hover:bg-hover"
                                            *ngIf="shouldShowCreateTagButton(newTagInput.value)"
                                            (click)="createTag(newTagInput.value); newTagInput.value = ''"
                                            matRipple>
                                            <mat-icon
                                                class="mr-2 icon-size-5"
                                                [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                            <div class="break-all">Create "<b>{{newTagInput.value}}</b>"</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <div class="actionTag mt-6 flex flex-col gap-6">
                    
                    <div class="flex gap-6">
                        <!-- <button class="downloadBt" (click)="downloadFile(selectedFile.fileName)">Download</button>
                        <button *ngIf="(accessStatus!='view')" class="deleteBt border" (click)="onDeleteSupportingDocument(selectedFile)">
                            Delete
                        </button> -->

                        <button class="downloadBt savedoc w-50 flex gap-2 items-center justify-center" *ngIf="(accessStatus!='view')" (click)="autoSave()">
                            <mat-spinner *ngIf="autosave_loader" class="matSpinner" [diameter]="24" [mode]="'indeterminate'"></mat-spinner>
                            Save
                        </button>

                        <button class="drawerchatBt flex items-center justify-center" (click)="openDialog(selectedFile)"> <mat-icon class="icon-size-5 text-white" svgIcon="heroicons_outline:chat-bubble-left-right"></mat-icon> Chat with AI</button>
                    </div>
                    <div class="my-1 mb-2" *ngIf="docSaved">
                        <fuse-alert *ngIf="docSaved"
                            [appearance]="'soft'"
                            [showIcon]="false"
                            type="success"
                            [dismissible]="true" [dismissed]="false">
                            Saved successfully!
                        </fuse-alert>
                    </div>
                    
                    
                </div>

            </div>
        </form>
    </div>
</fuse-drawer>