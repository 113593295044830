<div class="flex actBt deployBox flex-col gap-3 mb-6 ">
    <div class="breadcrumb text-sm text-[#656A77]">Configurations <span class="text-[#4F45E4]">/Apex Classes</span></div>
    <div class="subHeadingCont">                                                
        <div class="text-black font-medium text-lg">Apex Class Configuration</div>
        <div class="text-[#94A3B8] text-md font-normal mt-1">Build and deploy apex code directly into your Salesforce environment.</div> 
    </div>

    <div class="connectSalesforce bg-card p-4 flex flex-col rounded">
        <div class="subHeadingCont">                                                
            <div class="text-black font-medium text-lg">Connect your Salesforce Org</div>
            <div class="text-[#94A3B8] text-md font-normal mt-1">Connect your Salesforce org to access metadata and deploy, push, or pull code directly.</div> 
        </div>
        <div class="flex items-center justify-between salesforce-inp mt-4">
            <div class="bg-[#E2E8F0] flex h-10 items-center px-4 py-1 rounded text-[#656A77] text-md w-10/12">Salesforce Org ID: 00Dxx0000001Y2uEAE</div>
            <button mat-button class="rounded primaryBt" [color]="'primary'" > Reauthorize</button>
        </div>
    </div>


    <div class="apexBoard bg-card p-6 rounded">
        <div class="fieldBox mb-6">
            <label for="" class="font-medium mb-3">Apex Class Name</label>
            <div class="border flex mt-1 p-3 rounded rounded-lg">
                AccountController.apex
            </div>
        </div>

        <div class="fieldBox mb-6">
            <label for="" class="font-medium mb-3">Apex Class Description</label>
            <div class="border flex mt-1 p-3 rounded rounded-lg">
                A random account controller in an Apex class typically handles the logic for creating, retrieving, or modifying account records in Salesforce. It might include methods to generate a random account, fetch random accounts based on certain criteria, or perform bulk operations on account records. 
            </div>
        </div>

        <div class="fieldBox mb-6">
            <label for="" class="font-medium mb-3">AI Instructions</label>
            <div class=" flex flex-col mt-1 rounded rounded-lg InstructionBoard">
                <div class="content p-5">
                    <p>Write an Apex class, RandomAccountController, that includes methods to:</p>
                    <ul class="list-decimal mb-4 pl-4">
                        <li>Generate a Random Account: Create a method that generates a new Account with random data (e.g., random Account Name, Type, Industry, etc.) and saves it to the Salesforce database.</li>
                        <li>Retrieve Random Account(s): Create a method that retrieves a random Account from the database based on specific criteria (e.g., Industry or Account Type).</li>
                        <li>Test Method: Write a test method that ensures the correct creation of random Account records and verifies the retrieval logic works properly.</li>
                    </ul>
                </div>
                <div class="seprator"></div>
                <div class="inaction p-3">
                    <ul class="flex gap-3">
                        <li class="border px-2 py-0.5 rounded text-sm flex gap-1">
                            <img src="assets/icons/github.svg" alt="">
                            <span>Generate via Github CoPilot</span>
                        </li>
                        <li class="border px-2 py-0.5 rounded text-sm flex gap-1"> 
                            <img src="assets/icons/salesforce.svg" alt="">
                            <span>Generate via XGen</span>
                        </li>
                        <li class="border px-2 py-0.5 rounded text-sm flex gap-1">
                            <img src="assets/icons/getgen.svg" alt="">
                            <span>Use GetGenerative.ai </span>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="fieldBox">
            <label for="" class="font-medium mb-3">Generating Code</label>

            <div class="border flex flex-col mt-1 rounded rounded-lg ">
                <div class="content p-5 " >
                    <pre><code>{{codeString}}</code></pre>
                </div>
                <div class="seprator"></div>
                <div class="inaction p-3">
                    <ul class="flex gap-3">
                        <li class="border px-2 py-0.5 rounded text-sm flex gap-1">
                            <img src="assets/icons/regenrate.svg" alt="">
                            <span>Regenerate</span>
                        </li>
                        <li class="border px-2 py-0.5 rounded text-sm flex gap-1"> 
                            <img src="assets/icons/retestb.svg" alt="">
                            <span>Retest</span>
                        </li>
                        <li class="border px-2 py-0.5 rounded text-sm flex gap-1">
                            <img src="assets/icons/deployb.svg" alt="">
                            <span>Deploy </span>
                        </li>
                    </ul>
                </div>

            </div>

        </div>

        <div class="action mt-6">
            <div class="flex justify-end gap-6">
                <button class="closeBt">Close</button>
                <button class="saveBt">Save</button>
            </div>
        </div>
    </div>
   
</div>