import { tags } from '../../../../mock-api/apps/tasks/data';
import { trigger } from '@angular/animations';
import { Component, ElementRef, Inject, ViewChild,TemplateRef,ViewContainerRef } from '@angular/core';

import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Overlay, OverlayRef,OverlayContainer } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JIRA } from 'app/core/common/constants';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { DatePipe, NgIf,NgFor, NgClass } from '@angular/common';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { DialogRef } from '@angular/cdk/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseAlertType } from '@fuse/components/alert';
import { CommonService } from 'app/core/common/common.service';
import { firstValueFrom } from 'rxjs';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { environment } from 'environments/environment';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import Heading from '@tiptap/extension-heading';
import { NgxTiptapModule } from 'ngx-tiptap';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
@Component({
  selector: 'app-select-stories',
  standalone: true,
  imports: [MatIconModule,NgxTiptapModule,MatPaginatorModule,NgIf,MatProgressSpinnerModule,DatePipe,NgFor,NgClass,ReactiveFormsModule,MatDialogModule,MatIcon,MatMenuModule,MatInputModule,MatSelectModule,MatButtonModule,MatOptionModule,MatCheckboxModule,MatFormFieldModule,MatRadioModule],
  templateUrl: './select-stories.component.html',
  styleUrl: './select-stories.component.scss'
})
export class SelectStoriesComponent {

  headerForm:UntypedFormGroup;
  proposal: any = {};
  _buildDetail: any = {};
  is_write_allowed: boolean = true;
  loader_data: boolean = true;
  all_original_docs:any=[];
  all_tags:any [] = [];
  all_themes:any [] = [];
  storyList:any[] = [];
  selected_stories:any[] = [];
  all_epics:any[]=[];
  userStoryArray:any[]=[];
  newlyAdded=0;
  isSelectAll:boolean=false;
  currentPage=0;
  totalRecords=0;
  pageSize=20;

  all_types:any[]=[
    {value:'Acceptance',label:"Acceptance"}, 
    {value:'Accessibility',label:"Accessibility"}, 
    {value:'Compatibility',label:"Compatibility"},
    {value:'Destructive',label:"Destructive"},
    {value:'Functionality',label:"Functionality"},
    {value:'Performance',label:"Performance"},
    {value:'Regression',label:"Regression"},
    {value:'Security',label:"Security"},
    {value:'Smoke & Sanity',label:"Smoke & Sanity"},
    {value:'Usability',label:"Usability"}
  ];

  constructor(
      private _formBuilder: UntypedFormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<SelectStoriesComponent>,
      private atlassianService:AtlassianService
  ){
    // console.log("data:",data);
    if(data){
      this.proposal=data.proposal;
      this.all_themes = data.themeTypes;
      this._buildDetail = data._buildDetail;
      this.userStoryArray = data.userStoryArray;
      this.all_epics=[];
      for(let epic of this.proposal.epics){
        let epics= epic.epics.map((themeEpic) => {
          return { label: themeEpic.name, value: themeEpic.name };
        });
        this.all_epics.push(...epics);
      }
      this.initProjectStories();
    }
  }

  ngOnInit(){
    this.headerForm = this._formBuilder.group({
      search : [''],
      selected_tag : [''],
      selected_theme : [''],
      selected_epic : [''],
    });
  }

  initProjectStories() {
    const offset = this.currentPage * this.pageSize;
    this.atlassianService.getProjectStories(this.proposal._id, offset, this.pageSize).subscribe((response) => {
      if (response.success) {
        this.storyList = response.data;
        this.all_original_docs = response.data;  
        if (this.userStoryArray.length) {
          this.selected_stories = this.userStoryArray;
          this.storyList = this.reorderStories(this.storyList, this.selected_stories);
        }
      }
      this.totalRecords = response.total;
      this.loader_data = false;
    });
  }
  
  // Function to reorder storyList with selected_stories at the top
  reorderStories(storyList: any[], selectedStories: any[]): any[] {
    // console.log(selectedStories);
    const selectedSet = new Set(selectedStories.map(story => story._id)); // Assuming stories have unique _id
    const selected = storyList.filter(story => selectedSet.has(story._id));
    const unselected = storyList.filter(story => !selectedSet.has(story._id));
  
    return [...selected, ...unselected]; // Merging selected stories at the top
  }  

  filterCount(){
    console.log(this.headerForm.value);
    let all_docs = JSON.parse(JSON.stringify(this.all_original_docs));
    let tag_collection = this.headerForm.get('selected_tag').value || [];
    let theme_collection = this.headerForm.get('selected_theme').value || [];
    let epic_collection = this.headerForm.get('selected_epic').value || [];
    let search_term = this.headerForm.get('search').value?.toLowerCase() || '';
    // console.log(all_docs);
    this.storyList = all_docs.filter(element => {
      // Filter by tags if selected
      const matchesTags = !tag_collection.length || (element.tags && element.tags.some(tag => tag_collection.includes(tag)));
  
      // Filter by theme if selected
      const matchesTheme = !theme_collection.length || (element.theme && theme_collection.includes(element.theme));
      
      // Filter by epic if selected
      const matchesEpic= !epic_collection.length || (element.epic && epic_collection.includes(element.epic));
  
      // Filter by search term if provided
      const matchesSearchTerm = !search_term || (element.title && element.title.toLowerCase().includes(search_term)) || (element.storyID && element.storyID.toLowerCase().includes(search_term)) || (element.theme && element.theme.toLowerCase().includes(search_term)) || (element.epic && element.epic.toLowerCase().includes(search_term));
  
      // Include element only if it matches all criteria
      return matchesTags && matchesTheme && matchesEpic && matchesSearchTerm;
    });
    // console.log(this.storyList);
  }

  onChangeTheme(event){
    let selectedThemes=event.value;

    if(selectedThemes.length){
      this.all_epics=[];
      for(let epic of this.proposal.epics){
        if(selectedThemes.includes(epic.theme)){
          let epics= epic.epics.map((themeEpic) => {
            return { label: themeEpic.name, value: themeEpic.name };
          });
          this.all_epics.push(...epics);
        }
      }
    }
  }

  onSelectAll() {
    this.isSelectAll = !this.isSelectAll;
    if (this.isSelectAll) {
      const newSelectedFeatures = this.storyList.filter(story =>
        !this.selected_stories.some(selected => selected._id === story._id)
      );
  
      this.selected_stories = [...this.selected_stories, ...newSelectedFeatures];
    } else {
      // Deselect All
      this.selected_stories = [];
    }
  }
  

  closeRef(){
    this.dialogRef.close(this.selected_stories);
  }

  selectStory(eachstory) {
    const index = this.selected_stories.findIndex(el => el._id === eachstory._id);
    if (index !== -1) {
        this.selected_stories.splice(index, 1);
    } else {
        this.selected_stories.push(eachstory);
    }

  }

  alreadySelect(eachstory) {
    return this.selected_stories.some(el => el._id === eachstory._id);
  }


  onRemoveSelections(){
    this.selected_stories=[];
  }


  onSelectStory(){
    this.dialogRef.close(this.selected_stories);
  }


  onPageChange(event: PageEvent) {
      console.log("On CHange Page:",event);
      this.loader_data = true;
      this.isSelectAll=false;
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.initProjectStories();
    }

}
