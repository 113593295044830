import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerComponent } from '@fuse/components/drawer';

@Component({
  selector: 'app-add-feature',
  standalone: true,
  imports:  [FuseDrawerComponent,NgIf,MatIcon,MatMenuModule,ReactiveFormsModule,MatFormFieldModule,MatInputModule,MatSelectModule],
  templateUrl: './add-feature.component.html',
  styleUrl: './add-feature.component.scss'
})
export class AddFeatureComponent {
  @ViewChild('themeDrawer') public  themeDrawer: FuseDrawerComponent;

  isSavingFeature:boolean=false;
  themeForm:UntypedFormGroup;
  featureSaved:boolean=false;

  @Input()
  themes:any=[];
  @Input()
  epics:any=[];
  @Input()
  releases_list:any=[];
  @Output()
  onSaveFeature=new EventEmitter<any>();

  @Output()
  onDeleteFeature=new EventEmitter<any>();

  isEdit:boolean=false;
  selectedIndex:number=0;
  filteredEpics=[];

  constructor(private _formBuilder:UntypedFormBuilder){
    this.themeForm=this._formBuilder.group({
      feature:["",Validators.required],
      theme:["",Validators.required],
      epic:["",Validators.required],
      release:["",Validators.required]
    });
  }

  ngOnInit(){

  }

  filterEpics(){
    let selectedTheme=this.themeForm.get("theme").value;
    for(let theme of this.epics){
      if(selectedTheme==theme.theme){
        // console.log("Selected Theme:",theme);
        this.filteredEpics=theme.epics.map((epic)=>{ return{value:epic.name,label:epic.name}; });
        break;
      }
    }
  }

  openedChanged(event){
    
  }

  onDeleteFeatureEvent(){
    this.onDeleteFeature.emit({...this.themeForm.value,isEdit:this.isEdit,index:this.selectedIndex});
    this.themeDrawer.close();
  }

  onCloseDrawer(){
    this.themeForm.reset();
  }

  onSaveFeatureEvent(){
    // console.log("On Save Feature: ",this.themeForm.value);
    this.onSaveFeature.emit({...this.themeForm.value,isEdit:this.isEdit,index:this.selectedIndex});
    this.themeForm.reset();
    this.themeDrawer.close();

  }
  onChangeThemeSelection(event){
    this.filterEpics();
  }

  openDrawer(){
    this.themeForm.reset();
    this.themeForm.markAsUntouched();
    this.themeDrawer.open();
  }
}
