

<div class="shimmer-effect h-60 rounded" *ngIf="fetch_user_org"></div>
<div *ngIf="(!user_orgs.length && !fetch_user_org && (!findMetadata || (findMetadata && !findMetadata._id)))" class="bg-card shadow rounded p-3">
    <div class="flex items-center justify-center flex-col min-h-72 gap-3" >
        <h4 class="font-semibold text-lg">Connect with your Salesforce Org</h4>
        <p class="text-secondary text-lg text-center">Establish a secure connection to your Salesforce org to extract metadata efficiently.</p>
        <span class="note mt-10">Click here to get started</span>
        <button mat-flat-button class="salesforceBt" (click)="linkSalesforce()" [disabled]="!is_write_allowed">Link Salesforce Org</button>
    </div>
</div>


<div *ngIf="(user_orgs.length && (!findMetadata || (findMetadata && !findMetadata._id)) && !fetch_user_org)" class="bg-card shadow rounded p-3">
    <div class="flex items-center justify-center flex-col min-h-72 gap-3" >
        <h4 class="font-semibold text-lg">Connect with your Salesforce Org</h4>
        <p class="text-secondary text-lg text-center">Establish a secure connection to your Salesforce org to extract metadata efficiently. </p>
        <div class="max-w-[30rem] flex flex-col">
            <fuse-alert *ngIf="user_orgs[0].active" class="mb-3 mt-3" type="success"  [showIcon]="true" [appearance]="'outline'" [dismissed]="false"  [dismissible]="false">
                <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:check-circle'"> </mat-icon>  
                Salesforce org connected successfully.
            </fuse-alert>
            <fuse-alert *ngIf="!user_orgs[0].active" class="mb-3 mt-3" type="error"  [showIcon]="true" [appearance]="'outline'" [dismissed]="false"  [dismissible]="false">
                <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:check-circle'"> </mat-icon>  
                The Salesforce org is not connected. The extraction process will attempt to reconnect the org.
            </fuse-alert>
        </div>
        <div class="actionSec flex gap-2 justify-center">
            <button mat-flat-button [color]="'primary'" [disabled]="(load_extract || !is_write_allowed)" class="extractBt" (click)="metadataExtract()">
                <mat-progress-spinner *ngIf="load_extract" mode="indeterminate"></mat-progress-spinner>
                Start Metadata Extraction
            </button>
            <button *ngIf="user_orgs[0].active" mat-flat-button class="disconnectBt" (click)="manageconnect({value:'disconnect'})" [disabled]="!!is_write_allowed">
                Disconnect Org
            </button>
        </div>
    </div>
</div>



<div *ngIf="(user_orgs.length && findMetadata && findMetadata._id && !fetch_user_org && describeMetadata.length)" class="connectBox">

    <!-- alert for dropdown action -->
    <fuse-alert *ngIf="showSuccess" class="mb-3 mt-3 fullwidAlert" type="success"  [showIcon]="true" [appearance]="'outline'" [dismissed]="false"  [dismissible]="true">
        <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:check-circle'"> </mat-icon>  
        Salesforce org connected successfully.
    </fuse-alert>
    <fuse-alert *ngIf="showSuccessdisconnect" class="mb-3 mt-3 fullwidAlert" type="success"  [showIcon]="true" [appearance]="'outline'" [dismissed]="false"  [dismissible]="true">
        <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:check-circle'"> </mat-icon>  
        Salesforce org disconnected successfully.
    </fuse-alert>
    <fuse-alert *ngIf="showFailure" class="mb-3 mt-3 fullwidAlert" type="error"  [showIcon]="true" [appearance]="'outline'" [dismissed]="false"  [dismissible]="true">
        <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'heroicons_outline:x-circle'"> </mat-icon>  
        Salesforce Org authentication failed
    </fuse-alert>

    
    <div class="flex actBt flex-wrap items-center gap-3 mb-8 justify-between">
        <div class="subHeadingCont">                                                
            <div class="text-black font-medium text-lg">Existing Org Metadata</div>
            <div class="text-[#94A3B8] text-md font-normal">Effortlessly Extract and Analyze Metadata to Unlock Valuable Insights and Enhance Data Management.</div> 
        </div>
        <div class="manageConnect" *ngIf="is_write_allowed">
            <mat-form-field class="flex-auto gt-xs:pl-3  ">
                <img src="assets/icons/cloudcheck.svg" class="w-5" alt="">
                <mat-select  [placeholder]="'Manage Connection'" class="ml-2" (selectionChange)="manageconnect($event)" [formControl]="connectionControl" >
                    <mat-option value="refresh"> <div class="custom-option"> <img src="assets/icons/cloudrefresh.svg" class="w-5" alt=""> Refresh </div></mat-option>
                    <mat-option value="reauthorize"> <div class="custom-option"><img src="assets/icons/cloudsearch.svg" class="w-5" alt=""> Reauthorize </div></mat-option>
                    <mat-option value="edit"><div class="custom-option"> <img src="assets/icons/cloudedit.svg" class="w-5" alt=""> Edit </div></mat-option>
                    <mat-option *ngIf="user_orgs[0].active" value="disconnect"> <div class="custom-option"><img src="assets/icons/clouddisconnect.svg" class="w-5" alt=""> Disconnect </div></mat-option>
                    <mat-option *ngIf="!user_orgs[0].active" value="connect"> <div class="custom-option"><img src="assets/icons/cloudconnect.svg" class="w-5" alt=""> Connect </div></mat-option>
                </mat-select>
                <!-- <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cloud'"></mat-icon> -->
            </mat-form-field>
        </div>
    </div>    

    <div class="tabSec relative">
        <mat-tab-group  class=" viewTabs "  mat-stretch-tabs="false" animationDuration="0ms" >

            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="inline-flex items-center space-x-2">
                        <span class="flex items-center gap-1"><mat-icon class="icon-size-5 mat-tab-activeic" svgIcon="heroicons_outline:check"></mat-icon> Metadata Extract</span>
                    </span>
                    
                </ng-template>
                
                <ng-template matTabContent>
                    <app-metadata-extract [(proposalID)]="_proposalDetail._id" [(proposal)]="_proposalDetail" [(user)]="user" [(describeMetadata)]="describeMetadata" [(findMetadata)]="findMetadata" [(fetch_meta_data)]="fetch_meta_data" [(accessStatus)]="accessStatus" [is_write_allowed]="is_write_allowed"></app-metadata-extract>
                </ng-template>
            </mat-tab>
    
            <mat-tab [disabled]="!fetch_vector_data">
                <ng-template mat-tab-label>
                    <span class="inline-flex items-center space-x-2">
                        <span class="flex items-center gap-1"><mat-icon class="icon-size-5 mat-tab-activeic" svgIcon="heroicons_outline:check"></mat-icon> Insights</span>
                    </span>
                    
                </ng-template>
                
                <ng-template matTabContent >
                    <app-technical-insight [(proposalID)]="_proposalDetail._id" [(proposal)]="_proposalDetail" [(user)]="user" [(describeMetadata)]="describeMetadata" [(findMetadata)]="findMetadata" [(fetch_vector_data)]="fetch_vector_data" [(accessStatus)]="accessStatus" [is_write_allowed]="is_write_allowed"></app-technical-insight>

                </ng-template>
            </mat-tab>
        </mat-tab-group>
        <div class="chatBtSec">
            <button [disabled]="!fetch_vector_data" (click)="openChatWithAI()"  mat-flat-button  class="chatAiBt"> <img src="assets/icons/chatai.svg" alt=""> Chat With AI</button>
        </div>
    </div>
</div>
    