<div class="flex flex-col flex-auto min-w-0 relative" >
    <div class="flex-auto">
        <div class="formBox">
            <div class="formHead flex justify-between">
                <h4 class="text-2xl">{{existingConfig && existingConfig._id?'Edit':'Add'}} Configuration</h4>
                <!-- <span class=" linkedS" (click)="selectStory()">Linked User Story</span> -->
                <span class="close-dialog cursor-pointer" [mat-dialog-close]="true" > 
                    <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
                </span>
            </div>
            <div class="separator"></div>
            <form action="" class="mainform"  [formGroup]="newConfigForm">
                <div class="desc flex flex-col">
                    <label class="font-medium">Name*</label>
                    <textarea [formControlName]="'metadataName'" name="" id="" class="border mt-2 rounded w-full p-3" row="2" style="resize: none;" ></textarea>
                </div>

                <div class="fir flex flex-wrap gap-6 mt-6">
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Theme</mat-label>
                        <mat-select [placeholder]="'Theme'" [formControlName]="'themeID'" class="selectOption2" (selectionChange)="onChangeTheme($event)">
                            @for(option of themeTypes;track option){
                            <mat-option [value]="option.value" [disabled]="isLoading">{{option.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Epic</mat-label>
                        <mat-select [placeholder]="'Epic'" [formControlName]="'epicID'" class="selectOption2" (selectionChange)="onChangeEpic($event)">
                            @for(option of epicTypes;track option){
                            <mat-option [value]="option.value" [disabled]="isLoading">{{option.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label> Type</mat-label>
                        <mat-select [placeholder]="'Select Type'" [formControlName]="'metadataType'" class="selectOption2" (selectionChange)="onChangeMetadata($event)">
                            @for(option of allMetadataType;track option){
                            <mat-option [value]="option.value">{{option.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label> Category</mat-label>
                        <mat-select [placeholder]="'Select Category'" [formControlName]="'solutionCategory'" class="selectOption2" readonly (selectionChange)="onChangeCategory($event)">
                            @for(option of allMetadataCategory;track option){
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Sub Category</mat-label>
                        <mat-select [placeholder]="'Select Sub Category'" [formControlName]="'solutionSubCategory'" class="selectOption2" readonly>
                            @for(option of allMetadataSubcategory;track option){
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Work Type</mat-label>
                        <mat-select [placeholder]="'Type'" [formControlName]="'workType'" class="selectOption2" readonly>
                            @for(option of workTypes;track option){
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Action</mat-label>
                        <mat-select [placeholder]="'Action'" [formControlName]="'actionType'" class="selectOption2">
                            @for(option of actionTypes;track option){
                                <mat-option [value]="option.value" [disabled]="isLoading">{{option.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Cloud</mat-label>
                        <mat-select [formControlName]="'cloud'" [placeholder]="'Cloud'" class="selectOption2">
                            @for (option of technologyDomains; track option) {
                                <mat-option [value]="option.name">{{option.name}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Status</mat-label>
                        <mat-select [formControlName]="'status'" [placeholder]="'Status'" class="selectOption2">
                            @for (option of statusTypes; track option) {
                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-form-field class="width-60 mt-6 " (click)="selectStory()">
                    <mat-label>Linked User Stories</mat-label>
                    <input matInput [value]="userStoryArray.length + ' Stories Selected'">
                    @if(userStoryArray.length){
                        <span class="addTestCases cursor-pointer font-medium text-[#4F45E4]" >Update</span>
                    }@else{
                    <span class="addTestCases cursor-pointer font-medium text-[#4F45E4]" >Add</span>
                    }
                </mat-form-field>

                <div class="desc flex flex-col mt-6">
                    <label class="font-medium">Description*</label>
                    <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                        <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBold('desc')">
                            <mat-icon class="icon-size-4">format_bold</mat-icon>
                          </button>
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleItalic('desc')">
                            <mat-icon class="icon-size-4">format_italic</mat-icon>
                          </button>
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBulletList('desc')">
                            <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                          </button>
                        </div>
                      
                        <!-- TipTap editor component -->
                        <tiptap-editor 
                          [editor]="editor"
                          [formControlName]="'description'"
                          id="tiptap-editor"
                          placeholder="Start typing here...">
                        </tiptap-editor>
                    </div>
                </div> 

                <div class="desc flex flex-col mt-6">
                    <label class="font-medium">Assumptions*</label>
                    <!-- <textarea name="" id="" class="descText" [formControlName]="'assumption'"></textarea> -->
                    <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                        <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBold('assumption')">
                            <mat-icon class="icon-size-4">format_bold</mat-icon>
                          </button>
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleItalic('assumption')">
                            <mat-icon class="icon-size-4">format_italic</mat-icon>
                          </button>
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBulletList('assumption')">
                            <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                          </button>
                        </div>
                      
                        <!-- TipTap editor component -->
                        <tiptap-editor 
                          [editor]="editor1"
                          [formControlName]="'assumption'"
                          id="tiptap-editor2"
                          placeholder="Start typing here...">
                        </tiptap-editor>
                    </div>
                </div> 

                <div class="desc flex flex-col mt-6">
                    <label class="font-medium">Developer Notes</label>
                    <!-- <textarea name="" id="" class="descText" [formControlName]="'assumption'"></textarea> -->
                    <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                        <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBold('notes')">
                            <mat-icon class="icon-size-4">format_bold</mat-icon>
                          </button>
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleItalic('notes')">
                            <mat-icon class="icon-size-4">format_italic</mat-icon>
                          </button>
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBulletList('notes')">
                            <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                          </button>
                        </div>
                      
                        <!-- TipTap editor component -->
                        <tiptap-editor 
                          [editor]="editor2"
                          [formControlName]="'developerNote'"
                          id="tiptap-editor2"
                          placeholder="Start typing here...">
                        </tiptap-editor>
                    </div>
                </div> 

                <div class="formAct flex justify-end mb-8 items-center">
                    <button  mat-flat-button [color]="'primary'" [disabled]="(!newConfigForm.valid || isSavingConfig)" class="mt-6" (click)="onSave()" >
                        <div class="flex gap-1 items-center">
                        <mat-progress-spinner *ngIf="isSavingConfig" mode="indeterminate"></mat-progress-spinner> <span>Save</span>
                        </div>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>