import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { TemplatePortal } from '@angular/cdk/portal';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild, ElementRef, TemplateRef, ViewContainerRef, Inject ,HostListener } from '@angular/core';
import { FormArray, FormControl, FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CommonService } from 'app/core/common/common.service';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { ProposalService } from 'app/core/proposal/proposal.service';
import { WebsocketService } from 'app/core/websockets/websocket.service';
import { TechnologyDomainService } from 'app/core/technology-domain/technology-domain.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import country_list from '../../../../assets/i18n/country_list.json';
import { filter, finalize, firstValueFrom, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import Speech from 'speak-tts';
import {v4 as uuidv4} from 'uuid';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { QuickPromptComponent } from 'app/modules/common/quick-prompt/quick-prompt.component';
import { QuickPromptTipComponent } from 'app/modules/common/quick-prompt-tip/quick-prompt-tip.component';
import { EditorControlsComponent } from 'app/modules/common/editor-controls/editor-controls.component';
import {MatTableModule} from '@angular/material/table';
declare var webkitSpeechRecognition: any;

import { NgxTiptapModule } from 'ngx-tiptap';
import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { Heading } from '@tiptap/extension-heading';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { BulletList } from '@tiptap/extension-bullet-list';
import { ListItem } from '@tiptap/extension-list-item';
import { Underline } from '@tiptap/extension-underline';
import { fuseAnimations } from '@fuse/animations';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from '@tiptap/extension-text-style';
import { DOMSerializer, DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model';
import { Transaction } from 'prosemirror-state';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseLoadingService } from '@fuse/services/loading';
import {marked} from 'marked';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { IProposal } from 'app/core/proposal/proposal.types';
import moment from 'moment';
import analytics_event from 'app/core/common/analytics_event';
import { AnalyticsService } from 'app/core/common/analytics_service';
import { PricingPlanService } from 'app/core/pricing-plan/pricing-plan.service';
export interface Country {
  code: string;
  name: string;
}

export interface ProposalList {
  name: string;
  technology: string;
  created: string;
  id: string;
}

const ELEMENT_DATA: ProposalList[] = [
  {name: 'Generative.AI Salesforce Implementation', technology: 'Service Cloud', created: '23 September, 2024',id:'1'},
  {name: 'Generative.AI Salesforce Implementation', technology: 'Service Cloud', created: '23 September, 2024',id:'2'},
  {name: 'Generative.AI Salesforce Implementation', technology: 'Service Cloud', created: '23 September, 2024',id:'2'},
];

@Component({
  selector: 'app-new-proposal',
  standalone: true,
  templateUrl: './new-proposal.component.html',
  animations   : fuseAnimations,
  styleUrl: './new-proposal.component.scss' ,
  imports      : [MatProgressBarModule,MatTableModule,NgxTiptapModule, EditorControlsComponent, NgClass,QuickPromptTipComponent,RouterLink,QuickPromptComponent, FuseDrawerComponent, MatIconModule, MatProgressSpinnerModule, FormsModule, FuseAlertComponent, NgIf, MatDialogModule,MatProgressBarModule, ReactiveFormsModule, MatStepperModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule,MatChipsModule,NgFor,AsyncPipe],
})
export class NewProposalComponent {
  @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
  @ViewChild('tagsPanelOriginPlatform') private _tagsPanelOriginPlatform: ElementRef;
  
  @ViewChild('searchTestCaseType') searchTextboxTestCaseType: ElementRef;
  @ViewChild('searchDeviceType') searchTextboxDeviceType: ElementRef;
  @ViewChild('searchToolType') searchTextboxToolType: ElementRef;
  
  @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
  @ViewChild('tagsPanelPlatform') private _tagsPanelPlatform: TemplateRef<any>;
  @ViewChild('horizontalStepper') private horizontalStepper: MatStepperModule;
  @ViewChild(MatStepper) private stepper: MatStepper;
  @ViewChild(FuseDrawerComponent) rewriteAIDrawer: FuseDrawerComponent;
  @ViewChild("summary") private summaryElement:ElementRef;

  @ViewChild("fileInputSummary") private fileInputSummary:ElementRef;
  @ViewChild("fileInputPlanSummary") private fileInputPlanSummary:ElementRef;



  isDragging = false;

  @HostListener('window:dragover', ['$event'])
  preventWindowDrag(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('window:drop', ['$event'])
  preventWindowDrop(event: DragEvent) {
    event.preventDefault();
  }

  addOnBlur = true;
  platformArray: FormArray<any>;
  testtypeArray:any[]=[];
  testdeviceArray:any[]=['Laptop'];

  displayedColumns: string[] = ['id','name', 'technology', 'created','action'];
  dataSource = ELEMENT_DATA;
  clickedRows = new Set<ProposalList>();
  selectedRow: any;
  selectedRowProject: any;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  alert: { type: FuseAlertType; message: string } = {
    type   : 'success',
    message: '',
  };
  supportingFilesData:string[]=[];
  sizeLimitAlert:boolean=false;
  infoAlertSummary:boolean=false;
  infoAlertBusiness:boolean=false;
  selectedSupportingFiles=[];
  selectedPlanSummarySupportingFiles=[];
  flashMessage: string;
  flashMessageType: 'success' | 'error' | null = null;
  horizontalStepperForm: UntypedFormGroup;
  drawerGroupForm: UntypedFormGroup;
  proposal_id: string;
  testing_plan_id: string;
  build_id: string;
  button_text: string;
  rewriteAlert: boolean = false;
  recording: boolean = false;
  generate_loader = false;
  generate_replace = false;
  generate_append = false;
  generate_grammar = false;
  showcustomprompt = false;
  displayinfoSummary = false;
  tagsEditMode: boolean = false;
  tagsEditModePlatform: boolean = false;
  mouseDownXPos=0;
  mouseDownPos=0;
  overflowbubble = false;
  speech: any;
  speechData: any;
  technologiesTypes: any []= [];
  cloudTypes: any []= [];
  industries: any []= [];
  filteredTags: any []= [];
  filteredTagsPlatform: any []= [];
  country_list: Country[] = country_list;
  user: User;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  speechRecognition;// = new webkitSpeechRecognition();
  timer: any;
  connectionID: string;
  businessCapabilities:string[]=[];
  businessCapabilitiesResponse:string;
  businessOutcomesResponse:string;
  outcomes:string[]=[];
  mouseInitialPosition=null;
  isQuickMenuHighlighted:boolean=false;
  showQuickPromptMenu = false;
  menuPosition = { top: '500px', left: '80px' };
  quickSelectedText="";
  insideTextArea=false;
  selectedTextAreaType="";
  quickSelectionStartIndex=-1;
  quickSelectionEndIndex=-1;
  selectedText: string = '';
  isProcessingFiles:boolean=false;
  isProcessingPlanFiles:boolean=false;
  private _tagsPanelOverlayRef: OverlayRef;
  loader_project = false;
  loader_proposal = false;
  allProject:any[]=[];
  allProposal:any[]=[];
  filteredOptionsTestCaseType: Observable<any[]>;
  filteredOptionsDeviceType: Observable<any[]>;
  filteredOptionsToolType: Observable<any[]>;
  searchTextboxControlTestCaseType = new FormControl();
  searchTextboxControlDeviceType = new FormControl();
  searchTextboxControlToolType = new FormControl();
  searchCtrl = new FormControl('');
  selectedValuesTypeList = [];
  selectedValuesDeviceList = [];
  selectedValuesToolList = [];
  testingTypes: any[] =[
    {label:'Manual',value:'Manual',active:true},
    {label:'Automatic (Coming Soon)',value:'Automatic',active:false}
  ];
  typeList:any[]=[
    {value:'Acceptance',label:"Acceptance"}, 
    {value:'Accessibility',label:"Accessibility"}, 
    {value:'Compatibility',label:"Compatibility"},
    {value:'Destructive',label:"Destructive"},
    {value:'Functionality',label:"Functionality"},
    {value:'Performance',label:"Performance"},
    {value:'Regression',label:"Regression"},
    {value:'Security',label:"Security"},
    {value:'Smoke & Sanity',label:"Smoke & Sanity"},
    {value:'Usability',label:"Usability"}
  ];
  deviceList:any[]=[
    {value:'Laptop',label:"Laptop"}, 
    {value:'Desktop',label:"Desktop"}, 
    {value:'Smartphone',label:"Smartphone"},
    {value:'Tablet',label:"Tablet"}
  ];
  toolList:any[]=[
    {value:'Jira',label:"Jira"}, 
    {value:'Zephyr',label:"Zephyr"}, 
    {value:'TestRail',label:"TestRail"},
    {value:'qTest',label:"qTest"},
    {value:'PractiTest',label:"PractiTest"},
    {value:'Excel',label:"Excel"},
  ];
  editor = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: (this.data['category']=='Proposal'?'Summarize the key highlights of your project, focusing on the objectives, solutions, and expected outcomes in a concise manner.':'Summarize the key highlights of your project, focusing on the objectives, solutions, and expected outcomes in a concise manner.'),
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onUpdate: ({ editor }) => {
      let charCount = editor.getText().trim().length;
      if(charCount<100)this.displayinfoSummary=false;
      else this.displayinfoSummary=true;
    },
    onSelectionUpdate: ({ editor }) => {
      this.overflowbubble = true;
      this.updateSelectedText(editor);
    },
  });

  editor1 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Name of your Project: CRM',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.overflowbubble = true;
      this.updateSelectedText(editor);
    },
  });

  editor2 = new Editor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      ListItem,
      Underline,
      TextStyle,
      Color,
      Placeholder.configure({
        placeholder: 'Please upload all relevant test strategy documents, including Objective, Scope, Out of Scope, Test Environments, Testing Tools, Defect Management, Requirements, User Profile, System Environment, Test Cases, Acceptance Criteria, and Dependencies',
      }),
      Heading.configure({
        levels: [1, 2, 3, 4, 5 ,6], // Define which heading levels you want to support
      })
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: 'p-2 border-black focus:border-blue-700 border-1 rounded-md outline-none',
        spellCheck: 'false',
      },
    },
    onSelectionUpdate: ({ editor }) => {
      this.overflowbubble = true;
      this.updateSelectedText(editor);
    },
  });

  /**
   * Constructor
   */
  constructor(
    private zone: NgZone,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private _userService: UserService,
    private _proposalService: ProposalService,
    private _websocketService: WebsocketService,
    private _pricingPlanService: PricingPlanService,
    private _formBuilder: UntypedFormBuilder,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _technologyDomainService:TechnologyDomainService,
    private _commonService:CommonService,
    private platform: Platform,
    private _analyticsService:AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: {category: string}
  )
  {
    this.technologiesTypes=this._commonService.technologiesTypes;
    this.industries=this._commonService.industries;
    this.filteredTags = this.industries;//console.log(this.industries);
    // this.cloudTypes = this._commonService.cloudTypes['Salesforce'];
    this._technologyDomainService.getWithTechnology(0,100,"Salesforce").subscribe();
    this._technologyDomainService.technologyDomains$.subscribe((response)=>{
      if(response.length){
        this.cloudTypes=[];
        response.forEach(el =>{
          if(el.active)this.cloudTypes.push({label:el.name,value:el.name,order:el.order});
          this.cloudTypes.sort((a, b) => {
            if (a.order === undefined) return 1; // Place 'a' at the end if 'order' is undefined
            if (b.order === undefined) return -1; // Place 'b' at the end if 'order' is undefined
            return b.order - a.order; // Sort in descending order
          });
        });
      }
    });
    this.speechRecognition = new webkitSpeechRecognition();
    this.speech = new Speech(); // will throw an exception if not browser supported
    if (this.speech.hasBrowserSupport()) {
       this.speechRecognition.continuous = true;
      this.speechRecognition.interimResults = true;
      // returns a boolean
      // console.log(
      //   'speech synthesis supported',
      //   this.speech.hasBrowserSupport()
      // );
      this.speech
        .init({
          continuous: true,
          volume: 1,
          lang: 'en-GB',
          rate: 1.2,
          pitch: 1,
          voice: 'Google UK English Female',
          splitSentences: true,
          // listeners: {
          //   onvoiceschanged: (voices) => {
          //     console.log('Event voiceschanged', voices);
          //   },
          // },
        })
        .then((data) => {
          // The "data" object contains the list of available voices and the voice synthesis params
          this.speechData = data;
        })
        .catch((e) => {
          console.error('An error occured while initializing : ', e);
        });
    }

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "connection")
    ).subscribe((message) => {
        // console.log("Received:", message);
        this.connectionID = message.data;
    });
  
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "fix_grammar")
    ).subscribe((message) => {
        // console.log("Received:", message);
        if(!message.isCompleted && message.taskID == this.drawerGroupForm.get('taskID').value){
          let stripContent = this.drawerGroupForm.get('modified').value + message.data;
          this.drawerGroupForm.get('modified').setValue(stripContent);
        }
        else if(message.isCompleted){
          this.generate_grammar = false;
          this.generate_loader = false;
        }
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "business_capabilities")
    ).subscribe((message) => {

        // console.log("message",message);

        /*
         this.businessCapabilitiesResponse+=message.data;

         if(this.businessCapabilitiesResponse!=""){
          this.businessCapabilities=this.businessCapabilitiesResponse.split(",")||[];
         }

         if(message.isCompleted){
          this.businessCapabilities=this.businessCapabilitiesResponse.split(",");
         }
        */

         this.businessCapabilitiesResponse += message.data;

         if (this.businessCapabilitiesResponse !== "") {
             const capabilitiesArray = this.businessCapabilitiesResponse.split(",");
             this.businessCapabilities = Array.from(new Set(capabilitiesArray));
         }
     
        if (message.isCompleted) {
            const capabilitiesArray = this.businessCapabilitiesResponse.split(",");
            this.businessCapabilities = Array.from(new Set(
                capabilitiesArray.filter(item => {
                    const trimmed = item.trim();
                    return trimmed.length > 1; // Only keep items with length > 1
                })
            ));
        }






          

    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "business_outcomes")
    ).subscribe((message) => {

         this.businessOutcomesResponse+=message.data;

         if(this.businessOutcomesResponse!=""){
          this.outcomes=this.businessOutcomesResponse.split(",")||[];
         }

         if(message.isCompleted){
          this.outcomes=this.businessOutcomesResponse.split(",");
         }

    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "test_type_recommendations")
    ).subscribe((message) => {
      // console.log("Received:", message);
      if (message.data !== "") {
        const recommendation = message.data.split(",") || [];
        let trimVal = [];
        recommendation.forEach(el => {
            const trimmedEl = el.trim();
            if (!this.typeList.find(element => element.label === trimmedEl)) {
                this.typeList.push({ label: trimmedEl, value: trimmedEl });
            }
            // this.testtypeArray.push(trimmedEl);
            trimVal.push(trimmedEl);
        });
        if(!this.testtypeArray.length){
          this.horizontalStepperForm.get('step11.selectedTestCaseTypes').setValue(trimVal);
          this.testtypeArray = trimVal;
        }
      }
    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "supporting_doc_summary")
    ).subscribe((message) => {
      // console.log(message);
      let parsedMessage=marked.parse(message.data);
      return;
      /*
      if(this.horizontalStepperForm.get('step5.summary').value==""){
        this.horizontalStepperForm.get('step5.summary').setValue(`${parsedMessage}`);
      }else{
         this.horizontalStepperForm.get('step5.summary').setValue(`${this.horizontalStepperForm.get('step5.summary').value}
         </br>
         ${parsedMessage}
         `);

      }
         */
      let charCount = this.horizontalStepperForm.get('step5.summary').value.length;console.log(charCount);
      if(charCount<100)this.displayinfoSummary=false;
      else this.displayinfoSummary=true;

         let allProcessed=true;
         let j = 0;
         for(let supportingDoc of this.selectedSupportingFiles){
          if(supportingDoc.id==message.taskID){
            supportingDoc.processed=true;
          }

          if(!supportingDoc.processed){
            allProcessed=false;
          }

          this.selectedSupportingFiles[j].processingF=false;
          this.selectedSupportingFiles[j].processingPF=false;
          j++;
         }

         if(allProcessed){
          this.isProcessingFiles=false;
          this.isProcessingPlanFiles = false;
         }

    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "supporting_doc_embeddings")
    ).subscribe((message:any) => {

      // console.log("Supporting Doc Embeddings:",message);

    });
    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "validate_supporting_doc")
    ).subscribe((message:any) => {


      let document=null;
        for(let supportingDoc of this.selectedSupportingFiles){
          if(supportingDoc.id==message.data.documentID){
            supportingDoc.valid=message.data.valid==1?true:false;
            document=supportingDoc;
            break;
          }
        }

        if(document==null || document.valid==false){
        this.alert={
          type:"error",
          message:`The document does not contain relevant information. Upload a new document or proceed with the current document.`
        };
        this.flashMessage=`The document does not contain relevant information. Upload a new document or proceed with the current document.`;
        this.sizeLimitAlert=true;
        setTimeout(()=>{
         // this.sizeLimitAlert=false;
        },3000);

      }

      //console.log("Supporting Doc Validation:",message);
      // console.log("Supporting Doc Validation:",message.data.valid);

    });

    this._websocketService.messageSubject.pipe(
      filter((message) => message.type === "supporting_doc_text")
    ).subscribe((message) => {
      console.log("File Processing COmpleted");
      this.supportingFilesData.push(message.data);

        try{
          let charCount = this.horizontalStepperForm.get('step5.summary')?this.horizontalStepperForm.get('step5.summary').value.length:1;
          if(charCount<100){this.displayinfoSummary=false;
          }else{ this.displayinfoSummary=true};
        }catch(error){
          console.log("Error: ",error) 
        }
         let allProcessed=true;
         let j =0;
         console.log(this.selectedSupportingFiles);
         for(let supportingDoc of this.selectedSupportingFiles){
          if(supportingDoc.id==message.taskID){
            supportingDoc.processed=true;
          }

          if(!supportingDoc.processed){
            allProcessed=false;
          }
          this.selectedSupportingFiles[j]["processingF"]=false;
          j++;
         }

         let i=0;
         for(let supportingDoc of this.selectedPlanSummarySupportingFiles){
          if(supportingDoc.id==message.taskID){
            supportingDoc.processed=true;
          }

          if(!supportingDoc.processed){
            allProcessed=false;
          }
          this.selectedSupportingFiles[i]["processingPF"]=false;
          i++;
         }



        // console.log("on Recive supporting doc text:",allProcessed);
         if(allProcessed){
          this.isProcessingFiles=false;
          this.isProcessingPlanFiles = false;
         }
        


    });
  }

  ngOnInit(): void
  {
      // Subscribe to the user service
      this._userService.user$
      .pipe((takeUntil(this._unsubscribeAll)))
      .subscribe((user: User) =>
      {
          this.user = user;
      });
      this.platformArray = this._formBuilder.array([]) as FormArray;
      // Horizontal stepper form
      this.horizontalStepperForm = this._formBuilder.group({
          step0: this._formBuilder.group({
            projectType: ['', Validators.required],
          }),
          step1: this._formBuilder.group({
            testingType: ['', Validators.required],
          }),
          step2: this._formBuilder.group({
            technology: ['Salesforce', Validators.required],
          }),
          step3: this._formBuilder.group({
            product: ['', Validators.required],
          }),
          step4: this._formBuilder.group({
            industry: ['', Validators.required],
          }),
          // step4: this._formBuilder.group({
          //   location: ['', Validators.required],
          //   currency: ['', Validators.required],
          // }),
          step5: this._formBuilder.group({
            summary: ['', Validators.required],
            alert: ['']
          }),
          step6:[''],
          step7:[''],
          step8: this._formBuilder.group({
            projectID: ['', Validators.required],
          }),
          step9: this._formBuilder.group({
            testtype: ['Manual', Validators.required],
          }),
          step10: this._formBuilder.group({
            testplansummary: ['', Validators.required],
            alert: ['']
          }),
          step11: this._formBuilder.group({
            selectedTestCaseTypes: new FormControl([])
          }),
          step12: this._formBuilder.group({
            selectedDeviceTypes: new FormControl(['Laptop'])
          }),
          step13: [''],
          step14: this._formBuilder.group({
            linkedProposalID: ['', Validators.required]
          }),
          step15: this._formBuilder.group({
            name: ['', Validators.required],
          }),
          step16: [''],
          step17: ['']
      });

      this.drawerGroupForm = this._formBuilder.group({
        index: [0],
        type: [''],
        taskID: [''],
        original: ['', Validators.required],
        custom: [''],
        modified: ['']
      });

      this.horizontalStepperForm.valueChanges.subscribe( () => {
        this.cdr.detectChanges()
      });
      this.horizontalStepperForm.get('step11.selectedTestCaseTypes').valueChanges.subscribe(selectedValues => {
        // Here you can handle any additional logic when the selection changes
        this.testtypeArray = [];
        if(selectedValues && selectedValues.length){
          selectedValues.forEach(element => {
            this.testtypeArray.push(element);
          });
        }
      });
      this.horizontalStepperForm.get('step12.selectedDeviceTypes').valueChanges.subscribe(selectedValues => {
        // Here you can handle any additional logic when the selection changes
          this.testdeviceArray = [];
          if(selectedValues && selectedValues.length){
            selectedValues.forEach(element => {
              this.testdeviceArray.push(element);
            });
          }
      });
      let getListType = (this.data['category']=='Project'?'Proposal':((this.data['category']=='Testing' || this.data['category']=='Build')?'Project':''));
      this.getListOf(getListType);

      this.filteredOptionsDeviceType = this.searchTextboxControlDeviceType.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this._filterDeviceType(name))
      );
  }

  get selectedTestCaseTypes(): string[] {
    return this.horizontalStepperForm.get('step11.selectedTestCaseTypes').value;
  }

  getListOf(getListType){
    this.loader_project = true;
    this.loader_proposal = true;
    this._proposalService.getListofType(getListType)
    .pipe(
        finalize(() =>
        {
          this.loader_project = false;
          this.loader_proposal = false;
        }),
    )
    .subscribe(
       async (response) =>
        {
          if(response && response.data && response.data.length){
            if(getListType=='Project')this.allProject = response.data;
            else this.allProposal = response.data;
          }
        },
        (error) =>
        {
            
        },
    );
  }


  selectRow(row: any): void {
    this.selectedRow = row === this.selectedRow ? null : row; // Deselect if the same row is clicked
    if(this.selectedRow){
      this.selectProposal(row._id);
    }
    else this.horizontalStepperForm.get('step14.linkedProposalID').setValue('');
  }

  selectRowProject(row: any): void {
    this.selectedRowProject = row === this.selectedRowProject ? null : row; // Deselect if the same row is clicked
    if(this.selectedRowProject){
      this.selectProject(row);
    }
    else this.horizontalStepperForm.get('step8.projectID').setValue('');
  }

  getStatus(){
    let allProcessed = this.selectedSupportingFiles.every(doc => !doc.processingF);
    if (allProcessed) {
      this.isProcessingFiles = false;
    }

    const summaryControl = this.horizontalStepperForm.get('step5.summary');
    if (summaryControl?.value?.trim() === '<p></p>') {
      summaryControl.setValue('');
    }
    return (!summaryControl?.value || this.isProcessingFiles);
  }

  getPlanStatus(){
    let allProcessed=true;
    for(let supportingDoc of this.selectedSupportingFiles){
     if(!supportingDoc.processingPF){
       allProcessed=false;
     }
    }

    if(allProcessed){
     this.isProcessingPlanFiles=false;
    }

    if(this.horizontalStepperForm.get('step10.testplansummary').value=='<p></p>')this.horizontalStepperForm.get('step10.testplansummary').setValue('');
    return (!this.horizontalStepperForm.get('step10.testplansummary').value && !this.isProcessingPlanFiles);
  }

  updateSelectedText(editor: Editor): void {
    const { from, to } = editor.state.selection;
    // this.selectedText = editor.state.doc.textBetween(from, to, ' ');
    // this.quickSelectedText = editor.state.doc.textBetween(from, to, ' ');

    // Get the selected content as a ProseMirror Fragment
    const selectedFragment = editor.state.doc.slice(from, to);

    // Convert the Fragment to a DOM Node
    const serializer = DOMSerializer.fromSchema(editor.schema);
    const div = document.createElement('div');
    selectedFragment.content.forEach(node => {
        div.appendChild(serializer.serializeNode(node));
    });

    // Get the selected HTML
    const selectedHTML = div.innerHTML;

    // Store the selection details
    this.quickSelectionStartIndex = from;
    this.quickSelectionEndIndex = to;
    this.selectedText = selectedHTML;
    if(!this.selectedText){
      this.overflowbubble = false;
    }
  }

  onRemoveFile(fileTitle:string,type){
    if(type=='summary'){
      for(let i=0;i<this.selectedSupportingFiles.length;i++){
        if(this.selectedSupportingFiles[i].title==fileTitle){
          this.selectedSupportingFiles.splice(i,1);
          break;
        }
      }
  
      if(this.selectedSupportingFiles.length<=0){
        this.isProcessingFiles=false;
      }
    }
    else if(type=='testplansummary'){
      
      for(let i=0;i<this.selectedPlanSummarySupportingFiles.length;i++){
        if(this.selectedPlanSummarySupportingFiles[i].title==fileTitle){
          this.selectedPlanSummarySupportingFiles.splice(i,1);
          break;
        }
      }
  
      if(this.selectedSupportingFiles.length<=0){
        this.isProcessingPlanFiles=false;
      }
    }
  }




  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent,type) {
    event.preventDefault();
    this.isDragging = false;
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {

      this.onFilesSelected(files,type,'drag');
    }
  }




  triggerFileInput(type){   
    if(type=='summary')this.fileInputSummary.nativeElement.click();
    else if(type=='testplansummary')this.fileInputPlanSummary.nativeElement.click();
  }

  async onFilesSelected(event,type,from){
    const maxSizeInBytes = 1048576*20; //20MB
    const dragfiles = (from == 'drag')?event:'';
    const input = event.target as HTMLInputElement;
    if ((input && input.files) || dragfiles ) {
      const files: FileList =  (from == 'drag')?dragfiles:input.files;
      // Handle the selected files here
      for(let a=0;a<files.length;a++){

        if(files[a].size>maxSizeInBytes){
          
            this.alert={
              type:"error",
              message:"The maximum allowed file size is 20MB"
            }
            this.sizeLimitAlert=true;
            setTimeout(()=>{
              this.sizeLimitAlert=false;
            },3000);
            return;
        }

        if(!this.detectFileType(files[a])){


          this.alert={
            type:"error",
            message:"This file type is not supported."
          }
          this.sizeLimitAlert=true;
          setTimeout(()=>{
            this.sizeLimitAlert=false;
          },3000);
          return;

        }





    }
  
     
     //console.log(files);
      for (let i = 0; i < files.length; i++) {
        if(files[i].size>maxSizeInBytes){
          this.alert={
            type:"error",
            message:"The maximum allowed file size is 20MB"
          }
          this.sizeLimitAlert=true;
          setTimeout(()=>{
            this.sizeLimitAlert=false;
          },3000);

          if(!this.detectFileType(files[i])){


            this.alert={
              type:"error",
              message:"This file type is not supported."
            }
            this.sizeLimitAlert=true;
            setTimeout(()=>{
              this.sizeLimitAlert=false;
            },3000);
            return;
  
          }
          
        }else{
         let fileData=this.detectFileType(files[i]);




        if(type=='testplansummary')
          this.selectedPlanSummarySupportingFiles.push({title:files[i].name,type:fileData.value,uploadURL:"",fileName:"",file:files[i],processed:false,fileSize:files[i].size});
        else if(type=='summary')
          this.selectedSupportingFiles.push({title:files[i].name,type:fileData.value,uploadURL:"",fileName:"",file:files[i],processed:false,fileSize:files[i].size});
        setTimeout(()=>{
         // document.getElementById('btnnepre').scrollIntoView({behavior: 'smooth'});
        },500);
        }
      }
      await this.uploadSupportingFiles(type);
    }
    
  }
  
  async uploadSupportingFiles(type){
    
    if(type=='summary'){
      for(let i=0;i<this.selectedSupportingFiles.length;i++){
        if(this.selectedSupportingFiles[i].uploadURL==="")
          {
            // console.log('here');
          this.isProcessingFiles=true;
          this.selectedSupportingFiles[i].processingF = true;
          try{
            let uploadResp=await firstValueFrom(this._proposalService.getSupportingDocumentUploadLink({filename: this.selectedSupportingFiles[i].title,filetype: this.selectedSupportingFiles[i].file.type}));
            this.selectedSupportingFiles[i].uploadURL=uploadResp.uploadURL;
            this.selectedSupportingFiles[i].fileName=uploadResp.filename;
          }catch(error){
            // console.log("Error:",error);
          }
          this._proposalService.uploadSupportingFile(this.selectedSupportingFiles[i].uploadURL,this.selectedSupportingFiles[i].file).subscribe(async(uploadResponse)=>{
            // console.log("Upload File Resp:",uploadResponse);
            let supportingDocument={
              title: this.selectedSupportingFiles[i].title,
              description: "",
              type: this.selectedSupportingFiles[i].type,
              fileName: this.selectedSupportingFiles[i].fileName,
              technology:this.horizontalStepperForm.get("step2.technology").value,
              clouds:this.horizontalStepperForm.get("step3.product").value,
              industry:this.horizontalStepperForm.get("step4.industry").value,
              fileSize:this.selectedSupportingFiles[i].fileSize,
              authorName: `${this.user.personal?.name?.first} ${this.user.personal?.name?.last}` || ""
            };
            try{
              let sdResponse=await firstValueFrom(this._proposalService.saveSupportingDocument(supportingDocument));
              this.selectedSupportingFiles[i].id=sdResponse.data._id||"";
            }catch(error){
            }
          });
        }
      }
      // console.log("Selected Files:",this.selectedSupportingFiles);
    }
    else if(type=='testplansummary'){
      for(let i=0;i<this.selectedPlanSummarySupportingFiles.length;i++){
        if(this.selectedPlanSummarySupportingFiles[i].uploadURL===""){
          this.isProcessingPlanFiles=true;
          this.selectedPlanSummarySupportingFiles[i].processingPF = true;
          try{
            let uploadResp=await firstValueFrom(this._proposalService.getSupportingDocumentUploadLink({filename: this.selectedPlanSummarySupportingFiles[i].title,filetype: this.selectedPlanSummarySupportingFiles[i].file.type}));
            this.selectedPlanSummarySupportingFiles[i].uploadURL=uploadResp.uploadURL;
            this.selectedPlanSummarySupportingFiles[i].fileName=uploadResp.filename;
          }catch(error){
            // console.log("Error:",error);
          }
          this._proposalService.uploadSupportingFile(this.selectedPlanSummarySupportingFiles[i].uploadURL,this.selectedPlanSummarySupportingFiles[i].file).subscribe(async(uploadResponse)=>{
            // console.log("Upload File Resp:",uploadResponse);
            let supportingDocument={
              title: this.selectedPlanSummarySupportingFiles[i].title,
              description: "",
              type: this.selectedPlanSummarySupportingFiles[i].type,
              fileName: this.selectedPlanSummarySupportingFiles[i].fileName,
              technology:this.horizontalStepperForm.get("step2.technology").value,
              clouds:this.horizontalStepperForm.get("step3.product").value,
              industry:this.horizontalStepperForm.get("step4.industry").value,
              fileSize:this.selectedPlanSummarySupportingFiles[i].fileSize,
              authorName: `${this.user.personal?.name?.first} ${this.user.personal?.name?.last}` || ""
            };
            try{
              let sdResponse=await firstValueFrom(this._proposalService.saveSupportingDocument(supportingDocument));
              this.selectedPlanSummarySupportingFiles[i].id=sdResponse.data._id||"";
            }catch(error){
            }
          });
        }
        this.selectedPlanSummarySupportingFiles[i].processingPF = false;
      }
      // console.log("Selected Files:",this.selectedPlanSummarySupportingFiles);
    }
  }

  getFileTypeString(type:number){
    let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.value===type);
    return fileValue.label;
  }

  getFileClass(fileType: number): string {
    switch (fileType) {
      case 0: return 'bg-red-600';
      case 1: return 'bg-green-600';
      case 2: return 'bg-gray-600';
      case 3: return 'bg-blue-600';
      case 4: return 'bg-blue-500';
      case 5: return 'bg-yellow-600';
      case 6: return 'bg-yellow-500';
      case 7: return 'bg-orange-600';
      case 8: return 'bg-orange-500';
      case 9: return 'bg-amber-600';
      default: return 'bg-white';
    }
  }

  detectFileType(file: File): { value: number, label: string } | null {
    const fileTypeMap: { [key: string]: string } = {
      'application/pdf': 'PDF',
      // 'text/csv': 'CSV',
      'text/plain':"TXT",
      'application/msword': 'DOC',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
      'application/vnd.ms-powerpoint': 'PPT',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
      'image/jpeg': 'JPEG',
      'image/png': 'PNG',
      'image/jpg': 'JPG'
    };

    const fileType = file.type;
    if (fileTypeMap[fileType]) {
      let thisFileType=fileTypeMap[fileType];
      let fileValue=this._commonService.fileTypes.find((fileType)=>fileType.label===thisFileType);
      return { value: fileValue.value, label: thisFileType };
    }
    return null;
  }

  ngAfterViewInit() {
    // You can now access this.horizontalStepper here
    // this.horizontalStepper['selectionChange'].subscribe((event) => {
    //   console.log('Selected index changed:', event.selectedIndex);
    // });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Cloud types
  */
  getcloudTypes(event){
    this.cloudTypes = this._commonService.cloudTypes[event];
  }

  /**
   * Create Proposal
  */
  createProposal(): void
  {
    // console.log(this.horizontalStepperForm.value);
    // Return if the form is invalid //this._websocketService.connectionID
    // console.log(this.connectionID,this._websocketService.connectionID['_buffer'][0]);
    // return;
    let supportingDocuments=[];
    if(this.selectedSupportingFiles.length>0){
      for(let sFile of this.selectedSupportingFiles){
        if(sFile!=""){
          supportingDocuments.push(sFile.id);
        }
      }
    }
    let  summary=this.horizontalStepperForm.get('step5.summary').value;
    let combinedSummary="";  //` ${this.supportingFilesData} ${summary}`;

    if(this.supportingFilesData.length>=1){
      combinedSummary=` ${this.supportingFilesData[0]} ${summary}`;
    }else{
      combinedSummary=summary;
    }

    let dataObject = {
      name: this.horizontalStepperForm.get('step15.name').value,
      summary: combinedSummary,
      technology: this.horizontalStepperForm.get('step2.technology').value,
      product: this.horizontalStepperForm.get('step3.product').value,
      industry: this.horizontalStepperForm.get('step4.industry').value,
      linkedProposalID: this.horizontalStepperForm.get('step14.linkedProposalID').value,
      businessCapabilities:this.businessCapabilities,
      businessOutcomes:this.outcomes,
      connectionID: this.connectionID?this.connectionID:this._websocketService.connectionID['_buffer'][0],
      supporting_docs:supportingDocuments,
      category: this.data['category']
    } ;
    if(this.data['category']=='Testing'){
      dataObject['isTestPlanFromScratch']=(this.horizontalStepperForm.get('step1.testingType').value=='new')?true:false;
      dataObject['type']=this.horizontalStepperForm.get('step9.testtype').value;
      dataObject['testcase_type']=this.horizontalStepperForm.get('step11.selectedTestCaseTypes').value;
      dataObject['devicetype']=this.horizontalStepperForm.get('step12.selectedDeviceTypes').value;
      dataObject['tooltype']=this.platformArray.value||[];
      dataObject['testplansummary']=this.horizontalStepperForm.get('step10.testplansummary').value;
      dataObject['projectID']=this.horizontalStepperForm.get('step8.projectID').value;
    }
    else if(this.data['category']=='Build'){
      dataObject['projectID']=this.horizontalStepperForm.get('step8.projectID').value;
    }

    console.log("dataObject",dataObject);//return;
    if(this.user){
      this.button_text = 'View ' + (this.data['category']=='Proposal'?'Proposal':(this.data['category']=='Build'?'Build':'Project'));
      
      // create
      if(this.data['category']=='Proposal' || (this.data['category']=='Project' && this.horizontalStepperForm.get('step0.projectType').value=='new') || (this.data['category']=='Testing' && this.horizontalStepperForm.get('step1.testingType').value=='new')){
        dataObject['category']=(this.data['category']=='Testing')?'Project':this.data['category'];
        this._proposalService.save(dataObject)
            .subscribe(
                (value) =>
                { 
                    if(value.status){
                      this._userService.fetchAgentStats().subscribe();
                      this._userService.clearCacheAndFetch().subscribe();
                      this._pricingPlanService.clearCacheAndFetchStatus().subscribe();
                      this._pricingPlanService.clearCacheAndFetchPlan().subscribe();
                      localStorage.removeItem("selectedIndex");
                      this.proposal_id= value.data._id;
                      this.testing_plan_id = value.data.testPlan;
                      this.startTimer(100);
                    }
                    else {
                      this.flashMessage = value.message?value.message:'Something went wrong!';
                      this.showFlashMessage('error');
                      if(value.message && (value.message.includes('subscription') || value.message.includes('plan'))){
                        localStorage.setItem("temp_proposal", JSON.stringify(dataObject));
                        setTimeout(() => {
                          // localStorage.setItem('panel','plan-billing');
                          this._router.navigate(['/settings/pricing']);
                        }, 5000);
                      }
                      if(this.dialog)this.dialog.closeAll();
                    }
                },
                (response) =>
                {
                },
            );
      }
      else if(this.data['category']=='Project' && this.horizontalStepperForm.get('step0.projectType').value=='existing'){
        this._proposalService.create(dataObject)
            .subscribe(
                (value) =>
                {
                    if(value.status){
                      this._userService.fetchAgentStats().subscribe();
                      this._userService.clearCacheAndFetch().subscribe();
                      this._pricingPlanService.clearCacheAndFetchStatus().subscribe();
                      this._pricingPlanService.clearCacheAndFetchPlan().subscribe();
                      localStorage.removeItem("selectedIndex");
                      this.proposal_id= value.data._id;
                      this.startTimer(100);
                    }
                    else {
                      this.flashMessage = value.message?value.message:'Something went wrong!';
                      this.showFlashMessage('error');
                      if(value.message && (value.message.includes('subscription') || value.message.includes('plan'))){
                        localStorage.setItem("temp_proposal", JSON.stringify(dataObject));
                        setTimeout(() => {
                          if(this.dialog)this.dialog.closeAll();
                          // localStorage.setItem('panel','plan-billing');
                          this._router.navigate(['/settings/pricing']);
                        }, 5000);
                      }
                    }
                },
                (response) =>
                {
                },
            );
      }
      else if(this.data['category']=='Testing' && this.horizontalStepperForm.get('step1.testingType').value=='existing'){
        this._proposalService.createTestPlan(dataObject)
            .subscribe(
                (value) =>
                {
                    if(value.status){
                      this._userService.fetchAgentStats().subscribe();
                      this._userService.clearCacheAndFetch().subscribe();
                      this._pricingPlanService.clearCacheAndFetchStatus().subscribe();
                      this._pricingPlanService.clearCacheAndFetchPlan().subscribe();
                      localStorage.removeItem("selectedIndex");
                      // this.proposal_id= value.data._id;
                      this.testing_plan_id = value.data._id;
                      this.startTimer(100);
                    }
                    else {
                      this.flashMessage = value.message?value.message:'Something went wrong!';
                      this.showFlashMessage('error');
                      if(value.message && (value.message.includes('subscription') || value.message.includes('plan'))){
                        localStorage.setItem("temp_proposal", JSON.stringify(dataObject));
                        setTimeout(() => {
                          // localStorage.setItem('panel','plan-billing');
                          this._router.navigate(['/settings/pricing']);
                        }, 5000);
                      }
                      if(this.dialog)this.dialog.closeAll();
                    }
                },
                (response) =>
                {
                },
            );
      }
      else if(this.data['category']=='Build'){
        this._proposalService.createBuild(dataObject)
            .subscribe(
                (value) =>
                {
                    if(value.status){
                      this._userService.fetchAgentStats().subscribe();
                      this._userService.clearCacheAndFetch().subscribe();
                      this._pricingPlanService.clearCacheAndFetchStatus().subscribe();
                      this._pricingPlanService.clearCacheAndFetchPlan().subscribe();
                      localStorage.removeItem("selectedIndex");
                      // this.proposal_id= value.data._id;
                      this.build_id = value.data._id;
                      this.startTimer(100);
                    }
                    else {
                      this.flashMessage = value.message?value.message:'Something went wrong!';
                      this.showFlashMessage('error');
                      // if(value.message && (value.message.includes('subscription') || value.message.includes('plan'))){
                      //   localStorage.setItem("temp_proposal", JSON.stringify(dataObject));
                      //   setTimeout(() => {
                      //     // localStorage.setItem('panel','plan-billing');
                      //     this._router.navigate(['/settings/pricing']);
                      //   }, 5000);
                      // }
                      // if(this.dialog)this.dialog.closeAll();
                    }
                },
                (response) =>
                {
                },
            );
      }
    }
    else {
      this.button_text = 'Signup to View ' + (this.data['category']=='Proposal'?'Proposal':'Project');
      localStorage.setItem("temp_proposal", JSON.stringify(dataObject));
      this.startTimer(100);
    }  
  }

  showFlashMessage(type: 'success' | 'error'): void
  {
      // Show the message
      this.flashMessageType = type;


      // Hide it after 3 seconds
      setTimeout(() =>
      {
          if(this.flashMessageType=='error' && this.dialog)this.dialog.closeAll();
          this.flashMessageType = null;

      }, 3000);
  }

  startTimer(time) {
    // Start the timer
    this.timer = setTimeout(() => {
      // Move to the next step after 3 minutes
      if (this.horizontalStepper) {
        this.moveNextStep();
        this.stopTimer();
      }
    }, time);
  }

  moveNextStep(){
    this.zone.run(() => {
      this.horizontalStepper['selectedIndex']++;
      this.cdr.detectChanges();
    });
  }

  stopTimer() {
    // Stop the timer if it's running
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  /**
   * Voice Record
  */
  startSpeechRecognition(type) {
    this.recording = !this.recording;
    if(this.recording){
      let text = (type=='summary')?this.horizontalStepperForm.get('step5.summary').value:this.horizontalStepperForm.get('step10.testplansummary').value;
      // console.log("text",text);
      this.speechRecognition.start();
      var interim_transcript = '';
      var final_transcript = text + ' ';
      this.speechRecognition.onresult = (event) => {
        // const result = text + " " +event.results[0][0].transcript;
        for (var i = event.resultIndex; i < event.results.length; ++i) {
            // Verify if the recognized text is the last with the isFinal property
            if (event.results[i].isFinal) {
                final_transcript += event.results[i][0].transcript;

                if (this.platform.ANDROID || this.platform.IOS) {
                  this.speechRecognition.stop();
                  this.recording=false;
                }
               
            } else {
                interim_transcript += event.results[i][0].transcript;
            }
        }
        // const result = text + " " +final_transcript;
        this.zone.run(() => {
          if (this.platform.ANDROID || this.platform.IOS) {
            (type=='summary')?this.horizontalStepperForm.get('step5.summary').setValue(final_transcript.toString()):this.horizontalStepperForm.get('step10.testplansummary').setValue(final_transcript.toString());
          }else{
            (type=='summary')?this.horizontalStepperForm.get('step5.summary').setValue(final_transcript.toString() + '. '):this.horizontalStepperForm.get('step10.testplansummary').setValue(final_transcript.toString() + '. ')
          }
          let charCount = (type=='summary')?this.horizontalStepperForm.get('step5.summary').value.length:this.horizontalStepperForm.get('step10.testplansummary').value.length;
          if(charCount<100)this.displayinfoSummary=false;
          else this.displayinfoSummary=true;
         
        });
        // Choose which result may be useful for you

        // console.log("Interim: ", interim_transcript);

        // console.log("Final: ",final_transcript);

        // console.log("Simple: ", event.results[0][0].transcript);
      };
    }
    else {
      this.speechRecognition.stop();
    }
  }

  typingStart(event){
    if(this.recording){
      this.recording = false;
      this.speechRecognition.stop();
    }
  }

  /**
   * Rewrite with AI
  */
  rewiteSummary(){
    this.generate_loader = true;
    let type = this.drawerGroupForm.get('type').value;
    let requirement= this.drawerGroupForm.get('custom').value;
    let taskID=uuidv4();
    this.drawerGroupForm.get('taskID').setValue(taskID);
    this._websocketService.sendFixGrammarCommand(this.drawerGroupForm.get('original').value, requirement,taskID);
    
    // this._proposalService.fixGrammar({input: this.drawerGroupForm.get('original').value,prompt: requirement})
    // .subscribe(
    //     (value) =>
    //     {
    //         if(value.success){
    //           const regexForStripHTML = /<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi;
    //           const stripContent = value.data.replaceAll(regexForStripHTML, '');
              
    //           this.drawerGroupForm.get('modified').setValue(stripContent);
    //         }
    //         this.generate_loader = false;
    //     },
    //     (response) =>
    //     {
         
    //     },
    // );
  }

  onSelectionChangeTestType(): void {
    // Handle selection changes if needed
    // console.log('Selected Test Case Types:', this.selectedValuesTypeList);
}

  private _filterDeviceType(name: string): String[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setSelectedValuesDeviceType();
    let filteredList = this.deviceList.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0);
    return filteredList;
  }

  selectionChangeDeviceType(event) {
    if (event.isUserInput && event.source._selected == false) {
      let index = this.selectedValuesDeviceList.indexOf(event.source.value);
      this.selectedValuesDeviceList.splice(index, 1)
    }
    else if (event.isUserInput && event.source._selected == true) {
      (this.selectedValuesDeviceList.indexOf(event.source.value))?this.selectedValuesDeviceList.push(event.source.value):'';
    }
  }

  openedChangeDeviceType(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchTextboxControlDeviceType.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextboxDeviceType.nativeElement.focus();
    }
  }

  clearSearchDeviceType(event) {
    event.stopPropagation();
    this.searchTextboxControlDeviceType.patchValue('');
  }

  setSelectedValuesDeviceType() {
    // console.log('selectFormControl', this.horizontalStepperForm.get('step11.devicetype').value);
    // if (this.horizontalStepperForm.get('step11.devicetype').value && this.horizontalStepperForm.get('step11.devicetype').value.length > 0) {
    //   this.horizontalStepperForm.get('step11.devicetype').value.forEach((e) => {
    //     if (this.selectedValuesDeviceList.indexOf(e) == -1) {
    //       this.selectedValuesDeviceList.push(e);
    //     }
    //   });
    // }
  }

  private _filterToolType(name: string): String[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setSelectedValuesDeviceType();
    let filteredList = this.toolList.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0);
    return filteredList;
  }

  selectionChangeToolType(event) {
    if (event.isUserInput && event.source._selected == false) {
      let index = this.selectedValuesToolList.indexOf(event.source.value);
      this.selectedValuesToolList.splice(index, 1)
    }
    else if (event.isUserInput && event.source._selected == true) {
      (this.selectedValuesToolList.indexOf(event.source.value))?this.selectedValuesToolList.push(event.source.value):'';
    }
  }

  openedChangeToolType(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchTextboxControlToolType.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextboxToolType.nativeElement.focus();
    }
  }

  clearSearchToolType(event) {
    event.stopPropagation();
    this.searchTextboxControlToolType.patchValue('');
  }

  setSelectedValuesToolType() {
    // console.log('selectFormControl', this.horizontalStepperForm.get('step11.devicetype').value);
    // if (this.horizontalStepperForm.get('step11.devicetype').value && this.horizontalStepperForm.get('step11.devicetype').value.length > 0) {
    //   this.horizontalStepperForm.get('step11.devicetype').value.forEach((e) => {
    //     if (this.selectedValuesDeviceList.indexOf(e) == -1) {
    //       this.selectedValuesDeviceList.push(e);
    //     }
    //   });
    // }
  }

  toggleDrawer(type){
    if(this.recording){
      this.recording = false;
      this.speechRecognition.stop();
    }
    this.drawerGroupForm.get('type').setValue(type);
    if(type=='summary')this.drawerGroupForm.get('original').setValue(this.horizontalStepperForm.get('step5.summary').value);
    else if(type=='title')this.drawerGroupForm.get('original').setValue(this.horizontalStepperForm.get('step15.name').value);
  }

  fixGrammar(){
    this.generate_grammar = true;
    let type = this.drawerGroupForm.get('type').value;
    let requirement= this.drawerGroupForm.get('original').value;
    let taskID=uuidv4();
    this.drawerGroupForm.get('taskID').setValue(taskID);
    this._websocketService.sendFixGrammarCommand(requirement, "",taskID);

    // this._proposalService.fixGrammar({input: requirement})
    // .subscribe(
    //     (value) =>
    //     {
    //         if(value.success){
    //           const regexForStripHTML = /<([^</> ]+)[^<>]*?>[^<>]*?<\/\1> */gi;
    //           const stripContent = value.data.replaceAll(regexForStripHTML, '');
              
    //           this.drawerGroupForm.get('modified').setValue(stripContent);
    //         }
    //         this.generate_grammar = false;
    //     },
    //     (response) =>
    //     {
         
    //     },
    // );
  }

  newContent(type){
    let text = "";
    if(type=='replace'){
      this.generate_replace = true;
      text = this.drawerGroupForm.get('modified').value;
      this.drawerGroupForm.get('original').setValue(text);
    }
    else {
      this.generate_append = true;
      text = this.drawerGroupForm.get('original').value + " " + this.drawerGroupForm.get('modified').value;
      this.drawerGroupForm.get('original').setValue(text);
    }
   if(text){
      if(this.drawerGroupForm.get('type').value=='summary')this.horizontalStepperForm.get('step5.summary').setValue(text);
      else if(this.drawerGroupForm.get('type').value=='title')this.horizontalStepperForm.get('step15.name').setValue(text);
      this.showcustomprompt=false;
      this.generate_replace = false;
      this.generate_append = false;
      this.drawerGroupForm.get('custom').setValue('');
    }
  }

  openedChanged(event){
    if(event===false){
      this.clear();
    }
  }

  clear(){
    this.showcustomprompt=false;
    this.generate_replace = false;
    this.generate_append = false;
    this.drawerGroupForm.get('modified').setValue('');
    this.drawerGroupForm.get('original').setValue('');
    this.drawerGroupForm.get('type').setValue('');
    this.drawerGroupForm.get('custom').setValue('');
  }

  trackProjectEvents(){
    try{
    if(this.horizontalStepperForm.get("step0.projectType").value=="new"){
      
      
        this._analyticsService.trackEvent(analytics_event.CREATE_NEW_PROJECT,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
      

    }else if(this.horizontalStepperForm.get("step0.projectType").value=="existing"){
      this._analyticsService.trackEvent(analytics_event.CREATE_PROJECT_FROM_PROPOSAL,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
      
    }
  }catch(error){
  }
  }
  trackTestingEvents(){
    try{
    if(this.horizontalStepperForm.get("step1.testingType").value=="new"){
      this._analyticsService.trackEvent(analytics_event.CREATE_NEW_TEST_PLAN,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
    }else if(this.horizontalStepperForm.get("step1.testingType").value=="existing"){
      this._analyticsService.trackEvent(analytics_event.CREATE_TEST_PLAN_FROM_PROJECT,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
    }
  }catch(error){
    
  }
  }

  onStepChange(event: StepperSelectionEvent): void {
     //console.log("On Change Step:",this.horizontalStepperForm.get("step0.projectType").value);

     if(event.selectedIndex==1){
      this.trackProjectEvents();
      this.trackTestingEvents();
     }

    this.isQuickMenuHighlighted=false;
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    this.selectedText = '';this.quickSelectedText = '';
    const currentStep = event.previouslySelectedIndex;
    if(this.recording){
      this.recording = false;
      this.speechRecognition.stop();
    }



    // console.log(event.selectedIndex);
    if((this.data['category']=='Proposal' && event.selectedIndex==4 && this.businessCapabilities.length==0) || (this.data['category']=='Project' && event.selectedIndex==5 && this.businessCapabilities.length==0) || (this.data['category']=='Testing' && event.selectedIndex==5 && this.businessCapabilities.length==0)){
      this.businessCapabilitiesResponse="";
      //fetch Business Capabilities
      let technology= this.horizontalStepperForm.get('step2.technology').value;
      let product= this.horizontalStepperForm.get('step3.product').value;
      let industry= this.horizontalStepperForm.get('step4.industry').value;
      let summary= this.horizontalStepperForm.get('step5.summary').value;
      let taskID=uuidv4();
      let combinedSummary="";//`${this.supportingFilesData} ${summary}`;
     // console.log("Summary: ",combinedSummary);

     if(this.supportingFilesData.length==1){
      combinedSummary=`${this.supportingFilesData[0]} ${summary}`;
      this._websocketService.sendBusinessCapabilitiesCommand(technology,industry,product,taskID,combinedSummary);
     }else if(this.supportingFilesData.length>1){

      let isFirst=true;
      for(let docSummary of this.supportingFilesData){
        if(isFirst){
          combinedSummary=`${docSummary} ${summary}`;
          this._websocketService.sendBusinessCapabilitiesCommand(technology,industry,product,taskID,combinedSummary);
          isFirst=false;
        }else{
          this._websocketService.sendBusinessCapabilitiesCommand(technology,industry,product,taskID,docSummary);
        }
      }

     }else{
      combinedSummary=summary;
      this._websocketService.sendBusinessCapabilitiesCommand(technology,industry,product,taskID,combinedSummary);
     }

    }
    else if((this.data['category']=='Proposal' && event.selectedIndex==5 && this.outcomes.length==0) || (this.data['category']=='Project' && event.selectedIndex==6 && this.outcomes.length==0) || (this.data['category']=='Testing' && event.selectedIndex==6 && this.outcomes.length==0)){
      this.outcomes=[];
      this.businessOutcomesResponse="";
      let technology= this.horizontalStepperForm.get('step2.technology').value;
      let product= this.horizontalStepperForm.get('step3.product').value;
      let industry= this.horizontalStepperForm.get('step4.industry').value;
      let summary= this.horizontalStepperForm.get('step5.summary').value;
      let taskID=uuidv4();
      this._websocketService.sendBusinessOutcomesCommand(technology,industry,product,summary,this.businessCapabilities,taskID);
    }
    else if((this.data['category']=='Testing' && ((event.selectedIndex==3 && this.horizontalStepperForm.get('step1.testingType')?.value === 'existing') || (event.selectedIndex==8 && this.horizontalStepperForm.get('step1.testingType')?.value === 'new')))){
      let technology= this.horizontalStepperForm.get('step2.technology').value;
      let product= this.horizontalStepperForm.get('step3.product').value;
      let industry= this.horizontalStepperForm.get('step4.industry').value;
      let summary= this.horizontalStepperForm.get('step5.summary').value;
      let test_summary= this.horizontalStepperForm.get('step10.testplansummary').value;
      let taskID=uuidv4();
      this._websocketService.sendTestRecommendationsTypeCommand(technology,industry,product,summary,this.businessCapabilities,taskID,test_summary);
      this.filteredTagsPlatform = this.toolList;
    }
  }

  enableInfoSummary(){
    this.infoAlertSummary = true;
    this.displayinfoSummary = true;
  }

  enableInfoBusiness(){
    this.infoAlertBusiness = true;
  }
  /**
   * Navigate
  */
  navigate(){
    if(this.user){
      if(this.data['category']=='Proposal')this._router.navigate(['/proposal/'+this.proposal_id]);
      else if(this.data['category']=='Project')this._router.navigate(['/project/'+this.proposal_id]);
      else if(this.data['category']=='Testing')this._router.navigate(['/testing/'+this.testing_plan_id]);
      else if(this.data['category']=='Build')this._router.navigate(['/builds/'+this.build_id]);
    }
    else this._router.navigate(['/sign-up']);
    if(this.dialog)this.dialog.closeAll();
  }

  showProposal(id){
    window.open('/proposal/'+id, '_blank');
  }

  showProject(id){
    window.open('/project/'+id, '_blank');
  }

  selectProposal(id){
    this.horizontalStepperForm.get('step14.linkedProposalID').setValue(id);
  }

  selectProject(project){
    this.horizontalStepperForm.get('step2.technology').setValue(project.proposal_meta.technology);
    this.horizontalStepperForm.get('step3.product').setValue(project.proposal_meta.product);
    this.horizontalStepperForm.get('step4.industry').setValue(project.proposal_meta.industry);
    this.horizontalStepperForm.get('step8.projectID').setValue(project._id);
    // console.log(this.horizontalStepperForm.get('step8.projectID').value);
  }

  updateUser(){
    this._userService.get().subscribe(()=>{
    });
  }

  closeDialog(){
    this.stopTimer();
    this.updateUser();
    if(this.dialog)this.dialog.closeAll();
  }

  onAddCapability(event:Event, input: HTMLInputElement){
    const value = (input.value || '').trim();
    if(value && !this.businessCapabilities.includes(value)){
    this.businessCapabilities.push(value);
    }
    input.value = '';

    // Clear the input event value
    // event.chipInput!.clear();
  }


  isEditingIndex: number | null = null; // Track which chip is being edited

enableEditing(index: number, input: HTMLInputElement): void {
  this.isEditingIndex = index; // Set the index of the chip being edited

  // Wait for the input to render, then focus it
  setTimeout(function(){ 
    const inputElement: HTMLElement = document.querySelector('.chip-edit-input') as HTMLElement;
    if(inputElement){
      inputElement.focus();
    }
  }, 500);
}

isEditing(index: number): boolean {
  return this.isEditingIndex === index; // Check if the chip is in editing mode
}

updateCapability(event: Event, index: number): void {
  const input = event.target as HTMLInputElement;
  const value = (input.value || '').trim();

  if (value) {
    this.businessCapabilities[index] = value; // Update the chip value
  }

  this.isEditingIndex = null; // Exit editing mode
}


  onEditCapability(capability,event){
    // console.log("Event:",event);
    let index=this.businessCapabilities.indexOf(capability);
    if(index!=-1){
      this.businessCapabilities[index]=event.value;
    }
  }

  onRemoveCapability(capability){
    this.businessCapabilities.splice(this.businessCapabilities.indexOf(capability),1);
  }

  onAddOutcome(event:Event, input: HTMLInputElement){
    const value = (input.value || '').trim();

    if(value && !this.outcomes.includes(value)){
    this.outcomes.push(value);
    }
    input.value = '';

    // Clear the input event value
    // event.chipInput!.clear();
  }


  isEditingOutcomeIndex: number | null = null; // Track which chip is being edited

  enableEditingOutcome(index: number, input: HTMLInputElement): void {
    this.isEditingOutcomeIndex = index; // Set the index of the chip being edited
  
    // Wait for the input to render, then focus it
    setTimeout(function(){ 
      const inputElement: HTMLElement = document.querySelector('.chip-edit-input2') as HTMLElement;
      if(inputElement){
        inputElement.focus();
      }
    }, 500);
  }
  
  isEditingOutcome(index: number): boolean {
    return this.isEditingOutcomeIndex === index; // Check if the chip is in editing mode
  }
  
  updateOutcome(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const value = (input.value || '').trim();
  
    if (value) {
      this.outcomes[index] = value; // Update the chip value
    }
  
    this.isEditingOutcomeIndex = null; // Exit editing mode
  }
  
  
  onEditOutcome(capability,event){
      // console.log("Event:",event);
      let index=this.outcomes.indexOf(capability);
      if(index!=-1){
        this.outcomes[index]=event.value;
      }
    }
  
    onRemoveOutcome(capability){
      this.outcomes.splice(this.outcomes.indexOf(capability),1);
    }



  // onEditOutcome(outcome,event){
  //   // console.log("Event:",event);
  //   let index=this.outcomes.indexOf(outcome);
  //   if(index!=-1){
  //     this.outcomes[index]=event.value;
  //   }
  // }
  // onRemoveOutcome(outcome){
  //   this.outcomes.splice(this.outcomes.indexOf(outcome),1);
  // }

  onTextSelect(event,targetId){
    
    this.showQuickPromptMenu = false;
 
    //  const targetId = (event.target as HTMLElement).id;
    // console.log('Target element ID:', targetId);
    this.selectedTextAreaType=targetId;
     
     
 
     this.mouseInitialPosition=event.clientY;
     //This code place menu at bottom of selection
    //  const textarea = event.target as HTMLTextAreaElement;
    //  const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    //  this.quickSelectionStartIndex=textarea.selectionStart;
    //  this.quickSelectionEndIndex=textarea.selectionEnd;
     //console.log('Selected text:', selectedText);
    //  this.quickSelectedText=selectedText;
     const selection = window.getSelection();
     if (selection && selection.toString().trim().length > 0) {
         const range = selection.getRangeAt(0);
         const rect = range.getBoundingClientRect();
 
         let last=event.pageY-140;

         if(targetId=="name"){
          
          last=event.pageY - 260;
        }



        if(last<this.mouseDownPos){

        //  const screenWidth = window.innerWidth;
         // let summaryRect=this.summaryElement.nativeElement.getBoundingClientRect();
         // const xPosition = (event.pageX >= (screenWidth / 2)) ? (summaryRect.width/2)+100  : (summaryRect.width/2)-50;
    
          this.menuPosition = {
            top: `${last}px`,
            left: `${this.mouseDownXPos}px`
        };
        }
         this.showQuickPromptMenu = true;
     } else {
         this.quickSelectedText="";
         this.showQuickPromptMenu = false;
     }
     
 
  }

  replaceSubstring(originalString: string, startIndex: number, endIndex: number, replacement: string): string {
    // Extract parts of the original string before and after the substring to be replaced
    const before = originalString.slice(0, startIndex);
    const after = originalString.slice(endIndex);
    
    // Combine the parts with the replacement string in between
    const newString = before + replacement + after;
    
    return newString;
  }

  onCompleteQuickAction(newValue){

  if(this.selectedTextAreaType=="summary"){
    // let updateText=this.replaceSubstring(this.horizontalStepperForm.get('step5.summary').value,this.quickSelectionStartIndex,this.quickSelectionEndIndex,newValue);
    // //let updateText=this.horizontalStepperForm.get('step5.summary').value.replace(this.quickSelectedText,newValue);
    // this.horizontalStepperForm.get('step5.summary').setValue(updateText);
    const { from, to } = this.editor.state.selection;
    const parser = ProseMirrorDOMParser.fromSchema(this.editor.schema);
    const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
    const tr: Transaction = this.editor.state.tr.replaceWith(from, to, replacementNode);
    const newTo = from + replacementNode.size;
    this.editor.view.dispatch(tr);
    this.editor.chain().focus().setTextSelection({ from, to: newTo }).run();
  }else if(this.selectedTextAreaType=="name"){
    // let updateText=this.replaceSubstring(this.horizontalStepperForm.get('step7.name').value,this.quickSelectionStartIndex,this.quickSelectionEndIndex,newValue);
    // //let updateText=this.horizontalStepperForm.get('step7.name').value.replace(this.quickSelectedText,newValue);
    // this.horizontalStepperForm.get('step7.name').setValue(updateText);
    const { from, to } = this.editor1.state.selection;
    const parser = ProseMirrorDOMParser.fromSchema(this.editor1.schema);
    const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
    const tr: Transaction = this.editor1.state.tr.replaceWith(from, to, replacementNode);
    const newTo = from + replacementNode.size;
    this.editor1.view.dispatch(tr);
    this.editor1.chain().focus().setTextSelection({ from, to: newTo }).run();
  }
  else if(this.selectedTextAreaType=="testplansummary"){
    // let updateText=this.replaceSubstring(this.horizontalStepperForm.get('step7.name').value,this.quickSelectionStartIndex,this.quickSelectionEndIndex,newValue);
    // //let updateText=this.horizontalStepperForm.get('step7.name').value.replace(this.quickSelectedText,newValue);
    // this.horizontalStepperForm.get('step7.name').setValue(updateText);
    const { from, to } = this.editor2.state.selection;
    const parser = ProseMirrorDOMParser.fromSchema(this.editor2.schema);
    const replacementNode = parser.parseSlice(document.createRange().createContextualFragment(newValue)).content;
    const tr: Transaction = this.editor2.state.tr.replaceWith(from, to, replacementNode);
    const newTo = from + replacementNode.size;
    this.editor2.view.dispatch(tr);
    this.editor2.chain().focus().setTextSelection({ from, to: newTo }).run();
  }

  
  this.showQuickPromptMenu=false;
  this.isQuickMenuHighlighted=false;
  document.getSelection().removeAllRanges();
    
  }

  onTextareaBlur(){
    
    if(!this.insideTextArea && !this.isQuickMenuHighlighted){
        this.showQuickPromptMenu=false;
    }

  }

  onMouseEnter(){  
    this.insideTextArea=true;
  }

  onMouseLeave(){
    this.insideTextArea=false;
  }

  onMouseEnterQuickPrompt(){
    this.isQuickMenuHighlighted=true;
  }

  onMouseLeaveQuickPrompt(){
    this.isQuickMenuHighlighted=false;
  }

  onSelectionChange(event,targetId){
  this.showQuickPromptMenu = false;
    const textarea = event.target as HTMLTextAreaElement;
  //  const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    
    this.quickSelectionStartIndex=textarea.selectionStart;
    this.quickSelectionEndIndex=textarea.selectionEnd;

  // const targetId = (event.target as HTMLElement).id;
  // console.log('Target element ID:', targetId);
  this.selectedTextAreaType=targetId;

    let clientY=0
    if(this.mouseInitialPosition!=null){
      clientY=this.mouseInitialPosition;
    }
    
  //  this.quickSelectedText=selectedText;
    if (this.quickSelectedText.trim().length > 0) {

    /*
        this.menuPosition = {
            top: `${clientY + window.scrollY -30}px`,
            left: `${event.clientX+window.scrollX-350}px`
        };
        */
        this.showQuickPromptMenu = true;
    } else {
        this.quickSelectedText="";
        this.showQuickPromptMenu = false;
    }

  }

  onMouseDown(event,targetId){
    // const targetId = (event.target as HTMLElement).id;
    

    if(this.isQuickMenuHighlighted){
      return;
    }
    this.showQuickPromptMenu=false;
    document.getSelection().removeAllRanges();
    if(!this.showQuickPromptMenu){
      const screenWidth = window.innerWidth;
      let xPosition = (event.pageX >= (screenWidth / 2)) ? ((screenWidth / 2)-350)  : 250;

       xPosition=230;
 
      if(targetId=="name"){
        this.menuPosition = {
          top: `${event.pageY - 260}px`,
          left: `${xPosition}px`
        };
        this.mouseDownPos=event.pageY - 260;
        this.mouseDownXPos=xPosition;
      }else{
      this.menuPosition = {
        top: `${event.pageY - 140}px`,
        left: `${xPosition}px`
      };
      this.mouseDownPos=event.pageY-140;
      this.mouseDownXPos=xPosition;

    }
    }
  }

  onClickOutside(){
    if(!this.isQuickMenuHighlighted){
      this.isQuickMenuHighlighted=false;
      this.showQuickPromptMenu=false;
       }
  }

  removeValueDevice(data){
    // this.selectionChangeTestCaseType({ isUserInput:true, source:{_selected : false,value:data}})
  }

  /**
     * Open tags panel
     */
  openTagsPanel(type): void
  {
    if(type=='industry'){
      // Create the overlay
      this._tagsPanelOverlayRef = this._overlay.create({
          backdropClass   : '',
          hasBackdrop     : true,
          scrollStrategy  : this._overlay.scrollStrategies.block(),
          positionStrategy: this._overlay.position()
              .flexibleConnectedTo(this._tagsPanelOrigin.nativeElement)
              .withFlexibleDimensions(true)
              .withViewportMargin(64)
              .withLockedPosition(true)
              .withPositions([
                  {
                      originX : 'start',
                      originY : 'bottom',
                      overlayX: 'start',
                      overlayY: 'top',
                  },
              ]),
      });
    }
    else if(type=='platform'){
      // Create the overlay
      this._tagsPanelOverlayRef = this._overlay.create({
          backdropClass   : '',
          hasBackdrop     : true,
          scrollStrategy  : this._overlay.scrollStrategies.block(),
          positionStrategy: this._overlay.position()
              .flexibleConnectedTo(this._tagsPanelOriginPlatform.nativeElement)
              .withFlexibleDimensions(true)
              .withViewportMargin(64)
              .withLockedPosition(true)
              .withPositions([
                  {
                      originX : 'start',
                      originY : 'bottom',
                      overlayX: 'start',
                      overlayY: 'top',
                  },
              ]),
      });
    }

    // Subscribe to the attachments observable
    this._tagsPanelOverlayRef.attachments().subscribe(() =>
    {
        // Focus to the search input once the overlay has been attached
        this._tagsPanelOverlayRef.overlayElement.querySelector('input').focus();
    });

    // Create a portal from the template
    const templatePortal = new TemplatePortal((type=='industry'?this._tagsPanel:this._tagsPanelPlatform), this._viewContainerRef);

    // Attach the portal to the overlay
    this._tagsPanelOverlayRef.attach(templatePortal);

    // Subscribe to the backdrop click
    this._tagsPanelOverlayRef.backdropClick().subscribe(() =>
    {
        // If overlay exists and attached...
        if ( this._tagsPanelOverlayRef && this._tagsPanelOverlayRef.hasAttached() )
        {
            // Detach it
            this._tagsPanelOverlayRef.detach();

            // Reset the tag filter
            if(type=='industry'){
              this.filteredTags = this.industries;
              this.tagsEditMode = false;
            }
            else if(type=='platform'){
              this.filteredTagsPlatform = this.toolList;
              this.tagsEditModePlatform = false;
            }

            // Toggle the edit mode off
            this.tagsEditMode = false;
            this.tagsEditModePlatform = false;
        }

        // If template portal exists and attached...
        if ( templatePortal && templatePortal.isAttached )
        {
            // Detach it
            templatePortal.detach();
        }
    });
  }

  /**
     * Filter tags
     *
     * @param event
     */
  filterTags(event,type): void
  {
      // Get the value
      const value = event.target.value.toLowerCase();

      // Filter the tags
      if(type=='industry'){
        // Filter the tags
        this.filteredTags = this.industries.filter(tag => tag.label.toLowerCase().includes(value));
      }
      else if(type=='platform'){
        this.filteredTagsPlatform = this.toolList.filter(tag => tag.label.toLowerCase().includes(value));
      }
  }

  /**
     * Should the create tag button be visible
     *
     * @param inputValue
     */
  shouldShowCreateTagButton(inputValue: string,type): boolean
  {
      if(type=='industry'){ 
        return !!!(inputValue === '' || this.industries.findIndex(tag => tag.label.toLowerCase() === inputValue.toLowerCase()) > -1);
      }
      else if(type=='platform'){
        return !!!(inputValue === '' || this.toolList.findIndex(tag => tag.label.toLowerCase() === inputValue.toLowerCase()) > -1);
      }
  }

  /**
     * Filter tags input key down event
     *
     * @param event
     */
  filterTagsInputKeyDown(event,type): void
  {
    // Return if the pressed key is not 'Enter'
    if ( event.key !== 'Enter' )
    {
        return;
    }

    let tag, isTagApplied;
    if(type=='industry'){ 
      // If there is no tag available...
      if ( this.filteredTags.length === 0 )
      {
          // Create the tag
          this.createTag(event.target.value,type);

          // Clear the input
          event.target.value = '';

          // Return
          return;
      }
      // If there is a tag...
      tag = this.filteredTags[0];
      isTagApplied = this.industries.find(id => id === tag._id);
    }
    else if(type=='platform'){
      // If there is no tag available...
      if ( this.filteredTags.length === 0 )
      {
          // Create the tag
          this.createTag(event.target.value,type);

          // Clear the input
          event.target.value = '';

          // Return
          return;
      }
      // If there is a tag...
      tag = this.filteredTagsPlatform[0];
      isTagApplied = this.toolList.find(id => id === tag._id);
  
    }
    // If the found tag is already applied to the task...
    if ( isTagApplied )
    {
        // Remove the tag from the task
        // this.deleteTagFromTask(tag);
    }
    else
    {
        // Otherwise add the tag to the task
        this.addTagToTask(tag,type);
    }
  }

  /**
   * Create a new tag
   *
   * @param title
   */
  createTag(title: string,type): void
  {
    const tag = {
        title,
    };
    this.addTagToTask(tag,type);

    this._tagsPanelOverlayRef.dispose();
    if(type=='industry'){
      this.filteredTags = this.industries;
      // Toggle the edit mode off
      this.tagsEditMode = false;
    }
    else if(type=='platform'){
      this.filteredTagsPlatform = this.toolList;
      // Toggle the edit mode off
      this.tagsEditModePlatform = false;
    }
  }
  /**
   * Add tag to the task
   *
   * @param tag
   */
  addTagToTask(tag,type): void
  {
    // console.log(tag);
    this._tagsPanelOverlayRef.dispose();
    if(type=='industry'){
      this.horizontalStepperForm.get('step4.industry').setValue(tag.title);

      // Toggle the edit mode off
      this.tagsEditMode = false;
    }
    else if(type=='platform'){
      this.platformArray.push(new FormControl(tag.title));
      this.toolList.push({label:tag.title,value:tag.title});
      this.filteredTagsPlatform = this.toolList;

      // Toggle the edit mode off
      this.tagsEditModePlatform = false;
      // this.horizontalStepperForm.get('step11.platform').setValue(tag.title);
      // // this.categoryArray.push(new FormControl(tag.title));

      // this.filteredTagsPlatform = this.toolList;

      // // Toggle the edit mode off
      // this.tagsEditModePlatform = false;
    }
  }

  toggleTaskTag(event,type): void
  {   
    if(type=='industry'){ 
      this.horizontalStepperForm.get('step4.industry').setValue(event.value);
      this._tagsPanelOverlayRef.dispose();
      this.filteredTags = this.industries;

      // Toggle the edit mode off
      this.tagsEditMode = false;
    }
    else if(type=='platform'){
        const index = this.platformArray.controls.findIndex(control => control.value === event.value);
        if (index === -1) {
            this.platformArray.push(new FormControl(event.value));
        } else {
            this.platformArray.removeAt(index);
        }
       this.tagsEditModePlatform = false;
    }
  }

  format(time) {
    return moment(time).format('D MMMM, YYYY');
  }



  removeTypeCase(event,index){
    this.testtypeArray.splice(index,1);
    this.horizontalStepperForm.get('step11.selectedTestCaseTypes').setValue(this.testtypeArray);
  }

  removeDeviceCase(event,index){
    this.testdeviceArray.splice(index,1);
    this.horizontalStepperForm.get('step12.selectedDeviceTypes').setValue(this.testdeviceArray);
  }

  removeToolCase(event,index){
    this.platformArray.removeAt(index);
  }

}
