<div class="min-w-full min-h-11 mb-5 bg-[#F1EFFF] custom-border rounded-lg flex justify-between items-center" *ngIf="isVisible">
    <div class="flex items-center">
    <img class="w-6 h-6 ml-5" src="assets/icons/aiicon1.svg">
    <div class="ml-5 text-[#4F45E4]">
        Highlight text or click anywhere to use AI editing.
    </div>
    </div>
    <button (click)="onClose()" mat-button class="mr-5"><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.05957 15L15.1796 5M5.05957 5L15.1796 15" stroke="#4F45E4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </button>
</div>
