import { CurrencyPipe, NgClass, NgFor, NgIf,NgTemplateOutlet, JsonPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, NgZone, Component, OnDestroy, OnInit, ViewEncapsulation,ViewChild, Input, AfterViewInit, TemplateRef, AfterViewChecked, ElementRef, ViewChildren, QueryList, HostListener, Output, EventEmitter, Renderer2  } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators,UntypedFormGroup,UntypedFormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';


@Component({
  selector: 'app-configuration',
  standalone: true,
  imports: [ MatIconModule, MatButtonModule, MatSelectModule, MatPaginator, MatPaginatorModule, FormsModule, MatRippleModule, ReactiveFormsModule, MatMenuModule, MatTabsModule, MatFormFieldModule, NgTemplateOutlet, MatInputModule, MatButtonToggleModule, NgFor, NgIf, MatTableModule, NgClass, MatProgressBarModule, CurrencyPipe, JsonPipe, CommonModule],
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.scss'
})
export class ConfigurationComponent {


  codeString ="public class RandomAccountController {\n" +
  "    // Method to generate a random account and save it\n" +
  "    public static Account generateRandomAccount() {\n" +
  "        Account randomAccount = new Account();\n" +
  "        // Generate random data for the account fields\n" +
  "        randomAccount.Name = 'Account ' + String.valueOf(Math.random()).substring(2, 8);\n" +
  "        randomAccount.Type = (Math.random() > 0.5) ? 'Prospect' : 'Customer';\n" +
  "        randomAccount.Industry = (Math.random() > 0.5) ? 'Technology' : 'Finance';\n" +
  "        // Insert the account\n" +
  "        insert randomAccount;\n" +
  "        return randomAccount;\n" +
  "    }\n\n" +
  "    // Method to retrieve a random account from the database based on specific criteria\n" +
  "    public static Account getRandomAccountByIndustry(String industry) {\n" +
  "        List<Account> accounts = [SELECT Id, Name, Type, Industry FROM Account WHERE Industry = :industry LIMIT 1];\n" +
  "        return accounts.isEmpty() ? null : accounts[0];\n" +
  "    }\n" +
  "}";


}
