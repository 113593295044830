import { WebsocketService } from './../../../../core/websockets/websocket.service';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, inject, Input,OnInit,Output,SimpleChanges,ViewChild,viewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { UserStoryComponent } from '../user-story/user-story.component';
import { JIRA } from 'app/core/common/constants';
import { NgClass, NgIf } from '@angular/common';
import { filter } from 'rxjs';
import { AtlassianService } from 'app/core/common/atlassian.service';
import { MatSelectModule } from '@angular/material/select';
import { DeleteConfirmationDialogComponent } from 'app/modules/common/delete-confirmation/delete-confirmation-dialog.component';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-story-table',
  standalone: true,
  imports: [MatIcon,UserStoryComponent,NgClass,NgIf,MatSelectModule,DragDropModule],
  templateUrl: './story-table.component.html',
  styleUrl: './story-table.component.scss'
})
export class StoryTableComponent implements OnInit {
  priorityTypes=[
    {value:JIRA.MEDIUM,label:"Medium",icon:'assets/icons/p_medium.svg'},
    {value:JIRA.HIGHEST,label:"Highest",icon:'assets/icons/p_highest.svg'},
    {value:JIRA.HIGH,label:"High",icon:'assets/icons/p_high.svg'},
    {value:JIRA.LOWE,label:"Low",icon:'assets/icons/p_low.svg'},
    {value:JIRA.LOWEST,label:"Lowest",icon:'assets/icons/p_lowest.svg'}
  ];
  @Input()
  stories: any[];

  @Input()
  themeTypes=[];

  @Input()
  epicTypes=[];

  @Input()
  releases_list=[];

  @Input()
  _page_id:any;

  @Input()
  is_write_allowed:any;


  @Input()
  proposal:any;

  @Output()
  onUpdateStories: EventEmitter<boolean>= new EventEmitter<boolean>();


  readonly dialog = inject(MatDialog);
  storyViewList : any[] = [];

  isSyncingWithJira:boolean=false;

  constructor(private websocketService:WebsocketService,private _changeDetectorRef: ChangeDetectorRef,private atlassianService:AtlassianService){
  
    
    this.websocketService.messageSubject.pipe(
      filter((message) => message.type === "story_synced")
    ).subscribe((message:any) => {
      let data=message.data;

      for(let story of this.storyViewList){
        if(story._id==data.story){
          story.jiraIssueID=data.jiraID;
        story.jiraKey=data.jiraKey;
        story.jiraSynced=true;
        story.isSyncing=false;

          break;
        }
      }


    });


  }

  ngOnInit(){
    // console.log(this.stories);
    if(this.stories){
    this.storyViewList = JSON.parse(JSON.stringify(this.stories));
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes['stories'] && changes['stories'].currentValue) {
  //     console.log(changes['stories'].currentValue);
  //   }
  // }

  openDialog() {
    const dialogRef = this.dialog.open(UserStoryComponent,
      { 
        panelClass: 'add-story-dialog',
        disableClose: true,
        data:{proposal:this.proposal,themeTypes:this.themeTypes,epicTypes:this.epicTypes}  
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.push){
        this.onPushToJira();
      }else if(result && result.updated){
      this.onUpdateStories.emit(true);
      }
    });
  }

  getReleaseValue(release_id){
    let element = this.releases_list.find(el=>el._id==release_id);
    return element.short_name;
  }

  getPriority(priority:number){
    let priorityFound=this.priorityTypes.find((p)=>p.value==priority);
    if(priorityFound){
      return priorityFound;
    }
    return  this.priorityTypes[0];
  }

  getIntegrationStatus(){
    if(this.proposal && this.proposal.integrations && this.proposal.integrations.jira && this.proposal.integrations.jira.active==true){
      return true;
    }
    return false;
  }

  getStatusString(status){
    if(status==JIRA.TO_DO){
      return "To Do";
    }else if(status==JIRA.IN_PROGRESS){
      return "In Progress";
    }else if(status==JIRA.DONE){
      return "Done";
    }
  }

  getStoryID(story){
    if(story && story._id!=""){
      if(story.jiraKey && story.jiraKey!=""){
        return story.jiraKey;
      }
      return story.storyID;
    }
    return "";
  }

  onPushToJira(){
    if(this.isSyncingWithJira){
      return;
    }
    this.isSyncingWithJira=true;
    this.atlassianService.syncProjectWithJira(this.proposal._id).subscribe((response)=>{
      if(response.success){

      }
    })
  }

  viewStoryInJira(story){
    if(!story.jiraSynced){
      return;
    }
    let issueURL=this.atlassianService.constructJiraIssueLink(this.proposal.integrations.jira.site,story.jiraKey);
    window.open(issueURL || "", "_blank");
  }

  onEditStory(story){
    const dialogRef = this.dialog.open(UserStoryComponent,{ 
      panelClass: 'add-story-dialog',
      disableClose: true,
      data:{proposal:this.proposal,themeTypes:this.themeTypes,epicTypes:this.epicTypes,story:story}  
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.push){
        this.onPushToJira();
      }else if(result && result.updated){
        this.onUpdateStories.emit(true);
      }
    });
  }

  onDeleteStory(story){
    if(story.delete){
      return;
    }
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: {message:"Are you sure you want to delete this story?",title:"Confirm Delete",buttonTitle:"Delete"},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.yes===true){
        story.delete=true;
        this.atlassianService.deleteStory(story._id).subscribe((response)=>{
          this.onUpdateStories.emit(true);
        })
      }
    });
  }

  onStoryReleaseSelection(event,story){
    // console.log(event);
    // console.log(story);
    let saveStoryRequest={
      _id:story._id,
      proposalID:this.proposal._id,
      title:story.title,
      theme:story.theme,
      epic:story.epic,
      release:event.value,
      priority:story.priority,
      status:story.status,
      tags:story.tags,
      description:story.description,
      attachments:story.attachments,
      dependencies:[]
    }
    this.atlassianService.createUpdateStory(saveStoryRequest).subscribe((response)=>{
      if(response && response.push){
        this.onPushToJira();
      }else if(response && response.updated){
      this.onUpdateStories.emit(true);
      }
    });
  }

  dropped(event: CdkDragDrop<any[]>): void
  {
    if(this.is_write_allowed){
      // Move the item in the array
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      // Mark for check
      this._changeDetectorRef.markForCheck();
      console.log(event);
    }
  }

  syncSingleStory(story){
    story.isSyncing=true;
    this.atlassianService.syncProjectWithJira(this.proposal._id,story._id).subscribe((response)=>{
      if(response.success){
      }
    });
  }
}
