import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { map, Observable, ReplaySubject, tap, of, switchMap, catchError } from 'rxjs';
import { environment } from 'environments/environment';
@Injectable({providedIn: 'root'})
export class UserService
{
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<User>
    {
        return this._httpClient.get<User>(environment.api_endpoint + 'user', { headers: { method: 'profile' } }).pipe(
            tap((user) =>
            {   
                this._user.next(user);
                return of(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'user/auth', user, { headers: { method: 'save_profile' } }).pipe(
            switchMap((response: any) =>
            {
                return of(response);
            }),
        );
    }

    postUserImage(data): Observable<any>
    {
        return this._httpClient.post(environment.api_endpoint + 'upload/save',JSON.stringify(data), { headers: { method: 'test' } }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
              return of(response);
            }),
        );
    }
}
