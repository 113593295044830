import { Injectable, Injector } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ProgressEventTracker {

  constructor(){

  }

  saveProgressEvent(type: string, taskID: string, event: string): void {
    const progressKey = `${type}-${taskID}`;
    try {
      localStorage.setItem(progressKey, event);
    } catch (error) {
      console.error('Failed to save progress event:', error);
    }
  }

  getProgressEvents(type: string, taskID: string): string {
    const progressKey = `${type}-${taskID}`;
    try {
      const event = localStorage.getItem(progressKey);
      if (event) {
        return event;
      }
    } catch (error) {
      console.error('Failed to get progress events:', error);
    }
    return "";
  }

}
