// event.service.ts
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, of, Subject, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AtlassianService {

  private _httpClient = inject(HttpClient);
    connectAtlassianAccount(proposalID:string){
        const authorizationUrl = environment.jira_auth+`&state=${proposalID}`;
        //`https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=miQap0CShiOGztt6GDdEngOU45JjtPGd&scope=read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20read%3Ajira-user%20write%3Ajira-work%20manage%3Ajira-webhook%20manage%3Ajira-data-provider%20offline_access&redirect_uri=https%3A%2F%2Fmy.getgenerative.ai%2Fatlassian%2Fcallback&state=${proposalID}&response_type=code&prompt=consent`;

      window.location.href = authorizationUrl;
    }

     saveToken(token:string,proposalID:string){
      return this._httpClient.post(environment.api_endpoint + 'atlassian',JSON.stringify({code:token,proposalID:proposalID}), { headers: { method: 'save_token' } }).pipe(
        catchError(() =>
            // Return false
            of(false),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }

    getSites(proposalID:string){
      return this._httpClient.get(environment.api_endpoint + 'atlassian?proposalID='+proposalID,{ headers: { method: 'get_clouds' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    getSiteProjects(siteID,proposalID){
      return this._httpClient.get(environment.api_endpoint + 'atlassian?cloudid='+siteID+"&proposalID="+proposalID,{ headers: { method: 'get_projects' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    saveJiraMappings(proposalID,jira){
      return this._httpClient.post(environment.api_endpoint + 'atlassian',{proposalID:proposalID,jira:jira},{ headers: { method: 'save_mappings' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    getProjectStories(projectID){
      return this._httpClient.get(environment.api_endpoint + 'atlassian?proposalID='+projectID,{ headers: { method: 'get_stories' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    createUpdateStory(request:any){
      return this._httpClient.post(environment.api_endpoint + 'atlassian',request,{ headers: { method: 'create_story' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    deleteStory(storyID:string){
      return this._httpClient.post(environment.api_endpoint + 'atlassian',{_id:storyID},{ headers: { method: 'delete_story' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    syncProjectWithJira(proposalID,storyID=""){
      return this._httpClient.post(environment.api_endpoint + 'atlassian',{proposalID:proposalID,storyID:storyID},{ headers: { method: 'sync_project' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    jiraSync(request){
      return this._httpClient.post(environment.api_endpoint + 'atlassian',JSON.stringify(request),{ headers: { method: 'jira_sync' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    getTestCaseNotSyncedCount(planID){
      return this._httpClient.get(environment.api_endpoint + 'atlassian?planID='+planID,{ headers: { method: 'get_test_case_jira_stats' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    removeJiraIntegrations(proposalID){
      return this._httpClient.post(environment.api_endpoint + 'atlassian',{proposalID:proposalID},{ headers: { method: 'remove_mappings' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    pauseJiraIntegrations(proposalID:string,active:boolean){
      return this._httpClient.post(environment.api_endpoint + 'atlassian',{proposalID:proposalID,active:active},{ headers: { method: 'pause_integration' } }).pipe(
        catchError(() =>
            // Return false
            of(null),
        ),
        switchMap((response: any) =>
        {
          return of(response);
        }),
    );
    }
    constructJiraIssueLink(site: string, issueKey: string): string {
      const fullSite = `${site}.atlassian.net`;
      return `https://${fullSite}/browse/${issueKey}`;
    }
}
