import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerComponent } from '@fuse/components/drawer';

@Component({
  selector: 'app-add-epic',
  standalone: true,
  imports: [FuseDrawerComponent,NgIf,MatIcon,MatMenuModule,ReactiveFormsModule,MatFormFieldModule,MatInputModule,MatSelectModule],
  templateUrl: './add-epic.component.html',
  styleUrl: './add-epic.component.scss'
})
export class AddEpicComponent {
  @ViewChild("addEpicDrawer")public epicDrawer:FuseDrawerComponent;
  isSavingEpic:boolean=false;
  themeForm:UntypedFormGroup;
  epicSaved:boolean=false;

  isEdit:boolean=false;
  oldEpic:string="";
  
  @Output()
  onSaveEpic=new EventEmitter<any>();

  @Input()
  themeTypes:any=[];

  constructor(private _formBuilder:UntypedFormBuilder){
    this.themeForm=this._formBuilder.group({
      epicName:["",Validators.required],
      theme:["",Validators.required]
    });
  }

  ngOnInit(){
   
  }

  openedChanged(event){
    
   
  }
  onDeleteEpic(){

  }
  onCloseDrawer(){
    this.themeForm.reset();
  }
  onSaveEpicEvent(){
    this.onSaveEpic.emit({epic:this.themeForm.get("epicName").value,theme:this.themeForm.get("theme").value,oldEpic:this.oldEpic,isEdit:this.isEdit});
    this.themeForm.reset();
    this.epicDrawer.close();

  }
  onChangeThemeSelection(event){

  }
}
