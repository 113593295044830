export const JIRA={
    NONE:-1,
    TASK:0,
    STORY:1,
    BUG:2,
    EPIC:3,
    COMPONENT:4,
    RELEASE:5,
    TO_DO:6,
    IN_PROGRESS:7,
    DONE:8,
    LOWEST:10,
    LOWE:11,
    MEDIUM:12,
    HIGH:13,
    HIGHEST:14,
    LABEL:15,
    SUBTASK:16

  }