<div class="flex flex-col flex-auto min-w-0 relative" >
       
    <!-- <span class="close-dialog cursor-pointer" [mat-dialog-close]="true" (click)="closeDialog()"> 
        <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
    </span> -->
    <!-- Main -->
    <div class="flex-auto">
        <div class="formBox">
            <div class="formHead flex justify-between">
                <h4 class="text-2xl">{{userTestCase && userTestCase._id?'Edit':'Create'}} Test Case</h4>
                <span class="close-dialog2 cursor-pointer" [mat-dialog-close]="true" (click)="closeDialog()"> 
                    <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
                </span>
            </div>
            <div class="separator"></div>
            <form action="" class="mainform" [formGroup]="testCaseForm">
                <div class="desc flex flex-col">
                    <label class="font-medium">Title</label>
                    <textarea [formControlName]="'title'"  name="" id="" class="border mt-2 rounded w-full p-3" row="2" style="resize: none;" ></textarea>
                </div>

                <div class="fir flex gap-6 mt-6">
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Theme</mat-label>
                        <mat-select [placeholder]="'Select Theme'" [formControlName]="'theme'"  class="selectOption2" (selectionChange)="onChangeTheme($event)">
                            @for (theme of themeTypes;track theme){
                                <mat-option [value]="theme.value">{{theme.label}}</mat-option> 
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Epic</mat-label>
                        <mat-select [placeholder]="'Select Epic'" [formControlName]="'epic'"  class="selectOption2">
                            @for(epic of epicTypes;track epic){
                                <mat-option [value]="epic.value">{{epic.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <div class="flex flex-auto flex-col gap-3 gt-xs:pl-3 selectField">
                        <mat-label class="font-medium">Story ID</mat-label>
                        <span class="selectOption2 cursor-pointer" (click)="selectStory()">          {{testCaseForm.get("storyID").value?testCaseForm.get("storyID").value:'Select Story'}}
                        </span>
                    </div>
                    <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                        <mat-label>Release</mat-label>
                        <mat-select [placeholder]="'Select Release'" [formControlName]="'release'"  class="selectOption2">
                            @for (release of releaseTypes;track release){
                                <mat-option [value]="release.value">{{release.label}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="flex mt-6 justify-between">
                    <div class="w-full flex gap-6">
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>Type</mat-label>
                            <mat-select [placeholder]="'Select Type'" class="selectOption2" [formControlName]="'type'">
                                @for (eachtype of typeList;track eachtype){
                                    <mat-option [value]="eachtype.value">{{eachtype.label}}</mat-option> 
                                }
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="testCaseForm.get('category').value" (click)="openTagsPanel('category')" #tagsPanelOriginCategory style="min-width: 180px;">
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                                <mat-label>Category</mat-label>
                                <mat-select [placeholder]="testCaseForm.get('category').value" class="selectOption2">
                                </mat-select>
                            </mat-form-field> 
                        </div>
                        <div *ngIf="!testCaseForm.get('category').value" (click)="openTagsPanel('category')" #tagsPanelOriginCategory style="min-width: 170px;">
                            <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                                <mat-label>Category</mat-label>
                                <mat-select [placeholder]="'Select Category'" class="selectOption2">
                                </mat-select>
                            </mat-form-field> 
                        </div>
                        <div class="" >
                            <!-- Tags panel -->
                            <ng-template #tagsPanelCategory>
                                <div class="w-60 rounded border shadow-md bg-card dropdownC">
                                    <!-- Tags panel header -->
                                    <div class="flex items-center m-3 mr-2">
                                        <div class="flex items-center">
                                            <mat-icon
                                                class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                            <div class="ml-2">
                                                <input
                                                    class="w-full min-w-0 py-1 border-0"
                                                    type="text"
                                                    placeholder="Enter Category"
                                                    (input)="filterTags($event,'category')"
                                                    (keydown)="filterTagsInputKeyDown($event,'category')"
                                                    [maxLength]="30"
                                                    #newTagInputCategory>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
                                        <!-- Tags -->
                                        <ng-container *ngIf="!tagsEditModeCategory">
                                            <ng-container *ngFor="let tag of filteredTagsCategory; trackBy: trackByFn">
                                                <div
                                                    class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                                                    (click)="toggleTaskTag(tag,'category')"
                                                    matRipple>
                                                    <mat-checkbox
                                                        class="flex items-center h-10 min-h-10 pointer-events-none"
                                                        [checked]="testCaseForm.get('category').value==tag.label"
                                                        [color]="'primary'"
                                                        [disableRipple]="true">
                                                    </mat-checkbox>
                                                    <div class="ml-1">{{tag.label}}</div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <!-- Create tag -->
                                        <div class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer hover:bg-hover"
                                            *ngIf="shouldShowCreateTagButton(newTagInputCategory.value,'category')"
                                            (click)="createTag(newTagInputCategory.value,'category'); newTagInputCategory.value = ''"
                                            matRipple>
                                            <mat-icon
                                                class="mr-2 icon-size-5"
                                                [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                            <div class="break-all">Create "<b>{{newTagInputCategory.value}}</b>"</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                       
                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>  Priority</mat-label>
                            <mat-select  [placeholder]="'Select Priority'" [formControlName]="'priority'" (selectionChange)="updatePriority($event)"  class="selectOption2" #proritySelect="matSelect">
                            <mat-select-trigger>
                                <div class="flex gap-1 items-center">
                                    <img [src]="getPriority(testCaseForm.get('priority').value).icon" class="w-5 h-5">{{getPriority(testCaseForm.get('priority').value).label}}
                                </div> 
                            </mat-select-trigger>

                            <ng-container *ngFor="let priority of priorityTypes;">
                                <mat-option [(value)]="priority.value">
                                    <div class="flex gap-1 items-center"><img class="w-3 h-3" [src]="priority.icon"><span>{{priority.label}}</span></div>  
                                </mat-option>
                            </ng-container>
                            </mat-select>
                        </mat-form-field>
                        

                        <mat-form-field class="flex-auto gt-xs:pl-3 selectField">
                            <mat-label>  Status</mat-label>
                            <mat-select [placeholder]="'Select Status'" [formControlName]="'status'" class="selectOption2" (selectionChange)="updateStatus($event)" #statusSelect="matSelect">
                            <mat-select-trigger>
                                <div class="flex gap-1 items-center">
                                    <img [src]="getStatus(testCaseForm.get('status').value).icon" class="w-5 h-5">{{getStatus(testCaseForm.get('status').value).label}}
                                </div> 
                            </mat-select-trigger>

                            <ng-container *ngFor="let status of statusTypes;">
                                <mat-option [(value)]="status.value">
                                    <div class="flex gap-1 items-center"><img class="w-3 h-3" [src]="status.icon"><span>{{status.label}}</span></div>  
                                </mat-option>
                            </ng-container>
                            </mat-select>
                        </mat-form-field>


                    </div>
                </div>

                <div class="flex justify-between">
                    <div class="tagSec mt-6">
                        <h5 class="text-md font-medium">Tags</h5>
                        <div class="flex flex-wrap items-center ">
                            <!-- Tags -->
                            <ng-container *ngIf="tagArray.controls.length">
                                <ng-container *ngFor="let eachtag of tagArray.controls; let i = index">
                                    <div class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                                        <span class="text-md text-black whitespace-nowrap">{{eachtag.value}}</span>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <div
                                class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 cursor-pointer text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700"
                                (click)="openTagsPanel('tag')"
                                #tagsPanelOrigin>
                                <ng-container *ngIf="tagArray.controls.length">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                                    <span class="ml-1.5 text-md font-medium whitespace-nowrap">Edit</span>
                                </ng-container>
    
                                <ng-container *ngIf="!tagArray.controls.length">
                                    <mat-icon
                                        class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                    <span class="ml-1.5 text-md font-medium whitespace-nowrap">Add</span>
                                </ng-container>
            
                                <!-- Tags panel -->
                                <ng-template #tagsPanel>
                                    <div class="w-60 rounded border shadow-md bg-card mt-8">
                                        <!-- Tags panel header -->
                                        <div class="flex items-center m-3 mr-2">
                                            <div class="flex items-center">
                                                <mat-icon
                                                    class="icon-size-5"
                                                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                                <div class="ml-2">
                                                    <input
                                                        class="w-full min-w-0 py-1 border-0"
                                                        type="text"
                                                        (input)="filterTags($event,'tag')"
                                                        (keydown)="filterTagsInputKeyDown($event,'tag')"
                                                        placeholder="Enter Tags"
                                                        [maxLength]="30"
                                                        #newTagInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="flex flex-col max-h-64 py-2 border-t overflow-y-auto">
                                            <!-- Tags -->
                                            <ng-container *ngIf="!tagsEditMode">
                                                <ng-container *ngFor="let eachtag of filteredTags; trackBy: trackByFn">
                                                    <div
                                                        class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                                                        (click)="toggleTaskTag(eachtag,'tag')"
                                                        matRipple>
                                                        <mat-checkbox
                                                            class="flex items-center h-10 min-h-10 pointer-events-none"
                                                            [checked]="tagArray.value.includes(eachtag.label)"
                                                            [color]="'primary'"
                                                            [disableRipple]="true">
                                                        </mat-checkbox>
                                                        <div class="ml-1">{{eachtag.label}}</div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <div
                                                class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer hover:bg-hover"
                                                *ngIf="shouldShowCreateTagButton(newTagInput.value,'tag')"
                                                (click)="createTag(newTagInput.value,'tag'); newTagInput.value = ''"
                                                matRipple>
                                                <mat-icon
                                                    class="mr-2 icon-size-5"
                                                    [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                                                <div class="break-all">Create "<b>{{newTagInput.value}}</b>"</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="desc flex flex-col mt-6">
                    <label class="font-medium">Description*</label>
                    <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                        <!-- Toolbar with icons inside editor box -->
                        <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBold()">
                            <mat-icon class="icon-size-4">format_bold</mat-icon>
                          </button>
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleItalic()">
                            <mat-icon class="icon-size-4">format_italic</mat-icon>
                          </button>
                          <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBulletList()">
                            <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                          </button>
                        </div>
                      
                        <!-- TipTap editor component -->
                        <tiptap-editor 
                          [editor]="editor1"
                          [formControlName]="'description'"
                          id="tiptap-editor"
                          placeholder="Enter description">
                        </tiptap-editor>
                    </div>
                </div> 

                <div class="desc flex flex-col mt-6">
                        <label class="font-medium">Preconditions</label>
                        <div class="summarybox border border-gray-300 rounded-lg bg-white mt-2">
                            <!-- Toolbar with icons inside editor box -->
                            <div class="editor-toolbar flex space-x-2 p-2 bg-gray-100 rounded-t-lg border-b border-gray-300">
                              <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBold()">
                                <mat-icon class="icon-size-4">format_bold</mat-icon>
                              </button>
                              <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleItalic()">
                                <mat-icon class="icon-size-4">format_italic</mat-icon>
                              </button>
                              <button class="text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-center" (click)="toggleBulletList()">
                                <mat-icon class="icon-size-4">format_list_bulleted</mat-icon>
                              </button>
                            </div>
                          
                            <!-- TipTap editor component -->
                            <tiptap-editor 
                              [editor]="editor"
                              [formControlName]="'preconditions'"
                              id="tiptap-editor"
                              placeholder="Enter preconditions">
                            </tiptap-editor>
                        </div>
                </div> 

                <div class="border min-h-20 mt-6 p-2 rounded dependSec">
                    <div class="head flex justify-between">
                        <div class="content">
                            <h4 class="font-medium text-lg">Steps and Results</h4>
                            <p class="text-[#9AA6B8] text-sm">Add Steps and their corresponding results to create the flow</p>
                        </div>
                    </div>

                    <!-- add Dependencies -->
                    <div class="w-full" cdkDropList (cdkDropListDropped)="dropped($event)" [cdkDropListData]="steps.controls">
                        <div *ngFor="let step of steps.controls; let i = index" [formGroup]="step" class="dependCont flex gap-4 mt-4" cdkDrag>
                          <div class="h-full flex items-center justify-center">
                            <mat-icon class="cursor-move" [svgIcon]="'heroicons_solid:bars-3'" cdkDragHandle></mat-icon>
                            <span class="stepCounter">{{ i + 1 }}</span>
                          </div>
                          <div class="flex items-center gap-4 w-full">
                            <div class="linkedTo desc">
                              <label class="font-medium">Steps</label>
                              <textarea formControlName="step" class="border h-20 mt-2 rounded w-full p-2" style="resize: none;"></textarea>
                            </div>
                            <mat-icon class="icon-size-5  mt-5 text-[#94A3B8]" svgIcon="heroicons_outline:arrows-right-left"></mat-icon>
                            <div class="userStory desc">
                              <label class="font-medium">Expected Results</label>
                              <textarea formControlName="expectedResult" class="border h-20 mt-2 rounded w-full p-2" style="resize: none;"></textarea>
                            </div>
                            <mat-icon class="mt-5 cursor-pointer" svgIcon="heroicons_outline:trash" (click)="deleteStep(i)"></mat-icon>
                          </div>
                        </div>
                    </div>
                    
                    <div class="dependAct flex justify-end">
                    <button mat-flat-button [color]="'primary'" class="mt-6 mb-4 font-normal" (click)="addSteps()">
                        <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon> Add Steps
                    </button>
                    </div>

                </div>

                

                <div class="border min-h-20 mt-6 p-2 rounded uploadSec">
                    <div class="head flex justify-between">
                        <div class="content">
                            <h4 class="font-medium text-lg">Attachments</h4>
                            <p class="text-[#9AA6B8] text-sm">Seamlessly attach documents, images, and links to enhance your workflow</p>
                            <!-- @for(attachment of fileAttachments;track attachment){
                                <div class="flex justify-between mt-4">{{attachment.fileName}}</div>
                            } -->
                        </div>
                        <input type="file" accept=".jpg, .png, .pdf, .docx, .pptx, .txt, .csv"  #fileInput (change)="onFilesSelected($event)" style="display: none;" multiple>

                        <button (click)="onClickSelectFile()" class="border h-8 flex items-center pl-3 pr-3 rounded text-md uploadBt"> <mat-icon class="icon-size-5" svgIcon="heroicons_outline:arrow-up-tray"></mat-icon> Upload File</button>
                        <div class="my-1 mb-2" *ngIf="sizeLimitAlert">
                            <fuse-alert
                            [appearance]="'soft'"
                            [showIcon]="false"
                            type="error"
                            [dismissible]="true" [dismissed]="false">
                            The maximum allowed file size is 20MB
                            </fuse-alert>
                        </div>
                    </div>
                    <!-- file list -->
                    <div class="filelist p-3 flex flex-wrap" *ngIf="fileAttachments && fileAttachments.length">
                        @for(attachment of fileAttachments;track attachment){
                        <div class="flex gap-2 items-center listBox p-2" *ngIf="getFileTypeString(attachment)">
                            <div class="relative inline">                     
                                <mat-icon class="icon-size-8" matPrefix svgIcon="heroicons_outline:document"></mat-icon>
                                <div class="typeText absolute bottom-0 left-0 rounded px-1.5 text-xs font-semibold leading-5 text-white" [ngClass]="getFileClass(attachment)"> {{getFileTypeString(attachment)}} </div>
                            </div>
                            <span  class="flex gap-2 items-center">{{attachment.fileName}} <mat-icon (click)="onRemoveFile(attachment)" class="icon-size-5 cursor-pointer" svgIcon="heroicons_outline:trash"></mat-icon></span>
                        </div>
                        }
                        
                    </div>
                    <div class="loader pl-3" *ngIf="fileLoader" >
                        <mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
                    </div>
                </div>

                <div class="formAct flex justify-end mb-8 items-center">
                    <button  mat-flat-button [color]="'primary'" class="mt-6" (click)="onSaveTestCase()"  [disabled]="(testCaseForm.invalid || isSavingStory)">
                        <div class="flex gap-1 items-center">
                        <mat-progress-spinner *ngIf="isSavingStory" mode="indeterminate"></mat-progress-spinner> <span>Save</span>
                        </div>
                    </button>
                </div>


            </form>

        </div>
    </div>
</div>