<div class="flex w-full flex-col flex-auto items-center sm:justify-center" [ngClass]="{'cursor-pointer': (selectedIndex === 8)}" style="font-family: 'Inter', sans-serif;" (click)="(selectedIndex === 8?updateProfile():'')">
    <div class="flex w-full sm:shadow sm:bg-card h-full">
        <div class="relative flex flex-auto items-center justify-center h-full lg:px-28 w-full bg-[#0F172A] dark:border-r">
            <!-- Background - @formatter:off -->
            <!-- Rings -->
            <svg class="absolute inset-0 pointer-events-none"
                 viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
                <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                    <circle r="234" cx="196" cy="23"></circle>
                    <circle r="234" cx="790" cy="491"></circle>
                </g>
            </svg>
            <!-- Dots -->
            <svg class="absolute -top-16 right-0 text-gray-700 bg-sec"
                 viewBox="0 0 220 192" width="220" height="192" fill="none">
                <defs>
                    <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                    </pattern>
                </defs>
                <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
            </svg>
            
            <div class="infoBox text-white text-center w-full" *ngIf="!startStepper">
                <h2 class="text-7xl font-semibold welcomeText mb-6" >Welcome to GetGenerative.ai </h2>
                <h5 class="text-2xl mt-6">Implement Salesforce 10x Faster with AI</h5>
                <p class="text-secondary">AI-powered Workspace + Agents to manage the entire implementation lifecycle from Pre-Sales to Deploy.</p>
                <button mat-flat-button class="mt-24 min-w-60" [color]="'primary'" type="button" (click)="startStepper = !startStepper">
                    Continue
                </button>
            </div>


            <div class="stepBox w-full text-white text-center relative z-10" *ngIf="startStepper">
                <form class="p-4" [formGroup]="horizontalStepperForm">
                    <mat-horizontal-stepper [linear]="true" #horizontalStepper (selectionChange)="onStepChange($event)">

                        <mat-step  [formGroupName]="'step0'" [stepControl]="horizontalStepperForm.get('step0')"
                            #horizontalStepperStep0 >
                            

                            <h5 class="text-4xl mt-6">Introducing Agents</h5>
                            <p class="text-secondary">Our AI Agents automate 70% of Salesforce implementation tasks, freeing you to focus on strategy and innovation.</p>

                            <div class=" allset iagentCard flex flex-wrap justify-center gap-6 mt-12">
                                <div class="card flex text-left  flex-col gap-2 p-4 border">
                                    <img class="w-10 h-10" src="assets/icons/agents/presales.svg" alt="">
                                    <h4 class="text-2xl font-medium">Pre-Sales Agent</h4>
                                    <p class="text-secondary text-md">Automate proposals, enrich needs analysis, and craft winning solutions effortlessly.</p>
                                </div>
                                <div class="card flex text-left  flex-col gap-2 p-4 border">
                                    <img class="w-10 h-10" src="assets/icons/agents/design.svg" alt="">
                                    <h4 class="text-2xl font-medium">Design Agent</h4>
                                    <p class="text-secondary text-md">Convert scopes into detailed user stories, requirements, and acceptance criteria in minutes.</p>
                                </div>
                                <div class="card flex text-left  flex-col gap-2 p-4 border">
                                    <img class="w-10 h-10" src="assets/icons/agents/build.svg" alt="">
                                    <h4 class="text-2xl font-medium">Build Agent <span class="smallTag"> Coming Soon </span></h4>
                                    <p class="text-secondary text-md">Generate technical designs, functional specs, and guides for fast, efficient development.</p>
                                </div>
                                <div class="card flex text-left  flex-col gap-2 p-4 border">
                                    <img class="w-14 h-10" src="assets/icons/agents/test.svg" alt="">
                                    <h4 class="text-2xl font-medium">Test Agent</h4>
                                    <p class="text-secondary text-md">Ensure flawless delivery with test plans, test cases, and UAT scripts.</p>
                                </div>
                                <div class="card flex text-left  flex-col gap-2 p-4 border">
                                    <img class="w-10 h-10" src="assets/icons/agents/golive.svg" alt="">
                                    <h4 class="text-2xl font-medium">Go-Live Agent <span class="smallTag"> Coming Soon </span></h4>
                                    <p class="text-secondary text-md">Deploy confidently with detailed checklists, deployment plans, and robust support.
                                    </p>
                                </div>
                            </div>


                            <div class="flex justify-center mt-12">
                                <button
                                    class="px-8  min-w-60"
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="horizontalStepperStep0.stepControl.invalid"
                                    type="button"
                                    matStepperNext>
                                    Continue
                                </button>
                            </div>
                        </mat-step>

                        <!-- <mat-step  [formGroupName]="'step1'" [stepControl]="horizontalStepperForm.get('step1')"
                            #horizontalStepperStep1 >
                            

                            <h5 class="text-4xl mt-6">Integrations</h5>
                            <p class="text-secondary">Integrates with all AI development tools to automate code creation.</p>

                            <div class="iconlist grid grid-cols-2 gap-10 mt-12 mb-6 text-left">
                                <div class="flex gap-2 items-center">
                                    <img src="assets/icons/atlassian.png" class="w-12 h-12" alt="">
                                    <div>
                                        <h4 class="font-medium text-lg">Atlassian</h4>
                                        <p class="font-normal text-md text-secondary">Organize and Track Your Projects Efficiently</p>
                                    </div>
                                </div>

                                <div class="flex gap-2 items-center ml-12">
                                    <img src="assets/icons/agents/githubnew.png" class="w-12 h-12" alt="">
                                    <div>
                                        <h4 class="font-medium text-lg">Github <span class="smallTag"> Coming Soon </span></h4>
                                        <p class="font-normal text-md text-secondary">Streamline Collaboration and Code Management</p>
                                    </div>
                                </div>

                                <div class="flex gap-2 items-center">
                                    <img src="assets/icons/agents/codegen.png" class="w-12 h-12" alt="">
                                    <div>
                                        <h4 class="font-medium text-lg">Codegen <span class="smallTag"> Coming Soon </span></h4>
                                        <p class="font-normal text-md text-secondary">Automate Code Generation for Faster Development</p>
                                    </div>
                                </div>

                                <div class="flex gap-2 items-center ml-12">
                                    <img src="assets/icons/agents/gearsetnew.png" class="w-12 h-12" alt="">
                                    <div>
                                        <h4 class="font-medium text-lg">Gearset <span class="smallTag"> Coming Soon </span></h4>
                                        <p class="font-normal text-md text-secondary">Simplify Salesforce Deployments and Rollbacks</p>
                                    </div>
                                </div>

                                <div class="flex gap-2 items-center">
                                    <img src="assets/icons/copado.png" class="w-12 h-12" alt="">
                                    <div>
                                        <h4 class="font-medium text-lg">Copado <span class="smallTag"> Coming Soon </span></h4>
                                        <p class="font-normal text-md text-secondary">Manage Salesforce DevOps with Ease</p>
                                    </div>
                                </div>

                                <div class="flex gap-2 items-center ml-12">
                                    <img src="assets/icons/codean.png" class="w-12 h-12" alt="">
                                    <div>
                                        <h4 class="font-medium text-lg">Code Analyzer 5.0 <span class="smallTag"> Coming Soon </span></h4>
                                        <p class="font-normal text-md text-secondary">Enhance Code Quality with In-Depth Analysis</p>
                                    </div>
                                </div>

                            </div>


                            
                            <div class="flex justify-center mt-12 gap-6">
                                <button class="px-8 mr-2 min-w-30" mat-flat-button [color]="'accent'" type="button" matStepperPrevious>
                                    Back
                                </button>
                                <button
                                    class="px-8 "
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="horizontalStepperStep1.stepControl.invalid"
                                    type="button"
                                    matStepperNext>
                                    Continue
                                </button>
                            </div>
                        </mat-step> -->

                        <mat-step  [formGroupName]="'step2'" [stepControl]="horizontalStepperForm.get('step2')" #horizontalStepperStep2 >
                        

                            <h5 class="text-4xl mt-6">Tell Us About Yourself</h5>
                            <p class="text-secondary mb-10">Help us personalize your experience with a few quick details.</p>


                            <div class="radioBox text-left font-medium mt-6">
                                <label class="block mb-4 text-lg" >What best describes your current role?</label>
                                <mat-radio-group aria-labelledby="current-role" class="example-radio-group mt-6" [formControlName]="'role'">
                                    <mat-radio-button class="block radioTab" value="Sales/ Pre-Sales"><div class="radioTabLabel"> A) Sales/Pre-Sales</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Business Analyst"><div class="radioTabLabel"> B) Business Analyst</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Developer/Technical Consultant"><div class="radioTabLabel"> C) Developer/Technical Consultant</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Project Manager"><div class="radioTabLabel"> D) Project Manager</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Other"><div class="radioTabLabel"> E) Other</div></mat-radio-button>
                                </mat-radio-group>
                            </div>


                            
                            <div class="flex justify-center mt-6">
                                <button class="px-8 mr-2 min-w-30" mat-flat-button [color]="'accent'" type="button" matStepperPrevious>
                                    Back
                                </button>
                                <button
                                    class="px-8 "
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="horizontalStepperStep2.stepControl.invalid"
                                    type="button"
                                    matStepperNext>
                                    Continue
                                </button>
                            </div>
                        </mat-step>

                        <mat-step  [formGroupName]="'step3'" [stepControl]="horizontalStepperForm.get('step3')" #horizontalStepperStep3 >
                            <h5 class="text-4xl mt-6">Tell Us About Yourself</h5>
                            <p class="text-secondary mb-10">Help us personalize your experience with a few quick details.</p>
                            <div class="radioBox text-left font-medium mt-6">
                                <label class="block mb-4 text-lg" >What type of organization do you work for? </label>
                                <mat-radio-group aria-labelledby="current-role" class="example-radio-group" [formControlName]="'organizationType'">
                                    <mat-radio-button class="block radioTab" value="Salesforce Consulting Firm"><div class="radioTabLabel"> A) Salesforce Consulting Firm</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="In-house Salesforce Team"><div class="radioTabLabel"> B) In-house Salesforce Team</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Freelancer"><div class="radioTabLabel"> C) Freelancer</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Other"><div class="radioTabLabel"> D) Other</div></mat-radio-button>
                                </mat-radio-group>
                            </div>                           
                            <div class="flex justify-center mt-6">
                                <button class="px-8 mr-2 min-w-30" mat-flat-button [color]="'accent'" type="button" matStepperPrevious>
                                    Back
                                </button>
                                <button
                                    class="px-8 "
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="horizontalStepperStep3.stepControl.invalid"
                                    type="button"
                                    matStepperNext>
                                    Continue
                                </button>
                            </div>
                        </mat-step>

                        <mat-step  [formGroupName]="'step4'" [stepControl]="horizontalStepperForm.get('step4')" #horizontalStepperStep4 >
                            <h5 class="text-4xl mt-6">Tell Us About Yourself</h5>
                            <p class="text-secondary mb-10">Help us personalize your experience with a few quick details.</p>
                            <div class="radioBox text-left font-medium mt-6">
                                <label class="block mb-4 text-lg" >What is your level in the organization? </label>
                                <mat-radio-group aria-labelledby="current-role" class="example-radio-group" [formControlName]="'organizationalLevel'">
                                    <mat-radio-button class="block radioTab" value="Individual Contributor"><div class="radioTabLabel"> A) Individual Contributor</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Team Lead/Manage"><div class="radioTabLabel"> B) Team Lead/Manage</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Senior Manager/Director"><div class="radioTabLabel"> C) Senior Manager/Director</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="C-level/Founder"><div class="radioTabLabel"> D) C-level/Founder</div></mat-radio-button>
                                </mat-radio-group>
                            </div>                      
                            <div class="flex justify-center mt-6">
                                <button class="px-8 mr-2 min-w-30" mat-flat-button [color]="'accent'" type="button" matStepperPrevious>
                                    Back
                                </button>
                                <button
                                    class="px-8 "
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="horizontalStepperStep4.stepControl.invalid"
                                    type="button"
                                    matStepperNext>
                                    Continue
                                </button>
                            </div>
                        </mat-step>

                        <mat-step  [formGroupName]="'step5'" [stepControl]="horizontalStepperForm.get('step5')" #horizontalStepperStep5 >
                            <h5 class="text-4xl mt-6">Tell Us About Yourself</h5>
                            <p class="text-secondary mb-10">Help us personalize your experience with a few quick details.</p>
                            <div class="radioBox text-left font-medium mt-6">
                                <label class="block mb-4 text-lg label-width">How experienced are you with Salesforce implementation? </label>
                                <mat-radio-group aria-labelledby="current-role" class="example-radio-group" [formControlName]="'experienceLevel'">
                                    <mat-radio-button class="block radioTab" value="Beginner"><div class="radioTabLabel"> A) Beginner (Just starting out)</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Intermediate"><div class="radioTabLabel"> B) Intermediate (Some experience with projects) </div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Advanced"><div class="radioTabLabel">C) Advanced (Handled complex projects)</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="Expert"><div class="radioTabLabel"> D) Expert (Highly experienced in Salesforce ecosystems)</div></mat-radio-button>
                                </mat-radio-group>
                            </div>


                            
                            <div class="flex justify-center mt-6">
                                <button class="px-8 mr-2 min-w-30" mat-flat-button [color]="'accent'" type="button" matStepperPrevious>
                                    Back
                                </button>
                                <button
                                    class="px-8 "
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="horizontalStepperStep5.stepControl.invalid"
                                    type="button"
                                    matStepperNext>
                                    Continue
                                </button>
                            </div>
                        </mat-step>

                        <mat-step  [formGroupName]="'step6'" [stepControl]="horizontalStepperForm.get('step6')" #horizontalStepperStep6 >
                            <h5 class="text-4xl mt-6">Tell Us About Yourself</h5>
                            <p class="text-secondary mb-10">Help us personalize your experience with a few quick details.</p>
                            <div class="radioBox text-left font-medium mt-6">
                                <label class="block mb-4 text-lg label-width" >How many Salesforce experts does your organisation have? </label>
                                <mat-radio-group aria-labelledby="current-role" class="example-radio-group" [formControlName]="'teamSize'">
                                    <mat-radio-button class="block radioTab" value="Less than 50"><div class="radioTabLabel"> A) Less than 50</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="50 to 100"><div class="radioTabLabel"> B) 50 to 100 </div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="100 to 500"><div class="radioTabLabel">C) 100 to 500</div></mat-radio-button>
                                    <mat-radio-button class="block radioTab" value="More than 500"><div class="radioTabLabel">D) More than 500 </div></mat-radio-button>
                                </mat-radio-group>
                            </div>                   
                            <div class="flex justify-center mt-6">
                                <button class="px-8 mr-2 min-w-30" mat-flat-button [color]="'accent'" type="button" matStepperPrevious>
                                    Back
                                </button>
                                <button
                                    class="px-8 "
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="horizontalStepperStep6.stepControl.invalid"
                                    type="button"
                                    matStepperNext>
                                    Continue
                                </button>
                            </div>
                        </mat-step>

                        <mat-step  [formGroupName]="'step7'" [stepControl]="horizontalStepperForm.get('step7')" #horizontalStepperStep7 >
                            <h5 class="text-4xl mt-6">Tell Us About Yourself</h5>
                            <p class="text-secondary mb-10">Help us personalize your experience with a few quick details.</p>
                            <div class="radioBox text-left font-medium mt-12">
                                <mat-form-field class="w-full input-filed">
                                    <mat-label class="text-white">LinkedIn Profile (optional):</mat-label>
                                    <input id="email" matInput placeholder="Enter your Linkedin Profile" [formControlName]="'linkedin'" autocomplete="off" style="font-size: 12px;">
                                </mat-form-field>
                            </div>                     
                            <div class="flex justify-center mt-6">
                                <button class="px-8 mr-2 min-w-30" mat-flat-button [color]="'accent'" type="button" matStepperPrevious>
                                    Back
                                </button>
                                <button
                                    class="px-8 "
                                    mat-flat-button
                                    [color]="'primary'"
                                    type="button"
                                    matStepperNext>
                                    Continue
                                </button>
                            </div>
                        </mat-step>

                        <mat-step  [formGroupName]="'step8'" [stepControl]="horizontalStepperForm.get('step8')" #horizontalStepperStep8 >
                            <h5 class="text-4xl mt-6">You’re all set!</h5>
                            <p class="text-secondary mb-10">Start exploring the app and discover how it transforms your Salesforce implementations.</p>
                            <div class="cardBox allset flex flex-wrap justify-center gap-4 mt-6 mb-10">
                                <div class="card flex text-left flex-col gap-2 p-4 border">
                                    <img class="w-10" src="assets/icons/agents/explore.svg" alt="">
                                    <h4 class="text-lg font-medium">Explore Agents </h4>
                                    <p class="text-secondary text-md">Discover how our AI Agents streamline every stage of your implementation.</p>
                                </div>
                                <div class="card flex text-left flex-col gap-2 p-4 border">
                                    <img class="w-10" src="assets/icons/agents/jiranew.png" alt="">
                                    <h4 class="text-lg font-medium">Integrate with Jira </h4>
                                    <p class="text-secondary text-md">Sync requirements, test cases, and updates effortlessly.</p>
                                </div>
                                <div class="card flex text-left flex-col gap-2 p-4 border">
                                    <img class="w-10" src="assets/icons/agents/team.svg" alt="">
                                    <h4 class="text-lg font-medium">Tell Your Team</h4>
                                    <p class="text-secondary text-md">Invite your team members to collaborate and leverage the platform together.</p>
                                </div>
                            </div>              
                            <div class="flex justify-center mt-6">
                                <button
                                    class="px-8 min-w-60"
                                    mat-flat-button
                                    [color]="'primary'"
                                    [disabled]="(horizontalStepperStep8.stepControl.invalid || loader_save)"
                                    type="button"
                                    matStepperNext (click)="updateProfile()">
                                    <mat-progress-spinner *ngIf="loader_save" [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
                                    Open GetGenerative.ai
                                </button>
                            </div>
                        </mat-step>
                    </mat-horizontal-stepper>
                </form>
            </div>



        
        </div>


    </div>
</div>
