<app-add-feature #addFeature (onDeleteFeature)="onDeleteFeatureCallback($event)"  (onSaveFeature)="onSaveFeature($event)" [themes]="themeTypes" [epics]="_proposalDetail.epics" [releases_list]="releases_list"></app-add-feature>
<app-add-epic (onSaveEpic)="onSaveEpic($event)" [themeTypes]="themeTypes" #addEpic></app-add-epic>
<app-add-theme (onSaveTheme)="onSaveTheme($event)"  #addTheme></app-add-theme>
<app-regenerate-insights-view (onRegenerateEpic)="onRegenerateEpicInitiated($event)" (onRegenerateTheme)="onRegenerateThemeInitiated($event)"  #regenDrawer></app-regenerate-insights-view>

@if(isJiraError){
  <fuse-alert   type="error" [showIcon]="true" [appearance]="'soft'" [dismissible]="'true'"> {{jiraErrorMessage}}</fuse-alert>
}


<div class="flex actBt flex-wrap items-center gap-3 mt-8 mb-8 justify-between">
  <div class="subHeadingCont" *ngIf="_page_id != 'testing'">                                                
      <div class="text-black font-medium text-lg">Solution Workspace</div>
      <div class="text-[#94A3B8] text-md font-normal">AI-enhanced platform to finalize and manage project requirements and solutions.</div> 
  </div>
  <div class="subHeadingCont" *ngIf="_page_id === 'testing'">                                                
    <div class="text-black font-medium text-lg">Testing Workspace</div>
    <div class="text-[#94A3B8] text-md font-normal">AI-enhanced workspace to manage project requirements and test cases.</div> 
</div>
      <!-- Add product button -->
  <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing' || _page_id === 'build') && is_write_allowed)" class="add-get-ai-bt flex flex-wrap gap-3">


    @if(getIntegrationStatus()){

      <button [ngClass]="{ 'syncBt': getNotSyncedCount()>0, 'disableBtn': getNotSyncedCount()==0 }"  mat-flat-button [disabled]="getNotSyncedCount()==0" (click)="onSyncProjectWithJira()" >
            @if(isSyncingProject || (_proposalDetail.integrations.jira.status && _proposalDetail.integrations.jira.status==1)){
              <img
              [ngClass]="'rotate-animation'"
              src="assets/icons/sync_icon_white.svg"
              class="w-6 h-6"
              />
              <img src="assets/icons/jirawhite.svg"  alt="">
              <span class="">Syncing {{getSyncLabel()}}</span>
            }@else{
              <img [src]="getNotSyncedCount()==0?'assets/icons/jira_disabled.svg':'assets/icons/jirawhite.svg'"  alt="">
              <span class="" *ngIf="getNotSyncedCount()>0">Sync {{getNotSyncedCount()}} {{getSyncLabel()}}</span>
              <span class="" *ngIf="getNotSyncedCount()<=0">All Synced</span>
            }
    </button>
    }

      <button class="addThemeBt" mat-flat-button [color]="'primary'" (click)="onClickAddTheme()"  >
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="">Add Theme </span>
      </button>
      <button class="bg-[#2C3344] text-white addEpicBt" mat-flat-button  (click)="onClickAddEpic()"  >
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="">Add Epic </span>
    </button>
  </div>
</div>
<div class="bg-card rounded-lg shadow  py-3 mt-6">
  <!-- Header Section -->
  <section class="flex flex-row flex-wrap justify-between px-3">
      <div class="flex expandBox border" >
        <mat-icon svgIcon="heroicons_outline:bars-4" (click)="onToggleAccordion(0)" [ngClass]="{'active':expandState==0 }"></mat-icon>
        <mat-icon svgIcon="heroicons_outline:bars-3" (click)="onToggleAccordion(1)" [ngClass]="{'active':expandState==1 }"></mat-icon>
        <mat-icon svgIcon="heroicons_outline:bars-2" (click)="onToggleAccordion(2)" [ngClass]="{'active':expandState==2 }"></mat-icon>
          <!-- <mat-icon *ngIf="expandState==0 || expandState==1" class="expandIc" svgIcon="heroicons_outline:arrows-pointing-out"></mat-icon>
          <mat-icon *ngIf="expandState==2" class="collapseIc" svgIcon="heroicons_outline:arrows-pointing-in"></mat-icon> -->
      </div>
      <form class="flex flex-wrap gap-2 justify-center " [formGroup]="inputFormGroup">
      <mat-form-field class="fuse-mat-dense searchTab" *ngIf="(_page_id != 'testing')">
        <mat-icon
            class="icon-size-5 mr-1"
            matPrefix
            [svgIcon]="'heroicons_solid:magnifying-glass'"
            
            ></mat-icon>
        <input
            matInput [formControlName]="'search'"
            [autocomplete]="'off'"
            [placeholder]="'Search'" (input)="search($event)">
      </mat-form-field>
      <!-- Add filters -->
      <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm searchTab  mw-100 ">
          <mat-select
              [formControlName]="'theme'"
              [placeholder]="'Theme'" multiple (selectionChange)="onChangeTheme($event)">
              <!-- <mat-option #allSelected [value]="0">All</mat-option> -->
              @for (option of themeTypes; track option) {
                  <mat-option [value]="option.value">{{option.label}}</mat-option>
              }
          </mat-select>
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
          <!-- <mat-icon class="icon-size-5" matPrefix svgIcon="assets/icons/theme_filter_icon.svg"></mat-icon> -->
          <!-- <img src="assets/icons/theme_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
      </mat-form-field>
      <mat-form-field class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab  mw-100 ">
          <mat-select
              [formControlName]="'epic_name'"
              [placeholder]="'Epic'" multiple (selectionChange)="onChangeEpic($event)">
              @for (option of epicTypes; track option) {
                  <mat-option [value]="option.value">{{option.label}}</mat-option>
              }
          </mat-select>
          <!-- <img src="assets/icons/epic_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
      </mat-form-field>
      <mat-form-field *ngIf="(_page_id != 'testing')" class="flex-auto gt-xs:pl-3 selectoptionSm epicDrop searchTab  mw-100 ">
        <mat-select
            [formControlName]="'release'"
            [placeholder]="'Release'" multiple (selectionChange)="onChangeEpic($event)">
            @for (option of releases_list; track option) {
                <mat-option [value]="option._id">{{option.name}}</mat-option>
            }
        </mat-select>
        <!-- <img src="assets/icons/epic_filter_icon.svg" class="icon-size-5 pr-1" matPrefix> -->
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
      </mat-form-field>
  </form>

  </section>
  <!-- Requirements Section -->
  <ng-container>
      <div class="max-w-full flex flex-col" *ngIf="requirements.length">
            <mat-accordion  class="max-w-full mt-6" [multi]="true" [displayMode]="'flat'"  cdkDropList (cdkDropListDropped)="dropTheme($event)" [cdkDropListData]="requirements">
              <ng-container *ngFor="let requirement of requirements; let themeIndex=index; trackBy: trackByFn">
                  <mat-expansion-panel
                      class="border-none w-full parentTab"
                      [(expanded)]="themesExpanded[themeIndex]"
                      [cdkDrag]="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build' || _page_id === 'testing') && is_write_allowed)"
                      [cdkDragLockAxis]="'y'" >
                      <mat-expansion-panel-header class="border-t border-b group w-full select-none h-15 font-semibold ">
                          <mat-panel-title class="flex items-center w-full pl-5">
                              <div class="flex-auto mr-2 truncate text-lg">
                                  <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build' || _page_id === 'testing') && is_write_allowed)"
                                          class="md:hidden absolute ml-2 flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                          cdkDragHandle>
                                          <mat-icon
                                              class="icon-size-5 text-hint"
                                              [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                      </div>
                                      <span>{{themeIndex + 1}}. {{requirement.theme}}</span>
                                    </div>
                              <!-- quickActTab -->
                              <div class="mr-2 quickActTab" >
                                <mat-icon *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build' || _page_id === 'testing') && is_write_allowed)" class="smallIc mr-2" svgIcon="heroicons_outline:pencil-square" (click)="onEditTheme(requirement);$event.stopPropagation()"></mat-icon>
                                <mat-icon *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build' || _page_id === 'testing') && is_write_allowed)" class="smallIc" svgIcon="heroicons_outline:trash" (click)="onDeleteTheme(requirement);$event.stopPropagation()"></mat-icon>

                              </div>
                              <button (click)="onRegenerateTheme(requirement,themeIndex);$event.stopPropagation()" class="mr-2 tooltip" (mouseover)="requirement['isHovered'] = true"  (mouseout)="requirement['isHovered'] = false" ><img [src]="requirement['isHovered'] ? 'assets/icons/regenerator_icon.svg' : 'assets/icons/regen_disabled_icon.svg'"  class="w-5 h-5">
                              <span class="tooltiptext" style="width: 110px; left: 15px;">Process New Insights</span>
                            </button>
                              
                            <button class="tooltip" (click)="onSelectInsight(requirement.theme,null);$event.stopPropagation()" (mouseover)="requirement['isInsightHovered'] = true" 
                              (mouseout)="requirement['isInsightHovered'] = false" ><img [src]="requirement['isInsightHovered'] ? 'assets/icons/insights_enabled_icon.svg' : 'assets/icons/insights_disabled_icon.svg'"  class="w-5 h-5">
                              <span class="tooltiptext" style="width: 100px; left: 20px;">View New Insights</span>
                            </button>
                              
                              
                              <!-- <mat-icon class="smallIc" (click)="onSelectInsight(requirement.theme);$event.stopPropagation()" svgIcon="heroicons_outline:sparkles"></mat-icon> -->

                          </mat-panel-title>
                      </mat-expansion-panel-header>
          
                      <!-- Theme Requirements inside each accordion item -->
                      <div class="questions-container  max-w-full">
                        <div class="questions-container  max-w-full ">                
                          <mat-accordion [multi]="true" [cdkDropList]="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build' || _page_id === 'testing') && is_write_allowed)" (cdkDropListDropped)="dropEpic($event,themeIndex)" [cdkDropListData]="requirement.epics">
                            @if(!requirement.isRegenerating){
                            <ng-container *ngFor="let epic of requirement.epics;trackBy: trackByEpics;let epicIndex=index;">


                              <mat-expansion-panel  [(expanded)]="epicsExpanded[getEpicKey(themeIndex,epicIndex)]" (expandedChange)="toggleExpansion(themeIndex, epicIndex, $event)" class="childTab"  [cdkDrag]="(_page_id === 'proposal' && is_write_allowed)"
                                [cdkDragLockAxis]="'y'">
                                  

                                   
                                  <mat-expansion-panel-header class="pl-14 textTab border-b group">
                                    <mat-panel-title class="flex justify-between items-center">
                                      <div class="flex-auto mr-2 truncate text-lg">
                                        <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build' || _page_id === 'testing') && is_write_allowed)"
                                                class="md:hidden absolute ml-8 flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                                cdkDragHandle>
                                                <mat-icon
                                                    class="icon-size-5 text-hint"
                                                    [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                            </div>
                                        <span class="ml-2">{{(themeIndex+1)}}.{{(epicIndex+1)}}. {{epic.name}}</span>
                                    </div>
                                    
                                    <!-- quickActTab -->

                                      <div class="mr-2 quickActTab" >
                                        <mat-icon *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing' || _page_id === 'build') && is_write_allowed)" class="smallIc mr-2" svgIcon="heroicons_outline:pencil-square" (click)="onEditEpic(requirement.theme,epic.name);$event.stopPropagation()"></mat-icon>
                                        <mat-icon *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing' || _page_id === 'build') && is_write_allowed)" class="smallIc" svgIcon="heroicons_outline:trash" (click)="onDeleteEpic(requirement.theme,epic.name);$event.stopPropagation()"></mat-icon>
                                      </div>

                                    <button (click)="onRegenerateEpic(requirement,epic,themeIndex,epicIndex);$event.stopPropagation()" class="mr-2 tooltip" (mouseover)="epic['isHovered'] = true" 
                                      (mouseout)="epic['isHovered'] = false" ><img [src]="epic['isHovered'] ? 'assets/icons/regenerator_icon.svg' : 'assets/icons/regen_disabled_icon.svg'"  class="w-5 h-5">
                                      <span class="tooltiptext" style="width: 110px; left: 15px;">Process New Insights</span>
                                    </button>
                                      
                                    <button class="tooltip" (click)="onSelectInsight(requirement.theme,epic);$event.stopPropagation()" (mouseover)="epic['isInsightHovered'] = true" 
                                      (mouseout)="epic['isInsightHovered'] = false" ><img [src]="epic['isInsightHovered'] ? 'assets/icons/insights_enabled_icon.svg' : 'assets/icons/insights_disabled_icon.svg'"  class="w-5 h-5">
                                      <span class="tooltiptext" style="width: 100px; left: 20px;">View New Insights</span>
                                    </button>
                                      
                                    
                                    
                                    
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>

                                  @if(epic.isRegenerating){
                                    <ng-container>
                                      <div class="ml-16 mr-10 mt-10">
                                      <app-regenerating-progress-bar [(taskID)]="epic._id" [generationType]="'epic'" [restart]="isRegenerating" [type]="getRegeneratorViewType()"></app-regenerating-progress-bar>
                                      </div>
                                      <div class="shimmer-effect rounded-2xl shadow ml-16 mr-10 my-10 h-60 relative overflow-hidden"></div>
                                      <div class=" shimmer-effect rounded-2xl shadow ml-16 mr-10 my-10 h-60 relative overflow-hidden"></div>
                                      {{ onRegeneratingFunctionCall(epic.index) }}
                                    </ng-container>
                                  }@else{
                                  <ng-container>


                                    

                                    <section>
                                      <div class="bg-card m-bg-card rounded-2xl shadow ml-16 mr-10 my-10" *ngIf="(_page_id === 'proposal' || _page_id === 'project')">

                                        <mat-accordion class="example-headers-align" multi="true">
                                          <mat-expansion-panel class="innerExpansion" [(expanded)]="featuresExpanded[getEpicKey(themeIndex,epicIndex)]"  >
                                            <mat-expansion-panel-header>
                                              <mat-panel-title>Features </mat-panel-title>
                                             
                                            </mat-expansion-panel-header>
                                            <div class="bg-white featureBox">
                                              <section  *ngIf="requirement.requirementsVisible==undefined || requirement.requirementsVisible" class="innerFeature">
                                                <div
                                                [cdkDropList]="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)"
                                                (cdkDropListDropped)="dropRequirement($event,themeIndex,epicIndex)"
                                                class="w-full"
                                                [cdkDropListData]="epic.features">
                                                <ng-container *ngFor="let feature of epic.features; let i = index">
                                                  <div
                                                    [id]="'requirement-' + i"
                                                    class="relative group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0  customB"
                                                    [cdkDrag]="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)"
                                                  >
                                                    <!-- Insight content -->
                                                    <div class="relative flex items-center h-full py-2 tabBorder">
                                                      <div class="flex justify-between pl-12 gap-1 min-w-full items-center h-full cursor-pointer">
                                                        <div class="flex w-full sm:mr-2 mx-2 sm:text-md text-md text-black font-regular" (click)="onEditFeature(requirement.theme,epic.name,feature,i)" *ngIf="is_write_allowed"  >
                                                          {{feature.title}}
                                                          <div class="font-medium ml-5 text-sm">{{getSolutionCategoryForRequirement(feature._id)}}</div>
                                                        </div>
                                                        <div class="flex w-full sm:mr-2 mx-2 sm:text-md text-md text-black font-regular" *ngIf="!is_write_allowed"  >
                                                          {{feature.title}}
                                                          <div class="font-medium ml-5 text-sm">{{getSolutionCategoryForRequirement(feature._id)}}</div>
                                                        </div>
                                              
                                                        <!-- Drag handle, visible only on hover -->
                                                        <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="absolute left-0 pl-6 h-full flex items-center justify-center opacity-0 group-hover:opacity-100">
                                                          <mat-icon class="cursor-move" [svgIcon]="'heroicons_solid:bars-3'" cdkDragHandle></mat-icon>
                                                        </div>
                                                        
      
                                                        
      
                                                        <!-- Edit and Delete Buttons -->
                                                        <div class="flex justify-end mr-8 gap-2 group">
      
                                                          <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" class="flex group pr-2">
                                                            <mat-select [value]="feature.release" class="selectOption" (selectionChange)="onRequirementReleaseSelection($event,requirement.theme,epic.name,feature,i)">
                                                              @for (release of releases_list; track release) {
                                                                <mat-option [value]="release._id">{{release.short_name}}</mat-option>
                                                              }
                                                            </mat-select>
                                                          </div>
      
                                                          <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'testing') && is_write_allowed)" class="gap-2 opacity-0 group-hover:opacity-100 flex gap-2" >
                                                            <mat-icon class="smallIc mr-1" (click)="onEditFeature(requirement.theme,epic.name,feature,i)"  svgIcon="heroicons_outline:pencil-square"></mat-icon>
                                                            <mat-icon class="smallIc"  (click)="onDeleteFeature(requirement.theme,epic.name,feature,i)" svgIcon="heroicons_outline:trash"></mat-icon>
                                                          </div>
      
                                                          <!-- <button class="leading-none -mr-2 opacity-0 group-hover:opacity-100" mat-icon-button (click)="onEditFeature(requirement.theme,epic.name,feature,i)">
                                                            <img class="max-w-4 max-h-4" src="assets/icons/edit_req_icon.svg" />
                                                          </button> -->
                                                          <!-- <button class="leading-none opacity-0 group-hover:opacity-100" mat-icon-button (click)="onDeleteFeature(requirement.theme,epic.name,feature,i)">
                                                            <img class="max-w-4 max-h-4" src="assets/icons/trash_req_icon.svg" />
                                                          </button> -->
                                                        </div>
      
                                                        
      
                                                      </div>
                                              
                                                    </div>
                                                  </div>
                                                </ng-container>
                                              </div>
                                              <section *ngIf="!epic.features.length">
                                                  <p class="noSolution">AI couldn’t find a requirement. Time for some human critical thinking! 
                                                    Feel free to add your requirement.</p>
                                              </section>
                                              <div *ngIf="((_page_id === 'proposal' || _page_id === 'project') && is_write_allowed)" class="min-w-full flex pl-10 py-5 items-center justify-start border-t  relative cursor-pointer" (click)="onClickAddFeature(requirement.theme,epic.name)">
                                                <mat-icon class="text-[#9AA6B8]" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                                <div  class="pl-2 text-md text-[#9AA6B8]">Add Requirement</div>
                                              </div>
                                            </section>
                                            </div>
                                          </mat-expansion-panel>
                                        </mat-accordion>
                                      </div>
                                      <div class="bg-card m-bg-card rounded-2xl shadow ml-16 mr-10 my-10" *ngIf="(_page_id === 'project' || _page_id === 'testing' || _page_id === 'build')">
                                        <mat-accordion class="example-headers-align" multi="true">
                                          <mat-expansion-panel class="innerExpansion" [(expanded)]="storiesExpanded[getEpicKey(themeIndex,epicIndex)]"   >
                                            <mat-expansion-panel-header>
                                              <mat-panel-title>User Stories </mat-panel-title>
                                            
                                            </mat-expansion-panel-header>

                                            <div class="bg-white">
                                               
                                              <app-story-table [buildID]="_buildDetail!=null?_buildDetail._id:''"  [(proposal)]="_proposalDetail" [themeTypes]="themeTypes" [epicTypes]="epicTypes" [(stories)]="epic.stories" (onUpdateStories)="onUpdateStories($event)" [themeID]="requirement._id" [epicID]="epic._id" [_page_id]="_page_id" [is_write_allowed]="is_write_allowed" [releases_list]="releases_list" (saveStoryEvent)="onSaveStoryReceived($event)" [(epicName)]="epic.name" [(metadataMap)]="metadataMap"
                                              [(themeName)]="requirement.theme" [(technologyDomains)]="technologyDomains"[(allMetadataType)]="allMetadataType"
                                              [(allMetadataCategory)]="allMetadataCategory"></app-story-table>
                                              
                                            </div>
                                          </mat-expansion-panel>
                                        </mat-accordion>
                                      </div>
                                      <div class="bg-card m-bg-card rounded-2xl shadow ml-16 mr-10 my-10"  *ngIf="(_page_id === 'testing')">

                                        <mat-accordion class="example-headers-align" multi="true">
                                          <mat-expansion-panel class="innerExpansion" [(expanded)]="casesExpanded[getEpicKey(themeIndex,epicIndex)]"  >
                                            <mat-expansion-panel-header>
                                              <mat-panel-title>Test Cases </mat-panel-title>                                             
                                            </mat-expansion-panel-header>
                                            <div class="bg-white">
                                              <app-test-case-table [myIndex]="epic.index"   [(proposal)]="_proposalDetail" [(testplan)]="_testplanDetail" [themeTypes]="themeTypes" [filterApplied]="filterApplied" [epicTypes]="epicTypes" [stories]="epic.stories" (onUpdateStories)="onUpdateStories($event)" [_page_id]="_page_id" [is_write_allowed]="is_write_allowed" [releases_list]="releases_list" [all_categories]="all_categories" [(epicName)]="epic.name" [(themeName)]="requirement.theme"></app-test-case-table>
                                            </div>
                                            
                                          </mat-expansion-panel>

                                        </mat-accordion>                                        
                                      
                                      </div>

                                      <!-- High Level Solutions -->
                                      <div class="bg-card m-bg-card rounded-2xl shadow ml-16 mr-10 my-10"  *ngIf="(_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build')">

                                        <mat-accordion class="example-headers-align" multi="true">
                                          <mat-expansion-panel class="innerExpansion" [(expanded)]="hlsolutionExpanded[getEpicKey(themeIndex,epicIndex)]"   >
                                            <mat-expansion-panel-header>
                                              <mat-panel-title>High Level Solution </mat-panel-title>                                             
                                            </mat-expansion-panel-header>
                                            <div class="bg-white featureBox">
                                              <section class="innerFeature">

                                                <div >
                                                      <div class="grid grid-cols-5 min-w-full px-5 gap-3 py-5 justify-center tabBorder">
                                                          <div class="flex justify-start col-span-1 text-md font-medium uppercase  ml-12">Solution Module</div>
                                                          <div class="flex justify-start col-span-1 text-md font-medium uppercase ">Sub Module</div>
                                                          <div class="flex justify-start col-span-1 text-md font-medium uppercase ">Technology</div>
                                                          <div class="flex justify-start col-span-1 text-md font-medium uppercase ">Complexity</div>
                                                          <div class="flex pl-4 justify-start col-span-1 text-md font-medium uppercase ">Effort</div>
                                                        </div>
                                                        
                                                        <section *ngIf="epic.solutions.length">
                                                          <div
                                                          [cdkDropList]="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build') && is_write_allowed)"
                                                            (cdkDropListDropped)="dropSolution($event,themeIndex,epicIndex)"
                                                            class="w-full"
                                                            [cdkDropListData]="epic.solutions"
                                                          >
                                                          <ng-container *ngFor="let solution of epic.solutions; let i = index">
                                                            <div [id]="'requirement-' + i" class="relative group w-full select-none hover:bg-gray-100 dark:hover:bg-hover sm:mt-0  customB" [cdkDrag]="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build') && is_write_allowed)">
                                                              <!-- Content for each section -->
                                                              <div class="grid grid-cols-5 gap-3 items-center py-3 px-5 tabBorder cursor-pointer">
                                                                <div *ngIf="((_page_id === 'proposal' || _page_id === 'project'  || _page_id === 'build') && is_write_allowed)" class="absolute left-0 pl-6 h-full flex items-center justify-center opacity-0 group-hover:opacity-100">
                                                                  <mat-icon class="cursor-move" [svgIcon]="'heroicons_solid:bars-3'" cdkDragHandle></mat-icon>
                                                                </div>
                                                                <div class="flex flex-wrap justify-start col-span-1 text-md text-black font-regular  ml-12"  (click)="is_write_allowed && toggleDetails(solution,requirement.theme,epic.name)">
                                                                  {{ solution.epicName }}
                                                                </div>
                                                                <div class="flex flex-wrap justify-start col-span-1 text-md text-black font-regular" (click)="is_write_allowed && toggleDetails(solution,requirement.theme,epic.name)">
                                                                  {{ solution.name }}
                                                                </div>
                                                                <div class="flex flex-wrap justify-start col-span-1 text-md text-black font-regular" (click)="is_write_allowed && toggleDetails(solution,requirement.theme,epic.name)">
                                                                  {{ solution.cloud || 'Salesforce' }}
                                                                </div>
                                                                <div class="flex flex-wrap justify-start col-span-1 text-md text-black font-regular" (click)="is_write_allowed && toggleDetails(solution,requirement.theme,epic.name)">
                                                                  {{ solution.complexity }}
                                                                </div>
                                                                <div class="flex justify-between pl-4 items-center col-span-1 text-md text-black font-regular ">
                                                                  <span (click)="is_write_allowed && toggleDetails(solution,requirement.theme,epic.name)" class="break-all" style="min-width: 45px;">{{ solution.effort }}</span>
          
                                                                  <div  class="flex justify-between pl-4 gap-1 group" style="min-width: 120px;">
                                                                    
                                                                    <div *ngIf="(_page_id === 'template' || !is_write_allowed)" class="flex justify-center col-span-1 group selectOption" style="width: 60px;">
                                                                      {{solution.release_shortname}}
                                                                    </div>
          
                                                                    <mat-select [value]="solution.release" *ngIf="((_page_id === 'project' || _page_id === 'proposal' || _page_id === 'build') && is_write_allowed)" class="selectOption" (selectionChange)="onSolutionReleaseSelection($event,solution,i)">
                                                                      @for (release of releases_list; track release) {
                                                                        <mat-option [value]="release._id">{{release.short_name}}</mat-option>
                                                                      }
                                                                    </mat-select>
          
                                                                    
                                                                    <div class="flex justify-end group" *ngIf="((_page_id === 'project' || _page_id === 'proposal' || _page_id === 'build') && is_write_allowed)">
                                                                      <div class="flex gap-1 opacity-0 group-hover:opacity-100">
                                                                        <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:pencil-square" (click)="toggleDetails(solution,requirement.theme,epic.name)"></mat-icon>
                                                                        <mat-icon class="smallIc cursor-pointer" svgIcon="heroicons_outline:trash" (click)="onClickDeleteSolution(solution._id,requirement.theme,epic.name)"></mat-icon>
                                                                      </div>
                                                                    </div>
          
                                                                    
          
                                                                  </div>
          
                                                                </div>
          
          
          
          
                                                              </div>
                                                              </div>
                                                              <!-- Expanded details section -->
                                                              
                                                          </ng-container>
                                                          
                                                          </div>
                                                        
                                                        </section>
                                                        
                                                        <section *ngIf="!epic.solutions.length">
                                                            <p class="noSolution">AI couldn’t find a solution for your requirement. Time for some human critical thinking! 
                                                              Feel free to add your solution from our master list.</p>
                                                        </section>
          
                                                        
                                                          <!-- Add Feature Button -->
                                                          <div *ngIf="((_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build') && is_write_allowed)" class="min-w-full flex pl-10 py-5 items-center justify-start relative border-t cursor-pointer" (click)="onClickAddSolution(requirement.theme,epic.name)" >
                                                            <mat-icon class="text-[#9AA6B8]" [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
                                                            <div class="pl-2 text-md text-[#9AA6B8]">Add Solution</div>
                                                            
                                                          </div>
                                                  </div>
                                                 
                                              </section>
                                              <div class="pb-4 pt-4 w-full" style="box-shadow: inset 0px 2px 2px #ccc;" *ngIf="is_write_allowed && (_page_id === 'proposal' || _page_id === 'project' || _page_id === 'build') && epic.features?.length">
                                                <button [ngClass]="{'opacity-50 cursor-not-allowed': epic.isRegenerating}"   [disabled]="epic.isRegenerating" class="bg-white ml-10 flex items-center justify-center gap-1 regen-btn text-black px-2 py-1 text-md rounded-md" (click)="onRegenerateSolutions(_proposalDetail._id,requirement._id,epic._id,epic)">
                                                <img [ngClass]="{ 'spin': epic.isRegenerating }" class="w-4 h-4" src="./assets/icons/regen_icon.svg">
                                                @if(!epic.isRegenerating){
                                                <span>Regenerate Solutions</span>
                                                }@else {
                                                  <span>Regenerating Solutions</span>
                                                }
                                                </button>
                                              </div>
        
                                            </div>
                                          </mat-expansion-panel>

                                        </mat-accordion>

                                        
                                      </div>
                                      
                                      @if(epic.solutions.length){
                                      <!-- Low Level Solutions -->
                                      <div class="bg-card m-bg-card rounded-2xl shadow ml-16 mr-10 my-10" *ngIf="(_page_id === 'project' || _page_id === 'build')">

                                        <mat-accordion class="example-headers-align" multi="true" [id]="'lls'+epic._id">
                                          <mat-expansion-panel class="innerExpansion" [(expanded)]="llsolutionExpanded[getEpicKey(themeIndex,epicIndex)]"  >
                                            <mat-expansion-panel-header>
                                              <mat-panel-title> Low Level Solutions </mat-panel-title>                                             
                                            </mat-expansion-panel-header>
                                            <div class="bg-white">
                                              <section *ngIf="(_page_id === 'project' || _page_id === 'build')">
                                                @if(epic.isGeneratingLLS){
                                                  <div class="p-4">
                                                    <app-regenerating-progress-bar [(taskID)]="epic._id" [generationType]="'lls'" [taskID]="epic._id" [restart]="epic.isGeneratingLLS" [type]="getRegeneratorViewType()"></app-regenerating-progress-bar>
                                                  </div>
                                                }
          
                                                <div class="flex justify-center m-bg-card  storyTableBox">
          
                                                  @if (!epic.isGeneratingLLS) {
          
                                                    @if(epic.lowLevelSolutionSummary && epic.lowLevelSolutionSummary.summary){
          
          
                                                      <section class="my-5 px-5 flex flex-col items-start justify-start w-full">
                                                            <div class="content relative w-full flex flex-col">
                                                                <div class="my-3 px-5 text-black" [innerHTML]="epic.llsSummaryCombined"></div>
                                                            </div>
                                                      </section>
          
          
          
          
                                                    }@else{
                                                  <section class="flex flex-col items-center justify-center w-full">
                                                    <img class="mt-6 w-5 h-5" src="./assets/icons/think_icon.svg">
                                                    <div class="text-md mt-2 font-medium text-center text-black">No Low Level Solution Available </div>
                                                  <div class="mt-2 w-1/2 text-center text-[#94A3B8] text-md">Review your requirements, user stories and high level solutions before generating the low level solution design.</div>
                                                
                                                  <button (click)="onClickGenerateLLS(requirement._id,epic)" class="mt-5 mb-15 px-3 py-2 bg-black text-white rounded-full flex gap-1"><img class="w-6 h-6" src="./assets/icons/magic_icon.svg"> Generate Solution Design</button>
                                                </section>
                                              }
                                                }@else {
                                                  <div class="w-full flex flex-col p-4 ">
          
                                                  <div class="shimmer-effect rounded-2xl shadow h-60 relative overflow-hidden"></div>
          
                                                  </div>
                                                }
                                            
                                                </div>
          
          
                                                @if(!epic.isGeneratingLLS && (epic.lowLevelSolutionSummary && epic.lowLevelSolutionSummary.summary && epic.lowLevelSolutionSummary.summary!="")){
          
                                                <div class="flex gap-2 pl-10 py-4 w-full"  style="box-shadow: inset 0px 2px 2px #ccc;">
          
                                                  <div *ngIf="is_write_allowed">
                                                    <button   class="bg-[#1E293B] flex items-center justify-center gap-1 regen-btn text-black px-2 py-1 text-md rounded-md" (click)="onEditLowLevelSolutionSummary(requirement._id,epic._id,epic.lowLevelSolutionSummary.summary,epic.lowLevelSolutionSummary.solutionCategories,epic.lowLevelSolutionSummary.assumptions,epic)" >
                                                      <img  class="w-4 h-4" src="./assets/icons/magic_edit_icon.svg">
                                                   
                                                      <span class="text-white pr-2">Edit </span>
                                                    </button>
                                                  </div>
                                                  <!-- @if(epic.stories && isRefreshSummaryEnabled(epic.stories)){ -->
                                                  <div *ngIf="is_write_allowed">
                                                    <button [ngClass]="{'opacity-50 cursor-not-allowed': !epic.features.length}" [disabled]="!isRefreshSummaryEnabled(epic.stories)" class="bg-white flex items-center justify-center gap-1 regen-btn text-black px-2 py-1 text-md rounded-md" (click)="onClickRefreshSummary(requirement._id,epic)">
                                                    <img [ngClass]="{ 'spin': epic.isGeneratingLLS  }" class="w-4 h-4" src="./assets/icons/regen_icon.svg">
                                                   
                                                    <span [ngClass]="{'text-gray-400': !isRefreshSummaryEnabled(epic.stories)}">Refresh Summary</span>
                                                    </button>
                                                  </div>
                                                <!-- } -->
          
                                                 
          
                                                </div>
                                              }
                                               
          
                                                </section>
                                            </div>

                                          </mat-expansion-panel>
                                        </mat-accordion>
                                      </div>
                                    }

                                      @if(!epic.isGeneratingLLS && epic.lowLevelSolutionSummary?.summary){
                                      <div class="bg-card m-bg-card rounded-2xl shadow ml-16 mr-10 my-10 "  *ngIf="(_page_id === 'build' && delayedRender)">

                                        <mat-accordion class="example-headers-align" multi="true">
                                          <mat-expansion-panel class="innerExpansion" [(expanded)]="configExpanded[getEpicKey(themeIndex,epicIndex)]"  >
                                            <mat-expansion-panel-header>
                                              <mat-panel-title> Configuration Workbook</mat-panel-title>                                             
                                            </mat-expansion-panel-header>
                                            <ng-container *ngIf="(!epic.isRegeneratingWorkbook && !_buildDetail.isGeneratingConfiguration); else regeneratingTemplate">
                                              <div class="bg-white">
                                                <app-configuration 
                                                  [myIndex]="epic.index"
                                                  [(proposal)]="_proposalDetail"
                                                  [(build)]="_buildDetail" [(configurations)]="epic.configurations"
                                                  [_page_id]="_page_id"
                                                  [is_write_allowed]="is_write_allowed"
                                                  [(allMetadataType)]="allMetadataType"
                                                  [(allMetadataCategory)]="allMetadataCategory"
                                                  [(technologyDomains)]="technologyDomains"
                                                  [(themeTypes)]="themeTypes"
                                                  [(metadataMap)]="metadataMap"
                                                  [(epicTypes)]="allEpics"
                                                  view_component="workspace"
                                                  [(epicName)]="epic.name"
                                                  [(themeName)]="requirement.theme"
                                                  [(themeID)]="requirement._id"
                                                  [(epicID)]="epic._id" (saveBuildEvent)="onSaveBuildReceived($event)"  [refreshTrigger]="epic.refreshTrigger"
                                                  [(lowLevelSolutionSummary)]="epic.lowLevelSolutionSummary">
                                                </app-configuration>
                                            
                                                <div *ngIf="epic.lowLevelSolutionSummary?.summary" class="w-full pl-10 py-4" style="box-shadow: inset 0px 2px 2px #ccc;">
                                                  <div *ngIf="is_write_allowed">
                                                    <button 
                                                      [ngClass]="{'opacity-50 cursor-not-allowed': !epic.features?.length}"  
                                                      [disabled]="epic.isRegeneratingWorkbook" 
                                                      class="bg-white flex items-center justify-center gap-1 regen-btn text-black px-2 py-1 text-md rounded-md" 
                                                      (click)="onClickRegenerateWorkbook(requirement._id, epic)">
                                                      <img [ngClass]="{ 'spin': epic.isRegeneratingWorkbook }" class="w-4 h-4" src="./assets/icons/regen_icon.svg">
                                                      <span>Regenerate Workbook</span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </ng-container>
                                            
                                            <!-- ELSE Block -->
                                            <ng-template #regeneratingTemplate>
                                              <div class="bg-white p-4">
                                                <div class=" mt-4">
                                                  <app-regenerating-progress-bar [(taskID)]="epic._id" [generationType]="'workbook'" [restart]="isRegenerating" [type]="getRegeneratorViewType()"></app-regenerating-progress-bar>
                                                </div>
                                                <div class="shimmer-effect rounded-2xl shadow  mt-4 h-60 relative overflow-hidden"></div>
                                              </div>
                                              
                                              <!-- Call function when regenerating -->
                                              {{ onRegeneratingFunctionCall(epic.index) }}
                                            </ng-template>
                                            
                                          </mat-expansion-panel>

                                        </mat-accordion>                                        
                                      

                                       

                                      </div>
                                    }

                                    </section>
                                    
                                  </ng-container>
                                }
                                   
                                </mat-expansion-panel>
                             
                              </ng-container>
                            }@else {


                              <ng-container>
                                
                                <div class="ml-16 mr-10 mt-10" *ngIf="epic && epic._id">
                                  <app-regenerating-progress-bar [generationType]="'theme'" [taskID]="epic._id" [restart]="isRegenerating" [type]="getRegeneratorViewType()"></app-regenerating-progress-bar>
                                </div>
                              <div class="shimmer-effect rounded-2xl shadow ml-16 mr-10 my-10 h-60 relative overflow-hidden"></div>



                              <div class=" shimmer-effect rounded-2xl shadow ml-16 mr-10 my-10 h-60 relative overflow-hidden"></div>


                              </ng-container>








                            }
                          </mat-accordion>          
                        </div>
                      </div>
                  </mat-expansion-panel>
              </ng-container>
          </mat-accordion>
      </div>

      <section *ngIf="!requirements.length">
        <div class="flex flex-col gap-4 items-center justify-center notfound p-16 w-full">
            <mat-icon svgIcon="heroicons_outline:exclamation-circle" class="text-[#000000]"></mat-icon>
            <h4 class="text-lg font-medium">No Data Found </h4>
        </div>
      </section>
  </ng-container>
</div>

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'rewriteAIDrawer'"
    [position]="'right'" 
    (openedChanged)="openedChanged($event)"
    #insightDrawer>
    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex items-center justify-between mt-5 ml-3 mr-3">
            <ng-container>
                <div class="flex flex-col">
                    <!-- <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon> -->
                    <span class="ml-2 font-normal text-sm text-gray-400 d-block">Insights</span>
                    <span *ngIf="selectedInsight" class="ml-2 font-semibold">{{selectedInsight.capability}}</span>
                </div>
            </ng-container>

            <div class="flex items-center">
                <!-- Close button -->
                <button mat-icon-button (click)="onCloseDrawer()" >
                  <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
       
        <form [formGroup]="insightquestionForm" class="flex flex-col flex-auto">
          <div formArrayName="questions" class="shareLink row m-0">
            <div *ngIf="questionsArray?.controls?.length > 0">
              <div *ngFor="let eachQuestion of questionsArray.controls; let i = index" [formGroupName]="i" class="p-6 border-b">
                <mat-form-field class="w-full">
                  <mat-label class="font-semibold">Question {{ i + 1 }}</mat-label>
                  <textarea readonly matInput formControlName="title"></textarea>
                </mat-form-field>
                <mat-form-field class="w-full mt-4">
                  <mat-label class="font-semibold">Answer {{ i + 1 }}</mat-label>
                  <textarea readonly matInput formControlName="notes"></textarea>
                </mat-form-field>
                
              </div>
            </div>
            <div *ngIf="!questionsArray?.controls?.length">
              There are no questions and answers in this theme. Please add from insights to view them.
            </div>
          </div>
        </form>
        
        
    </div>
</fuse-drawer>


<fuse-drawer 
class="w-screen min-w-screen sm:w-100 sm:min-w-100  releaseD z-999 editDrawer"
fixed
[mode]="'over'"
[name]="'lowlevelDrawer'"
[position]="'right'"
(openedChanged)="openedChangedLLS($event)"
#lowlevelDrawer>
  @if(selectedLowLevelSolution && isLLSDrawerOpen){
    <div class="flex flex-col w-full overflow-auto bg-card">
      <div class="flex justify-between items-center mb-4 pl-5 pt-4 " >
          <div class="heading">
              <h5 class="font-medium text-black text-lg break-all flex items-center gap-2"> 
                  <mat-icon svgIcon="heroicons_outline:pencil-square" class="icon-size-5 text-black"></mat-icon> 
                  Edit Low Level Solution 
              </h5>
          </div>
          <button mat-icon-button (click)="onCloseDrawer()" >
              <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
          </button>
      </div>

      <div class="p-4">
        <h3 class="font-medium mb-4 text-lg">Low Level Solution </h3>
        <div class="content mb-20">
            <!-- <textarea [(ngModel)]="selectedLowLevelSolution.epic.llsSummaryCombined" class="w-full border rounded p-2 overflow-auto resize-y min-h-[100px]" name="summaryEditor"></textarea> -->
            
            <div class="textbox rounded-md lowleveleditor">
              <tiptap-bubble-menu [editor]="llsSummaryCombinedEditor" [id]="'description-llsSummary'">
                  <app-editor-controls 
                  [editor]="llsSummaryCombinedEditor" 
                  [selectedText]="selectedText"
                  (completeQuickAction)="onCompleteQuickAction($event)">
                  </app-editor-controls>
              </tiptap-bubble-menu>
              
              <tiptap-editor 
              [id]="'description-llsSummary'"
              [editor]="llsSummaryCombinedEditor" 
              [(ngModel)]="selectedLowLevelSolution.selectedEpic.llsSummaryCombined"
                name="description-llsSummary"
              (blur)="onTextareaBlur()"
              (mouseenter)="onMouseEnter()"
              (mouseleave)="onMouseLeave()"
              (mousedown)="onMouseDown($event)"
              (mouseup)="onTextSelect($event, 'description-llsSummary')"
              (select)="onSelectionChange($event, 'description-llsSummary')"
              (keydown)="onSelectionChange($event, 'description-llsSummary')" >
              </tiptap-editor>
            </div>

        </div>

        <div class="flex items-center actionBt"  [ngClass]="{'justify-between' :(flashMessageType) , 'justify-end' :(!flashMessageType)  }" >
          <fuse-alert class="text-md" *ngIf="flashMessageType === 'success' && flashMessageType" type="success" [showIcon]="true" [appearance]="'soft'" [dismissible]="true" [dismissed]="false"> Updated successfully!</fuse-alert>
  
          <fuse-alert class="text-md" *ngIf="flashMessageType === 'error' && flashMessageType" type="warn" [showIcon]="true" [appearance]="'soft'" [dismissible]="true" [dismissed]="false"> An error occurred, try again!</fuse-alert>
          <div class="flex items-center gap-3">
                <button  (click)="onSaveLowLevelSolutionSummary()" [disabled]="isSavingLLSSummary"  mat-flat-button [color]="'primary'"  >
                  <div class="flex gap-1 items-center">
                    <mat-progress-spinner *ngIf="isSavingLLSSummary" mode="indeterminate"></mat-progress-spinner> <span>Save</span>
                  </div>
                </button>
          </div>
      </div>


        

      </div>

    </div>
  }
</fuse-drawer>



<fuse-drawer class="w-screen min-w-screen sm:w-100 sm:min-w-100  releaseD z-999 editDrawer" fixed [mode]="'over'" [name]="'solutionDrawer'" [position]="'right'" #solutionDrawer
(openedChanged)="openedChangedSolution($event)">

  <div class="flex flex-col w-full overflow-auto bg-card">
    <div class="flex justify-between mb-4 pl-5 items-center " >
        <div class="heading">
            <h5 class="font-medium text-lg break-all flex items-center gap-2 text-black"> 
              <mat-icon svgIcon="heroicons_outline:pencil-square" class="icon-size-5 text-black"></mat-icon> <span class="mt-1">Edit Solution</span></h5>
        </div>
        <button mat-icon-button (click)="onCloseDrawer()" >
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <app-solution-drawer *ngIf="isSolutionDrawerOpen && selectedSolution" [(solutionValue)]="selectedSolution" [_page_id]="_page_id" [is_write_allowed]="is_write_allowed" [(proposal)]="_proposalDetail" [themeTypes]="themeTypes" [epicTypes]="epicTypes" [releases_list]="releases_list" [(complexities)]="complexities" (solutionValueChange)="onSolutionUpdated($event)"></app-solution-drawer>
  </div>
</fuse-drawer>