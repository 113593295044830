

    <div class="flex flex-col flex-auto min-w-0 relative" (mousedown)="onClickOutside($event)">
       
       
    <!-- <app-quick-prompt (mouseenter)="onMouseEnterQuickPrompt()" (mouseleave)="onMouseLeaveQuickPrompt()" *ngIf="showQuickPromptMenu" (onCompleteQuickAction)="onCompleteQuickAction($event)" [quickActionSelection]="quickSelectedText" [menuPosition]="menuPosition" [boxWidth]="boxWidth"></app-quick-prompt> -->
       
        
        <span class="close-dialog "  [mat-dialog-close]="true">
            <mat-icon svgIcon="heroicons_solid:x-mark"></mat-icon>
        </span>
        <!-- Main -->
        <div class="flex-auto">
            <div class="formBox">
                <div class="flex font-bold justify-start gap-2 text-4xl">
                    <h2>Add New Solution</h2>
                </div>
                <form [formGroup]="tabGroupForm" #tabGroupUpNgForm="ngForm"
                class="flex flex-col w-full">
    
                    <div class="flex flex-col gap-6">
                        <div class="flex flex-auto flex-wrap">
                            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full">
                                        <mat-label>Solution Sub-Module Name</mat-label>
                                        <input
                                            matInput
                                            [formControlName]="'featureName'">
                                        <mat-error *ngIf="tabGroupForm.get('featureName').hasError('required')">
                                            Solution Sub-Module Name is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full">
                                        <mat-label>Complexity</mat-label>
                                        <mat-select formControlName="complexity"[placeholder]="'Select Option'">
                                            @for (option of complexities; track option) {
                                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                            }
                                        </mat-select>
                                        <mat-error *ngIf="tabGroupForm.get('complexity').hasError('required')">
                                            Complexity is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full">
                                        <mat-label>Solution Module</mat-label>
                                        <input
                                            matInput
                                            [formControlName]="'setupType'">
                                        <mat-error *ngIf="tabGroupForm.get('setupType').hasError('required')">
                                            Solution Module is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2" *ngIf="themeListTypes.length">
                                    <mat-form-field class="w-full">
                                        <mat-label>Theme</mat-label>
                                        <mat-select [formControlName]="'theme'" [placeholder]="'Select Option'" required (selectionChange)="featureThemeChange($event,inputFormGroup)">
                                        @for (option of themeListTypes; track option) {
                                            <mat-option [value]="option.value">{{option.label}}</mat-option>
                                        }
                                        </mat-select>
                                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
                                        <!-- <input matInput *ngIf="(data['selected_theme'])" [formControlName]="'theme'" [readonly]="(data['selected_theme'])"> -->
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2" *ngIf="themeListTypes.length">
                                    <mat-form-field class="w-full">
                                        <mat-label>Epic</mat-label>
                                        <mat-select [formControlName]="'epic'"[placeholder]="'Select Option'" required (selectionChange)="featureThemeChange($event,inputFormGroup)">
                                        @for (option of themeEpicTypes; track option) {
                                            <mat-option [value]="option.value">{{option.label}}</mat-option>
                                        }
                                        </mat-select>
                                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:rectangle-group'"></mat-icon>
                                        <!-- <input matInput *ngIf="(data['selected_epic'])" [formControlName]="'epic'" [readonly]="(data['selected_epic'])"> -->
                                    </mat-form-field>
                                </div>
    
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full">
                                        <mat-label>Technology Domain</mat-label>
                                        <mat-select [formControlName]="'product'"
                                        [placeholder]="'Select Domain'">
                                            @for (option of cloudTypes; track option) {
                                                <mat-option [value]="option.value">{{option.label}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full">
                                        <mat-label>Components Count</mat-label>
                                        <input type="number"
                                            matInput
                                            [formControlName]="'noOfStepToConfigureFeatures'">
                                        <mat-error *ngIf="tabGroupForm.get('noOfStepToConfigureFeatures').hasError('required')">
                                            Components Count is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <mat-form-field class="w-full">
                                        <mat-label>Effort</mat-label>
                                        <input type="number"
                                            matInput
                                            [formControlName]="'effort'">
                                        <mat-error *ngIf="tabGroupForm.get('effort').hasError('required')">
                                            Effort is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="sm:col-span-2" *ngIf="releases_list.length">
                                    <mat-form-field class="w-full">
                                        <mat-label>Release</mat-label>
                                        <mat-select [formControlName]="'release'" [placeholder]="'Select Option'" required>
                                        @for (option of releases_list; track option) {
                                            <mat-option [value]="option._id">{{option.name}}</mat-option>
                                        }
                                        </mat-select>
                                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
                                        <!-- <input matInput *ngIf="(data['selected_theme'])" [formControlName]="'theme'" [readonly]="(data['selected_theme'])"> -->
                                    </mat-form-field>
                                </div>
                            </div> 
    
    
                            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
                                    <div class="sm:col-span-4 w-full">
                                       <span class="block font-medium  mb-2"> Solution Description *</span>
                                        <tiptap-bubble-menu [editor]="editor" id="featureDetails">
                                            <app-editor-controls 
                                              [editor]="editor" 
                                              [selectedText]="selectedText"
                                              (completeQuickAction)="onCompleteQuickAction($event)">
                                            </app-editor-controls>
                                        </tiptap-bubble-menu>
                                        
                                        <tiptap-editor [ngClass]="{'errorClass':featureError}"
                                        id="details" 
                                        [editor]="editor" [formControlName]="'featureDetails'"
                                        (blur)="onTextareaBlur()"
                                        (mouseenter)="onMouseEnter()"
                                        (mouseleave)="onMouseLeave()"
                                        (mousedown)="onMouseDown($event, 'details')"
                                        (mouseup)="onTextSelect($event, 'details')"
                                        (select)="onSelectionChange($event, 'details')"
                                        (keydown)="onSelectionChange($event, 'details')"
                                        id="editor-with-bubble-menu">
                                        </tiptap-editor>
                                    </div>
                                    <!-- <div class="sm:col-span-2 w-full ">
                                        <span class="block font-medium  mb-2">Solution Description *</span>
                                                                <tiptap-bubble-menu [editor]="editor1" id="solutionDescription">
                                                                    <app-editor-controls 
                                                                      [editor]="editor1" 
                                                                      [selectedText]="selectedText"
                                                                      (completeQuickAction)="onCompleteQuickAction($event)">
                                                                    </app-editor-controls>
                                                                </tiptap-bubble-menu>
                                                                
                                                                <tiptap-editor [ngClass]="{'errorClass':solutionError}"
                                                                id="description" 
                                                                [editor]="editor1" 
                                                                [formControlName]="'effortSummary'"
                                                                (blur)="onTextareaBlur()"
                                                                (mouseenter)="onMouseEnter()"
                                                                (mouseleave)="onMouseLeave()"
                                                                (mousedown)="onMouseDown($event, 'description')"
                                                                (mouseup)="onTextSelect($event, 'description')"
                                                                (select)="onSelectionChange($event, 'description')"
                                                                (keydown)="onSelectionChange($event, 'description')"
                                                                id="editor-with-bubble-menu">
                                                                </tiptap-editor>
                                    </div> -->
                                </div>
    
    
                        </div>
                        <button mat-flat-button [color]="'primary'" class="w-1"
                            (click)="insertSelectedFeature()" [disabled]="tabGroupForm.disabled">
                            <mat-spinner  *ngIf="insert_loader" class="matSpinner"></mat-spinner>
                            Add
                        </button>
                    </div>
    
                </form>
    
    
            </div>
        </div>
    </div>



<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'rewriteAIDrawer'" (openedChanged)="openedChanged($event)"
    [position]="'right'"
    #rewriteAIDrawer>

    <div class="flex flex-col w-full overflow-auto  bg-card">
        <div class="flex flex-row h-20 items-center relative justify-center min-h-20  px-6 text-center w-full">
            <img src="assets/icons/aiicon1.svg" style="width: 20px;" alt=""> 
            <div class="ml-3 text-2xl font-semibold tracking-tight">Rewrite with AI</div>
            <button
                class="absolute right-0"
                mat-icon-button
                (click)="showcustomprompt=false;rewriteAIDrawer.close();clear();">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    
            </button>
        </div>

        <div class="flex flex-col p-6">
            <form [formGroup]="drawerGroupForm">
                <div class="flex flex-col gt-xs:flex-row">
                    <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>Original Text </mat-label>
                        <textarea
                            matInput [formControlName]="'original'"
                            [placeholder]="'Write a short description about your project summary'"
                            [rows]="5"></textarea>
                    </mat-form-field>
                </div>
                <div class="buttonSec flex justify-center"> 
                    <button class="px-8 mr-2 fixgramBt mt-6"  mat-raised-button color="primary" type="button"  (click)="showcustomprompt=false;fixGrammar()" [disabled]="(!drawerGroupForm.get('original').value || generate_grammar || generate_loader)"> 
                        <img *ngIf="!generate_grammar" src="assets/icons/whiteaiicon.png" style="width: 15px; margin-right: 5px;" alt=""> 
                        <mat-spinner *ngIf="generate_grammar" class="matSpinner"></mat-spinner>
                        Fix Grammar </button> 

                    <button class="px-8 mr-2 rewriteAiBt gap-2 mt-6"  mat-raised-button color="white" type="button" (click)="showcustomprompt=true;" [disabled]="(generate_grammar || generate_loader)"> 
                        <mat-icon color="white" svgIcon="heroicons_outline:squares-plus"></mat-icon> 
                        Custom Prompt 
                    </button> 
                    
                </div>


                <div *ngIf="showcustomprompt" class="flex flex-col gt-xs:flex-row mt-6">
                    <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>Custom Prompt </mat-label>
                        <textarea
                            matInput [formControlName]="'custom'"
                            [placeholder]="'Write a short description about your project summary'"
                            [rows]="2"></textarea>
                    </mat-form-field>
                </div>
                <div *ngIf="showcustomprompt" class="buttonSec flex justify-center"> 
                    <button class="px-8 mr-2 mt-6 rewriteAiBt"  mat-raised-button color="white" type="button" [disabled]="(!drawerGroupForm.get('custom').value || generate_loader)" (click)="rewiteSummary()"> 
                        <!-- <img src="assets/icons/whiteaiicon.png" style="width: 15px; margin-right: 5px;" alt="">  -->
                        <img *ngIf="!generate_loader" src="assets/icons/aiicon1.svg" style="width: 15px; margin-right: 5px;" alt=""> 
                        <mat-spinner  *ngIf="generate_loader" class="matSpinner"></mat-spinner>
                        Generate 
                    </button>  
                </div>

                <div class="flex flex-col gt-xs:flex-row mt-6">
                    <mat-form-field class="flex-auto  gt-xs:pl-3 ">
                        <mat-label>New Text </mat-label>
                        <textarea
                            matInput [formControlName]="'modified'"
                            [placeholder]="'Write a short description about your project summary'"
                            [rows]="5"></textarea>
                    </mat-form-field>
                </div>
                <div class="buttonSec flex justify-center"> 

                    <button
                        class="px-8 mr-2 mt-6 replaceBt"
                        mat-flat-button
                        [color]="'accent'"
                        type="button" [disabled]="(!drawerGroupForm.get('modified').value || generate_grammar || generate_loader)" (click)="newContent('replace')"
                        >
                        <mat-spinner  *ngIf="generate_replace" class="matSpinner"></mat-spinner>
                        Replace
                    </button>
                    <button
                        class="px-8 mt-6 appendBt"
                        mat-flat-button
                        [color]="'primary'"
                        type="button" [disabled]="(!drawerGroupForm.get('modified').value || generate_grammar || generate_loader)" (click)="newContent('append')"
                        >
                        <mat-spinner  *ngIf="generate_append" class="matSpinner"></mat-spinner>
                        Append
                    </button>
                    
                </div>
            </form>
        </div>

    </div>

</fuse-drawer>