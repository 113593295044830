import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NpsService } from 'app/core/nps/nps.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { AnalyticsService } from 'app/core/common/analytics_service';
import { NewProposalComponent } from 'app/modules/auth/new-proposal/new-proposal.component';
import analytics_event from 'app/core/common/analytics_event';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-nps',
  standalone: true,
  imports      : [ MatIconModule, MatProgressSpinnerModule, FormsModule, NgIf, MatDialogModule,MatProgressBarModule, ReactiveFormsModule, MatStepperModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatButtonModule, MatCheckboxModule, MatRadioModule,NgFor],
  templateUrl: './nps.component.html',
  styleUrl: './nps.component.scss'
})
export class NpsComponent {
  @ViewChild('horizontalStepper') private horizontalStepper: MatStepperModule;

  fetch_loader:boolean = false;
  submit:boolean = false;
  selectedRating: number;
  selectedValues: any[] = [];
  npsData: any = {};
  ratings = Array.from({ length: 10 }, (_, i) => i + 1);
  values:any[] = [];
  user: User;
  category: string;
  horizontalStepperForm: UntypedFormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  constructor(
    private dialog: MatDialog, 
    private _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _userService: UserService,
    private _analyticsService:AnalyticsService,
    private _npsService: NpsService,
    @Inject(MAT_DIALOG_DATA) public data: {category: string}
  )
  {
    // Subscribe to the user service
    this._userService.user$
    .pipe((takeUntil(this._unsubscribeAll)))
    .subscribe((user: User) =>
    {
        this.user = user;
    });
  }


  ngOnInit(): void
  {
    this.category = this.data['category'];
    // Horizontal stepper form
    this.horizontalStepperForm = this._formBuilder.group({
        step1: this._formBuilder.group({
          score: [null, Validators.required],
        }),
        step2: this._formBuilder.group({
          improvement_values: [null, Validators.required],
        }),
        step3: this._formBuilder.group({
          message: [''],
        })
    });
    if(this.category=='Proposal'){
      this.values = [
        'Proposal Summary',
        'Proposal Scope',
        'AI Recommended Features',
        'Rewrite with AI Functions',
        'Features Master',
        'Proposal Document',
        'Cost Assumptions',
        'Talk to Expert'
      ];
    }
    else if(this.category=='Project'){
      this.values = [
        'Project Summary',
        'Project Scope',
        'AI Recommended Features',
        'Rewrite with AI Functions',
        'Features Master',
        'Project Document',
        'Cost Assumptions',
        'Talk to Expert'
      ];
    }
  }

  selectRating(rating: number) {
    this.selectedRating = rating;
    this.horizontalStepperForm.get('step1.score').setValue(rating);
  }

  selectCategory(category: string) {
    const index = this.selectedValues.indexOf(category);
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
    }
    else this.selectedValues.push(category);
    this.horizontalStepperForm.get('step2.improvement_values').setValue(this.selectedValues);
  }

  submitNps(type){
    if(type=='submit')this.fetch_loader = true;
    else if(type=='skip')this.submit = true;
    let npsData = {score:this.horizontalStepperForm.get('step1.score').value,improvement_values:this.horizontalStepperForm.get('step2.improvement_values').value,message:this.horizontalStepperForm.get('step3.message').value};
    this._npsService.save(npsData)
    .subscribe(
      (value) =>
      {
       if(value){
        if(type=='close')this.dialog.closeAll();
        else if(type=='submit') this.submit = true;
        this._npsService.getStatus().subscribe();
       }
       if(type=='submit')this.fetch_loader = false;
      },
      (response) =>
      {
      },
    );
  }

  navigate(): void{
    this._router.navigate(['/proposal']);
    this.dialog.closeAll();
  }

  openDialog() {
    try{
        this._analyticsService.trackEvent(analytics_event.START_NEW_PROPOSAL,{name:this.user.personal.name,email:this.user.contact.email,company:this.user.company_name});
    }catch(error){
    }
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(NewProposalComponent,
      { 
        panelClass: 'custom-dialog-container',
        disableClose: true,
        data: {
          category: this.category
        } 
      });
  }

  closeDialog(){
    if(this.horizontalStepperForm.get('step1.score').value<1) {
      this.horizontalStepperForm.get('step1.score').setValue(0);
      this.horizontalStepperForm.get('step2.improvement_values').setValue([]);
    }
    if(!this.submit)this.submitNps('close');
    else this.dialog.closeAll();
  }

}
